import React from "react";
import { Divider, Paper, Button } from "@material-ui/core";
import TabsTitles from "../Layout/TabsTitles";
import { useParams } from "react-router-dom";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import BreadcrumbSteps from "../Layout/BreadcrumbSteps";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import AddEncryptionKey from "./AddEncryptionKey";
import EncryptionKeyRow from "./EncryptionKeyRow";
import { useAuthentication } from "../../Data/Authentication";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

function CarrierConfig() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { carrierIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [carrierDetails, setCarrierDetails] = React.useState();

    React.useEffect(() => {
        openLoader();
        APIs.getSingleCarrierDetails(carrierIDURL).then((r) => setCarrierDetails(r?.data));
    }, [])

    React.useEffect(() => {
        if (carrierDetails) closeLoader();
        else openLoader();
    }, [carrierDetails])

    return (
        <div id="connect-req-config">
            {carrierDetails && (
                <BreadcrumbSteps
                    breadcrumbTitleCurrent={`${carrierDetails?.CarrierName}`}
                    isCurrent={true}
                />
            )}
            <TabsTitles tabTitle={["Carrier Configuration"]} />
            <Paper elevation={0} className="p-5">
                <div className="row w-100 alignvh-center">
                    {/* <h5 className="black1 fw-700">Carrier information</h5> */}
                    {(userActions?.includes("37") ||
                        userActions?.includes("20")) && (
                            <div className="ml-auto">
                                <Button variant="contained" className="blue-btn mr-2" onClick={() => dialog.openDialog("Add Public Key",
                                    <AddEncryptionKey carrierID={carrierIDURL} />, []
                                )}>
                                    <label style={{ cursor: "pointer" }} className="mb-0">Add Encryption Key</label>
                                </Button>
                            </div>
                        )
                    }
                </div>
                {/* <Divider className="mt-3 mb-5" /> */}
                {/* <div className="row"> */}
                <Accordion defaultExpanded>
                    <AccordionSummary
                        aria-controls="panel2-content"
                        id="panel-header-cr-config"
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                        style={{ pointerEvents: 'none' }}
                    >
                        <span>Carrier Information</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Carrier Name</h6>

                                <div className="textfield-div">
                                    {carrierDetails?.CarrierName}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Carrier Code</h6>

                                <div className="textfield-div">
                                    {carrierDetails?.CarrierNumber}
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Carrier Federal Tax</h6>

                                <div className="textfield-div">
                                    {carrierDetails?.CarrierFederalTaxID}
                                </div>
                            </Grid>

                            <Grid item xs={carrierDetails?.EncryptionKeys?.length > 0 ? 12 : 6}>
                                <h6 className="form-title">Encryption Keys</h6>


                                {carrierDetails?.EncryptionKeys?.length > 0 && (
                                    <div className="row text-center table-header">
                                        <div className="col-3 table-cell first-cell">Key Name</div>
                                        <div className="col-3 table-cell">Key ID</div>
                                        <div className="col-3 table-cell">Fingerprint</div>
                                        {(userActions?.includes("37") ||
                                            userActions?.includes("20")) ? (
                                            <>
                                                <div className="col-2 table-cell">Created Date</div>
                                                <div className="col-1 table-cell">Actions</div>
                                            </>
                                        ) : (
                                            <div className="col-3 table-cell">Created Date</div>
                                        )}

                                    </div>
                                )}
                                {carrierDetails?.EncryptionKeys?.length > 0 ? (
                                    carrierDetails?.EncryptionKeys?.map((e, ei) => (
                                        <EncryptionKeyRow
                                            key={`encryption-keys-${ei}`}
                                            keyItem={e}
                                            index={ei}
                                            carrierDetails={carrierDetails}
                                            carrierID={carrierIDURL}
                                        />
                                    ))
                                ) : (
                                    <div className="textfield-div">
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                {/* </div> */}
            </Paper>
        </div >
    )
}

export default CarrierConfig;