import React from "react";
import * as APIs from "../../Data/APIs";
import Moment from 'moment';
import { useLoader } from "../Layout/Loader";
import FormsTransmissionDashboardFilter from "./FormsTransmissionDashboardFilter";
import FormsSingleFeedTransmissions from "./FormsSingleFeedTransmissions";
import { Paper, Zoom } from "@material-ui/core";
import Pagination from "../Layout/Pagination";
import MultipleTabsTitles from "../Layout/MultipleTabsTitles";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "../../Data/Authentication";
import { CustomTooltip } from "../Layout/Tooltip";
import GenericTable from "../Layout/GenericTable";
import { withCustomProps } from "../Transmissions/SingleFeedTransmissionNew";
import SingleEmployeeFormTransmissionNew from "./SingleEmployeeFormTransmissionNew";
// import Truncate from "react-truncate";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import Truncate from "../Layout/Truncate";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0);
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


const ExpandedRowDetails = ({ data }) => {
    return (
        <div className="expanded-row-details">
            steven
        </div>
    );
};


function FormsTransmissionDashboard() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);

    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);

    const transmissionsTabs = ["Forms Transmission Dashboard"];
    const [tabValue, setTabValue] = React.useState(0);
    const [feedsTransmissionsList, setFeedsTransmissionsList] = React.useState([]);
    const [expandedRows, setExpandedRows] = React.useState({});
    const [feedsTransmissionsResponse, setFeedsTransmissionsResponse] = React.useState("");
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [fromDateFilter, setFromDateFilter] = React.useState(todayDate);
    const [toDateFilter, setToDateFilter] = React.useState(todayDate);
    const [sentFromDateFilter, setSentFromDateFilter] = React.useState(null);
    const [sentToDateFilter, setSentToDateFilter] = React.useState(null);
    const [feedIDFilter, setFeedIDFilter] = React.useState("");
    const [carrierIDFilter, setCarrierIDFilter] = React.useState("");
    const [feedStatusFilter, setFeedStatusFilter] = React.useState("");
    const [feedPhaseFilter, setFeedPhaseFilter] = React.useState("");
    const [executionStatusFilter, setExecutionStatusFilter] = React.useState("");
    const [executionIDFilter, setExecutionIDFilter] = React.useState("");
    const [processingStepFilter, setProcessingStepFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("DESC");
    const [refreshClicked, setRefreshClicked] = React.useState(false);

    const [feedsFilterObject, setFeedsFilterObject] = React.useState();

    //Advanced Filters
    const [feedIDAdvFilter, setFeedIDAdvFilter] = React.useState("");
    const [selectedFeedNameAdvFilter, setSelectedFeedNameAdvFilter] = React.useState([]);
    const [selectedCarrierIDAdvFilter, setSelectedCarrierIDAdvFilter] = React.useState([]);
    const [selectedFeedStatusAdvFilter, setSelectedFeedStatusAdvFilter] = React.useState([]);
    const [selectedFeedPhaseAdvFilter, setSelectedFeedPhaseAdvFilter] = React.useState([]);
    const [executionIDAdvFilter, setExecutionIDAdvFilter] = React.useState("");
    const [selectedExecutionStatusAdvFilter, setSelectedExecutionStatusAdvFilter] = React.useState([]);
    const [selectedProcessingStepAdvFilter, setSelectedProcessingStepAdvFilter] = React.useState([]);
    const [requestFromDateAdvFilter, setRequestFromDateAdvFilter] = React.useState(todayDate);
    const [requestToDateAdvFilter, setRequestToDateAdvFilter] = React.useState(todayDate);
    const [sentFromDateAdvFilter, setSentFromDateAdvFilter] = React.useState(null);
    const [sentToDateAdvFilter, setSentToDateAdvFilter] = React.useState(null);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("DESC");


    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
        if (newValue === 1)
            routeHistory.push(`/FormsTransmissions/Dashboard/Production`);
        else routeHistory.push(`/FormsTransmissions/Dashboard`);
    };

    const getDataQuickFilters = () => {
        openLoader();
        if (fromDateFilter === null || todayDate === null) return;
        else {
            const feedsTransmissions = {};
            if (selectedPage >= 1) {
                feedsTransmissions.page = selectedPage;
                feedsTransmissions.limit = pageSize;
            }
            if (pageSize >= 10) {
                feedsTransmissions.limit = pageSize;
                feedsTransmissions.page = selectedPage;
            }
            if (fromDateFilter)
                feedsTransmissions.fromDate = Moment(fromDateFilter).format("YYYY-MM-DD");
            if (toDateFilter)
                feedsTransmissions.toDate = Moment(toDateFilter).format("YYYY-MM-DD");
            if (sentFromDateFilter)
                feedsTransmissions.sentFromDate = Moment(sentFromDateFilter).format("YYYY-MM-DD");
            if (sentToDateFilter)
                feedsTransmissions.sentToDate = Moment(sentToDateFilter).format("YYYY-MM-DD");
            if (feedIDFilter !== "")
                feedsTransmissions.feedID = [`${feedIDFilter}`];
            if (carrierIDFilter !== "")
                feedsTransmissions.carrierID = [`${carrierIDFilter}`];
            if (executionStatusFilter !== "")
                feedsTransmissions.executionStatus = [`${executionStatusFilter}`];
            if (processingStepFilter !== "")
                feedsTransmissions.processingStep = [`${processingStepFilter}`];
            if (feedStatusFilter !== "")
                feedsTransmissions.feedStatusID = [`${feedStatusFilter}`];
            if (feedPhaseFilter !== "")
                feedsTransmissions.feedPhaseID = [`${feedPhaseFilter}`];
            if (executionIDFilter !== "")
                feedsTransmissions.executionID = executionIDFilter;
            if (sortFieldFilter !== "")
                feedsTransmissions.sortType = sortFieldFilter;
            if (sortOrderFilter !== "")
                feedsTransmissions.sortOrder = sortOrderFilter;

            setFeedsFilterObject(feedsTransmissions);

            APIs.getFeedsDigitalFormsTranmissions(feedsTransmissions).then((r) => {
                setFeedsTransmissionsList(r?.data);
                setRefreshClicked(false);
                setTotalCount(r?.totalCount ?? 0);
                setExpandedRows({})
                closeLoader();
                setFeedsTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
            });
        }
    };

    const getDataAdvancedFilters = () => {
        openLoader();
        if (requestFromDateAdvFilter === null || requestToDateAdvFilter === null) return;
        const feedsTransmissions = {};
        if (selectedPageAdv >= 1) {
            feedsTransmissions.page = selectedPageAdv;
            feedsTransmissions.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            feedsTransmissions.limit = pageSizeAdv;
            feedsTransmissions.page = selectedPageAdv;
        }
        if (requestFromDateAdvFilter)
            feedsTransmissions.fromDate = Moment(requestFromDateAdvFilter).format("YYYY-MM-DD");
        if (requestToDateAdvFilter)
            feedsTransmissions.toDate = Moment(requestToDateAdvFilter).format("YYYY-MM-DD");
        if (sentFromDateAdvFilter)
            feedsTransmissions.sentFromDate = Moment(sentFromDateAdvFilter).format("YYYY-MM-DD");
        if (sentToDateAdvFilter)
            feedsTransmissions.sentToDate = Moment(sentToDateAdvFilter).format("YYYY-MM-DD");
        if (feedIDAdvFilter !== "")
            feedsTransmissions.feedID = [`${feedIDAdvFilter}`];
        if (selectedFeedNameAdvFilter.length > 0)
            feedsTransmissions.feedID = selectedFeedNameAdvFilter?.map(s => `${s?.FeedID}`);
        if (selectedCarrierIDAdvFilter.length > 0)
            feedsTransmissions.carrierID = selectedCarrierIDAdvFilter?.map(s => `${s?.CarrierID}`);
        if (selectedExecutionStatusAdvFilter.length > 0)
            feedsTransmissions.executionStatus = selectedExecutionStatusAdvFilter?.map(s => `${s?.Id}`);
        if (selectedProcessingStepAdvFilter.length > 0)
            feedsTransmissions.processingStep = selectedProcessingStepAdvFilter?.map(s => `${s?.Id}`);
        if (selectedFeedStatusAdvFilter.length > 0)
            feedsTransmissions.feedStatusID = selectedFeedStatusAdvFilter?.map(s => `${s?.FeedStatusID}`);
        if (selectedFeedPhaseAdvFilter.length > 0)
            feedsTransmissions.feedPhaseID = selectedFeedPhaseAdvFilter?.map(s => `${s?.FeedPhaseID}`);
        if (executionIDAdvFilter !== "")
            feedsTransmissions.executionID = executionIDAdvFilter;
        if (sortFieldAdvFilter !== "")
            feedsTransmissions.sortType = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            feedsTransmissions.sortOrder = sortOrderAdvFilter;

        setFeedsFilterObject(feedsTransmissions);

        APIs.getFeedsDigitalFormsTranmissions(feedsTransmissions).then((r) => {
            setFeedsTransmissionsList(r?.data);
            setRefreshClicked(false);
            setTotalCount(r?.totalCount ?? 0);
            setAdvancedFiltersAppliedClicked(false);
            setExpandedRows({})
            closeLoader();
            setFeedsTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
        });
    };


    React.useEffect(() => {
        if (quickFiltersClicked) getDataQuickFilters();
    }, [selectedPage, pageSize, fromDateFilter, toDateFilter, sentFromDateFilter, sentToDateFilter, feedIDFilter, carrierIDFilter, executionStatusFilter, feedStatusFilter, feedPhaseFilter, executionIDFilter, processingStepFilter, sortFieldFilter, sortOrderFilter, refreshClicked === true])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])

    React.useEffect(() => {
        getDataQuickFilters();
    }, [quickFiltersClicked === true])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [advancedFiltersClicked === true])
    // ---------------------------------------------------------------------NEW TABLE UI
    const handleSort = (column, sortDirection, type) => {
        if (type === "quick") {
            setSortFieldFilter(column?.sortingkey);
            setSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            setSortFieldAdvFilter(column?.sortingkey);
            setSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const handleRowExpandToggle = async (row) => {
        if (expandedRows[row.id]?.isExpanded) {
            setExpandedRows((prev) => ({ ...prev, [row.SK]: { ...prev[row.SK], isExpanded: false } }));
        } else {
            setExpandedRows((prev) => ({
                ...prev,
                [row.SK]: { isExpanded: !prev[row.SK]?.isExpanded }
            }));
        }
    };

    const expandableRowExpanded = (row) => row.isExpanded || false;

    const ExpandableRowsComponentWithProps = withCustomProps(SingleEmployeeFormTransmissionNew, {
        refreshClicked: refreshClicked,
        setRefreshClicked: setRefreshClicked,
    });

    const baseColumnsArray = [

        {
            name: 'Feed Name',
            sortable: true,
            sortingkey: 'FeedName',
            id: 'FeedName',
            selector: feedTransmission => <a style={{ wordBreak: "break-word", margin: "auto" }} href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`} onClick={(e) => { e.stopPropagation(); }}>
                {feedTransmission?.FeedName}
            </a>
        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: feedTransmission => feedTransmission?.CarrierName,
        },
        {
            name: 'Feed Status',
            sortable: true,
            sortingkey: 'FeedStatus',
            id: 'FeedStatus',
            selector: feedTransmission => feedTransmission?.FeedStatus,
        },
        {
            name: 'Feed Phase',
            sortable: true,
            sortingkey: 'FeedPhase',
            id: 'FeedPhase',
            selector: feedTransmission => feedTransmission?.FeedPhase,
        },
        {
            name: 'Request Timestamp',
            sortable: true,
            sortingkey: 'RequestTimestamp',
            id: 'RequestTimestamp',
            selector: feedTransmission => Moment(feedTransmission?.RequestDate).format("MM/DD/YYYY HH:mm:ss"),
            tooltip: "Date and Time of when the feed started processing or reprocessing"
        },
        {
            name: 'Sent Timestamp',
            sortable: true,
            sortingkey: 'SentTimestamp',
            id: 'SentTimestamp',
            selector: feedTransmission => <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom} title={feedTransmission?.SentDate === "N/A" ? "File is not transmitted yet" : ""}>
                <span>
                    {`${feedTransmission?.SentDate === "N/A" ? "N/A" : Moment(feedTransmission?.SentDate).format("MM/DD/YYYY HH:mm:ss")}`}
                </span>
            </CustomTooltip>,
            tooltip: "Date and Time of when the file feed was transmitted"
        },
        {
            name: 'Processing Step',
            sortable: true,
            width: '100px',
            sortingkey: 'ProcessingStep',
            id: 'ProcessingStep',
            selector: feedTransmission => feedTransmission?.ProcessingStep,
            tooltip: "The step in which the feed is currently processing"
        },
        {
            name: 'Processing Log',
            sortable: true,
            width: '200px',
            sortingkey: 'ProcessingLog',
            id: 'ProcessingLog',
            selector: feedTransmission =>
                <Truncate
                    lines={3}
                    ellipsis={
                        <span>
                            <button
                                className="btn-like-link fs-13"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    dialog.openDialog(
                                        "Processing Logs:",
                                        <div>
                                            
                                            <p style={{ wordBreak: "break-word" }}>
                                                {feedTransmission?.ProcessingLog}
                                            </p>
                                        </div>,[]
                                    );
                                }}
                            >
                                Read More
                            </button>
                        </span>
                    }
                >
                    {feedTransmission?.ProcessingLog}
                </Truncate>,
            tooltip: "The status of the Processing Step"
        },
        {
            name: 'Transmission Status',
            sortable: true,
            sortingkey: 'TransmissionStatus',
            id: 'TransmissionStatus',
            selector: feedTransmission => <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom}
                title={(feedTransmission?.ExecutionStatus)?.includes("Progress") ? "The feed is still processing" :
                    (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "Current processing step is completed" :
                        (feedTransmission?.ExecutionStatus)?.includes("Parsing") ? "Failed to convert the data file to the required format" :
                            (feedTransmission?.ExecutionStatus)?.includes("Tracking") ? "Failed to process the data" :
                                (feedTransmission?.ExecutionStatus)?.includes("Mapping") ? "There is an error in the output file" :
                                    (feedTransmission?.ExecutionStatus)?.includes("Serializing") ? "Unable to successfully generate a file" :
                                        (feedTransmission?.ExecutionStatus)?.includes("Encrypting") ? "Failed to encrypt the output file" :
                                            (feedTransmission?.ExecutionStatus)?.includes("Failed Transmitting") ? "Failed to submit the file" :
                                                (feedTransmission?.ExecutionStatus)?.includes("Failed Split") ? "Failed splitting the data into separate employees" :
                                                    (feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "File needs to be manually submitted" :
                                                        (feedTransmission?.ExecutionStatus)?.includes("Pending") ? "The feed didn't start processing yet" :
                                                            (feedTransmission?.ExecutionStatus)?.includes("Approval Required") ? "Pending customer approval to send the form" :
                                                                (feedTransmission?.ExecutionStatus)?.includes("Pulling") ? "Failed to extract a data file" :
                                                                    ""}>
                <span>
                    <div style={(feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}} className={`${(feedTransmission?.ExecutionStatus)?.includes("Fail") ? "red background status-div" : (feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "yellow-secondary-color background status-div" : ""}`} >
                        {feedTransmission?.ExecutionStatus}
                    </div>
                </span>
            </CustomTooltip>,
            tooltip: "Identifies if the feed processing was completed, failed, stopped, or pending"
        }
    ];

    const pushToColumnsBasedOnUserAction = () => {
        let checkif43or20 = (userActions?.includes("43") || userActions?.includes("20"))

        let itemsToInsert = [
            {
                index: 0,
                value: {
                    name: 'Feed ID',
                    width: '70px',
                    sortable: true,
                    sortingkey: 'FeedID',
                    id: 'FeedID',
                    selector: feedTransmission => <a style={{ margin: "auto" }} href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`} onClick={(e) => { e.stopPropagation(); }}>
                        {feedTransmission?.FeedID}
                    </a>
                },
                condition: checkif43or20
            },
            {
                index: 5,
                value: {
                    name: 'Execution ID',
                    sortable: true,
                    sortingkey: 'ExecutionID',
                    id: 'ExecutionID',
                    selector: feedTransmission => feedTransmission?.SK,
                },
                condition: checkif43or20
            },


        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumnsArray];


        itemsToInsert.forEach(item => {
            if (item?.condition)
                finalColumnsArray.splice(item.index, 0, item.value);
        });


        return finalColumnsArray;
    };



    return (
        <div id="transmission-dashboard-table">
            <div className="d-flex">
                <MultipleTabsTitles tabTitle={transmissionsTabs} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />
            </div>
            <Paper className="content-card-paper">
                <FormsTransmissionDashboardFilter
                    onFeedIDFilter={(d) => setFeedIDFilter(d)}
                    onCarrierIDFilter={(d) => setCarrierIDFilter(d)}
                    onExecutionStatusFilter={(d) => setExecutionStatusFilter(d)}
                    onProcessingStepFilter={(d) => setProcessingStepFilter(d)}
                    onFeedStatusFilter={(d) => setFeedStatusFilter(d)}
                    onFeedPhaseFilter={(d) => setFeedPhaseFilter(d)}
                    onExecutionIDFilter={(d) => setExecutionIDFilter(d)}
                    onFromDateFilter={(d) => setFromDateFilter(d)}
                    fromDateFilter={fromDateFilter}
                    onToDateFilter={(d) => setToDateFilter(d)}
                    toDateFilter={toDateFilter}
                    onSentFromDateFilter={(d) => setSentFromDateFilter(d)}
                    sentFromDateFilter={sentFromDateFilter}
                    onSentToDateFilter={(d) => setSentToDateFilter(d)}
                    sentToDateFilter={sentToDateFilter}
                    setRefreshClicked={setRefreshClicked}
                    selectedPage={selectedPage}
                    onSelectedPage={(p) => setSelectedPage(p)}
                    pageSize={pageSize}
                    onPageSize={(p) => setPageSize(p)}
                    totalCount={totalCount}
                    setTotalCount={setTotalCount}
                    selectedPageAdv={selectedPageAdv}
                    onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                    pageSizeAdv={pageSizeAdv}
                    onPageSizeAdv={(p) => setPageSizeAdv(p)}
                    quickFiltersClicked={quickFiltersClicked}
                    setQuickFiltersClicked={setQuickFiltersClicked}
                    advancedFiltersClicked={advancedFiltersClicked}
                    setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                    advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                    setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                    feedIDAdvFilter={feedIDAdvFilter}
                    onFeedIDAdvFilter={s => setFeedIDAdvFilter(s)}
                    selectedFeedNameAdvFilter={selectedFeedNameAdvFilter}
                    onSelectedFeedNameAdvFilter={s => setSelectedFeedNameAdvFilter(s)}
                    selectedCarrierIDAdvFilter={selectedCarrierIDAdvFilter}
                    onSelectedCarrierIDAdvFilter={s => setSelectedCarrierIDAdvFilter(s)}
                    selectedFeedStatusAdvFilter={selectedFeedStatusAdvFilter}
                    onSelectedFeedStatusAdvFilter={s => setSelectedFeedStatusAdvFilter(s)}
                    selectedFeedPhaseAdvFilter={selectedFeedPhaseAdvFilter}
                    onSelectedFeedPhaseAdvFilter={s => setSelectedFeedPhaseAdvFilter(s)}
                    executionIDAdvFilter={executionIDAdvFilter}
                    onExecutionIDAdvFilter={s => setExecutionIDAdvFilter(s)}
                    selectedExecutionStatusAdvFilter={selectedExecutionStatusAdvFilter}
                    onSelectedExecutionStatusAdvFilter={s => setSelectedExecutionStatusAdvFilter(s)}
                    selectedProcessingStepAdvFilter={selectedProcessingStepAdvFilter}
                    onSelectedProcessingStepAdvFilter={s => setSelectedProcessingStepAdvFilter(s)}
                    requestFromDateAdvFilter={requestFromDateAdvFilter}
                    onRequestFromDateAdvFilter={s => setRequestFromDateAdvFilter(s)}
                    requestToDateAdvFilter={requestToDateAdvFilter}
                    onRequestToDateAdvFilter={s => setRequestToDateAdvFilter(s)}
                    sentFromDateAdvFilter={sentFromDateAdvFilter}
                    onSentFromDateAdvFilter={s => setSentFromDateAdvFilter(s)}
                    sentToDateAdvFilter={sentToDateAdvFilter}
                    onSentToDateAdvFilter={s => setSentToDateAdvFilter(s)}
                />

                <div className="max-w-100 mt-3">
                    <GenericTable
                        columns={[...pushToColumnsBasedOnUserAction()]}
                        data={feedsTransmissionsList || []}
                        onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
                        expandableRows
                        // expandableRowsComponent={<SingleEmployeeFormTransmissionNew />}
                        expandableRowsComponent={ExpandableRowsComponentWithProps}
                        expandedRows={expandedRows}

                        handleRowExpandToggle={handleRowExpandToggle}
                        expandableRowExpanded={expandableRowExpanded}
                    />
                </div>


                <div>
                    <Pagination
                        stateLimit={true}
                        pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                        page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                        onChange={(t, v) => {
                            if (quickFiltersClicked) {
                                if (t === 1) setPageSize(v);
                                else if (t === 2) setSelectedPage(v);
                            } else if (advancedFiltersClicked) {
                                if (t === 1) setPageSizeAdv(v);
                                else if (t === 2) setSelectedPageAdv(v);
                            }
                        }}
                        count={totalCount}
                    />
                </div>
            </Paper>
        </div >
    )
}

export default FormsTransmissionDashboard;