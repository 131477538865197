import React from 'react'
import { useAuthentication } from "../../../../Data/Authentication";
import { useLoader } from "../../../Layout/Loader";
import { useParams } from "react-router-dom";
import EditRequestSVG from "../../../../Images/editRequest.svg";
import dateTimeSVG from "../../../../Images/dateTime.svg";
import Moment from 'moment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import * as APIs from "../../../../Data/APIs";
import TabsTitles from "../../../Layout/TabsTitles";
import { Divider, Paper, Button, Select, TextField, Switch, FormControlLabel, Zoom } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import BackupIcon from '@material-ui/icons/Backup';
import AnalystConnectivityRequestOEComments from './AnalystConnectivityRequestOEComments';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DropzoneArea } from 'material-ui-dropzone';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomTooltip } from "../../../Layout/Tooltip";
import { parseISO } from 'date-fns';
import { FailureModal } from '../../../Modals/FailureModal';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { styled } from '@mui/system';
import oeDataReadyEnabled from "../../../../Icons/oeDataReadyEnabled.svg";
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import plusIconSVG from "../../../../Icons/plusIcon.svg";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import infoCircleSVG from "../../../../Icons/info-circle.svg";
import editRequestIcon from "../../../../Icons/editIcon.svg";
import goToIcon from "../../../../Icons/right-to-bracket.svg";
import oeDataReadyDisabled from "../../../../Icons/oeDataReadyDisabled.svg";
import dataReadyIconSVG from "../../../../Icons/dataReadyIcon.svg";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Grid } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);


function AnalystConnectivityRequestViewOERequest() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const { oERequestIDURL } = useParams();
    const { connectivityRequestIDURL } = useParams();
    const [onEditOER, setOnEditOER] = React.useState(false);
    const [oERequest, setOERequest] = React.useState();
    const [planYearStartDay, setPlanYearStartDay] = React.useState(null);
    const [expectedDay, setExpectedDay] = React.useState(null);
    const [submissionDay, setSubmissionDay] = React.useState(null);
    const [oEType, setOEType] = React.useState("");
    const [oERequestDescription, setOERequestDescription] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [phase, setPhase] = React.useState("");
    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [fileAttached, setFileAttached] = React.useState();
    const [fileAttachedBase64, setFileAttachedBase64] = React.useState();
    const [assignedTo, setAssignedTo] = React.useState([]);
    const [contactsList, setContactsList] = React.useState([]);
    const [updatedGSChecked, setUpdatedGSChecked] = React.useState(false);
    const [isolvedDataChangesChecked, setIsolvedDataChangesChecked] = React.useState(false);
    const [draftChecked, setDraftChecked] = React.useState(false);
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const [OEContact, setOEContact] = React.useState("");
    const [deleteAttachment, setdeleteAttachment] = React.useState(false);
    // Resume production before PYSD
    const [resumeProdBeforePYSDChecked, setresumeProdBeforePYSDChecked] = React.useState(false);


    const [oEFileSubmissionDate, setOEFileSubmissionDate] = React.useState(null);
    // gated Data 
    const [statusesList, setStatusesList] = React.useState([]);
    const [phasesList, setPhasesList] = React.useState([]);
    const fileInputRef = React.useRef();

    // validation var 
    const [planYearStartDayValidation, setPlanYearStartDayValidation] = React.useState("");
    const [expectedDayValidation, setExpectedDayValidation] = React.useState("");
    // const [fileAttachedValidation, setFileAttachedValidation] = React.useState("");
    // comments 
    const [oeComments, setOEComments] = React.useState([]);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [expanded, setExpanded] = React.useState(true)


    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => { setConnectivityReq(r?.data); }).finally(() => closeLoader());
    }, [])
    React.useEffect(() => {
        openLoader();
        APIs.getOERequestDetails(oERequestIDURL).then(r => { setOERequest(r?.data); setOEComments(r?.data?.Comments ?? []); forceUpdate(); closeLoader(); });
    }, [])

    const handleAssignedToChange = (e, newValue) => {
        setAssignedTo(e.target.value);
    }
    React.useEffect(() => {
        APIs.getOERequestDetails(oERequestIDURL).then(r => { setOERequest(r?.data); });
    }, [])
    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: 3,
            ConnectivityRequestID: connectivityRequestIDURL
        }
        APIs.getContactsList(contactsBody).then((r) => {
            !(r?.data?.length) ? setContactsList([]) : setContactsList(r?.data);
        })
    }, [oERequest, connectivityRequestIDURL])

    React.useEffect(() => {
        setPlanYearStartDay(oERequest?.PlanYearStartDate);
        setExpectedDay(oERequest?.ClientDataExpectedDate);
        setOEType(oERequest?.OEType);
        setOERequestDescription(oERequest?.OEChanges);
        setSubmissionDay(oERequest?.SubmissionDate);
        setStatus(oERequest?.OERequestStatusID);
        setPhase(oERequest?.OERequestPhaseID);
        setIsolvedDataChangesChecked(oERequest?.ISolvedDataChanges === 1 ? true : false);
        setresumeProdBeforePYSDChecked(oERequest?.CanResumeProducionPYSD === 1 ? true : false);

        setUpdatedGSChecked(oERequest?.UpdatedGroupStructure === 1 ? true : false);
        setDraftChecked(oERequest?.IsDraftOERequest === 1 ? true : false);
        setOEFileSubmissionDate(oERequest?.OEFileSubmissionDate && oERequest?.OEFileSubmissionDate !== "0000-00-00" ? oERequest?.OEFileSubmissionDate : null);
        let filteredContact = contactsList.filter(s => s?.CognitoID === oERequest?.AssignedToCognitoID)[0];
        if (oERequest?.AssignedTo !== "" && oERequest?.assignedTo !== null) {
            setAssignedTo(filteredContact);
        }
        else {
            setAssignedTo(null);
        }

        let filterediSolvedContact = groupsAndOEContactsList.filter(s => s?.CognitoID === oERequest?.IsolvedContactCognitoID)[0];
        if (oERequest?.IsolvedContact !== "" && oERequest?.IsolvedContact !== null) {
            setOEContact(filterediSolvedContact);
        }
        else {
            setOEContact(null);
        }
        setdeleteAttachment(false);
        setFileAttached("");
        setFileAttachedBase64("");
    }, [onEditOER === true, oERequest, groupsAndOEContactsList, contactsList])

    // validation func 
    // planYearStartDay validation
    React.useEffect(() => {
        if (!planYearStartDay || planYearStartDay === "Invalid date") setPlanYearStartDayValidation("Plan year start day is missing"); else setPlanYearStartDayValidation("");
    }, [planYearStartDay, planYearStartDayValidation]);
    //  expectedDay validation
    React.useEffect(() => {
        if (!expectedDay || expectedDay === "Invalid date") setExpectedDayValidation("Client's expected data ready date is missing"); else setExpectedDayValidation("");
    }, [expectedDay, expectedDayValidation])
    // React.useEffect(() => {
    //     if (!oEType) setOETypeValidation("OE type is missing"); else setOETypeValidation("");
    // }, [oEType, oETypeValidation])
    // React.useEffect(() => {
    //     if (!oERequestDescription) setOERequestDescriptionValidation("oE Type Description is missing"); else setOERequestDescriptionValidation("");
    // }, [oERequestDescription, oERequestDescriptionValidation])
    // React.useEffect(() => {
    //     if (oERequest?.Attachment === "." || oERequest?.Attachment == null || oERequest?.Attachment == "") {
    //         if (!fileAttached && updatedGSChecked) setFileAttachedValidation("Group structure is missing"); else setFileAttachedValidation("");
    //     }
    // }, [fileAttached, fileAttachedValidation, updatedGSChecked])


    // get Data 
    React.useEffect(() => {
        APIs.getOERequestStatusesList().then(r => !(r?.data?.length) ? setStatusesList([]) : setStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getOERequestPhasesList().then(r => !(r?.data?.length) ? setPhasesList([]) : setPhasesList(r?.data));
    }, [])
    const handlePlanYearStartDateChange = (date) => {
        setPlanYearStartDay(Moment(date).format('YYYY-MM-DD'));
    };
    const handleOEFileSubmissionDate = (date) => {
        setOEFileSubmissionDate(Moment(date).format('YYYY-MM-DD'));
    };
    const handleExpectedDateChange = (date) => {
        setExpectedDay(Moment(date).format('YYYY-MM-DD'));
    };

    const handleSubmissionDateChange = (date) => {
        setSubmissionDay(Moment(date).format('YYYY-MM-DD'));
    };


    const encodeFileAttachedBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result.split(',')[1];
                setFileAttachedBase64(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };


    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    };

    const handleFileAttachedChangeDrag = (files) => {
        if (files.length === 0) return;
        if (files[0]?.size === 0) {
            FailureModal(dialog, {
                title: 'Failure!',
                body: 'File is empty!'
            });
        }
        else {
            encodeFileAttachedBase64(files[0]);
            setFileAttached(files[0]);
            setOpenSnackBar(true);
        }
    }

    const handelDeleteAttachment = (e) => {
        ConfirmModal(dialog, () => setdeleteAttachment(true), {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this file?`,
        })
    }

    const handelChangeStatus = (e) => {
        setStatus(e.target.value);
        if (e.target.value == 4) {
            SuccessModal(dialog, {
                title: 'Alert!',
                type: "alert",
                body: `Please note that the OE Automation flow will be stopped for blocked OE`,
            });
        }
    }

    const editOERequestSubmit = (e) => {
        e.preventDefault();

        if (planYearStartDayValidation || expectedDayValidation) return;
        openLoader();
        let editOEBody = {
            OERequestID: oERequestIDURL,
            PlanYearStartDate: planYearStartDay,
            ClientDataExpectedDate: expectedDay,
            OEType: oEType,
            OEChanges: oERequestDescription,
            ISolvedDataChanges: isolvedDataChangesChecked ? 1 : 0,
            UpdatedGroupStructure: updatedGSChecked ? 1 : 0,
            IsDraftOERequest: draftChecked ? 1 : 0,
            IsolvedContact: OEContact?.CognitoID,
            OERequestStatusID: status,
            OERequestPhaseID: phase,
            AssignedTo: assignedTo?.CognitoID ?? null,
            OEFileSubmissionDate: oEFileSubmissionDate,
            NoAttachmentUpdate: fileAttached || deleteAttachment ? false : true,
            CanResumeProducionPYSD: resumeProdBeforePYSDChecked ? 1 : 0,
        }

        if (fileAttached) {
            editOEBody.Attachment = updatedGSChecked ? {
                FileName: fileAttached?.name?.substring(0, fileAttached?.name.lastIndexOf('.')) ?? "",
                Extension: fileAttached?.name?.substring(fileAttached?.name.lastIndexOf('.') + 1, fileAttached?.name.length) ?? "",
                Value: fileAttachedBase64 ?? ""
            } : null;
        }
        if (deleteAttachment && !fileAttached) {
            editOEBody.Attachment = null;
        }
        // Api for edit OE Request 
        APIs.editOERequest(editOEBody).then(r => {
            window.location.reload()
        }).finally((t) => closeLoader());
    }

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])
    const handelSendOEFile = () => {
        openLoader();
        APIs.sendOEFile({ OERequestIDs: [parseInt(oERequestIDURL)] }).then(r => {
            if (r.success === "1") {
                openLoader();
                let editOEBody = {
                    OERequestID: oERequest.Id,
                    PlanYearStartDate: oERequest.PlanYearStartDate,
                    ClientDataExpectedDate: oERequest.ClientDataExpectedDate,
                    OEType: oERequest.OEType,
                    OEChanges: oERequest.OEChanges,
                    ISolvedDataChanges: oERequest.ISolvedDataChanges,
                    UpdatedGroupStructure: oERequest.UpdatedGroupStructure,
                    IsDraftOERequest: oERequest.IsDraftOERequest,
                    IsolvedContact: oERequest.IsolvedContactCognitoID,
                    OERequestStatusID: oERequest.OERequestStatusID,
                    OERequestPhaseID: 4,
                    AssignedTo: oERequest.AssignedToCognitoID,
                    OEFileSubmissionDate: oERequest.OEFileSubmissionDate,
                    NoAttachmentUpdate: true,
                    CanResumeProducionPYSD: oERequest.CanResumeProducionPYSD,
                }
                // Api for edit OE Request 
                APIs.editOERequest(editOEBody).then(r => window.location.reload())
            } else {
                closeLoader();
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r.msg,
                })
            }
        })
    }

    return (

        <div id="view-action-item">

            {oERequest && connectivityReq && (
                <BreadcrumbSteps
                    breadcrumbTitle={`Connectivity Request "${connectivityReq?.Peo?.PeoName ? connectivityReq?.Peo?.PeoName : connectivityReq?.GroupName}"`}
                    isCurrent={false}
                    breadcrumbTitleCurrent={`OE Request #${oERequestIDURL}`}
                    breadcrumbLink={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`}
                />
            )}
            <TabsTitles tabTitle={["View OE Request"]} />
            {(userActions?.includes("44") ||
                userActions?.includes("20")) && (
                    <div>
                        <div className="alignvh-center" style={{ position: "relative", justifyContent: "flex-end" }}>
                            <Button className="add-request MuiButton-btnWithText blue-outlined-btn" style={{ marginTop: "-66px" }} href={`/OERequests/ViewConnectivityRequest/${connectivityRequestIDURL}/CreateOERequest`}>
                                <svg width="22" height="22" viewBox="0 0 22 22" className="mt--2">
                                    <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                                </svg> OE Request
                            </Button>
                        </div>
                    </div>
                )
            }
            <Paper elevation={0} className="p-5">
                <form onSubmit={editOERequestSubmit} className='d-flex flex-column' id="connect-req-config">

                    <div className="ml-auto d-inline-flex">
                        {(userActions?.includes("57") ||
                            userActions?.includes("20")) ? (
                            <CustomTooltip TransitionComponent={Zoom} title={
                                ((oERequest?.OERequestStatusID === 3 || oERequest?.OERequestStatusID === 4) && !onEditOER && oERequest?.SendOEFileDone !== 1)
                                    ? `This button is disabled because OE request is ${oERequest?.OERequestStatusName}`
                                    : ((status === 4 && onEditOER && oERequest?.SendOEFileDone !== 1) ? `This button is disabled because OE request is Blocked`
                                        : ((status === 3 && onEditOER && oERequest?.SendOEFileDone !== 1) ? `This button is disabled because OE request is Cancelled`
                                            : (Moment(todayDate).format('YYYY-MM-DD') >= oERequest?.PlanYearStartDate) ? "Plan Year Start Date has passed, updates will go through the weekly files"
                                                : (new Date(oERequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ? "The earliest for an OE file to be sent is 45 days before the PYSD to capture terminations as expected" : ""))
                            }>
                                <span>
                                    <Button
                                        disabled={
                                            oERequest?.SendOEFileDone === 1 ||
                                            oERequest?.OERequestStatusID === 3 || //cancelled
                                            oERequest?.OERequestStatusID === 4 || //blocked
                                            status === 4 ||
                                            status === 3 ||
                                            Moment(todayDate).format('YYYY-MM-DD') >= oERequest?.PlanYearStartDate ||
                                            (new Date(oERequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45
                                        }
                                        variant="contained"
                                        className="align-items-center blue-outlined-btn d-flex justify-content-center mr-2"
                                        onClick={handelSendOEFile}
                                    >
                                        <span className='mr-2'>
                                            {/* <ForwardToInboxIcon style={{ cursor: "pointer", fontSize: "23px" }} /> */}
                                            {oERequest?.SendOEFileDone === 1 ?
                                                <svg width="19" height="13" viewBox="0 0 19 13" className='mt--1' >
                                                    <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                                                </svg> :
                                                oERequest?.OERequestStatusID === 3 ||
                                                    oERequest?.OERequestStatusID === 4 ||
                                                    Moment(todayDate).format('YYYY-MM-DD') >= oERequest?.PlanYearStartDate ||
                                                    (new Date(oERequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                                    <svg width="15" height="15" viewBox="0 0 15 15" className='mt--4'>
                                                        <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                                    </svg> :
                                                    <svg width="15" height="15" viewBox="0 0 15 15" className='mt--4'>
                                                        <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                                    </svg>
                                            }
                                        </span>
                                        <span>OE Data is Ready</span>
                                    </Button>
                                </span>
                            </CustomTooltip>
                        ) : (
                            <div className="align-items-center blue-outlined-btn d-flex justify-content-center mr-2">
                                <span className='mr-2'>
                                    {/* <ForwardToInboxIcon style={{ cursor: "pointer", fontSize: "23px" }} /> */}
                                    {oERequest?.SendOEFileDone === 1 ?
                                        <svg width="19" height="13" viewBox="0 0 19 13" className='mt--1' >
                                            <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                                        </svg> :
                                        oERequest?.OERequestStatusID === 3 ||
                                            oERequest?.OERequestStatusID === 4 ||
                                            Moment(todayDate).format('YYYY-MM-DD') >= oERequest?.PlanYearStartDate ||
                                            (new Date(oERequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                            <svg width="15" height="15" viewBox="0 0 15 15" className='mt--4' >
                                                <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                            </svg> :
                                            <svg width="15" height="15" viewBox="0 0 15 15" className='mt--4'>
                                                <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                            </svg>
                                    }
                                </span>
                                <span>OE Data is Ready</span>
                            </div>
                        )}
                        <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => setOnEditOER(!onEditOER)} disabled={!oERequest}>
                            <svg className="ml-1" width="15" height="15" viewBox="0 0 25 24">
                                <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                            </svg>
                            <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit OE Request</label>
                        </Button>
                    </div>


                    <Accordion defaultExpanded>
                        <AccordionSummary
                            aria-controls="panel-content"
                            id="panel-header-voiw-OE-Request-info"

                            style={{ pointerEvents: 'none' }}
                        >
                            <span >OE Request Information</span>

                        </AccordionSummary>
                        <AccordionDetails>

                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                {/* Connectivity Request ID */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Connectivity Request ID</h6>
                                    <div className="textfield-div">
                                        {connectivityRequestIDURL}
                                        <a href={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`} className="ml-auto alignvh-center color blue-main-color">
                                            {/* <ExitToAppIcon /> */}
                                            <svg width="20" height="21" viewBox="0 0 25 21">
                                                <use xlinkHref={`${goToIcon}#right-to-bracket`}></use>
                                            </svg>
                                            <span className="ml-1 fw-500">Go</span>
                                        </a>
                                    </div>
                                </Grid>
                                {/*Client Name*/}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Client Name</h6>
                                    <div className="textfield-div">
                                        {oERequest?.PeoName ? oERequest?.PeoName : oERequest?.GroupName}
                                    </div>
                                </Grid>
                                {/* Carrier Name */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Carrier Name</h6>
                                    <div className="textfield-div">
                                        {oERequest?.CarrierName === "Third Party" ? `${oERequest?.CarrierName} (${connectivityReq?.ThirdPartyName})` : oERequest?.CarrierName}
                                    </div>
                                </Grid>
                                {/* Plan Year Start Date */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">Plan Year Start Date</h6>
                                        <span className="text-danger ml-2">*</span>
                                        <CustomTooltip style={{ marginLeft: "auto", marginRight: "23px" }} TransitionComponent={Zoom} title={"The client’s renewal date with the carrier"}>
                                            {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                            <svg className="ml-1" width="15" height="15" viewBox="0 0 25 24" >
                                                <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                            </svg>
                                        </CustomTooltip>
                                    </div>

                                    {(oERequest?.SendOEFileDone === 0 && onEditOER && (userActions?.includes("46") || userActions?.includes("20"))) ? (
                                        <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px" }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    className="w-100"
                                                    autoOk={true}
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="frequency-date"
                                                    value={planYearStartDay === null ? planYearStartDay : parseISO(planYearStartDay)}
                                                    onChange={handlePlanYearStartDateChange}
                                                    keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    ) : (
                                        <div className="textfield-div">
                                            {oERequest?.PlanYearStartDate ? Moment(oERequest?.PlanYearStartDate).format('MM/DD/YYYY') : null}
                                            <div className="ml-auto">
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                    )}
                                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>{planYearStartDayValidation}</div>
                                </Grid>
                                {/* Expected Date  */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">Client's Expected Data Ready Date
                                            <span className="text-danger mb-2 ml-2">*</span></h6>
                                        <CustomTooltip style={{ marginLeft: "auto", marginRight: "23px" }} TransitionComponent={Zoom} title={"Client’s expected date of when OE updates/entries will be finalized to send the OE file"}>
                                            {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                            <svg className="ml-1" width="15" height="15" viewBox="0 0 25 24" >
                                                <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                            </svg>
                                        </CustomTooltip>
                                    </div>
                                    {(onEditOER && (userActions?.includes("47") || userActions?.includes("20"))) ? (
                                        <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px" }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    className="w-100"
                                                    autoOk={true}
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="date"
                                                    value={expectedDay === null ? expectedDay : parseISO(expectedDay)}
                                                    onChange={handleExpectedDateChange}
                                                    keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    ) : (
                                        <div className="textfield-div">
                                            {oERequest?.ClientDataExpectedDate ? Moment(oERequest?.ClientDataExpectedDate).format('MM/DD/YYYY') : null}
                                            <div className="ml-auto">
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                    )}
                                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>{expectedDayValidation}</div>
                                </Grid>
                                {/* isolved data changes */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">isolved (Data)  Changes</h6>
                                        <CustomTooltip style={{ marginLeft: "auto", marginRight: "23px" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please check it if there are changes in the data like plan names or coverage level codes, it does not necessary require an updated structure</div>}>
                                            {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                            <svg className="ml-1" width="15" height="15" viewBox="0 0 25 24" >
                                                <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                            </svg>
                                        </CustomTooltip>
                                    </div>
                                    {(onEditOER && (userActions?.includes("46") || userActions?.includes("20"))) ? (
                                        <FormControlLabel
                                            className="dependents-switch"
                                            control={
                                                <Switch
                                                    checked={isolvedDataChangesChecked}
                                                    onChange={() => setIsolvedDataChangesChecked(!isolvedDataChangesChecked)}
                                                />
                                            }
                                            label=""
                                        />
                                    ) : (
                                        <div className="textfield-div">
                                            {oERequest?.ISolvedDataChanges === 1 ? "Yes" : "No"}
                                        </div>
                                    )}
                                </Grid>
                                {/* updated GS */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">Updated Group Structure</h6>
                                        <CustomTooltip style={{ marginLeft: "auto", marginRight: "23px" }} TransitionComponent={Zoom} title={"Please check it if there is a new group structure for the current open enrollment"}>
                                            {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                            <svg className="ml-1" width="15" height="15" viewBox="0 0 25 24" >
                                                <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                            </svg>
                                        </CustomTooltip>
                                    </div>
                                    {(onEditOER) ? (
                                        <FormControlLabel
                                            className="dependents-switch"
                                            control={
                                                <Switch
                                                    checked={updatedGSChecked}
                                                    onChange={() => setUpdatedGSChecked(!updatedGSChecked)}
                                                />
                                            }
                                            label=""
                                        />
                                    ) : (
                                        <div className="textfield-div">
                                            {oERequest?.UpdatedGroupStructure === 1 ? "Yes" : "No"}
                                        </div>
                                    )}

                                </Grid>
                                {/* Group Structure >> !onEditOER */}
                                {!onEditOER && oERequest?.UpdatedGroupStructure === 1 && (
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="">
                                                <h6 className="form-title">Group Structure</h6>
                                                {/* <span className="text-danger mb-2 ml-2">*</span> */}
                                            </div>

                                            <div>
                                                <div className="textfield-div no-height">
                                                    <a href={oERequest?.AttachmentUrl} style={{ textDecoration: "underline" }}>
                                                        {oERequest?.Attachment !== "." && oERequest?.Attachment !== null ? oERequest?.Attachment : ""}
                                                    </a>
                                                </div>
                                            </div>
                                        </Grid>
                                    </>
                                )}
                                {/* Group Structure >> onEditOER */}
                                {onEditOER && updatedGSChecked && (
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div className="d-flex">
                                                <h6 className="form-title">Group Structure</h6>
                                                {/* <span className="text-danger mb-2 ml-2">*</span> */}
                                            </div>
                                            {fileAttached ? (
                                                <div className="pr-0 pl-0">
                                                    <input ref={fileInputRef} hidden type="file" className="text-input w-100" style={{ paddingRight: "50px" }} value={fileAttached ? fileAttached?.File?.name : ""} />

                                                    <div className="action-item-attachment-added textfield-div" >
                                                        <span>{fileAttached?.name}</span>
                                                        <span>
                                                            <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); }} style={{ cursor: "pointer" }}>
                                                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                            </svg>
                                                            {/* <DeleteForeverIcon className="ml-3 color grey2" style={{ cursor: "pointer" }} onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); }} /> */}
                                                        </span>
                                                        <span className="ml-auto color blue-main-color" style={{ position: "relative" }}><span className="color black2 mr-3">  {!deleteAttachment ? oERequest?.Attachment : ""}</span><span style={{ position: "absolute", right: "-15px", top: "0" }}><BackupIcon /></span></span>

                                                    </div>
                                                    {(oERequest?.Attachment !== "." && oERequest?.Attachment !== null) && !deleteAttachment && (
                                                        <div className="mt-2">
                                                            <span className="text-danger mb-2 ml-2">Kindly be noted that the currently group structure file will replace the previous existing file.</span>
                                                        </div>
                                                    )}

                                                    <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                                                        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                                            File {fileAttached?.name} successfully added.
                                                        </Alert>
                                                    </Snackbar>
                                                </div>
                                            ) : (
                                                <div className="pr-0 pl-0" style={{ position: "relative" }}>
                                                    <DropzoneArea
                                                        maxFileSize={20 * 1024 * 1024}
                                                        // add max size 20mb            
                                                        dropzoneParagraphClass={"MuiDropzoneArea-text-updated-gray"}
                                                        filesLimit={1}
                                                        dropzoneText={<div className="dz-message" data-dz-message> {(oERequest?.Attachment !== "." && oERequest?.Attachment !== null && !deleteAttachment) ? `Replace Group Structure file` : `Add Group Structure file`}</div>}
                                                        dropzoneClass={"containerClassDrag pb-5"}
                                                        onChange={(files) => handleFileAttachedChangeDrag(files)}
                                                        showPreviewsInDropzone={false}
                                                        showAlerts={false}
                                                    />

                                                    {
                                                        (!deleteAttachment && oERequest?.Attachment !== "." && oERequest?.Attachment !== null) && <span className="color black2 mr-3 " style={{ position: "absolute", left: 10, bottom: 10, zIndex: "99" }}>{oERequest?.Attachment}   {!deleteAttachment && <span>
                                                            <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={handelDeleteAttachment} style={{ cursor: "pointer" }}>
                                                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                            </svg>
                                                            {/* <DeleteForeverIcon className="ml-3 color grey2" style={{ cursor: "pointer" }} onClick={handelDeleteAttachment} /> */}
                                                        </span>}</span>
                                                    }
                                                </div>
                                            )}
                                        </Grid>
                                        {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                                <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{fileAttachedValidation}</div> */}

                                    </>
                                )}
                                {/* Description */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">Expected Changes (if any)</h6>
                                        <CustomTooltip style={{ marginLeft: "auto", marginRight: "23px" }} TransitionComponent={Zoom} title={"Please list benefit changes that should be considered for the current open enrollment"}>
                                            {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                            <svg className="ml-1" width="15" height="15" viewBox="0 0 25 24" >
                                                <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                            </svg>
                                        </CustomTooltip>
                                    </div>

                                    <div>
                                        {(onEditOER && (userActions?.includes("49") || userActions?.includes("20"))) ? (
                                            <textarea type="text" className="action-item-textarea w-100" value={oERequestDescription} onChange={(e) => { setOERequestDescription(e.target.value) }} rows={4} ></textarea>
                                        ) : (
                                            <div className="textfield-div max-height">
                                                <span style={{ whiteSpace: "break-spaces" }}>{oERequest?.OEChanges}</span>
                                            </div>
                                        )}
                                        {/* error Description  */}
                                        {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
                        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{oERequestDescriptionValidation}</div> */}
                                    </div>
                                </Grid>
                                {/* Status */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="">
                                        <h6 className="form-title">Status</h6>
                                    </div>
                                    <div>
                                        {(onEditOER && ((!userActions?.includes("20") && oERequest?.OERequestStatusID !== 3) || userActions?.includes("20"))) ? (
                                            <FormControl label="Select status" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={status ?? ""}
                                                    onChange={handelChangeStatus}
                                                >

                                                    {statusesList && statusesList?.map((c, ci) => (
                                                        <MenuItem key={`statuses-list-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {oERequest?.OERequestStatusName}
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                                {/* Phases */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Phase</h6>
                                    <div >
                                        {(onEditOER && (userActions?.includes("51") || userActions?.includes("20"))) ? (
                                            <FormControl label="Select phase" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={phase ?? ""}
                                                    onChange={e => setPhase(e.target.value)}
                                                >

                                                    {phasesList && phasesList?.map((c, ci) => (
                                                        <MenuItem key={`phases-list-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {oERequest?.OERequestPhaseName}
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                                {/* Assigned To */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Assigned To</h6>
                                    <div >
                                        {(onEditOER && (userActions?.includes("45") || userActions?.includes("20"))) ? (
                                            <Autocomplete
                                                options={contactsList?.sort((s1, s2) => {
                                                    return s2.IsEnabled - s1.IsEnabled;
                                                }) ?? []}
                                                groupBy={(option) => option.IsEnabled}
                                                renderGroup={(params) => (
                                                    <li key={params.key}>
                                                        <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                                        <GroupItems>{params.children}</GroupItems>
                                                    </li>
                                                )}
                                                autoHighlight
                                                getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                                onChange={(e, newValue) => {
                                                    setAssignedTo(newValue);
                                                }}
                                                value={assignedTo}
                                                getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        {`${option.FirstName} ${option.LastName}`}
                                                    </React.Fragment>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                                        }}
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <div className="textfield-div">
                                                {oERequest?.AssignedTo}
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                                {/* Feed */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Feed</h6>
                                    <div className="textfield-div">
                                        {oERequest?.FeedName}
                                    </div>
                                </Grid>
                                {/* Feed ID */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Feed ID</h6>
                                    <div className="textfield-div">
                                        {oERequest?.FeedID}
                                        {oERequest?.FeedName !== null && (
                                            <a href={`/Feeds/ViewFeed/${oERequest?.FeedID}`} className="ml-auto alignvh-center color blue-main-color">
                                                {/* <ExitToAppIcon /> */}
                                                <svg width="20" height="21" viewBox="0 0 25 21">
                                                    <use xlinkHref={`${goToIcon}#right-to-bracket`}></use>
                                                </svg>
                                                <span className="ml-1 fw-500">Go</span>
                                            </a>
                                        )}
                                    </div>
                                </Grid>
                                {/* OE File Transmission Date */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">OE File Transmission Date</h6>
                                    {(onEditOER && (userActions?.includes("58") || userActions?.includes("20"))) ? (
                                        <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px" }}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    className="w-100"
                                                    autoOk={true}
                                                    disableToolbar
                                                    variant="inline"
                                                    format="MM/dd/yyyy"
                                                    margin="normal"
                                                    id="frequency-date"
                                                    value={oEFileSubmissionDate !== "Invalid date" ? oEFileSubmissionDate === null ? oEFileSubmissionDate : parseISO(oEFileSubmissionDate) : null}
                                                    onChange={handleOEFileSubmissionDate}
                                                    keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    ) : (
                                        <div className="textfield-div">
                                            {oERequest?.OEFileSubmissionDate && oERequest?.OEFileSubmissionDate !== "0000-00-00" ? Moment(oERequest?.OEFileSubmissionDate).format('MM/DD/YYYY') : null}
                                            <div className="ml-auto">
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                    )}

                                </Grid>
                                {/* isolved Contact */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">isolved Contact</h6>
                                        <CustomTooltip style={{ marginLeft: "auto", marginRight: "23px" }} TransitionComponent={Zoom} title={"Assigned OE Contact"}>
                                            {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                            <svg className="ml-1" width="15" height="15" viewBox="0 0 25 24" >
                                                <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                            </svg>
                                        </CustomTooltip>
                                    </div>
                                    {(onEditOER && (userActions?.includes("47") || userActions?.includes("20"))) ? (
                                        <Autocomplete
                                            options={groupsAndOEContactsList?.sort((s1, s2) => {
                                                return s2.IsEnabled - s1.IsEnabled;
                                            }) ?? []}
                                            groupBy={(option) => option.IsEnabled}
                                            renderGroup={(params) => (
                                                <li key={params.key}>
                                                    <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                                    <GroupItems>{params.children}</GroupItems>
                                                </li>
                                            )}
                                            autoHighlight
                                            getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                            onChange={(e, newValue) => {
                                                setOEContact(newValue);
                                            }}
                                            value={OEContact}
                                            getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    {`${option.FirstName} ${option.LastName}`}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <div className="textfield-div">
                                            {oERequest?.IsolvedContact}
                                        </div>
                                    )}
                                </Grid>
                                {/* Draft */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <div className="d-flex">
                                        <h6 className="form-title">Draft</h6>
                                        <CustomTooltip style={{ marginLeft: "auto", marginRight: "23px" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please check it to identify this is not a ready yet OE request</div>}>
                                            {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                            <svg className="ml-1" width="15" height="15" viewBox="0 0 25 24" >
                                                <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                            </svg>
                                        </CustomTooltip>
                                    </div>
                                    {(onEditOER && oERequest?.OERequestStatusName === "Not Started" && (userActions?.includes("46") || userActions?.includes("20"))) ? (
                                        <FormControlLabel
                                            className="dependents-switch"
                                            control={
                                                <Switch
                                                    checked={draftChecked}
                                                    onChange={() => setDraftChecked(!draftChecked)}
                                                />
                                            }
                                            label=""
                                        />
                                    ) : (
                                        <div className="textfield-div">
                                            {oERequest?.IsDraftOERequest === 1 ? "Yes" : "No"}
                                        </div>
                                    )}
                                </Grid>
                                {/* // Resume Production Before PYSD */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Resume Production Before PYSD</h6>
                                    {(onEditOER && (userActions?.includes("45") || userActions?.includes("20"))) ? (
                                        <FormControlLabel
                                            className="dependents-switch"
                                            control={
                                                <Switch
                                                    checked={resumeProdBeforePYSDChecked}
                                                    onChange={() => setresumeProdBeforePYSDChecked(!resumeProdBeforePYSDChecked)}
                                                />
                                            }
                                            label=""
                                        />
                                    ) : (
                                        <div className="textfield-div">
                                            {oERequest?.CanResumeProducionPYSD === 1 ? "Yes" : "No"}
                                        </div>
                                    )}
                                </Grid>
                                {/* Created Date  */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Created Date</h6>
                                    <div className="textfield-div">
                                        {oERequest?.Created ? Moment(oERequest?.Created).format('MM/DD/YYYY') : null}
                                        <div className="ml-auto">
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        </div>
                                    </div>
                                </Grid>
                                {/* Created By  */}
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Created By</h6>
                                    <div className="textfield-div">
                                        {oERequest?.CreatedBy}
                                    </div>
                                </Grid>
                                {onEditOER && (
                                    <div className="w-100 mt-5 text-right">
                                        <Button type="submit" variant="contained" className="blue-btn" onClick={() => { setRequestSubmitted(true); }}>Submit OE Request</Button>
                                    </div>
                                )}
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </form>

                <AnalystConnectivityRequestOEComments oERequest={oERequest} setOERequest={setOERequest} oeComments={oeComments} setOEComments={setOEComments} parentForceUpdate={forceUpdate} />

            </Paper>

        </div >
    )
}

export default AnalystConnectivityRequestViewOERequest
