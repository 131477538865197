import React, { createRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';
import * as Apis from '../../../../Data/APIs'
import { useLoader } from '../../../Layout/Loader';

import { CustomTooltip } from '../../../Layout/Tooltip';
import { Button, Checkbox, Divider, FormControlLabel, Zoom } from '@material-ui/core';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { ConfirmModal } from '../../../Modals/ConfirmModal';
import { SuccessModal } from '../../../Modals/SuccessModal';
import { FailureModal } from '../../../Modals/FailureModal';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import RejectionReasonModal from "../../../TransmissionsForms/RejectionReasonModal";
import { useAuthentication } from '../../../../Data/Authentication';
import processEndIcon from "../../../../Icons/arrow-right-long-to-line.svg";
import { EmployeeForm } from '../../../EmployeeViews/EmployeeSignFormModal';
import moment from 'moment';
import { toESTTime } from '../../../../_helper';
import ChangedDetails from '../../ChangedDetails';
import viewActionItemSVG from "../../../../Icons/viewActionItem.svg";
import GenericTable from '../../../Layout/GenericTable';
import thumbsDown from "../../../../Icons/thumbs-down.svg";

const ClientFormsPendingBatchSubmissionTabSingleFeedWithForms = ({ feed, openSuccessSnackbar, onOpenSuccessSnackbar, openErrorSnackbar, onOpenErrorSnackbar, ...props }) => {
    const { openLoader, closeLoader } = useLoader();
    const [expanded, setexpanded] = useState(false);

    const [forms, setForms] = useState([])
    const [lastEvaluatedKey, setlastEvaluatedKey] = useState(null);
    const [selectedForms, setSelectedForms] = React.useState([]);

    const [selfForms, setSelfForms] = useState([]);
    const [SelfLastEvaluatedKey, setSelfLastEvaluatedKey] = useState(null);
    const [selectedSelfForms, setSelectedSelfForms] = React.useState([]);

    const [dfElements, setDfElements] = useState([])


    const { authUser } = useAuthentication();
    let userLegalID = authUser?.attributes["custom:LegalID"]
    let userAccountID = authUser?.attributes["custom:AccountID"]
    let userClientID = authUser?.attributes["custom:ClientID"]

    const dialog = useModalDialog(0);
    const dialog2 = useModalDialog(2);
    const signaturesRef = createRef();
    const formRef = createRef();
    const pdfRef = createRef();
    const fieldsRef = createRef();

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    // formsType ==> all , self , other
    let getFormsPerFeed = (FeedID, formsType, withReset, withResetSelf) => {
        openLoader();
        let currentFeedInfo = {
            //lsi 5 >> Transmissions Tab
            lsi: '14',
            roleID: "6",
            feedID: FeedID,
            hrSign: '0',
            userClientID: userClientID ?? '',
            userAccountID: userAccountID ?? '',
            userLegalID: userLegalID ?? ''
        };
        if ((formsType == 'all' || formsType == 'other') && lastEvaluatedKey && !withReset) currentFeedInfo.LastEvaluatedKey = lastEvaluatedKey
        if ((formsType == 'all' || formsType == 'self') && SelfLastEvaluatedKey && !withResetSelf) currentFeedInfo.SelfLastEvaluatedKey = SelfLastEvaluatedKey
        Apis.getEmployeeForms({ ...currentFeedInfo }).then(r => {
            const { selfData, clientData } = r;
            // self Data
            if (selfData.msg == "Success") {
                setDfElements(r.selfData.DFElements)
                // if (formsType == 'all' || formsType == 'self') {
                if (withResetSelf)
                    setSelfForms(selfData?.data);
                else
                    setSelfForms([...selfForms, ...selfData?.data]);
                // }
                setSelfLastEvaluatedKey(selfData?.SelfLastEvaluatedKey ? selfData?.SelfLastEvaluatedKey : null)
            } else setSelfForms([])

            // all Data
            if (clientData.msg == "Success") {
                if (!dfElements) {
                    setDfElements(r.clientData.DFElements)
                }
                let notSelfForms = clientData?.data.filter(f => f.selfFlag !== "1")
                if (withReset)
                    setForms(notSelfForms);
                else
                    setForms([...forms, ...notSelfForms]);
                setlastEvaluatedKey(clientData?.LastEvaluatedKey ? clientData?.LastEvaluatedKey : null)
            } else setForms([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }


    let handleExpandFeed = (FeedID) => {
        if (!expanded) {
            getFormsPerFeed(FeedID, 'all', true, true)
        }
        setexpanded(prev => !prev);
    }


    const handleSelectAllEmployeesClick = (value, fType) => {
        let selectedFormsArray = (fType == 'self') ? selectedSelfForms : selectedForms;
        if (!value || value === "") selectedFormsArray = [];
        else if (selectedFormsArray.indexOf(...value) >= 0) {
            selectedFormsArray.splice(selectedFormsArray.indexOf(...value));
        }
        else {
            selectedFormsArray.length = 0;
            selectedFormsArray.push(...value);
        }
        (fType == 'self') ? setSelectedSelfForms([...selectedFormsArray]) : setSelectedForms([...selectedFormsArray]);
    }

    const handleTransmitForms = (selfFlag) => {
        openLoader();
        let transmitFormObject = {
            feedID: feed?.FeedID,
            empArray: (selfFlag ? selectedSelfForms : selectedForms)?.map(form => (
                {
                    SK: form?.SK,
                    empDOB: form?.empDOB,
                    empKey: form?.empKey,
                    empName: form?.empName,
                    empSSN: form?.empSSN,
                    outputFile: form?.outputFile,
                    executionID: form?.executionID,
                    formType: form?.formType
                }
            ))
        }
        Apis.transmitForm(transmitFormObject).then((r) => {
            onOpenSuccessSnackbar(true);
            getFormsPerFeed(feed?.FeedID, selfFlag ? 'self' : 'other', true, true)
        }).catch((error) => {
            onOpenErrorSnackbar(true);
        });
    }

    const handleRejectForms = (rejectReason, rejectionValidationForm, formType) => {
        if (rejectionValidationForm) return;
        openLoader();
        dialog.closeDialog();
        let rejectFormObject = {
            empArray: (formType == 'self' ? selectedSelfForms : selectedForms)?.map(form => (
                {
                    SK: form?.SK,
                    empDOB: form?.empDOB,
                    empKey: form?.empKey,
                    empName: form?.empName,
                    empSSN: form?.empSSN,
                    outputFile: form?.outputFile,
                    executionID: form?.executionID,
                    feedID: form?.feedID,
                    rejectionReason: rejectReason,
                }
            ))
        }
        Apis.rejectForm(rejectFormObject).then((r) => {
            getFormsPerFeed(feed?.FeedID, formType ? 'self' : 'other', true, true)
        });
    }

    const getTestFile = (testOnly) => {
        // testOnly =>>will be 1 or 0 , 1 = stops on parser step only
        openLoader();
        Apis.getTestFile({ feedId: feed?.FeedID, testOnly }).then((r) => {
            if (r?.statusCode === 200) {
                SuccessModal(dialog, {
                    title: 'Success!',
                    body: `Your request has been processed successfully!`,
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: `The request has not been processed successfully. Please try again!`,
                });
            }
            closeLoader();
        })
    }
    const getTestFileConfirmation = () => {
        openModal(0, () => getTestFile(0), {
            title: 'Please Confirm',
            question: `Generate Forms will clear all the Forms that are still Pending an action <br/> Please make sure all Pending Forms are transmitted or rejected before generating new forms`,
        })
    }
    //============================================================================NEW TABLE UI 

    const handleViewFn = (form) =>
        dialog2.openDialog(
            <></>,
            <>
                {/* will submit rejection reason in this Component */}
                <EmployeeForm
                    form={form}
                    signaturesRef={signaturesRef}
                    fieldsRef={fieldsRef}
                    formRef={formRef}
                    pdfRef={pdfRef}
                    canSign={false}
                    // client Can Edit form if (the form wasn't Hr Signature required)
                    canEdit={!feed?.HrSignature}
                    mainDialog={dialog2}
                />
            </>,
            []


        )


    const handleFormSelectionClick = (value, withSelfFlag) => (event) => {

        let selectedFormsArray = withSelfFlag ? selectedSelfForms : selectedForms;

        if (!value || value === "") selectedFormsArray = [];
        else if (selectedFormsArray.indexOf(value) >= 0) {
            selectedFormsArray.splice(selectedFormsArray.indexOf(value), 1);
        }
        else {
            selectedFormsArray.push(value);
        }
        withSelfFlag ? setSelectedSelfForms([...selectedFormsArray]) : setSelectedForms([...selectedFormsArray])
    }


    const columnsArrayWithSelf = (withSelfFlag) => {

        const baseColumnArray = [
            {
                name: withSelfFlag ?
                    // self check all
                    <>
                        {selfForms?.length > 0 && (
                            <CustomTooltip TransitionComponent={Zoom}
                                title={selfForms.length <= 0 ? "There are no employees left to be selected" : "Select All"}>
                                <span>
                                    <FormControlLabel
                                        style={{ display: "unset", margin: "0" }}
                                        aria-label="Acknowledge"
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                disabled={selfForms.length <= 0}
                                                id={`employees-select-all`}
                                                value={selfForms}
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checked={selfForms.length > 0 &&
                                                    selectedSelfForms.length === selfForms?.length
                                                }
                                                onClick={() => handleSelectAllEmployeesClick(selfForms, 'self')}
                                                checkedIcon={<DoneOutlineIcon />}
                                                color="default"
                                            />
                                        }
                                    />
                                </span>
                            </CustomTooltip>
                        )}
                    </>
                    :
                    // all forms check all
                    <>
                        {forms?.length > 0 &&
                            <CustomTooltip TransitionComponent={Zoom}
                                title={forms.length <= 0 ? "There are no employees left to be selected" : "Select All"}>
                                <span>
                                    <FormControlLabel
                                        style={{ display: "unset", margin: "0" }}
                                        aria-label="Acknowledge"
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                disabled={forms.length <= 0}
                                                id={`employees-select-all`}
                                                value={forms}
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checked={forms.length > 0 &&
                                                    selectedForms.length === forms?.length
                                                }
                                                onClick={() => handleSelectAllEmployeesClick(forms, 'other')}
                                                checkedIcon={<DoneOutlineIcon />}
                                                color="default"
                                            />
                                        }
                                    />
                                </span>
                            </CustomTooltip>
                        }
                    </>
                ,
                sortable: false,
                id: 'check',
                doesNotHaveToolTip: true,
                selector: form =>
                    <>
                        <FormControlLabel
                            style={{ margin: "0", display: "unset" }}
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={
                                <Checkbox
                                    value={form}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checked={
                                        withSelfFlag ?
                                            (selectedSelfForms && selectedSelfForms.findIndex(item => item?.SK == form?.SK) >= 0)
                                            : (selectedForms && selectedForms.findIndex(item => item?.SK == form?.SK) >= 0)
                                    }
                                    onClick={handleFormSelectionClick(form, withSelfFlag)}
                                    checkedIcon={<DoneOutlineIcon />}
                                    color="default"
                                />
                            }
                        />
                    </>
            },
            {
                name: 'Employee Name',
                sortable: false,
                id: 'empName',
                selector: form => form.empName
            },
            {
                name: 'Carrier',
                sortable: false, id: 'carrierName',
                selector: form => form?.carrierName
            },
            {
                name: 'Form Type',
                sortable: false
                , id: 'formType',
                selector: form => form?.formType?.replace('Enrolment', 'Enrollment')
            },
            {
                name: 'Generation Timestamp',
                sortable: false
                , id: 'formGenerationTime',
                selector: form => form?.formGenerationTime ? moment(toESTTime(form?.formGenerationTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"

            },
            {
                name: 'Generation Reason',
                sortable: false
                , id: 'GenerationReason',
                selector: form =>
                    <button className="fs-13 link-style btn-like-link" style={{ color: "#2c87f0" }} onClick={(e) => {
                        e.stopPropagation(); e.preventDefault(); dialog.openDialog("Changed Fields:",
                            <div id="last-sent-file-modal">
                                {/* <h5 className="black1 fw-700">Changed Fields:</h5>
                                <Divider /> */}
                                <ChangedDetails dsxUrl={form?.trackedFile} dfElements={dfElements} />
                            </div>, []
                        )
                    }}>
                        Change Details
                    </button>
            },
            {
                name: 'Actions',
                sortable: false
                , id: 'Actions',
                selector: form => <div className="d-flex my-1" role="button" onClick={() => handleViewFn(form)}>
                    <svg width="18" height="18" viewBox="0 0 18 18" >
                        <use xlinkHref={`${viewActionItemSVG}#viewAIIcon`}></use>
                    </svg>
                    <span className="ml-2">View</span>
                </div>
            }
        ]

        return baseColumnArray

    }




    return (
        <>
            <div className="w-100 d-flex  mt-1 py-2">
                <div className="" role="button" onClick={() => handleExpandFeed(feed?.FeedID)}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <p className="">{feed?.CarrierName ? feed?.CarrierName : 'Carrier Name'} {
                    feed?.FeedFormMainTypes?.length > 0 && <> - ({feed?.FeedFormMainTypes?.map((el, elIndex) => <>
                        {el} {elIndex !== feed?.FeedFormMainTypes?.length - 1 && ', '}
                    </>)})
                    </>
                }</p>
                <Button variant="contained" className="blue-outlined-btn ml-auto" hidden disabled={!feed} style={{ whiteSpace: "nowrap" }} onClick={getTestFileConfirmation}>
                    <label style={{ cursor: "pointer" }} className="mb-0" hidden>Generate Forms</label>
                </Button>
            </div>
            <div className="w-100 d-flex flex-column">
                {expanded && <>
                    {/* Self Forms */}
                    {selfForms ? <>

                        <div className="my-3 text-right d-flex">
                            <p className='my-auto mb-0'>My Forms</p>
                            {selfForms?.length > 0 && (
                                <>
                                    <Button variant="contained" className="blue-btn mr-2 ml-auto"
                                        style={{ whiteSpace: "nowrap" }}
                                        disabled={selectedSelfForms?.length <= 0} onClick={() => {
                                            openModal(0, () => handleTransmitForms(true), {
                                                title: "Please Confirm",
                                                question: `Please confirm the selected form(s) have been reviewed for accuracy and are ready to be transmitted <br/> to the Carrier for processing. `,
                                            })
                                        }}>
                                        <label style={{ cursor: "pointer" }} className="mb-0">
                                            {/* <KeyboardTabIcon className="mr-2" /> */}
                                            <svg width="25" height="19" viewBox="0 0 31 19" className="mr-2" >
                                                <use xlinkHref={`${processEndIcon}#arrow-right-long-to-line`}></use>
                                            </svg>
                                            <span>Transmit Forms</span></label>
                                    </Button>
                                    <Button variant="contained" className="blue-btn" style={{ whiteSpace: "nowrap" }} disabled={selectedSelfForms?.length <= 0} onClick={() => {
                                        openModal(0, dialog.openDialog("Rejection Reason", <RejectionReasonModal formType="self" forceRejectWithReason={handleRejectForms} />, []), {
                                            title: "Please Confirm",
                                            question: `Are you sure you want to reject the selected forms?`,
                                        })
                                    }}>
                                        <label style={{ cursor: "pointer" }} className="mb-0">
                                            {/* <ThumbDownOffAltIcon className="mr-2" /> */}
                                            <svg width="24" height="22" viewBox="0 0 24 22" className="mr-2" >
                                                <use xlinkHref={`${thumbsDown}#thumbs-down`}></use>
                                            </svg>
                                            <span>Reject Forms</span></label>
                                    </Button>
                                </>
                            )}
                        </div>

                        <GenericTable
                            columns={[...columnsArrayWithSelf(true)]}
                            data={selfForms}
                            onSort={() => { }}
                        />


                    </> : 'No Forms Here'}
                    {/* All Forms */}
                    {forms ? <>
                        <div className="my-3 text-right d-flex">
                            <p className='mt-3 mb-0'>All Forms</p>
                            {forms?.length > 0 && (
                                <>
                                    <Button variant="contained" className="blue-btn mr-2 ml-auto"
                                        style={{ whiteSpace: "nowrap" }}
                                        disabled={selectedForms?.length <= 0} onClick={() => {
                                            openModal(0, () => handleTransmitForms(false), {
                                                title: "Please Confirm",
                                                question: `Please confirm the selected form(s) have been reviewed for accuracy and are ready to be transmitted <br/> to the Carrier for processing. `,
                                            })
                                        }}>
                                        <label style={{ cursor: "pointer" }} className="mb-0">
                                            {/* <KeyboardTabIcon className="mr-2" /> */}
                                            <svg width="25" height="19" viewBox="0 0 31 19" className="mr-2" >
                                                <use xlinkHref={`${processEndIcon}#arrow-right-long-to-line`}></use>
                                            </svg>
                                            <span>Transmit Forms</span></label>
                                    </Button>
                                    <Button variant="contained" className="blue-btn" style={{ whiteSpace: "nowrap" }} disabled={selectedForms?.length <= 0} onClick={() => {
                                        openModal(0, dialog.openDialog("Rejection Reason", <RejectionReasonModal formType="other" forceRejectWithReason={handleRejectForms} />, []), {
                                            title: "Please Confirm",
                                            question: `Are you sure you want to reject the selected forms?`,
                                        })
                                    }}>
                                        <label style={{ cursor: "pointer" }} className="mb-0">
                                            {/* <ThumbDownOffAltIcon className="mr-2" /> */}
                                            <svg width="24" height="22" viewBox="0 0 24 22" className="mr-2" >
                                                <use xlinkHref={`${thumbsDown}#thumbs-down`}></use>
                                            </svg>
                                            <span>Reject Forms</span></label>
                                    </Button>
                                </>
                            )}
                        </div>
                        <GenericTable
                            columns={[...columnsArrayWithSelf(false)]}
                            data={forms}
                            onSort={() => { }}
                        />

                        {forms?.length <= 0 && (
                            <div className="text-center mt-3">
                                <Button className="load-more-btn blue-btn" disabled={lastEvaluatedKey == null} onClick={() => getFormsPerFeed(feed?.FeedID, 'other', false, true)}>Load More Results</Button>
                            </div>)
                        }

                    </> : 'No Forms Here'}
                </>}
            </div>
        </>
    )
}

export default ClientFormsPendingBatchSubmissionTabSingleFeedWithForms
