import React from "react";
import { Divider, Paper, Button, FormControl } from "@material-ui/core";
import UsersTable from "./UsersTable";
import ClearIcon from '@material-ui/icons/Clear';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import plusIconSVG from "../../../Icons/PlusIconWhite.svg";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'moment';

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function UsersListView() {

    const { openLoader, closeLoader } = useLoader();
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [totalCount, setTotalCount] = React.useState(0);
    const [usersList, setUsersList] = React.useState([]);
    const [firstNameFilter, setFirstNameFilter] = React.useState("");
    const [lastNameFilter, setLastNameFilter] = React.useState("");
    const [emailFilter, setEmailFilter] = React.useState("");
    const [roleFilter, setRoleFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const [rolesList, setRolesList] = React.useState([]);
    const [createdDateFromFilter, setCreatedDateFromFilter] = React.useState(null);
    const [createdDateToFilter, setCreatedDateToFilter] = React.useState(null);

    React.useEffect(() => {
        openLoader();
        APIs.getAllRoles().then((r) => (
            setRolesList(r?.data),
            closeLoader()
        ));
    }, [])

    const getUsers = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPage >= 1) {
            requestParameters.page = selectedPage;
            requestParameters.limit = pageSize;
        }
        if (pageSize >= 10) {
            requestParameters.limit = pageSize;
            requestParameters.page = selectedPage;
        }
        if (firstNameFilter !== "")
            requestParameters.FirstName = firstNameFilter;
        if (lastNameFilter !== "")
            requestParameters.LastName = lastNameFilter;
        if (emailFilter !== "")
            requestParameters.Email = emailFilter;
        if (roleFilter !== "")
            requestParameters.RoleIDsArray = [roleFilter];
        if (statusFilter !== "")
            requestParameters.IsEnabled = parseInt(statusFilter);
        if (createdDateFromFilter !== null)
            requestParameters.CreatedFrom = createdDateFromFilter ? Moment(createdDateFromFilter).format('YYYY-MM-DD') : null;
        if (createdDateToFilter !== null)
            requestParameters.CreatedTo = createdDateToFilter ? Moment(createdDateToFilter).format('YYYY-MM-DD') : null;
        if (sortFieldFilter !== "")
            requestParameters.sortField = sortFieldFilter;
        if (sortOrderFilter !== "")
            requestParameters.sortOrder = sortOrderFilter;
        APIs.getUsers(requestParameters).then((r) => (
            !(r?.data?.length) ? setUsersList([]) : setUsersList(r?.data),
            closeLoader(),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }

    React.useEffect(() => {
        getUsers();
    }, [selectedPage, pageSize, sortFieldFilter, sortOrderFilter, firstNameFilter, lastNameFilter, emailFilter, roleFilter, statusFilter, createdDateFromFilter, createdDateToFilter])


    const handleFirstNameFilter = (e) => {
        setFirstNameFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleLastNameFilter = (e) => {
        setLastNameFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }


    const handleEmailFilter = (e) => {
        setEmailFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleRoleFilter = (e) => {
        setRoleFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleStatusFilter = (e) => {
        setStatusFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setCreatedDateFromFilter(date);
        }
        else {
            setCreatedDateToFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleClearSearch = () => {
        setFirstNameFilter("");
        setLastNameFilter("");
        setEmailFilter("");
        setRoleFilter("");
        setStatusFilter("");
        setCreatedDateFromFilter(null);
        setCreatedDateToFilter(null);
        setSelectedPage(1);
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <div>
            <div>
                <div className="alignvh-center mt-3" style={{ position: "relative", justifyContent: "flex-end" }}>
                    <Button className="add-request  blue-btn" style={{ marginTop: "-15px" }} href={`/Users/CreateUser`}>
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="white" className="mr-2">
                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                        </svg>
                         User
                    </Button>
                </div>
                <h5 className="black1 fw-400 ml-3">Users</h5>
            </div>
            <Paper className="content-card-paper">
                <div className="search-container">
                    <div className="row alignvh-center">
                        <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>First Name:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={firstNameFilter} onChange={handleFirstNameFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Last Name:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={lastNameFilter} onChange={handleLastNameFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Email:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={emailFilter} onChange={handleEmailFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Role:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={roleFilter ?? ""}
                                        onChange={handleRoleFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {rolesList?.map((s, si) => (
                                            <option key={`roles-${si}`} value={s?.Id}>{s?.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Status:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={statusFilter ?? ""}
                                        onChange={handleStatusFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value='0'>Disabled</option>
                                        <option value='1'>Enabled</option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Created Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={createdDateFromFilter}
                                            onChange={handleDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={createdDateToFilter}
                                            onChange={handleDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>

                            <div className="col-xl-12 mt-4" >
                                <Button className="blue-outlined-btn d-block" onClick={handleClearSearch}>
                                    <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2">
                                        <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                    </svg>
                                    <span>Clear Search</span>
                                </Button>
                            </div>
                        </div>
                        <UsersTable usersList={usersList} setUsersList={setUsersList} selectedPage={selectedPage} pageSize={pageSize} setSelectedPage={setSelectedPage} setPageSize={setPageSize} totalCount={totalCount} onSortFieldFilter={(s) => setSortFieldFilter(s)} onSortOrderFilter={(s) => setSortOrderFilter(s)} />
                    </div>
                </div >
            </Paper >
        </div >
    )
}

export default UsersListView;