import React, { useEffect, useState } from 'react'
import GenericTable from '../Layout/GenericTable'
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import * as APIs from '../../Data/APIs'
import LaunchIcon from '@mui/icons-material/Launch';
import downloadIconSVG from "../../Icons/downloadIcon.svg";
import Truncate from 'react-truncate';
import { toESTTime } from '../../_helper';
import Moment from 'moment';



const SingleEmployeeFormTransmissionNew = ({ data, refreshClicked, setRefreshClicked, ...props }) => {
  const dialog = useModalDialog(0);
  const [feedTransmissionLogs, setFeedTransmissionLogs] = useState([]);
  const [executionID, setExecutionID] = useState();
  const [logIndex, setLogIndex] = useState(-1);
  const [loading, setLoading] = useState(true);


  React.useEffect(() => {
    APIs.getSingleFeedDigitalFormTranmissionLogs(data?.SK).then((r) => (
      setFeedTransmissionLogs(r?.data ?? []),
      setRefreshClicked(false),
      setLoading(false)
    ));

    return () => {
      setFeedTransmissionLogs([])
    }
  }, [logIndex, refreshClicked === true])



  const baseColumns = [
    {
      name: 'Execution Step',
      sortable: false,
      id: 'ExecutionStep',
      selector: l => (
        <>
          {l?.SK !== "1" &&
            <a href={`/FormsTransmissions/Dashboard/${l?.PK}/${l?.SK}/${l?.LSI}/Members`} target="_blank" rel="noopener noreferrer">
              <LaunchIcon />
            </a>
          }

          <span style={{ marginLeft: l?.SK !== "1" ? "10px" : "33px" }}>
            {l?.LSI}
          </span>
        </>

      )
    },
    {
      name: 'Execution Status',
      sortable: false,
      id: 'ExecutionStatus',
      selector: l =>
        <div className="w-100 d-flex flex-wrap">
          <div className={`${(l?.ExecutionStatus).includes("Fail") ? "red background status-div my-auto" : ""}`}>
            {l?.ExecutionStatus}
          </div>
          {(l?.LSI === "Parser" || l?.LSI === "Split") &&
            (<> <a href={l?.InputFile} className='ml-auto my-auto'> <svg width="20" height="20" viewBox="0 0 20 20" >
              <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
            </svg> Download Input</a> </>)}
        </div>

    },
    {
      name: 'Processing Log',
      sortable: false,
      id: 'ProcessingLog',
      selector: l =>
        <Truncate lines={3} ellipsis={<span>... <button className="btn-like-link fs-13" style={{ color: "#2c87f0" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog("Processing Logs:",<div><p style={{ wordBreak: "break-word" }}>{l?.ProcessingLog}</p></div>,[]) }}>Read More</button></span>}>
          {l?.ProcessingLog}
        </Truncate>

    },
    {
      name: 'Success Count',
      sortable: false,
      id: 'SuccessCount',
      selector: l => l?.successCount
    },
    {
      name: 'Failure Count',
      sortable: false,
      id: 'FailureCount',
      selector: l => l?.failureCount
    },
    {
      name: 'Processing Start Time',
      sortable: false,
      id: 'ProcessingStartTime',
      selector: l => Moment(toESTTime(l?.ProcessingStartTime)).format("MM/DD/YYYY HH:mm:ss")
    },
    {
      name: 'Processing End Time',
      sortable: false,
      id: 'ProcessingEndTime',
      selector: l => (l?.ProcessingEndTime === "Invalid date" || l?.ProcessingEndTime === "-") ? "N/A" : Moment(toESTTime(l?.ProcessingEndTime)).format("MM/DD/YYYY HH:mm:ss")
    }
  ]
  return (
    <div className="p-3">
      {loading ? 'Loading...' :
        <>
          <GenericTable
            columns={baseColumns}
            data={feedTransmissionLogs.filter(transmissionLog => transmissionLog?.PK == data?.SK)}
            onSort={() => { }}
            evenAndOddStyles={false}
          />
        </>
      }
    </div>
  )
}

export default SingleEmployeeFormTransmissionNew
