import React from "react";
import MultipleTabsTitles from "../../../Layout/MultipleTabsTitles";
import AnalystFeedsTable from "./AnalystFeedsTable";
import { FormControl, Paper } from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import TruncatedChip from "../../../Layout/TruncatedChip";
import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useAuthentication } from "../../../../Data/Authentication";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Chip from '@mui/material/Chip';
import FeedsFilterModalBody from "./FeedsFilterModalBody";
import RefreshIcon from '@material-ui/icons/Refresh';
import refreshSVG from "../../../../Icons/refresh.svg";
import cancelIconSVG from "../../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../../Icons/advancedFilter.svg";
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import plusIconSVG from "../../../../Icons/plusIcon.svg";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function AnalystViewFeeds() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [value, setValue] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [totalCount, setTotalCount] = React.useState(0);

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);

    // Lists
    const [feedsList, setFeedsList] = React.useState([]);
    const [feedsListFilter, setFeedsListFilter] = React.useState([]);
    const [feedsStatus, setFeedsStatus] = React.useState([]);
    const [feedsStatuses, setFeedsStatuses] = React.useState([]);
    const [feedsPhases, setFeedsPhases] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);
    const [structureTypes, setStructureTypes] = React.useState([]);

    const [feedIDFilter, setFeedIDFilter] = React.useState("");
    const [feedNameFilter, setFeedNameFilter] = React.useState("");
    const [feedNameAutoValue, setFeedNameAutoValue] = React.useState("");
    const [carrierNameFilter, setCarrierNameFilter] = React.useState("");
    const [carrierNameAutoValue, setCarrierNameAutoValue] = React.useState("");
    const [feedTypeNameFilter, setFeedTypeNameFilter] = React.useState("");
    const [fileTypeNameFilter, setFileTypeNameFilter] = React.useState("");
    const [structureTypeNameFilter, setStructureTypeNameFilter] = React.useState("");
    const [createdFromDateFilter, setCreatedFromDateFilter] = React.useState(null);
    const [createdToDateFilter, setCreatedToDateFilter] = React.useState(null);
    // const [lastModifiedFromDateFilter, setLastModifiedFromDateFilter] = React.useState(sixMonthDate);
    // const [lastModifiedToDateFilter, setLastModifiedToDateFilter] = React.useState(todayDate);

    // const [modifiedByFilter, setModifiedByFilter] = React.useState("");
    // const [lastModifiedByAutoValue, setLastModifiedByAutoValue] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState();
    const [phaseFilter, setPhaseFilter] = React.useState();

    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const [refreshClicked, setRefreshClicked] = React.useState(false);
    //advanced filters
    const [selectedFeedsFilter, setSelectedFeedsFilter] = React.useState([]);
    const [selectedCarriersFilter, setSelectedCarriersFilter] = React.useState([]);
    const [selectedFeedTypesFilter, setSelectedFeedTypesFilter] = React.useState([]);
    const [selectedFileTypesFilter, setSelectedFileTypesFilter] = React.useState("");
    const [selectedStructureTypesFilter, setSelectedStructureTypesFilter] = React.useState([]);
    const [selectedStatusesFilter, setSelectedStatusesFilter] = React.useState([]);
    // const [selectedLastModifiedByFilter, setSelectedLastModifiedByFilter] = React.useState([]);
    const [selectedPhasesFilter, setSelectedPhasesFilter] = React.useState([]);
    const [feedIDAdvFilter, setFeedIDAdvFilter] = React.useState("");
    const [createdFromDateAdvFilter, setCreatedFromDateAdvFilter] = React.useState(null);
    const [createdToDateAdvFilter, setCreatedToDateAdvFilter] = React.useState(null);
    // const [lastModifiedFromDateAdvFilter, setLastModifiedFromDateAdvFilter] = React.useState(sixMonthDate);
    // const [lastModifiedToDateAdvFilter, setLastModifiedToDateAdvFilter] = React.useState(todayDate);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("");

    const [openDialog, setOpenDialog] = React.useState(false);


    const handleClickOpenDialog = () => {
        setFeedIDFilter("");
        setFeedNameFilter("");
        setFeedNameAutoValue("");
        setCarrierNameFilter("");
        setCarrierNameAutoValue("");
        setFeedTypeNameFilter("");
        setFileTypeNameFilter("");
        setStructureTypeNameFilter("");
        setCreatedFromDateFilter(null);
        setCreatedToDateFilter(null);
        // setLastModifiedFromDateFilter(sixMonthDate);
        // setLastModifiedToDateFilter(todayDate);
        setStatusFilter("");
        // setModifiedByFilter("");
        setPhaseFilter("");
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
    };

    React.useEffect(() => {
        openLoader();
        APIs.getFeedStatuses().then((r) => (
            (!(r?.length) ? setFeedsStatus([]) : setFeedsStatus(r)),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedPhases().then((r) =>
        (!(r?.data?.length) ? setFeedsPhases([]) : setFeedsPhases(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getStructureTypes().then((r) => !(r?.data?.length) ? setStructureTypes([]) : setStructureTypes(r?.data?.map((c) => (c))));
    }, []);

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedNameFilter ?? "" }).then((r) => !(r?.data?.length) ? setFeedsListFilter([]) : setFeedsListFilter(r?.data));
        setFeedNameAutoValue(feedNameFilter);
    }, [feedNameFilter])

    React.useEffect(() => {
        openLoader();
        APIs.getCarriersListByName({ CarrierName: carrierNameFilter ?? "" }).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
        setCarrierNameAutoValue(carrierNameFilter);
    }, [carrierNameFilter])

    // React.useEffect(() => {
    //     setLastModifiedByAutoValue(`${modifiedByFilter?.FirstName ?? ""} ${modifiedByFilter?.LastName ?? ""}`);
    // }, [modifiedByFilter])

    React.useEffect(() => {
        const list = ["All"];
        if (feedsStatus?.length > 0) {
            list.push(...feedsStatus?.map(s => s?.FeedStatusName));
        }

        setFeedsStatuses(list);
    }, [feedsStatus])

    const handleTabChange = (e, newValue) => {
        setValue(newValue);
        const selectedStatus = feedsStatus[newValue - 1]?.FeedStatusID;
        setStatusFilter(selectedStatus);
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleFeedIDFilter = (e) => {
        setFeedIDFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleFeedTypeNameFilter = (e) => {
        setFeedTypeNameFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleFileTypeNameFilter = (e) => {
        setFileTypeNameFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleStructureTypeNameFilter = (e) => {
        setStructureTypeNameFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleCreatedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setCreatedFromDateFilter(date);
        }
        else {
            setCreatedToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    // const handleLastModifiedDateChange = (controlName) => (date) => {
    //     if (date)
    //         date.setHours(0, 0, 0, 0);
    //     if (controlName == "fromDate") {
    //         setLastModifiedFromDateFilter(date);
    //     }
    //     else {
    //         setLastModifiedToDateFilter(date);
    //     }
    //     setSelectedPage(1);
    //     setPageSize(30);
    // };

    const handleFeedPhaseFilter = (e, newValue) => {
        setPhaseFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const getQuickFeedsData = () => {
        openLoader();
        const requestParameters = {};
        requestParameters.RequestDateFrom = createdFromDateFilter ? Moment(createdFromDateFilter).format('YYYY-MM-DD') : null;
        requestParameters.RequestDateTo = createdToDateFilter ? Moment(createdToDateFilter).format('YYYY-MM-DD') : null;
        // requestParameters.lastModifiedDateFrom = lastModifiedFromDateFilter ? Moment(lastModifiedFromDateFilter).format('YYYY-MM-DD') : null;
        // requestParameters.lastModifiedDateTo = lastModifiedToDateFilter ? Moment(lastModifiedToDateFilter).format('YYYY-MM-DD') : null;
        if (selectedPage >= 1) {
            requestParameters.pageNumber = selectedPage;
            requestParameters.limit = pageSize;
        }
        if (pageSize >= 10) {
            requestParameters.limit = pageSize;
            requestParameters.pageNumber = selectedPage;
        }
        if (feedIDFilter !== "")
            requestParameters.FeedID = feedIDFilter;
        if (feedNameFilter !== "")
            requestParameters.FeedName = feedNameFilter;
        if (carrierNameFilter !== "")
            requestParameters.CarrierName = carrierNameFilter;
        if (fileTypeNameFilter !== "")
            requestParameters.DataType = fileTypeNameFilter;
        if (feedTypeNameFilter !== "")
            requestParameters.FeedTypeName = feedTypeNameFilter;
        if (structureTypeNameFilter !== "")
            requestParameters.StructureTypeID = structureTypeNameFilter;
        // if (modifiedByFilter !== "")
        //     requestParameters.lastModifiedBy = modifiedByFilter.CognitoID;
        if (statusFilter !== "")
            requestParameters.FeedStatusID = statusFilter;
        if (phaseFilter !== "")
            requestParameters.FeedPhaseID = phaseFilter;
        if (sortFieldFilter !== "")
            requestParameters.sortField = sortFieldFilter;
        if (sortOrderFilter !== "")
            requestParameters.sortOrder = sortOrderFilter;
        APIs.getFeeds(requestParameters).then((r) => (
            setFeedsList(r?.data),
            setRefreshClicked(false),
            closeLoader(),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }

    const getAdvancedFeedsData = () => {
        openLoader();
        const requestParameters = {};
        requestParameters.RequestDateFrom = createdFromDateAdvFilter ? Moment(createdFromDateAdvFilter).format('YYYY-MM-DD') : null;
        requestParameters.RequestDateTo = createdToDateAdvFilter ? Moment(createdToDateAdvFilter).format('YYYY-MM-DD') : null;
        // requestParameters.lastModifiedDateFrom = lastModifiedFromDateAdvFilter ? Moment(lastModifiedFromDateAdvFilter).format('YYYY-MM-DD') : null;
        // requestParameters.lastModifiedDateTo = lastModifiedToDateAdvFilter ? Moment(lastModifiedToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedPageAdv >= 1) {
            requestParameters.pageNumber = selectedPageAdv;
            requestParameters.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            requestParameters.limit = pageSizeAdv;
            requestParameters.pageNumber = selectedPageAdv;
        }
        if (feedIDAdvFilter !== "")
            requestParameters.FeedID = feedIDAdvFilter;
        if (selectedFeedsFilter.length > 0)
            requestParameters.FeedIDsArray = selectedFeedsFilter?.map(s => s?.FeedID);
        if (selectedFeedTypesFilter.length > 0)
            requestParameters.FeedTypeIDsArray = selectedFeedTypesFilter?.map(s => s?.FeedTypeID);
        if (selectedFileTypesFilter !== "")
            requestParameters.DataType = selectedFileTypesFilter;
        if (selectedStructureTypesFilter.length > 0)
            requestParameters.StructureTypeIDsArray = selectedStructureTypesFilter?.map(s => s?.StructureTypeID);
        if (selectedCarriersFilter.length > 0)
            requestParameters.CarrierIDsArray = selectedCarriersFilter?.map(s => s?.CarrierID);
        // if (selectedLastModifiedByFilter.length > 0)
        //     requestParameters.LastModifiedByCognitoIDsArray = selectedLastModifiedByFilter?.map(s => s?.CognitoID);
        if (selectedStatusesFilter.length > 0)
            requestParameters.FeedStatusIDsArray = selectedStatusesFilter?.map(s => s?.FeedStatusID);
        if (selectedPhasesFilter.length > 0)
            requestParameters.FeedPhaseIDsArray = selectedPhasesFilter?.map(s => s?.FeedPhaseID);
        if (sortFieldAdvFilter !== "")
            requestParameters.sortField = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            requestParameters.sortOrder = sortOrderAdvFilter;

        APIs.getFeeds(requestParameters).then((r) => (
            setFeedsList(r?.data),
            setTotalCount(r?.totalCount ?? 0),
            setAdvancedFiltersAppliedClicked(false),
            closeLoader()
        ));
    }

    React.useEffect(() => {
        if (quickFiltersClicked) getQuickFeedsData();
    }, [selectedPage, pageSize, feedIDFilter, feedNameFilter, carrierNameFilter, feedTypeNameFilter, fileTypeNameFilter, structureTypeNameFilter, createdFromDateFilter, createdToDateFilter, statusFilter, phaseFilter, sortFieldFilter, sortOrderFilter, refreshClicked])

    React.useEffect(() => {
        getAdvancedFeedsData();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])

    React.useEffect(() => {
        getQuickFeedsData();
    }, [quickFiltersClicked === true])

    React.useEffect(() => {
        getAdvancedFeedsData();
    }, [advancedFiltersClicked === true])

    const handleClearSearch = () => {
        setValue(0);
        setFeedIDFilter("");
        setFeedNameFilter("");
        setFeedNameAutoValue("");
        setCarrierNameFilter("");
        setCarrierNameAutoValue("");
        setFeedTypeNameFilter("");
        setFileTypeNameFilter("");
        setStructureTypeNameFilter("");
        setCreatedFromDateFilter(null);
        setCreatedToDateFilter(null);
        // setLastModifiedFromDateFilter(null);
        // setLastModifiedToDateFilter(null);
        // setModifiedByFilter("");
        // setLastModifiedByAutoValue("");
        setStatusFilter("");
        setPhaseFilter("");
        setSelectedPage(1);
    }

    const handleClearSearchReset = () => {
        setValue(0);
        setFeedIDFilter("");
        setFeedIDAdvFilter("");
        setFeedNameFilter("");
        setFeedNameAutoValue("");
        setCarrierNameFilter("");
        setCarrierNameAutoValue("");
        setFeedTypeNameFilter("");
        setFileTypeNameFilter("");
        setStructureTypeNameFilter("");
        setCreatedFromDateFilter(null);
        setCreatedToDateFilter(null);
        // setLastModifiedFromDateFilter(sixMonthDate);
        // setLastModifiedToDateFilter(null);
        setCreatedFromDateAdvFilter(null);
        setCreatedToDateAdvFilter(null);
        // setLastModifiedFromDateAdvFilter(sixMonthDate);
        // setLastModifiedToDateAdvFilter(todayDate);
        // setModifiedByFilter("");
        // setLastModifiedByAutoValue("");
        setStatusFilter("");
        setPhaseFilter("");
        setSelectedPageAdv(1);
        setSelectedFeedsFilter([]);
        setSelectedCarriersFilter([]);
        setSelectedStatusesFilter([]);
        // setSelectedLastModifiedByFilter([]);
        setSelectedPhasesFilter([]);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    const notisIsovedSuperAdminOriSolvedUser = () => {
        //attached user roles ID for analyst and super admin
        return (userRoles?.includes("1") ||
            userRoles?.includes("7"));
    }

    return (
        <div id="view-feeds" className="mt-3">
            <div className="row w-100 alignvh-center">
                <h5 className="black1 fw-400 ml-3">Feeds Dashboard</h5>
            </div>
            <div>
                {(userActions?.includes("28") ||
                    userActions?.includes("20")) && (
                        <div className="alignvh-center" style={{ position: "relative", justifyContent: "flex-end" }}>
                            <Button className="add-request blue-outlined-btn" href={`/Feeds/CreateFeed`}>
                                <svg width="22" height="22" viewBox="0 0 22 22" className="mt--2">
                                    <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                                </svg> Feed
                            </Button>
                        </div>
                    )
                }
                {quickFiltersClicked && (
                    <MultipleTabsTitles tabTitle={feedsStatuses} value={value} setValue={setValue} onChange={handleTabChange} />
                )}
            </div>

            <Paper className="content-card-paper">
                <div className="search-container">
                    <div className="row alignvh-center">
                        <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                        <Button
                            className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                            onClick={handleClickOpenDialog}
                            style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                        >
                            <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1" >
                                <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                            </svg>
                            Advanced Filters
                        </Button>
                        <React.Fragment>
                            {feedIDFilter && <TruncatedChip body={`Feed ID: ${feedIDFilter}`} />}
                            {feedIDAdvFilter && <TruncatedChip body={`Feed ID: ${feedIDAdvFilter}`} />}
                            {feedNameFilter && <TruncatedChip body={`Feed: ${feedNameFilter}`} />}
                            {selectedFeedsFilter && selectedFeedsFilter?.length > 0 && <TruncatedChip body={`Feeds: ${selectedFeedsFilter?.map(s => s.FeedName).join(" - ")}`} />}
                            {carrierNameFilter && <TruncatedChip body={`Carrier: ${carrierNameFilter}`} />}
                            {selectedCarriersFilter && selectedCarriersFilter?.length > 0 && <TruncatedChip body={`Carriers: ${selectedCarriersFilter?.map(s => s.CarrierName).join(" - ")}`} />}
                            {feedTypeNameFilter && <TruncatedChip body={`Feed Type: ${feedTypeNameFilter === "Multi" ? "Multi Tenant" : "Single Tenant"}`} />}
                            {selectedFeedTypesFilter && selectedFeedTypesFilter?.length > 0 && <TruncatedChip body={`Feed Types: ${selectedFeedTypesFilter?.map(s => s.FeedTypeName).join(" - ")}`} />}
                            {fileTypeNameFilter && <TruncatedChip body={`Data Type: ${fileTypeNameFilter === "Full" ? "Full File" : "Changes Only File"}`} />}
                            {selectedFileTypesFilter && <TruncatedChip body={`Data Types: ${selectedFileTypesFilter === "Full" ? "Full File" : "Changes Only File"}`} />}
                            {structureTypeNameFilter && <TruncatedChip body={`Structure Type: ${structureTypes?.filter(s => s.StructureTypeID == structureTypeNameFilter)[0].StructureTypeName}`} />}
                            {selectedStructureTypesFilter && selectedStructureTypesFilter?.length > 0 && <TruncatedChip body={`Structure Types: ${selectedStructureTypesFilter?.map(s => s.StructureTypeName).join(" - ")}`} />}
                            {quickFiltersClicked && createdFromDateFilter && createdToDateFilter && <TruncatedChip body={`Creation Date: From: ${Moment(createdFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(createdToDateFilter).format("MM/DD/YYYY")}`} />}
                            {quickFiltersClicked && createdFromDateFilter && !createdToDateFilter && <TruncatedChip body={`Creation Date: From: ${Moment(createdFromDateFilter).format("MM/DD/YYYY")}`} />}
                            {quickFiltersClicked && !createdFromDateFilter && createdToDateFilter && <TruncatedChip body={`Creation Date: To: ${Moment(createdToDateFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && createdFromDateAdvFilter && createdToDateAdvFilter && <TruncatedChip body={`Creation Date: From: ${Moment(createdFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(createdToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && createdFromDateAdvFilter && !createdToDateAdvFilter && <TruncatedChip body={`Creation Date: From: ${Moment(createdFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && !createdFromDateAdvFilter && createdToDateAdvFilter && <TruncatedChip body={`Creation Date: To: ${Moment(createdToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {phaseFilter && <TruncatedChip body={`Feed Phase: ${feedsPhases?.filter(r => r.FeedPhaseID == phaseFilter)[0].FeedPhaseName}`} />}
                            {selectedPhasesFilter && selectedPhasesFilter?.length > 0 && <TruncatedChip body={`Feed Phases: ${selectedPhasesFilter?.map(s => s.FeedPhaseName).join(" - ")}`} />}
                            {statusFilter && <TruncatedChip body={`Feed Status: ${feedsStatus?.filter(s => s.FeedStatusID == statusFilter)[0].FeedStatusName}`} />}
                            {selectedStatusesFilter && selectedStatusesFilter?.length > 0 && <TruncatedChip body={`Feed Statuses: ${selectedStatusesFilter?.map(s => s.FeedStatusName).join(" - ")}`} />}
                        </React.Fragment>
                    </div>
                    {quickFiltersClicked && (
                        (userActions?.includes("43") || userActions?.includes("20")) ? (
                            <div className="row">
                                <div className="col-xl-3 mt-3">
                                    <span>Feed ID:</span>
                                    <br />
                                    <input type="text" className="search-input w-100" value={feedIDFilter} onChange={handleFeedIDFilter} />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Feed Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={feedsListFilter}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={feedNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.FeedName}
                                        onChange={(e, value) => {
                                            setFeedNameFilter(value?.FeedName ?? "");
                                            setSelectedPage(1);
                                            setPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.FeedName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setFeedNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Carrier Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={carriersList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={carrierNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.CarrierName}
                                        onChange={(e, value) => {
                                            setCarrierNameFilter(value?.CarrierName ?? "");
                                            setSelectedPage(1);
                                            setPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.CarrierName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setCarrierNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Feed Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={feedTypeNameFilter ?? ""}
                                            onChange={handleFeedTypeNameFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            <option aria-label="single" value="Single">Single Tenant</option>
                                            <option aria-label="multi" value="Multi">Multi Tenant</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Data Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={fileTypeNameFilter ?? ""}
                                            onChange={handleFileTypeNameFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            <option aria-label="full" value="Full">Full File</option>
                                            <option aria-label="changes" value="Changes">Changes Only File</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                {notisIsovedSuperAdminOriSolvedUser() &&
                                    <div className="col-xl-3 mt-3">
                                        <span>Structure Type:</span>
                                        <br />
                                        <FormControl className="search-dropdown w-100">
                                            <NativeSelect
                                                value={structureTypeNameFilter ?? ""}
                                                onChange={handleStructureTypeNameFilter}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" value="" />
                                                {structureTypes?.map((s, si) => (
                                                    <option key={`structure-type-${si}`} value={s?.StructureTypeID}>{s?.StructureTypeName}</option>
                                                ))}
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                }
                                <div className="col-xl-3 mt-3">
                                    <span>Created Date:</span>
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={createdFromDateFilter}
                                                onChange={handleCreatedDateChange("fromDate")}
                                                placeholder="From Date"
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={createdToDateFilter}
                                                onChange={handleCreatedDateChange("toDate")}
                                                placeholder="To Date"
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Phase:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={phaseFilter ?? ""}
                                            onChange={handleFeedPhaseFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            {feedsPhases?.map((p, pi) => (
                                                <option key={`feed-phase-${pi}`} value={p.FeedPhaseID}>{p.FeedPhaseName}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-xl-3 mt-3">
                                    <span>Feed Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={feedsListFilter}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={feedNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.FeedName}
                                        onChange={(e, value) => {
                                            setFeedNameFilter(value?.FeedName ?? "");
                                            setSelectedPage(1);
                                            setPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.FeedName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setFeedNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Carrier Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={carriersList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={carrierNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.CarrierName}
                                        onChange={(e, value) => {
                                            setCarrierNameFilter(value?.CarrierName ?? "");
                                            setSelectedPage(1);
                                            setPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.CarrierName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setCarrierNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Feed Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={feedTypeNameFilter ?? ""}
                                            onChange={handleFeedTypeNameFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            <option aria-label="single" value="Single">Single Tenant</option>
                                            <option aria-label="multi" value="Multi">Multi Tenant</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Data Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={fileTypeNameFilter ?? ""}
                                            onChange={handleFileTypeNameFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            <option aria-label="full" value="Full">Full File</option>
                                            <option aria-label="changes" value="Changes">Changes Only File</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                {notisIsovedSuperAdminOriSolvedUser() &&
                                    <div className="col-xl-3 mt-3">
                                        <span>Structure Type:</span>
                                        <br />
                                        <FormControl className="search-dropdown w-100">
                                            <NativeSelect
                                                value={structureTypeNameFilter ?? ""}
                                                onChange={handleStructureTypeNameFilter}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" value="" />
                                                {structureTypes?.map((s, si) => (
                                                    <option key={`structure-type-${si}`} value={s?.StructureTypeID}>{s?.StructureTypeName}</option>
                                                ))}
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                }
                                <div className="col-xl-3 mt-3">
                                    <span>Created Date:</span>
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={createdFromDateFilter}
                                                onChange={handleCreatedDateChange("fromDate")}
                                                placeholder="From Date"
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={createdToDateFilter}
                                                onChange={handleCreatedDateChange("toDate")}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Phase:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={phaseFilter ?? ""}
                                            onChange={handleFeedPhaseFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            {feedsPhases?.map((p, pi) => (
                                                <option key={`feed-phase-${pi}`} value={p.FeedPhaseID}>{p.FeedPhaseName}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                            </div>
                        ))
                    }
                    <div className="col-xl-12 mt-4" >
                        <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                            <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                                <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                            </svg>
                            <span className="ml-1">Refresh List</span>
                        </Button>
                        <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                            <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                            </svg> Clear Search
                        </Button>
                    </div>
                    <AnalystFeedsTable advancedFiltersClicked={advancedFiltersClicked} quickFiltersClicked={quickFiltersClicked} feedsList={feedsList} setFeedsList={setFeedsList} selectedPage={selectedPage} pageSize={pageSize} setSelectedPage={setSelectedPage} setPageSize={setPageSize} selectedPageAdv={selectedPageAdv} setSelectedPageAdv={setSelectedPageAdv} pageSizeAdv={pageSizeAdv} setPageSizeAdv={setPageSizeAdv} totalCount={totalCount} onSortFieldFilter={(s) => setSortFieldFilter(s)} onSortOrderFilter={(s) => setSortOrderFilter(s)} onSortFieldAdvFilter={setSortFieldAdvFilter} onSortOrderAdvFilter={setSortOrderAdvFilter} />
                </div >
            </Paper >

            <FeedsFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setPageSizeAdv={setPageSizeAdv}
                setSelectedPageAdv={setSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                setFeedIDAdvFilter={setFeedIDAdvFilter}
                setSelectedFeedsFilter={setSelectedFeedsFilter}
                setSelectedCarriersFilter={setSelectedCarriersFilter}
                setSelectedFeedTypesFilter={setSelectedFeedTypesFilter}
                setSelectedFileTypesFilter={setSelectedFileTypesFilter}
                setSelectedStructureTypesFilter={setSelectedStructureTypesFilter}
                setCreatedFromDateAdvFilter={setCreatedFromDateAdvFilter}
                setCreatedToDateAdvFilter={setCreatedToDateAdvFilter}
                // setLastModifiedFromDateAdvFilter={setLastModifiedFromDateAdvFilter}
                // setLastModifiedToDateAdvFilter={setLastModifiedToDateAdvFilter}
                // setSelectedLastModifiedByFilter={setSelectedLastModifiedByFilter}
                setSelectedPhasesFilter={setSelectedPhasesFilter}
                setSelectedStatusesFilter={setSelectedStatusesFilter}
                onCloseClearReset={handleClearSearchReset}
            />


        </div >
    );
}

export default AnalystViewFeeds;