import React from "react";
import { Divider, Button } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';

function SummaryAnalystView({ contentList, preMappingSections, onEdit, setActiveStep, ...props }) {
    return (
        <div>
            {preMappingSections.map((c, ci) => (
                <div key={`summary-section-${ci}`} className="pmf-summary-section-div">
                    <div className="row w-100 alignvh-center">
                        <h6 className="pink-primary-color color fw-700">{c?.Title}</h6>
                        <div className="ml-auto">
                            <Button onClick={(e) => { setActiveStep(ci) }}>
                                <EditIcon color="action" />
                            </Button>
                        </div>
                    </div>
                    <Divider className="mb-3" />
                    {c.Type === "carrierPlan" ? (
                        <div className="row mb-2">
                            <div className="col-xl-4 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Carrier Plan Name</h6>
                            </div>
                            <div className="col-xl-3 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Plan Type</h6>
                            </div>
                            <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Analyst Comment</h6>
                            </div>
                        </div>
                    ) : c.Type === "relationshipCode" ? (
                        c.IncludeDependents === true ? (
                            <div className="row mb-2">
                                <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <h6 className="form-title">Carrier Relationship Code</h6>
                                </div>
                                <div className="col-xl-7 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <h6 className="form-title">Analyst Comment</h6>
                                </div>
                            </div>
                        ) : (
                            <div className="no-data-alert">
                                <h6>This file will not include dependents</h6>
                            </div>
                        )
                    ) : (
                        <div className="row mb-2">
                            <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">{preMappingSections[ci].Title}</h6>
                            </div>
                            <div className="col-xl-7 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Analyst Comment</h6>
                            </div>
                        </div>
                    )}
                    {c?.Model?.map((p, pi) => (
                        c.Type === "carrierPlan" ? (
                            <div className="row mb-2" key={`Plan-summary-${pi}`}>
                                <div className="col-xl-4 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.CarrierPlanName}</span>
                                </div>
                                <div className="col-xl-3 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.PlanType}</span>
                                </div>
                                <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.Comment.Text}</span>
                                </div>
                            </div>
                        ) : c.Type === "relationshipCode" ? (
                            <div key={`Plan-summary-${pi}`} className="row mb-2">
                                <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.CarrierRelationshipCode}</span>
                                </div>
                                <div className="col-xl-7 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.Comment.Text}</span>
                                </div>
                            </div>
                        ) : (
                            <div key={`Plan-summary-${pi}`} className="row mb-2">
                                <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.CarrierSection}</span>
                                </div>
                                <div className="col-xl-7 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.Comment.Text}</span>
                                </div>
                            </div>
                        )
                    ))
                    }
                </div>
            ))}
            {contentList.map((c, ci) => (
                <div key={`summary-section-${ci}`} className="pmf-summary-section-div">
                    <div className="row w-100 alignvh-center">
                        <h6 className="pink-primary-color color fw-700">{c?.Title}</h6>
                        <div className="ml-auto">
                            <Button onClick={(e) => { onEdit ? setActiveStep(ci + preMappingSections.length) : setActiveStep(ci) }}>
                                <EditIcon color="action" />
                            </Button>
                        </div>
                    </div>
                    <Divider className="mb-3" />
                    {c.Type === "carrierPlan" ? (
                        <div className="row mb-2">
                            <div className="col-xl-4 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Carrier Plan Name</h6>
                            </div>
                            <div className="col-xl-3 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Plan Type</h6>
                            </div>
                            <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Analyst Comment</h6>
                            </div>
                        </div>
                    ) : c.Type === "relationshipCode" ? (
                        c.IncludeDependents === true ? (
                            <div className="row mb-2">
                                <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <h6 className="form-title">Carrier Relationship Code</h6>
                                </div>
                                <div className="col-xl-7 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <h6 className="form-title">Analyst Comment</h6>
                                </div>
                            </div>
                        ) : (
                            <div className="no-data-alert">
                                <h6>This file will not include dependents</h6>
                            </div>
                        )
                    ) : (
                        <div className="row mb-2">
                            <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">{contentList[ci].Title}</h6>
                            </div>
                            <div className="col-xl-7 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Analyst Comment</h6>
                            </div>
                        </div>
                    )}
                    {c?.Model?.map((p, pi) => (
                        c.Type === "carrierPlan" ? (
                            <div className="row mb-2" key={`Plan-summary-${pi}`}>
                                <div className="col-xl-4 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.CarrierPlanName}</span>
                                </div>
                                <div className="col-xl-3 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.PlanType}</span>
                                </div>
                                <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.Comment.Text}</span>
                                </div>
                            </div>
                        ) : c.Type === "relationshipCode" ? (
                            <div key={`Plan-summary-${pi}`} className="row mb-2">
                                <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.CarrierRelationshipCode}</span>
                                </div>
                                <div className="col-xl-7 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.Comment.Text}</span>
                                </div>
                            </div>
                        ) : (
                            <div key={`Plan-summary-${pi}`} className="row mb-2">
                                <div className="col-xl-5 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.CarrierSection}</span>
                                </div>
                                <div className="col-xl-7 alignvh-center pr-0 pl-0" style={{ whiteSpace: "nowrap" }}>
                                    <span>{p.Comment.Text}</span>
                                </div>
                            </div>
                        )
                    ))
                    }
                </div>
            ))}
        </div>
    )
}

export default SummaryAnalystView;