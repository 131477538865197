import React from "react";
import { Button, Divider, Paper, TextField } from "@material-ui/core";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import * as APIs from "../../../Data/APIs";
import AnalystConnectivityRequestSingleContactRow from "./AnalystConnectivityRequestSingleContactRow";
import { useLoader } from "../../Layout/Loader";
import { useAuthentication } from "../../../Data/Authentication";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import { emailPattern } from "../../GroupRequest/ViewEditGroupRequest/ViewGroupRequestClientContactsRow";
import plusIconSVG from "../../../Icons/PlusIconWhite.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import saveIconSVG from "../../../Icons/save.svg";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AnalystConnectivityRequestContacts({ carrierContactTypes, connectivityReq, onEdit, setConnectivityReq, selectedPlans, otherPlanTypes, ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const [addContactClicked, setAddContactClicked] = React.useState(false);
    const [inputFNValue, setInputFNValue] = React.useState("");
    const [inputLNValue, setInputLNValue] = React.useState("");
    const [inputEMValue, setInputEMValue] = React.useState("");
    const [inputTypeValue, setInputTypeValue] = React.useState("");
    const [inputSaved, setInputSaved] = React.useState(false);
    const { openLoader, closeLoader } = useLoader();
    const [, forceUpdate] = React.useReducer(x => !x, false);


    const [saveButtonClicked, setSaveButtonClicked] = React.useState(false);

    // validations
    const [firstNameValidation, setFirstNameValidation] = React.useState('');
    const [lastNameValidation, setLastNameValidation] = React.useState('');
    const [emailValidation, setEmailValidation] = React.useState('');
    const [typeValidation, setTypeValidation] = React.useState('');


    // Validation UseEffects
    // First Name Validation
    React.useEffect(() => {
        if (!inputFNValue || inputFNValue.split(" ").join("").length == 0) setFirstNameValidation("This Field is Required");
        else setFirstNameValidation("");
    }, [inputFNValue, firstNameValidation])

    // Last Name Validation
    React.useEffect(() => {
        if (!inputLNValue || inputLNValue.split(" ").join("").length == 0) setLastNameValidation("This Field is Required");
        else setLastNameValidation("");
    }, [inputLNValue, lastNameValidation])

    // Email Validation
    React.useEffect(() => {
        if (!inputEMValue || inputEMValue.split(" ").join("").length == 0) {
            setEmailValidation("This Field is Required");
        } else if (!emailPattern.test(inputEMValue)) {
            setEmailValidation("Invalid Email Format");
        }
        else setEmailValidation("");
    }, [inputEMValue, emailValidation])

    // type Validation
    React.useEffect(() => {
        if (!inputTypeValue || inputTypeValue.split(" ").join("").length == 0) setTypeValidation("This Field is Required");
        else setTypeValidation("");
    }, [inputTypeValue, typeValidation])
    // // Validation UseEffects




    React.useEffect(() => {
        if (!connectivityReq) return;
        connectivityReq.ConnectivityRequestContacts = connectivityReq.ConnectivityRequestContacts ?? [];
    }, [connectivityReq, JSON.stringify(connectivityReq?.ConnectivityRequestContacts)]);

    const textValidatorCheck = React.useRef();

    const handleChangeInputFNValue = (e) => {
        setInputFNValue(e.target.value);
    }

    const handleChangeInputLNValue = (e) => {
        setInputLNValue(e.target.value);
    }

    const handleChangeInputEMValue = (e) => {
        setInputEMValue(e.target.value);
    }

    const handleChangeInputTypeValue = (e) => {
        setInputTypeValue(e.target.value);
    }

    const handleSaveAllInputs = () => {
        setSaveButtonClicked(true)
        if (firstNameValidation || lastNameValidation || emailValidation || typeValidation) return;
        openLoader();

        setAddContactClicked(false);
        setInputSaved(true);
        const newContact = {
            ConnectivityRequestContactFirstName: inputFNValue,
            ConnectivityRequestContactLastName: inputLNValue,
            ConnectivityRequestContactEmail: inputEMValue,
            CarrierContactType: inputTypeValue,
            isDelete: false
        }

        connectivityReq.ConnectivityRequestContacts = [...(connectivityReq.ConnectivityRequestContacts ?? []), newContact];

        let updatedContacts = {};

        updatedContacts = {
            ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
            CarriersContacts: connectivityReq.ConnectivityRequestContacts,
        };

        APIs.editConnectivityRequestContacts(updatedContacts).then(r => {
            APIs.getConnectivityRequest(connectivityReq?.ConnectivityRequestID).then((s) => {
                setConnectivityReq(s?.data);
                setAddContactClicked(false);
                setInputFNValue("");
                setInputLNValue("");
                setInputEMValue("");
                setInputTypeValue("");
                setSaveButtonClicked(false)
                closeLoader();
            })
        });
    }

    return (
        <div className="mt-5" id="cr-carrier-contacts">
            <Accordion defaultExpanded>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel-header-cr-contacts"
                >
                    <span>Carrier Contacts</span>
                    {(userActions?.includes("23") || userActions?.includes("20")) && (
                        <div className="add-record-btn ml-auto" onClick={(e) => { setAddContactClicked(true); e.stopPropagation(); }}>
                            <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                            </svg>
                            ADD
                        </div>
                    )}
                </AccordionSummary>
                <AccordionDetails className="no-padding-accordion-details">

                    <div className="row table-header">
                        <div className="col-2 table-cell">
                            First Name
                        </div>
                        <div className="col-2 table-cell">
                            Last Name
                        </div>
                        {(userActions?.includes("23") ||
                            userActions?.includes("20")) && (
                                <>
                                    <div className="col-4 table-cell">
                                        Email
                                    </div>
                                    <div className="col-2 table-cell">
                                        Type
                                    </div>
                                    <div className="col-2 table-cell">
                                        Actions
                                    </div>
                                </>
                            )
                        }
                        {!(userActions?.includes("23") ||
                            userActions?.includes("20")) && (
                                <>
                                    <div className="col-5 table-cell">
                                        Email
                                    </div>
                                    <div className="col-3 table-cell">
                                        Type
                                    </div>
                                </>
                            )
                        }
                    </div>

                    {connectivityReq?.ConnectivityRequestContacts?.length > 0 ? connectivityReq?.ConnectivityRequestContacts?.map((c, ci) => (
                        <AnalystConnectivityRequestSingleContactRow
                            contact={c}
                            index={ci}
                            key={`single-contact-row-${ci}`}
                            connectivityReq={connectivityReq}
                            parentForceUpdate={forceUpdate}
                            setConnectivityReq={setConnectivityReq}
                            selectedPlans={selectedPlans}
                            otherPlanTypes={otherPlanTypes}
                            carrierContactTypes={carrierContactTypes}
                        />
                    )) : (
                        <div className="no-data-alert">
                            <h6>No Contacts Added!</h6>
                        </div>
                    )}

                    {addContactClicked && (
                        <div className="row table-row">
                            <div className="col-2 table-cell first-cell">
                                <div className="mt-2 d-flex flex-column">
                                    <TextField style={{ width: "80%" }} className="mx-auto" variant="outlined" value={inputFNValue} onChange={handleChangeInputFNValue} placeholder="Write first name here..." />
                                    {saveButtonClicked && firstNameValidation &&
                                        <span className="text-danger mx-auto">
                                            {firstNameValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col-2 table-cell">
                                <div className="mt-2 d-flex flex-column">
                                    <TextField style={{ width: "80%" }} className="mx-auto" variant="outlined" value={inputLNValue} onChange={handleChangeInputLNValue} placeholder="Write last name here..." />
                                    {saveButtonClicked && lastNameValidation &&
                                        <span className="text-danger mx-auto">
                                            {lastNameValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col-4 table-cell">
                                <div className="mt-2 d-flex flex-column">
                                    <TextField style={{ width: "80%" }} className="mx-auto" variant="outlined" value={inputEMValue} onChange={handleChangeInputEMValue} placeholder="Write email here..." />
                                    {saveButtonClicked && emailValidation &&
                                        <span className="text-danger mx-auto">
                                            {emailValidation}
                                        </span>
                                    }

                                </div>
                            </div>
                            <div className="col-2 table-cell">
                                <div className="editable-textfield">
                                    <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                        <Select style={{ textAlign: "left", display: "grid" }} className="text-dropdown" value={inputTypeValue} onChange={handleChangeInputTypeValue}>
                                            {carrierContactTypes?.map((c, ci) => (
                                                <MenuItem value={c}>{c}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {saveButtonClicked && typeValidation &&
                                        <span className="text-danger">
                                            {typeValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col-2 table-cell" style={{ display: "flex", justifyContent: "center" }}>
                                <Button className="icon-btn" color="inherit" onClick={handleSaveAllInputs}>
                                    <svg width="15" height="21" viewBox="0 0 22 21" >
                                        <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
                                    </svg>
                                </Button>
                                <CustomTooltip TransitionComponent={Zoom} title="Cancel">
                                    <Button className="icon-btn" color="inherit" onClick={() => {
                                        setAddContactClicked(false);
                                        setSaveButtonClicked(false);
                                        setInputFNValue("");
                                        setInputLNValue("");
                                        setInputEMValue("");

                                    }}>
                                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                        </svg>
                                    </Button>
                                </CustomTooltip>
                            </div>
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AnalystConnectivityRequestContacts;
