import moment from 'moment';
import React from 'react'

const SingleFeedTransmissionsPlans = ({ plans, ...props }) => {

    return (
        <>
            <div className="" style={{ minWidth: '900px' }}>
                <div className="row table-header" style={{ position: 'sticky', top: -9, zIndex: 2 }}>
                    <div className="col table-cell">
                        Benefit Name
                    </div>
                    <div className="col table-cell">
                        Benefit Type
                    </div>
                    <div className="col table-cell">
                        Benefit Start Date
                    </div>
                    <div className="col table-cell">
                        Benefit Status
                    </div>
                </div>

                <div
                    style={{
                        overflowX: 'hidden',
                        maxHeight: plans.length > 7 ? 300 : 'auto',
                        marginRight: plans.length > 7 ? -17 : 0,
                    }}>
                    {plans?.length > 0 && plans.map((plan, planIndex) => {
                        const { id, isExported, name, startDate, type } = plan;
                        return (
                            <div className="row table-row" key={`plan-${planIndex}-${id}`}>
                                <div className="col table-cell first">{name}</div>
                                <div className="col table-cell">{type}</div>
                                <div className="col table-cell">
                                    {(startDate == null || startDate == undefined) ? '' : moment(startDate).format('MM/DD/YYYY')}
                                </div>
                                <div className="col table-cell last">
                                    <div className={`${isExported ? 'lightgreen' : 'grey1'} background status-div`} style={{ display: "flex", justifySelf: "center" }}>
                                        {isExported ? 'Included' : 'Excluded'}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>

                {plans.length == 0 &&
                    <div className="no-data-alert">
                        <h6>No plans Found!</h6>
                    </div>
                }

            </div>
        </>
    )
}

export default SingleFeedTransmissionsPlans
