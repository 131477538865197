import React from 'react';
import Avatar from '@mui/material/Avatar';

function UserAvatar({ user }) {
    const getInitials = (firstName, lastName) => {
        if (!firstName || !lastName) return '';
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    };

    const initials = getInitials(user['custom:FirstName'], user['custom:LastName']);

    return (
        <div className="header-user-avatar">
            {initials}
        </div>
    );
};

export default UserAvatar;
