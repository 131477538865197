import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import { Button, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import * as APIs from "../../../Data/APIs";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { SuccessModal } from "../../Modals/SuccessModal";
import { FailureModal } from "../../Modals/FailureModal";
import { useLoader } from "../../Layout/Loader";
import { useAuthentication } from "../../../Data/Authentication";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import editPenIcon from "../../../Icons/editPen.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import checkboxIcon from "../../../Icons/Checkbox.svg";
import deleteForeverSVG from "../../../Icons/trash-xmark.svg";
import saveIconSVG from "../../../Icons/save.svg";

export let emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

function ViewGroupRequestClientContactsRow({
    contact,
    index,
    groupReq,
    setGroupReq,
    parentForceUpdate,
    ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const [editContactClicked, setEditContactClicked] = React.useState(false);
    const [saveButtonClicked, setSaveButtonClicked] = React.useState(false);

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    // validations
    const [firstNameValidation, setFirstNameValidation] = React.useState('');
    const [lastNameValidation, setLastNameValidation] = React.useState('');
    const [emailValidation, setEmailValidation] = React.useState('');


    // Validation UseEffects
    // First Name Validation
    React.useEffect(() => {
        if (!contact.GroupRequestContactFirstName) setFirstNameValidation("This Field is Required");
        else setFirstNameValidation("");
    }, [contact.GroupRequestContactFirstName, firstNameValidation])

    // Last Name Validation
    React.useEffect(() => {
        if (!contact.GroupRequestContactLastName) setLastNameValidation("This Field is Required");
        else setLastNameValidation("");
    }, [contact.GroupRequestContactLastName, lastNameValidation])

    // Email Validation
    React.useEffect(() => {
        if (!contact.GroupRequestContactEmail) {
            setEmailValidation("This Field is Required");
        } else if (!emailPattern.test(contact.GroupRequestContactEmail)) {
            setEmailValidation("Invalid Email Format");
        }
        else setEmailValidation("");
    }, [contact.GroupRequestContactEmail, emailValidation])
    // // Validation UseEffects

    React.useEffect(() => {
        if (!groupReq) return;
        groupReq.GroupRequestContacts = groupReq.GroupRequestContacts ?? [];
    }, [groupReq, JSON.stringify(groupReq?.GroupRequestContacts)]);


    // to re-get Group request data on (save , cancel , delete)
    const reGetGroupRequestData = () => {
        openLoader();
        APIs.getGroupRequestData(groupReq?.groupRequest?.GroupRequestID).then((s) => {
            setGroupReq({
                groupRequest: s?.data?.GroupRequest,
                GroupRequestContacts: s?.data?.GroupRequestContacts,
                connectivities: s?.data?.connectivities
            }
            ); setEditContactClicked(false); closeLoader();
        })
    }

    const handleEditedInputs = () => {
        setSaveButtonClicked(true)
        if (firstNameValidation || lastNameValidation || emailValidation) return;
        openLoader();
        groupReq.GroupRequestContacts[index].isDelete = false;
        let updatedContacts = {};

        updatedContacts = {
            GroupRequestID: groupReq?.groupRequest?.GroupRequestID,
            ClientsContacts: groupReq.GroupRequestContacts,
        };

        APIs.updateGroupRequestContacts(updatedContacts).then(r => {
            reGetGroupRequestData()
        });
    }

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const handleDeleteContactConfirm = () => {
        openModal(0, handleDeleteContact, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this Client contact?`,
        })
    }

    const handleDeleteContact = () => {
        openLoader();
        contact.isDelete = true;

        let deleteContacts = {};

        deleteContacts = {
            GroupRequestID: groupReq?.groupRequest?.GroupRequestID,
            ClientsContacts: [contact],
        };

        APIs.updateGroupRequestContacts(deleteContacts).then(r => {
            reGetGroupRequestData()
        });
    }


    return (
        <div className="row table-row">
            <div className="col-3 table-cell first-cell">
                {editContactClicked ? (
                    <div className="d-flex flex-column align-items-center">
                        <TextField style={{ width: "80%" }} variant="outlined" value={contact.GroupRequestContactFirstName ?? ""} onChange={(e) => { contact.GroupRequestContactFirstName = e.target.value; parentForceUpdate(); }} placeholder="Write first name here..." />
                        {saveButtonClicked && firstNameValidation &&
                            <span className="text-danger">
                                {firstNameValidation}
                            </span>
                        }

                    </div>
                ) : (
                    contact.GroupRequestContactFirstName
                )}
            </div>
            <div className="col-3 table-cell">
                {editContactClicked ? (
                    <div className="d-flex flex-column align-items-center">
                        <TextField style={{ width: "80%" }} variant="outlined" value={contact.GroupRequestContactLastName ?? ""} onChange={(e) => { contact.GroupRequestContactLastName = e.target.value; parentForceUpdate(); }} placeholder="Write last name here..." />
                        {saveButtonClicked && lastNameValidation &&
                            <span className="text-danger">
                                {lastNameValidation}
                            </span>
                        }
                    </div>
                ) : (
                    contact.GroupRequestContactLastName
                )}
            </div>

            <div className="col-2 table-cell" style={{ wordBreak: "break-all" }}>

                {editContactClicked ? (
                    <div className="d-flex flex-column align-items-center">
                        <TextField style={{ width: "80%" }}
                            type="email"
                            variant="outlined"
                            value={contact.GroupRequestContactEmail ?? ""}
                            onChange={(e) => { contact.GroupRequestContactEmail = e.target.value; parentForceUpdate(); }}
                            placeholder="Write last name here..."
                        />
                        {saveButtonClicked && emailValidation &&
                            <span className="text-danger">
                                {emailValidation}
                            </span>
                        }
                    </div>

                ) : (
                    contact.GroupRequestContactEmail
                )}
            </div>

            <div className="col-2 table-cell">

                {editContactClicked ? (

                    <div className="d-flex flex-column align-items-center">
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={contact.ReceiveNotifications}
                            value={contact.ReceiveNotifications}
                            onChange={() => {
                                contact.ReceiveNotifications = !contact.ReceiveNotifications;
                                parentForceUpdate();
                            }}
                            checkedIcon={<DoneOutlineIcon />}
                            color="default"
                        />
                    </div>

                ) : (
                    contact.ReceiveNotifications ?
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={contact.ReceiveNotifications}
                            checkedIcon={<DoneOutlineIcon />}
                            color="default"
                            onChange={e => e.preventDefault()}
                            className="mx-auto"
                        /> : <></>
                )}
            </div>

            <div className="col-2 table-cell">
                {editContactClicked ? (
                    <div style={{ marginLeft: "-10px" }}>
                        <div className="d-flex" style={{ justifyContent: "center" }}>
                            <Button className="icon-btn" color="inherit" onClick={handleEditedInputs}>
                                <svg width="15" height="21" viewBox="0 0 22 21" >
                                    <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
                                </svg>
                            </Button>
                            <CustomTooltip TransitionComponent={Zoom} title="Cancel">
                                <Button className="pl-0 icon-btn" color="inherit" onClick={reGetGroupRequestData}>
                                    <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                        <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                    </svg>
                                </Button>
                            </CustomTooltip>
                        </div>
                        <CustomTooltip TransitionComponent={Zoom} title="Delete">
                            <span>
                                <Button className="icon-btn" color="inherit" onClick={handleDeleteContactConfirm}>
                                    <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                    </svg>
                                    {/* <DeleteForeverIcon style={{ fontSize: 30 }} /> */}
                                </Button>
                            </span>
                        </CustomTooltip>
                    </div>
                ) : (
                    <Button onClick={() => setEditContactClicked(true)} color="inherit">
                        <svg width="20" height="20" viewBox="0 0 20 20" >
                            <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                        </svg>
                    </Button>
                )}
            </div>

        </div>
    )
}

export default ViewGroupRequestClientContactsRow;
