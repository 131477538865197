import React from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Divider, IconButton } from "@material-ui/core";
import deleteForeverSVG from "../../Icons/trash-xmark.svg";


function FileRow({
    file,
    index,
    uploadedFiles,
    setUploadedFiles,
    onDeleteFiles,
    ...props
}) {


    const handleDeleteFile = (index) => {
        uploadedFiles.splice(index, 1);
        uploadedFiles = [...(uploadedFiles ?? [])];
        setUploadedFiles(uploadedFiles);
        onDeleteFiles();
    }

    return (
        <div className="single-file-div">
            {/* <div className="col-1">
                <div >{file?.Extension?.toUpperCase()}</div>
            </div> */}
            <div className="col-3">
                <div >{file?.Title}</div>
            </div>
            <div className="col-8 " >
                {file?.Name}
            </div>
            <div className="">
                {/* <DeleteForeverIcon  color="action" style={{ fontSize: "30px", cursor: "pointer" }} /> */}
                <svg  width="21" height="24" viewBox="0 0 21 24" onClick={() => handleDeleteFile(index)}   style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
            </div>
        </div>
    )
}

export default FileRow;