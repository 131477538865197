import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SelectCarrierPlansInternal from "./SelectCarrierPlansInternal";
import SelectSingleConnectivityRequestType from "./SelectSingleConnectivityRequestType";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabsTitlesWithMoreDD from "../../Layout/TabsTitlesWithMoreDD";

function SelectConnectivityType({ groupReq, isPEOClicked, selectedOtherCarriers, selectCRTypeValidationForm, requestSubmitted, requestSaved, parentForceUpdate, carriers, setSelectedCarriers, selectedCarriers, subTypeList, setSubTypeList, formsSubTypeList, setFormsSubTypeList, selectCRHREmailValidationForm, attachFormsValidationForm, ...props }) {

    const [expanded, setExpanded] = React.useState("SelectCRType");
    const [tabValue, setTabValue] = React.useState(0);
    const [tabDefaultValue, setTabDefaultValue] = React.useState("[Other Carrier Name]")

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };



    // if selectedTab removed from selected Carriers list and it was last item
    const handleTabValue = () => {
        let otherCarrierInCasePEOLength = (selectedOtherCarriers?.selectedOtherCarriersFullArray?.length ?? 0)
        if (tabValue >= (selectedCarriers?.length + otherCarrierInCasePEOLength)) {
            let totalLength = (selectedCarriers.length + otherCarrierInCasePEOLength)
            if (totalLength == 0) {
                return (0)
            } else {
                return (selectedCarriers.length - 1 + otherCarrierInCasePEOLength)
            }
        } else {
            return (tabValue)
        }
    }


    React.useEffect(() => {
        setTabValue(handleTabValue())
    }, [selectedCarriers, tabValue, selectedOtherCarriers?.selectedOtherCarriersFullArray])

    return (
        <Accordion expanded={expanded === `SelectCRType`}>
            <AccordionSummary
                onClick={() => expanded === `SelectCRType` ? setExpanded("") : setExpanded("SelectCRType")}
                // className="pl-0 pr-0"
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="expand-carrier-contacts"
            >
                {/* <Typography className="title-typography">Connectivity Requests Type</Typography> */}
                <span>Connectivity Requests Type</span>
            </AccordionSummary>
            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
            <div className="col-xl-10 mt-2 mb-3 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectCRTypeValidationForm}</div>
            <div className="col-xl-10 mb-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectCRHREmailValidationForm}</div>
            <AccordionDetails>
                <div className="w-100">
                    <div className="border-bottom">
                        <TabsTitlesWithMoreDD
                            tabTitle={[
                                ...(selectedCarriers?.map(sc => isPEOClicked ? sc?.CarrierName : sc?.carrierName) || []),
                                ...(selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(s => s?.Name ?? tabDefaultValue) || [])
                            ]}
                            value={handleTabValue()}
                            setValue={setTabValue}
                        />
                    </div>

                    {/* <AppBar position="static" color="default">
                        <div className="row">
                            <Tabs
                                className="tab-style w-100"
                                value={handleTabValue()}
                                onChange={handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs"
                            >
                                {selectedCarriers?.map((sc, sci) => (
                                    <Tab key={`carrier-contacts-tab-${sci}`} label={isPEOClicked ? sc?.CarrierName : sc?.carrierName} />
                                ))}
                                {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                                    <Tab
                                        key={`tp-tab-${si}`}
                                        label={s?.Name ?? tabDefaultValue}
                                    />
                                ))}
                            </Tabs>
                        </div>
                    </AppBar> */}
                    {selectedCarriers?.map((s, si) => (
                        si === tabValue && (
                            <SelectSingleConnectivityRequestType
                                carrier={s}
                                index={si}
                                key={`single-carrier-plans-${si}`}
                                subTypeList={subTypeList}
                                setSubTypeList={setSubTypeList}
                                formsSubTypeList={formsSubTypeList}
                                setFormsSubTypeList={setFormsSubTypeList}
                                selectCRHREmailValidationForm={selectCRHREmailValidationForm}
                                requestSubmitted={requestSubmitted}
                                parentForceUpdate={parentForceUpdate}
                                isPEOClicked={isPEOClicked}
                                attachFormsValidationForm={attachFormsValidationForm}
                                selectedCarriers={selectedCarriers}
                                requestSaved={requestSaved}
                            />
                        )
                    ))}
                    {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                        si + selectedCarriers.length === tabValue && (
                            <SelectSingleConnectivityRequestType
                                carrier={s}
                                index={si}
                                key={`single-carrier-plans-${si}`}
                                subTypeList={subTypeList}
                                setSubTypeList={setSubTypeList}
                                formsSubTypeList={formsSubTypeList}
                                setFormsSubTypeList={setFormsSubTypeList}
                                selectCRHREmailValidationForm={selectCRHREmailValidationForm}
                                requestSubmitted={requestSubmitted}
                                parentForceUpdate={parentForceUpdate}
                                isPEOClicked={isPEOClicked}
                                attachFormsValidationForm={attachFormsValidationForm}
                                selectedCarriers={selectedCarriers}
                                requestSaved={requestSaved}
                            />
                        )
                    ))}
                </div >
            </AccordionDetails>
        </Accordion>
    )
}

export default SelectConnectivityType;
