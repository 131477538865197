import React from "react";
import { Divider, Paper, Select, TextField, Button, SvgIcon, IconButton, makeStyles } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import RepeatIcon from '@material-ui/icons/Repeat';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
import Moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import calendarClockIcon from "../../../../Icons/calendar-clock.svg";
import clockIcon from "../../../../Icons/clock.svg";
import repeatIcon from "../../../../Icons/arrows-repeat.svg";
import calendarRangeSchedulerSVG from "../../../../Icons/calendar-range-scheduler.svg";
import arrowsRepeatSchedulerSVG from "../../../../Icons/arrows-repeat-scheduler.svg";
import clockSchedulerSVG from "../../../../Icons/clock-scheduler.svg";
import calendarSchedulerDateSVG from "../../../../Icons/calendar-scheduler-date.svg";
import clockSchedulerTimeSVG from "../../../../Icons/clock-scheduler-time.svg";

let currentTime = new Date().getTime();
currentTime = Moment(currentTime).format('YYYY-MM-DDTHH:mm:ss');

let currentDate = new Date();
currentDate = Moment(currentDate).format('MM/DD/YYYY');

function AnalystCreateSchedulerConfiguration({
    index,
    scheduler,
    feedSchedulers,
    onSetFeedSchedulers,
    requestSubmitted,
    onEdit = false,
    onHandelDeleteScheduler,
    feedStatusID,
    ...props
}) {


    const dialog = useModalDialog(0);
    const [checkCustomWeekdays, setCheckCustomWeekdays] = React.useState(true);
    const [checkAllWeekdays, setCheckAllWeekdays] = React.useState(false);
    const [checkWeekendOnly, setCheckWeekendOnly] = React.useState(false);
    const [checkAllWeekdaysNoWeekend, setCheckAllWeekdaysNoWeekend] = React.useState(false);
    const [expanded, setExpanded] = React.useState("");
    //validations
    const [schedulerFrequencyValidationForm, setSchedulerFrequencyValidationForm] = React.useState("")
    const [schedulerDaysValidationForm, setSchedulerDaysValidationForm] = React.useState("")
    const [schedulerMonthlyTypeValidationForm, setSchedulerMonthlyTypeValidationForm] = React.useState()
    const [schedulerMonthlyPartValidationForm, setSchedulerMonthlyPartValidationForm] = React.useState()
    const [schedulerMonthlyDayInMonthValidationForm, setSchedulerMonthlyDayInMonthValidationForm] = React.useState("")
    const [schedulerMonthlyWeekdayInMonthValidationForm, setSchedulerMonthlyWeekdayInMonthValidationForm] = React.useState("")

    const allWeekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const weekendOnly = ["Saturday", "Sunday"];
    const allWeekdaysNoWeekend = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    const monthlyWeekParts = ["First", "Second", "Third", "Fourth", "Fifth", "Last"];


    React.useEffect(() => {
        if (!scheduler?.Type) setSchedulerFrequencyValidationForm("Scheduler frequency is missing, please select one."); else setSchedulerFrequencyValidationForm("")
    }, [scheduler?.Type, schedulerFrequencyValidationForm])


    React.useEffect(() => {
        if (scheduler?.Type === "WeeklyScheduler" && scheduler?.WeekDay.length <= 0) setSchedulerDaysValidationForm("No days were selected, please select at least one day."); else setSchedulerDaysValidationForm("")
    }, [scheduler?.Type, scheduler?.WeekDay, schedulerDaysValidationForm])

    React.useEffect(() => {
        if (scheduler?.Type === "MonthlyScheduler" && !scheduler?.MonthlyType) setSchedulerMonthlyTypeValidationForm("No monthly recurrence was selected, please select one."); else setSchedulerMonthlyTypeValidationForm("")
    }, [scheduler?.Type, scheduler?.MonthlyType, schedulerMonthlyTypeValidationForm])

    React.useEffect(() => {
        if (scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 1 && !scheduler?.DayOfMonth) setSchedulerMonthlyDayInMonthValidationForm("Day is missing, please enter a specific day."); else setSchedulerMonthlyDayInMonthValidationForm("")
    }, [scheduler?.Type, scheduler?.MonthlyType, scheduler?.DayOfMonth, schedulerMonthlyDayInMonthValidationForm])

    React.useEffect(() => {
        if (scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 2 && !scheduler?.WeekDay) setSchedulerMonthlyWeekdayInMonthValidationForm("Weekday is missing, please select one."); else setSchedulerMonthlyWeekdayInMonthValidationForm("")
    }, [scheduler?.Type, scheduler?.MonthlyType, scheduler?.WeekDay, schedulerMonthlyWeekdayInMonthValidationForm])

    React.useEffect(() => {
        if (scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 2 && !scheduler?.PartType) setSchedulerMonthlyPartValidationForm("Week part is missing, please select one."); else setSchedulerMonthlyPartValidationForm("")
    }, [scheduler, scheduler?.Type, scheduler?.MonthlyType, scheduler?.PartType, schedulerMonthlyPartValidationForm])

    React.useEffect(() => {
        if (checkAllWeekdays) {
            let newArr = [...feedSchedulers];
            newArr[index].WeekDay = allWeekdays;
            onSetFeedSchedulers(newArr);
        }
        else if (checkWeekendOnly) {
            let newArr = [...feedSchedulers];
            newArr[index].WeekDay = weekendOnly;
            onSetFeedSchedulers(newArr);
        }
        else if (checkAllWeekdaysNoWeekend) {
            let newArr = [...feedSchedulers];
            newArr[index].WeekDay = allWeekdaysNoWeekend;
            onSetFeedSchedulers(newArr);
        }
        else { setCheckCustomWeekdays(true); }
    }, [checkAllWeekdays, checkWeekendOnly, checkAllWeekdaysNoWeekend, checkCustomWeekdays])

    React.useEffect(() => {
        if (scheduler?.WeekDay?.length === weekendOnly.length && weekendOnly.every(item => scheduler?.WeekDay?.indexOf(item) > -1)) {
            setCheckWeekendOnly(true); setCheckAllWeekdays(false); setCheckAllWeekdaysNoWeekend(false); setCheckCustomWeekdays(false);
        }
        else if (scheduler?.WeekDay?.length === allWeekdays.length && allWeekdays.every(item => scheduler?.WeekDay?.indexOf(item) > -1)) {
            setCheckWeekendOnly(false); setCheckAllWeekdays(true); setCheckAllWeekdaysNoWeekend(false); setCheckCustomWeekdays(false);
        }
        else if (scheduler?.WeekDay?.length === allWeekdaysNoWeekend.length && allWeekdaysNoWeekend.every(item => scheduler?.WeekDay?.indexOf(item) > -1)) {
            setCheckWeekendOnly(false); setCheckAllWeekdays(false); setCheckAllWeekdaysNoWeekend(true); setCheckCustomWeekdays(false);
        }
        else {
            setCheckCustomWeekdays(true); setCheckWeekendOnly(false); setCheckAllWeekdays(false); setCheckAllWeekdaysNoWeekend(false);
        }
    }, [scheduler?.WeekDay])


    const handleCheckEnableScheduleChange = () => {
        let newArr = [...feedSchedulers];
        newArr[index].Enabled = !newArr[index].Enabled;
        onSetFeedSchedulers(newArr);
    };

    const handleSchedulerFrequencyChange = (e, value) => {
        let newArr = [...feedSchedulers];
        newArr[index].Type = value;
        newArr[index].Recurrence = 1;
        newArr[index].WeekDay = [];
        onSetFeedSchedulers(newArr);

    }

    const handleSchedulerDateChange = (date) => {
        let newArr = [...feedSchedulers];
        newArr[index].StartDate = Moment(date).format('YYYY-MM-DD');
        onSetFeedSchedulers(newArr);
    };

    const handleSchedulerTimeChange = (time) => {
        let newArr = [...feedSchedulers];
        newArr[index].StartTime = Moment(time).format("HH:mm");
        newArr[index].StartDateTime = `${scheduler?.StartDate}T${Moment(time).format("HH:mm")}`;
        onSetFeedSchedulers(newArr);
    };

    React.useEffect(() => {
        if (scheduler?.Type === "MonthlyScheduler") {
            let newArr = [...feedSchedulers];
            newArr[index].Recurrence = 1;
            onSetFeedSchedulers(newArr);
        }
    }, [scheduler?.Type])

    const handleDaysSelectedChange = (e, value) => {
        let newArr = [...feedSchedulers];
        let selectedDays = newArr[index].WeekDay;
        if (!value || value === "") selectedDays = [];
        else if (selectedDays.indexOf(value) >= 0)
            selectedDays.splice(selectedDays.indexOf(value), 1);
        else selectedDays.push(value);
        newArr[index].WeekDay = [...selectedDays];
        onSetFeedSchedulers(newArr);
    }


    const handleWeeklyChange = () => {
        let newArr = [...feedSchedulers];
        newArr[index].Recurrence = 1;
        onSetFeedSchedulers(newArr);

    }

    const handleBiweeklyChange = () => {
        let newArr = [...feedSchedulers];
        newArr[index].Recurrence = 2;
        onSetFeedSchedulers(newArr);

    }

    const handleCustomWeekdaysChange = () => {
        let newArr = [...feedSchedulers];
        newArr[index].WeekDay = [];
        onSetFeedSchedulers(newArr);
        setCheckCustomWeekdays(true);
        setCheckAllWeekdays(false);
        setCheckWeekendOnly(false);
        setCheckAllWeekdaysNoWeekend(false);
    }

    const handleAllWeekdaysChange = () => {
        setCheckAllWeekdays(!checkAllWeekdays);
        setCheckWeekendOnly(false);
        setCheckAllWeekdaysNoWeekend(false);
        setCheckCustomWeekdays(false);
    }

    const handleWeekendOnlyChange = () => {
        setCheckWeekendOnly(!checkWeekendOnly);
        setCheckAllWeekdays(false);
        setCheckAllWeekdaysNoWeekend(false);
        setCheckCustomWeekdays(false);
    }

    const handleAllWeekdaysNoWeekendChange = () => {
        setCheckAllWeekdaysNoWeekend(!checkAllWeekdaysNoWeekend);
        setCheckAllWeekdays(false);
        setCheckWeekendOnly(false);
        setCheckCustomWeekdays(false);
    }


    const handleCheckDayInMonthChange = () => {
        let newArr = [...feedSchedulers];
        newArr[index].DayOfMonth = null;
        newArr[index].WeekDay = null;
        newArr[index].MonthlyType = 1;
        newArr[index].PartType = 1;
        onSetFeedSchedulers(newArr);

    }

    const handleCheckWeekdayInMonthChange = () => {
        let newArr = [...feedSchedulers];
        newArr[index].WeekDay = null;
        newArr[index].MonthlyType = 2;
        newArr[index].PartType = "";
        onSetFeedSchedulers(newArr);

    }

    const handleweekPartInMonthChange = (e) => {
        let newArr = [...feedSchedulers];
        newArr[index].PartType = e.target.value;
        onSetFeedSchedulers(newArr);
    }

    const handleWeekdayInMonthChange = (e) => {
        let newArr = [...feedSchedulers];
        newArr[index].WeekDay = e.target.value;
        onSetFeedSchedulers(newArr);
    }

    const handleDayInMonthChange = (e) => {
        let newArr = [...feedSchedulers];
        newArr[index].DayOfMonth = e.target.value;
        onSetFeedSchedulers(newArr);
    }
    const handelDeleteScheduler = (e) => {
        onHandelDeleteScheduler(scheduler);
    }

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }


    const handleDeleteSchedulerModal = () => {
        if (onEdit) {
            openModal(0, handelDeleteScheduler, {
                title: 'Please Confirm',
                question: `Are you sure you want to delete this scheduler?`,
            });

        } else {
            handelDeleteScheduler();
        }
    }

    const CustomKeyboardDateButton = (props) => (
        <IconButton {...props}>
            <SvgIcon component="img" src={calendarSchedulerDateSVG} />
        </IconButton>
    );

    const CustomKeyboardTimeButton = (props) => (
        <IconButton {...props}>
            <SvgIcon component="img" src={clockSchedulerTimeSVG} />
        </IconButton>
    );

    const useStyles = makeStyles({
        hideDefaultDateIcon: {
            '& .MuiIconButton-root': {
                visibility: 'hidden',
            },
            '& .MuiIconButton-root::after': {
                content: `url(${calendarSchedulerDateSVG})`,
                visibility: 'visible',
                position: 'absolute',
                right: 0,
            },
        },
        hideDefaultTimeIcon: {
            '& .MuiIconButton-root': {
                visibility: 'hidden',
            },
            '& .MuiIconButton-root::after': {
                content: `url(${clockSchedulerTimeSVG})`,
                visibility: 'visible',
                position: 'absolute',
                right: 0,
            },
        },
    });

    const classes = useStyles();

    return (
        <div id="create-scheduler" key={`scheduler-number-${index}`} className="w-100">
            <div className="mt-3 text-danger mb-3" hidden={!requestSubmitted}>{schedulerFrequencyValidationForm}</div>

            {scheduler?.Type === "WeeklyScheduler" && (
                <>
                    <div className="mt-3 text-danger mb-3" hidden={!requestSubmitted}>{schedulerDaysValidationForm}</div>
                </>
            )}

            {scheduler?.Type === "MonthlyScheduler" && (
                <>
                    <div className="mt-3 text-danger mb-3" hidden={!requestSubmitted}>{schedulerMonthlyTypeValidationForm}</div>
                </>
            )}

            {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 1 && (
                <>
                    <div className="mt-3 text-danger mb-3" hidden={!requestSubmitted}>{schedulerMonthlyDayInMonthValidationForm}</div>
                </>
            )}

            {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 2 && (
                <>
                    <div className="mt-3 text-danger" hidden={!requestSubmitted}>{schedulerMonthlyPartValidationForm}</div>
                    <div className="text-danger mb-3" hidden={!requestSubmitted}>{schedulerMonthlyWeekdayInMonthValidationForm}</div>
                </>
            )}
            <Accordion className="scheduler-feed mt-2" expanded={expanded === `SelectScheduler`} id={`scheduler-num-${index}`}>

                <AccordionSummary
                    onClick={() => expanded === `SelectScheduler` ? setExpanded("") : setExpanded("SelectScheduler")}
                    className="pl-0 pr-0"
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="expand-SelectScheduler"
                >
                    <div>
                        <svg width="28" height="25" viewBox="0 0 28 25" className="color pink-primary-color mr-2" >
                            <use xlinkHref={`${calendarClockIcon}#calendar-clock`}></use>
                        </svg>
                        Occurs
                        {scheduler?.Recurrence == 2 ? " biweekly " : scheduler?.Type === "WeeklyScheduler" ? " every week " : scheduler?.Type === "MonthlyScheduler" ? " every month " : "..."}
                        on &nbsp;
                        {scheduler?.Type === null && "..."}
                        {scheduler?.Type === "WeeklyScheduler" && (checkWeekendOnly ? "weekends only " : checkAllWeekdaysNoWeekend ? "weekdays only " : checkAllWeekdays ? "all days " : scheduler?.WeekDay?.length > 0 ? scheduler?.WeekDay?.map((d => `${d} `)).join(", ") : "...")}
                        {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 1 && (scheduler?.DayOfMonth ? `day ${scheduler?.DayOfMonth}  of each month ` : "...")}
                        {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 2 && (scheduler?.PartType ? `the  ${scheduler?.PartType} ` : "...")}
                        {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 2 && (scheduler?.WeekDay ? ` ${scheduler?.WeekDay === "WeekDay" ? "Weekday" : scheduler?.WeekDay === "WeekEndDay" ? "Weekend" : scheduler?.WeekDay}  of each month ` : '...')}
                        at &nbsp;
                        {scheduler?.StartDateTime ? Moment(scheduler?.StartDateTime).format('HH:mm') : "..."}
                        &nbsp; Schedule will be used starting on &nbsp;
                        {scheduler?.StartDate ? Moment(scheduler?.StartDate).format('MM/DD/YYYY') : "..."}  &nbsp;
                        ({scheduler?.Enabled ? 'Enabled' : (feedStatusID === 8 ? 'Disabled Due To OE' : 'Disabled')})
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div className="mb-4 d-inline-flex">
                            <div>
                                <span className="fw-400">Enable this schedule</span>
                                <Switch checked={scheduler?.Enabled} onClick={handleCheckEnableScheduleChange} />
                            </div>
                            <div>
                                {feedSchedulers.length > 1 &&
                                    <Button variant="contained" className="blue-outlined-btn mr-2 ml-auto" onClick={handleDeleteSchedulerModal}>
                                        <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                        </svg>
                                        Remove Scheduler
                                    </Button>
                                }
                            </div>
                        </div>
                        {scheduler?.Enabled && (
                            <div id="scheduler-frequency">
                                <div className="row text-center">
                                    <div className="col-xl-3 pl-3 pr-3">
                                        <h6 className="black1 fw-500 fs-16">Frequency</h6>
                                    </div>
                                    <div className="col-xl-5 pl-3 pr-3">
                                        <h6 className="black1 fw-500 fs-16">Recurrence</h6>
                                    </div>
                                    <div className="col-xl-4 pl-3 pr-3">
                                        <h6 className="black1 fw-500 fs-16">Duration (Date &amp; Time)</h6>
                                    </div>
                                </div>
                                <div className="row mt-2 text-center">
                                    <div className="col-xl-3 pr-3 pl-3">
                                        <div className="scheduler-info-div">
                                            <div style={{ flex: "1", textAlignLast: "left" }}>
                                                <svg width="32" height="32" viewBox="0 0 22 22">
                                                    <use xlinkHref={`${calendarRangeSchedulerSVG}#calendarRangeScheduler`}></use>
                                                </svg>
                                                <span>Occurs</span>
                                            </div>
                                            <div style={{ flex: "2", textAlignLast: "right" }}>
                                                <Chip className="ml-2 scheduler-chip" label={scheduler?.Recurrence == 2 ? "Biweekly" : scheduler?.Type === "WeeklyScheduler" ? "Weekly" : scheduler?.Type === "MonthlyScheduler" ? "Monthly" : "..."} variant="outlined" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-5 pr-3 pl-3">
                                        <div className="scheduler-info-div">
                                            <div style={{ flex: "1", textAlignLast: "left" }}>
                                                <svg width="32" height="32" viewBox="0 0 22 22">
                                                    <use xlinkHref={`${arrowsRepeatSchedulerSVG}#arrowsRepeatScheduler`}></use>
                                                </svg>
                                                <span>Recurs</span>
                                            </div>
                                            <div style={{ flex: "3", textAlignLast: "right" }}>
                                                {scheduler?.Type === "WeeklyScheduler" && (
                                                    <>
                                                        <Chip className="scheduler-chip" label={
                                                            scheduler?.WeekDay?.length === weekendOnly.length && weekendOnly.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "Weekends only" :
                                                                scheduler?.WeekDay?.length === allWeekdaysNoWeekend.length && allWeekdaysNoWeekend.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "Weekdays only" :
                                                                    scheduler?.WeekDay?.length === allWeekdays.length && allWeekdays.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "All days" : scheduler?.WeekDay?.length > 0 ? scheduler?.WeekDay?.map((d => d)).join(", ") : "..."} />
                                                    </>
                                                )}
                                                {scheduler?.Type === "MonthlyScheduler" && (
                                                    <>
                                                        {scheduler?.MonthlyType == 1 && (
                                                            <>
                                                                <span className="ml-2">on day</span>
                                                                <Chip className="ml-2 scheduler-chip" label={scheduler?.DayOfMonth ? scheduler?.DayOfMonth : "..."} variant="outlined" />
                                                                <span className="ml-2">of each month</span>
                                                            </>
                                                        )}
                                                        {scheduler?.MonthlyType == 2 && (
                                                            <>
                                                                <span className="ml-2">on the</span>
                                                                <Chip className="ml-2 scheduler-chip" label={scheduler?.PartType ? scheduler?.PartType : "..."} variant="outlined" />
                                                                <Chip className="ml-2 scheduler-chip" label={scheduler?.WeekDay ? scheduler?.WeekDay : "..."} variant="outlined" />
                                                                <span className="ml-2">of each month</span>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {(scheduler?.Type !== "WeeklyScheduler" && scheduler?.Type !== "MonthlyScheduler") && (
                                                    <Chip className="scheduler-chip ml-2" label="..." />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 pr-3 pl-3">
                                        <div className="scheduler-info-div">
                                            <div style={{ flex: "1", textAlignLast: "left" }}>
                                                <svg width="32" height="32" viewBox="0 0 22 22">
                                                    <use xlinkHref={`${clockSchedulerSVG}#clockScheduler`}></use>
                                                </svg>
                                                <span>Starts on</span>
                                            </div>
                                            <div style={{ flex: "2", textAlignLast: "right" }}>
                                                <Chip className="ml-2 scheduler-chip" label={Moment(scheduler?.StartDate).format("MM/DD/YYYY") !== "Invalid date" ? Moment(scheduler?.StartDate).format("MM/DD/YYYY") : "..."} />
                                                <span className="ml-2">at</span>
                                                <Chip className="ml-2 scheduler-chip" label={Moment(scheduler?.StartDateTime).format("HH:mm") !== "Invalid date" ? Moment(scheduler?.StartDateTime).format("HH:mm") : "..."} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-12" style={{ minHeight: "10px" }}></div>
                                    <div className="col-xl-3 pr-3 pl-3">
                                        <ToggleButtonGroup orientation="vertical" value={scheduler?.Type} exclusive onChange={handleSchedulerFrequencyChange}>
                                            <ToggleButton value="WeeklyScheduler">Weekly</ToggleButton>
                                            <ToggleButton value="MonthlyScheduler">Monthly</ToggleButton>
                                        </ToggleButtonGroup>
                                        {scheduler?.Type === "WeeklyScheduler" && (
                                            <div style={{ textAlignLast: "left" }}>
                                                <FormControlLabel
                                                    className="mt-2"
                                                    control={
                                                        <Switch
                                                            checked={scheduler?.Recurrence == 1}
                                                            onChange={handleWeeklyChange}
                                                        />
                                                    }
                                                    label="Weekly"
                                                />
                                                <br />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={scheduler?.Recurrence == 2}
                                                            onChange={handleBiweeklyChange}
                                                        />
                                                    }
                                                    label="Biweekly"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-xl-5 pr-3 pl-3">
                                        {scheduler?.Type === "WeeklyScheduler" ? (
                                            <div>
                                                <div style={{ textAlignLast: "left" }}>
                                                    <FormControlLabel
                                                        className="mt-2"
                                                        control={
                                                            <Switch
                                                                checked={checkAllWeekdays && !checkWeekendOnly && !checkAllWeekdaysNoWeekend && !checkCustomWeekdays}
                                                                onChange={handleAllWeekdaysChange}
                                                            />
                                                        }
                                                        label="All days"
                                                    />
                                                    <br />
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={checkWeekendOnly && !checkAllWeekdays && !checkAllWeekdaysNoWeekend && !checkCustomWeekdays}
                                                                onChange={handleWeekendOnlyChange}
                                                            />
                                                        }
                                                        label="Weekends only"
                                                    />
                                                    <br />
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={checkAllWeekdaysNoWeekend && !checkAllWeekdays && !checkWeekendOnly && !checkCustomWeekdays}
                                                                onChange={handleAllWeekdaysNoWeekendChange}
                                                            />
                                                        }
                                                        label="Weekdays only"
                                                    />
                                                    <br />
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={checkCustomWeekdays && !checkAllWeekdays && !checkWeekendOnly && !checkAllWeekdaysNoWeekend}
                                                                onChange={handleCustomWeekdaysChange}
                                                            />
                                                        }
                                                        label="Custom days"
                                                    />
                                                </div>
                                                {checkCustomWeekdays && !checkAllWeekdays && !checkWeekendOnly && !checkAllWeekdaysNoWeekend && (
                                                    <ToggleButtonGroup orientation="vertical" value={scheduler?.WeekDay} exclusive onChange={handleDaysSelectedChange}>
                                                        {allWeekdays?.map((w, wi) => (
                                                            <ToggleButton key={`weekdays-${wi}`} value={w}>
                                                                {w}
                                                            </ToggleButton>
                                                        ))}
                                                    </ToggleButtonGroup>
                                                )}
                                            </div>
                                        ) : scheduler?.Type === "MonthlyScheduler" ? (
                                            <div id="monthly-scheduler">
                                                <div style={{ textAlignLast: "left" }}>
                                                    <FormControlLabel
                                                        className="mt-2"
                                                        control={
                                                            <Switch
                                                                checked={scheduler?.MonthlyType == 1}
                                                                onChange={handleCheckDayInMonthChange}
                                                            />
                                                        }
                                                        label="Day in a month"
                                                    />
                                                    <br />
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={scheduler?.MonthlyType == 2}
                                                                onChange={handleCheckWeekdayInMonthChange}
                                                            />
                                                        }
                                                        label="Weekday in a month"
                                                    />
                                                </div>
                                                <div className="row alignvh-center text-left mt-2">
                                                    {scheduler?.MonthlyType == 2 && (
                                                        <>
                                                            <div className="col-xl-3">
                                                                <span>Week</span>
                                                            </div>
                                                            <div className="col-xl-9">
                                                                <FormControl label="Select Week In Month" focused={false} variant="outlined" className="w-100">
                                                                    <Select
                                                                        value={scheduler?.PartType}
                                                                        onChange={handleweekPartInMonthChange}
                                                                    >
                                                                        {monthlyWeekParts.map((w, wi) => (
                                                                            <MenuItem key={`monthly-weeks-${wi}`} value={w}>{w}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </div>

                                                            <div className="col-xl-3 mt-2">
                                                                <span>Week day</span>
                                                            </div>
                                                            <div className="col-xl-9 mt-2">
                                                                <FormControl label="Select Day In Week" focused={false} variant="outlined" className="w-100">
                                                                    <Select
                                                                        value={scheduler?.WeekDay}
                                                                        onChange={handleWeekdayInMonthChange}
                                                                    >
                                                                        {allWeekdays.map((w, wi) => (
                                                                            <MenuItem key={`monthly-days-${wi}`} value={w}>{w}</MenuItem>
                                                                        ))}
                                                                        <MenuItem key="monthly-days-Day" value="Day">Day</MenuItem>
                                                                        <MenuItem key="monthly-days-WeekDay" value="WeekDay">Weekday</MenuItem>
                                                                        <MenuItem key="monthly-days-WeekEndDay" value="WeekEndDay">Weekend</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                        </>
                                                    )}
                                                    {scheduler?.MonthlyType == 1 && (
                                                        <>
                                                            <div className="col-xl-3">
                                                                <span>Day</span>
                                                            </div>
                                                            <div className="col-xl-9">
                                                                <input type="number" min="1" max="31" pattern="[1-31]" className="text-input w-100" value={scheduler?.DayOfMonth ?? ""} onChange={handleDayInMonthChange} />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                Please select a frequency to show recurrence
                                                <Skeleton variant="rect" width="100%" height={100} />
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-xl-4 pr-3 pl-3">
                                        {!scheduler?.Type ? (
                                            <div>
                                                Please select a frequency to show duration
                                                <Skeleton variant="rect" width="100%" height={100} />
                                            </div>
                                        ) : (
                                            <div className="text-left">
                                                <span className="fw-400">Date</span>
                                                <div className="date-div w-100 mb-3">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            autoOk={true}
                                                            disableToolbar
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="frequency-date"
                                                            className={`w-100 mt-0 ${classes.hideDefaultDateIcon}`}
                                                            value={scheduler?.StartDate}
                                                            onChange={handleSchedulerDateChange}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <span className="fw-400">Time</span>
                                                <div className="date-div w-100">
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardTimePicker
                                                            margin="normal"
                                                            id="frequency-time"
                                                            className={`w-100 mt-0 ${classes.hideDefaultTimeIcon}`}
                                                            value={scheduler?.StartDateTime}
                                                            format="HH:mm"
                                                            onChange={handleSchedulerTimeChange}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AnalystCreateSchedulerConfiguration;