import React, { useEffect } from "react";
import { Divider } from "@material-ui/core";
import * as APIs from "../../../Data/APIs";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Backdrop, CircularProgress, Button } from "@material-ui/core";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";


function MapInternalCarrier({ iSolvedCarrier, forceCarrierListUpdate, ...props }) {

    const [internalCarriers, setInternalCarriers] = React.useState([]);
    const [selectedInternalCarrierObject, setSelectedInternalCarrierObject] = React.useState({
        CarrierInternalName: iSolvedCarrier?.CarrierInternalName,
        isOtherChecked: false,
        CarrierID: iSolvedCarrier?.CarrierID
    });

    const dialog = useModalDialog(0);

    React.useEffect(() => {
        APIs.getCarriers({}).then(r => setInternalCarriers(r?.data));
    }, [])

    const handleSelectedInternalCarrierChange = (e) => {

        if (selectedInternalCarrierObject.CarrierInternalName === "Other") {
            iSolvedCarrier.CarrierID = null;
            iSolvedCarrier.isOtherChecked = true;
            iSolvedCarrier.CarrierInternalName = "Other";
        } else {
            iSolvedCarrier.CarrierInternalName = selectedInternalCarrierObject?.CarrierName;
            iSolvedCarrier.CarrierID = parseInt(selectedInternalCarrierObject?.CarrierID);
            iSolvedCarrier.isOtherChecked = false;
        }
        if (forceCarrierListUpdate) forceCarrierListUpdate();
    };

    return (
        <div id="last-sent-file-modal">

            {internalCarriers?.length > 0 ?
                <FormControl component="fieldset">
                    <RadioGroup aria-label="internalCarrier" name="internalCarrier" className="internalCarrier-radio-group" value={iSolvedCarrier?.CarrierID ?? ""}>
                        {internalCarriers.map((c, ci) => (
                            <FormControlLabel key={`internal-carrier-${ci}`} value={c?.CarrierID} control={
                                <Radio onClick={(e) => {
                                    if (c?.CarrierInternalName !== "Other") {
                                        setSelectedInternalCarrierObject(c)
                                    } else {
                                        setSelectedInternalCarrierObject({
                                            CarrierInternalName: 'Other',
                                            isOtherChecked: true,
                                            CarrierID: null
                                        })
                                    }
                                }
                                }
                                    checked={iSolvedCarrier?.CarrierInternalName !== "Other" && (selectedInternalCarrierObject?.CarrierID === c?.CarrierID)} />
                            }
                                label={c?.CarrierName} />
                        ))}

                        {internalCarriers.length > 0 && (
                            <FormControlLabel value="Other"
                                control={<Radio
                                    onClick={() => {
                                        setSelectedInternalCarrierObject({
                                            CarrierInternalName: "Other",
                                            isOtherChecked: true,
                                            CarrierID: null
                                        })
                                    }}
                                    checked={selectedInternalCarrierObject?.CarrierInternalName == "Other" || iSolvedCarrier?.CarrierInternalName == "Other"}
                                />} label="Other" />
                        )}
                    </RadioGroup>
                </FormControl> :
                <div className="fs-12" style={{ textTransform: "initial", fontWeight: "400", textAlign: "center" }}>
                    <br />
                    Please wait ......
                    <br />
                    <br />
                    <CircularProgress size="4rem" color="inherit" />
                </div>}
            <div className="row w-100 mt-5 custom-internal-mapping-actions" style={{ justifyContent: "center" }}>
                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => dialog.closeDialog()}>Cancel</Button>
                <Button variant="contained" className="blue-btn" disabled={!selectedInternalCarrierObject} onClick={() => {
                    handleSelectedInternalCarrierChange();
                    dialog.closeDialog();
                }} >Map Selected</Button>
            </div>
        </div>
    )
}

export default MapInternalCarrier;