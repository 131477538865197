
import Moment from "moment"

let currentTime = new Date().getTime()
currentTime = Moment(currentTime).format("YYYY-MM-DDTHH:mm:ss")

let currentDate = new Date()
currentDate = Moment(currentDate).format("YYYY-MM-DD")

export default class SchedulerObject {
    constructor({ Type = null, Enabled = false,
        Recurrence = 1,
        StartDate = currentDate,
        EndDate = null,
        DayFrequency = 0,
        DayFrequencyRecurrence = null,
        StartTime = Moment(currentTime).format('YYYY-MM-DDTHH:mm:ss'),
        EndTime = null,
        WeekDay = null,
        DayOfMonth = null,
        MonthlyType = null,
        PartType = null,
        StartDateTime = `${currentDate}T${Moment(currentTime).format("HH:mm")}`
    }) {
        this.Type = Type;
        this.Enabled = Enabled;
        this.Recurrence = Recurrence;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.DayFrequency = DayFrequency;
        this.DayFrequencyRecurrence = DayFrequencyRecurrence;
        this.StartTime = StartTime;
        this.EndTime = EndTime;
        this.WeekDay = WeekDay;
        this.DayOfMonth = DayOfMonth;
        this.MonthlyType = MonthlyType;
        this.PartType = PartType;
        this.StartDateTime = StartDateTime;
    }
}