import React from "react";
import { Divider, Paper } from "@material-ui/core";
import dateTimeSVG from "../../../../Images/dateTime.svg";
import Button from "@material-ui/core/Button";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as APIs from "../../../../Data/APIs";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { FailureModal } from "../../../Modals/FailureModal";
import { useParams } from "react-router-dom";
import { useLoader } from "../../../Layout/Loader";
import Moment from 'moment';
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";


function Set401kProcessFeedDate({ feed, feedIDURL, ...props }) {

    const dialog = useModalDialog(0);
    const [dataDate401kFrom, setDataDate401kFrom] = React.useState(null);
    const { openLoader, closeLoader } = useLoader();

    const handleDataDate401Change = (date) => {
        setDataDate401kFrom(Moment(date).format('YYYY-MM-DD'));
    };

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const getTestFile = () => {
        openLoader();
        APIs.get401KTestFile({ feedId: feedIDURL, checkDate: dataDate401kFrom }).then((r) => {
            // if (r?.statusCode === 200) {
            SuccessModal(dialog, {
                title: 'Success!',
                body: `Please wait for the file to show in the transmission dashboard!`,
            });
            // } else {
            //     FailureModal(dialog, {
            //         title: 'Failure',
            //         body: `The request has not been processed successfully. Please try again!`,
            //     });
            // }
            closeLoader();
        })
    }

    return (
        <div id="last-sent-file-modal">
            {/* <h5 className="black1 fw-700">Please select date to receive the data:</h5>
            <Divider /> */}
            <Paper elevation={0} className="mt-3 p-4 search-container">
                <div className="text-input w-100" style={{ display: "inline-flex" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            className="w-100"
                            autoOk={true}
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yyyy"
                            margin="normal"
                            id="frequency-date"
                            value={dataDate401kFrom}
                            onChange={handleDataDate401Change}
                            keyboardIcon={<svg  width="14" height="16" viewBox="0 0 14 16">
                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                        </svg>}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </Paper>
                <div className="row w-100 mt-5" style={{ justifyContent: "flex-end" }}>
                    <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => dialog.closeDialog()}>Cancel</Button>
                    <Button variant="contained" className="blue-btn" disabled={dataDate401kFrom === null || !dataDate401kFrom || dataDate401kFrom === "Invalid date"} onClick={() => {
                        dialog.closeDialog();
                        openModal(0, getTestFile, {
                            title: 'Please Confirm',
                            question: `Are you sure you want to get a new test file for feed ${feed?.FeedID}?`,
                        })
                    }}>Process</Button>
                </div>
        </div>
    )
}

export default Set401kProcessFeedDate;