import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Button from "@material-ui/core/Button";


export const ModalContext = React.createContext();

export const ModalDialogContext = React.createContext({
    openDialog: (index, title, body, actions, className, options) => { },
    closeDialog: (index) => { },
    isDialogOpen: (index) => { },
});

function PaperComponent({ ...props }) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
export function useModalDialog(index = 0) {
    const modalDialogContext = React.useContext(ModalDialogContext);

    return {
        openDialog: (title, body, actions, className, options = {}) => modalDialogContext.openDialog(index, title, body, actions, className, options ?? {}),
        closeDialog: () => modalDialogContext.closeDialog(index),
        isDialogOpen: () => modalDialogContext.isDialogOpen(index),
    };
}

export function ModalDialogProvider({ ...props }) {
    const [dialogs, setDialogs] = React.useState([{ isOpen: false }, { isOpen: false }, { isOpen: false }, { isOpen: false }, { isOpen: false }]);

    const openDialog = React.useCallback(
        (index, title, body, actions, className, { disableDefaultClose, showCloseButton }) => {
            const dialog = dialogs[index];
            if (dialog && dialog.isOpen !== true) {
                dialog.isOpen = true;
                dialog.title = title;
                dialog.body = body;
                dialog.actions = actions;
                dialog.className = className;
                dialog.disableDefaultClose = disableDefaultClose === true;
                dialog.showCloseButton = showCloseButton === true;
                setDialogs([...dialogs]);
            }
        },
        [dialogs]
    );
    const closeDialog = React.useCallback(
        (index) => {
            const dialog = dialogs[index];
            if (dialog && dialog.isOpen === true) {
                dialog.isOpen = false;
                setDialogs([...dialogs]);
            }
        },
        [dialogs]
    );
    const isDialogOpen = React.useCallback(
        (index) => {
            return dialogs[index]?.isOpen;
        },
        [dialogs]
    );

    return (
        <ModalDialogContext.Provider
            value={{
                openDialog,
                closeDialog,
                isDialogOpen,
            }}
        >
            {props.children}
            {dialogs?.map((d, di) => (
                <Dialog
                    key={`Modal-Dialog-${di}`}
                    open={d.isOpen}
                    onClose={() => { if (d.disableDefaultClose !== true) closeDialog(di); }}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{ className: d.className }}
                >
                    <div className="dalog-body">

                        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                            {d.title}
                            {d.showCloseButton &&
                                <i className="fas fa-times float-right" style={{ cursor: "pointer" }} onClick={() => closeDialog(di)}></i>}
                        </DialogTitle>
                        <DialogContent>{d.body}</DialogContent>
                        <DialogActions>
                            {d.actions?.map((a, ai) => (
                                <React.Fragment key={`Dialog-${di}-Action-${ai}`}>
                                    {a.isButton ? (
                                        a.body
                                    ) : (
                                        <div autoFocus onClick={a.onClick}>
                                            {a.body}
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                        </DialogActions>
                    </div>


                </Dialog>
            ))
            }
        </ModalDialogContext.Provider >
    );
}