import React from 'react'
import * as APIs from "../../../Data/APIs";
import Moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { useLoader } from '../../Layout/Loader';
import HomeViewOERequestsList from './HomeViewOERequestsList';
import { useAuthentication } from '../../../Data/Authentication';
const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


function HomeOERequestsDashboard({
    onHasOEPendingActions,
    onOERequestsListCallback,
    ...props
}) {

    // style

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    const { openLoader, closeLoader } = useLoader();
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [oERequestsList, setOERequestsList] = React.useState([]);
    const [refreshClicked, setRefreshClicked] = React.useState(false);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);

    // normal filter 

    const [oERequestIDFilter, setOERequestIDFilter] = React.useState("");
    const [selectedExpectedFromDateFilter, setSelectedExpectedFromDateFilter] = React.useState(null);
    const [selectedExpectedToDateFilter, setSelectedExpectedToDateFilter] = React.useState(null);
    const [iSolvedContact, setISolvedContact] = React.useState("");
    const [iSolvedContactAutoValue, setISolvedContactAutoValue] = React.useState("");
    const [updatedGS, setUpdatedGS] = React.useState("");
    const [draft, setDraft] = React.useState("");
    const [clientsList, setClientsList] = React.useState([]);
    const [peosList, setPEOsList] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);
    const [clientName, setClientName] = React.useState();
    const [clientNameAutoValue, setClientNameAutoValue] = React.useState("");
    const [peoName, setPEOName] = React.useState();
    const [peoNameAutoValue, setPEONameAutoValue] = React.useState("");
    const [carName, setCarName] = React.useState();
    const [carNameAutoValue, setCarNameAutoValue] = React.useState("");
    const [clientSelectedFilter, setClientSelectedFilter] = React.useState("");
    const [peoSelectedFilter, setPEOSelectedFilter] = React.useState("");
    const [carrierSelectedFilter, setCarrierSelectedFilter] = React.useState("");
    const [selectedOEFileSubmissionFromDateFilter, setSelectedOEFileSubmissionFromDateFilter] = React.useState(null);
    const [selectedOEFileSubmissionToDateFilter, setSelectedOEFileSubmissionToDateFilter] = React.useState(null);

    // advanced filter 
    const [oERequestIDAdvFilter, setOERequestIDAdvFilter] = React.useState("");
    const [selectedExpectedFromDateAdvFilter, setSelectedExpectedFromDateAdvFilter] = React.useState(null);
    const [selectedExpectedToDateAdvFilter, setSelectedExpectedToDateAdvFilter] = React.useState(null);
    const [iSolvedContactAdvFilter, setISolvedContactAdvFilter] = React.useState("");
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [selectedISolvedContactFilter, setSelectedISolvedContactFilter] = React.useState([]);
    const [updatedGSAdvFilter, setUpdatedGSAdvFilter] = React.useState("");
    const [draftAdvFilter, setDraftAdvFilter] = React.useState("");
    const [selectedClientsFilter, setSelectedClientsFilter] = React.useState([]);
    const [selectedPEOsFilter, setSelectedPEOsFilter] = React.useState([]);
    const [selectedCarriersFilter, setSelectedCarriersFilter] = React.useState([]);
    const [selectedOEFileSubmissionFromDateAdvFilter, setSelectedOEFileSubmissionFromDateAdvFilter] = React.useState(null);
    const [selectedOEFileSubmissionToDateAdvFilter, setSelectedOEFileSubmissionToDateAdvFilter] = React.useState(null);
    //  page size and selected and count 
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);

    // gated Data 
    const [usersList, setUsersList] = React.useState([]);
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const [contactsList, setContactsList] = React.useState([]);

    // get Data 

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: 3,
        }
        APIs.getContactsList(contactsBody).then((r) => {
            setContactsList(r?.data ?? []);
        })
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])


    // handel get oe data
    const getOERequestsQuickData = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPage >= 1) {
            requestParameters.page = selectedPage;
            requestParameters.limit = pageSize;
        }
        if (pageSize >= 10) {
            requestParameters.limit = pageSize;
            requestParameters.page = selectedPage;
        }
        if (iSolvedContact !== "")
            requestParameters.IsolvedContactCognitoIDsArray = iSolvedContact === undefined ? "" : [iSolvedContact?.CognitoID];
        if (selectedExpectedFromDateFilter !== null)
            requestParameters.ClientDataExpectedDateFrom = selectedExpectedFromDateFilter ? Moment(selectedExpectedFromDateFilter).format('YYYY-MM-DD') : null;
        if (oERequestIDFilter !== "")
            requestParameters.OERequestID = parseInt(oERequestIDFilter);
        if (selectedExpectedToDateFilter !== null)
            requestParameters.ClientDataExpectedDateTo = selectedExpectedToDateFilter ? Moment(selectedExpectedToDateFilter).format('YYYY-MM-DD') : null;
        if (updatedGS !== "")
            requestParameters.UpdatedGroupStructure = parseInt(updatedGS);
        if (draft !== "")
            requestParameters.IsDraftOERequest = parseInt(draft);
        if (sortFieldFilter !== "")
            requestParameters.sortField = sortFieldFilter;
        if (sortOrderFilter !== "")
            requestParameters.sortOrder = sortOrderFilter;
        if (clientSelectedFilter !== "")
            requestParameters.GroupIDsArray = [clientSelectedFilter.GroupID];
        if (peoSelectedFilter !== "")
            requestParameters.PeoIDsArray = [peoSelectedFilter.PeoId];
        if (carrierSelectedFilter !== "")
            requestParameters.CarrierIDsArray = [carrierSelectedFilter.CarrierID];
        if (selectedOEFileSubmissionFromDateFilter !== null)
            requestParameters.OEFileSubmissionDateFrom = selectedOEFileSubmissionFromDateFilter ? Moment(selectedOEFileSubmissionFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedOEFileSubmissionToDateFilter !== null)
            requestParameters.OEFileSubmissionDateTo = selectedOEFileSubmissionToDateFilter ? Moment(selectedOEFileSubmissionToDateFilter).format('YYYY-MM-DD') : null;

        APIs.getOERequestsList(requestParameters).then((r) => (
            !(r?.data?.length) ? setOERequestsList([]) : setOERequestsList(r?.data),
            onOERequestsListCallback(r?.data),
            setRefreshClicked(false),
            closeLoader(),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }


    // handel get oe data Advanced 
    const getOERequestsAdvancedData = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPageAdv >= 1) {
            requestParameters.page = selectedPageAdv;
            requestParameters.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            requestParameters.limit = pageSizeAdv;
            requestParameters.page = selectedPageAdv;
        }
        if (selectedISolvedContactFilter.length > 0)
            requestParameters.IsolvedContactCognitoIDsArray = selectedISolvedContactFilter?.map(s => s?.CognitoID);
        if (oERequestIDAdvFilter !== "")
            requestParameters.OERequestID = parseInt(oERequestIDAdvFilter);
        if (selectedExpectedFromDateAdvFilter !== null)
            requestParameters.ClientDataExpectedDateFrom = selectedExpectedFromDateAdvFilter ? Moment(selectedExpectedFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedExpectedToDateAdvFilter !== null)
            requestParameters.ClientDataExpectedDateTo = selectedExpectedToDateAdvFilter ? Moment(selectedExpectedToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (updatedGSAdvFilter !== "")
            requestParameters.UpdatedGroupStructure = parseInt(updatedGSAdvFilter);
        if (draftAdvFilter !== "")
            requestParameters.IsDraftOERequest = parseInt(draftAdvFilter);
        if (sortFieldAdvFilter !== "")
            requestParameters.sortField = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            requestParameters.sortOrder = sortOrderAdvFilter;
        if (selectedClientsFilter.length > 0)
            requestParameters.GroupIDsArray = selectedClientsFilter?.map(s => s?.GroupID);
        if (selectedPEOsFilter.length > 0)
            requestParameters.PeoIDsArray = selectedPEOsFilter?.map(s => s?.PeoId);
        if (selectedCarriersFilter.length > 0)
            requestParameters.CarrierIDsArray = selectedCarriersFilter?.map(s => s?.CarrierID);
        if (selectedOEFileSubmissionFromDateAdvFilter !== null)
            requestParameters.OEFileSubmissionDateFrom = selectedOEFileSubmissionFromDateAdvFilter ? Moment(selectedOEFileSubmissionFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedOEFileSubmissionToDateAdvFilter !== null)
            requestParameters.OEFileSubmissionDateTo = selectedOEFileSubmissionToDateAdvFilter ? Moment(selectedOEFileSubmissionToDateAdvFilter).format('YYYY-MM-DD') : null;

        APIs.getOERequestsList(requestParameters).then((r) => (
            !(r?.data?.length) ? setOERequestsList([]) : setOERequestsList(r?.data),
            onOERequestsListCallback(r?.data),
            setAdvancedFiltersAppliedClicked(false),
            closeLoader(),
            setRefreshClicked(false),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }
    React.useEffect(() => {
        openLoader();
        if (!userRoles?.includes("6")) {
            APIs.getGroupsListByName({ GroupName: clientName ?? "" }).then((r) => !(r?.data?.length) ? setClientsList([]) : setClientsList(r?.data));
            APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
        }
        APIs.getCarriersListByName({ CarrierName: carName ?? "" }).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [])



    React.useEffect(() => {
        if (quickFiltersClicked) getOERequestsQuickData();
    }, [selectedPage, pageSize, updatedGS, draft, iSolvedContact, oERequestIDFilter, selectedExpectedFromDateFilter, selectedExpectedToDateFilter, sortFieldFilter, sortOrderFilter, clientName, peoName, carName, selectedOEFileSubmissionFromDateFilter, selectedOEFileSubmissionToDateFilter, refreshClicked])

    React.useEffect(() => {
        getOERequestsQuickData();
    }, [quickFiltersClicked === true])
    React.useEffect(() => {
        getOERequestsAdvancedData();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])

    React.useEffect(() => {
        getOERequestsAdvancedData();
    }, [advancedFiltersClicked === true])

    return (
        <>
            <HomeViewOERequestsList
                oERequestsList={oERequestsList}
                setOERequestsList={setOERequestsList}
                refreshClicked={refreshClicked}
                setRefreshClicked={setRefreshClicked}
                quickFiltersClicked={quickFiltersClicked}
                setQuickFiltersClicked={setQuickFiltersClicked}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                selectedExpectedFromDateFilter={selectedExpectedFromDateFilter}
                setSelectedExpectedFromDateFilter={setSelectedExpectedFromDateFilter}
                selectedExpectedToDateFilter={selectedExpectedToDateFilter}
                setSelectedExpectedToDateFilter={setSelectedExpectedToDateFilter}
                iSolvedContact={iSolvedContact}
                setISolvedContact={setISolvedContact}
                iSolvedContactAutoValue={iSolvedContactAutoValue}
                setISolvedContactAutoValue={setISolvedContactAutoValue}
                updatedGS={updatedGS}
                setUpdatedGS={setUpdatedGS}
                draft={draft}
                setDraft={setDraft}
                oERequestIDFilter={oERequestIDFilter}
                setOERequestIDFilter={setOERequestIDFilter}
                clientsList={clientsList}
                setClientsList={setClientsList}
                peosList={peosList}
                setPEOsList={setPEOsList}
                carriersList={carriersList}
                setCarriersList={setCarriersList}
                clientName={clientName}
                setClientName={setClientName}
                clientNameAutoValue={clientNameAutoValue}
                setClientNameAutoValue={setClientNameAutoValue}
                peoName={peoName}
                setPEOName={setPEOName}
                peoNameAutoValue={peoNameAutoValue}
                setPEONameAutoValue={setPEONameAutoValue}
                carName={carName}
                setCarName={setCarName}
                carNameAutoValue={carNameAutoValue}
                setCarNameAutoValue={setCarNameAutoValue}
                clientSelectedFilter={clientSelectedFilter}
                setClientSelectedFilter={setClientSelectedFilter}
                peoSelectedFilter={peoSelectedFilter}
                setPEOSelectedFilter={setPEOSelectedFilter}
                carrierSelectedFilter={carrierSelectedFilter}
                setCarrierSelectedFilter={setCarrierSelectedFilter}
                selectedOEFileSubmissionFromDateFilter={selectedOEFileSubmissionFromDateFilter}
                setSelectedOEFileSubmissionFromDateFilter={setSelectedOEFileSubmissionFromDateFilter}
                selectedOEFileSubmissionToDateFilter={selectedOEFileSubmissionToDateFilter}
                setSelectedOEFileSubmissionToDateFilter={setSelectedOEFileSubmissionToDateFilter}
                selectedExpectedFromDateAdvFilter={selectedExpectedFromDateAdvFilter}
                setSelectedExpectedFromDateAdvFilter={setSelectedExpectedFromDateAdvFilter}
                selectedExpectedToDateAdvFilter={selectedExpectedToDateAdvFilter}
                setSelectedExpectedToDateAdvFilter={setSelectedExpectedToDateAdvFilter}
                iSolvedContactAdvFilter={iSolvedContactAdvFilter}
                setISolvedContactAdvFilter={setISolvedContactAdvFilter}
                oERequestIDAdvFilter={oERequestIDAdvFilter}
                setOERequestIDAdvFilter={setOERequestIDAdvFilter}
                sortFieldAdvFilter={sortFieldAdvFilter}
                setSortFieldAdvFilter={setSortFieldAdvFilter}
                sortOrderAdvFilter={sortOrderAdvFilter}
                setSortOrderAdvFilter={setSortOrderAdvFilter}
                sortOrderFilter={sortOrderFilter}
                setSortOrderFilter={setSortOrderFilter}
                sortFieldFilter={sortFieldFilter}
                setSortFieldFilter={setSortFieldFilter}
                selectedISolvedContactFilter={selectedISolvedContactFilter}
                setSelectedISolvedContactFilter={setSelectedISolvedContactFilter}
                updatedGSAdvFilter={updatedGSAdvFilter}
                setUpdatedGSAdvFilter={setUpdatedGSAdvFilter}
                draftAdvFilter={draftAdvFilter}
                setDraftAdvFilter={setDraftAdvFilter}
                selectedClientsFilter={selectedClientsFilter}
                setSelectedClientsFilter={setSelectedClientsFilter}
                selectedPEOsFilter={selectedPEOsFilter}
                setSelectedPEOsFilter={setSelectedPEOsFilter}
                selectedCarriersFilter={selectedCarriersFilter}
                setSelectedCarriersFilter={setSelectedCarriersFilter}
                selectedOEFileSubmissionFromDateAdvFilter={selectedOEFileSubmissionFromDateAdvFilter}
                setSelectedOEFileSubmissionFromDateAdvFilter={setSelectedOEFileSubmissionFromDateAdvFilter}
                selectedOEFileSubmissionToDateAdvFilter={selectedOEFileSubmissionToDateAdvFilter}
                setSelectedOEFileSubmissionToDateAdvFilter={setSelectedOEFileSubmissionToDateAdvFilter}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                totalCount={totalCount}
                setTotalCount={setTotalCount}
                selectedPageAdv={selectedPageAdv}
                setSelectedPageAdv={setSelectedPageAdv}
                pageSizeAdv={pageSizeAdv}
                setPageSizeAdv={setPageSizeAdv}
                usersList={usersList}
                setUsersList={setUsersList}
                groupsAndOEContactsList={groupsAndOEContactsList}
                setGroupsAndOEContactsList={setGroupsAndOEContactsList}
                contactsList={contactsList}
                setContactsList={setContactsList}
                getOERequestsQuickData={getOERequestsQuickData}
                getOERequestsAdvancedData={getOERequestsAdvancedData}
            />

        </>

    )
}

export default HomeOERequestsDashboard
