import React from "react";
import Moment from 'moment';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { FailureModal } from "../../../Modals/FailureModal";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import AnalystFeedFormPlansMatching from "./AnalystFeedFormPlansMatching";
import { useParams } from "react-router-dom";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Select } from "@material-ui/core";
import SaveIcon from '@mui/icons-material/Save';
import AnalystFeedFormAttachMap from "./AnalystFeedFormAttachMap";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";


function AnalystFeedFormRecord({ feed, form, index, onMatchingAllPlansValidation, ...props }) {

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const { feedIDURL } = useParams();

    const [groupPlans, setGroupPlans] = React.useState([]);
    const [formPlans, setFormPlans] = React.useState([]);
    const [matchedPlans, setMatchedPlans] = React.useState([]);
    const [filteredMatchedPlans, setFilteredMatchedPlans] = React.useState([]);
    const [mapID, setMapID] = React.useState();
    const [carrierMaps, setCarrierMaps] = React.useState([]);

    const [matchingPlansValidation, setMatchingPlansValidation] = React.useState([]);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        openLoader();
        APIs.getGroupPlansDFList(feedIDURL).then((r) => { setGroupPlans(r?.data); closeLoader(); });
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getCarrierDFPlansList(feed?.CarrierID).then((r) => { setFormPlans(r?.data); closeLoader(); });
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getDFMatchedPlans(feedIDURL).then((r) => { setMatchedPlans(r?.data); setFilteredMatchedPlans(r?.data?.filter(f => f.FormId === form?.DigitalFormID)); }).finally(() => closeLoader());
    }, [])

    React.useEffect(() => {
        openLoader();
        const getMapsBody = { CarrierID: `${feed?.CarrierID}` }
        APIs.getCarrierMaps(getMapsBody).then((r) => !(r?.data?.length) ? setCarrierMaps([]) : setCarrierMaps(r?.data?.map(c => c))).finally(() => closeLoader());;
    }, [feed?.CarrierID])

    const handleDeleteForm = () => {
        openModal(0, deleteForm, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this form?`,
        })
    }

    const deleteForm = () => {
        openLoader();
        APIs.deleteDigitalForm({
            FeedID: feed?.FeedID,
            DigitalFormID: form?.DigitalFormID
        }).then((r) => window.location.reload())
    }

    React.useEffect(() => {
        let matchedPlansValidationArray = matchedPlans;
        matchedPlansValidationArray = matchedPlans?.map(s => s.FormId);
        matchedPlansValidationArray = Array.from(new Set(matchedPlansValidationArray));
        setMatchingPlansValidation(matchedPlansValidationArray);
    }, [matchedPlans])

    React.useEffect(() => {
        if (feed?.DigitalForms?.length === matchingPlansValidation?.length)
            onMatchingAllPlansValidation(true); else onMatchingAllPlansValidation(false);
    }, [feed, matchingPlansValidation])

    return (
        <div key={`digital-form-${index}`} className="row table-row-with-border-bottom table-row-bordered-l">
            <div className="col-1 table-cell first-cell">
                {form?.DigitalFormID}
            </div>
            <div className="col-2 table-cell" style={{ wordBreak: "break-word" }}>
                {form?.DigitalFormName}
            </div>
            <div className="col-2 table-cell">
                {form?.MainTypes?.map(s => s.Name).join(" , ")}
            </div>
            <div className="col-2 table-cell">
                {form?.SubTypes?.map(s => s.Name).join(" , ")}
            </div>
            <div className="col-1 table-cell">
                <CustomTooltip TransitionComponent={Zoom} title={groupPlans?.length <= 0 ? `There are no Group plans, please process the feed first` : `Click here to match plans`}>
                    <span>
                        <a className="bootstrap-link missing-data-warning" style={groupPlans?.length <= 0 ? { pointerEvents: "none", cursor: "pointer" } : { cursor: "pointer" }} onClick={(e) => {
                            dialog.openDialog(`${form?.DigitalFormName} / Plans Matching`,
                                <AnalystFeedFormPlansMatching
                                    feed={feed}
                                    groupPlans={groupPlans}
                                    filteredFormPlans={formPlans?.filter(f => f.FormId === form?.DigitalFormID)}
                                    parentForceUpdate={forceUpdate}
                                    form={form}
                                    filteredMatchedPlans={filteredMatchedPlans}
                                />, []
                            );
                        }}>
                            {filteredMatchedPlans?.length <= 0 ? `No Matched Plans` : filteredMatchedPlans?.length === 1 ? `${filteredMatchedPlans?.length} Matched Plan` : `${filteredMatchedPlans?.length} Matched Plans`}
                        </a>
                    </span>
                </CustomTooltip>
            </div>
            <div className="col-1 table-cell" style={{ justifyItems: "center" }}>
                <CustomTooltip TransitionComponent={Zoom} title="Click here to attach/edit a map for the form">
                    <span>
                        <a className="bootstrap-link missing-data-warning" style={groupPlans?.length <= 0 ? { pointerEvents: "none", cursor: "pointer", wordBreak: "break-all" } : { cursor: "pointer", wordBreak: "break-all" }} onClick={(e) => {
                            dialog.openDialog(`${form?.DigitalFormName} / Plans Matching`,
                                <AnalystFeedFormAttachMap
                                    feed={feed}
                                    groupPlans={groupPlans}
                                    filteredFormPlans={formPlans?.filter(f => f.FormId === form?.DigitalFormID)}
                                    parentForceUpdate={forceUpdate}
                                    form={form}
                                    filteredMatchedPlans={filteredMatchedPlans}
                                    carrierMaps={carrierMaps}
                                />, []
                            );
                        }}>
                            {form?.MapName ? form?.MapName : `No Map Attached`}
                        </a>
                    </span>
                </CustomTooltip>
            </div>
            <div className="col-1 table-cell">
                {form?.DigitizedBy}
            </div>
            <div className="col-1 table-cell">
                {form?.CreatedDate ? Moment(form?.CreatedDate).format('MM/DD/YYYY') : null}
            </div>
            <div className="col-1 table-cell last-cell" style={{ display: "flex", justifyContent: "center" }}>
                <svg width="21" height="24" viewBox="0 0 21 24" onClick={handleDeleteForm} style={{ cursor: "pointer" }}>
                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                </svg>
            </div>
        </div>
    )
}

export default AnalystFeedFormRecord;