import React from "react";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

function BreadcrumbSteps({ breadcrumbTitle, breadcrumbLink, breadcrumbTitleCurrent, isCurrent, withHomeLink = true, ...props }) {

    const breadcrumbObject = [{
        title: breadcrumbTitle,
        link: breadcrumbLink,
        isCurrent: isCurrent
    }]

    const breadcrumbObjectCurrent = {
        titleCurrent: breadcrumbTitleCurrent,
        isCurrentCurrent: isCurrent
    }

    return (
        <Breadcrumbs id="breadcrumb-steps" aria-label="breadcrumb">
            {withHomeLink && <Link color="inherit" href="/">Home</Link>}


            {!isCurrent && breadcrumbObject.map((b, bi) => (
                <Link style={{ cursor: "pointer" }} key={`breadcrumb-links-${bi}`} href={b.link} color="textPrimary">{b.title}</Link>
            ))}
            <Typography color="textPrimary">{breadcrumbObjectCurrent.titleCurrent}</Typography>

        </Breadcrumbs>
    );
}

export default BreadcrumbSteps;
