import React from "react";
import { Paper, TextField, Button, Select, Chip } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from '@material-ui/icons/Clear';
import { useAuthentication } from "../../../../Data/Authentication";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AnalystTransmissionsFilterModalBody from "./AnalystTransmissionsFilterModalBody";
import Moment from 'moment';
import refreshSVG from "../../../../Icons/refresh.svg";
import cancelIconSVG from "../../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../../Icons/advancedFilter.svg";
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import TruncatedChip from "../../../Layout/TruncatedChip";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const yearDate = new Date();
yearDate.setHours(0, 0, 0, 0)
yearDate.setFullYear(todayDate.getFullYear() - 1);

function AnalystSingleTransmissionFilter({
    onExecutionStatusFilter,
    onProcessingStepFilter,
    onFeedStatusFilter,
    onFeedPhaseFilter,
    onExecutionIDFilter,
    onFromDateFilter,
    fromDateFilter,
    onToDateFilter,
    toDateFilter,
    onSentFromDateFilter,
    sentFromDateFilter,
    onSentToDateFilter,
    sentToDateFilter,
    setRefreshClicked,
    selectedPage,
    onSelectedPage,
    pageSize,
    onPageSize,
    totalCount,
    setTotalCount,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSizeAdv,
    onPageSizeAdv,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    selectedFeedStatusAdvFilter,
    onSelectedFeedStatusAdvFilter,
    selectedFeedPhaseAdvFilter,
    onSelectedFeedPhaseAdvFilter,
    executionIDAdvFilter,
    onExecutionIDAdvFilter,
    selectedExecutionStatusAdvFilter,
    onSelectedExecutionStatusAdvFilter,
    selectedProcessingStepAdvFilter,
    onSelectedProcessingStepAdvFilter,
    requestFromDateAdvFilter,
    onRequestFromDateAdvFilter,
    requestToDateAdvFilter,
    onRequestToDateAdvFilter,
    sentFromDateAdvFilter,
    onSentFromDateAdvFilter,
    sentToDateAdvFilter,
    onSentToDateAdvFilter,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [executionStatusesList, setExecutionStatusesList] = React.useState([]);
    const [processingStepsList, setProcessingStepsList] = React.useState([]);
    const [feedsTypesList, setFeedsTypesList] = React.useState([]);
    const [feedsStatusesList, setFeedsStatusesList] = React.useState([]);
    const [feedsPhasesList, setFeedsPhasesList] = React.useState([]);

    const [selectedFromDate, setSelectedFromDate] = React.useState(sixMonthDate);
    const [selectedToDate, setSelectedToDate] = React.useState(todayDate);
    const [selectedSentFromDate, setSelectedSentFromDate] = React.useState(null);
    const [selectedSentToDate, setSelectedSentToDate] = React.useState(null);
    const [executionStatus, setExecutionStatus] = React.useState("");
    const [processingStep, setProcessingStep] = React.useState("");
    const [executionID, setExecutionID] = React.useState("");
    const [feedStatus, setFeedStatus] = React.useState("");
    const [feedPhase, setFeedPhase] = React.useState("");

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        onExecutionStatusFilter("");
        setExecutionStatus("");
        onProcessingStepFilter("");
        setProcessingStep("");
        onFeedStatusFilter("");
        setFeedStatus("");
        onFeedPhaseFilter("");
        setFeedPhase("");
        onExecutionIDFilter("");
        setExecutionID("");
        onFromDateFilter(sixMonthDate);
        setSelectedFromDate(sixMonthDate);
        onToDateFilter(todayDate);
        setSelectedToDate(todayDate);
        onSentFromDateFilter(null);
        setSelectedSentFromDate(null);
        onSentToDateFilter(null);
        setSelectedSentToDate(null);
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
    };

    React.useEffect(() => {
        APIs.getExecutionStatusesList().then((r) => setExecutionStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getProcessingStepsList().then((r) => setProcessingStepsList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getFeedFileTypes().then((r) => (!(r?.data?.length) ? setFeedsTypesList([]) : setFeedsTypesList(r?.data)));
    }, [])

    React.useEffect(() => {
        APIs.getFeedStatuses().then((r) => (!(r?.length) ? setFeedsStatusesList([]) : setFeedsStatusesList(r)));
    }, [])

    React.useEffect(() => {
        APIs.getFeedPhases().then((r) => (!(r?.data?.length) ? setFeedsPhasesList([]) : setFeedsPhasesList(r.data)));
    }, [])

    const handleDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedFromDate(date);
            onFromDateFilter(date);
        }
        else {
            setSelectedToDate(date);
            onToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleSentDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedSentFromDate(date);
            onSentFromDateFilter(date);
        }
        else {
            setSelectedSentToDate(date);
            onSentToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleExecutionStatusChange = (e) => {
        setExecutionStatus(e.target.value);
        onExecutionStatusFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleProcessingStepChange = (e) => {
        setProcessingStep(e.target.value);
        onProcessingStepFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedStatusChange = (e) => {
        setFeedStatus(e.target.value);
        onFeedStatusFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedPhaseChange = (e) => {
        setFeedPhase(e.target.value);
        onFeedPhaseFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleExecutionIDFilter = (e) => {
        setExecutionID(e.target.value);
        onExecutionIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleClearSearch = () => {
        setExecutionStatus("");
        onExecutionStatusFilter("");
        setProcessingStep("");
        onProcessingStepFilter("");
        setExecutionID("");
        onExecutionIDFilter("");
        setFeedStatus("");
        onFeedStatusFilter("");
        setFeedPhase("");
        onFeedPhaseFilter("");
        setSelectedFromDate(null);
        onFromDateFilter(null);
        setSelectedToDate(null);
        onToDateFilter(null);
        setSelectedSentFromDate(null);
        onSentFromDateFilter(null);
        setSelectedSentToDate(null);
        onSentToDateFilter(null);
        onSelectedPage(1);
    }

    const handleClearSearchReset = () => {
        setExecutionStatus("");
        onExecutionStatusFilter("");
        setProcessingStep("");
        onProcessingStepFilter("");
        setFeedStatus("");
        onFeedStatusFilter("");
        setFeedPhase("");
        onFeedPhaseFilter("");
        setExecutionID("");
        onExecutionIDFilter("");
        setSelectedFromDate(sixMonthDate);
        onFromDateFilter(sixMonthDate);
        setSelectedToDate(todayDate);
        onToDateFilter(todayDate);
        setSelectedSentFromDate(null);
        onSentFromDateFilter(null);
        setSelectedSentToDate(null);
        onSentToDateFilter(null);
        onSelectedPage(1);
        onSelectedFeedStatusAdvFilter([]);
        onSelectedFeedPhaseAdvFilter([]);
        onExecutionIDAdvFilter("");
        onSelectedExecutionStatusAdvFilter([]);
        onSelectedProcessingStepAdvFilter([]);
        onRequestFromDateAdvFilter(sixMonthDate);
        onRequestToDateAdvFilter(todayDate);
        onSentFromDateFilter(null);
        onSentToDateFilter(null);
        onSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
    }

    return (
        <div>
            <div className="search-container">
                <div className="row alignvh-center">
                    <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                    <Button
                        className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                        onClick={handleClickOpenDialog}
                        style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1">
                            <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                        </svg>
                        Advanced Filters
                    </Button>
                    <React.Fragment>
                        {feedStatus && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed Status: ${feedsStatusesList?.filter(s => s.FeedStatusID == feedStatus)[0].FeedStatusName}`} />}
                        {selectedFeedStatusAdvFilter && selectedFeedStatusAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed Statuses: ${selectedFeedStatusAdvFilter?.map(s => s.FeedStatusName).join(" - ")}`} />}
                        {feedPhase && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed Phase: ${feedsPhasesList?.filter(r => r.FeedPhaseID == feedPhase)[0].FeedPhaseName}`} />}
                        {selectedFeedPhaseAdvFilter && selectedFeedPhaseAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed Phases: ${selectedFeedPhaseAdvFilter?.map(s => s.FeedPhaseName).join(" - ")}`} />}
                        {executionID && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Execution ID: ${executionID}`} />}
                        {executionIDAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Execution ID: ${executionIDAdvFilter}`} />}
                        {executionStatus && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Transmission Status: ${executionStatusesList?.filter(s => s.Id == executionStatus)[0].Name}`} />}
                        {selectedExecutionStatusAdvFilter && selectedExecutionStatusAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Transmission Statuses: ${selectedExecutionStatusAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                        {processingStep && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Processing Step: ${processingStepsList?.filter(s => s.Id == processingStep)[0].Name}`} />}
                        {selectedProcessingStepAdvFilter && selectedProcessingStepAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Processing Steps: ${selectedProcessingStepAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                        {quickFiltersClicked && fromDateFilter && toDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Request Date: From: ${Moment(fromDateFilter).format("MM/DD/YYYY")} To: ${Moment(toDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && fromDateFilter && !toDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Request Date: From: ${Moment(fromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !fromDateFilter && toDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Request Date: To: ${Moment(toDateFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && requestFromDateAdvFilter && requestToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Request Date: From: ${Moment(requestFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(requestToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && requestFromDateAdvFilter && !requestToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Request Date: From: ${Moment(requestFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !requestFromDateAdvFilter && requestToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Request Date: To: ${Moment(requestToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && sentFromDateFilter && sentToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Sent Date: From: ${Moment(sentFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(sentToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && sentFromDateFilter && !sentToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Sent Date: From: ${Moment(sentFromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !sentFromDateFilter && sentToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Sent Date: To: ${Moment(sentToDateFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && sentFromDateAdvFilter && sentToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Sent Date: From: ${Moment(sentFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(sentToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && sentFromDateAdvFilter && !sentToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Sent Date: From: ${Moment(sentFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !sentFromDateAdvFilter && sentToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Sent Date: To: ${Moment(sentToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                    </React.Fragment>
                </div>
                {quickFiltersClicked && (
                    (userActions?.includes("43") || userActions?.includes("20")) ? (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Execution ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={executionID} onChange={handleExecutionIDFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Feed Status:</span>
                                <br />
                                <FormControl label="Select feed status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedStatus}
                                        onChange={handleFeedStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsStatusesList?.map((c, ci) => (
                                            <MenuItem key={`feed-status-${ci}`} value={c?.FeedStatusID}>{c?.FeedStatusName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Feed Phase:</span>
                                <br />
                                <FormControl label="Select phase" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedPhase}
                                        onChange={handleFeedPhaseChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsPhasesList?.map((c, ci) => (
                                            <MenuItem key={`feed-phase-${ci}`} value={c?.FeedPhaseID}>{c?.FeedPhaseName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Transmission Status:</span>
                                <br />
                                <FormControl label="Select execution status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={executionStatus}
                                        onChange={handleExecutionStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {executionStatusesList?.map((c, ci) => (
                                            <MenuItem key={`execution-status-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Processing Step:</span>
                                <br />
                                <FormControl label="Select processing step" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={processingStep}
                                        onChange={handleProcessingStepChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {processingStepsList?.map((c, ci) => (
                                            <MenuItem key={`processing-step-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Request Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedFromDate}
                                            onChange={handleDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedToDate}
                                            onChange={handleDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Sent Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedSentFromDate}
                                            onChange={handleSentDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedSentToDate}
                                            onChange={handleSentDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Feed Status:</span>
                                <br />
                                <FormControl label="Select status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedStatus}
                                        onChange={handleFeedStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsStatusesList?.map((c, ci) => (
                                            <MenuItem key={`feed-status-${ci}`} value={c?.FeedStatusID}>{c?.FeedStatusName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Feed Phase:</span>
                                <br />
                                <FormControl label="Select phase" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={feedPhase}
                                        onChange={handleFeedPhaseChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {feedsPhasesList?.map((c, ci) => (
                                            <MenuItem key={`feed-phase-${ci}`} value={c?.FeedPhaseID}>{c?.FeedPhaseName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Request Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedFromDate}
                                            onChange={handleDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedToDate}
                                            onChange={handleDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Sent Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedSentFromDate}
                                            onChange={handleSentDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedSentToDate}
                                            onChange={handleSentDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Processing Step:</span>
                                <br />
                                <FormControl label="Select processing step" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={processingStep}
                                        onChange={handleProcessingStepChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {processingStepsList?.map((c, ci) => (
                                            <MenuItem key={`processing-step-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Transmission Status:</span>
                                <br />
                                <FormControl label="Select execution status" focused={false} className="w-100">
                                    <Select
                                        className="search-dropdown"
                                        value={executionStatus}
                                        onChange={handleExecutionStatusChange}
                                    >
                                        <MenuItem value="">None</MenuItem>
                                        {executionStatusesList?.map((c, ci) => (
                                            <MenuItem key={`execution-status-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    ))
                }
                <div className="col-xl-12 mt-4" >
                    <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                            <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                        </svg>
                        <span className="ml-1">Refresh List</span>
                    </Button>
                    <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                        </svg> Clear Search
                    </Button>
                </div>
            </div >

            <AnalystTransmissionsFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                onPageSizeAdv={onPageSizeAdv}
                onSelectedPageAdv={onSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                onCloseClearReset={handleClearSearchReset}
                onSelectedFeedStatusAdvFilter={onSelectedFeedStatusAdvFilter}
                onSelectedFeedPhaseAdvFilter={onSelectedFeedPhaseAdvFilter}
                onExecutionIDAdvFilter={onExecutionIDAdvFilter}
                onSelectedExecutionStatusAdvFilter={onSelectedExecutionStatusAdvFilter}
                onSelectedProcessingStepAdvFilter={onSelectedProcessingStepAdvFilter}
                onRequestFromDateAdvFilter={onRequestFromDateAdvFilter}
                onRequestToDateAdvFilter={onRequestToDateAdvFilter}
                onSentFromDateAdvFilter={onSentFromDateAdvFilter}
                onSentToDateAdvFilter={onSentToDateAdvFilter}
            />
        </div >
    )
}

export default AnalystSingleTransmissionFilter;