import React from "react";
import Button from "@material-ui/core/Button";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { FailureModal } from "../Modals/FailureModal";

function AddEncryptionKey({ carrierID, ...props }) {

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [encryptionKeyInput, setEncryptionKeyInput] = React.useState();
    const [encryptionKeyInputValidation, setEncryptionKeyInputValidation] = React.useState("");
    const [encryptionKeyExistsValidation, setEncryptionKeyExistsValidation] = React.useState("");
    const [addKeyClicked, setAddKeyClicked] = React.useState(false);

    const handleEncryptionKeyChange = (e) => {
        setEncryptionKeyInput(e.target.value);
        setEncryptionKeyExistsValidation("");
    }

    const addPublicKey = () => {
        setAddKeyClicked(true);
        openLoader();
        const addKey = {
            EncryptionKey: btoa(encryptionKeyInput),
            CarrierID: carrierID
        }

        APIs.addEncryptionKey(addKey).then((r) => {
            if (r?.success === "0") {
                setEncryptionKeyExistsValidation(r?.msg);
                setAddKeyClicked(false);
            }
            else {
                dialog.closeDialog();
                window.location.reload();
            }
        });
    }

    const handleEncryptionKeyCharRestriction = (e) => {
        if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            setEncryptionKeyInputValidation(e.key === " " ? `Encryption key should not contain any spaces` : e.key === "Enter" ? `Encryption key should not contain any new line` : "");
        } else {
            setEncryptionKeyInputValidation("");
        }
    }

    return (
        <div>
            {/* <h5 className="dialog-public-key-title">Add Public Key</h5> */}
            <textarea onKeyDown={handleEncryptionKeyCharRestriction} value={encryptionKeyInput?.replace(/\n*$/, "")} onChange={handleEncryptionKeyChange} className="public-key-textarea" rows={5} />
            <div className="mt-2 text-danger fs-14">{encryptionKeyInputValidation}</div>
            <div className="mt-2 text-danger fs-14">{encryptionKeyExistsValidation}</div>
            <div className="mt-3 text-right">
                <Button className="blue-outlined-btn mr-2" disabled={addKeyClicked} onClick={(e) => dialog.closeDialog()}>Cancel</Button>
                <Button disabled={!encryptionKeyInput || addKeyClicked} className="blue-btn" onClick={addPublicKey}>Add</Button>
            </div>
        </div>
    )
}

export default AddEncryptionKey;