import React from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { FailureModal } from "../../../Modals/FailureModal";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs"
import DownloadLink from "react-download-link";
import Moment from 'moment';
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { Button } from "@material-ui/core";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import downloadIconSVG from "../../../../Icons/downloadIcon.svg";

function UploadedFileAI({ uploadedFile, actionItem, contact, ...props }) {
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();


    const [decodedString, setDecodedString] = React.useState("");




    React.useEffect(() => {
        handleDownloadFile();
    }, [decodedString])

    const handleDownloadFile = () => {
        const submitDownloadFile = {
            ActionItemID: actionItem.Id,
            guid: uploadedFile.guid,
            attachmentExtension: uploadedFile.extension,
        }

        APIs.getActionItemSingleFileAttachment(submitDownloadFile).then((r) => {
            setDecodedString(r?.data);

        });
    }
    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }


    const handleDeleteFile = () => {
        openModal(0, deleteFile, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this file?`,
        })
    }
    const deleteFile = () => {
        openLoader();
        let editAIBody = {}
        if (actionItem?.ResponsiblePartyID === 2) {
            editAIBody = {
                ActionItemID: actionItem?.Id,
                Status: actionItem?.Status,
                CompletionDate: (actionItem?.Status === 1 || actionItem?.Status === 3) ? null : Moment(actionItem?.CompletionDate).format("YYYY-MM-DD"),
                CloseReason: (actionItem?.Status === 1 || actionItem?.Status === 3) ? "" : actionItem?.CloseReason,
                NewAttachments: [{
                    Type: "ActionItem",
                    Name: uploadedFile?.Name,
                    Extension: uploadedFile?.extension,
                    GUID: uploadedFile?.guid,
                    isDeleted: 1
                }],
                ResponsiblePartyID: actionItem?.ResponsiblePartyID,
                CarrierContactID: contact?.Id,
                ActionItemText: actionItem?.ActionItemText,
                Description: actionItem?.Description.replaceAll("\n", "<br/>"),
                DueDate: actionItem?.DueDate,
            }
        } else {
            editAIBody = {
                ActionItemID: actionItem?.Id,
                Status: actionItem?.Status,
                CompletionDate: (actionItem?.Status === 1 || actionItem?.Status === 3) ? null : Moment(actionItem?.CompletionDate).format("YYYY-MM-DD"),
                CloseReason: (actionItem?.Status === 1 || actionItem?.Status === 3) ? "" : actionItem?.CloseReason,
                NewAttachments: [{
                    Type: "ActionItem",
                    Name: uploadedFile?.Name,
                    Extension: uploadedFile?.extension,
                    GUID: uploadedFile?.guid,
                    isDeleted: 1
                }],
                ResponsiblePartyID: actionItem?.ResponsiblePartyID,
                ContactID: contact?.Id,
                ActionItemText: actionItem?.ActionItemText,
                Description: actionItem?.Description.replaceAll("\n", "<br/>"),
                DueDate: actionItem?.DueDate,
            }
        }

        APIs.editActionItem(editAIBody)
            .then(r => {
                window.location.reload();
            })
            .finally(t => closeLoader())

    }
    return (
        <>
            <div className="col-9 table-cell first-cell">
                {uploadedFile?.Name}
            </div>

            <div className="col-3 table-cell alignvh-center last-cell" style={{ justifyContent: "center", cursor: "pointer" }} >
                <DownloadLink
                    style={{ fontSize: 30 }}
                    label={
                        <svg width="20" height="20" viewBox="0 0 20 20" onClick={handleDownloadFile}>
                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                        </svg>

                    }
                    filename={uploadedFile.Name}
                    exportFile={() => {
                        let binaryString = window.atob(decodedString);
                        let binaryLen = binaryString.length;
                        let ab = new ArrayBuffer(binaryLen);
                        let ia = new Uint8Array(ab);
                        for (let i = 0; i < binaryLen; i++) {
                            ia[i] = binaryString.charCodeAt(i);
                        }
                        let bb = new Blob([ab]);
                        return bb;
                    }}
                />

                {/* <DeleteForeverIcon className="ml-3" onClick={handleDeleteFile} color="action" style={{ fontSize: "30px", cursor: "pointer" }} /> */}
                <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={(e) => handleDeleteFile()} style={{ cursor: "pointer" }}>
                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                </svg>
            </div>


        </>


    )
}

export default UploadedFileAI;