import React, { useRef } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import * as APIs from "../../../Data/APIs";
import MapInternalCarrier from "./MapInternalCarrier";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { Button } from "@material-ui/core";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
function SelectCarriersAndPlans({
    parentForceUpdate,
    carriers,
    setSelectedCarriers,
    selectedCarriers,
    selectedThirdParties,
    setSelectedThirdParties,
    carrierSearchValue,
    onIndexCallback,
    onInternalCarrier,
    ...props }) {

    const dialog = useModalDialog(0);
    const [checkedCarrier, setCheckedCarrier] = React.useState(false);
    const [index, setIndex] = React.useState(-1);
    const [, forceUpdate] = React.useReducer(x => !x, false);

    const handleCarrierChange = (value) => (e) => {
        setCheckedCarrier(e.target.checked);
    }

    const handleCarrierClick = (value) => (event) => {
        let selectedCarriersArray = selectedCarriers;
        if (!value || value === "") selectedCarriersArray = [];
        else if (selectedCarriersArray.indexOf(value) >= 0) {
            value.CarrierID = null;
            value.CarrierInternalName = "";
            // selectedCarriersArray.splice(selectedCarriersArray.indexOf(value), 1);
            // when un-check carrier remove all selected carriers who's have same carrier Code
            for (let i = 0; i < selectedCarriersArray.length; i++) {
                if (selectedCarriersArray[i]?.id == value?.id) {
                    selectedCarriersArray?.splice(i, 1); // remove the i-th element from the array
                    i--; // decrement i to account for the removed element
                }
            }
        }
        else {
            value.SelectedCRTypes = ["EDI"];
            value.ConnectivityRequestEDISubTypeID = 1;
            selectedCarriersArray.push(value);
            forceUpdate();
            if (value.InternalCarrierID === null)
                dialog.openDialog("Map Carriers", <MapInternalCarrier iSolvedCarrier={value} forceCarrierListUpdate={parentForceUpdate} />, []);
        }
        setSelectedCarriers([...selectedCarriersArray]);
        setIndex(selectedCarriers.indexOf(value));
        onIndexCallback(selectedCarriers.indexOf(value));
        parentForceUpdate();
    }

    const handleThirdPartyClick = (value) => (event) => {
        let selectedThirdPartyArray = selectedThirdParties;
        if (!value || value === "") selectedThirdPartyArray = [];
        else if (selectedThirdPartyArray.indexOf(value) >= 0) {
            value.CarrierID = null;
            value.CarrierInternalName = "";
            selectedThirdPartyArray.splice(selectedThirdPartyArray.indexOf(value), 1);
        }
        else {
            value.SelectedCRTypes = ["EDI"];
            value.ConnectivityRequestEDISubTypeID = 1;
            selectedThirdPartyArray.push(value);
            forceUpdate();
            if (value.InternalCarrierID === null)
                dialog.openDialog("Map Carriers", <MapInternalCarrier iSolvedCarrier={value} forceCarrierListUpdate={parentForceUpdate} />, []);
        }
        setSelectedThirdParties([...selectedThirdPartyArray]);
        setIndex(selectedCarriers.indexOf(value));
        onIndexCallback(selectedCarriers.indexOf(value));
    }

    const getLastCarrierFromSelectedCarriersWithSameID = (arr, id) => {
        for (let i = arr?.length - 1; i >= 0; i--) {
            if (arr[i]?.id == id) {
                return i;
            }
        }
        return -1; // return -1 if there is no object has this carrierCode
    };

    const reformatCarrierToPush = (selectedCarrier) => {
        let returnedCarrier = {
            carrierCode: selectedCarrier.carrierCode,
            carrierName: selectedCarrier.carrierName,
            carrierType: selectedCarrier.carrierType,
            id: selectedCarrier.id,
            isolvedPlanTypes: selectedCarrier?.isolvedPlanTypes ?? [],
            partnerCode: selectedCarrier?.partnerCode,
            partnerName: selectedCarrier?.partnerName,
            planTypesList: selectedCarrier?.planTypesList ?? [],
            SelectedCRTypes: ["EDI"],
            ConnectivityRequestEDISubTypeID: 1,
            Migration: selectedCarrier.Migration,
            MigrationStartDate: null,
            PlannedFirstProductionDate: null,
            Attachments: [],
            CarrierID: selectedCarrier?.CarrierID,
            isOtherChecked: false
        }
        if (selectedCarrier.Migration && selectedCarrier.Migration != "No")
            returnedCarrier.MigrationPhase = 2

        // mapppedCarrier by popup 
        if (selectedCarrier.CarrierInternalName) {
            returnedCarrier.CarrierInternalName = selectedCarrier?.CarrierInternalName;
            returnedCarrier.InternalCarrierID = null;
            returnedCarrier.InternalCarrierName = null;
        } else if (selectedCarrier?.InternalCarrierName && selectedCarrier?.InternalCarrierID) {
            //mappedCarrier by default (no need to map it again)
            returnedCarrier.InternalCarrierID = selectedCarrier?.InternalCarrierID;
            returnedCarrier.InternalCarrierName = selectedCarrier?.InternalCarrierName;
        }
        return returnedCarrier;
    }

    const handleCarrierClickPlus = (value) => {
        let selectedCarriersArray = selectedCarriers;
        let lastIndexOfSelectedCrrier = getLastCarrierFromSelectedCarriersWithSameID(selectedCarriersArray, value?.id)
        let reformatedSelectedCarrier = reformatCarrierToPush({ ...value })

        //to push carrier to selected carrier beside last one has same CarrierCode
        selectedCarriersArray.splice(lastIndexOfSelectedCrrier + 1, 0, { ...reformatedSelectedCarrier });
        forceUpdate();
        setSelectedCarriers([...selectedCarriersArray]);
    }

    const handleCarrierClickMinuis = (value) => {
        let selectedCarriersArray = selectedCarriers;
        let lastIndexOfSelectedCrrier = getLastCarrierFromSelectedCarriersWithSameID(selectedCarriersArray, value?.id)
        selectedCarriersArray.splice(lastIndexOfSelectedCrrier, 1);

        forceUpdate();
        setSelectedCarriers([...selectedCarriersArray]);
    }

    React.useEffect(() => {
        if (!selectedCarriers) return;
        const selectedCarrier = selectedCarriers[index];
        if (!selectedCarrier) return;
        selectedCarrier.CarrierID = onInternalCarrier;
    }, [onInternalCarrier]);

    return (
        <div id="select-carriers-plans">
            {carriers?.filter(s => carrierSearchValue ? s.carrierName?.toLowerCase().includes(carrierSearchValue.toLowerCase()) : s).map((c, ci) => (
                <Accordion key={`carriers-${ci}`} expanded={false}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="carriers-select"
                        className="accordion-checkboxes select-carrier-accordion"

                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                                <>
                                    <Checkbox
                                        id={`carrier-option-${ci}`}
                                        value={c}
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        checked={(selectedCarriers && selectedCarriers.indexOf(c) >= 0) || (selectedThirdParties && selectedThirdParties.indexOf(c) >= 0)}
                                        onChange={handleCarrierChange(c)}
                                        onClick={c?.carrierName === "Third Party" ? handleThirdPartyClick(c) : handleCarrierClick(c)}
                                        checkedIcon={<DoneOutlineIcon />}
                                        color="default"
                                    />


                                    <CustomTooltip TransitionComponent={Zoom} title={selectedCarriers.indexOf(c) >= 0 && c.InternalCarrierID !== null ? `Already mapped to "${c.InternalCarrierName}"` : ""}>
                                        <span style={{ position: "absolute", right: "30px", top: "6px" }}>
                                            <Button disabled={selectedCarriers.indexOf(c) < 0 || c.InternalCarrierID !== null} className="blue-outlined-btn" onClick={() => {
                                                setIndex(selectedCarriers.indexOf(c));
                                                onIndexCallback(selectedCarriers.indexOf(c));
                                                dialog.openDialog("Map Carriers", <MapInternalCarrier iSolvedCarrier={c} forceCarrierListUpdate={parentForceUpdate} />, []);
                                                forceUpdate();
                                            }}>Map Carrier</Button>
                                        </span>
                                    </CustomTooltip>

                                    

                                </>
                            }
                            label={c?.carrierName}
                        />
                        {(selectedCarriers && selectedCarriers.indexOf(c) > -1 && (c?.InternalCarrierID || c?.CarrierInternalName)) &&
                            <div className="my-auto" onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}>
                                <button type="button" className="plus-carrier-btn"
                                    disabled={selectedCarriers.filter(sc => sc?.id == c.id)?.length < 2}
                                    onClick={() => selectedCarriers.filter(sc => sc?.id == c.id)?.length > 1 && handleCarrierClickMinuis(c)}>
                                    <RemoveOutlinedIcon className={`color ${selectedCarriers.filter(sc => sc?.id == c.id)?.length > 1 ? 'blue-main-color' : 'grey1'}`} />
                                </button>
                                <button type="button" className="plus-carrier-btn"
                                    onClick={() => handleCarrierClickPlus(c)}><AddOutlinedIcon className="blue-main-color color" /></button>
                            </div>
                        }
                        {selectedCarriers.filter(sc => sc?.id == c?.id)?.length > 1 &&
                                        <div className="counter-div-carriers" style={{ whiteSpace: 'nowrap' }}> {selectedCarriers.filter(sc => sc?.id == c.id)?.length}</div>}

                    </AccordionSummary>
                </Accordion>
            ))
            }
        </div>
    )
}

export default SelectCarriersAndPlans;
