import React from "react";
import { Divider, Paper, Select, TextField, Button } from "@material-ui/core";
import AnalystCreateSchedulerConfiguration from "./AnalystCreateSchedulerConfiguration";
import SchedulerObject from "./SchedulerObject";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AnalystCreateSchedulers({
    feedSchedulers,
    onSetFeedSchedulers,
    schedulersValidationForm,
    onSetSchedulersValidationForm,
    requestSubmitted,
    feedStatusID,
    onEdit = false,
    ...props
}) {
    const [, forceUpdate] = React.useReducer(x => !x, false);

    React.useEffect(() => {
        if (feedSchedulers?.filter((s) => !s.Type ||
            (s.Type === "WeeklyScheduler" && s.WeekDay.length <= 0) ||
            (s.Type === "MonthlyScheduler" && !s.MonthlyType) ||
            (s.Type === "MonthlyScheduler" && s.MonthlyType == 1 && !s.DayOfMonth) ||
            (s.Type === "MonthlyScheduler" && s.MonthlyType == 2 && !s.WeekDay) ||
            (s.Type === "MonthlyScheduler" && s.MonthlyType == 2 && !s.PartType)).length > 0) {
            onSetSchedulersValidationForm("Scheduler  Error.");
        }
        else {
            onSetSchedulersValidationForm("");
        }
    }, [feedSchedulers, schedulersValidationForm])

    const handelAddNewScheduler = (e) => {
        let newArr = [...feedSchedulers];
        newArr.push(new SchedulerObject({}));
        onSetFeedSchedulers(newArr);

    }

    const handelDeleteScheduler = (scheduler) => {
        let newArr = [...feedSchedulers];
        newArr.splice(newArr.indexOf(scheduler), 1);
        onSetFeedSchedulers([...newArr]);
        forceUpdate();
    }
    return (
        <div id="scheduler-config" className="mt-5">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel-header-scheduler"
                >
                    <span>Scheduler Configuration</span>
                    <Button variant="contained" className="blue-btn ml-auto" onClick={(e) => { handelAddNewScheduler(); e.stopPropagation() }}>
                        <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                        </svg>
                        Add New Scheduler
                    </Button>
                </AccordionSummary>
                <AccordionDetails style={{ flexWrap: "wrap" }}>
                    {feedSchedulers && feedSchedulers.length > 0 && feedSchedulers.map((s, si) => (
                        <AnalystCreateSchedulerConfiguration
                            key={`feedScheduler-number-${si}`}
                            index={si}
                            scheduler={s}
                            feedSchedulers={feedSchedulers}
                            onSetFeedSchedulers={onSetFeedSchedulers}
                            requestSubmitted={requestSubmitted}
                            onEdit={onEdit}
                            onHandelDeleteScheduler={(s) => handelDeleteScheduler(s)}
                            feedStatusID={feedStatusID}
                        />

                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );


}
export default AnalystCreateSchedulers;