import React from "react";
import { useParams } from "react-router-dom";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import { InputBase, withStyles, NativeSelect, FormControl, Paper, Button, TextField } from "@material-ui/core";
import MultipleTabsTitles from "../../../Layout/MultipleTabsTitles";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import Moment from 'moment';
import Autocomplete from "@material-ui/lab/Autocomplete";
import AnalystConnectivityRequestActionItemsTable from "./AnalystConnectivityRequestActionItemsTable";
import RefreshIcon from '@material-ui/icons/Refresh';
import { useAuthentication } from "../../../../Data/Authentication";
import { useHistory } from "react-router-dom";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import TruncatedChip from "../../../Layout/TruncatedChip";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Chip from '@mui/material/Chip';
import CRActionItemsFilterModalBody from "./CRActionItemsFilterModalBody";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/system';
import refreshSVG from "../../../../Icons/refresh.svg";
import cancelIconSVG from "../../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../../Icons/advancedFilter.svg";
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});

function AnalystConnectivityRequestActionItems() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();

    const { connectivityRequestIDURL } = useParams();
    const [value, setValue] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [totalCount, setTotalCount] = React.useState(0);
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [actionItemsList, setActionItemsList] = React.useState([]);
    const [responsiblePartiesList, setResponsiblePartiesList] = React.useState([]);
    const [contactsList, setContactsList] = React.useState([]);
    const [contactsAnalystsList, setContactsAnalystsList] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [statusesList, setStatusesList] = React.useState([]);
    const [statusFilter, setStatusFilter] = React.useState();
    const [actionItemIDFilter, setActionItemIDFilter] = React.useState();
    const [connectivityRequestIDFilter, setConnectivityRequestIDFilter] = React.useState();
    const [responsiblePartyFilter, setResponsiblePartyFilter] = React.useState("");
    const [contactNameFilter, setContactNameFilter] = React.useState("");
    const [contactNameAutoValue, setContactNameAutoValue] = React.useState("");
    const [requestorFilter, setRequestorFilter] = React.useState("");
    const [requestorNameAutoValue, setRequestorNameAutoValue] = React.useState("");
    const [lastModifiedByFilter, setLastModifiedByFilter] = React.useState("");
    const [lastModifiedByAutoValue, setLastModifiedByAutoValue] = React.useState("");
    const [actionItemFilter, setActionItemFilter] = React.useState("");
    const [selectedStartFromDateFilter, setSelectedStartFromDateFilter] = React.useState(null);
    const [selectedStartToDateFilter, setSelectedStartToDateFilter] = React.useState(null);
    const [selectedDueFromDateFilter, setSelectedDueFromDateFilter] = React.useState(null);
    const [selectedDueToDateFilter, setSelectedDueToDateFilter] = React.useState(null);
    const [selectedCompletionFromDateFilter, setSelectedCompletionFromDateFilter] = React.useState(null);
    const [selectedCompletionToDateFilter, setSelectedCompletionToDateFilter] = React.useState(null);
    const [selectedLastModifiedFromDateFilter, setSelectedLastModifiedFromDateFilter] = React.useState(null);
    const [selectedLastModifiedToDateFilter, setSelectedLastModifiedToDateFilter] = React.useState(null);
    const [refreshClicked, setRefreshClicked] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(1);
    const [escalatedFilter, setEscalatedFilter] = React.useState("");
    const [primaryAnalystFilter, setPrimaryAnalystFilter] = React.useState("");
    const [isolvedRepresentativeFilter, setIsolvedRepresentativeFilter] = React.useState("");
    const [primaryAnalysAutoValue, setPrimaryAnalysAutoValue] = React.useState("");
    const [isolvedRepresentativeAutoValue, setIsolvedRepresentativeAutoValue] = React.useState("");
    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);
    const [agingActionFilter, setAgingActionFilter] = React.useState("");


    //advanced filters
    const [selectedContactsFilter, setSelectedContactsFilter] = React.useState([]);
    const [selectedRequestorsFilter, setSelectedRequestorsFilter] = React.useState([]);
    const [selectedLastModifiedByFilter, setSelectedLastModifiedByFilter] = React.useState([]);
    const [selectedStatusesFilter, setSelectedStatusesFilter] = React.useState([]);

    const [actionItemIDAdvFilter, setActionItemIDAdvFilter] = React.useState();
    const [actionItemAdvFilter, setActionItemAdvFilter] = React.useState("");
    const [responsiblePartyAdvFilter, setResponsiblePartyAdvFilter] = React.useState("");
    const [selectedStartFromDateAdvFilter, setSelectedStartFromDateAdvFilter] = React.useState(null);
    const [selectedStartToDateAdvFilter, setSelectedStartToDateAdvFilter] = React.useState(null);
    const [selectedDueFromDateAdvFilter, setSelectedDueFromDateAdvFilter] = React.useState(null);
    const [selectedDueToDateAdvFilter, setSelectedDueToDateAdvFilter] = React.useState(null);
    const [selectedCompletionFromDateAdvFilter, setSelectedCompletionFromDateAdvFilter] = React.useState(null);
    const [selectedCompletionToDateAdvFilter, setSelectedCompletionToDateAdvFilter] = React.useState(null);
    const [selectedLastModifiedFromDateAdvFilter, setSelectedLastModifiedFromDateAdvFilter] = React.useState(null);
    const [selectedLastModifiedToDateAdvFilter, setSelectedLastModifiedToDateAdvFilter] = React.useState(null);
    const [primaryAnalystAdvFilter, setPrimaryAnalystAdvFilter] = React.useState([]);
    const [isolvedRepresentativeAdvFilter, setIsolvedRepresentativeAdvFilter] = React.useState([]);

    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("");
    const [escalatedAdvFilter, setEscalatedAdvFilter] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const connectivityTabs = ["Connectivity Request Configuration", "Action Items", "OE Requests"];
    const [groupReqStatus, setGroupReqStatus] = React.useState("");
    const [agingActionAdvFilter, setAgingActionAdvFilter] = React.useState("");


    React.useEffect(() => {
        openLoader();
        APIs.getGroupRequestData(connectivityReq?.GroupRequestID).then((r) => {
            setGroupReqStatus(r?.data?.GroupRequest?.GroupRequestStatusID); closeLoader();
        });
    }, [connectivityReq]);

    const handleClickOpenDialog = () => {
        setActionItemIDFilter("");
        setActionItemFilter("");
        setResponsiblePartyFilter("");
        setContactNameFilter("");
        setContactNameAutoValue("");
        setRequestorFilter("");
        setRequestorNameAutoValue("");
        setLastModifiedByFilter("");
        setLastModifiedByAutoValue("");
        setSelectedLastModifiedFromDateFilter(null);
        setSelectedLastModifiedToDateFilter(null);
        setSelectedStartFromDateFilter(null);
        setSelectedStartToDateFilter(null);
        setSelectedDueFromDateFilter(null);
        setEscalatedFilter("");
        setSelectedDueToDateFilter(null);
        setSelectedCompletionFromDateFilter(null);
        setSelectedCompletionToDateFilter(null);
        setStatusFilter(undefined);
        setPrimaryAnalystFilter("");
        setIsolvedRepresentativeFilter("");
        setAgingActionFilter("");
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
    };

    const handleTabChange = (e, newValue) => {
        openLoader();
        setTabValue(newValue);
        if (newValue === 1)
            routeHistory.push(`/ActionItems/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`);
        else if (newValue === 2)
            routeHistory.push(`/OERequests/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`);
        else routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`);
    };

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => { setConnectivityReq(r?.data); closeLoader(); });
    }, [])

    React.useEffect(() => {
        APIs.getActionItemStatusesList().then(r => !(r?.data?.length) ? setStatusesList([]) : setStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getResponsiblePartiesList().then(r => !(r?.data?.length) ? setResponsiblePartiesList([]) : setResponsiblePartiesList(r?.data));
    }, [])


    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])


    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: parseInt(responsiblePartyFilter),
            ConnectivityRequestID: connectivityRequestIDURL
        }

        APIs.getContactsList(contactsBody).then((r) => {
            !(r?.data?.length) ? setContactsList([]) : setContactsList(r?.data);
        })
    }, [responsiblePartyFilter, connectivityRequestIDFilter])

    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: 3,
            ConnectivityRequestID: connectivityRequestIDURL
        }

        APIs.getContactsList(contactsBody).then((r) => {
            !(r?.data?.length) ? setContactsAnalystsList([]) : setContactsAnalystsList(r?.data);
        })
    }, [responsiblePartyFilter, connectivityRequestIDFilter])



    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        setContactNameAutoValue(`${contactNameFilter?.FirstName ?? ""} ${contactNameFilter?.LastName ?? ""}`);
    }, [contactNameFilter])

    React.useEffect(() => {
        setRequestorNameAutoValue(`${requestorFilter?.FirstName ?? ""} ${requestorFilter?.LastName ?? ""}`);
    }, [requestorFilter])

    React.useEffect(() => {
        setLastModifiedByAutoValue(`${lastModifiedByFilter?.FirstName ?? ""} ${lastModifiedByFilter?.LastName ?? ""}`);
    }, [lastModifiedByFilter])

    React.useEffect(() => {
        setPrimaryAnalysAutoValue(`${primaryAnalystFilter?.FirstName ?? ""} ${primaryAnalystFilter?.LastName ?? ""}`);
    }, [primaryAnalystFilter])

    React.useEffect(() => {
        setIsolvedRepresentativeAutoValue(`${isolvedRepresentativeFilter?.FirstName ?? ""} ${isolvedRepresentativeFilter?.LastName ?? ""}`);
    }, [isolvedRepresentativeFilter])


    const getActionItemsQuickData = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPage >= 1) {
            requestParameters.page = selectedPage;
            requestParameters.limit = pageSize;
        }
        if (pageSize >= 10) {
            requestParameters.limit = pageSize;
            requestParameters.page = selectedPage;
        }
        if (statusFilter !== "" || statusFilter !== undefined)
            requestParameters.StatusIDsArray = (statusFilter === undefined || !statusFilter) ? "" : [statusFilter];
        requestParameters.ConnectivityRequestID = connectivityRequestIDURL;
        if (responsiblePartyFilter !== "")
            requestParameters.ResponsiblePartyID = parseInt(responsiblePartyFilter);
        if (contactNameFilter !== "")
            requestParameters.ContactIDsArray = [contactNameFilter?.Id];
        if (actionItemIDFilter !== "")
            requestParameters.ActionItemID = parseInt(actionItemIDFilter);
        if (requestorFilter !== "")
            requestParameters.RequestorCognitoIDsArray = requestorFilter === undefined ? "" : [requestorFilter?.CognitoID];
        if (lastModifiedByFilter !== "")
            requestParameters.LastModifiedByCognitoIDsArray = lastModifiedByFilter === undefined ? "" : [lastModifiedByFilter?.CognitoID];
        if (actionItemFilter !== "")
            requestParameters.ActionItemText = actionItemFilter;
        if (selectedStartFromDateFilter !== null)
            requestParameters.StartDateFrom = selectedStartFromDateFilter ? Moment(selectedStartFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedStartToDateFilter !== null)
            requestParameters.StartDateTo = selectedStartToDateFilter ? Moment(selectedStartToDateFilter).format('YYYY-MM-DD') : null;
        if (selectedDueFromDateFilter !== null)
            requestParameters.DueDateFrom = selectedDueFromDateFilter ? Moment(selectedDueFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedDueToDateFilter !== null)
            requestParameters.DueDateTo = selectedDueToDateFilter ? Moment(selectedDueToDateFilter).format('YYYY-MM-DD') : null;
        if (selectedCompletionFromDateFilter !== null)
            requestParameters.CompletionDateFrom = selectedCompletionFromDateFilter ? Moment(selectedCompletionFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedCompletionToDateFilter !== null)
            requestParameters.CompletionDateTo = selectedCompletionToDateFilter ? Moment(selectedCompletionToDateFilter).format('YYYY-MM-DD') : null;
        if (selectedLastModifiedFromDateFilter !== null)
            requestParameters.DateModifiedFrom = selectedLastModifiedFromDateFilter ? Moment(selectedLastModifiedFromDateFilter).format('YYYY-MM-DD') : null;
        if (selectedLastModifiedToDateFilter !== null)
            requestParameters.DateModifiedTo = selectedLastModifiedToDateFilter ? Moment(selectedLastModifiedToDateFilter).format('YYYY-MM-DD') : null;

        if (primaryAnalystFilter !== "")
            requestParameters.PrimaryAnalystCognitoIDsArray = [primaryAnalystFilter.CognitoID];
        if (isolvedRepresentativeFilter !== "")
            requestParameters.IsolvedRepresentativeCognitoIDsArray = [isolvedRepresentativeFilter.CognitoID];
        if (agingActionFilter !== null)
            requestParameters.AgingActions = parseInt(agingActionFilter);
        if (sortFieldFilter !== "")
            requestParameters.sortField = sortFieldFilter;
        if (sortOrderFilter !== "")
            requestParameters.sortOrder = sortOrderFilter;
        if (escalatedFilter !== "")
            requestParameters.ActionItemSeverity = escalatedFilter;
        APIs.getActionItemsList(requestParameters).then((r) => (
            setActionItemsList(r?.data),
            closeLoader(),
            setRefreshClicked(false),
            setTotalCount(r?.totalCount ?? 0)
        ));
    }

    const getActionItemsAdvancedData = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPageAdv >= 1) {
            requestParameters.page = selectedPageAdv;
            requestParameters.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            requestParameters.limit = pageSizeAdv;
            requestParameters.page = selectedPageAdv;
        }
        if (selectedStatusesFilter.length > 0)
            requestParameters.StatusIDsArray = selectedStatusesFilter?.map(s => s?.Id);
        if (actionItemIDAdvFilter !== "")
            requestParameters.ActionItemID = parseInt(actionItemIDAdvFilter);
        if (actionItemAdvFilter !== "")
            requestParameters.ActionItemText = actionItemAdvFilter;
        requestParameters.ConnectivityRequestID = connectivityRequestIDURL;
        if (responsiblePartyAdvFilter !== "")
            requestParameters.ResponsiblePartyID = parseInt(responsiblePartyAdvFilter);
        if (selectedContactsFilter.length > 0)
            requestParameters.ContactIDsArray = selectedContactsFilter?.map(s => s?.Id);
        if (selectedRequestorsFilter !== "")
            requestParameters.RequestorCognitoIDsArray = selectedRequestorsFilter?.map(s => s?.CognitoID);
        if (selectedLastModifiedByFilter.length > 0)
            requestParameters.LastModifiedByCognitoIDsArray = selectedLastModifiedByFilter?.map(s => s?.CognitoID);
        if (selectedStartFromDateAdvFilter !== null)
            requestParameters.StartDateFrom = selectedStartFromDateAdvFilter ? Moment(selectedStartFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedStartToDateAdvFilter !== null)
            requestParameters.StartDateTo = selectedStartToDateAdvFilter ? Moment(selectedStartToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedDueFromDateAdvFilter !== null)
            requestParameters.DueDateFrom = selectedDueFromDateAdvFilter ? Moment(selectedDueFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedDueToDateAdvFilter !== null)
            requestParameters.DueDateTo = selectedDueToDateAdvFilter ? Moment(selectedDueToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedCompletionFromDateAdvFilter !== null)
            requestParameters.CompletionDateFrom = selectedCompletionFromDateAdvFilter ? Moment(selectedCompletionFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedCompletionToDateAdvFilter !== null)
            requestParameters.CompletionDateTo = selectedCompletionToDateAdvFilter ? Moment(selectedCompletionToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedLastModifiedFromDateAdvFilter !== null)
            requestParameters.DateModifiedFrom = selectedLastModifiedFromDateAdvFilter ? Moment(selectedLastModifiedFromDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedLastModifiedToDateAdvFilter !== null)
            requestParameters.DateModifiedTo = selectedLastModifiedToDateAdvFilter ? Moment(selectedLastModifiedToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (escalatedAdvFilter !== "")
            requestParameters.ActionItemSeverity = escalatedAdvFilter;

        if (primaryAnalystAdvFilter.length > 0)
            requestParameters.PrimaryAnalystCognitoIDsArray = primaryAnalystAdvFilter?.map(s => s?.CognitoID);
        if (isolvedRepresentativeAdvFilter.length > 0)
            requestParameters.IsolvedRepresentativeCognitoIDsArray = isolvedRepresentativeAdvFilter?.map(s => s?.CognitoID);
        if (agingActionAdvFilter !== null)
            requestParameters.AgingActions = parseInt(agingActionAdvFilter);

        if (sortFieldAdvFilter !== "")
            requestParameters.sortField = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            requestParameters.sortOrder = sortOrderAdvFilter;
        APIs.getActionItemsList(requestParameters).then((r) => (
            setActionItemsList(r?.data),
            setRefreshClicked(false),
            setTotalCount(r?.totalCount ?? 0),
            setAdvancedFiltersAppliedClicked(false),
            closeLoader()
        ));
    }


    React.useEffect(() => {
        if (quickFiltersClicked) getActionItemsQuickData();
    }, [selectedPage, pageSize, statusFilter, responsiblePartyFilter, contactNameFilter, actionItemIDFilter, requestorFilter, lastModifiedByFilter, actionItemFilter, selectedStartFromDateFilter, selectedStartToDateFilter, selectedDueFromDateFilter, selectedDueToDateFilter, selectedCompletionFromDateFilter, selectedCompletionToDateFilter, selectedLastModifiedFromDateFilter, selectedLastModifiedToDateFilter, sortFieldFilter, sortOrderFilter, escalatedFilter, primaryAnalystFilter, isolvedRepresentativeFilter, refreshClicked, agingActionFilter])

    React.useEffect(() => {
        getActionItemsAdvancedData();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])

    React.useEffect(() => {
        getActionItemsQuickData();
    }, [quickFiltersClicked === true])

    React.useEffect(() => {
        getActionItemsAdvancedData();
    }, [advancedFiltersClicked === true])

    const handleStatusFilter = (e) => {
        setStatusFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleResponsiblePartyFilter = (e) => {
        setResponsiblePartyFilter(e.target.value);
        setContactNameFilter("");
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleActionItemIDFilter = (e) => {
        setActionItemIDFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }
    const handleEscalatedFilter = (e, newValue) => {
        setEscalatedFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleActionItemFilter = (e) => {
        setActionItemFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleAgingActionsFilter = (e, newValue) => {
        setAgingActionFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleStartDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedStartFromDateFilter(date);
        }
        else {
            setSelectedStartToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleDueDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedDueFromDateFilter(date);
        }
        else {
            setSelectedDueToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleCompletionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedCompletionFromDateFilter(date);
        }
        else {
            setSelectedCompletionToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleLastModifiedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedLastModifiedFromDateFilter(date);
        }
        else {
            setSelectedLastModifiedToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleClearSearch = () => {
        setValue(0);
        setStatusFilter(undefined);
        setResponsiblePartyFilter("");
        setContactNameFilter("");
        setContactNameAutoValue("");
        setRequestorFilter("");
        setRequestorNameAutoValue("");
        setLastModifiedByFilter("");
        setLastModifiedByAutoValue("");
        setActionItemFilter("");
        setActionItemIDFilter("");
        setSelectedStartFromDateFilter(null);
        setSelectedStartToDateFilter(null);
        setSelectedDueFromDateFilter(null);
        setSelectedDueToDateFilter(null);
        setEscalatedFilter("");
        setEscalatedAdvFilter("");
        setPrimaryAnalysAutoValue("");
        setIsolvedRepresentativeAutoValue("");
        setAgingActionFilter("");
        setPrimaryAnalystFilter("");
        setIsolvedRepresentativeFilter("");
        setSelectedCompletionFromDateFilter(null);
        setSelectedCompletionToDateFilter(null);
        setSelectedLastModifiedFromDateFilter(null);
        setSelectedLastModifiedToDateFilter(null);
        setSelectedPage(1);
    }

    const handleClearSearchReset = () => {
        setValue(0);
        setStatusFilter(undefined);
        setResponsiblePartyFilter("");
        setResponsiblePartyAdvFilter("");
        setContactNameFilter("");
        setContactNameAutoValue("");
        setRequestorFilter("");
        setActionItemIDFilter("");
        setActionItemIDAdvFilter("");
        setRequestorNameAutoValue("");
        setLastModifiedByFilter("");
        setLastModifiedByAutoValue("");
        setActionItemFilter("");
        setActionItemAdvFilter("");
        setSelectedStartFromDateFilter(null);
        setSelectedStartToDateFilter(null);
        setSelectedDueFromDateFilter(null);
        setSelectedDueToDateFilter(null);
        setSelectedCompletionFromDateFilter(null);
        setSelectedCompletionToDateFilter(null);
        setSelectedLastModifiedFromDateFilter(null);
        setSelectedLastModifiedToDateFilter(null);
        setSelectedStartFromDateAdvFilter(null);
        setSelectedStartToDateAdvFilter(null);
        setSelectedDueFromDateAdvFilter(null);
        setSelectedDueToDateAdvFilter(null);
        setSelectedCompletionFromDateAdvFilter(null);
        setSelectedCompletionToDateAdvFilter(null);
        setSelectedLastModifiedFromDateAdvFilter(null);
        setSelectedLastModifiedToDateAdvFilter(null);
        setPrimaryAnalysAutoValue("");
        setIsolvedRepresentativeAutoValue("");
        setAgingActionAdvFilter("");
        setPrimaryAnalystFilter("");
        setIsolvedRepresentativeFilter("");
        setPrimaryAnalystAdvFilter([]);
        setIsolvedRepresentativeAdvFilter([]);
        setEscalatedFilter("");
        setEscalatedAdvFilter("");
        setSelectedPage(1);
        setSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <div id="cr-view-action-items">
            <div className="d-flex flex-wrap">
                {connectivityReq && (
                    <BreadcrumbSteps
                        breadcrumbTitleCurrent={`${connectivityReq?.Peo?.PeoName ? connectivityReq?.Peo?.PeoName : connectivityReq?.GroupName}, ${connectivityReq?.CarrierName === "Third Party" ? `${connectivityReq?.CarrierName} (${connectivityReq?.ThirdPartyName})` : connectivityReq?.CarrierName}`}
                        isCurrent={true}
                    />
                )}
                {groupReqStatus === 4 && <div className="warmingOERequest ml-auto">  <ErrorOutlineIcon className="mr-1" />Kindly note that this connectivity request is attached to a draft group request</div>}
            </div>

            {quickFiltersClicked && (
                <div className="d-flex">
                    <MultipleTabsTitles tabTitle={connectivityTabs} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />
                </div>
            )}

            {(userActions?.includes("39") || userActions?.includes("20")) && (
                <div>
                    <div className="alignvh-center" style={{ position: "relative", justifyContent: "flex-end" }}>
                        <Button className="add-request blue-btn" style={advancedFiltersClicked ? { marginTop: "-45px" } : { marginTop: "-56px" }} href={`/ActionItems/ViewConnectivityRequest/${connectivityRequestIDURL}/CreateActionItem`}>
                            <svg width="12" height="12" viewBox="0 0 12 12" className="mt--1 mr-2">
                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                            </svg>
                            Action Item
                        </Button>
                    </div>
                </div>
            )}

            <Paper className="content-card-paper search-container">
                <div className="row alignvh-center">
                    <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                    <Button
                        className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                        onClick={handleClickOpenDialog}
                        style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1" >
                            <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                        </svg>Advanced Filters
                    </Button>
                    <React.Fragment>
                        {actionItemIDFilter && <TruncatedChip body={`Action Item ID: ${actionItemIDFilter}`} />}
                        {actionItemIDAdvFilter && <TruncatedChip body={`Action Item ID: ${actionItemIDAdvFilter}`} />}
                        {actionItemFilter && <TruncatedChip body={`Action Item: ${actionItemFilter}`} />}
                        {actionItemAdvFilter && <TruncatedChip body={`Action Item: ${actionItemAdvFilter}`} />}
                        {responsiblePartyFilter && <TruncatedChip body={`Responsible Party: ${responsiblePartiesList.filter(s => s.Id == responsiblePartyFilter)[0].Name}`} />}
                        {responsiblePartyAdvFilter && <TruncatedChip body={`Responsible Party: ${responsiblePartiesList.filter(s => s.Id == responsiblePartyAdvFilter)[0].Name}`} />}
                        {contactNameFilter && <TruncatedChip body={`Contact Name: ${contactNameFilter.FirstName} ${contactNameFilter.LastName}`} />}
                        {selectedContactsFilter && selectedContactsFilter?.length > 0 && <TruncatedChip body={`Contacts: ${selectedContactsFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}
                        {requestorFilter && <TruncatedChip body={`Requestor: ${requestorFilter.FirstName} ${requestorFilter.LastName}`} />}
                        {selectedRequestorsFilter && selectedRequestorsFilter?.length > 0 && <TruncatedChip body={`Requestors: ${selectedRequestorsFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}
                        {lastModifiedByFilter && <TruncatedChip body={`Last Modified By: ${lastModifiedByFilter.FirstName} ${lastModifiedByFilter.LastName}`} />}
                        {selectedLastModifiedByFilter && selectedLastModifiedByFilter?.length > 0 && <TruncatedChip body={`Last Modified By: ${selectedLastModifiedByFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}
                        {quickFiltersClicked && selectedLastModifiedFromDateFilter && selectedLastModifiedToDateFilter && <TruncatedChip body={`Last Modified Date: From: ${Moment(selectedLastModifiedFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedLastModifiedToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedLastModifiedFromDateFilter && !selectedLastModifiedToDateFilter && <TruncatedChip body={`Last Modified Date: From: ${Moment(selectedLastModifiedFromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !selectedLastModifiedFromDateFilter && selectedLastModifiedToDateFilter && <TruncatedChip body={`Last Modified Date: To: ${Moment(selectedLastModifiedToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedStartFromDateFilter && selectedStartToDateFilter && <TruncatedChip body={`Start Date: From: ${Moment(selectedStartFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedStartToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedStartFromDateFilter && !selectedStartToDateFilter && <TruncatedChip body={`Start Date: From: ${Moment(selectedStartFromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !selectedStartFromDateFilter && selectedStartToDateFilter && <TruncatedChip body={`Start Date: To: ${Moment(selectedStartToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedDueFromDateFilter && selectedDueToDateFilter && <TruncatedChip body={`Due Date: From: ${Moment(selectedDueFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedDueToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedDueFromDateFilter && !selectedDueToDateFilter && <TruncatedChip body={`Due Date: From: ${Moment(selectedDueFromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !selectedDueFromDateFilter && selectedDueToDateFilter && <TruncatedChip body={`Due Date: To: ${Moment(selectedDueToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedCompletionFromDateFilter && selectedCompletionToDateFilter && <TruncatedChip body={`Completion Date: From: ${Moment(selectedCompletionFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedCompletionToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && selectedCompletionFromDateFilter && !selectedCompletionToDateFilter && <TruncatedChip body={`Completion Date: From: ${Moment(selectedCompletionFromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !selectedCompletionFromDateFilter && selectedCompletionToDateFilter && <TruncatedChip body={`Completion Date: To: ${Moment(selectedCompletionToDateFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedLastModifiedFromDateAdvFilter && selectedLastModifiedToDateAdvFilter && <TruncatedChip body={`Last Modified Date: From: ${Moment(selectedLastModifiedFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedLastModifiedToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedLastModifiedFromDateAdvFilter && !selectedLastModifiedToDateAdvFilter && <TruncatedChip body={`Last Modified Date: From: ${Moment(selectedLastModifiedFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !selectedLastModifiedFromDateAdvFilter && selectedLastModifiedToDateAdvFilter && <TruncatedChip body={`Last Modified Date: To: ${Moment(selectedLastModifiedToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedStartFromDateAdvFilter && selectedStartToDateAdvFilter && <TruncatedChip body={`Start Date: From: ${Moment(selectedStartFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedStartToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedStartFromDateAdvFilter && !selectedStartToDateAdvFilter && <TruncatedChip body={`Start Date: From: ${Moment(selectedStartFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !selectedStartFromDateAdvFilter && selectedStartToDateAdvFilter && <TruncatedChip body={`Start Date: To: ${Moment(selectedStartToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedDueFromDateAdvFilter && selectedDueToDateAdvFilter && <TruncatedChip body={`Due Date: From: ${Moment(selectedDueFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedDueToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedDueFromDateAdvFilter && !selectedDueToDateAdvFilter && <TruncatedChip body={`Due Date: From: ${Moment(selectedDueFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !selectedDueFromDateAdvFilter && selectedDueToDateAdvFilter && <TruncatedChip body={`Due Date: To: ${Moment(selectedDueToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedCompletionFromDateAdvFilter && selectedCompletionToDateAdvFilter && <TruncatedChip body={`Completion Date: From: ${Moment(selectedCompletionFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedCompletionToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && selectedCompletionFromDateAdvFilter && !selectedCompletionToDateAdvFilter && <TruncatedChip body={`Completion Date: From: ${Moment(selectedCompletionFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !selectedCompletionFromDateAdvFilter && selectedCompletionToDateAdvFilter && <TruncatedChip body={`Completion Date: To: ${Moment(selectedCompletionToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {statusFilter && <TruncatedChip body={`Status: ${statusesList?.filter(s => s.Id == statusFilter)[0].Name}`} />}
                        {selectedStatusesFilter && selectedStatusesFilter?.length > 0 && <TruncatedChip body={`Status: ${selectedStatusesFilter?.map(s => s.Name).join(" - ")}`} />}
                        {escalatedFilter && escalatedFilter !== "" && <TruncatedChip body={`Escalated Action Items: ${escalatedFilter === "Escalated" ? "Yes" : ""}`} />}
                        {escalatedAdvFilter && escalatedAdvFilter !== "" && <TruncatedChip body={`Escalated Action Items: ${escalatedAdvFilter === "Escalated" ? "Yes" : ""}`} />}
                        {primaryAnalystFilter && <TruncatedChip body={`Primary Analyst: ${primaryAnalystFilter.FirstName} ${primaryAnalystFilter.LastName}`} />}
                        {primaryAnalystAdvFilter && primaryAnalystAdvFilter?.length > 0 && <TruncatedChip body={`Primary Analyst: ${primaryAnalystAdvFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}
                        {isolvedRepresentativeFilter && <TruncatedChip body={`isolved Representative: ${isolvedRepresentativeFilter.FirstName} ${isolvedRepresentativeFilter.LastName}`} />}
                        {isolvedRepresentativeAdvFilter && isolvedRepresentativeAdvFilter?.length > 0 && <TruncatedChip body={`isolved Representative: ${isolvedRepresentativeAdvFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}
                        {agingActionFilter && agingActionFilter !== "null" && <TruncatedChip body={`Include Aging: ${agingActionFilter === "1" ? "Yes" : ""} `} />}
                        {agingActionAdvFilter && agingActionAdvFilter !== "null" && agingActionAdvFilter?.length > 0 && <TruncatedChip body={`Include Aging: ${agingActionAdvFilter === "1" ? "Yes" : ""} `} />}
                    </React.Fragment>
                </div>

                {quickFiltersClicked && (
                    (userActions?.includes("43") || userActions?.includes("20")) ? (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Action Item ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={actionItemIDFilter} onChange={handleActionItemIDFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Action Item:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={actionItemFilter} onChange={handleActionItemFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Responsible Party:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={responsiblePartyFilter}
                                        onChange={handleResponsiblePartyFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {responsiblePartiesList?.map((p, pi) => (
                                            <option key={`responsible-parties-list-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Contact Name:</span>
                                <br />
                                <Autocomplete
                                    options={contactsList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={contactNameAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    disabled={!responsiblePartyFilter || responsiblePartyFilter === `2`}
                                    onChange={(e, newValue) => {
                                        setContactNameFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setContactNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Requestor:</span>
                                <br />
                                <Autocomplete
                                    options={usersList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    autoHighlight
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    className="search-dropdown w-100"
                                    inputValue={requestorNameAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setRequestorFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setRequestorNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Last Modified By:</span>
                                <br />
                                <Autocomplete
                                    options={usersList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={lastModifiedByAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setLastModifiedByFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setLastModifiedByAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Status:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={statusFilter}
                                        onChange={handleStatusFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {statusesList?.map((p, pi) => (
                                            <option key={`statuses-list-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Escalated Action Items:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={escalatedFilter ?? ""}
                                        onChange={handleEscalatedFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="Escalated">Yes</option>

                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Start Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedStartFromDateFilter}
                                            onChange={handleStartDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedStartToDateFilter}
                                            onChange={handleStartDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Due Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedDueFromDateFilter}
                                            onChange={handleDueDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedDueToDateFilter}
                                            onChange={handleDueDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Last Modified Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedLastModifiedFromDateFilter}
                                            onChange={handleLastModifiedDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedLastModifiedToDateFilter}
                                            onChange={handleLastModifiedDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Completion Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedCompletionFromDateFilter}
                                            onChange={handleCompletionDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedCompletionToDateFilter}
                                            onChange={handleCompletionDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Primary Analyst:</span>
                                <br />
                                <Autocomplete
                                    options={contactsAnalystsList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={primaryAnalysAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setPrimaryAnalystFilter(newValue ?? "");

                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setPrimaryAnalysAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>isolved Representative:</span>
                                <br />
                                <Autocomplete
                                    options={groupsAndOEContactsList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={isolvedRepresentativeAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setIsolvedRepresentativeFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setIsolvedRepresentativeAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Include Aging:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={agingActionFilter ?? ""}
                                        onChange={handleAgingActionsFilter}
                                        input={<BootstrapInput />}
                                        multiple={false}
                                    >
                                        <option aria-label="None" value="null" />

                                        <option value="1">Yes</option>
                                    </NativeSelect>
                                </FormControl>

                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Responsible Party:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={responsiblePartyFilter}
                                        onChange={handleResponsiblePartyFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {responsiblePartiesList?.map((p, pi) => (
                                            <option key={`responsible-parties-list-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Contact Name:</span>
                                <br />
                                <Autocomplete
                                    options={contactsList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={contactNameAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    disabled={!responsiblePartyFilter || responsiblePartyFilter === `2`}
                                    onChange={(e, newValue) => {
                                        setContactNameFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setContactNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Action Item:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={actionItemFilter} onChange={handleActionItemFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Requestor:</span>
                                <br />
                                <Autocomplete
                                    options={usersList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    autoHighlight
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    className="search-dropdown w-100"
                                    inputValue={requestorNameAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setRequestorFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setRequestorNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Status:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={statusFilter}
                                        onChange={handleStatusFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {statusesList?.map((p, pi) => (
                                            <option key={`statuses-list-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Last Modified By:</span>
                                <br />
                                <Autocomplete
                                    options={usersList?.sort((s1, s2) => {
                                        return s2.IsEnabled - s1.IsEnabled;
                                    })}
                                    groupBy={(option) => option.IsEnabled}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={lastModifiedByAutoValue ?? ""}
                                    getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                    onChange={(e, newValue) => {
                                        setLastModifiedByFilter(newValue ?? "");
                                        setSelectedPage(1);
                                        setPageSize(30);
                                    }}
                                    getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {`${option.FirstName} ${option.LastName}`}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setLastModifiedByAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Escalated Action Items:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={escalatedFilter ?? ""}
                                        onChange={handleEscalatedFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="Escalated">Yes</option>

                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Include Aging:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={agingActionFilter ?? ""}
                                        onChange={handleAgingActionsFilter}
                                        input={<BootstrapInput />}
                                        multiple={false}
                                    >
                                        <option aria-label="None" value="null" />

                                        <option value="1">Yes</option>
                                    </NativeSelect>
                                </FormControl>

                            </div>
                        </div>
                    )
                )}
                <div className="col-xl-12 mt-4" >
                    <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                            <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                        </svg>
                        <span className="ml-1">Refresh List</span>
                    </Button>
                    <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                        </svg> Clear Search
                    </Button>
                </div>

                <AnalystConnectivityRequestActionItemsTable advancedFiltersClicked={advancedFiltersClicked} quickFiltersClicked={quickFiltersClicked} actionItemsList={actionItemsList} setActionItemsList={setActionItemsList} selectedPage={selectedPage} pageSize={pageSize} setSelectedPage={setSelectedPage} setPageSize={setPageSize} selectedPageAdv={selectedPageAdv} setSelectedPageAdv={setSelectedPageAdv} pageSizeAdv={pageSizeAdv} setPageSizeAdv={setPageSizeAdv} totalCount={totalCount} onSortFieldFilter={(s) => setSortFieldFilter(s)} onSortOrderFilter={(s) => setSortOrderFilter(s)} onSortFieldAdvFilter={setSortFieldAdvFilter} onSortOrderAdvFilter={setSortOrderAdvFilter} />

            </Paper>

            <CRActionItemsFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                setPageSizeAdv={setPageSizeAdv}
                setSelectedPageAdv={setSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                setActionItemIDAdvFilter={setActionItemIDAdvFilter}
                setActionItemAdvFilter={setActionItemAdvFilter}
                setResponsiblePartyAdvFilter={setResponsiblePartyAdvFilter}
                setSelectedContactsFilter={setSelectedContactsFilter}
                setSelectedRequestorsFilter={setSelectedRequestorsFilter}
                setSelectedLastModifiedByFilter={setSelectedLastModifiedByFilter}
                setSelectedLastModifiedFromDateAdvFilter={setSelectedLastModifiedFromDateAdvFilter}
                setSelectedLastModifiedToDateAdvFilter={setSelectedLastModifiedToDateAdvFilter}
                setSelectedStartFromDateAdvFilter={setSelectedStartFromDateAdvFilter}
                setSelectedStartToDateAdvFilter={setSelectedStartToDateAdvFilter}
                setSelectedDueFromDateAdvFilter={setSelectedDueFromDateAdvFilter}
                setSelectedDueToDateAdvFilter={setSelectedDueToDateAdvFilter}
                setSelectedCompletionFromDateAdvFilter={setSelectedCompletionFromDateAdvFilter}
                setSelectedCompletionToDateAdvFilter={setSelectedCompletionToDateAdvFilter}
                setSelectedStatusesFilter={setSelectedStatusesFilter}
                setEscalatedAdvFilter={setEscalatedAdvFilter}
                setPrimaryAnalystAdvFilter={setPrimaryAnalystAdvFilter}
                setIsolvedRepresentativeAdvFilter={setIsolvedRepresentativeAdvFilter}
                setAgingActionAdvFilter={setAgingActionAdvFilter}
                groupsAndOEContactsList={groupsAndOEContactsList}
                onCloseClearReset={handleClearSearchReset}
            />
        </div>
    )
}

export default AnalystConnectivityRequestActionItems;