import React from "react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { SuccessModal } from "../Modals/SuccessModal";
import { FailureModal } from "../Modals/FailureModal";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadLink from "react-download-link";
import GetAppIcon from '@material-ui/icons/GetApp';
import Moment from 'moment';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { Button } from "@material-ui/core";
import { useAuthentication } from "../../Data/Authentication";
import deleteForeverSVG from "../../Icons/trash-xmark.svg";
import downloadIconSVG from "../../Icons/downloadIcon.svg";

function ConnectivityRequestUploadedFormRecord({ form, index, connectivityReq, selectedPlans, otherPlanTypes, formsList, ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [decodedString, setDecodedString] = React.useState("");


    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const handleDownloadForm = () => {
        const submitDownloadForm = {
            ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
            guid: form.GUID,
            attachmentExtension: form.Extension,
        }

        APIs.getSingleFormAttachment(submitDownloadForm).then((r) => {
            setDecodedString(r?.data);
        });
    }

    React.useEffect(() => {
        handleDownloadForm();
    }, [decodedString])

    const handleDeleteForm = () => {
        openModal(0, deleteForm, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this form?`,
        })
    }

    const deleteForm = () => {
        openLoader();
        const submitDeleteForm = {
            ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
            GroupRequestID: connectivityReq?.GroupRequestID,
            ConnectivityRequestType: connectivityReq?.ConnectivityRequestType,
            ConnectivityRequestStatusID: connectivityReq?.ConnectivityRequestStatusID,
            ConnectivityRequestPhaseID: connectivityReq?.ConnectivityRequestPhaseID,
            ConnectivityRequestFormsSubTypeID: connectivityReq?.ConnectivityRequestFormsSubTypeID,
            ConnectivityRequestEDISubTypeID: connectivityReq?.ConnectivityRequestEDISubTypeID,
            HREmail: connectivityReq?.HREmail,
            CarrierGroupNumber: connectivityReq?.CarrierGroupNumber,
            MigrationConnectivityRequest: connectivityReq?.MigrationConnectivityRequest,
            MigrationStartDate: connectivityReq?.MigrationStartDate,
            PlannedFirstProductionDate: connectivityReq?.PlannedFirstProductionDate,
            MigrationPhaseID: connectivityReq?.MigrationPhaseID,
            ConnectivityRequestFeedID: connectivityReq?.ConnectivityRequestFeedID,
            ConnectivityRequestConfigurations: connectivityReq?.ConnectivityRequestConfigurations,
            ReceivedEncryptionRequirements: connectivityReq?.ReceivedEncryptionRequirements,
            ClientEffectiveDate: connectivityReq?.ClientEffectiveDate,
            ClientContact: connectivityReq?.ClientContact,
            IsolvedCarrier: {
                Code: connectivityReq?.IsolvedCarrierCode,
                Name: connectivityReq?.IsolvedCarrierName,
                Type: connectivityReq?.IsolvedCarrierType,
                isNew: false
            },
            DigitalForms: [{
                Type: "DigitalForm",
                Name: form?.Name,
                Extension: form?.Extension,
                GUID: form?.GUID,
                isDeleted: 1
            }],
            PlanTypes: selectedPlans?.filter(s => s !== "Other") ?? [],
            OtherPlanTypes: otherPlanTypes?.split(/\r\n|\r|\n/).length === 1 && otherPlanTypes?.split(/\r\n|\r|\n/)[0] === "" ? [] : otherPlanTypes?.split(/\r\n|\r|\n/) ?? [],
        };

        if (userRoles?.includes("1") ||
            userRoles?.includes("7")) {
            APIs.getEditConnectivityRequestForAnalyst(submitDeleteForm).then((r) => {
                window.location.reload();
            });
        }
        else {
            APIs.editConnectivityRequestPlans(submitDeleteForm).then((r) => {
                window.location.reload();
            });
        }
    }

    return (
        <div className="row table-row table-row-bordered-l-r">
            <div className="col-6 table-cell first-cell">
                {form?.Name}
            </div>
            <div className="col-3 table-cell">
                <span>{form?.Created ? Moment(form?.Created).format('MM/DD/YYYY') : null}</span>
            </div>
            <div className="col-3 table-cell last-cell" style={{ display: "flex", justifyContent: "center" }}>
                
                <DownloadLink
                    style={{  marginTop:"-2px" }}
                    label={
                        <svg width="20" height="20" viewBox="0 0 20 20" className="icon-btn" onClick={handleDownloadForm} style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                        </svg>

                    }
                    filename={form.Name}
                    exportFile={() => {
                        let binaryString = window.atob(decodedString);
                        let binaryLen = binaryString.length;
                        let ab = new ArrayBuffer(binaryLen);
                        let ia = new Uint8Array(ab);
                        for (let i = 0; i < binaryLen; i++) {
                            ia[i] = binaryString.charCodeAt(i);
                        }
                        let bb = new Blob([ab]);
                        return bb;
                    }}
                />
                <CustomTooltip TransitionComponent={Zoom} title={formsList.length < 2 ? "Form connectivity should have at least one form" : ""}>
                    <span>
                        <Button className="icon-btn p-0" color="inherit" disabled={formsList.length < 2} onClick={handleDeleteForm}>
                            {/* <DeleteForeverIcon style={{ fontSize: 30 }} /> */}
                            <svg width="21" height="24" viewBox="0 0 21 24" >
                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                            </svg>
                        </Button>
                    </span>
                </CustomTooltip>
            </div>
        </div>
        // <div className="row container-div p-5 m-3 alignvh-center">
        //     <div className="container-internal-file-div col-xl-3" style={{ position: "relative" }}>
        //         <div style={{ position: "absolute", zIndex: "10", left: "5px", top: "10px" }}>
        //             <DeleteForeverIcon onClick={handleDeleteForm} color="action" style={{ fontSize: "30px", cursor: "pointer" }} />
        //             <DownloadLink
        //                 label={<GetAppIcon onClick={handleDownloadForm} color="action" style={{ fontSize: "30px", cursor: "pointer" }} />}
        //                 filename={form.Name}
        //                 exportFile={() => {
        //                     let binaryString = window.atob(decodedString);
        //                     let binaryLen = binaryString.length;
        //                     let ab = new ArrayBuffer(binaryLen);
        //                     let ia = new Uint8Array(ab);
        //                     for (let i = 0; i < binaryLen; i++) {
        //                         ia[i] = binaryString.charCodeAt(i);
        //                     }
        //                     let bb = new Blob([ab]);
        //                     return bb;
        //                 }}
        //             />
        //         </div>
        //         <PictureAsPdfIcon style={{ color: "var(--pink-primary-color)", fontSize: "50px" }} />
        //         <span style={{ wordBreak: "break-all", marginTop: "10px" }}>{form?.Name}</span>
        //     </div>
        //     <div className="col-xl-1"></div>
        //     <div id="DF-main-types" className="col-xl-8">
        //         <h6 className="form-title">Main Types</h6>
        //         <div className="textfield-div">
        //             <span>{form?.MainTypes?.map(s => s.Name).join(" - ")}</span>
        //         </div>
        //         <h6 className="form-title mt-4">Sub Types</h6>
        //         <div className="textfield-div">
        //             <span>{form?.SubTypes?.map(s => s.Name).join(" - ")}</span>
        //         </div>
        //         <h6 className="form-title mt-4">Uploaded Date</h6>
        //         <div className="textfield-div">
        //             <span>{form?.Created ? Moment(form?.Created).format('MM/DD/YYYY') : null}</span>
        //         </div>
        //     </div>
        //     {/* <div className="col-xl-3 mr-1">
        //         <h6 className="form-title">Sub Types</h6>
        //         <div className="textfield-div">
        //             <span>{form?.SubTypes?.map(s => s.Name).join(" - ")}</span>
        //         </div>
        //     </div>
        //     <div id="DF-sub-types" className="col-xl-3 mr-1">
        //         <h6 className="form-title">Uploaded Date</h6>
        //         <div className="textfield-div">
        //             <span>{form?.Created ? Moment(form?.Created).format('MM/DD/YYYY') : null}</span>
        //         </div>
        //     </div> */}
        // </div>
    )
}

export default ConnectivityRequestUploadedFormRecord;