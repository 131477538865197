import React from "react";
import * as APIs from "../../../Data/APIs";
import { useHistory } from "react-router-dom";
import MultipleTabsTitles from "../../Layout/MultipleTabsTitles";
import { Paper, Zoom } from "@material-ui/core";
import Moment from 'moment';
import { useLoader } from "../../Layout/Loader";
import { useAuthentication } from "../../../Data/Authentication";
import HomeProductionDashboardFilter from "./HomeProductionDashboardFilter";
import Pagination from "../../Layout/Pagination";
import HomeProductionDashboardSingleFeed from "./HomeProductionDashboardSingleFeed";
import { CustomTooltip } from "../../Layout/Tooltip";
import GenericTable from "../../Layout/GenericTable";
import downloadIconSVG from "../../../Icons/downloadIcon.svg";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const yearDate = new Date();
yearDate.setHours(0, 0, 0, 0)
yearDate.setFullYear(todayDate.getFullYear() - 1);


function HomeProductionDashboard() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);

    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const [tabValue, setTabValue] = React.useState(1);
    const [productionTransmissionsList, setProductionTransmissionsList] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [statusesList, setStatusesList] = React.useState([]);
    const [statusTabValue, setStatusTabValue] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [lastRunFromDateFilter, setLastRunFromDateFilter] = React.useState(null);
    const [lastRunToDateFilter, setLastRunToDateFilter] = React.useState(null);
    const [nextRunFromDateFilter, setNextRunFromDateFilter] = React.useState(null);
    const [nextRunToDateFilter, setNextRunToDateFilter] = React.useState(null);
    const [feedIDFilter, setFeedIDFilter] = React.useState("");
    const [executionStatusFilter, setExecutionStatusFilter] = React.useState("");

    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("DESC");
    const [refreshClicked, setRefreshClicked] = React.useState(false);

    //Advanced Filters
    const [feedIDAdvFilter, setFeedIDAdvFilter] = React.useState("");
    const [selectedFeedNameAdvFilter, setSelectedFeedNameAdvFilter] = React.useState([]);
    const [selectedExecutionStatusAdvFilter, setSelectedExecutionStatusAdvFilter] = React.useState([]);
    const [lastRunFromDateAdvFilter, setLastRunFromDateAdvFilter] = React.useState(null);
    const [lastRunToDateAdvFilter, setLastRunToDateAdvFilter] = React.useState(null);
    const [nextRunFromDateAdvFilter, setNextRunFromDateAdvFilter] = React.useState(null);
    const [nextRunToDateAdvFilter, setNextRunToDateAdvFilter] = React.useState(null);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("DESC");

    const [sortOrder, setSortOrder] = React.useState("DESC");

    React.useEffect(() => {
        APIs.getProductionDashboardStatusesList().then((r) => setStatusesList(r?.data));
    }, [])

    const getDataQuickFilters = () => {
        openLoader();
        const feedsTransmissions = {};
        feedsTransmissions.feedPhaseID = ["7"]; //production only
        if (selectedPage >= 1) {
            feedsTransmissions.page = selectedPage;
            feedsTransmissions.limit = pageSize;
        }
        if (pageSize >= 10) {
            feedsTransmissions.limit = pageSize;
            feedsTransmissions.page = selectedPage;
        }
        if (lastRunFromDateFilter !== null)
            feedsTransmissions.lastRunFromDate = Moment(lastRunFromDateFilter).format("YYYY-MM-DD");
        if (lastRunToDateFilter !== null)
            feedsTransmissions.lastRunToDate = Moment(lastRunToDateFilter).format("YYYY-MM-DD");
        if (nextRunFromDateFilter !== null)
            feedsTransmissions.nextRunFromDate = Moment(nextRunFromDateFilter).format("YYYY-MM-DD");
        if (nextRunToDateFilter !== null)
            feedsTransmissions.nextRunToDate = Moment(nextRunToDateFilter).format("YYYY-MM-DD");
        if (feedIDFilter !== "")
            feedsTransmissions.feedID = [`${feedIDFilter}`];
        if (executionStatusFilter !== "")
            feedsTransmissions.transmissionStatusID = executionStatusFilter;
        if (sortFieldFilter !== "")
            feedsTransmissions.sortType = sortFieldFilter;
        if (sortOrderFilter !== "")
            feedsTransmissions.sortOrder = sortOrderFilter;

        APIs.getProductionDashboardList(feedsTransmissions).then((r) => {
            setProductionTransmissionsList(r?.data);
            setRefreshClicked(false);
            setTotalCount(r?.totalCount ?? 0);
            closeLoader();
        }).catch(err => {
            setProductionTransmissionsList([]);
            closeLoader();
        });
        // }
    };

    const getDataAdvancedFilters = () => {
        openLoader();
        const feedsTransmissions = {};
        feedsTransmissions.feedPhaseID = ["7"]; //production only
        feedsTransmissions.type = `${statusTabValue + 1}`;
        if (selectedPageAdv >= 1) {
            feedsTransmissions.page = selectedPageAdv;
            feedsTransmissions.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            feedsTransmissions.limit = pageSizeAdv;
            feedsTransmissions.page = selectedPageAdv;
        }
        if (lastRunFromDateAdvFilter)
            feedsTransmissions.fromDate = Moment(lastRunFromDateAdvFilter).format("YYYY-MM-DD");
        if (lastRunToDateAdvFilter)
            feedsTransmissions.toDate = Moment(lastRunToDateAdvFilter).format("YYYY-MM-DD");
        if (nextRunFromDateAdvFilter)
            feedsTransmissions.fromDate = Moment(nextRunFromDateAdvFilter).format("YYYY-MM-DD");
        if (nextRunToDateAdvFilter)
            feedsTransmissions.toDate = Moment(nextRunToDateAdvFilter).format("YYYY-MM-DD");
        if (feedIDAdvFilter !== "")
            feedsTransmissions.feedID = [`${feedIDAdvFilter}`];
        if (selectedFeedNameAdvFilter.length > 0)
            feedsTransmissions.feedID = selectedFeedNameAdvFilter?.map(s => `${s?.FeedID}`);
        if (selectedExecutionStatusAdvFilter.length > 0)
            feedsTransmissions.executionStatus = selectedExecutionStatusAdvFilter?.map(s => `${s?.Id}`);
        if (sortFieldAdvFilter !== "")
            feedsTransmissions.sortType = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            feedsTransmissions.sortOrder = sortOrderAdvFilter;

        APIs.getProductionDashboardList(feedsTransmissions).then((r) => {
            setProductionTransmissionsList(r?.data);
            setRefreshClicked(false);
            setTotalCount(r?.totalCount ?? 0);
            setAdvancedFiltersAppliedClicked(false);
            closeLoader();
        });
    };

    React.useEffect(() => {
        if (quickFiltersClicked) getDataQuickFilters();
    }, [statusTabValue, selectedPage, pageSize, lastRunFromDateFilter, lastRunToDateFilter, nextRunFromDateFilter, nextRunToDateFilter, feedIDFilter, executionStatusFilter, sortFieldFilter, sortOrderFilter, refreshClicked === true])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])

    React.useEffect(() => {
        getDataQuickFilters();
    }, [quickFiltersClicked === true])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [advancedFiltersClicked === true])

    const isClient = () => {
        return userRoles?.includes("6")
    }

    // -------------------------------- NEW TABLE ---------------------------------
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        if (type === "quick") {
            setSortFieldFilter(column?.sortingkey);
            setSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            setSortFieldAdvFilter(column?.sortingkey);
            setSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const baseColumnsArray = [
        {
            name: 'Feed Name',
            sortable: true,
            sortingkey: 'FeedName',
            id: 'FeedName',
            selector: (c) =>
                isClient() ?
                    <> {c?.FeedName} </> :
                    <a style={{ wordBreak: "break-word", margin: "auto" }} href={`/Feeds/ViewFeed/${c?.FeedID}`} onClick={(e) => { e.stopPropagation(); }}>
                        {c?.FeedName}
                    </a>

        },
        {
            name: 'Output File',
            selector: (c) =>
                c?.Output?.length <= 0 ? "" : (
                    <CustomTooltip TransitionComponent={Zoom} title="Download Output File">
                        <a href={c?.Output[0]}>
                            <svg width="20" height="20" viewBox="0 0 20 20" >
                                <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                            </svg>
                        </a>
                    </CustomTooltip>
                )

        },
        {
            name: 'Last Run',
            tooltip: 'Date and Time of the last sent file',
            sortable: true,
            sortingkey: 'LastRun',
            id: 'LastRun',
            selector: (c) => c?.LastRun !== null ? Moment(c?.LastRun).format("MM/DD/YYYY HH:mm:ss") : "N/A"
        },
        {
            name: 'Next Run',
            tooltip: 'Date and Time of the next scheduled file',
            sortable: true,
            sortingkey: 'NextRun',
            id: 'NextRun',
            selector: (c) => c?.NextRun !== null ? Moment(c?.NextRun).format("MM/DD/YYYY HH:mm:ss") : "N/A"
        },
        {
            name: 'Transmission Status',
            tooltip: 'Identifies if the feed processing was completed, failed, stopped, or pending',
            sortable: true,
            sortingkey: 'TransmissionStatusName',
            id: 'TransmissionStatusName',
            selector: (c) => <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom}
                title={(c?.TransmissionStatusName)?.includes("Progress") ? "The feed is still processing" :
                    (c?.TransmissionStatusName)?.includes("Completed") ? "Current processing step is completed" :
                        (c?.TransmissionStatusName)?.includes("Parsing") ? "Failed to convert the data file to the required format" :
                            (c?.TransmissionStatusName)?.includes("Tracking") ? "Failed to process the data" :
                                (c?.TransmissionStatusName)?.includes("Mapping") ? "There is an error in the output file" :
                                    (c?.TransmissionStatusName)?.includes("Serializing") ? "Unable to successfully generate a file" :
                                        (c?.TransmissionStatusName)?.includes("Encrypting") ? "Failed to encrypt the output file" :
                                            (c?.TransmissionStatusName)?.includes("Failed Transmitting") ? "Failed to submit the file" :
                                                (c?.TransmissionStatusName)?.includes("Pending Transmission") ? "File needs to be manually submitted" :
                                                    (c?.TransmissionStatusName)?.includes("Pending") ? "The feed didn't start processing yet" :
                                                        (c?.LastRun !== null && (c?.TransmissionStatusName === null)) ? "Failed to extract a data file" :
                                                            (c?.TransmissionStatusName)?.includes("Finished") ? "There are no changes on the output file" :
                                                                ""}>
                <span>
                    <div style={(c?.TransmissionStatusName)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                        className={`${((c?.TransmissionStatusName)?.includes("Fail") ||
                            (c?.LastRun !== null && (c?.TransmissionStatusName === null))) ? "red background status-div" :
                            (c?.TransmissionStatusName)?.includes("Pending Transmission") ? "yellow-secondary-color background status-div" :
                                (c?.TransmissionStatusName)?.includes("Completed") ? "green background status-div" :
                                    (c?.TransmissionStatusName)?.includes("Finished") ? "lightgreen background status-div" :
                                        (c?.TransmissionStatusName === "Pending" ||
                                            (c?.LastRun === null && c?.TransmissionStatusName === null)) ? "purple1 background status-div" :
                                            ""}`} >
                        {(c?.TransmissionStatusName === "Pending" || (c?.LastRun === null && c?.TransmissionStatusName === null)) ? "Pending" :
                            (c?.LastRun !== null && (!((c?.TransmissionStatusName)?.includes("Pending Transmission")) && (c?.logid_today === null || c?.TransmissionStatusName === null))) ? "File Pulling Failed" : c?.TransmissionStatusName}
                    </div>
                </span>
            </CustomTooltip>
        }
    ];


    return (
        <div id="transmission-dashboard-table">
            {/* remove filters for client */}
            {!userRoles?.includes("6") &&
                <Paper className="content-card-paper">
                    <HomeProductionDashboardFilter
                        onFeedIDFilter={(d) => setFeedIDFilter(d)}
                        onExecutionStatusFilter={(d) => setExecutionStatusFilter(d)}
                        onLastRunFromDateFilter={(d) => setLastRunFromDateFilter(d)}
                        lastRunFromDateFilter={lastRunFromDateFilter}
                        onLastRunToDateFilter={(d) => setLastRunToDateFilter(d)}
                        lastRunToDateFilter={lastRunToDateFilter}
                        onNextRunFromDateFilter={(d) => setNextRunFromDateFilter(d)}
                        nextRunFromDateFilter={nextRunFromDateFilter}
                        onNextRunToDateFilter={(d) => setNextRunToDateFilter(d)}
                        nextRunToDateFilter={nextRunToDateFilter}
                        setRefreshClicked={setRefreshClicked}
                        selectedPage={selectedPage}
                        onSelectedPage={(p) => setSelectedPage(p)}
                        pageSize={pageSize}
                        onPageSize={(p) => setPageSize(p)}
                        totalCount={totalCount}
                        setTotalCount={setTotalCount}
                        selectedPageAdv={selectedPageAdv}
                        onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                        pageSizeAdv={pageSizeAdv}
                        onPageSizeAdv={(p) => setPageSizeAdv(p)}
                        quickFiltersClicked={quickFiltersClicked}
                        setQuickFiltersClicked={setQuickFiltersClicked}
                        advancedFiltersClicked={advancedFiltersClicked}
                        setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                        advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                        setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                        feedIDAdvFilter={feedIDAdvFilter}
                        onFeedIDAdvFilter={s => setFeedIDAdvFilter(s)}
                        selectedFeedNameAdvFilter={selectedFeedNameAdvFilter}
                        onSelectedFeedNameAdvFilter={s => setSelectedFeedNameAdvFilter(s)}
                        selectedExecutionStatusAdvFilter={selectedExecutionStatusAdvFilter}
                        onSelectedExecutionStatusAdvFilter={s => setSelectedExecutionStatusAdvFilter(s)}
                        lastRunFromDateAdvFilter={lastRunFromDateAdvFilter}
                        onLastRunFromDateAdvFilter={s => setLastRunFromDateAdvFilter(s)}
                        lastRunToDateAdvFilter={lastRunToDateAdvFilter}
                        onLastRunToDateAdvFilter={s => setLastRunToDateAdvFilter(s)}
                        nextRunFromDateAdvFilter={nextRunFromDateAdvFilter}
                        onNextRunFromDateAdvFilter={s => setNextRunFromDateAdvFilter(s)}
                        nextRunToDateAdvFilter={nextRunToDateAdvFilter}
                        onNextRunToDateAdvFilter={s => setNextRunToDateAdvFilter(s)}
                    />
                    <GenericTable
                        columns={[...baseColumnsArray]}
                        data={productionTransmissionsList || []}
                        onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
                    />
                    <div>
                        <Pagination
                            stateLimit={true}
                            pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                            page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                            onChange={(t, v) => {
                                if (quickFiltersClicked) {
                                    if (t === 1) setPageSize(v);
                                    else if (t === 2) setSelectedPage(v);
                                } else if (advancedFiltersClicked) {
                                    if (t === 1) setPageSizeAdv(v);
                                    else if (t === 2) setSelectedPageAdv(v);
                                }
                            }}
                            count={totalCount}
                        />
                    </div>
                </Paper>
            }
            {userRoles?.includes("6") &&
                <Paper elevation={0} className="content-card-paper">

                    <GenericTable
                        columns={[...baseColumnsArray]}
                        data={productionTransmissionsList || []}
                        onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
                    />

                    <div>
                        <Pagination
                            stateLimit={true}
                            pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                            page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                            onChange={(t, v) => {
                                if (quickFiltersClicked) {
                                    if (t === 1) setPageSize(v);
                                    else if (t === 2) setSelectedPage(v);
                                } else if (advancedFiltersClicked) {
                                    if (t === 1) setPageSizeAdv(v);
                                    else if (t === 2) setSelectedPageAdv(v);
                                }
                            }}
                            count={totalCount}
                        />
                    </div>
                </Paper >}
        </div >
    )
}
export default HomeProductionDashboard;
