import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper, TextField, FormControl } from '@material-ui/core/';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { useParams } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { styled } from '@mui/system';
import ListSubheader from '@mui/material/ListSubheader';
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

function OERequestFilterModalBody({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    setPageSizeAdv,
    setSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    setOERequestIDFilterAdvFilter,
    setUpdatedGSAdvFilter,
    setSelectedPlanYearStartFromDateFilterAdvFilter,
    setSelectedPlanYearStartToDateFilterAdvFilter,
    setSelectedExpectedFromDateFilterAdvFilter,
    setSelectedExpectedToDateFilterAdvFilter,
    setSelectedCreatedFromDateFilterAdvFilter,
    setSelectedCreatedToDateFilterAdvFilter,
    setStatusFilterAdvFilter,
    setPhaseFilterAdvFilter,
    setOETypeFilterAdvFilter,
    setAssignedToAdvFilter,
    onSelectedAssignedToFilter,
    setSelectedStatusesFilter,
    setSelectedPhasesFilter,
    setSelectedOETypesFilter,
    onCloseClearReset,
    setISolvedContactAdvFilter,
    onSelectedISolvedContactFilter,
    selectedISolvedContactFilter,
    setSelectedOEFileSubmissionFromDateFilterAdvFilter,
    setSelectedOEFileSubmissionToDateFilterAdvFilter,
    ...props
}) {

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [oERequestID, setOERequestID] = React.useState("");
    const { connectivityRequestIDURL } = useParams();
    const [selectedPlanYearStartFromDate, setSelectedPlanYearStartFromDate] = React.useState(null);
    const [selectedPlanYearStartToDate, setSelectedPlanYearStartToDate] = React.useState(null);
    const [selectedExpectedFromDate, setSelectedExpectedFromDate] = React.useState(null);
    const [selectedExpectedToDate, setSelectedExpectedToDate] = React.useState(null);
    const [selectedCreatedFromDate, setSelectedCreatedFromDate] = React.useState(null);
    const [selectedCreatedToDate, setSelectedCreatedToDate] = React.useState(null);
    const [assignedTo, setAssignedTo] = React.useState("");
    const [assignedToSearchValue, setAssignedToSearchValue] = React.useState("");
    const [iSolvedContact, setISolvedContact] = React.useState("");
    const [iSolvedContactAutoValue, setISolvedContactAutoValue] = React.useState("");
    const [iSolvedContactSearchValue, setISolvedContactSearchValue] = React.useState("");
    const [selectedAssignees, setSelectedAssignees] = React.useState([]);
    const [selectedISolvedContacts, setSelectedISolvedContacts] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [selectedPhases, setSelectedPhases] = React.useState([]);
    const [selectedTypes, setSelectedTypes] = React.useState([]);
    const [updatedGS, setUpdatedGS] = React.useState("");
    const [selectedOEFileSubmissionFromDate, setSelectedOEFileSubmissionFromDate] = React.useState(null);
    const [selectedOEFileSubmissionToDate, setSelectedOEFileSubmissionToDate] = React.useState(null);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    // gated Data 
    const [statusesList, setStatusesList] = React.useState([]);
    const [phasesList, setPhasesList] = React.useState([]);
    const [contactsList, setContactsList] = React.useState([]);
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);




    // get Data 
    React.useEffect(() => {
        APIs.getOERequestStatusesList().then(r => !(r?.data?.length) ? setStatusesList([]) : setStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getOERequestPhasesList().then(r => !(r?.data?.length) ? setPhasesList([]) : setPhasesList(r?.data));
    }, [])

    React.useEffect(() => {
        setISolvedContactAutoValue(`${iSolvedContact?.FirstName ?? ""} ${iSolvedContact?.LastName ?? ""}`);
    }, [iSolvedContact])

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: 3,
            ConnectivityRequestID: connectivityRequestIDURL
        }
        APIs.getContactsList(contactsBody).then((r) => {
            setContactsList(r?.data ?? []);
        })
    }, [])

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };
    const handleOERequestIDFilter = (e) => {
        setOERequestID(e.target.value);
        setOERequestIDFilterAdvFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleUpdatedGSFilter = (e, newValue) => {
        setUpdatedGS(e.target.value);
        setUpdatedGSAdvFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }


    const handleSelectedPlanYearStartChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedPlanYearStartFromDate(date);
            setSelectedPlanYearStartFromDateFilterAdvFilter(date);
        }
        else {
            setSelectedPlanYearStartToDate(date);
            setSelectedPlanYearStartToDateFilterAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };


    const handleOEFileSubmissionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedOEFileSubmissionFromDate(date);
            setSelectedOEFileSubmissionFromDateFilterAdvFilter(date);
        }
        else {
            setSelectedOEFileSubmissionToDate(date);
            setSelectedOEFileSubmissionToDateFilterAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };

    const handleSelectedExpectedChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedExpectedFromDate(date);
            setSelectedExpectedFromDateFilterAdvFilter(date);
        }
        else {
            setSelectedExpectedToDate(date);
            setSelectedExpectedToDateFilterAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };






    const handleeSelectedCreatedChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedCreatedFromDate(date);
            setSelectedCreatedFromDateFilterAdvFilter(date);
        }
        else {
            setSelectedCreatedToDate(date);
            setSelectedCreatedToDateFilterAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }

    const handleAssignedToClick = (value) => (event) => {
        let selectedAssignedToArray = selectedAssignees;
        if (!value || value === "") selectedAssignedToArray = [];
        else if (selectedAssignedToArray.indexOf(value) >= 0) {
            selectedAssignedToArray.splice(selectedAssignedToArray.indexOf(value), 1);
        }
        else {
            selectedAssignedToArray.push(value);
        }
        setSelectedAssignees([...selectedAssignedToArray]);
        onSelectedAssignedToFilter([...selectedAssignedToArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }



    const handleOERequestStatusesClick = (value) => (event) => {
        let selectedOERequestStatusesArray = selectedStatuses;
        if (!value || value === "") selectedOERequestStatusesArray = [];
        else if (selectedOERequestStatusesArray.indexOf(value) >= 0) {
            selectedOERequestStatusesArray.splice(selectedOERequestStatusesArray.indexOf(value), 1);
        }
        else {
            selectedOERequestStatusesArray.push(value);
        }
        setSelectedStatuses([...selectedOERequestStatusesArray]);
        setSelectedStatusesFilter([...selectedOERequestStatusesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleOERequestPhasesClick = (value) => (event) => {
        let selectedOERequestPhasesArray = selectedPhases;
        if (!value || value === "") selectedOERequestPhasesArray = [];
        else if (selectedOERequestPhasesArray.indexOf(value) >= 0) {
            selectedOERequestPhasesArray.splice(selectedOERequestPhasesArray.indexOf(value), 1);
        }
        else {
            selectedOERequestPhasesArray.push(value);
        }
        setSelectedPhases([...selectedOERequestPhasesArray]);
        setSelectedPhasesFilter([...selectedOERequestPhasesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }


    const handleOETypesClick = (value) => (event) => {
        let selectedOETypesArray = selectedTypes;
        if (!value || value === "") selectedOETypesArray = [];
        else if (selectedOETypesArray.indexOf(value) >= 0) {
            selectedOETypesArray.splice(selectedOETypesArray.indexOf(value), 1);
        }
        else {
            selectedOETypesArray.push(value);
        }
        setSelectedTypes([...selectedOETypesArray]);
        setSelectedOETypesFilter([...selectedOETypesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleISolvedContactClick = (value) => (event) => {
        let selectedISolvedContactArray = selectedISolvedContacts;
        if (!value || value === "") selectedISolvedContactArray = [];
        else if (selectedISolvedContactArray.indexOf(value) >= 0) {
            selectedISolvedContactArray.splice(selectedISolvedContactArray.indexOf(value), 1);
        }
        else {
            selectedISolvedContactArray.push(value);
        }
        setSelectedISolvedContacts([...selectedISolvedContactArray]);
        onSelectedISolvedContactFilter([...selectedISolvedContactArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleClearSearch = () => {
        setOERequestID("");
        setOERequestIDFilterAdvFilter("");
        setUpdatedGS("");
        setUpdatedGSAdvFilter("");
        setOETypeFilterAdvFilter("");
        setStatusFilterAdvFilter("");
        setPhaseFilterAdvFilter("");
        setSelectedPlanYearStartFromDate(null);
        setSelectedPlanYearStartFromDateFilterAdvFilter(null);

        setSelectedOEFileSubmissionFromDate(null);
        setSelectedOEFileSubmissionFromDateFilterAdvFilter(null);

        setSelectedOEFileSubmissionToDate(null);
        setSelectedOEFileSubmissionToDateFilterAdvFilter(null);

        setSelectedPlanYearStartToDate(null);
        setSelectedPlanYearStartToDateFilterAdvFilter(null);
        setSelectedExpectedFromDate(null);
        setSelectedExpectedFromDateFilterAdvFilter(null);
        setSelectedExpectedToDate(null);
        setSelectedExpectedToDateFilterAdvFilter(null);
        setSelectedCreatedFromDate(null);
        setSelectedCreatedFromDateFilterAdvFilter(null);
        setSelectedCreatedToDate(null);
        setSelectedCreatedToDateFilterAdvFilter(null);
        setAssignedTo("");
        setSelectedAssignees([]);
        onSelectedAssignedToFilter([]);
        setAssignedToAdvFilter("");
        setSelectedStatuses([]);
        setSelectedStatusesFilter([]);
        setSelectedPhases([]);
        setSelectedPhasesFilter([]);
        setSelectedTypes([]);
        setSelectedOETypesFilter([]);
        setSelectedPageAdv(1);
        setISolvedContact("");
        onSelectedISolvedContactFilter([]);
        setISolvedContactAdvFilter("");
        setSelectedISolvedContacts([]);
    }

    React.useEffect(() => {
        setOERequestID("");
        setOERequestIDFilterAdvFilter("");
        setUpdatedGS("");
        setUpdatedGSAdvFilter("");
        setOETypeFilterAdvFilter("");
        setStatusFilterAdvFilter("");
        setPhaseFilterAdvFilter("");
        setSelectedAssignees([]);
        onSelectedAssignedToFilter([]);
        setSelectedPlanYearStartFromDate(null);
        setSelectedPlanYearStartFromDateFilterAdvFilter(null);
        setSelectedPlanYearStartToDate(null);
        setSelectedOEFileSubmissionFromDate(null);
        setSelectedOEFileSubmissionFromDateFilterAdvFilter(null);

        setSelectedOEFileSubmissionToDate(null);
        setSelectedOEFileSubmissionToDateFilterAdvFilter(null);

        setSelectedPlanYearStartToDateFilterAdvFilter(null);
        setSelectedExpectedFromDate(null);
        setSelectedExpectedFromDateFilterAdvFilter(null);
        setSelectedExpectedToDate(null);
        setSelectedExpectedToDateFilterAdvFilter(null);
        setSelectedCreatedFromDate(null);
        setSelectedCreatedFromDateFilterAdvFilter(null);
        setSelectedCreatedToDate(null);
        setSelectedCreatedToDateFilterAdvFilter(null);
        setAssignedTo("");
        setAssignedToAdvFilter("");
        setSelectedStatuses([]);
        setSelectedStatusesFilter([]);
        setSelectedPhases([]);
        setSelectedPhasesFilter([]);
        setSelectedTypes([]);
        setSelectedOETypesFilter([]);
        setSelectedPageAdv(1);
        setISolvedContact("");
        onSelectedISolvedContactFilter([]);
        setISolvedContactAdvFilter("");
        setSelectedISolvedContacts([]);
    }, [advancedFiltersClicked === false])

    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            setSelectedPageAdv(1);
            setPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            setSelectedPageAdv(1);
            setPageSizeAdv(30);
        }
    }

    const BootstrapInput = withStyles((theme) => ({
        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced OE Request Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            <DialogContent>

                <div className="filter-body-div">
                    <Box style={{ display: 'flex' }}>
                        <Tabs
                            style={{ maxHeight: "370px", overflow: "auto" }}
                            orientation="vertical"
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                        >
                            <Tab className="filter-tab-btn" label="OE Request ID" {...a11yProps(0)} />
                            <Tab className="filter-tab-btn" label="status" {...a11yProps(1)} />
                            <Tab className="filter-tab-btn" label="Phase" {...a11yProps(2)} />
                            <Tab className="filter-tab-btn" label="Updated Group Structure" {...a11yProps(3)} />
                            <Tab className="filter-tab-btn" label="Assigned To" {...a11yProps(4)} />
                            <Tab className="filter-tab-btn" label="isolved Contact" {...a11yProps(5)} />
                            <Tab className="filter-tab-btn" label="Plan Year Start Date" {...a11yProps(6)} />
                            <Tab className="filter-tab-btn" label="Client's Expected Data Ready Date" {...a11yProps(7)} />
                            <Tab className="filter-tab-btn" label="Created Date" {...a11yProps(8)} />
                            <Tab className="filter-tab-btn" label="OE File Transmission Date" {...a11yProps(9)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <span>Please filter by only one OE Request ID:</span>
                            <br />
                            <input type="text" className="search-input w-100" placeholder="OE Request ID" value={oERequestID} onChange={handleOERequestIDFilter} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`oe-request-status-all`}>
                                    <AccordionSummary
                                        aria-controls="oe-request-status-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`oe-request-status-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedStatuses && selectedStatuses.length === statusesList.length}
                                                        onClick={handelSelectAll(selectedStatuses, statusesList, setSelectedStatuses, setSelectedStatusesFilter)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>
                                {statusesList.map((f, fi) => (
                                    <Accordion key={`oe-request-status-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="oe-request-statuses-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`oe-request-status-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                            onClick={handleOERequestStatusesClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.Name}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`oe-request-phase-all`}>
                                    <AccordionSummary
                                        aria-controls="oe-request-phase-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`oe-request-phase-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedPhases && selectedPhases.length === phasesList.length}
                                                        onClick={handelSelectAll(selectedPhases, phasesList, setSelectedPhases, setSelectedPhasesFilter)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>
                                {phasesList.map((f, fi) => (
                                    <Accordion key={`oe-request-phase-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="oe-request-phases-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`oe-request-phase-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPhases && selectedPhases.indexOf(f) >= 0}
                                                            onClick={handleOERequestPhasesClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.Name}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <span>Please filter by only one option:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={updatedGS}
                                    onChange={handleUpdatedGSFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </NativeSelect>
                            </FormControl>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <input type="text" className="text-input w-100" placeholder="Search users here..." value={assignedToSearchValue} onChange={(e) => setAssignedToSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`users-assignto-all`}>
                                    <AccordionSummary
                                        aria-controls="users-assignto-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`users-assignto-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedAssignees && selectedAssignees.length === contactsList.length}
                                                        onClick={handelSelectAll(selectedAssignees, contactsList, setSelectedAssignees, onSelectedAssignedToFilter)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>

                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                {contactsList?.filter(s => s.IsEnabled === 1)?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`users-assignto-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="users-assignto-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-assignto-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedAssignees && selectedAssignees.indexOf(c) >= 0}
                                                            onClick={handleAssignedToClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={`${c.FirstName} ${c.LastName}`}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                {contactsList?.filter(s => s.IsEnabled !== 1)?.filter(s => assignedToSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(assignedToSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`users-assignto-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="users-assignto-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-assignto-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedAssignees && selectedAssignees.indexOf(c) >= 0}
                                                            onClick={handleAssignedToClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={`${c.FirstName} ${c.LastName}`}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={5}>
                            <input type="text" className="text-input w-100" placeholder="Search users here..." value={iSolvedContactSearchValue} onChange={(e) => setISolvedContactSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`users-iSolved-all`}>
                                    <AccordionSummary
                                        aria-controls="users-iSolved-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`users-iSolved-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedISolvedContacts && selectedISolvedContacts.length === groupsAndOEContactsList.length}
                                                        onClick={handelSelectAll(selectedISolvedContacts, groupsAndOEContactsList, setSelectedISolvedContacts, onSelectedISolvedContactFilter)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>

                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                {groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.filter(s => iSolvedContactSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(iSolvedContactSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`users-iSolved-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="users-iSolved-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-iSolved-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedISolvedContacts && selectedISolvedContacts.indexOf(c) >= 0}
                                                            onClick={handleISolvedContactClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={`${c.FirstName} ${c.LastName}`}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                                <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                {groupsAndOEContactsList?.filter(s => s.IsEnabled !== 1)?.filter(s => iSolvedContactSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(iSolvedContactSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`users-iSolved-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="users-iSolved-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-iSolved-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedISolvedContacts && selectedISolvedContacts.indexOf(c) >= 0}
                                                            onClick={handleISolvedContactClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={`${c.FirstName} ${c.LastName}`}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={6}>
                            <span>From:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedPlanYearStartFromDate}
                                        onChange={handleSelectedPlanYearStartChange("fromDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedPlanYearStartToDate}
                                        onChange={handleSelectedPlanYearStartChange("toDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </TabPanel>
                        <TabPanel value={tabValue} index={7}>
                            <span>From:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedExpectedFromDate}
                                        onChange={handleSelectedExpectedChange("fromDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedExpectedToDate}
                                        onChange={handleSelectedExpectedChange("toDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </TabPanel>
                        <TabPanel value={tabValue} index={8}>
                            <span>From:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedCreatedFromDate}
                                        onChange={handleeSelectedCreatedChange("fromDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedCreatedToDate}
                                        onChange={handleeSelectedCreatedChange("toDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </TabPanel>
                        <TabPanel value={tabValue} index={9}>
                            <span>From:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedOEFileSubmissionFromDate}
                                        onChange={handleOEFileSubmissionDateChange("fromDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedOEFileSubmissionToDate}
                                        onChange={handleOEFileSubmissionDateChange("toDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </TabPanel>
                    </Box>
                </div>

            </DialogContent>
            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog>
    )
}

export default OERequestFilterModalBody
