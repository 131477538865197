import React from "react";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader"

function CancelGroupRequest({ groupReq, selectedSubgroups, groupsAndOEContactsList, groupRequestIDURL, openLoader, ...props }) {
    const dialog = useModalDialog(0);

    const [reasonStatus, setReasonStatus] = React.useState("");
    const [selectStatusReasonValidation, setSelectStatusReasonValidation] = React.useState("");
    const [requestCancelClicked, setRequestCancelClicked] = React.useState(false);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }


    React.useEffect(() => {
        if (!reasonStatus?.replaceAll(" ", "").replaceAll("\n", "")) setSelectStatusReasonValidation("Closure reason cannot be empty ");
        else setSelectStatusReasonValidation("");
    }, [reasonStatus, selectStatusReasonValidation]);


    const handleCancelChange = (e) => {
        setReasonStatus(e.target.value);
    }

    const handleCancelGroup = () => {
        setRequestCancelClicked(true);
        if (!reasonStatus) return;
        dialog.closeDialog();
        openModal(0, updateGroupRequests, {
            title: 'Please Confirm',
            question: `Are you sure you want to cancel group request #${groupReq?.groupRequest?.GroupRequestID}?`,
        })

    }

    // Cancel group request
    const updateGroupRequests = () => {
        openLoader();
        const updateGroupRequest = {
            GroupID: groupReq?.groupRequest?.GroupID,
            GroupRequestStatusID: 2,
            StatusReason: reasonStatus,
            GroupSize: groupReq?.groupRequest?.GroupSize,
            GroupEffectiveDate: groupReq?.groupRequest?.GroupEffectiveDate,
            SubGroups: selectedSubgroups,
            GroupContact: groupsAndOEContactsList?.filter(s => `${s.FirstName} ${s.LastName}` === groupReq?.groupRequest?.GroupContact)[0]?.CognitoID,
            OEContact: groupsAndOEContactsList?.filter(s => `${s.FirstName} ${s.LastName}` === groupReq?.groupRequest?.OEContact)[0]?.CognitoID,
            MigrationGroupRequest: groupReq?.groupRequest?.MigrationGroupRequest,
            MigrationStartDate: groupReq?.groupRequest?.MigrationStartDate,
            PlannedFirstProductionDate: groupReq?.groupRequest?.PlannedFirstProductionDate,
            MigrationPhaseID: groupReq?.groupRequest?.MigrationPhaseID,
            GroupInstanceID: groupReq?.groupRequest?.GroupInstanceID
        }
        APIs.editGroupRequestISolved(updateGroupRequest, groupRequestIDURL).then((r) => (
            window.location.reload()
        ));
    }


    return (
        <div id="cancel-connectivity">
            {/* <h5 className="black1 fw-700">Cancel Group Request</h5>
            <Divider /> */}
            <div className="col-xl-12 mt-3 alignvh-center mt-4">
                <h6 className="form-title" style={{ fontFamily: 'Manrope' }}>Reason For Cancellation</h6>
            </div>
            <div className="col-xl-12 mt-3">
                <textarea
                    type="text"
                    style={{ fontFamily: 'Manrope', fontSize: "12px" }}
                    className="action-item-textarea w-100"
                    value={reasonStatus}
                    onChange={handleCancelChange}
                    rows={4}
                />
            </div>
            <div
                className="col-xl-2 alignvh-center"
                hidden={!requestCancelClicked}>
            </div>
            <div
                className="col-xl-10 mt-2 text-danger"
                style={{ fontSize: "13px", fontFamily: 'Manrope' }}
                hidden={!requestCancelClicked}
            >
                {selectStatusReasonValidation}
            </div>
            <div className="w-100 text-center mt-5">
                <Button disabled={!reasonStatus?.replaceAll(" ", "").replaceAll("\n", "")} onClick={handleCancelGroup} variant="contained" className="blue-outlined-btn">Cancel  group request</Button>
            </div>
        </div>
    )
}

export default CancelGroupRequest;