import React from "react";
import { Paper, Button } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import ClearIcon from '@material-ui/icons/Clear';
import refreshSVG from "../../Icons/refresh.svg";
import cancelIconSVG from "../../Icons/CancelIcon.svg";

function FormsTransmissionLogEmployeesDashboardFilter({
    onEmployeeNameFilter,
    onEmployeeSSNFilter,
    setRefreshClicked,
    ...props
}) {

    const [employeeName, setEmployeeName] = React.useState("");
    const [employeeSSN, setEmployeeSSN] = React.useState("");

    const handleEmployeeNameFilter = (e) => {
        setEmployeeName(e.target.value);
        onEmployeeNameFilter(e.target.value);
    }

    const handleEmployeeSSNFilter = (e) => {
        setEmployeeSSN(e.target.value);
        onEmployeeSSNFilter(e.target.value);
    }

    const handleClearSearch = () => {
        setEmployeeName("");
        onEmployeeNameFilter("");
        setEmployeeSSN("");
        onEmployeeSSNFilter("");
    }

    return (
        <div className="search-container">
            <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
            <div className="row">
                <div className="col-xl-3 mt-3">
                    <span>Employee Name:</span>
                    <br />
                    <input type="text" className="search-input w-100" value={employeeName} onChange={handleEmployeeNameFilter} />
                </div>
                <div className="col-xl-3 mt-3">
                    <span>Employee SSN:</span>
                    <br />
                    <input type="text" className="search-input w-100" value={employeeSSN} onChange={handleEmployeeSSNFilter} />
                </div>

                <div className="col-xl-12 mt-4" >
                    <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                        {/* <RefreshIcon color="inherit" style={{ fontSize: 30 }} /> */}
                        <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                            <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                        </svg>
                        <span className="ml-1">Refresh List</span>
                    </Button>
                    <Button className="blue-outlined-btn ml-2" onClick={handleClearSearch}>
                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                        </svg> Clear Search
                    </Button>
                </div>
            </div>
        </div >
    )
}

export default FormsTransmissionLogEmployeesDashboardFilter;