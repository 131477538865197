import React from "react";
import * as APIs from "../../../../Data/APIs";
import ArticleIcon from '@mui/icons-material/Article';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { Select, FormControl } from "@material-ui/core";
import Grid from '@mui/material/Grid';

function AnalystFeedFormAdd({ selectedForm, index, requestSubmitted, onSelectFormMainTypesValidationForm, onSelectFormSubTypesValidationForm, ...props }) {

    const [, forceUpdate] = React.useReducer(x => !x, false);

    const [mainTypesList, setMainTypesList] = React.useState([]);
    const [subTypesList, setSubTypesList] = React.useState([]);

    const [mainTypesValidation, setMainTypesValidation] = React.useState("");
    const [subTypesValidation, setSubTypesValidation] = React.useState("");

    React.useEffect(() => {
        APIs.getDigitalFormsMainTypes().then(s => setMainTypesList(s?.data));
    }, [])

    React.useEffect(() => {
        APIs.getDigitalFormsSubTypes().then(s => setSubTypesList(s?.data));
    }, [])

    React.useEffect(() => {
        if (!selectedForm) return;
        if (!selectedForm.MainTypes || selectedForm.MainTypes?.length <= 0) {
            setMainTypesValidation("Missing form main type, please select at least one");
            onSelectFormMainTypesValidationForm("Missing form main type, please select at least one");
            forceUpdate();
        } else {
            setMainTypesValidation("");
            onSelectFormMainTypesValidationForm("");
        }
    }, [JSON.stringify(selectedForm), mainTypesValidation])

    React.useEffect(() => {
        if (!selectedForm) return;
        if (!selectedForm.SubTypes || selectedForm.SubTypes?.length <= 0) {
            setSubTypesValidation("Missing form sub type, please select at least one");
            onSelectFormSubTypesValidationForm("Missing form sub type, please select at least one");
            forceUpdate();
        } else {
            setSubTypesValidation("");
            onSelectFormSubTypesValidationForm("");
        }
    }, [JSON.stringify(selectedForm), subTypesValidation])


    return (
        <div id="DF-main-types">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4}>
                    <div className="container-internal-file-div">
                        <ArticleIcon style={{ color: "var(--blue-main-color)", fontSize: "50px" }} />
                        <span style={{ wordBreak: "break-all", marginTop: "10px" }}>{selectedForm?.Name}</span>
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <h6 className="form-title">Choose Main Types</h6>
                    <FormControl label="Select Main Types" focused={false} variant="outlined" className="w-100">
                        <Select
                            className="text-dropdown"
                            value={selectedForm?.MainTypes ?? []}
                            onChange={e => {
                                if (!selectedForm) return;
                                if (!selectedForm.MainTypes) selectedForm.MainTypes = [];
                                if (selectedForm.MainTypes.map(s => s.Name).indexOf(e.target.value[selectedForm.MainTypes?.length]) >= 0)
                                    selectedForm.MainTypes.splice(selectedForm.MainTypes.indexOf({
                                        Name: e.target.value[selectedForm.MainTypes?.length],
                                        Id: mainTypesList.filter(s => (s.Name === e.target.value[selectedForm.MainTypes?.length]))[0]?.Id,
                                    }), 1);
                                else {
                                    selectedForm.MainTypes = [...selectedForm.MainTypes, {
                                        Name: e.target.value[selectedForm.MainTypes.length],
                                        Id: mainTypesList.filter(s => (s.Name === e.target.value[selectedForm.MainTypes.length]))[0]?.Id,
                                    }];
                                }
                                forceUpdate();
                            }}
                            variant="outlined"
                            multiple
                            input={
                                <Input />
                            }
                            renderValue={(selected) => selected.map(s => s.Name).join(', ')}
                        >
                            {mainTypesList?.map((c, ci) => (
                                <MenuItem key={`main-types-${ci}`} value={c.Name}>
                                    <Checkbox checked={selectedForm && selectedForm.MainTypes?.map(s => s.Name).indexOf(c.Name) > -1} />
                                    <ListItemText primary={c.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>{mainTypesValidation}</div>
                    {selectedForm && selectedForm.MainTypes?.map((s, si) => (
                        <Chip
                            className="mt-2 mr-1"
                            key={`main-types-chips=${si}`}
                            label={s.Name}
                            onDelete={() => {
                                let array = selectedForm.MainTypes;
                                array.splice(si, 1);
                                selectedForm.MainTypes = [...array];
                                forceUpdate();
                            }}
                        />
                    ))}
                </Grid>
                <Grid item xs={4}>
                    <h6 className="form-title">Choose Sub Types</h6>
                    <FormControl label="Select Sub Types" focused={false} variant="outlined" className="w-100">
                        <Select
                            className="text-dropdown"
                            value={selectedForm?.SubTypes ?? []}
                            onChange={e => {
                                if (!selectedForm) return;
                                if (!selectedForm.SubTypes) selectedForm.SubTypes = [];
                                if (selectedForm.SubTypes.map(s => s.Name).indexOf(e.target.value[selectedForm.SubTypes?.length]) >= 0)
                                    selectedForm.SubTypes.splice(selectedForm.SubTypes.indexOf({
                                        Name: e.target.value[selectedForm.SubTypes?.length],
                                        Id: subTypesList.filter(s => (s.Name === e.target.value[selectedForm.SubTypes?.length]))[0]?.Id,
                                    }), 1);
                                else {
                                    selectedForm.SubTypes = [...selectedForm.SubTypes, {
                                        Name: e.target.value[selectedForm.SubTypes.length],
                                        Id: subTypesList.filter(s => (s.Name === e.target.value[selectedForm.SubTypes.length]))[0]?.Id,
                                    }];
                                }
                                forceUpdate();
                            }}
                            variant="outlined"
                            multiple
                            input={
                                <Input />
                            }
                            renderValue={(selected) => selected.map(s => s.Name).join(', ')}
                        >
                            {subTypesList?.map((c, ci) => (
                                <MenuItem key={`sub-types-${ci}`} value={c.Name}>
                                    <Checkbox checked={selectedForm && selectedForm?.SubTypes?.map(s => s.Name).indexOf(c.Name) > -1} />
                                    <ListItemText primary={c.Name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>{subTypesValidation}</div>
                    {selectedForm && selectedForm.SubTypes?.map((s, si) => (
                        <Chip
                            className="mt-2 mr-1"
                            key={`sub-types-chips=${si}`}
                            label={s.Name}
                            onDelete={() => {
                                let array = selectedForm?.SubTypes;
                                array.splice(si, 1);
                                selectedForm.SubTypes = [...array];
                                forceUpdate();
                            }}
                        />
                    ))}
                </Grid>
            </Grid>
        </div>
    )
}

export default AnalystFeedFormAdd;