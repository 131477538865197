import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download';
import * as Apis from '../../Data/APIs'
import { useLoader } from '../Layout/Loader';
import { toESTTime } from './EmployeeDashboardPendingFormsTab';
import { Button } from "@material-ui/core";
import { useAuthentication } from '../../Data/Authentication';
import downloadIconSVG from "../../Icons/downloadIcon.svg";
import GenericTable from '../Layout/GenericTable';



const EmployeeDashboardTransmittedFormsTab = () => {
    const { openLoader, closeLoader } = useLoader();
    const [transmittedForms, setTransmittedForms] = useState([])
    const [lastEvaluatedKey, setlastEvaluatedKey] = useState(null);
    const { authUser } = useAuthentication();
    let userLegalID = authUser?.attributes["custom:LegalID"]
    let userAccountID = authUser?.attributes["custom:AccountID"]
    let userClientID = authUser?.attributes["custom:ClientID"]

    const getFormsHandler = () => {
        openLoader();
        let currentFeedInfo = {
            // 5 >> Transmitted Forms
            roleID: "10",
            lsi: '5',
            userClientID: userClientID ?? '',
            userAccountID: userAccountID ?? '',
            userLegalID: userLegalID ?? ''
        };
        if (lastEvaluatedKey) currentFeedInfo.LastEvaluatedKey = lastEvaluatedKey
        Apis.getEmployeeForms({ ...currentFeedInfo }).then(r => {
            // debugger
            if (r.msg == "Success") {
                setTransmittedForms([...transmittedForms, ...r?.data])
                setlastEvaluatedKey(r?.LastEvaluatedKey ? r?.LastEvaluatedKey : null)

                // setTotalCount(r?.totalCount)
            } else setTransmittedForms([])

        }).catch((error) => {
            console.log('Get Transmitted Forms Error', error);
        }).finally((t) => closeLoader());
    }

    useEffect(() => {
        getFormsHandler()
    }, [])


    const handleDownloadFile = (form) => {
        let viewFormObject = {
            OutputFile: form?.outputFile,
            isDF: true,
            feedID: form?.feedID,
            executionID: form?.executionID,
            fileName:
                form?.empName + " - " +
                form?.carrierName + " - " +
                (form?.formGenerationTime ? Moment(toESTTime(form?.formGenerationTime)).format('MM-DD-YYYY') : "N/A")
        }
        openLoader();
        Apis.generateOldPdfUrlForEmployees(viewFormObject).then((r) => {
            window.open(r?.data);
            closeLoader();
        });
    }

    // ===========================================================NEW UI TABLE
    const baseColumnArray = [
        {
            name: 'Carrier',
            sortable: false,
            id: 'carrierName',
            selector: form => form.carrierName
        },
        {
            name: 'Form Type',
            sortable: false,
            id: 'formType',
            selector: form => form?.formType?.replace('Enrolment', 'Enrollment')
        },
        {
            name: 'Generation Timestamp',
            sortable: false,
            id: 'formGenerationTime',
            selector: form => form?.formGenerationTime ? Moment(toESTTime(form?.formGenerationTime)).format('MM/DD/YYYY, HH:mm:ss') : 'N/A'
        },
        {
            name: 'Transmitted Time',
            sortable: false,
            id: 'transmittedTime',
            selector: form => form?.transmittedTime ? Moment(toESTTime(form?.transmittedTime)).format('MM/DD/YYYY, HH:mm:ss') : 'N/A'
        },
        {
            name: 'Actions',
            sortable: false,
            id: 'Actions',
            selector: form =>
                <button className="d-flex my-1 btn" type='buttton' onClick={() => handleDownloadFile(form)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" >
                        <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                    </svg>
                    <span className="ml-2 fs-12" style={{ color: "var(--dt-cell-gray)", textWrap: "nowrap" }}>Download</span>
                </button>
        },
    ]


    return (
        <div>
            <GenericTable
                columns={[...baseColumnArray]}
                data={transmittedForms || []}
                onSort={() => { }}
            />
            <div className="text-center mt-3">
                <Button className="load-more-btn blue-btn" disabled={lastEvaluatedKey == null} onClick={() => getFormsHandler()}>Load More Results</Button>
            </div>
        </div>
    )
}

export default EmployeeDashboardTransmittedFormsTab
