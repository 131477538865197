import React, { useEffect, useState } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import { ConfirmModal } from '../Modals/ConfirmModal';
import { ConfirmSignFormModal, EmployeeForm, blobToBytes } from './EmployeeSignFormModal';
import * as Apis from '../../Data/APIs'
import { useLoader } from '../Layout/Loader';
import EmployeeDashboardPendingFormsTabRejectionModal from './EmployeeDashboardPendingFormsTabRejectionModal';
import Moment from 'moment';
import MomentTZ from 'moment-timezone';
import { createRef } from 'react';
import { Button } from "@material-ui/core";
import UploadWetSignatureInput from '../ClientFormsTabs/UploadWetSignatureInput';
import { useAuthentication } from '../../Data/Authentication';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { CustomTooltip } from '../Layout/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { SuccessModal } from '../Modals/SuccessModal';
import editPenIcon from "../../Icons/editPen.svg";
import viewActionItemSVG from "../../Icons/viewActionItem.svg";
import GenericTable from '../Layout/GenericTable';


export function toESTTime(timeString, format = undefined) {
    const localTime = MomentTZ.utc(timeString).toDate();
    return MomentTZ(localTime).tz("America/New_York").format(format ?? "YYYY-MM-DD HH:mm:ss")
}


const EmployeeDashboardPendingFormsTab = () => {
    const dialog = useModalDialog(0);
    const dialog1 = useModalDialog(1);
    const dialog2 = useModalDialog(2);
    const dialog3 = useModalDialog(3);
    const successModalDialog = useModalDialog(4);

    const { openLoader, closeLoader } = useLoader();
    const [pendingForms, setpendingForms] = useState([]);
    const [lastEvaluatedKey, setlastEvaluatedKey] = useState(null);
    const { authUser } = useAuthentication();
    let userHasElectronicSignature = authUser?.attributes["custom:ElectronicSignature"] == "Accepted";

    let userLegalID = authUser?.attributes["custom:LegalID"]
    let userAccountID = authUser?.attributes["custom:AccountID"]
    let userClientID = authUser?.attributes["custom:ClientID"]
    const signaturesRef = createRef();
    const formRef = createRef();
    const pdfRef = createRef();
    const fieldsRef = createRef();

    const handleViewFn = (form) => {

        dialog2.openDialog(
            <></>,
            <>
                {/* will submit rejection reason in this Component */}
                <EmployeeForm
                    form={form}
                    signaturesRef={signaturesRef}
                    fieldsRef={fieldsRef}
                    formRef={formRef}
                    pdfRef={pdfRef}
                    canSign={false}
                    canEdit={false}
                    mainDialog={dialog2}
                />
            </>,
            [
                // { body: <Button type="button" className="black-btn">Save</Button>, onClick: () => { saveEditFields() } },
                // { body: <Button type="button" className="black-btn">Download</Button>, onClick: () => { downloadPDF() } },
                // { body: <Button type="button" className="black-outlined-btn">close</Button>, onClick: () => { dialog2.closeDialog() } }
            ]


        )
    }

    //===================================================SIGN FORMS
    const openSuccessModalHandler = (form, spouseFlag = false) => {
        const batchSubmissionQuote = "Your signed form has been submitted to HR for approval.";
        const BypassAdminApprovalQuote = "Your signed form has been transmitted to the carrier for processing.";

        let quote =
            spouseFlag ? "Thank you for signing the form, <br /> please make sure to upload the form including the required dependent signature.   " :
                form?.isBatch.toUpperCase() === "TRUE" ? batchSubmissionQuote :
                    BypassAdminApprovalQuote
        SuccessModal(successModalDialog, {
            title: 'Success!',
            body: quote,
        })
    }

    const HandleSignCore = (form, spouseFlag = false) => {
        openLoader();
        const { empDOB, empKey, empName, empSSN, feedID, executionID, SK, outputFile, isBatch, formType } = form

        let employeeObiect = {}
        employeeObiect.empDOB = empDOB || ''
        employeeObiect.empKey = empKey || ''
        employeeObiect.empName = empName || ''
        employeeObiect.empSSN = empSSN || ''
        employeeObiect.feedID = feedID || ''
        employeeObiect.executionID = executionID || ''
        employeeObiect.SK = SK || ''
        employeeObiect.outputFile = outputFile || ''
        employeeObiect.formType = formType || ""
        if (spouseFlag) employeeObiect.depSignature = '1'
        if (isBatch) employeeObiect.isBatch = isBatch.toUpperCase() === "TRUE" ? true : false

        let bodyObject = {
            empArray: [{ ...employeeObiect }]
        }
        Apis.transmitEmpoleyeesPendingApproval(bodyObject)
            .then(r => {
                getFormsHandler(true)
            })
            .catch((error) => {
                console.log('Sign Form Error:', error);
            }).finally((t) => {
                dialog.closeDialog();
                // close wet Signature dialog
                dialog3.closeDialog();
                openSuccessModalHandler(form, spouseFlag);
                closeLoader()
            });
    }

    let getGeneratedEmployeeFormHandler = async (form) => {
        openLoader();
        const forms = await Apis.getGeneratedEmployeeForms(form.FeedID, form?.IsolvedEmployeeID);
        const generatedForm = await Apis.getGeneratedEmployeeForm(forms[0].PK, forms[0].SK);
        closeLoader();
        ConfirmSignFormModal(
            dialog1,
            form,
            (spouseFlag) => {
                HandleSignCore(form, spouseFlag);
            },
            'Employee',
            // canEdit (Fields)
            false,
            openLoader,
            closeLoader,
            generatedForm
        )
    }

    const handleSignFn = (form) => ConfirmModal(
        dialog,
        () => {
            getGeneratedEmployeeFormHandler(form)

        },
        {
            title: "Please Confirm",
            question: `<div class="text-center client-form-sign-confirmation-modal">I have agreed to the Terms of Use and Consent to Electronic Signature. I certify the terms and conditions of the agreement are still in effect. I have reviewed the content of the enrollment form and it is true and complete to the best of my knowledge. By selecting ‘Sign’ below, I acknowledge I am electronically signing the enrollment form.</div>`,
        }, "", { yes: "Sign", no: "Cancel" })
    const handleWetSignatureUploader = (form) => dialog3.openDialog('Upload File', <UploadWetSignatureInput form={form} dialog={dialog3} HandleSignCore={HandleSignCore} />)

    // //===================================================SIGN FORMS
    // ======================================================REJECT FORMS
    const handleRejctFn = (form) => {
        dialog.openDialog(
            <div>
                <h5 className="black1 fw-700 text-left mb-0 fs-18">Rejection Reason</h5>
            </div>,
            <>
                {/* will submit rejection reason in this Component */}
                <EmployeeDashboardPendingFormsTabRejectionModal
                    form={form}
                    dialog={dialog}
                    regetFormsHandler={() => getFormsHandler(true)}
                />
            </>

        )
    }
    // ==================================================================
    const getFormsHandler = (withReset = false) => {
        openLoader();
        let currentFeedInfo = {
            //lsi 5 >> Transmissions Tab
            roleID: "10",
            lsi: '15',
            userClientID: userClientID ?? '',
            userAccountID: userAccountID ?? '',
            userLegalID: userLegalID ?? ''
        };
        if (lastEvaluatedKey && !withReset) currentFeedInfo.LastEvaluatedKey = lastEvaluatedKey
        Apis.getEmployeeForms({ ...currentFeedInfo }).then(r => {
            if (r.msg == "Success") {
                if (withReset)
                    setpendingForms(r?.data);
                else
                    setpendingForms([...pendingForms, ...r?.data]);

                setlastEvaluatedKey(r?.LastEvaluatedKey ? r?.LastEvaluatedKey : null)
            } else setpendingForms([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }

    useEffect(() => {
        getFormsHandler(true);
    }, [])
    // =================================================================NEW TABLE UI
    const baseColumnArray = [
        // <div className="col table-cell">Actions</div>
        {
            name: 'Carrier',
            sortable: false,
            id: 'Carrier',
            selector: form => form.carrierName
        },
        {
            name: 'Form Type',
            sortable: false,
            id: 'formType',
            selector: form => form?.formType?.replace('Enrolment', 'Enrollment')
        },
        {
            name: 'Generation Timestamp',
            sortable: false,
            id: 'formGenerationTime',
            selector: form => form?.formGenerationTime ? Moment(toESTTime(form?.formGenerationTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"
        },
        {
            name: 'Actions',
            sortable: false,
            id: 'Actions',
            selector: form =>
                <div className="d-flex flex-column">
                    <div className="d-flex my-1" role="button" onClick={() => handleViewFn(form)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" >
                            <use xlinkHref={`${viewActionItemSVG}#viewAIIcon`}></use>
                        </svg>
                        <span className="ml-2">View</span>
                    </div>
                    {userHasElectronicSignature ?
                        <>
                            {form?.isWetSignature ?
                                <CustomTooltip TransitionComponent={Zoom}
                                    title='Upload Signed Form'>
                                    <div className="d-flex my-1" role="button" onClick={() => handleWetSignatureUploader(form)}>
                                        <FileUploadIcon /><span className="ml-2">Upload</span>
                                    </div>
                                </CustomTooltip> :
                                <div className="d-flex my-1" role="button" onClick={() => handleSignFn(form)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" >
                                        <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                                    </svg>
                                    <span className="ml-2">Sign</span>
                                </div>
                            }
                        </>
                        :
                        <CustomTooltip TransitionComponent={Zoom}
                            title='Upload Signed Form'>
                            <div className="d-flex my-1" role="button" onClick={() => handleWetSignatureUploader(form)}>
                                <FileUploadIcon /><span className="ml-2">Upload</span>
                            </div>
                        </CustomTooltip>
                    }
                    <div className="d-flex my-1" role="button" onClick={() => handleRejctFn(form)}>
                        <HighlightOffIcon /><span className="ml-2">Reject</span>
                    </div>
                </div>
        },


    ]

    return (
        <div>
            <GenericTable
                columns={[...baseColumnArray]}
                data={pendingForms || []}
                onSort={() => { }}
            />
            <div className="text-center mt-3">
                <Button className="load-more-btn blue-btn" disabled={lastEvaluatedKey == null} onClick={() => getFormsHandler()}>Load More Results</Button>
            </div>
        </div>
    )
}

export default EmployeeDashboardPendingFormsTab
