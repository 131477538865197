import React from "react";
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { SuccessModal } from "../../Modals/SuccessModal";
import { FailureModal } from "../../Modals/FailureModal";
import * as APIs from "../../../Data/APIs";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { useHistory } from "react-router-dom";
import { AlertModal } from "../../Modals/AlertModal";
import { parseISO } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'moment';
import dateTimeSVG from "../../../Images/dateTime.svg";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Select } from "@material-ui/core";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

function UncancelConnectivity({ uncancelledConnectivities, groupReq, connectivities, openLoader, closeLoader, ...props }) {
    const dialog = useModalDialog(0);
    const [checked, setChecked] = React.useState(false);
    const [selectedConnectivities, setSelectedConnectivities] = React.useState([]);
    const [statuses, setStatuses] = React.useState([]);
    const [reasonStatuses, setReasonStatuses] = React.useState([]);
    const [selectStatusReasonValidation, setSelectStatusReasonValidation] = React.useState([]);
    const [requestCancelClicked, setRequestCancelClicked] = React.useState(false);
    const [allValid, setAllValid] = React.useState(false);
    const [connectivityRequestsStatus, setConnectivityRequestsStatus] = React.useState([]);
    const [, forceUpdate] = React.useReducer(x => !x, false);

    const handleChange = (e) => {
        setChecked(e.target.checked);
    };

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestStatuses().then((r) => (
            setConnectivityRequestsStatus(r?.data)
        )).finally(() => closeLoader());
    }, [])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            case 3:
                return AlertModal(dialog, handleFunction, content);
            default:
                break;
        }
    }


    React.useEffect(() => {
        if (selectedConnectivities?.length <= 0) {
            setAllValid(false);
        } else if (selectedConnectivities?.length > statuses?.length) {
            setAllValid(false);
        } else for (var ci = 0; ci < selectedConnectivities.length; ci++) {
            if ((statuses.filter(s => (s.ConnectivityRequestStatusID === 3 || s.ConnectivityRequestStatusID === 2)).length !== reasonStatuses.filter(s => (s !== "" && s !== '')).length)) {
                setAllValid(false);
            } else {
                setAllValid(true);
            }
        }
    }, [statuses, reasonStatuses, selectedConnectivities]);



    const handleConnectivityClick = (value) => (event) => {
        let selectedConnectivitiesArray = selectedConnectivities;
        let selectedStatusesArray = statuses;
        if (!value || value === "") selectedConnectivitiesArray = [];
        else if (selectedConnectivitiesArray.indexOf(value) >= 0) {
            selectedStatusesArray.splice(selectedConnectivitiesArray.indexOf(value), 1);
            selectedConnectivitiesArray.splice(selectedConnectivitiesArray.indexOf(value), 1);

        }
        else {
            selectedConnectivitiesArray.push(value);
        };
        setSelectedConnectivities([...selectedConnectivitiesArray]);
        setStatuses([...selectedStatusesArray]);
    }

    const handleCancelConnectivity = () => {
        setRequestCancelClicked(true);

        dialog.closeDialog();
        openModal(0, submitUncancelGroupRequest, {
            title: 'Please Confirm',
            question: `Are you sure you want to uncancel this group request with the chosen attached connectivity request(s)?`,
        })
    }

    // Cancel connectivity request
    const submitUncancelGroupRequest = () => {
        openLoader();
        let ConnectivityRequestsArrayBody = [];
        selectedConnectivities.map((c, ci) => {
            ConnectivityRequestsArrayBody.push({
                Id: selectedConnectivities[ci].ConnectivityRequestID,
                StatusID: statuses[ci].ConnectivityRequestStatusID,
                StatusName: statuses[ci].ConnectivityRequestStatusName,
                UncancellationReason: reasonStatuses[ci]
            })
        })
        const uncancelGroupRequest = {
            ConnectivityRequestsArray: ConnectivityRequestsArrayBody,
            GroupRequestID: groupReq?.groupRequest?.GroupRequestID,

        }

        APIs.uncancelGroupRequest(uncancelGroupRequest).then((r) => (
            window.location.reload()
        ));
    }

    return (
        <div id="cancel-connectivity">
            {/* <h5 className="black1 fw-700 ml-3">Connectivity Requests</h5>
            <Divider /> */}
            {connectivities?.map((c, ci) => (
                <div className="mt-3" key={`cancel-connectivity-${ci}`}>
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <span className="alignvh-center">
                                <Checkbox
                                    disabled={c.ConnectivityRequestStatusName !== "Cancelled"}
                                    id={`connectivity-option-${ci}`}
                                    value={c}
                                    checked={selectedConnectivities && selectedConnectivities[c]}
                                    onChange={handleChange}
                                    onClick={handleConnectivityClick(c)}
                                />
                            </span>
                        }
                        label={c.IsolvedCarrierName === "Third Party" ? `${c.IsolvedCarrierName} (${c.ThirdPartyName})` : c.IsolvedCarrierName}
                    />
                    {c.PlanTypes?.map((p, pi) => (
                        <h6 key={`plan-types-${pi}`} className="ml-3 plan-type-modal">{p.PlanTypeName}</h6>
                    ))}

                    {(c.CancellationDate !== null && c.ConnectivityRequestStatusName !== "Cancelled") && <div className=" text-alert-yellow" >{`This connectivity request has a future cancellation date ${Moment(c.CancellationDate).format('MM/DD/YYYY')}`}</div>}


                    {selectedConnectivities.find((s) => s === c) &&
                        <div key={`cancel-${ci}`}>
                            <div className="col-xl-12 mt-3 alignvh-center mt-4">
                                <h6 className="form-title" style={{ fontFamily: 'Manrope' }}>Status</h6>
                            </div>
                            <div className="col-xl-12 mt-3">
                                <FormControl label="Select Status" focused={false} variant="outlined" className="w-100">
                                    <Select
                                        className="text-dropdown fs-16"
                                        value={statuses[selectedConnectivities.indexOf(c)]}
                                        onChange={(e) => {
                                            let reasonStatuse = [...statuses];
                                            reasonStatuse[selectedConnectivities.indexOf(c)] = e.target.value;
                                            if (e.target.value.ConnectivityRequestStatusID !== 3 || e.target.value.ConnectivityRequestStatusID !== 2) {
                                                reasonStatuses[selectedConnectivities.indexOf(c)] = ""
                                            }
                                            setStatuses(reasonStatuse);
                                        }}
                                    >
                                        {connectivityRequestsStatus.filter(s => s.ConnectivityRequestStatusID !== 5)?.map((s, si) => (
                                            <MenuItem key={`connect-status-${si}`} value={s}>{s?.ConnectivityRequestStatusName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            {(statuses[selectedConnectivities.indexOf(c)]?.ConnectivityRequestStatusID === 2 || statuses[selectedConnectivities.indexOf(c)]?.ConnectivityRequestStatusID === 3) && (
                                <>
                                    <div className="col-xl-12 mt-3 alignvh-center mt-4">
                                        <h6 className="form-title" style={{ fontFamily: 'Manrope' }}>
                                            {statuses[selectedConnectivities.indexOf(c)]?.ConnectivityRequestStatusID === 2 ? "On Hold " : statuses[selectedConnectivities.indexOf(c)]?.ConnectivityRequestStatusID === 3 ? "Block " : ""}
                                            Reason
                                        </h6>
                                    </div>
                                    <div className="col-xl-12 mt-3">
                                        <textarea
                                            type="text"
                                            className="action-item-textarea w-100 fs-16"
                                            value={reasonStatuses[selectedConnectivities.indexOf(c)]}
                                            onChange={(e) => {
                                                let reasonStatuse = [...reasonStatuses];
                                                reasonStatuse[selectedConnectivities.indexOf(c)] = e.target.value;
                                                setReasonStatuses(reasonStatuse);
                                            }}
                                            rows={4}
                                        />
                                    </div>
                                </>
                            )}
                        </div>}
                </div>
            ))
            }


            <div className="w-100 text-center mt-5">
                <CustomTooltip TransitionComponent={Zoom} title={selectedConnectivities.length <= 0 ? "Please select at least one connectivity" : ""}>
                    <span>
                        <Button disabled={!allValid} onClick={handleCancelConnectivity} variant="contained" className="blue-outlined-btn">{selectedConnectivities.length > 1 ? 'Uncancel Connectivities' : 'Uncancel Connectivity'}</Button>
                    </span>
                </CustomTooltip>
            </div>
        </div >
    )
}

export default UncancelConnectivity;