import React from "react";
import QRCode from 'qrcode.react';
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContextText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { ToastsStore } from 'react-toasts';
import { useLoader } from "../Layout/Loader";


function QRCodeComponent({ cognitoUser, ...props }) {

    const routeHistory = useHistory();

    const { openLoader, closeLoader } = useLoader();
    const [error, setError] = React.useState("");
    const [secretCode, setSecretCode] = React.useState("");
    const [mfaCode, setMFACode] = React.useState("");


    React.useEffect(() => {
        if (cognitoUser) {
            Auth.setupTOTP(cognitoUser).then(code => {
                setSecretCode(`otpauth://totp/${cognitoUser.attributes.email}?secret=${code}&issuer=isolved Carrier Connect`);
            });
        }
    }, [cognitoUser])

    const handleSave = (e) => {
        e.preventDefault();
        openLoader();
        Auth.verifyTotpToken(cognitoUser, mfaCode)
            .then(() => {
                Auth.setPreferredMFA(cognitoUser, 'TOTP').then(() => {
                    ToastsStore.success('Token Verified Updated');
                    window.location.reload();
                });
            })
            .catch((err) => {
                if (err.message.includes("session is expired"))
                    setError("Session is expired, please try again!")
                else if (err.message.includes("Code mismatch"))
                    setError("Code is incorrect, please try again!")
                else if (err.message.includes("Member must satisfy regular expression pattern") || err.message.includes("Member must have length greater than or equal to 6") || err.message.includes("Member must have length less than or equal to 6"))
                    setError("Code must satisfy regular expression pattern[0-9] and have length less than or equal to 6")
                else if (err.message.includes("Too many invalid credentials attempts"))
                    window.location.href = "/";
                else setError(err.message);
                closeLoader();
            });
    };

    return (
        <div>
            <form onSubmit={handleSave}>
                <div className="dialog-title">
                    <h2>Enable time-based one-time password (TOTP)</h2>
                </div>
                <div className="dialog-body">
                    <h5>1- Install an authenticator app on your mobile device.</h5>
                    <h5>2- Scan the following QR code in your authenticator app.</h5>
                    <QRCode className="mt-2 mb-2" value={secretCode} />
                    <h5>3- Enter the code from your authenticator app below.</h5>
                    <input required type="text" className="text-input mt-3 mb-3" style={{ minHeight: "40px", minWidth: "35%" }} value={mfaCode} onInput={(e) => setMFACode(e.target.value)} />
                    {error && error !== "" && (
                        <div style={{ color: "red", textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                            <label>Error: <span>{error}</span></label>
                        </div>
                    )}
                </div>
                <div className="dialog-footer">
                    <Button type="submit" className="verify-mfa-btn mfa-btn mr-3">
                        Save Authentication
                    </Button>
                    <Button type="button" onClick={(e) => {
                        e.preventDefault();
                        Auth.signOut()
                            .then((r) => {
                                window.location.reload();
                            })
                            .catch((error) => {
                                console.log("Signout Error:", !error.message ? error : error.message);
                            });
                    }} className="verify-mfa-btn mfa-btn">
                        Sign Out
                    </Button>
                </div>
            </form>
        </div>
    )
}

export default QRCodeComponent;