import React from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SelectClientInformationInternal from "./SelectClientInformationInternal";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

function SelectClientInformation({ groupReq, isPEOClicked, selectedOtherCarriers, requestSubmitted, requestSaved, parentForceUpdate, carriers, setSelectedCarriers, selectedCarriers, groupsAndOEContactsList, onSelectClientContactValidationForm, onSelectClientEffectiveDateValidationForm,
    selectClientEffectiveDateValidationForm, selectClientContactValidationForm, ...props }) {

    const [expanded, setExpanded] = React.useState("ClientEffectiveDate");

    return (
        <Accordion expanded={expanded === `ClientEffectiveDate`}>
            <AccordionSummary
                onClick={() => expanded === `ClientEffectiveDate` ? setExpanded("") : setExpanded("ClientEffectiveDate")}
                // className="pl-0 pr-0"
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="expand-carrier-contacts"
            >


                <span >{groupReq?.groupRequest?.IsPEO !== 1 || !isPEOClicked ? 'Client Information' : 'PEO Information'}</span>
            </AccordionSummary>
            <AccordionDetails className="d-flex flex-column">
                <div className="mb-2 text-danger" hidden={!requestSubmitted}>{selectClientEffectiveDateValidationForm}</div>
                <div className="mb-2 text-danger" hidden={!requestSubmitted || !groupReq}>{selectClientContactValidationForm}</div>
                <SelectClientInformationInternal
                    groupReq={groupReq}
                    isPEOClicked={isPEOClicked}
                    selectedOtherCarriers={selectedOtherCarriers}
                    parentForceUpdate={parentForceUpdate}
                    carriers={carriers}
                    setSelectedCarriers={setSelectedCarriers}
                    selectedCarriers={selectedCarriers}
                    groupsAndOEContactsList={groupsAndOEContactsList}
                    onSelectClientContactValidationForm={onSelectClientContactValidationForm}
                    onSelectClientEffectiveDateValidationForm={onSelectClientEffectiveDateValidationForm}
                    requestSubmitted={requestSubmitted}
                    requestSaved={requestSaved} />
            </AccordionDetails>
        </Accordion>
    )
}

export default SelectClientInformation;
