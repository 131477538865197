import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #E6E8F1',
        marginTop: "1em",
    },
    indicator: {
        backgroundColor: "var(--pink-primary-color)",
        height: '3px',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        minWidth: 70,
        fontWeight: '350 !important',
        fontSize: '14px',
        marginRight: theme.spacing(4),
        color: 'var(--var(--grey3-secondary-color))',
        '&:hover': {
            color: '#42505C',
            opacity: 1,
        },
    },
    selected: {
        color: "var(--black-dark-color)",
        fontWeight: '500 !important',
    },
}))((props) => <Tab disableRipple {...props} />);


function TabsTitles({ tabTitle, value, setValue, onChange, ...props }) {

    return (
        <div className="w-100">
            <AntTabs value={value} onChange={onChange}>
                {tabTitle.map((t, ti) => (
                    <AntTab key={ti} label={t} />
                ))}
            </AntTabs>
        </div>
    )
}

export default TabsTitles;