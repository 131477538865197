import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PieChartIcon from '@mui/icons-material/PieChart';
import { Divider, Paper } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Chart from "react-google-charts";
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import Moment from 'moment';
import { changeTimeZoneToEST } from "../../Layout/DateConverted";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import LinearProgressBar from "../../Layout/LinearProgressBar";


const todayDate = changeTimeZoneToEST(new Date());
todayDate.setHours(0, 0, 0, 0)

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

function TransmissionsStatistics({
    title,
    isProductionDashboard,
    onFromDateFilter,
    onToDateFilter,
    fromDateFilter,
    toDateFilter,
    onTransmissionStatusFilter,
    onTransmissionPhaseFilter,
    tableRef,
    onTransmissionStatusDashboardChange,
    onTransmissionPhaseDashboardChange,
    onSetFilterCard,
    refreshClicked,
    ...props
}) {

    const { openLoader, closeLoader } = useLoader();
    const [dashboardTransmissionStatus, setDashboardTransmissionStatus] = React.useState([]);
    const [dashboardTransmissionPhase, setDashboardTransmissionPhase] = React.useState([]);

    React.useEffect(() => {
        getTransmissionsStats();
    }, [fromDateFilter, toDateFilter, refreshClicked])

    const getTransmissionsStats = () => {
        if (fromDateFilter === null || toDateFilter === null) return;
        openLoader();
        const requestTransmissions = {
            isProduction: isProductionDashboard ? true : false,
            fromDate: fromDateFilter ? Moment(fromDateFilter).format('YYYY-MM-DD') : null,
            toDate: toDateFilter ? Moment(toDateFilter).format('YYYY-MM-DD') : null,
            lastRunFromDate: isProductionDashboard ? Moment(todayDate).format('YYYY-MM-DD') : null,
            lastRunToDate: isProductionDashboard ? Moment(todayDate).format('YYYY-MM-DD') : null,
        };

        APIs.getTranmissionsDashboard(requestTransmissions).then((r) => (
            setDashboardTransmissionStatus(r?.data?.dashboard?.StatusPercentage),
            setDashboardTransmissionPhase(r?.data?.dashboard?.phasesPercentage),
            closeLoader()
        ));
    }

    const handleDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            onFromDateFilter(date);
        }
        else {
            onToDateFilter(date);
        }
    };

    React.useEffect(() => {
        if (fromDateFilter === null) {
            onFromDateFilter(monthDate);
        }
    }, [fromDateFilter])

    React.useEffect(() => {
        if (toDateFilter === null) {
            onToDateFilter(todayDate);
        }
    }, [toDateFilter])

    const getCountsForElementStatus = (id) => {
        // const elementIndex = dashboardTransmissionStatus.findIndex((element) => {
        //     return element.id === id;
        // })
        // return dashboardTransmissionStatus[elementIndex].count;
    }

    function handleStatus(status) {
        onTransmissionStatusDashboardChange(true);
        onTransmissionPhaseDashboardChange(false);
        onTransmissionPhaseFilter("");
        onTransmissionStatusFilter(status);
        tableRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    function handlePhase(phase) {
        onTransmissionPhaseDashboardChange(true);
        onTransmissionStatusDashboardChange(false);
        onTransmissionStatusFilter("");
        onTransmissionPhaseFilter(phase);
        tableRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const _statusColors = [
        "#7cd992",
        "#0e4c8a",
        "#00C305",
        "#8088cd",
        "#EBA400",
        "#F22525",
        "#f34266",
        "#DC143C",
        "#FF0000",
        "#C04000",
        "#ec5233",
        "#880808",
    ];

    const _phaseColors = [
        "#CCCCCC",
        "#000000",
        "#707C97",
        "#7cd992",
        "#EBA400",
        "#5382a1",
        "#0e4c8a",
        "#00C305",
    ];

    return (
        <div id="transmissions-statistics">
            <Accordion className="exchangeAccordion mb-4">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                    style={{ minHeight: "65px" }}
                >
                    <div style={{ fontWeight: "500" }}>
                        <PieChartIcon className="color pink-primary-color mr-3" />
                        <span>{title}</span>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ borderTop: "1px solid gainsboro" }}>
                    {!isProductionDashboard && (
                        <div className="row mb-5">
                            <div className="col-xl-3">
                                <span>Request Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={fromDateFilter}
                                            onChange={handleDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={toDateFilter}
                                            onChange={handleDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    )}
                    <div className="mb-5">
                        {dashboardTransmissionStatus && dashboardTransmissionStatus.length > 0 && dashboardTransmissionStatus.filter(s => s.count === 0).length !== dashboardTransmissionStatus.length && (
                            <>
                                <h6 className="fw-500">Execution Statuses</h6>
                                <div className="row mt-3">
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 1)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 1)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 1)?.count} level="blue" onClickHandler={() => handleStatus(1)} />
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 11)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 11)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 11)?.count} level="blue" onClickHandler={() => handleStatus(11)} />
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 2)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 2)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 2)?.count} level="green" onClickHandler={() => handleStatus(2)} />
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 14)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 14)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 14)?.count} level="green" onClickHandler={() => handleStatus(14)} />
                                    </div>
                                    <div className="col-xl-4">
                                        {isProductionDashboard && (
                                            <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 12)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 12)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 12)?.count} level="blue" onClickHandler={() => handleStatus(12)} />
                                        )}
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 3)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 3)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 3)?.count} level="red" onClickHandler={() => handleStatus(3)} />
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 4)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 4)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 4)?.count} level="red" onClickHandler={() => handleStatus(4)} />
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 5)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 5)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 5)?.count} level="red" onClickHandler={() => handleStatus(5)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 6)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 6)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 6)?.count} level="red" onClickHandler={() => handleStatus(6)} />
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 7)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 7)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 7)?.count} level="red" onClickHandler={() => handleStatus(7)} />
                                        <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 8)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 8)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 8)?.count} level="red" onClickHandler={() => handleStatus(8)} />
                                        {isProductionDashboard && (
                                            <LinearProgressBar title={dashboardTransmissionStatus.find(el => el.id == 13)?.name} percentage={Math.round(dashboardTransmissionStatus.find(el => el.id == 13)?.percentage * 10) / 10} count={dashboardTransmissionStatus.find(el => el.id == 13)?.count} level="red" onClickHandler={() => handleStatus(13)} />
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {!isProductionDashboard && dashboardTransmissionPhase && dashboardTransmissionPhase.length > 0 && dashboardTransmissionPhase.filter(s => s.count === 0).length !== dashboardTransmissionPhase.length && (
                            <>
                                <h6 className="fw-500">Feed Phases</h6>
                                <div className="row mt-3">
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardTransmissionPhase[1]?.name} percentage={Math.round(dashboardTransmissionPhase[1]?.percentage * 10) / 10} count={dashboardTransmissionPhase[1]?.count} level="blue" onClickHandler={() => handlePhase(1)} />
                                        <LinearProgressBar title={dashboardTransmissionPhase[2]?.name} percentage={Math.round(dashboardTransmissionPhase[2]?.percentage * 10) / 10} count={dashboardTransmissionPhase[2]?.count} level="blue" onClickHandler={() => handlePhase(2)} />
                                        <LinearProgressBar title={dashboardTransmissionPhase[3]?.name} percentage={Math.round(dashboardTransmissionPhase[3]?.percentage * 10) / 10} count={dashboardTransmissionPhase[3]?.count} level="blue" onClickHandler={() => handlePhase(3)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardTransmissionPhase[4]?.name} percentage={Math.round(dashboardTransmissionPhase[4]?.percentage * 10) / 10} count={dashboardTransmissionPhase[4]?.count} level="blue" onClickHandler={() => handlePhase(4)} />
                                        <LinearProgressBar title={dashboardTransmissionPhase[5]?.name} percentage={Math.round(dashboardTransmissionPhase[5]?.percentage * 10) / 10} count={dashboardTransmissionPhase[5]?.count} level="blue" onClickHandler={() => handlePhase(5)} />
                                        <LinearProgressBar title={dashboardTransmissionPhase[6]?.name} percentage={Math.round(dashboardTransmissionPhase[6]?.percentage * 10) / 10} count={dashboardTransmissionPhase[6]?.count} level="blue" onClickHandler={() => handlePhase(6)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardTransmissionPhase[7]?.name} percentage={Math.round(dashboardTransmissionPhase[7]?.percentage * 10) / 10} count={dashboardTransmissionPhase[7]?.count} level="green" onClickHandler={() => handlePhase(7)} />
                                        <LinearProgressBar title={dashboardTransmissionPhase[0]?.name} percentage={Math.round(dashboardTransmissionPhase[0]?.percentage * 10) / 10} count={dashboardTransmissionPhase[0]?.count} level="red" onClickHandler={() => handlePhase(8)} />
                                    </div>
                                </div>
                            </>
                        )}

                        {dashboardTransmissionStatus.filter(s => s.count === 0).length === dashboardTransmissionStatus.length && dashboardTransmissionPhase.filter(s => s.count === 0).length === dashboardTransmissionPhase.length && (
                            <div className="no-data-alert mt-5">
                                <h5>No Data Available!</h5>
                            </div>
                        )}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div >
    )
}

export default TransmissionsStatistics;
