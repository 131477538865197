import { Snackbar } from '@mui/material';
import { Button, Divider, Link } from "@material-ui/core";
import { useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyToClipboardButton = ({ textToCopy }) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(true);
        navigator.clipboard.writeText(textToCopy);
    }
    return (
        <>
            <Button onClick={() => handleClick()}><ContentCopyIcon /></Button>
            <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Copied to clipboard"
            />
        </>
    )
}
export default CopyToClipboardButton;