import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { MenuItem, Select, FormControl } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import dateTimeSVG from "../../../Images/dateTime.svg";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { parseISO } from 'date-fns';
import Moment from 'moment';
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import Grid from '@mui/material/Grid';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SelectSingleConnectivityRequestMigration({
    groupReq,
    carrier,
    index,
    parentForceUpdate,
    selectedCRTypes,
    isPEOClicked,
    migrationPhasesList,
    ...props
}) {

    React.useEffect(() => {
        if (carrier.Migration) return;
        else {
            carrier.Migration = groupReq?.groupRequest?.MigrationGroupRequest
        }
    }, [])

    // React.useEffect(() => {
    //     if (carrier.MigrationStartDate) return;
    //     else {
    //         carrier.MigrationStartDate = groupReq?.groupRequest?.MigrationStartDate ? Moment(groupReq?.groupRequest?.MigrationStartDate).format('YYYY-MM-DD') : null
    //     }
    // }, [])

    // React.useEffect(() => {
    //     if (carrier.PlannedFirstProductionDate) return;
    //     else {
    //         carrier.PlannedFirstProductionDate = groupReq?.groupRequest?.PlannedFirstProductionDate ? Moment(groupReq?.groupRequest?.PlannedFirstProductionDate).format('YYYY-MM-DD') : null
    //     }
    // }, [])

    React.useEffect(() => {
        if (carrier.MigrationPhase) return;
        else {
            carrier.MigrationPhase = groupReq?.groupRequest?.MigrationPhaseID;
        }
    }, [])


    // on change migration >> default migration phase(Phase 1 - Map Built)
    React.useEffect(() => {
        if (carrier.Migration) {
            if (carrier.Migration == "No") {
                // if migration == No remove the value of MigrationPhase
                carrier.MigrationPhase = groupReq?.groupRequest?.MigrationPhaseID;
            } else {
                // if migration == (EBN or everything) change the value of MigrationPhase to (Phase 1 - Map Built)(phase id = 2)
                carrier.MigrationPhase = 2;
            }
            parentForceUpdate();
        }

    }, [carrier.Migration])

    return (
        <TabPanel value={index} index={index} className="container-div" >
            <div className="row ">
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <h6 className="form-title">Migration
                            <span className="text-danger mb-2 ml-2">*</span></h6>

                        <FormControl label="Select Migration" focused={false} variant="outlined" className="w-100">
                            <Select
                                className="text-dropdown"
                                value={carrier.Migration ?? groupReq?.groupRequest?.MigrationGroupRequest}
                                onChange={e => {
                                    carrier.Migration = (e.target.value);
                                    parentForceUpdate();
                                }}
                            >
                                <MenuItem value="No">No</MenuItem>
                                <MenuItem value="eBenefits Network">eBenefits Network</MenuItem>
                                <MenuItem value="Everything Benefits">Everything Benefits</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Migration Added Field*/}
                    {(!carrier.Migration && (groupReq?.groupRequest?.MigrationGroupRequest === "eBenefits Network" || groupReq?.groupRequest?.MigrationGroupRequest === "Everything Benefits")) &&
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Migration Start Date
                                    <span className="text-danger mb-2 ml-2">*</span></h6>

                                <div className="text-input " >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className="w-100"
                                            autoOk={true}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="migrationStart-date"
                                            value={carrier.MigrationStartDate ? carrier.MigrationStartDate === null ? carrier.MigrationStartDate : parseISO(carrier.MigrationStartDate) : groupReq?.groupRequest?.MigrationStartDate ? Moment(groupReq?.groupRequest?.MigrationStartDate).format('MM/DD/YYYY') : null}
                                            onChange={(date) => {
                                                carrier.MigrationStartDate = (Moment(date).format('YYYY-MM-DD'));
                                                parentForceUpdate();
                                            }}
                                            keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Planned First Production Date
                                    <span className="text-danger mb-2 ml-2">*</span></h6>

                                <div className=" text-input" >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className="w-100"
                                            autoOk={true}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="migrationStart-date"
                                            value={carrier.PlannedFirstProductionDate ? carrier.PlannedFirstProductionDate === null ? carrier.PlannedFirstProductionDate : parseISO(carrier.PlannedFirstProductionDate) : groupReq?.groupRequest?.PlannedFirstProductionDate ? Moment(groupReq?.groupRequest?.PlannedFirstProductionDate).format('MM/DD/YYYY') : null}
                                            onChange={(date) => {
                                                carrier.PlannedFirstProductionDate = (Moment(date).format('YYYY-MM-DD'));
                                                parentForceUpdate();
                                            }}
                                            keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Migration Phase
                                    <span className="text-danger mb-2 ml-2">*</span></h6>

                                <FormControl label="Select migration phase" focused={false} variant="outlined" className="w-100">
                                    <Select
                                        className="text-dropdown"
                                        value={carrier.MigrationPhase ?? groupReq?.groupRequest?.MigrationPhaseID}
                                        onChange={e => {
                                            carrier.MigrationPhase = (e.target.value);
                                            parentForceUpdate();
                                        }}
                                    >
                                        {migrationPhasesList && migrationPhasesList?.map((c, ci) => (
                                            <MenuItem key={`migration-phase-${ci}`} value={c.Id}>{c.Name}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>

                        </>
                    }

                    {carrier.Migration && (carrier.Migration === "eBenefits Network" || carrier.Migration === "Everything Benefits") &&
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Migration Start Date
                                    <span className="text-danger mb-2 ml-2">*</span></h6>

                                <div className="text-input w-100" >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className="w-100"
                                            autoOk={true}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="migrationStart-date"
                                            value={carrier.MigrationStartDate ? Moment(carrier.MigrationStartDate).format('MM/DD/YYYY') : null}
                                            // value={carrier.MigrationStartDate ? carrier.MigrationStartDate === null ? carrier.MigrationStartDate : parseISO(carrier.MigrationStartDate) : groupReq?.groupRequest?.MigrationStartDate ? Moment(groupReq?.groupRequest?.MigrationStartDate).format('MM/DD/YYYY') : null}
                                            onChange={(date) => {
                                                // date == null ? null : Moment(date).format('YYYY-MM-DD') >> to avoid invalid format in case null value 
                                                carrier.MigrationStartDate = (date == null ? null : Moment(date).format('YYYY-MM-DD'));
                                                parentForceUpdate();
                                            }}
                                            keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Planned First Production Date
                                    <span className="text-danger mb-2 ml-2">*</span></h6>

                                <div className=" text-input w-100" >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            className="w-100"
                                            autoOk={true}
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="migrationStart-date"
                                            value={carrier.PlannedFirstProductionDate ? Moment(carrier.PlannedFirstProductionDate).format('MM/DD/YYYY') : null}
                                            // value={carrier.PlannedFirstProductionDate ? carrier.PlannedFirstProductionDate === null ? carrier.PlannedFirstProductionDate : parseISO(carrier.PlannedFirstProductionDate) : groupReq?.groupRequest?.PlannedFirstProductionDate ? Moment(groupReq?.groupRequest?.PlannedFirstProductionDate).format('MM/DD/YYYY') : null}
                                            onChange={(date) => {
                                                // date == null ? null : Moment(date).format('YYYY-MM-DD') >> to avoid invalid format in case null value 
                                                carrier.PlannedFirstProductionDate = (date == null ? null : Moment(date).format('YYYY-MM-DD'));
                                                parentForceUpdate();
                                            }}
                                            keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Migration Phase
                                    <span className="text-danger mb-2 ml-2">*</span></h6>


                                <FormControl label="Select migration phase" focused={false} variant="outlined" className="w-100">
                                    <Select
                                        className="text-dropdown"
                                        value={carrier.MigrationPhase ?? groupReq?.groupRequest?.MigrationPhaseID}
                                        onChange={e => {
                                            carrier.MigrationPhase = (e.target.value);
                                            parentForceUpdate();
                                        }}
                                    >
                                        {migrationPhasesList && migrationPhasesList?.map((c, ci) => (
                                            <MenuItem key={`migration-phase-${ci}`} value={c.Id}>{c.Name}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>

                        </>
                    }
                </Grid>
            </div>
        </TabPanel>
    )
}

export default SelectSingleConnectivityRequestMigration;
