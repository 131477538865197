import React from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { Button, Divider, Link, } from "@material-ui/core";
import Truncate from 'react-truncate';
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import MomentTZ from 'moment-timezone';
import { event } from "jquery";
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import { SuccessModal } from "../Modals/SuccessModal";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { FailureModal } from "../Modals/FailureModal";
import { useAuthentication } from "../../Data/Authentication";
import { saveAs } from 'file-saver'
import Moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboardButton from "../Layout/CopyToClipboardButton";
import DescriptionIcon from '@mui/icons-material/Description';
import PieChartIcon from '@mui/icons-material/PieChart';
import SingleFeedTransmissionsPlans from "./SingleFeedTransmissionsPlans";
import downloadIconSVG from "../../Icons/downloadIcon.svg";
import fileNameSVG from "../../Icons/fileName.svg";
import schedulerIconSVG from "../../Icons/scheduler.svg";
import viewPlansSVG from "../../Icons/viewPlans.svg";
import retryIcon from "../../Icons/rotate-left.svg";
import processEndIcon from "../../Icons/arrow-right-long-to-line.svg";

export function toESTTime(timeString, format = undefined) {
    const localTime = MomentTZ.utc(timeString).toDate();
    return MomentTZ(localTime).tz("America/New_York").format(format ?? "YYYY-MM-DD HH:mm:ss")
}


function SingleFeedTransmissions({
    feedTransmission,
    index,
    key,
    refreshClicked,
    setRefreshClicked,
    selectedPage,
    onSelectedPage,
    pageSize,
    onPageSize,
    totalCount,
    setTotalCount,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [accordionExpanded, setAccordionExpanded] = React.useState(false);
    const [feedTransmissionLogs, setFeedTransmissionLogs] = React.useState([]);
    const [executionID, setExecutionID] = React.useState();
    const [logIndex, setLogIndex] = React.useState(-1);


    const [parserArray, setParserArray] = React.useState([]);
    const [trackerArray, setTrackerArray] = React.useState([]);
    const [mapperArray, setMapperArray] = React.useState([]);
    const [serializerArray, setSerializerArray] = React.useState([]);
    const [encrypterArray, setEncrypterArray] = React.useState([]);
    const [transmitterArray, setTransmitterArray] = React.useState([]);
    const [recentComponentsArray, setRecentComponentsArray] = React.useState([]);


    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        if (accordionExpanded) {
            openLoader();
            APIs.getSingleFeedTranmissionLogs(feedTransmission?.SK).then((r) => (
                setFeedTransmissionLogs(r?.data ?? []),
                setRefreshClicked(false),
                closeLoader()
            ));
        }
    }, [logIndex, refreshClicked === true, accordionExpanded])


    const retryComponent = (index) => {
        setLogIndex(index);

        const retryBody = {
            feedID: parseInt(feedTransmissionLogs[index]?.FeedID),
            type: parseInt(feedTransmissionLogs[index]?.ComponentID),
            isRetry: true,
            executionID: feedTransmissionLogs[index]?.PK,
            fileName: feedTransmissionLogs[index]?.InputFile.split("?AWSAccessKey")[0].substring(
                feedTransmissionLogs[index]?.InputFile.split("?AWSAccessKey")[0].indexOf("Engine/"),
                feedTransmissionLogs[index]?.InputFile.split("?AWSAccessKey")[0].length
            ),
            executionStatus: feedTransmissionLogs[index]?.ExecutionStatus,
            sk: feedTransmissionLogs[index]?.SK,
        }
        APIs.retryProcessToEnd(retryBody).then(r => (
            SuccessModal(dialog, {
                title: 'Processing...',
                body: `Kindly be noted that this may take few minutes.`,
            })
        ));
    }

    const processToEndComponent = (index) => {
        setLogIndex(index);

        const processToEndBody = {
            feedID: parseInt(feedTransmissionLogs[index]?.FeedID),
            type: parseInt(feedTransmissionLogs[index]?.ComponentID),
            isRetry: false,
            executionID: feedTransmissionLogs[index]?.PK,
            fileName: feedTransmissionLogs[index]?.InputFile.split("?AWSAccessKey")[0].substring(
                feedTransmissionLogs[index]?.InputFile.split("?AWSAccessKey")[0].indexOf("Engine/"),
                feedTransmissionLogs[index]?.InputFile.split("?AWSAccessKey")[0].length
            ),
            executionStatus: feedTransmissionLogs[index]?.ExecutionStatus,
            sk: feedTransmissionLogs[index]?.SK,
        }
        APIs.retryProcessToEnd(processToEndBody).then(r => (
            SuccessModal(dialog, {
                title: 'Processing...',
                body: `Kindly be noted that this may take few minutes.`,
            })
        ));
    }
    const confirmProcess = (index) => {
        setLogIndex(index);

        const confirmProcessBody = {
            feedID: feedTransmissionLogs[index]?.FeedID,
            executionID: feedTransmissionLogs[index]?.PK,
            filePath: feedTransmissionLogs[index]?.OutputFile.split("?AWSAccessKey")[0].substring(
                feedTransmissionLogs[index]?.OutputFile.split("?AWSAccessKey")[0].indexOf("Engine/"),
                feedTransmissionLogs[index]?.OutputFile.split("?AWSAccessKey")[0].length
            ),
            sk: feedTransmissionLogs[index]?.SK,
        }
        APIs.confirmProcessTransmitter(confirmProcessBody).then(r => (
            SuccessModal(dialog, {
                title: 'Success',
                body: `An email has been successfully sent to the Carrier`,
            })
        ));
    }
    React.useEffect(() => {
        if (feedTransmissionLogs?.length > 0) {
            setParserArray(feedTransmissionLogs?.filter(s => s.LSI === "Parser"));
            setTrackerArray(feedTransmissionLogs?.filter(s => s.LSI === "Tracker"));
            setMapperArray(feedTransmissionLogs?.filter(s => s.LSI === "Mapper"));
            setSerializerArray(feedTransmissionLogs?.filter(s => s.LSI === "Serializer"));
            setEncrypterArray(feedTransmissionLogs?.filter(s => s.LSI === "Encrypter"));
            setTransmitterArray(feedTransmissionLogs?.filter(s => s.LSI === "Transmitter"));
        } else return;
    }, [feedTransmissionLogs])

    React.useEffect(() => {
        setRecentComponentsArray([parserArray[0], trackerArray[0], mapperArray[0], serializerArray[0], encrypterArray[0], transmitterArray[0]]);
    }, [parserArray, trackerArray, mapperArray, serializerArray, encrypterArray, transmitterArray])

    const getExactFileNameHandle = () => {
        openLoader()
        APIs.getExactFileName(feedTransmission?.SK)
            .then(r => {
                if (r?.success === "1") {
                    dialog.openDialog(
                        <div>
                            <h5 className="black1 fw-700">File name</h5>
                            {/* <Divider /> */}
                        </div>,
                        <>
                            <div >{r?.fileName}</div>
                            <div className="row" style={{ justifyContent: "flex-end" }}><CopyToClipboardButton textToCopy={r?.fileName} /></div>
                        </>

                    )
                }
                else {
                    FailureModal(dialog, {
                        title: 'Failure!',
                        body: 'Failed Retrieving File Name'
                    });
                }
            })
            .catch(err => console.log(err))
            .finally(() => closeLoader())
    }

    const sortActualPlans = (plans) => {
        plans.sort((a, b) => {
            if (a.isExported && !b.isExported) {
                return -1; // a should come before b
            } else if (!a.isExported && b.isExported) {
                return 1; // b should come before a
            } else {
                return 0; // the order of a and b doesn't matter
            }
        });

        return plans
    }

    const getActualPlansHandle = () => {
        openLoader()
        APIs.getActualPlans(feedTransmission?.SK)
            .then(r => {
                dialog.openDialog(
                    <div>
                        <h5 className="black1 fw-700 text-left mb-0">Actual Plans</h5>
                    </div>,
                    <>
                        <SingleFeedTransmissionsPlans plans={r?.length > 0 ? sortActualPlans(r) : []} />
                    </>

                )


            })
            .catch(err => FailureModal(dialog, {
                title: 'Failure!',
                body: err
            }))
            .finally(() => closeLoader())
    }

    return (
        <div className="row table-row">
            <Accordion expanded={accordionExpanded} onChange={() => { setExecutionID(feedTransmission?.SK) }}>
                <AccordionSummary>
                    {(userActions?.includes("43") ||
                        userActions?.includes("20")) ? (
                        <div className="row table-row w-100">
                            <div className="col-1 table-cell first d-flex">
                                <div className="row">
                                    {accordionExpanded ? <ExpandLessIcon onClick={() => { setAccordionExpanded(false); setExecutionID(feedTransmission?.SK) }} /> : <ExpandMoreIcon onClick={() => { setAccordionExpanded(true); setExecutionID(feedTransmission?.SK) }} />}
                                </div>
                                <a style={{ margin: "auto" }} href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`} onClick={(e) => { e.stopPropagation(); }}>
                                    {feedTransmission?.FeedID}
                                </a>
                            </div>
                            <div className="col-2 table-cell">
                                <a style={{ wordBreak: "break-word", margin: "auto" }} href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`} onClick={(e) => { e.stopPropagation(); }}>
                                    {feedTransmission?.FeedName}
                                </a>
                            </div>
                            <div className="col-9 pr-0 pl-0">
                                <div className="row">
                                    <div className="col-1 table-cell" style={{ userSelect: "text", cursor: "auto" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.CarrierName === "Third Party" ? `${feedTransmission?.CarrierName} (${feedTransmission?.ThirdPartyName})` : feedTransmission?.CarrierName}</div>
                                    {/* <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.FeedType}</div> */}
                                    <div className="col-1 table-cell">
                                        {feedTransmission?.ConnectivityRequests?.length > 1 ? (
                                            <a className="fs-13 link-style" style={{ color: "#2c87f0" }} onClick={(e) => {
                                                e.stopPropagation(); e.preventDefault(); dialog.openDialog( "Clients:",
                                                    <div id="last-sent-file-modal">
                                                        {/* <h5 className="black1 fw-700">Clients:</h5>
                                                        <Divider /> */}
                                                        <h6 className="text-nowrap mt-4 fw-500">
                                                            {feedTransmission?.ConnectivityRequests?.map((s, si) => (
                                                                <>
                                                                    <a key={`internal-client-names-${si}`} className="link-style" href={`/GroupRequests/ViewGroupRequest/${s?.GroupRequestID}`}>
                                                                        - {s?.PeoName ? s?.PeoName : s?.GroupName}
                                                                    </a>
                                                                    <br />
                                                                </>
                                                            ))}
                                                        </h6>
                                                    </div> , []
                                                )
                                            }}>
                                                {`${feedTransmission?.ConnectivityRequests?.length} Clients`}
                                            </a>
                                        ) : (
                                            <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${feedTransmission?.GroupRequestID}`}>
                                                {feedTransmission?.PeoName ? feedTransmission?.PeoName : feedTransmission?.ClientName}
                                            </a>
                                        )}
                                    </div>
                                    <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.FeedStatus}</div>
                                    <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.FeedPhase}</div>
                                    <div className="col-1 table-cell" style={{ userSelect: "text", cursor: "auto", wordBreak: "break-all" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.SK}</div>
                                    <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{Moment(feedTransmission?.RequestDate).format("MM/DD/YYYY HH:mm:ss")}</div>

                                    <CustomTooltip className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom} title={feedTransmission?.SentDate === "N/A" ? "File is not transmitted yet" : ""}>
                                        <span>
                                            {`${feedTransmission?.SentDate === "N/A" ? "N/A" : Moment(feedTransmission?.SentDate).format("MM/DD/YYYY HH:mm:ss")}`}
                                        </span>
                                    </CustomTooltip>


                                    <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} >{feedTransmission?.ProcessingStep}</div>
                                    <div className="col-2 table-cell" style={{ wordBreak: "break-all" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                        <Truncate lines={3} ellipsis={<span>... <a className="fs-13" style={{ color: "#2c87f0" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog("Processing Logs:",<div><p style={{ wordBreak: "break-word" }}>{feedTransmission?.ProcessingLog}</p></div>,[]) }}>Read More</a></span>}>
                                            {feedTransmission?.ProcessingLog}
                                        </Truncate>
                                    </div>
                                    {/* change color if pending here  */}
                                    <CustomTooltip className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom}
                                        title={(feedTransmission?.ExecutionStatus)?.includes("Progress") ? "The feed is still processing" :
                                            (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "Current processing step is completed" :
                                                (feedTransmission?.ExecutionStatus)?.includes("Parsing") ? "Failed to convert the data file to the required format" :
                                                    (feedTransmission?.ExecutionStatus)?.includes("Tracking") ? "Failed to process the data" :
                                                        (feedTransmission?.ExecutionStatus)?.includes("Mapping") ? "There is an error in the output file" :
                                                            (feedTransmission?.ExecutionStatus)?.includes("Serializing") ? "Unable to successfully generate a file" :
                                                                (feedTransmission?.ExecutionStatus)?.includes("Encrypting") ? "Failed to encrypt the output file" :
                                                                    (feedTransmission?.ExecutionStatus)?.includes("Failed Transmitting") ? "Failed to submit the file" :
                                                                        (feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "File needs to be manually submitted" :
                                                                            (feedTransmission?.ExecutionStatus)?.includes("Pending") ? "The feed didn't start processing yet" :
                                                                                (feedTransmission?.ExecutionStatus)?.includes("File Pulling Failed") ? "Failed to extract a data file" :
                                                                                    (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "There are no changes on the output file" :
                                                                                        ""}>
                                        <span>
                                            <div style={(feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                                                className={`
                                        ${(feedTransmission?.ExecutionStatus).includes("Fail") ? "red background status-div" :
                                                        (feedTransmission?.ExecutionStatus).includes("Pending Transmission") ? "yellow-secondary-color background status-div" :
                                                            (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "green background status-div" :
                                                                (feedTransmission?.ExecutionStatus)?.includes("In Progress") ? "purple1 background status-div" :
                                                                    (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "lightgreen background status-div" :
                                                                        ""}`}
                                            >
                                                {feedTransmission?.ExecutionStatus}
                                            </div>
                                        </span>
                                    </CustomTooltip>

                                    <div className="col-1 table-cell last" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                        <div>
                                            <CustomTooltip TransitionComponent={Zoom}
                                                title={feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" ? `No readable file available for this step` :
                                                    (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") ? `No readable file available for failed mapping transmission` :
                                                        (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "No readable file available for empty file" :
                                                            `Download Readable File`}>
                                                <a style={{ cursor: feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" || (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") || (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "default" : "", textDecoration: feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" ? "none" : "" }} onClick={() => {
                                                    if (feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" || (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") || (feedTransmission?.ExecutionStatus)?.includes("Finished"))
                                                        return;
                                                    else APIs.getDownloadReadableExcel(feedTransmission?.SK).then(r => window.open(r?.url));
                                                }}>
                                                    <svg width="18" height="18" viewBox="0 0 18 18" className = "mt-2">
                                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                                </svg>
                                                </a>
                                            </CustomTooltip>

                                            {/* File name show only when transmission is completed */}
                                            {feedTransmission?.ProcessingLog === "Transmission Completed" && (
                                                <CustomTooltip TransitionComponent={Zoom} title="File Name">
                                                    <a onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        getExactFileNameHandle();


                                                    }}>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" >
                                                    <use xlinkHref={`${fileNameSVG}#fileNameIcon`}></use>
                                                </svg>
                                                    </a>
                                                </CustomTooltip>
                                            )}

                                            {feedTransmission?.Schedules && (
                                                <CustomTooltip TransitionComponent={Zoom} title="Scheduler">
                                                    <a onClick={(e) => {
                                                        e.stopPropagation(); e.preventDefault(); dialog.openDialog(
                                                            <div>
                                                                <h5 className="black1 fw-700">Scheduler</h5>
                                                                {/* <Divider /> */}
                                                            </div>,
                                                            <>
                                                                <div>{feedTransmission?.Schedules?.split('\n').map(s => <p>{s}</p>)}</div>
                                                                <div className="row" style={{ justifyContent: "flex-end" }}><CopyToClipboardButton textToCopy={feedTransmission?.Schedules} /></div>
                                                            </>

                                                        )

                                                    }}>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" >
                                                    <use xlinkHref={`${schedulerIconSVG}#schedulerIcon`}></use>
                                                </svg>
                                                    </a>

                                                </CustomTooltip>
                                            )}
                                        </div>
                                        <div>
                                            
                                            {/* mapper or above && @mapper case (it should have a file) */}
                                            {((feedTransmission?.ProcessingStep === "Mapper" &&
                                                (feedTransmission?.ProcessingLog == "Invalid Output File" || feedTransmission?.ProcessingLog == "Mapping Completed")
                                            ) ||
                                                feedTransmission?.ProcessingStep === "Serializer" ||
                                                feedTransmission?.ProcessingStep === "Encrypter" ||
                                                feedTransmission?.ProcessingStep === "Transmitter") &&
                                                <CustomTooltip TransitionComponent={Zoom} title="View Plans">
                                                    <a onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                        getActualPlansHandle();
                                                    }}>
                                                        <svg width="18" height="18" viewBox="0 0 18 18" >
                                                    <use xlinkHref={`${viewPlansSVG}#viewPlansIcon`}></use>
                                                </svg>
                                                    </a>
                                                </CustomTooltip>

                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row table-row w-100">
                            <div className="col-1 table-cell first d-flex">
                                <div className="row">
                                    {accordionExpanded ? <ExpandLessIcon onClick={() => { setAccordionExpanded(false); setExecutionID(feedTransmission?.SK) }} /> : <ExpandMoreIcon onClick={() => { setAccordionExpanded(true); setExecutionID(feedTransmission?.SK) }} />}
                                </div>
                                <a style={{ wordBreak: "break-word", margin: "auto" }} href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`} onClick={(e) => { e.stopPropagation(); }}>
                                    {feedTransmission?.FeedName}
                                </a>
                            </div>
                            <div className="col-1 table-cell" style={{ userSelect: "text", cursor: "auto" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}> {feedTransmission?.CarrierName === "Third Party" ? `${feedTransmission?.CarrierName} (${feedTransmission?.ThirdPartyName})` : feedTransmission?.CarrierName}</div>
                            {/* <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.FeedType}</div> */}
                            <div className="col-1 table-cell">
                                {feedTransmission?.ConnectivityRequests?.length > 1 ? (
                                    <a className="fs-13 link-style" style={{ color: "#2c87f0" }} onClick={(e) => {
                                        e.stopPropagation(); e.preventDefault(); dialog.openDialog( "Clients:",
                                            <div id="last-sent-file-modal">
                                                {/* <h5 className="black1 fw-700">Clients:</h5>
                                                <Divider /> */}
                                                <h6 className="text-nowrap mt-4 fw-500">
                                                    {feedTransmission?.ConnectivityRequests?.map((s, si) => (
                                                        <>
                                                            <a key={`internal-client-names-${si}`} className="link-style" href={`/GroupRequests/ViewGroupRequest/${s?.GroupRequestID}`}>
                                                                - {s?.PeoName ? s?.PeoName : s?.GroupName}
                                                            </a>
                                                            <br />
                                                        </>
                                                    ))}
                                                </h6>
                                            </div>,[]
                                        )
                                    }}>
                                        {`${feedTransmission?.ConnectivityRequests?.length} Clients`}
                                    </a>
                                ) : (
                                    <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${feedTransmission?.GroupRequestID}`}>
                                        {feedTransmission?.PeoName ? feedTransmission?.PeoName : feedTransmission?.ClientName}
                                    </a>
                                )}
                            </div>
                            <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.FeedStatus}</div>
                            <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.FeedPhase}</div>
                            <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{Moment(feedTransmission?.RequestDate).format("MM/DD/YYYY HH:mm:ss")}</div>
                            <CustomTooltip className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom} title={feedTransmission?.SentDate === "N/A" ? "File is not transmitted yet" : ""}>
                                <span>
                                    {`${feedTransmission?.SentDate === "N/A" ? "N/A" : Moment(feedTransmission?.SentDate).format("MM/DD/YYYY HH:mm:ss")}`}
                                </span>
                            </CustomTooltip>
                            <div className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} >{feedTransmission?.ProcessingStep}</div>
                            <div className="col-2 table-cell" style={{ wordBreak: "break-all" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                <Truncate lines={3} ellipsis={<span>... <a className="fs-13" style={{ color: "#2c87f0" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog("Processing Logs:",<div><p style={{ wordBreak: "break-word" }}>{feedTransmission?.ProcessingLog}</p></div>,[]) }}>Read More</a></span>}>
                                    {feedTransmission?.ProcessingLog}
                                </Truncate>
                            </div>
                            {/* change color if pending here  */}
                            <CustomTooltip className="col-1 table-cell" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom}
                                title={(feedTransmission?.ExecutionStatus)?.includes("Progress") ? "The feed is still processing" :
                                    (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "Current processing step is completed" :
                                        (feedTransmission?.ExecutionStatus)?.includes("Parsing") ? "Failed to convert the data file to the required format" :
                                            (feedTransmission?.ExecutionStatus)?.includes("Tracking") ? "Failed to process the data" :
                                                (feedTransmission?.ExecutionStatus)?.includes("Mapping") ? "There is an error in the output file" :
                                                    (feedTransmission?.ExecutionStatus)?.includes("Serializing") ? "Unable to successfully generate a file" :
                                                        (feedTransmission?.ExecutionStatus)?.includes("Encrypting") ? "Failed to encrypt the output file" :
                                                            (feedTransmission?.ExecutionStatus)?.includes("Failed Transmitting") ? "Failed to submit the file" :
                                                                (feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "File needs to be manually submitted" :
                                                                    (feedTransmission?.ExecutionStatus)?.includes("Pending") ? "The feed didn't start processing yet" :
                                                                        (feedTransmission?.ExecutionStatus)?.includes("Pulling") ? "Failed to extract a data file" :
                                                                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "There are no changes on the output file" :
                                                                                ""}>
                                <span>
                                    <div style={(feedTransmission?.ExecutionStatus).includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                                        className={`
                                        ${(feedTransmission?.ExecutionStatus).includes("Fail") ? "red background status-div" :
                                                (feedTransmission?.ExecutionStatus).includes("Pending Transmission") ? "yellow-secondary-color background status-div" :
                                                    (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "green background status-div" :
                                                        (feedTransmission?.ExecutionStatus)?.includes("In Progress") ? "purple1 background status-div" :
                                                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "lightgreen background status-div" :
                                                                ""}`}
                                    >
                                        {feedTransmission?.ExecutionStatus}
                                    </div>
                                </span>
                            </CustomTooltip>

                            <div className="col-1 table-cell last" onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                                <div>
                                    <CustomTooltip TransitionComponent={Zoom} title={feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" ? `No readable file available for this step` :
                                        (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") ? `No readable file available for failed mapping transmission` :
                                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "No readable file available for empty file" :
                                                `Download Readable File`}>
                                        <a style={{ cursor: feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" || (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") || (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "default" : "", textDecoration: feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" ? "none" : "" }} onClick={() => {
                                            if (feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" || (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") || (feedTransmission?.ExecutionStatus)?.includes("Finished"))
                                                return;
                                            else APIs.getDownloadReadableExcel(feedTransmission?.SK).then(r => window.open(r?.url));
                                        }}>
                                            <svg width="18" height="18" viewBox="0 0 18 18" className = "mt-2" >
                                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                                </svg>
                                        </a>
                                    </CustomTooltip>

                                    {feedTransmission?.ProcessingLog === "Transmission Completed" && (
                                        <CustomTooltip TransitionComponent={Zoom} title="File name">
                                            <a onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                getExactFileNameHandle();

                                            }}>
                                                <svg width="18" height="18" viewBox="0 0 18 18" >
                                                    <use xlinkHref={`${fileNameSVG}#fileNameIcon`}></use>
                                                </svg>
                                            </a>
                                        </CustomTooltip>
                                    )}

                                    {feedTransmission?.Schedules && (
                                        <CustomTooltip TransitionComponent={Zoom} title="Scheduler">
                                            <a onClick={(e) => {
                                                e.stopPropagation(); e.preventDefault(); dialog.openDialog(
                                                    <div>
                                                        <h5 className="black1 fw-700">Scheduler</h5>
                                                        {/* <Divider /> */}
                                                    </div>,
                                                    <>
                                                        <div>{feedTransmission?.Schedules?.split('\n').map(s => <p>{s}</p>)}</div>
                                                        <div className="row" style={{ justifyContent: "flex-end" }}><CopyToClipboardButton textToCopy={feedTransmission?.Schedules} /></div>
                                                    </>

                                                )

                                            }}>
                                                <svg width="18" height="18" viewBox="0 0 18 18" >
                                                    <use xlinkHref={`${schedulerIconSVG}#schedulerIcon`}></use>
                                                </svg>
                                            </a>

                                        </CustomTooltip>
                                    )}
                                </div>
                                <div>
                                    

                                    {/* mapper or above && @mapper case (it should have a file) */}
                                    {((feedTransmission?.ProcessingStep === "Mapper" &&
                                        (feedTransmission?.ProcessingLog == "Invalid Output File" || feedTransmission?.ProcessingLog == "Mapping Completed")
                                    ) ||
                                        feedTransmission?.ProcessingStep === "Serializer" ||
                                        feedTransmission?.ProcessingStep === "Encrypter" ||
                                        feedTransmission?.ProcessingStep === "Transmitter") &&
                                        <CustomTooltip TransitionComponent={Zoom} title="View Plans">
                                            <a onClick={(e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                getActualPlansHandle();
                                            }}>
                                                <svg width="14" height="14" viewBox="0 0 14 14" >
                                                    <use xlinkHref={`${viewPlansSVG}#viewPlansIcon`}></use>
                                                </svg>
                                            </a>
                                        </CustomTooltip>

                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </AccordionSummary>

                {(userActions?.includes("41") ||
                    userActions?.includes("20")) ? (
                    <div className="row table-row" style={{ width: "95%", margin: "0 auto" }}>
                        <AccordionDetails className="details-full-div">
                            <div className="row table-subheader">
                                <div className="col-2 table-cell first-cell">Execution Step</div>
                                <div className="col-2 table-cell">Execution Status</div>
                                <div className="col-2 table-cell">Processing Log</div>
                                <div className="col-1 table-cell">Input File</div>
                                <div className="col-1 table-cell">Output File</div>
                                <div className="col-1 table-cell">Processing Start Time</div>
                                <div className="col-1 table-cell">Processing End Time</div>
                                <div className="col-2 table-cell">Actions</div>
                            </div>
                            {feedTransmissionLogs?.length > 0 && feedTransmissionLogs?.map((l, li) => (
                                feedTransmission?.SK === l?.PK && (
                                    <div className="row w-100 table-row" key={`feed-transmission-logs-${li}`}>
                                        <div className="col-2 table-cell fw-700 fs-13">{l?.LSI}</div>
                                        <div className="col-2 table-cell">
                                            <div className={`${(l?.ExecutionStatus).includes("Fail") ? "red background status-div" : ""}`}>
                                                {l?.ExecutionStatus}
                                            </div>

                                        </div>
                                        <div className="col-2 table-cell">
                                            {l?.LSI === "Mapper" && l?.validationExcelFileKey !== "N/A" ? (
                                                <>
                                                    <CustomTooltip TransitionComponent={Zoom} title="Download Output File">
                                                        {/* <a style={{ cursor: "pointer" }} onClick={() => APIs.downloadOutputFile({
                                                            processId: feedTransmission?.SK,
                                                            sk: l?.SK
                                                        }).then(blob => { saveAs(blob.data, blob.fileName) })}> */}
                                                        <a style={{ cursor: "pointer" }} onClick={() => APIs.downloadInvalidFile(feedTransmission?.SK).then(blob => { saveAs(blob.data, blob.fileName) })}>
                                                            <span className="mr-2"><GetAppIcon fontSize="small" /></span>
                                                            <span>{l?.ProcessingLog}</span>
                                                        </a>
                                                    </CustomTooltip>
                                                    <CustomTooltip TransitionComponent={Zoom} title="Download Report">
                                                        <a href={l?.validationExcelFileKey}>
                                                            <span className="mr-2"><GetAppIcon fontSize="small" /></span>
                                                            <span>{l?.ProcessingLog === "Invalid Output File" ? "Invalid Output Report" : l?.ProcessingLog}</span>
                                                        </a>
                                                    </CustomTooltip>
                                                </>
                                            ) : (
                                                <Truncate lines={3} ellipsis={<span>... <a className="fs-13" style={{ color: "#2c87f0" }} onClick={() => dialog.openDialog("Processing Logs:",<div><p style={{ wordBreak: "break-word" }}>{l?.ProcessingLog}</p></div>,[])}>Read More</a></span>}>
                                                    {l?.ProcessingLog}
                                                </Truncate>
                                            )}
                                        </div>
                                        <div className="col-1 table-cell">
                                            {l?.InputFile === "N/A" ? "N/A" : (
                                                <CustomTooltip TransitionComponent={Zoom} title="Download link expires in 10 minutes">
                                                    <a href={l?.InputFile}>Download</a>
                                                </CustomTooltip>
                                            )}
                                        </div>
                                        <div className="col-1 table-cell">
                                            {l?.OutputFile === "N/A" ? "N/A" : (
                                                <CustomTooltip TransitionComponent={Zoom} title="Download link expires in 10 minutes">
                                                    <a href={l?.OutputFile}>Download</a>
                                                </CustomTooltip>
                                            )}
                                        </div>
                                        <div className="col-1 table-cell">{Moment(toESTTime(l?.ProcessingStartTime)).format("MM/DD/YYYY HH:mm:ss")}</div>
                                        <div className="col-1 table-cell">{Moment(toESTTime(l?.ProcessingEndTime)).format("MM/DD/YYYY HH:mm:ss")}</div>
                                        <div className="col-2 table-cell alignvh-center" style={{ display: "block" }}>
                                            {feedTransmissionLogs.filter(s1 => recentComponentsArray.some(s2 => s2 !== undefined && s1.SK === s2.SK)).map((t, ti) => (
                                                t === l && (
                                                    <div key={`retry-process-to-end-${ti}`}>
                                                        <CustomTooltip TransitionComponent={Zoom} title="Retry the current component">
                                                            <Button className="transmission-actions-btn mt-1" onClick={() => {
                                                                openModal(0, () => retryComponent(li), {
                                                                    title: "Please Confirm",
                                                                    question: `Are you sure you want to retry the ${l?.LSI} for feed ${feedTransmission?.FeedID}?`,
                                                                })
                                                            }}>
                                                                <svg width="12" height="21" viewBox="0 0 23 21" className="mr-1" >
                                                                    <use xlinkHref={`${retryIcon}#rotate-left`}></use>
                                                                </svg>
                                                                <span>Retry</span>
                                                            </Button>
                                                        </CustomTooltip>

                                                        {(((feedTransmission?.ExecutionStatus).includes("Pending Transmission") && ti !== 0) || !(feedTransmission?.ExecutionStatus).includes("Pending Transmission")) && <CustomTooltip TransitionComponent={Zoom} title="Start processing from the current step until the configured component in the feed configurations">
                                                            <Button className="transmission-actions-btn" onClick={() => {
                                                                openModal(0, () => processToEndComponent(li), {
                                                                    title: "Please Confirm",
                                                                    question: `Are you sure you want to rerun from the ${l?.LSI} for feed ${feedTransmission?.FeedID}?`,
                                                                })
                                                            }}>
                                                                <svg width="15" height="19" viewBox="0 0 31 19" className="mr-1" >
                                                                    <use xlinkHref={`${processEndIcon}#arrow-right-long-to-line`}></use>
                                                                </svg>
                                                                <span>Process To End</span>
                                                            </Button>
                                                        </CustomTooltip>}
                                                        {(feedTransmission?.ExecutionStatus).includes("Pending Transmission") && ti === 0 &&
                                                            <>
                                                                <br /> <CustomTooltip TransitionComponent={Zoom} title="Proceed transmitting confirmation email to the Carrier after sending the file manually">
                                                                    <Button className="transmission-actions-btn" onClick={() => {
                                                                        openModal(0, () => confirmProcess(li), {
                                                                            title: "Please Confirm",
                                                                            question: `Are you sure you have sent the file manually on the Carrier's portal?`,
                                                                        })
                                                                    }}>
                                                                        <svg width="15" height="19" viewBox="0 0 31 19" className="mr-1" >
                                                                    <use xlinkHref={`${processEndIcon}#arrow-right-long-to-line`}></use>
                                                                </svg>
                                                                        <span>Mark Transmitted</span>
                                                                    </Button>
                                                                </CustomTooltip>
                                                            </>}
                                                    </div>
                                                )
                                            ))}

                                        </div>
                                    </div>
                                ))
                            )}
                            {/* </div> */}
                        </AccordionDetails>
                    </div>
                ) : (
                    <div className="row table-row" style={{ width: "95%", margin: "0 auto" }}>
                        <AccordionDetails className="details-full-div">
                            <div className="row table-subheader">
                                <div className="col-2 table-cell first-cell">Execution Step</div>
                                <div className="col-2 table-cell">Execution Status</div>
                                <div className="col-2 table-cell">Processing Log</div>
                                <div className="col-1 table-cell">Input File</div>
                                <div className="col-1 table-cell">Output File</div>
                                <div className="col-2 table-cell">Processing Start Time</div>
                                <div className="col-2 table-cell">Processing End Time</div>
                            </div>
                            {feedTransmissionLogs?.length > 0 && feedTransmissionLogs?.map((l, li) => (
                                feedTransmission?.SK === l?.PK && (
                                    <div className="row w-100 table-row" key={`feed-transmission-logs-${li}`}>
                                        <div className="col-2 table-cell fw-700 fs-13">{l?.LSI}</div>
                                        <div className="col-2 table-cell">
                                            <div className={`${(l?.ExecutionStatus).includes("Fail") ? "red background status-div" : ""}`}>
                                                {l?.ExecutionStatus}
                                            </div>

                                        </div>
                                        <div className="col-2 table-cell">
                                            {l?.LSI === "Mapper" && l?.validationExcelFileKey !== "N/A" ? (
                                                <>
                                                    <CustomTooltip TransitionComponent={Zoom} title="Download Output File">
                                                        {/* <a style={{ cursor: "pointer" }} onClick={() => APIs.downloadOutputFile({
                                                            processId: feedTransmission?.SK,
                                                            sk: l?.SK
                                                        }).then(blob => { saveAs(blob.data, blob.fileName) })}> */}
                                                        <a style={{ cursor: "pointer" }} onClick={() => APIs.downloadInvalidFile(feedTransmission?.SK).then(blob => { saveAs(blob.data, blob.fileName) })}>
                                                            <span className="mr-2"><GetAppIcon fontSize="small" /></span>
                                                            <span>{l?.ProcessingLog}</span>
                                                        </a>
                                                    </CustomTooltip>
                                                    <CustomTooltip TransitionComponent={Zoom} title="Download Report">
                                                        <a href={l?.validationExcelFileKey}>
                                                            <span className="mr-2"><GetAppIcon fontSize="small" /></span>
                                                            <span>{l?.ProcessingLog === "Invalid Output File" ? "Invalid Output Report" : l?.ProcessingLog}</span>
                                                        </a>
                                                    </CustomTooltip>
                                                </>
                                            ) : (
                                                <Truncate lines={3} ellipsis={<span>... <a className="fs-13" style={{ color: "#2c87f0" }} onClick={() => dialog.openDialog("Processing Logs:",<div><p style={{ wordBreak: "break-word" }}>{l?.ProcessingLog}</p></div>,[])}>Read More</a></span>}>
                                                    {l?.ProcessingLog}
                                                </Truncate>
                                            )}
                                        </div>
                                        <div className="col-1 table-cell">
                                            {l?.InputFile === "N/A" ? "N/A" : (
                                                <CustomTooltip TransitionComponent={Zoom} title="Download link expires in 10 minutes">
                                                    <a href={l?.InputFile}>Download</a>
                                                </CustomTooltip>
                                            )}
                                        </div>
                                        <div className="col-1 table-cell">
                                            {l?.OutputFile === "N/A" ? "N/A" : (
                                                <CustomTooltip TransitionComponent={Zoom} title="Download link expires in 10 minutes">
                                                    <a href={l?.OutputFile}>Download</a>
                                                </CustomTooltip>
                                            )}
                                        </div>
                                        <div className="col-2 table-cell">{Moment(toESTTime(l?.ProcessingStartTime)).format("MM/DD/YYYY HH:mm:ss")}</div>
                                        <div className="col-2 table-cell">{Moment(toESTTime(l?.ProcessingEndTime)).format("MM/DD/YYYY HH:mm:ss")}</div>
                                    </div>
                                ))
                            )}
                            {/* </div> */}
                        </AccordionDetails>
                    </div>
                )

                }
            </Accordion>
        </div >
    )
}

export default SingleFeedTransmissions;
