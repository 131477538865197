import React from 'react';
import { Select, FormControl, Divider, Paper, MenuItem } from "@material-ui/core";
import PreMappingStepperAnalyst from './PreMappingStepperAnalyst';
import { useAuthentication } from "../../../../Data/Authentication";
import { useParams } from "react-router-dom";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";

function PreMappingFormAnalyst() {

    const { connectivityRequestIDURL } = useParams();
    const { authUser } = useAuthentication();
    const { openLoader, closeLoader } = useLoader();
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [usersList, setUsersList] = React.useState([]);
    const [primaryAnalyst, setPrimaryAnalyst] = React.useState("");
    const [secondaryAnalyst, setSecondaryAnalyst] = React.useState("");
    const [primaryAnalystValidation, setPrimaryAnalystValidation] = React.useState("");
    const [preMapSubmitted, setPreMapSubmitted] = React.useState(false);

    React.useEffect(() => {
        if (!primaryAnalyst) setPrimaryAnalystValidation("Please select a primary Analyst for this Pre-Mapping form."); else setPrimaryAnalystValidation("");
    }, [primaryAnalyst, primaryAnalystValidation])

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        if (connectivityReq) closeLoader();
        else openLoader();
    }, [connectivityReq])

    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])

    const handlePrimaryAnalystChange = (e, newValue) => {
        setPrimaryAnalyst(e.target.value);
    }

    const handleSecondaryAnalystChange = (e, newValue) => {
        setSecondaryAnalyst(e.target.value);
    }

    return (
        <div id="pre-mapping-form">
            <BreadcrumbSteps
                breadcrumbTitle={`Connectivity Request #${connectivityRequestIDURL}`}
                isCurrent={false}
                breadcrumbTitleCurrent="Create Pre-Mapping Form"
                breadcrumbLink={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`}
            />
            <Paper elevation={0} className="p-5 mb-3 mt-3">
                <div className="row w-100 alignvh-center">
                    <h5 className="black1 fw-700">Pre-Mapping Form</h5>
                </div>
                <Divider className="mt-3 mb-5" />
                <div className="row">
                    <div className="col-xl-2 alignvh-center">
                        <h6 className="form-title">Client Name</h6>
                    </div>
                    <div className="col-xl-10">
                        <div className="textfield-div">
                            {connectivityReq?.Peo?.PeoName ? connectivityReq?.Peo?.PeoName : connectivityReq?.GroupName}
                        </div>
                    </div>

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Carrier Name</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <div className="textfield-div">
                            {connectivityReq?.CarrierName}
                        </div>
                    </div>

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Primary Analyst</h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <FormControl label="Select primary analyst" focused={false} variant="outlined" className="w-100">
                            <Select
                                className="text-dropdown"
                                value={primaryAnalyst ?? ""}
                                onChange={handlePrimaryAnalystChange}
                            >
                                <MenuItem value="">None</MenuItem>
                                {usersList?.map((c, ci) => (
                                    <MenuItem key={`users-list-${ci}`} value={c}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-xl-2 alignvh-center" hidden={!preMapSubmitted}></div>
                    <div className="col-xl-10 mt-2 text-danger" hidden={!preMapSubmitted}>{primaryAnalystValidation}</div>

                    {primaryAnalyst && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Primary Analyst Email</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                <div className="textfield-div">
                                    {primaryAnalyst?.Email ?? ""}
                                </div>
                            </div>
                        </>
                    )}

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Secondary Analyst</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <FormControl label="Select secondary analyst" focused={false} variant="outlined" className="w-100">
                            <Select
                                className="text-dropdown"
                                value={secondaryAnalyst ?? ""}
                                onChange={handleSecondaryAnalystChange}
                            >
                                <MenuItem value="">None</MenuItem>
                                {usersList?.map((c, ci) => (
                                    <MenuItem key={`users-list-${ci}`} value={c}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    {secondaryAnalyst && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Analyst Email</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                <div className="textfield-div">
                                    {secondaryAnalyst?.Email ?? ""}
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </Paper>
            <PreMappingStepperAnalyst onPreMapSubmitted={s => setPreMapSubmitted(s)} connectivityReq={connectivityReq} primaryAnalyst={primaryAnalyst} secondaryAnalyst={secondaryAnalyst} />
        </div>
    );
}

export default PreMappingFormAnalyst;