import React from "react";
import { Button } from "@material-ui/core";


export function ConfirmModal(dialog, onClickConfirm, content, labelextraCssClasses = "", btnNames = { yes: 'Yes', no: 'No' }) {
    dialog.openDialog(
        <div className="confirm modal-head">
            {content.title}
        </div>,
        <div className="confirm modal-body-content">

            <label dangerouslySetInnerHTML={{ __html: content?.question }} className={`black2-color mt-4 fs-12 w-100 ${labelextraCssClasses}`} />
            {content.list && content.list}
        </div>,
        [
            { body: <Button className="blue-btn">{btnNames?.yes}</Button>, onClick: (e) => { onClickConfirm(e); dialog.closeDialog() } },
            { body: <Button className="blue-outlined-btn">{btnNames?.no}</Button>, onClick: () => { dialog.closeDialog() } }
        ]
    )
}

let content = {
    title: 'title',
    question: 'are you sure want to cancel connectivity?',
    list: `<ul>
            {content.list && content.list.length > 0 && content.list.map(el => 
                <li key={el.carrierId}>{el.carrierName}</li>
            )}
           </ul>`
}
