import React from "react";
import Truncate from 'react-truncate';
import { Link, FormControl, Select, MenuItem } from "@material-ui/core";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Button from "@material-ui/core/Button";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { useCommentsModalDialog } from "../../../Layout/CommentsModalDialog";
import { Comments } from "../../Comments";
import ContentBody from "../../ContentBody";
import { useAuthentication } from "../../../../Data/Authentication";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";

function CarrierPlansAnalystView({ onEdit, currentTimestamp, carrierPlansSection, setIsNextActive, setIsSaveActive, ...props }) {

    const { authUser } = useAuthentication();
    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const commentsDialog = useCommentsModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [customerPlans, setCustomerPlans] = React.useState([]);
    const [carrierPlans, setCarrierPlans] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [index, setIndex] = React.useState(-1);
    const [planTypes, setPlanTypes] = React.useState([]);
    const [carrierPlansSectionOriginalLength, setCarrierPlansSectionOriginalLength] = React.useState();
    const [plansArray, setPlansArray] = React.useState([]);

    const _colorOptions = {
        primaryAnalystColor: "var(--yellow-secondary-color)",
        primaryAdminColor: "var(--purple)"
    };

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupPlans(connectivityReq?.ConnectivityRequestID).then((r) => (
            setCustomerPlans(r?.data),
            closeLoader()
        ));
    }, [connectivityReq])

    React.useEffect(() => {
        setCarrierPlans(carrierPlansSection?.Model.map(s => ({
            carrierPlanName: s.CarrierPlanName,
            carrierPlanType: s.PlanType
        })))
    }, [carrierPlansSection])

    React.useEffect(() => {
        openLoader();
        APIs.getPlanTypes().then((r) => setPlanTypes(r?.data?.sort((a, b) => a.PlanTypeName.toLowerCase() > b.PlanTypeName.toLowerCase() ? 1 : -1)));
    }, [])

    const handleFunction = (value) => {
        setIndex(value);
        const commentsBody = {
            Type: carrierPlansSection.Type,
            GUID: carrierPlansSection.Model[value].GUID
        }

        APIs.getPreMappingComments(commentsBody).then((r) => {
            setComments(r?.data); forceUpdate();
        })
    }

    React.useEffect(() => {
        if (index === -1) return;
        handleFunction(index); forceUpdate();
    }, [index])

    React.useEffect(() => {
        if (comments === undefined || index === -1) return;
        carrierPlansSection.Model[index].Comment = comments[comments?.length - 1] ?? [];
    }, [comments?.length])

    React.useEffect(() => {
        if (onEdit === true)
            carrierPlansSection.Model.map(s => s.Action = 2)
        else return;
    }, [onEdit === true])

    React.useEffect(() => {
        let lastElement = plansArray[plansArray.length - 1];
        if (carrierPlansSection && plansArray.length > 0) carrierPlansSection.Model = [...carrierPlansSection.Model, lastElement];
    }, [plansArray]);

    React.useEffect(() => {
        setCarrierPlansSectionOriginalLength(carrierPlansSection.Model?.length);
    }, [])

    React.useEffect(() => {
        const carrierPlanNamesCondition = plansArray.map(s => s.CarrierPlanName).filter(f => f === "");
        const planTypesCondition = plansArray.map(s => s.PlanType).filter(f => f === "");
        const commentsCondition = plansArray.map(s => s.Comment.Text).filter(f => f === "");
        if (carrierPlanNamesCondition.length <= 0 && planTypesCondition.length <= 0 && commentsCondition.length <= 0) setIsNextActive(true); else setIsNextActive(false);
        if (plansArray.length === 0 ? setIsSaveActive(false) : (carrierPlanNamesCondition.length <= 0 && planTypesCondition.length <= 0 && commentsCondition.length <= 0) ? setIsSaveActive(true) : setIsSaveActive(false));
    }, [JSON.stringify(plansArray)])

    const handleDeleteRecord = (index, value) => {
        if (carrierPlansSection.Model[index].Action === 2)
            carrierPlansSection.Model.splice(index, 1);
        else {
            carrierPlansSection.Model.splice(index, 1);
            plansArray.splice((plansArray.indexOf(value)), 1);
        }
        forceUpdate();
    }

    React.useEffect(() => {
        if (!onEdit) setPlansArray([]);
    }, [onEdit])

    return (
        <div id="carrier-plans-section">
            <div className="row">
                <div className="col-xl-6">
                    <div className="table-header row mr-1">
                        <div className="col-xl-9 table-cell first-cell">Carrier Plan Names</div>
                        <div className="col-xl-3 table-cell">Plan Type</div>
                    </div>
                    <div className="table-row mr-1">
                        {carrierPlansSection.Model.map((c, ci) => (
                            <div key={`carrier-plans-view-${ci}`} className="row">
                                <div className="col-xl-9 table-cell first-cell">{c.CarrierPlanName}</div>
                                <div className="col-xl-3 table-cell">{c.PlanType}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="col-xl-6">
                    <div className="table-header row ml-1">
                        <div className="col-xl-9 table-cell first-cell">Group Plan Names</div>
                        <div className="col-xl-3 table-cell">Plan Type</div>
                    </div>
                    <div className="table-row ml-1">
                        {customerPlans.map((c, ci) => (
                            <div key={`customer-plans-list-${ci}`} className="row">
                                <div className="col-xl-9 table-cell first-cell">{c.BenefitName}</div>
                                <div className="col-xl-3 table-cell">{c.BenefitType}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row table-header mt-3">
                <div className="col-xl-6 table-cell first-cell">Mapping Plans</div>
                <div className="col-xl-6 table-cell">Comments</div>
            </div>
            <div className="table-subheader row">
                <div className="col-xl-3 table-cell first-cell">Carrier Plans</div>
                <div className="col-xl-3 table-cell">Group Plans</div>
                <div className="col-xl-1 table-cell">User</div>
                {onEdit ? (
                    <>
                        <div className="col-xl-3 table-cell">Latest Comment</div>
                        <div className="col-xl-2 table-cell">Actions</div>
                    </>
                ) : (
                    <>
                        <div className="col-xl-4 table-cell">Latest Comment</div>
                        <div className="col-xl-1 table-cell">Actions</div>
                    </>
                )}

            </div>
            {carrierPlansSection?.Model?.map((p, pi) => (
                <div key={`plans-section-view-${pi}`} className="table-row row">
                    <div className="col-xl-3 table-cell first-cell">
                        {p.CarrierPlanName}
                    </div>
                    <div className="col-xl-3 table-cell">
                        {p.GroupPlans && p.GroupPlans.length > 0 ? (
                            p.GroupPlans.map((s, si) => (
                                <div key={`matched-plans-${si}`}>{s.GroupPlanName}</div>
                            ))
                        ) : (
                            <div className="no-data-alert text-left">Not matched yet by the Admin</div>
                        )}
                    </div>
                    <div className="col-xl-1 table-cell">
                        <div className="userInfo">
                            <CustomTooltip TransitionComponent={Zoom} title={<div><span>{p.Comment?.Name}</span><br /><span>{p.Comment?.Email}</span></div>}>
                                <span className="user" style={{ backgroundColor: _colorOptions.primaryAnalystColor }}>{p.Comment?.Name?.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                            </CustomTooltip>
                        </div>
                    </div>
                    {onEdit ? (
                        <>
                            <div className="col-xl-3 table-cell">
                                <div className="mt-1">
                                    <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog(<span style={{ whiteSpace: "break-spaces" }}>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                                        <span>{p.Comment?.Text}</span>
                                    </Truncate>
                                </div>
                            </div>
                            <div className="col-xl-2 table-cell">
                                <div className="comments-section-single-column">
                                    <Button className="icon-btn" onClick={e => {
                                        handleFunction(pi); forceUpdate(); Comments(commentsDialog, {
                                            title: p.CarrierPlanName,
                                            body: <ContentBody section={carrierPlansSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                        });
                                    }}>
                                        <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                                    </Button>
                                    <CustomTooltip TransitionComponent={Zoom} title={(p.GroupPlans && p.GroupPlans.length > 0) ? "This plan is matched by the Admin. Cannot be deleted." : (carrierPlansSection.Model.length === 1 || (carrierPlansSection.Model.length === 1 && plansArray.length === 1)) ? "There should be at least one matched plan" : ""}>
                                        <span>
                                            <Button
                                                className="icon-btn"
                                                disabled={(p.GroupPlans && p.GroupPlans.length > 0) || (carrierPlansSection.Model.length === 1 || (carrierPlansSection.Model.length === 1 && plansArray.length === 1))}
                                                onClick={e => {
                                                    openModal(0, () => {
                                                        handleDeleteRecord(pi, p)
                                                    }, {
                                                        title: 'Please Confirm',
                                                        question: `Are you sure you want to delete this record?`,
                                                    });
                                                }}
                                            >
                                                {/* <DeleteForeverIcon style={{ width: "25px", height: "25px" }} color="action" /> */}
                                                <svg  width="21" height="24" viewBox="0 0 21 24" >
                                                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                </svg>
                                            </Button>
                                        </span>
                                    </CustomTooltip>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-xl-4 table-cell">
                                <div className="mt-1">
                                    <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog(<span style={{ whiteSpace: "break-spaces" }}>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                                        <span>{p.Comment?.Text}</span>
                                    </Truncate>
                                </div>
                            </div>
                            <div className="col-xl-1 table-cell">
                                <div className="comments-section-single-column">
                                    <Button onClick={e => {
                                        handleFunction(pi); forceUpdate(); Comments(commentsDialog, {
                                            title: p.CarrierPlanName,
                                            body: <ContentBody section={carrierPlansSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                        });
                                    }}>
                                        <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            ))}


            {onEdit && (
                <>
                    <div className="row mt-5 mb-5 w-100 alignvh-center">
                        <Button variant="contained" className="blue-btn" onClick={() => {
                            setPlansArray([...plansArray, {
                                GUID: uuidv4(),
                                CarrierPlanName: "",
                                PlanType: "",
                                PlanTypeID: "",
                                Action: 1,
                                Comment: {
                                    Timestamp: currentTimestamp,
                                    Text: "",
                                    UserID: authUser?.attributes["sub"],
                                    Name: `${authUser?.attributes["custom:FirstName"]} ${authUser?.attributes["custom:LastName"]}`,
                                    Email: `${authUser?.attributes["email"]}`
                                }
                            }]);
                        }}>
                            <label style={{ cursor: "pointer" }} className="mb-0">
                            <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                                            </svg>
                                 Add Plans</label>
                        </Button>
                    </div>
                    {plansArray.length <= 0 && (
                        <div className="no-data-alert">
                            <h6>Please start adding carrier plans</h6>
                        </div>
                    )}
                    {plansArray.map((p, pi) => (
                        <div className="row mb-2" key={`Plan-${p.GUID}`}>
                            <div className="col-xl-3 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Carrier Plan Name</h6>
                                <span className="text-danger mb-2 ml-2 mr-2">*</span>
                                <input type="text" className="text-input w-100" value={p.CarrierPlanName ?? ""} onChange={e => { p.CarrierPlanName = e.target.value; forceUpdate(); }} />
                            </div>
                            <div className="col-xl-3 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Plan Type</h6>
                                <span className="text-danger mb-2 ml-2 mr-2">*</span>
                                <FormControl label="Select Plan Type" focused={false} variant="outlined" className="w-100">
                                    <Select
                                        className="text-dropdown"
                                        value={p.PlanType ?? ""}
                                        onChange={e => { p.PlanType = e.target.value; p.PlanTypeID = planTypes.filter(s => (s.PlanTypeName === e.target.value))[0].PlanTypeID; forceUpdate(); }}
                                    >
                                        {planTypes.map((p, pi) => (
                                            <MenuItem key={`plan-types-${pi}`} value={p.PlanTypeName}>{p.PlanTypeName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-xl-4 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Analyst Comment</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                                <textarea value={p.Comment?.Text ?? ""} onChange={e => { p.Comment.Text = e.target.value; forceUpdate(); }} style={{ minWidth: "unset", width: "100%" }} rows={3} />
                            </div>
                            <div className="col-xl-1 alignvh-center">
                                <CustomTooltip TransitionComponent={Zoom} title={(carrierPlansSection.Model.length === 1 && plansArray.length === 1) ? "There should be at least one matched plan" : ""}>
                                    <span>
                                        <Button disabled={carrierPlansSection.Model.length === 1 && plansArray.length === 1} className="icon-btn" onClick={e => {
                                            openModal(0, () => {
                                                plansArray.splice(pi, 1);
                                                carrierPlansSection.Model.splice((carrierPlansSection.Model.indexOf(p)), 1);
                                                forceUpdate();
                                            }, {
                                                title: 'Please Confirm',
                                                question: `Are you sure you want to delete this record?`,
                                            });
                                        }}
                                        >
                                            {/* <DeleteForeverIcon style={{ width: "30px", height: "30px" }} color="action" /> */}
                                            <svg  width="21" height="24" viewBox="0 0 21 24" >
                                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                            </svg>
                                        </Button>
                                    </span>
                                </CustomTooltip>
                            </div>
                        </div>
                    ))
                    }
                </>
            )}
        </div>
    )
}

export default CarrierPlansAnalystView;