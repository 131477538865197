import React from "react";
import { Divider, Paper, IconButton, Button } from "@material-ui/core";
import FileRow from "./FileRow";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReplyIcon from '@material-ui/icons/Reply';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { DropzoneArea } from 'material-ui-dropzone';
import { FailureModal } from "../Modals/FailureModal";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import TabsTitlesWithMoreDD from "../Layout/TabsTitlesWithMoreDD";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className="p-3">{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function FilesAttachments({ selectedCarriers, isPEOClicked, selectedOtherCarriers, parentForceUpdate, ...props }) {

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [expanded, setExpanded] = React.useState("");
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [uploadedOtherCarriersFiles, setUploadedOtherCarriersFiles] = React.useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [tabDefaultValue, setTabDefaultValue] = React.useState("[Other Carrier Name]")
    const [tempGUID, setTempGUID] = React.useState("");


    React.useEffect(() => {
        if (!selectedCarriers) return;
        const selectedCarrier = selectedCarriers[tabValue];
        if (!selectedCarrier) return;
        selectedCarrier.Attachments = selectedCarrier.Attachments ?? [];
        selectedCarrier.CompanionGuide = selectedCarrier.CompanionGuide ?? undefined;
        selectedCarrier.GroupStructure = selectedCarrier.GroupStructure ?? undefined;
        selectedCarrier.ConnectivityInfo = selectedCarrier.ConnectivityInfo ?? undefined;
        selectedCarrier.NamingConventionInfo = selectedCarrier.NamingConventionInfo ?? undefined;
        selectedCarrier.CensusFile = selectedCarrier.CensusFile ?? undefined;
        selectedCarrier.EncryptionFile = selectedCarrier.EncryptionFile ?? undefined;
        setUploadedFiles(selectedCarrier.Attachments);
    }, [tabValue, selectedCarriers]);


    React.useEffect(() => {
        if (!selectedOtherCarriers) return;
        const selectedOtherCarrier = selectedOtherCarriers[tabValue > selectedCarriers.length ? tabValue - selectedCarriers.length : selectedCarriers.length - tabValue];
        if (!selectedOtherCarrier) return;
        selectedOtherCarrier.Attachments = selectedOtherCarrier.Attachments ?? [];
        selectedOtherCarrier.CompanionGuide = selectedOtherCarrier.CompanionGuide ?? undefined;
        selectedOtherCarrier.GroupStructure = selectedOtherCarrier.GroupStructure ?? undefined;
        selectedOtherCarrier.ConnectivityInfo = selectedOtherCarrier.ConnectivityInfo ?? undefined;
        selectedOtherCarrier.NamingConventionInfo = selectedOtherCarrier.NamingConventionInfo ?? undefined;
        selectedOtherCarrier.CensusFile = selectedOtherCarrier.CensusFile ?? undefined;
        selectedOtherCarrier.EncryptionFile = selectedOtherCarrier.EncryptionFile ?? undefined;
        setUploadedOtherCarriersFiles(selectedOtherCarrier.Attachments);
    }, [tabValue, selectedOtherCarriers]);
    const onDeleteOtherFiles = () => {
        selectedOtherCarriers[tabValue].Attachments = [...uploadedOtherCarriersFiles];
    }
    const onDeleteFiles = () => {
        selectedCarriers[tabValue].Attachments = [...uploadedFiles];
    }
    const handleCompanionGuideChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "CarrierCompanionGuide", title: "Companion Guide" });
        }
    }

    const handleCompanionGuideChangeDragOtherCarriers = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64OtherCarriers({ files: files, type: "CarrierCompanionGuide", title: "Companion Guide" });
        }
    }

    const handleGroupStructureChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "GroupStructureDocument", title: "Group Structure" });
        }
    }

    const handleGroupStructureChangeDragOtherCarriers = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64OtherCarriers({ files: files, type: "GroupStructureDocument", title: "Group Structure" });
        }
    }

    const handleConnectivityInfoChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "ConnectivityInfoDocument", title: "Connectivity Info" });
        }
    }

    const handleConnectivityInfoChangeDragOtherCarriers = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64OtherCarriers({ files: files, type: "ConnectivityInfoDocument", title: "Connectivity Info" });
        }
    }

    const handleNamingConventionInfoChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "NamingConventionInfoDocument", title: "Naming Convention Info" });
        }
    }

    const handleNamingConventionInfoChangeDragOtherCarriers = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64OtherCarriers({ files: files, type: "NamingConventionInfoDocument", title: "Naming Convention Info" });
        }
    }

    const handleCensusFileChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "CensusFile", title: "Census File" });
        }
    }

    const handleCensusFileChangeDragOtherCarriers = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64OtherCarriers({ files: files, type: "CensusFile", title: "Census File" });
        }
    }

    const handleEncryptionFileChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "EncryptionFile", title: "Encryption key" });
        }
    }

    const handleEncryptionFileChangeDragOtherCarriers = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64OtherCarriers({ files: files, type: "EncryptionFile", title: "Encryption key" });
        }
    }

    const handleOtherFileChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "OtherFile", title: "Other" });
        }
    }

    const handleOtherFileChangeDragOtherCarriers = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64OtherCarriers({ files: files, type: "OtherFile", title: "Other" });
        }
    }


    const encodeFileBase64 = async ({ files, type, title }) => {
        files.map((file) => {
            var reader = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    var Base64 = reader.result.split(',')[1];
                    addCurrentFilesInArray(file, Base64, type, title);
                };
                reader.onerror = (error) => {
                    console.log("error: ", error);
                };
            }
        });
    };

    const encodeFileBase64OtherCarriers = async ({ files, type, title }) => {
        files.map((file) => {
            var reader = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    var Base64 = reader.result.split(',')[1];
                    addCurrentFilesInArrayOtherCarriers(file, Base64, type, title);
                };
                reader.onerror = (error) => {
                    console.log("error: ", error);
                };
            }
        });
    };

    const addCurrentFilesInArray = (file, fileBase64, type, title) => {
        if (file === undefined) return;
        openLoader();
        let UploadedFilesArray = uploadedFiles;
        let newFile = {
            Type: type,
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Title: title,
            Name: file?.name,
        }
        let newFileUploadAPI = {
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Value: fileBase64,
        }
        APIs.addAttachment(newFileUploadAPI).then(r => {
            newFile.GUID = r.GUID;
            setTempGUID(r?.GUID);
        });
        UploadedFilesArray.push(newFile);
        selectedCarriers[tabValue].Attachments = [...UploadedFilesArray];
        setUploadedFiles(selectedCarriers[tabValue].Attachments);
    }

    const addCurrentFilesInArrayOtherCarriers = (file, fileBase64, type, title) => {
        if (file === undefined) return;
        openLoader();
        let UploadedFilesArray = uploadedOtherCarriersFiles;
        let newFile = {
            Type: type,
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Title: title,
            Name: file?.name,
        }
        let newFileUploadAPI = {
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Value: fileBase64,
        }
        APIs.addAttachment(newFileUploadAPI).then(r => {
            newFile.GUID = r.GUID;
            setTempGUID(r?.GUID);
        });
        UploadedFilesArray.push(newFile);
        selectedOtherCarriers[tabValue > selectedCarriers.length ? tabValue - selectedCarriers.length : selectedCarriers.length - tabValue].Attachments = [...UploadedFilesArray];
        setUploadedOtherCarriersFiles(selectedOtherCarriers[tabValue > selectedCarriers.length ? tabValue - selectedCarriers.length : selectedCarriers.length - tabValue].Attachments);
    }

    React.useEffect(() => {
        if (uploadedFiles?.any(s => !s.GUID)) {
            openLoader();
        }
        else closeLoader();
    }, [tempGUID])

    React.useEffect(() => {
        if (uploadedOtherCarriersFiles?.any(s => !s.GUID)) {
            openLoader();
        }
        else closeLoader();
    }, [tempGUID])

    // if selectedTab removed from selected Carriers list and it was last item
    const handleTabValue = () => {
        let otherCarrierInCasePEOLength = (selectedOtherCarriers?.length ?? 0)
        if (tabValue >= (selectedCarriers?.length + otherCarrierInCasePEOLength)) {
            let totalLength = (selectedCarriers.length + otherCarrierInCasePEOLength)
            if (totalLength == 0) {
                return (0)
            } else {
                return (selectedCarriers.length - 1 + otherCarrierInCasePEOLength)
            }
        } else {
            return (tabValue)
        }
    }

    React.useEffect(() => {
        setTabValue(handleTabValue())
    }, [selectedCarriers, tabValue, selectedOtherCarriers])


    return (
        <div className="mt-5" id="connectivity-request-files-section">

            <Accordion expanded={expanded === `FilesAttachments`}>
                <AccordionSummary
                    onClick={() => expanded === `FilesAttachments` ? setExpanded("") : setExpanded("FilesAttachments")}
                    // className="pl-0 pr-0"
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="expand-carrier-contacts"
                >
                    <span >Attach Documents</span>
                </AccordionSummary>
                <AccordionDetails className="d-flex flex-column">
                    {/* <AppBar position="static" color="default">
                            <div className="row">
                                <Tabs
                                    className="tab-style w-100"
                                    value={handleTabValue()}
                                    onChange={handleTabChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs"
                                >
                                    {selectedCarriers?.map((sc, sci) => (
                                        <Tab key={`carrier-contacts-tab-${sci}`} label={isPEOClicked ? sc?.CarrierName : sc?.carrierName} />
                                    ))}
                                    {selectedOtherCarriers?.map((s, si) => (
                                        <Tab
                                            key={`tp-tab-${si}`}
                                            label={s?.Name ?? tabDefaultValue}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                        </AppBar> */}

                    <div className="border-bottom">
                        <TabsTitlesWithMoreDD
                            tabTitle={[
                                ...(selectedCarriers?.map(sc => isPEOClicked ? sc?.CarrierName : sc?.carrierName) || []),
                                ...(selectedOtherCarriers?.map(s => s?.Name ?? tabDefaultValue) || [])
                            ]}
                            value={handleTabValue()}
                            setValue={setTabValue}
                        />
                    </div>
                    {selectedCarriers?.length > 0 && tabValue < selectedCarriers?.length && (
                        <TabPanel value={tabValue} index={tabValue} >
                            <div className="row">
                                <div className="col-3 row alignvh-center">
                                    <div className="label-text">Choose Files</div>
                                    <DropzoneArea
                                        dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                        filesLimit={100}
                                        dropzoneText={"Companion Guide"}
                                        onDrop={(files) => handleCompanionGuideChangeDrag(files)}
                                        showPreviewsInDropzone={false}
                                        showAlerts={false}
                                    />
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Group Structure"}
                                            onDrop={(files) => handleGroupStructureChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Connectivity Info"}
                                            onDrop={(files) => handleConnectivityInfoChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Name Convention"}
                                            onDrop={(files) => handleNamingConventionInfoChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Census File"}
                                            onDrop={(files) => handleCensusFileChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Encryption Key"}
                                            onDrop={(files) => handleEncryptionFileChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Other"}
                                            onDrop={(files) => handleOtherFileChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                </div>
                                {uploadedFiles?.length <= 0 ? (
                                    <div className="col-9">
                                        <div className="label-text">Attached Files</div>
                                        {/* <Divider className="mb-3" /> */}
                                        <div className="files-list-div">
                                            <h6 className="color grey2">No files were attached</h6>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-9">
                                        {/* <h5 className="mt-3 ml-3 black1">Chosen Files Summary</h5> */}
                                        {/* <Divider /> */}
                                        <div className="label-text">Attached Files</div>

                                        <div className="files-list-div">
                                            {uploadedFiles?.length > 0 && uploadedFiles?.map((l, li) => (
                                                <FileRow
                                                    key={`files-list-${li}`}
                                                    file={l}
                                                    index={li}
                                                    uploadedFiles={uploadedFiles}
                                                    setUploadedFiles={setUploadedFiles}
                                                    onDeleteFiles={onDeleteFiles}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </TabPanel>
                    )}
                    {selectedOtherCarriers?.length > 0 && tabValue >= selectedCarriers?.length && (
                        <TabPanel value={tabValue} index={tabValue} >
                            <div className="row">
                                <div className="col-4 row alignvh-center">
                                    <DropzoneArea
                                        dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                        filesLimit={100}
                                        dropzoneText={"Companion Guide"}
                                        onDrop={(files) => handleCompanionGuideChangeDragOtherCarriers(files)}
                                        showPreviewsInDropzone={false}
                                        showAlerts={false}
                                    />
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Group Structure"}
                                            onDrop={(files) => handleGroupStructureChangeDragOtherCarriers(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Connectivity Info"}
                                            onDrop={(files) => handleConnectivityInfoChangeDragOtherCarriers(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Name Convention"}
                                            onDrop={(files) => handleNamingConventionInfoChangeDragOtherCarriers(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Census File"}
                                            onDrop={(files) => handleCensusFileChangeDragOtherCarriers(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Encryption Key"}
                                            onDrop={(files) => handleEncryptionFileChangeDragOtherCarriers(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Other"}
                                            onDrop={(files) => handleOtherFileChangeDragOtherCarriers(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                </div>
                                {uploadedOtherCarriersFiles?.length <= 0 ? (
                                    <div className="col-8 files-list-div">
                                        <h5 className="mt-3 ml-3 black1">Chosen Files Summary</h5>
                                        <Divider className="mb-3" />
                                        <div className="files-list text-center">
                                            <h6 className="color grey2">No files were chosen</h6>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="col-8 files-list-div">
                                        <h5 className="mt-3 ml-3 black1">Chosen Files Summary</h5>
                                        <Divider />
                                        <div className="files-list">
                                            {uploadedOtherCarriersFiles?.length > 0 && uploadedOtherCarriersFiles?.map((l, li) => (
                                                <FileRow
                                                    key={`files-list-${li}`}
                                                    file={l}
                                                    index={li}
                                                    uploadedFiles={uploadedOtherCarriersFiles}
                                                    setUploadedFiles={setUploadedOtherCarriersFiles}
                                                    onDeleteFiles={onDeleteOtherFiles}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </TabPanel>
                    )}
                </AccordionDetails>
            </Accordion>

        </div>
    )
}

export default FilesAttachments;
