import React from "react";
import GroupRequestsStatistics from "./GroupRequestsStatistics";
import GroupRequestsListView from "./GroupRequestsListView";
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import Moment from 'moment';
import { useParams } from "react-router-dom";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0)

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function GroupRequestsDashboard() {

    const { openLoader, closeLoader } = useLoader();

    const { GRStatusURL } = useParams();

    const tableRef = React.useRef();

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);

    const [dashboardGRStatus, setDashboardGRStatus] = React.useState([]);
    const [dashboardGRPhase, setDashboardGRPhase] = React.useState([]);
    const [groupRequestsList, setGroupRequestsList] = React.useState([""]);
    const [groupRequestsStatus, setGroupRequestsStatus] = React.useState([]);
    const [groupRequestsPhases, setGroupRequestsPhases] = React.useState([]);
    const [isPEOFilter, setIsPEOFilter] = React.useState("");
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [fromDateFilter, setFromDateFilter] = React.useState(null);
    const [toDateFilter, setToDateFilter] = React.useState(null);
    const [fromDateAdvFilter, setFromDateAdvFilter] = React.useState(null);
    const [toDateAdvFilter, setToDateAdvFilter] = React.useState(null);
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const [groupNameFilter, setGroupNameFilter] = React.useState("");
    const [peoNameFilter, setPEONameFilter] = React.useState("");
    const [groupRequestIDFilter, setGroupRequestIDFilter] = React.useState("");
    const [groupIDFilter, setGroupIDFilter] = React.useState("");
    const [peoIDFilter, setPEOIDFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState("");
    const [phaseFilter, setPhaseFilter] = React.useState("");
    const [migrationFilter, setMigrationFilter] = React.useState("");
    const [onPhaseDashboardChange, setOnPhaseDashboardChange] = React.useState(false);
    const [onStatusDashboardChange, setOnStatusDashboardChange] = React.useState(false);
    const [refreshClicked, setRefreshClicked] = React.useState(false);
    const [clientNumberFilter, setClientNumberFilter] = React.useState("");

    //Advanced Filters
    const [selectedGroupsFilter, setSelectedGroupsFilter] = React.useState([]);
    const [selectedPEOsFilter, setSelectedPEOsFilter] = React.useState([]);
    const [selectedStatusesFilter, setSelectedStatusesFilter] = React.useState([]);
    const [selectedPhasesFilter, setSelectedPhasesFilter] = React.useState([]);
    const [groupRequestIDAdvFilter, setGroupRequestIDAdvFilter] = React.useState("");
    const [groupIDAdvFilter, setGroupIDAdvFilter] = React.useState("");
    const [clientNumberAdvFilter, setClientNumberAdvFilter] = React.useState("");
    const [peoIDAdvFilter, setPEOIDAdvFilter] = React.useState("");
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("");
    const [migrationAdvFilter, setMigrationAdvFilter] = React.useState();
    const [migrationStartDateFromDateAdvFilter, setMigrationStartDateFromDateAdvFilter] = React.useState(null);
    const [migrationStartDateToDateAdvFilter, setMigrationStartDateToDateAdvFilter] = React.useState(null);
    const [plannedFirstProductionDateFromDateAdvFilter, setPlannedFirstProductionDateFromDateAdvFilter] = React.useState(null);
    const [plannedFirstProductionDateToDateAdvFilter, setPlannedFirstProductionDateToDateAdvFilter] = React.useState(null);
    const [migrationPhasesAdvFilter, setMigrationPhasesAdvFilter] = React.useState([]);
    const [createdByAdvFilter, setCreatedByAdvFilter] = React.useState([]);
    const [isPEOAdvFilter, setIsPEOAdvFilter] = React.useState("");
    const [filterCard, setFilterCard] = React.useState(false);
    React.useEffect(() => {
        openLoader();
        APIs.getGroupRequestStatuses().then((r) => (
            !(r?.data?.length) ? setGroupRequestsStatus([]) : setGroupRequestsStatus(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupRequestPhases().then((r) => (
            !(r?.data?.length) ? setGroupRequestsPhases([]) : setGroupRequestsPhases(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupRequestsDashboard().then((r) => {
            !(r?.data?.StatusPercentage?.length) ? setDashboardGRStatus([]) : setDashboardGRStatus(r?.data?.StatusPercentage?.map((c) => (c)));
            !(r?.data?.phasesPercentage?.length) ? setDashboardGRPhase([]) : setDashboardGRPhase(r?.data?.phasesPercentage?.map((c) => (c)))
        });
    }, [refreshClicked]);

    const getDataQuickFilters = () => {
        openLoader();
        const requestParameters = {};
        if (GRStatusURL === undefined) {
            requestParameters.SubmissionDateFrom = fromDateFilter ? Moment(fromDateFilter).format('YYYY-MM-DD') : null;
            requestParameters.SubmissionDateTo = toDateFilter ? Moment(toDateFilter).format('YYYY-MM-DD') : null;
            if (selectedPage >= 1) {
                requestParameters.page = selectedPage;
                requestParameters.limit = pageSize;
            }
            if (pageSize >= 10) {
                requestParameters.limit = pageSize;
                requestParameters.page = selectedPage;
            }
            if (groupNameFilter !== "")
                requestParameters.GroupName = groupNameFilter;
            if (peoNameFilter !== "")
                requestParameters.PeoName = peoNameFilter;
            if (groupRequestIDFilter !== "")
                requestParameters.GroupRequestID = groupRequestIDFilter;
            if (migrationFilter?.length > 0)
                requestParameters.MigrationGroupRequestsArray = [migrationFilter];
            if (groupIDFilter !== "")
                requestParameters.GroupID = groupIDFilter;
            if (clientNumberFilter !== "")
                requestParameters.GroupNumber = clientNumberFilter;
            if (statusFilter !== "")
                requestParameters.GroupRequestStatusID = statusFilter;
            if (phaseFilter !== "")
                requestParameters.GroupRequestPhaseIDsArray = [phaseFilter];
            if (sortFieldFilter !== "")
                requestParameters.sortField = sortFieldFilter;

            if (sortOrderFilter !== "")
                requestParameters.sortOrder = sortOrderFilter;
            if (isPEOFilter !== null) {
                requestParameters.IsPEO = isPEOFilter;
            }
        } else {

            if (GRStatusURL === "Active") {
                requestParameters.SubmissionDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.SubmissionDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.GroupRequestStatusID = 1;
                if (selectedPage >= 1) {
                    requestParameters.page = selectedPage;
                    requestParameters.limit = pageSize;
                }
                if (pageSize >= 10) {
                    requestParameters.limit = pageSize;
                    requestParameters.page = selectedPage;
                }
                if (groupNameFilter !== "")
                    requestParameters.GroupName = groupNameFilter;
                if (peoNameFilter !== "")
                    requestParameters.PeoName = peoNameFilter;
                if (groupRequestIDFilter !== "")
                    requestParameters.GroupRequestID = groupRequestIDFilter;
                if (migrationFilter?.length > 0)
                    requestParameters.MigrationGroupRequestsArray = [migrationFilter];
                if (phaseFilter !== "")
                    requestParameters.GroupRequestPhaseIDsArray = [phaseFilter];
                if (groupIDFilter !== "")
                    requestParameters.GroupID = groupIDFilter;
                if (clientNumberFilter !== "")
                    requestParameters.GroupNumber = clientNumberFilter;
                if (sortFieldFilter !== "")
                    requestParameters.sortField = sortFieldFilter;
                if (sortOrderFilter !== "")
                    requestParameters.sortOrder = sortOrderFilter;
                if (isPEOFilter !== null) {
                    requestParameters.IsPEO = isPEOFilter;
                }
            } else if (GRStatusURL === "Cancelled") {
                requestParameters.SubmissionDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.SubmissionDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.GroupRequestStatusID = 2;
                if (selectedPage >= 1) {
                    requestParameters.page = selectedPage;
                    requestParameters.limit = pageSize;
                }
                if (pageSize >= 10) {
                    requestParameters.limit = pageSize;
                    requestParameters.page = selectedPage;
                }
                if (groupNameFilter !== "")
                    requestParameters.GroupName = groupNameFilter;
                if (peoNameFilter !== "")
                    requestParameters.PeoName = peoNameFilter;
                if (groupRequestIDFilter !== "")
                    requestParameters.GroupRequestID = groupRequestIDFilter;
                if (migrationFilter?.length > 0)
                    requestParameters.MigrationGroupRequestsArray = [migrationFilter];
                if (phaseFilter !== "")
                    requestParameters.GroupRequestPhaseIDsArray = [phaseFilter];
                if (groupIDFilter !== "")
                    requestParameters.GroupID = groupIDFilter;
                if (clientNumberFilter !== "")
                    requestParameters.GroupNumber = clientNumberFilter;
                if (sortFieldFilter !== "")
                    requestParameters.sortField = sortFieldFilter;
                if (sortOrderFilter !== "")
                    requestParameters.sortOrder = sortOrderFilter;
                if (isPEOFilter !== null) {
                    requestParameters.IsPEO = isPEOFilter;
                }
            } else {

                requestParameters.SubmissionDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.SubmissionDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.GroupRequestStatusID = 3;
                if (selectedPage >= 1) {
                    requestParameters.page = selectedPage;
                    requestParameters.limit = pageSize;
                }
                if (pageSize >= 10) {
                    requestParameters.limit = pageSize;
                    requestParameters.page = selectedPage;
                }
                if (groupNameFilter !== "")
                    requestParameters.GroupName = groupNameFilter;
                if (peoNameFilter !== "")
                    requestParameters.PeoName = peoNameFilter;
                if (groupRequestIDFilter !== "")
                    requestParameters.GroupRequestID = groupRequestIDFilter;
                if (migrationFilter?.length > 0)
                    requestParameters.MigrationGroupRequestsArray = [migrationFilter];
                if (phaseFilter !== "")
                    requestParameters.GroupRequestPhaseIDsArray = [phaseFilter];
                if (groupIDFilter !== "")
                    requestParameters.GroupID = groupIDFilter;
                if (clientNumberFilter !== "")
                    requestParameters.GroupNumber = clientNumberFilter;
                if (sortFieldFilter !== "")
                    requestParameters.sortField = sortFieldFilter;
                if (sortOrderFilter !== "")
                    requestParameters.sortOrder = sortOrderFilter;
                if (isPEOFilter !== null) {
                    requestParameters.IsPEO = isPEOFilter;
                }
            }
        }

        APIs.getGroupRequests(requestParameters).then((r) => (
            setGroupRequestsList(r?.data?.list),
            setTotalCount(r?.totalCount ?? 0),
            setRefreshClicked(false),
            setFilterCard(false),
            closeLoader()
        ));
    }

    const getDataAdvancedFilters = () => {
        openLoader();
        const requestParameters = {};
        requestParameters.SubmissionDateFrom = fromDateAdvFilter ? Moment(fromDateAdvFilter).format('YYYY-MM-DD') : null;
        requestParameters.SubmissionDateTo = toDateAdvFilter ? Moment(toDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedPageAdv >= 1) {
            requestParameters.page = selectedPageAdv;
            requestParameters.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            requestParameters.limit = pageSizeAdv;
            requestParameters.page = selectedPageAdv;
        }
        if (groupRequestIDAdvFilter !== "")
            requestParameters.GroupRequestID = groupRequestIDAdvFilter;
        if (groupIDAdvFilter !== "")
            requestParameters.GroupID = groupIDAdvFilter;
        if (clientNumberAdvFilter !== "")
            requestParameters.GroupNumber = clientNumberAdvFilter;
        if (peoIDAdvFilter !== "")
            requestParameters.GroupID = peoIDAdvFilter;
        if (migrationAdvFilter?.length > 0)
            requestParameters.MigrationGroupRequestsArray = migrationAdvFilter;
        if (selectedGroupsFilter.length > 0)
            requestParameters.GroupIDsArray = selectedGroupsFilter?.map(s => s?.GroupID);
        if (selectedPEOsFilter.length > 0)
            requestParameters.PeoIDsArray = selectedPEOsFilter?.map(s => s?.PeoId);
        if (selectedStatusesFilter.length > 0)
            requestParameters.GroupRequestStatusIDsArray = selectedStatusesFilter?.map(s => s?.GroupRequestStatusID);
        if (selectedPhasesFilter.length > 0)
            requestParameters.GroupRequestPhaseIDsArray = selectedPhasesFilter?.map(s => s?.Id);
        if (sortFieldAdvFilter !== "")
            requestParameters.sortField = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            requestParameters.sortOrder = sortOrderAdvFilter;

        // created by filter adv
        if (createdByAdvFilter.length > 0)
            requestParameters.CreatedByCognitoIDsArray = createdByAdvFilter?.map(s => s?.CognitoID);


        if (migrationPhasesAdvFilter.length > 0)
            requestParameters.MigrationPhaseIDsArray = migrationPhasesAdvFilter?.map(s => s?.Id);

        if (migrationStartDateFromDateAdvFilter !== null)
            requestParameters.MigrationStartDateFrom = migrationStartDateFromDateAdvFilter ? Moment(migrationStartDateFromDateAdvFilter).format('YYYY-MM-DD') : null;

        if (migrationStartDateToDateAdvFilter !== null)
            requestParameters.MigrationStartDateTo = migrationStartDateToDateAdvFilter ? Moment(migrationStartDateToDateAdvFilter).format('YYYY-MM-DD') : null;


        if (plannedFirstProductionDateFromDateAdvFilter !== null)
            requestParameters.PlannedFirstProductionDateFrom = plannedFirstProductionDateFromDateAdvFilter ? Moment(plannedFirstProductionDateFromDateAdvFilter).format('YYYY-MM-DD') : null;

        if (plannedFirstProductionDateToDateAdvFilter !== null)
            requestParameters.PlannedFirstProductionDateTo = plannedFirstProductionDateToDateAdvFilter ? Moment(plannedFirstProductionDateToDateAdvFilter).format('YYYY-MM-DD') : null;

        if (isPEOAdvFilter !== null) {
            requestParameters.IsPEO = isPEOAdvFilter;
        }
        APIs.getGroupRequests(requestParameters).then((r) => (
            setGroupRequestsList(r?.data?.list),
            setTotalCount(r?.totalCount ?? 0),
            setAdvancedFiltersAppliedClicked(false),
            closeLoader()
        ));
    }

    React.useEffect(() => {
        if (quickFiltersClicked && !filterCard) getDataQuickFilters();
    }, [filterCard, quickFiltersClicked === true, selectedPage, pageSize, groupNameFilter, peoNameFilter, groupIDFilter, clientNumberFilter, peoIDFilter, groupRequestIDFilter, fromDateFilter, toDateFilter, statusFilter, phaseFilter, migrationFilter, sortFieldFilter, sortOrderFilter, isPEOFilter, refreshClicked])


    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])



    React.useEffect(() => {
        if (advancedFiltersClicked === true) getDataAdvancedFilters();
    }, [advancedFiltersClicked === true])

    return (
        <div>
            {GRStatusURL === undefined && (
                <GroupRequestsStatistics
                    dashboardGRStatus={dashboardGRStatus}
                    dashboardGRPhase={dashboardGRPhase}
                    onPhaseFilter={(s) => setPhaseFilter(s)}
                    onStatusFilter={(s) => setStatusFilter(s)}
                    tableRef={tableRef}
                    onSetOnPhaseDashboardChange={(s) => setOnPhaseDashboardChange(s)}
                    onSetOnStatusDashboardChange={(s) => setOnStatusDashboardChange(s)}
                    onSetFromDateFilter={(s) => setFromDateFilter(s)}
                    onSetToDateFilter={(s) => setToDateFilter(s)}
                    onSetFilterCard={(s) => setFilterCard(s)}
                />
            )}
            <GroupRequestsListView
                groupRequestsList={groupRequestsList}
                setGroupRequestsList={setGroupRequestsList}
                selectedPage={selectedPage}
                onSelectedPage={(p) => setSelectedPage(p)}
                selectedPageAdv={selectedPageAdv}
                onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                pageSize={pageSize}
                onPageSize={(p) => setPageSize(p)}
                pageSizeAdv={pageSizeAdv}
                onPageSizeAdv={(p) => setPageSizeAdv(p)}
                onGroupNameFilter={(g) => setGroupNameFilter(g)}
                groupNameFilter={groupNameFilter}
                onPEONameFilter={(g) => setPEONameFilter(g)}
                peoNameFilter={peoNameFilter}
                onGroupRequestIDFilter={(i) => setGroupRequestIDFilter(i)}
                groupRequestIDFilter={groupRequestIDFilter}
                onGroupRequestIDAdvFilter={(i) => setGroupRequestIDAdvFilter(i)}
                groupRequestIDAdvFilter={groupRequestIDAdvFilter}
                onMigrationFilter={s => setMigrationFilter(s)}
                migrationFilter={migrationFilter}
                onMigrationAdvFilter={s => setMigrationAdvFilter(s)}
                migrationAdvFilter={migrationAdvFilter}
                onGroupIDFilter={(j) => setGroupIDFilter(j)}
                groupIDFilter={groupIDFilter}
                onClientNumberFilter={(j) => setClientNumberFilter(j)}
                clientNumberFilter={clientNumberFilter}
                onGroupIDAdvFilter={(i) => setGroupIDAdvFilter(i)}
                groupIDAdvFilter={groupIDAdvFilter}
                onClientNumberAdvFilter={(i) => setClientNumberAdvFilter(i)}
                clientNumberAdvFilter={clientNumberAdvFilter}
                onPEOIDFilter={(j) => setPEOIDFilter(j)}
                peoIDFilter={peoIDFilter}
                onPEOIDAdvFilter={(i) => setPEOIDAdvFilter(i)}
                peoIDAdvFilter={peoIDAdvFilter}
                onFromDateFilter={(d) => setFromDateFilter(d)}
                fromDateFilter={fromDateFilter}
                onToDateFilter={(d) => setToDateFilter(d)}
                toDateFilter={toDateFilter}
                onFromDateAdvFilter={(d) => setFromDateAdvFilter(d)}
                fromDateAdvFilter={fromDateAdvFilter}
                onToDateAdvFilter={(d) => setToDateAdvFilter(d)}
                toDateAdvFilter={toDateAdvFilter}
                totalCount={totalCount}
                setTotalCount={setTotalCount}
                onStatusFilter={(s) => setStatusFilter(s)}
                statusFilter={statusFilter}
                onPhaseFilter={(s) => setPhaseFilter(s)}
                phaseFilter={phaseFilter}
                onSortFieldFilter={(s) => setSortFieldFilter(s)}
                onSortOrderFilter={(s) => setSortOrderFilter(s)}
                onSortFieldAdvFilter={(s) => setSortFieldAdvFilter(s)}
                onSortOrderAdvFilter={(s) => setSortOrderAdvFilter(s)}
                groupRequestsStatus={groupRequestsStatus}
                groupRequestsPhases={groupRequestsPhases}
                GRStatus={GRStatusURL}
                onSelectedGroups={(s) => setSelectedGroupsFilter(s)}
                selectedGroupsFilter={selectedGroupsFilter}
                onSelectedPEOs={(s) => setSelectedPEOsFilter(s)}
                selectedPEOsFilter={selectedPEOsFilter}
                onSelectedStatuses={(s) => setSelectedStatusesFilter(s)}
                selectedStatusesFilter={selectedStatusesFilter}
                onSelectedPhases={(s) => setSelectedPhasesFilter(s)}
                selectedPhasesFilter={selectedPhasesFilter}
                quickFiltersClicked={quickFiltersClicked}
                setQuickFiltersClicked={setQuickFiltersClicked}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                tableRef={tableRef}
                onMigrationStartFromDateAdvFilter={(s) => setMigrationStartDateFromDateAdvFilter(s)}
                onMigrationStartToDateAdvFilter={(s) => setMigrationStartDateToDateAdvFilter(s)}
                onPlannedFirstProductionFromDateAdvFilter={(s) => setPlannedFirstProductionDateFromDateAdvFilter(s)}
                onPlannedFirstProductionToDateAdvFilter={(s) => setPlannedFirstProductionDateToDateAdvFilter(s)}
                onSelectedMigrationPhasesAdvFilter={(s) => setMigrationPhasesAdvFilter(s)}
                migrationStartDateFromDateAdvFilter={migrationStartDateFromDateAdvFilter}
                migrationStartDateToDateAdvFilter={migrationStartDateToDateAdvFilter}
                plannedFirstProductionDateFromDateAdvFilter={plannedFirstProductionDateFromDateAdvFilter}
                plannedFirstProductionDateToDateAdvFilter={plannedFirstProductionDateToDateAdvFilter}
                migrationPhasesAdvFilter={migrationPhasesAdvFilter}
                createdByAdvFilter={createdByAdvFilter}
                onSelectedCreatedByAdvFilter={(s) => setCreatedByAdvFilter(s)}
                onPhaseDashboardChange={onPhaseDashboardChange}
                onStatusDashboardChange={onStatusDashboardChange}
                onSetOnPhaseDashboardChange={(s) => setOnPhaseDashboardChange(s)}
                onSetOnStatusDashboardChange={(s) => setOnStatusDashboardChange(s)}
                onsetFilterCard={(s) => setFilterCard(s)}
                setRefreshClicked={(s) => setRefreshClicked(s)}
                isPEOFilter={isPEOFilter}
                onSetIsPEOFilter={setIsPEOFilter}
                isPEOAdvFilter={isPEOAdvFilter}
                onSetIsPEOAdvFilter={setIsPEOAdvFilter}
            />
        </div>
    )
}

export default GroupRequestsDashboard;