import React, { useState } from "react";
import logoSVG from "../../Images/imageLogo.svg";
import Button from "@material-ui/core/Button";
import LockIcon from '@material-ui/icons/Lock';
import { useLoader } from "../Layout/Loader";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { useModalDialog } from "../Layout/ModalDialogCustomizedNoClose";
import { FailureModalWithAction } from "../Modals/FailureModalWithAction";
import * as APIs from "../../Data/APIs";

function SSOContainer({ cognitoUser, currentURLOnLogin, onCognitoUser, username, setUsername, password, setPassword, error, setError, ...props }) {

    const [mfaCode, setMFACode] = React.useState("");

    const [errorCode, setErrorCode] = React.useState("");
    const location = useLocation();
    const params = location?.search ? new URLSearchParams(location.search) : null;

    const dialog = useModalDialog(0);

    const { openLoader, closeLoader } = useLoader();
    const routeHistory = useHistory();

    React.useEffect(() => {
        openLoader();
        if (location?.search) {
            const authenticatedCode = params.get("code");
            // const authenticatedEmail = params.get("EmailAddress");
            // const authenticatedSSOClient = params.get("SSOClient");
            // const authenticatedCompanyCode = params.get("CompanyCode");
            // const authenticatedSSOToken = params.get("SSOToken");
            if (authenticatedCode && authenticatedCode !== "") {
                APIs.getEmailFromCode({ code: authenticatedCode }).then(r => {
                    Auth.signIn(r.email).then(resultSignIn => {
                        if (resultSignIn.challengeName === "CUSTOM_CHALLENGE" && resultSignIn.challengeParam?.name === "SSO-Auth") {
                            Auth.sendCustomChallengeAnswer(resultSignIn, JSON.stringify({ Email: r.email, Username: username })).then(resultCustomChallenge => {
                                onCognitoUser(resultCustomChallenge);
                                // first time
                                if (resultCustomChallenge.challengeName === "CUSTOM_CHALLENGE" && resultCustomChallenge.challengeParam?.name === "SMS-OTP") {
                                    closeLoader();
                                } else {
                                    params.delete("code");
                                    // params.delete("EmailAddress");
                                    // params.delete("SSOClient");
                                    // params.delete("CompanyCode");
                                    // params.delete("SSOToken");
                                    params.delete("IsFirstLogin");
                                    params.delete("PendingEmployeeId");
                                    params.delete("IsLoggingIn");
                                    params.delete("EmployeeSelected");
                                    const paramsStr = params.toString();
                                    window.location.href = "/";
                                }
                            }).catch((error) => {
                                console.log('error: ', error);
                                FailureModalWithAction(dialog, {
                                    title: 'Failure!',
                                    body: "Error: " + error.message.split("with error")[1]
                                });
                                closeLoader();
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                        closeLoader();
                    });
                })
            }
        }
    }, [location?.search])

    // const verifyInput = React.useRef();

    // React.useEffect(() => {
    //     verifyInput.current.focus();
    // }, [])

    return (
        <div id="login">
            <div className="row">
                <div className="col-1 login-first-corner-div"></div>
                <div className="col-10 login-div"></div>
                <div className="col-1 login-last-corner-div"></div>
            </div>
            <div className="login-content-mfa row">
                <div className="col-6" style={{ display: "inline-flex", justifyContent: "center" }}>
                    <svg viewBox="0 0 73 14" width="60%">
                        <use xlinkHref={`${logoSVG}#logoSVG`}></use>
                    </svg>
                </div>
                <div className="col-6">
                    <div elevation={0} style={{ height: "100%", padding: "30px 85px" }}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (!mfaCode) { setErrorCode("confirmation code cannot be empty"); closeLoader(); return; }
                            openLoader();
                            // change it to SMS-OTP ->
                            Auth.sendCustomChallengeAnswer(cognitoUser, mfaCode,)
                                .then((r) => {
                                    onCognitoUser(r);
                                    setErrorCode("");
                                    params.delete("EmailAddress");
                                    params.delete("SSOClient");
                                    params.delete("CompanyCode");
                                    params.delete("SSOToken");
                                    params.delete("IsFirstLogin");
                                    params.delete("PendingEmployeeId");
                                    params.delete("IsLoggingIn");
                                    params.delete("EmployeeSelected");
                                    window.location.href = "/";
                                    closeLoader();
                                })
                                .catch((err) => {
                                    if (err.message.includes("session is expired"))
                                        setErrorCode("Session is expired, please try again!")
                                    else if (err.message.includes("Invalid code"))
                                        setErrorCode("confirmation code is incorrect, please try again!")
                                    else if (err.message.includes("Too many invalid credentials attempts"))
                                        window.location.href = "/";
                                    else setErrorCode(err);
                                    closeLoader();
                                });
                        }}>
                        </form>
                    </div>
                </div>
            </div>
        </div >


    )
}

export default SSOContainer;