import React from "react";
import { Select } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Grid from '@mui/material/Grid';

function AnalystCreateTransmissionProtocol({
    onTransmissionTypeChange,
    onProtocolTypeChange,
    onSSHKeyChange,
    onSFTPSiteChange,
    onSFTPPortChange,
    onSFTPUsernameChange,
    onSFTPPasswordChange,
    onSFTPDirectoryChange,
    onConfigureView,
    transmissionTypeValidationForm,
    sftpProtocolTypeValidationForm,
    sftpSSHKeyValidationForm,
    sftpSiteValidationForm,
    sftpPortValidationForm,
    sftpUsernameValidationForm,
    sftpPasswordValidationForm,
    sftpDirectoryValidationForm,
    requestSubmitted,
    feedsTransmissionTypes,
    feedsProtocolTypes,
    feedSSHKeys,
    toEmail,
    setToEmail,
    ccEmail,
    setCcEmail,
    bccEmail,
    setBccEmail,
    emailSubject,
    setEmailSubject,
    carrierEmailSubjectValidationForm,
    emailBody,
    setEmailBody,
    carrierEmailBodyValidationForm,
    carrierEmailToEmailValidationForm,
    ...props
}) {

    const [transmissionType, setTransmissionType] = React.useState();
    const [protocolType, setProtocolType] = React.useState();
    const [sshKey, setSSHKey] = React.useState();
    const [sftpSite, setSFTPSite] = React.useState();
    const [sftpPort, setSFTPPort] = React.useState();
    const [sftpUsername, setSFTPUsername] = React.useState();
    const [sftpPassword, setSFTPPassword] = React.useState();
    const [sftpDirectory, setSFTPDirectory] = React.useState();
    const [showPassword, setShowPassword] = React.useState(false);

    const handleTransmissionTypeChange = (e) => {
        setTransmissionType(e.target.value);
        onTransmissionTypeChange(e.target.value);
    };

    const handleProtocolTypeChange = (e) => {
        setProtocolType(e.target.value);
        onProtocolTypeChange(e.target.value);
    };

    const handleSSHKeyChange = (e) => {
        setSSHKey(e.target.value);
        onSSHKeyChange(e.target.value);
    };

    const handleSFTPSiteChange = (e) => {
        setSFTPSite(e.target.value);
        onSFTPSiteChange(e.target.value);
    }

    const handleSFTPPortChange = (e) => {
        setSFTPPort(e.target.value);
        onSFTPPortChange(e.target.value);
    }

    const handleSFTPUsernameChange = (e) => {
        setSFTPUsername(e.target.value);
        onSFTPUsernameChange(e.target.value);
    }

    const handleSFTPPasswordChange = (e) => {
        setSFTPPassword(e.target.value);
        onSFTPPasswordChange(e.target.value);
    }

    const handleSFTPDirectoryChange = (e) => {
        setSFTPDirectory(e.target.value);
        onSFTPDirectoryChange(e.target.value);
    }

    return (
        <div className="w-100">
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-3">
                <Grid item xs={12}>
                    <div style={{ display: "ruby" }}>
                        <h6 className="form-title">Transmission Type</h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <FormControl label="Select transmission" focused={false} variant="outlined" className="w-100">
                        <Select
                            className="text-dropdown"
                            value={transmissionType ?? ""}
                            onChange={handleTransmissionTypeChange}
                        >
                            {feedsTransmissionTypes?.length > 0 && feedsTransmissionTypes.map((t, ti) => (
                                <MenuItem key={`transmission-types-${ti}`} value={t.TransmissionTypeID}>{t.TransmissionTypeName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>{transmissionTypeValidationForm}</div>
                </Grid>
            </Grid>

            {transmissionType === 2 && (
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Protocol Type</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                        </div>
                        <FormControl label="Select transmission" focused={false} variant="outlined" className="w-100">
                            <Select
                                className="text-dropdown"
                                value={protocolType ?? ""}
                                onChange={handleProtocolTypeChange}
                            >
                                {feedsProtocolTypes?.length > 0 && feedsProtocolTypes.map((p, pi) => (
                                    <MenuItem key={`protocol-types-${pi}`} value={p.ProtocolTypeName}>{p.ProtocolTypeName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpProtocolTypeValidationForm}</div>
                    </Grid>
                    {protocolType === "SCP SFTP (SSH)" && (
                        <Grid item xs={12} sm={12} md={6}>
                            <div style={{ display: "ruby" }}>
                                <h6 className="form-title">SSH Key</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                            </div>
                            <FormControl label="Select transmission" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={sshKey ?? ""}
                                    onChange={handleSSHKeyChange}
                                >
                                    {feedSSHKeys?.length > 0 && feedSSHKeys.map((p, pi) => (
                                        <MenuItem key={`ssh-key-${pi}`} value={p.Id}>{p.FeedKeyName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpSSHKeyValidationForm}</div>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Site</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                        </div>
                        <input type="text" className="text-input w-100" value={sftpSite ?? ""} onChange={handleSFTPSiteChange} />
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpSiteValidationForm}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Port</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                        </div>
                        <input type="number" className="text-input w-100" value={sftpPort ?? ""} onChange={handleSFTPPortChange} />
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpPortValidationForm}</div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Username</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                        </div>
                        <input type="text" className="text-input w-100" value={sftpUsername ?? ""} onChange={handleSFTPUsernameChange} />
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpUsernameValidationForm}</div>
                    </Grid>
                    {protocolType !== "SCP SFTP (SSH)" && (
                        <Grid item xs={12} sm={12} md={6}>
                            <div style={{ display: "ruby" }}>
                                <h6 className="form-title">Password</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                            </div>
                            <div className="position-relative">
                                <input type={showPassword ? "text" : "password"} className="text-input w-100" value={sftpPassword ?? ""} onChange={handleSFTPPasswordChange} />
                                <span style={{ right: "12px", position: "absolute", cursor: "pointer", top: "5px" }}>
                                    {showPassword ? <Visibility className="fs-18" onClick={() => setShowPassword(false)} /> : <VisibilityOff className="fs-18" onClick={() => setShowPassword(true)} />}
                                </span>
                            </div>
                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpPasswordValidationForm}</div>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Remote Directory</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                        </div>
                        <input type="text" className="text-input w-100" value={sftpDirectory ?? ""} onChange={handleSFTPDirectoryChange} />
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{sftpDirectoryValidationForm}</div>
                    </Grid>
                </Grid>
            )}

            {transmissionType && transmissionType === 1 && (
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-3">
                    <Grid item xs={12}>
                        <div style={{ display: "ruby" }}>
                            <h6 className="form-title">Confirmation Email</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                        </div>
                        <div className="components-div pb-3">
                            <div className="mt-3 mb-2">
                                <span>You can enter multiple emails here separated by commas</span>
                            </div>
                            <div className="row">
                                <div className="col-xl-1 mt-3 alignvh-center">
                                    <h6 className="form-title">To:</h6>
                                </div>
                                <div className="col-xl-11 mt-3">
                                    <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={toEmail} onChange={(e) => { setToEmail(e.target.value) }} />
                                </div>
                                <div className="col-xl-1 mt-1 alignvh-center"></div>
                                <div className="col-xl-11 mt-1">
                                    <div className="mt-2 text-danger" hidden={!requestSubmitted}>{carrierEmailToEmailValidationForm}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-1 mt-3 alignvh-center">
                                    <h6 className="form-title">Cc:</h6>
                                </div>
                                <div className="col-xl-11 mt-3">
                                    <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={ccEmail} onChange={(e) => { setCcEmail(e.target.value) }} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-1 mt-3 alignvh-center">
                                    <h6 className="form-title">Bcc:</h6>
                                </div>
                                <div className="col-xl-11 mt-3">
                                    <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={bccEmail} onChange={(e) => { setBccEmail(e.target.value) }} />
                                </div>
                            </div>
                            {(toEmail !== "" || ccEmail !== "" || bccEmail !== "") && (
                                <>
                                    <div className="row">
                                        <div className="col-xl-1 mt-3 alignvh-center">
                                            <h6 className="form-title">Subject:</h6>
                                        </div>
                                        <div className="col-xl-11 mt-3">
                                            <input autoComplete="new-password" className="text-input w-100" value={emailSubject} onChange={(e) => { setEmailSubject(e.target.value) }} />
                                        </div>
                                        <div className="col-xl-1 mt-1 alignvh-center"></div>
                                        <div className="col-xl-11 mt-1">
                                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{carrierEmailSubjectValidationForm}</div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-1 mt-3 alignvh-center">
                                            <h6 className="form-title">Body:</h6>
                                        </div>
                                        <div className="col-xl-11 mt-3">
                                            <textarea type="text" className="action-item-textarea w-100" value={emailBody} onChange={(e) => { setEmailBody(e.target.value) }} rows={4} />
                                        </div>
                                        <div className="col-xl-1 mt-1 alignvh-center"></div>
                                        <div className="col-xl-11 mt-1">
                                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{carrierEmailBodyValidationForm}</div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

export default AnalystCreateTransmissionProtocol;