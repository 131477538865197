import React from "react";
import { Button } from "@material-ui/core";

export function FailureModalWithAction(dialog, content) {
    dialog.openDialog(
        (content.type && content.type == "alert") ?
            (
                <div className="confirm modal-head yellow-secondary-color-warning-color2">
                    {content.title}
                </div>
            ) :
            (<div className="failure modal-head">
                {content.title}
            </div >)

        , <div className="failure modal-body-content" >
            {(content.type && content.type == "alert") ? <i className="fas fa-exclamation-triangle fa-4x yellow-secondary-color-warning-color2"></i> : <i className="fa fa-times-circle fa-4x"></i>}
            <br />
            <label className="black2 color mt-4 fs-12 alert-dialog-label">{content.body}</label>
        </div >,
        [
            { body: <Button className="blue-outlined-btn">{content.buttonTitle ?? "Close"}</Button>, onClick: (e) => { window.location.href = "/"; dialog.closeDialog() } },
        ]

    )
}


