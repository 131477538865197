import React from "react";
import logoSVG from "../../Images/imageLogo.svg";
import Button from "@material-ui/core/Button";
import { useLoader } from "../Layout/Loader";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import * as APIs from "../../Data/APIs";

function ChangePassword({ cognitoUser, setCognitoUser, ...props }) {

    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const [error, setError] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);
    const [userID, setUserID] = React.useState("");

    React.useEffect(() => {
        if (cognitoUser === undefined)
            window.location.href = "/";
        else return;
    }, [])

    const resetInput = React.useRef();

    React.useEffect(() => {
        resetInput.current.focus();
    }, [])

    React.useEffect(() => {
        if (confirmNewPassword !== newPassword) setError("Your password and confirmation password are not matched"); else setError("");
    }, [confirmNewPassword])

    React.useEffect(() => {
        APIs.getUserID({ Email: cognitoUser?.username }).then((r) => setUserID(r.CognitoID));
    }, [])

    return (
        <div id="change-pw">
            <div className="row">
                <div className="col-1 login-first-corner-div"></div>
                <div className="col-10 login-div"></div>
                <div className="col-1 login-last-corner-div"></div>
            </div>
            <div className="login-content-mfa row">
                <div className="col-6" style={{ display: "inline-flex", justifyContent: "center" }}>
                    <svg viewBox="0 0 73 14" width="60%">
                        <use xlinkHref={`${logoSVG}#logoSVG`}></use>
                    </svg>
                </div>
                <div className="col-6">
                    <div elevation={0} style={{ height: "100%", padding: "30px 85px" }}>
                        <SettingsBackupRestoreIcon style={{ fontSize: "70px", color: "var(--pink-primary-color)" }} />
                        <h4 className="mt-3 black1 color fw-700">Change PASSWORD</h4>

                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (newPassword !== confirmNewPassword) return;
                            openLoader();
                            Auth.completeNewPassword(cognitoUser, newPassword)
                                .then(r => {
                                    r.username = userID;
                                    r.challengeParam.USER_ID_FOR_SRP = userID;
                                    if (r.challengeName === "SMS_MFA")
                                        routeHistory.push("/MFAAuthentication");
                                    else window.location.reload();
                                })
                                .catch((error) => {
                                    setError(!error.message ? error : error.message);
                                })
                                .finally(() => closeLoader());
                        }}>
                            <div className="text-left">
                                <h5 className="color black2 mb-4 mt-5">Please change your password to a strong one for more security</h5>
                                <div className="textfield-div mt-2">{cognitoUser?.username}</div>
                                <div className="mt-4 w-100 alignvh-center position-relative">
                                    <input required ref={resetInput} autoComplete="new-password" type={showNewPassword ? "text" : "password"} className="text-input w-100" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                    <span style={{ right: "12px", position: "absolute", cursor: "pointer", top: "5px" }}>
                                        {showNewPassword ? <Visibility className="fs-18" onClick={() => setShowNewPassword(false)} /> : <VisibilityOff className="fs-18" onClick={() => setShowNewPassword(true)} />}
                                    </span>
                                </div>
                                <div className="mt-4 w-100 alignvh-center position-relative">
                                    <input required autoComplete="new-password" type={showConfirmNewPassword ? "text" : "password"} className="text-input w-100" placeholder="Confirm New Password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                    <span style={{ right: "12px", position: "absolute", cursor: "pointer", top: "5px" }}>
                                        {showConfirmNewPassword ? <Visibility className="fs-18" onClick={() => setShowConfirmNewPassword(false)} /> : <VisibilityOff className="fs-18" onClick={() => setShowConfirmNewPassword(true)} />}
                                    </span>
                                </div>
                                {error && error !== "" && (
                                    <div style={{ color: "red", textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                                        <label>Error: <span>{error}</span></label>
                                    </div>
                                )}
                            </div>

                            <Button type="submit" variant="contained" className="signin-btn">Change Password</Button>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ChangePassword;