import React from "react";

function FeedConnectivitiesList({ feedConnectivities, ...props }) {

    return (
        <div id="connectivity-group-requests-table">
            <div className="row table-header">
                <div className="col-1 table-cell first-cell">Connectivity Request ID</div>
                <div className="col-2 table-cell">Carrier Name</div>
                <div className="col-1 table-cell">Partner</div>
                <div className="col-1 table-cell">Group Request ID</div>
                <div className="col-1 table-cell">Client Number</div>
                <div className="col-2 table-cell">Client Name</div>
                <div className="col-2 table-cell">Assigned To</div>
                <div className="col-1 table-cell">Status</div>
                <div className="col-1 table-cell">Phase</div>
            </div>

            {feedConnectivities && feedConnectivities?.map((g, gi) => (
                <div key={`group-requests-list-${gi}`} className="row table-row">
                    <div className="col-1 table-cell first-cell">
                        <a className="link-style" href={`/ConnectivityRequests/ViewConnectivityRequest/${g?.ConnectivityRequestID}`}>{g?.ConnectivityRequestID}</a>
                    </div>
                    <div className="col-2 table-cell">
                        <a className="link-style" href={`/ConnectivityRequests/ViewConnectivityRequest/${g?.ConnectivityRequestID}`}>{g?.CarrierName === "Third Party" ? `${g?.CarrierName} (${g.ThirdPartyName})` : g?.CarrierName}</a>
                    </div>
                    <div className="col-1 table-cell">
                        {g?.PartnerName === 'Direct' ?
                            'Direct' :
                            g?.PartnerName ?
                                g?.PartnerName :
                                g?.IsPEO === 1 ?
                                    "PEO" :
                                    'Network'}
                    </div>
                    <div className="col-1 table-cell">
                        <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${g?.GroupRequestID}`}>{g?.GroupRequestID}</a>
                    </div>
                    <div className="col-1 table-cell">
                        <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${g?.GroupRequestID}`}>{g?.IsPEO === 1 ? "PEO" : g?.GroupNumber}</a>
                    </div>
                    <div className="col-2 table-cell">
                        <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${g?.GroupRequestID}`}>{g?.PeoName ? g?.PeoName : g?.GroupName}</a>
                    </div>
                    <div className="col-2 table-cell">
                        {g?.AssignedTo}
                    </div>
                    <div className="col-1 table-cell">
                        <div className={`status-div m-auto
                        ${g?.ConnectivityRequestStatusName === "In Progress" ? `inProgress` :
                                g?.ConnectivityRequestStatusName === "On Hold" ? `onHold` :
                                    g?.ConnectivityRequestStatusName === "Blocked" ? `blocked` :
                                        g?.ConnectivityRequestStatusName === "Cancelled" ? `cancelled` :
                                            g?.ConnectivityRequestStatusName === "Not Started" ? `notStarted` :
                                                g?.ConnectivityRequestStatusName === "Production" ? `production` : `productionDisabled`}`}>
                            {g?.ConnectivityRequestStatusName}
                        </div>
                    </div>
                    <div className="col-1 table-cell">
                        <div className={`phase-div m-auto
                        ${g?.ConnectivityRequestPhasesName === "Not Started" ? `notStarted` :
                                g?.ConnectivityRequestPhasesName === "Requirements Gathering" ? `requirementGathering` :
                                    g?.ConnectivityRequestPhasesName === "Obtain Customer Dataset" ? `obtainCustomerDataset` :
                                        g?.ConnectivityRequestPhasesName === "Resource Assignment" ? `resourceAssignment` :
                                            g?.ConnectivityRequestPhasesName === "Dataset Validation" ? `datasetValidation` :
                                                g?.ConnectivityRequestPhasesName === "Mapping" ? `mapping` :
                                                    g?.ConnectivityRequestPhasesName === "Testing" ? `testing` :
                                                        g?.ConnectivityRequestPhasesName === "Ready for Production" ? `readyforproduction` : `production`}`}>
                            {g?.ConnectivityRequestPhasesName}
                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
}

export default FeedConnectivitiesList;