import React from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Truncate from 'react-truncate';
import { Link } from "@material-ui/core";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Button from "@material-ui/core/Button";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { useCommentsModalDialog } from "../../../Layout/CommentsModalDialog";
import { Comments } from "../../Comments";
import ContentBody from "../../ContentBody";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useAuthentication } from "../../../../Data/Authentication";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";

function RelationshipCodesAnalystView({ onEdit, currentTimestamp, relationshipCodesSection, setIsNextActive, setIsSaveActive, ...props }) {

    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const commentsDialog = useCommentsModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [customerRelationshipCodes, setCustomerRelationshipCodes] = React.useState([]);
    const [carrierRelationshipCodes, setCarrierRelationshipCodes] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [index, setIndex] = React.useState(-1);

    const { authUser } = useAuthentication();
    const [checkIncludeDependents, setCheckIncludeDependents] = React.useState(relationshipCodesSection.IncludeDependents);
    const [relationshipArray, setRelationshipsArray] = React.useState([]);
    const [relationshipCodesSectionOriginalLength, setRelationshipCodesSectionOriginalLength] = React.useState();

    const _colorOptions = {
        primaryAnalystColor: "var(--yellow-secondary-color)",
        primaryAdminColor: "var(--purple)"
    };

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupRelationshipCodes(connectivityReq?.ConnectivityRequestID).then((r) => (
            setCustomerRelationshipCodes(r?.data?.map(s => s.Name)),
            closeLoader()
        ));
    }, [connectivityReq])

    React.useEffect(() => {
        setCarrierRelationshipCodes(relationshipCodesSection?.Model.map(s => s.CarrierRelationshipCode))
    }, [relationshipCodesSection])

    const handleFunction = (value) => {
        setIndex(value);
        const commentsBody = {
            Type: relationshipCodesSection.Type,
            GUID: relationshipCodesSection.Model[value]?.GUID
        }

        APIs.getPreMappingComments(commentsBody).then((r) => {
            setComments(r?.data); forceUpdate();
        })
    }

    React.useEffect(() => {
        if (index === -1) return;
        else { handleFunction(index); forceUpdate(); }
    }, [index])

    React.useEffect(() => {
        if (comments === undefined || index === -1) return;
        else { relationshipCodesSection.Model[index].Comment = comments[comments?.length - 1]; }
    }, [comments])


    const handleCheckIncludeDependentsChange = () => {
        setCheckIncludeDependents(!checkIncludeDependents);
        relationshipCodesSection.IncludeDependents = (!checkIncludeDependents);

        if (relationshipCodesSection.IncludeDependents === false) { relationshipArray.length = 0; relationshipCodesSection.Model.length = relationshipCodesSectionOriginalLength }
    };

    React.useEffect(() => {
        if (onEdit === true)
            relationshipCodesSection.Model.map(s => s.Action = 2)
        else return;
    }, [onEdit === true])

    React.useEffect(() => {
        let lastElement = relationshipArray[relationshipArray.length - 1];
        if (relationshipCodesSection && relationshipArray.length > 0) relationshipCodesSection.Model = [...relationshipCodesSection.Model, lastElement];
    }, [relationshipArray]);

    React.useEffect(() => {
        setRelationshipCodesSectionOriginalLength(relationshipCodesSection.Model?.length);
    }, [])

    React.useEffect(() => {
        const carrierRelationshipCodeCondition = relationshipArray.map(s => s.CarrierRelationshipCode).filter(f => f === "");
        const commentsCondition = relationshipArray.map(s => s.Comment.Text).filter(f => f === "");
        if (!relationshipCodesSection.IncludeDependents || (relationshipCodesSection.IncludeDependents && carrierRelationshipCodeCondition.length <= 0 && commentsCondition.length <= 0)) setIsNextActive(true); else setIsNextActive(false);
        if (relationshipArray.length === 0 ? setIsSaveActive(false) : (!relationshipCodesSection.IncludeDependents || (relationshipCodesSection.IncludeDependents && carrierRelationshipCodeCondition.length <= 0 && commentsCondition.length <= 0)) ? setIsSaveActive(true) : setIsSaveActive(false));
    }, [relationshipCodesSection.IncludeDependents, JSON.stringify(relationshipArray)])

    const handleDeleteRecord = (index, value) => {
        if (relationshipCodesSection.Model[index].Action === 2)
            relationshipCodesSection.Model.splice(index, 1);
        else {
            relationshipCodesSection.Model.splice(index, 1);
            relationshipArray.splice((relationshipArray.indexOf(value)), 1);
        }
        forceUpdate();
    }

    React.useEffect(() => {
        if (!onEdit) setRelationshipsArray([]);
    }, [onEdit])

    return (
        <div id="relationship-codes-section">
            {relationshipCodesSection.IncludeDependents && (
                <div className="row">
                    <div className="col-xl-6">
                        <div className="table-header row mr-1">
                            <div className="col-xl-12 table-cell first-cell">Carrier Relationship Codes</div>
                        </div>
                        <div className="table-row mr-1">
                            {relationshipCodesSection.Model.map((c, ci) => (
                                <div key={`relationship-codes-${ci}`} className="table-cell first-cell">
                                    <div className="col-xl-12">{c.CarrierRelationshipCode}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className="table-header row ml-1">
                            <div className="col-xl-12 table-cell first-cell">Group Relationship Codes</div>
                        </div>
                        <div className="table-row ml-1">
                            {customerRelationshipCodes.map((c, ci) => (
                                <div key={`customer-relationship-codes-${ci}`} className="table-cell first-cell">
                                    <div className="col-xl-12">{c}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            <div className="row alignvh-center mb-5 mt-5">
                <div className="col-xl-12">
                    {onEdit ? (
                        <FormControlLabel
                            className="dependents-switch"
                            control={
                                <Switch
                                    checked={relationshipCodesSection.IncludeDependents}
                                    onChange={handleCheckIncludeDependentsChange}
                                />
                            }
                            label="Include Dependents?"
                        />
                    ) : (
                        !relationshipCodesSection.IncludeDependents && (
                            <div className="no-data-alert">
                                <h6>This file will not include dependents</h6>
                            </div>
                        )
                    )}
                </div>
            </div>
            {relationshipCodesSection.IncludeDependents && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-header row">
                                <div className="col-xl-3 table-cell first-cell">Mapping Relationship Codes</div>
                                <div className="col-xl-9 table-cell">Comments</div>
                            </div>
                        </div>
                    </div>
                    <div className="table-subheader row">
                        <div className="col-xl-3 table-cell first-cell">Carrier Relationship Codes</div>
                        <div className="col-xl-1 table-cell">User</div>
                        {onEdit ? (
                            <>
                                <div className="col-xl-6 table-cell">Latest Comment</div>
                                <div className="col-xl-2 table-cell">Actions</div>
                            </>
                        ) : (
                            <>
                                <div className="col-xl-7 table-cell">Latest Comment</div>
                                <div className="col-xl-1 table-cell">Actions</div>
                            </>
                        )}
                    </div>
                    {relationshipCodesSection?.Model?.map((p, pi) => (
                        <div key={`relationship-section-${pi}`} className="table-row row">
                            <div className="col-xl-3 table-cell first-cell">
                                {p.CarrierRelationshipCode}
                            </div>
                            <div className="col-xl-1 table-cell">
                                <div className="userInfo">
                                    <CustomTooltip TransitionComponent={Zoom} title={<div><span>{p.Comment?.Name}</span><br /><span>{p.Comment?.Email}</span></div>}>
                                        <span className="user" style={{ backgroundColor: _colorOptions.primaryAnalystColor }}>{p.Comment?.Name.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                                    </CustomTooltip>
                                </div>
                            </div>
                            {onEdit ? (
                                <>
                                    <div className="col-xl-6 table-cell">
                                        <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog(<span>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                                            <span>{p.Comment?.Text}</span>
                                        </Truncate>
                                    </div>
                                    <div className="col-xl-2 table-cell">
                                        <div className="comments-section-single-column">
                                            <Button onClick={e => {
                                                handleFunction(pi); Comments(commentsDialog, {
                                                    title: p.CarrierRelationshipCode,
                                                    body: <ContentBody section={relationshipCodesSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                                });
                                            }}>
                                                <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                                            </Button>
                                            <CustomTooltip TransitionComponent={Zoom} title={relationshipCodesSection.Model.length === 1 || (relationshipCodesSection.Model.length === 1 && relationshipArray.length === 1) ? "There should be at least one matched relationship code" : ""}>
                                                <span>
                                                    <Button className="icon-btn" disabled={relationshipCodesSection.Model.length === 1 || (relationshipCodesSection.Model.length === 1 && relationshipArray.length === 1)} onClick={e => {
                                                        openModal(0, () => {
                                                            handleDeleteRecord(pi, p)
                                                        }, {
                                                            title: 'Please Confirm',
                                                            question: `Are you sure you want to delete this record?`,
                                                        });
                                                    }}>
                                                        {/* <DeleteForeverIcon style={{ width: "25px", height: "25px" }} color="action" /> */}
                                                        <svg  width="21" height="24" viewBox="0 0 21 24"   style={{ cursor: "pointer" }}>
                                                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                        </svg>
                                                    </Button>
                                                </span>
                                            </CustomTooltip>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-xl-7 table-cell">
                                        <div className="mt-1">
                                            <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog(<span>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                                                <span>{p.Comment?.Text}</span>
                                            </Truncate>
                                        </div>
                                    </div>
                                    <div className="col-xl-1 table-cell">
                                        <div className="comments-section-single-column">
                                            <Button onClick={e => {
                                                handleFunction(pi); Comments(commentsDialog, {
                                                    title: p.CarrierRelationshipCode,
                                                    body: <ContentBody section={relationshipCodesSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                                });
                                            }}>
                                                <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </>
            )}

            {onEdit && (
                <>
                    <div className="row mt-5 mb-5 w-100 alignvh-center">
                        <Button disabled={!relationshipCodesSection.IncludeDependents} variant="contained" className="blue-btn" onClick={() => setRelationshipsArray([...relationshipArray, {
                            GUID: uuidv4(),
                            CarrierRelationshipCode: "",
                            Action: 1,
                            Comment: {
                                Timestamp: currentTimestamp,
                                Text: "",
                                UserID: authUser?.attributes["sub"],
                                Name: `${authUser?.attributes["custom:FirstName"]} ${authUser?.attributes["custom:LastName"]}`,
                                Email: `${authUser?.attributes["email"]}`
                            }
                        }])}>
                            <label style={{ cursor: "pointer" }} className="mb-0">
                            <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                                            </svg>
                                 Add Relationship Code</label>
                        </Button>
                    </div>

                    {relationshipCodesSection.IncludeDependents && relationshipArray.length <= 0 && (
                        <div className="no-data-alert">
                            <h6>Please start adding relationship codes</h6>
                        </div>
                    )}

                    {relationshipCodesSection.IncludeDependents && relationshipArray.map((p, pi) => (
                        <div key={`generic-${p.GUID}`} className="row mb-2">
                            <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Carrier Relationship Code</h6>
                                <span className="text-danger mb-2 ml-2 mr-4">*</span>
                                <input value={p.CarrierRelationshipCode ?? ""} onChange={e => { p.CarrierRelationshipCode = e.target.value; forceUpdate(); }} type="text" className="text-input w-100" />
                            </div>
                            <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                <h6 className="form-title">Analyst Comment</h6>
                                <span className="text-danger mb-2 ml-2 mr-4">*</span>
                                <textarea value={p.Comment?.Text ?? ""} onChange={e => { p.Comment.Text = e.target.value; forceUpdate(); }} style={{ minWidth: "unset", width: "100%" }} rows={3} />
                            </div>
                            <div className="col-xl-1 alignvh-center">
                                <CustomTooltip TransitionComponent={Zoom} title={(relationshipCodesSection.Model.length === 1 && relationshipArray.length === 1) ? "There should be at least one matched relationship code" : ""}>
                                    <span>
                                        <Button disabled={relationshipCodesSection.Model.length === 1 && relationshipArray.length === 1} className="icon-btn" onClick={e => {
                                            openModal(0, () => {
                                                relationshipArray.splice(pi, 1);
                                                relationshipCodesSection.Model.splice((relationshipCodesSection.Model.indexOf(p)), 1);
                                                forceUpdate();
                                            }, {
                                                title: 'Please Confirm',
                                                question: `Are you sure you want to delete this record?`,
                                            });
                                        }}>
                                            {/* <DeleteForeverIcon style={{ width: "30px", height: "30px" }} color="action" /> */}
                                            <svg  width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                            </svg>
                                        </Button>
                                    </span>
                                </CustomTooltip>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div >
    )
}

export default RelationshipCodesAnalystView;