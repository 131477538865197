import React from "react";
import { Divider, Paper, Button, Select, FormControl, MenuItem } from "@material-ui/core";
import PreMappingStepperAdminView from './PreMappingStepperAdminView';
import { useAuthentication } from "../../../../Data/Authentication";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import EditRequestSVG from "../../../../Images/editRequest.svg";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import editRequestIcon from "../../../../Icons/editIcon.svg";

function PreMappingFormAdminView() {

    const { authUser } = useAuthentication();
    const { openLoader, closeLoader } = useLoader();
    const { connectivityRequestIDURL } = useParams();
    const [preMappingObject, setPreMappingObject] = React.useState();
    const [preMappingBasicData, setPreMappingObjectBasicData] = React.useState();
    const [preMappingSections, setPreMappingSections] = React.useState([]);
    const [onEdit, setOnEdit] = React.useState(false);

    const [usersList, setUsersList] = React.useState([]);
    const [primaryAdmin, setPrimaryAdmin] = React.useState("");
    const [secondaryAdmin, setSecondaryAdmin] = React.useState("");
    const [primaryAdminValidation, setPrimaryAdminValidation] = React.useState("");
    const [preMapSubmitted, setPreMapSubmitted] = React.useState(false);
    const [isSaveActive, setIsSaveActive] = React.useState(false);
    React.useEffect(() => {
        if (!primaryAdmin) setPrimaryAdminValidation("Please select a primary Admin for this Pre-Mapping form."); else setPrimaryAdminValidation("");
    }, [primaryAdmin, primaryAdminValidation])

    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getPreMapping(connectivityRequestIDURL).then((r) => {
            setPreMappingObject(r?.data);
            setPreMappingObjectBasicData(r?.data?.PreMappingBasicData);
            setPreMappingSections(r?.data?.Sections);
            closeLoader();
        });
    }, [])

    const handlePrimaryAdminChange = (e, newValue) => {
        setPrimaryAdmin(e.target.value);
        setIsSaveActive(true);
    }

    const handleSecondaryAdminChange = (e, newValue) => {
        setSecondaryAdmin(e.target.value);
        setIsSaveActive(true);
    }

    React.useEffect(() => {
        setPrimaryAdmin(...usersList.filter(s => `${s.FirstName} ${s.LastName}` === preMappingBasicData?.Admins[0]?.Name));
        setSecondaryAdmin(...usersList.filter(s => `${s.FirstName} ${s.LastName}` === preMappingBasicData?.Admins[1]?.Name));
    }, [onEdit === true])

    return (
        <div id="pre-mapping-form">
            <BreadcrumbSteps
                breadcrumbTitle={`Connectivity Request #${connectivityRequestIDURL}`}
                isCurrent={false}
                breadcrumbTitleCurrent="View Pre-Mapping Form"
                breadcrumbLink={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`}
            />
            <Paper elevation={0} className="p-5 mb-3 mt-3">
                <div className="row w-100 alignvh-center">
                    <h5 className="black1 fw-700">Pre-Mapping Form</h5>
                    <div className="ml-auto">
                        <Button variant="contained" className="blue-outlined-btn" onClick={() => setOnEdit(true)}>
                        <svg width="20" height="24" viewBox="0 0 25 24">
                                                <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                            </svg>
                            <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Form</label>
                        </Button>
                    </div>
                </div>
                <Divider className="mt-3 mb-5" />
                <div className="row">
                    <div className="col-xl-2 alignvh-center">
                        <h6 className="form-title">Client Name</h6>
                    </div>
                    <div className="col-xl-10">
                        <div className="textfield-div">
                            {preMappingBasicData?.Peo?.PeoName ? preMappingBasicData?.Peo?.PeoName : preMappingBasicData?.GroupName}
                        </div>
                    </div>

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Carrier Name</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <div className="textfield-div">
                            {preMappingBasicData?.CarrierName}
                        </div>
                    </div>

                    {preMappingBasicData?.Admins && preMappingBasicData?.Admins[0] && preMappingBasicData?.Admins[0].Name !== "undefined undefined" && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Primary Admin</h6>
                                <span className="text-danger mb-2 ml-2">*</span>
                            </div>
                            <div className="col-xl-10 mt-3">
                                {onEdit ? (
                                    <FormControl label="Select primary admin" focused={false} variant="outlined" className="w-100">
                                        <Select
                                            className="text-dropdown"
                                            value={primaryAdmin ?? ""}
                                            onChange={handlePrimaryAdminChange}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {usersList?.map((c, ci) => (
                                                <MenuItem key={`users-list-${ci}`} value={c}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <div className="textfield-div">
                                        {preMappingBasicData?.Admins[0]?.Name}
                                    </div>
                                )}
                            </div>

                            {onEdit && (
                                <>
                                    <div className="col-xl-2 alignvh-center" hidden={!preMapSubmitted}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!preMapSubmitted}>{primaryAdminValidation}</div>
                                </>
                            )}

                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Primary Admin Email</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                {onEdit ? (
                                    <div className="textfield-div">
                                        {primaryAdmin?.Email ?? ""}
                                    </div>
                                ) : (
                                    <div className="textfield-div">
                                        {preMappingBasicData?.Admins[0]?.Email}
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {preMappingBasicData?.Admins && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Admin</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                {onEdit ? (
                                    <FormControl label="Select secondary admin" focused={false} variant="outlined" className="w-100">
                                        <Select
                                            className="text-dropdown"
                                            value={secondaryAdmin ?? ""}
                                            onChange={handleSecondaryAdminChange}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {usersList?.map((c, ci) => (
                                                <MenuItem key={`users-list-${ci}`} value={c}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <div className="textfield-div">
                                        {preMappingBasicData?.Admins[1]?.Name === "undefined undefined" ? "" : preMappingBasicData?.Admins[1]?.Name}
                                    </div>
                                )}
                            </div>

                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Admin Email</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                {onEdit ? (
                                    <div className="textfield-div">
                                        {secondaryAdmin?.Email ?? ""}
                                    </div>
                                ) : (
                                    <div className="textfield-div">
                                        {preMappingBasicData?.Admins[1]?.Email}
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Primary Analyst</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <div className="textfield-div">
                            {preMappingBasicData?.Analysts[0]?.Name}
                        </div>
                    </div>

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Primary Analyst Email</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <div className="textfield-div">
                            {preMappingBasicData?.Analysts[0]?.Email}
                        </div>
                    </div>

                    {preMappingBasicData?.Analysts[1]?.Name !== "undefined undefined" && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Analyst</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                <div className="textfield-div">
                                    {preMappingBasicData?.Analysts[1]?.Name}
                                </div>
                            </div>

                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Analyst Email</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                <div className="textfield-div">
                                    {preMappingBasicData?.Analysts[1]?.Email}
                                </div>
                            </div>
                        </>
                    )}

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Submission Date</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <div className="textfield-div">
                            {Intl.DateTimeFormat('en-US').format(preMappingObject?.SubmissionDate)}
                        </div>
                    </div>
                </div>
                <PreMappingStepperAdminView primaryAdmin={primaryAdmin} secondaryAdmin={secondaryAdmin} onPreMapSubmitted={s => setPreMapSubmitted(s)} preMappingObject={preMappingObject} preMappingBasicData={preMappingBasicData} preMappingSections={preMappingSections} onEdit={onEdit} setOnEdit={setOnEdit} isSaveActive={isSaveActive} setIsSaveActive={(s) => setIsSaveActive(s)} />
            </Paper>
        </div>
    )
}

export default PreMappingFormAdminView;