import React from "react";
import * as APIs from "../../Data/APIs";
import NearMeIcon from '@material-ui/icons/NearMe';
import { Button } from "@material-ui/core";
import { useLoader } from "../Layout/Loader";
import { useAuthentication } from "../../Data/Authentication";
import Moment from 'moment';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';

function ContentBody({
    section,
    index,
    colorOptions,
    connectivityRequestIDURL,
    onComments,
    ...props
}) {

    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [comments, setComments] = React.useState([]);
    const { authUser } = useAuthentication();
    const { openLoader, closeLoader } = useLoader();
    const [preMappingObject, setPreMappingObject] = React.useState();
    let currentTimestamp = new Date().getTime();
    const [commentText, setCommentText] = React.useState("");

    React.useEffect(() => {
        const commentsBody = {
            Type: section.Type,
            GUID: section.Model[index].GUID
        }

        APIs.getPreMappingComments(commentsBody).then((r) => {
            setComments(r?.data); onComments(r?.data); forceUpdate();
        })
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getPreMapping(connectivityRequestIDURL).then((r) => {
            setPreMappingObject(r?.data);
            closeLoader();
        });
    }, [])

    const handleCommentTextChange = (e) => {
        setCommentText(e.target.value);
    }

    const submitAddComment = () => {
        if (commentText === "") return;
        const addCommentBody = {
            Type: section?.Type,
            GUID: preMappingObject?.GUID,
            Index: section?.Index,
            ModelGUID: section.Model[index].GUID,
            Comment: {
                Timestamp: currentTimestamp,
                Text: commentText,
                UserID: authUser?.attributes["sub"],
                Name: `${authUser?.attributes["custom:FirstName"]} ${authUser?.attributes["custom:LastName"]}`,
                Email: `${authUser?.attributes["email"]}`
            }
        }
        APIs.getPreMappingAddComment(addCommentBody).then((r) => {
            setCommentText("");
            const commentsBody = {
                Type: section.Type,
                GUID: section.Model[index].GUID
            }

            APIs.getPreMappingComments(commentsBody).then((r) => {
                setComments(r?.data); onComments(r?.data); forceUpdate();
            })
        });
    }

    return (
        <div>
            <div className="comments-thread">
                {comments?.map((p, pi) => (
                    <div className="mt-3" key={`single-match-${pi}`}>
                        <div className="mb-4">
                            <div className="userInfo alignvh-center row">
                                <div>
                                    <CustomTooltip TransitionComponent={Zoom} title={<div><span>{p?.Name}</span><br /><span>{p?.Email}</span></div>}>
                                        <span className="user" style={{ backgroundColor: colorOptions.primaryAnalystColor }}>{p.Name.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                                    </CustomTooltip>
                                </div>
                                <div className="ml-2">
                                    <span className="fw-700">{p.Name}</span>
                                    <br />
                                    <span style={{ color: "var(--black3)" }}>{Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }).format(p.Timestamp)}</span>
                                </div>
                            </div>
                            <div className="mt-3 row">
                                <span style={{ whiteSpace: "break-spaces" }}>{p.Text}</span>
                            </div>
                        </div>
                    </div>
                )).reverse()}
            </div>
            <div className="row comments-modal-footer alignvh-center">
                <div className="col-11">
                    <textarea placeholder="Please add your comment here..." type="text" className="text-input w-100" value={commentText} rows={2} onChange={handleCommentTextChange} />
                </div>
                <div className="col-1">
                    <Button onClick={submitAddComment} style={{ marginLeft: "-10px" }}>
                        <NearMeIcon color="action" style={{ transform: "rotate(45deg)", fontSize: "40px" }} />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ContentBody;