import React, { createRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';
import * as Apis from '../../../../Data/APIs'
import { useLoader } from '../../../Layout/Loader';
import ClientFormsPendingEmployeeSignatureTabSingleFeedWithFormsSingleFormRow from './ClientFormsPendingEmployeeSignatureTabSingleFeedWithFormsSingleFormRow';
import { Button } from "@material-ui/core";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { FailureModal } from "../../../Modals/FailureModal";
import { useAuthentication } from '../../../../Data/Authentication';
import Moment from 'moment';
import { toESTTime } from '../../../../_helper'
import { Divider, Zoom } from '@mui/material';
import ChangedDetails from '../../ChangedDetails';
import viewActionItemSVG from "../../../../Icons/viewActionItem.svg";
import UploadWetSignatureInput from '../../UploadWetSignatureInput';
import EmployeeDashboardPendingFormsTabRejectionModal from '../../../EmployeeViews/EmployeeDashboardPendingFormsTabRejectionModal';
import { ConfirmSignFormModal, EmployeeForm } from '../../../EmployeeViews/EmployeeSignFormModal';
import { CustomTooltip } from '../../../Layout/Tooltip';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import editPenIcon from "../../../../Icons/editPen.svg";
import GenericTable from '../../../Layout/GenericTable';



const ClientFormsPendingEmployeeSignatureTabSingleFeedWithForms = ({ feed, ...props }) => {
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const dialog1 = useModalDialog(1);
    const dialog2 = useModalDialog(2);
    const dialog3 = useModalDialog(3);


    const signaturesRef = createRef();
    const formRef = createRef();
    const pdfRef = createRef();

    const [expanded, setexpanded] = useState(false);

    const [forms, setForms] = useState([]);
    const [lastEvaluatedKey, setlastEvaluatedKey] = useState(null);

    const [selfForms, setSelfForms] = useState([]);
    const [SelfLastEvaluatedKey, setSelfLastEvaluatedKey] = useState(null);

    const [dfElements, setDfElements] = useState([])


    const { authUser } = useAuthentication();
    let userLegalID = authUser?.attributes["custom:LegalID"]
    let userAccountID = authUser?.attributes["custom:AccountID"]
    let userClientID = authUser?.attributes["custom:ClientID"]

    // formsType ==> all , self , other
    let getFormsPerFeed = (FeedID, formsType, withReset, withResetSelf) => {
        openLoader();
        let currentFeedInfo = {
            //lsi 5 >> Transmissions Tab
            lsi: '15',
            roleID: "6",
            feedID: FeedID,
            userClientID: userClientID ?? '',
            userAccountID: userAccountID ?? '',
            userLegalID: userLegalID ?? ''
        };
        if ((formsType == 'all' || formsType == 'other') && lastEvaluatedKey && !withReset) currentFeedInfo.LastEvaluatedKey = lastEvaluatedKey
        if ((formsType == 'all' || formsType == 'self') && SelfLastEvaluatedKey && !withResetSelf) currentFeedInfo.SelfLastEvaluatedKey = SelfLastEvaluatedKey
        Apis.getEmployeeForms({ ...currentFeedInfo }).then(r => {
            const { selfData, clientData } = r;
            // self Data
            if (selfData.msg == "Success") {
                setDfElements(r.selfData.DFElements)
                if (withResetSelf)
                    setSelfForms(selfData?.data);
                else
                    setSelfForms([...selfForms, ...selfData?.data]);

                setSelfLastEvaluatedKey(selfData?.SelfLastEvaluatedKey ? selfData?.SelfLastEvaluatedKey : null)
            } else setSelfForms([])

            // all Data
            if (clientData.msg == "Success") {
                if (!dfElements) {
                    setDfElements(r.clientData.DFElements)
                }
                let notSelfForms = clientData?.data.filter(f => f.selfFlag !== "1")
                if (withReset)
                    setForms(notSelfForms);
                else
                    setForms([...forms, ...notSelfForms]);
                setlastEvaluatedKey(clientData?.LastEvaluatedKey ? clientData?.LastEvaluatedKey : null)
            } else setForms([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }

    let handleExpandFeed = (FeedID) => {
        if (!expanded) {
            getFormsPerFeed(FeedID, 'all', true, true)
        }
        setexpanded(prev => !prev);
    }

    // Process Feed
    // process Feed and get raw data 
    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content, 'lineHeight-1-6em');
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }
    const getTestFile = (testOnly) => {
        // testOnly =>>will be 1 or 0 , 1 = stops on parser step only
        openLoader();
        Apis.getTestFile({ feedId: feed?.FeedID, testOnly }).then((r) => {
            if (r?.statusCode === 200) {
                SuccessModal(dialog, {
                    title: 'Success!',
                    body: `Your request has been processed successfully!`,
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: `The request has not been processed successfully. Please try again!`,
                });
            }
            closeLoader();
        })
    }
    const getTestFileConfirmation = () => {
        // if (feed?.StructureTypeID === 3) {
        //     dialog.openDialog(
        //         <Set401kProcessFeedDate feed={feed} feedIDURL={feedIDURL} />
        //     )
        // }
        openModal(0, () => getTestFile(0), {
            title: 'Please Confirm',
            question: `Generate Forms will clear all the Forms that are still Pending an action <br/> Please make sure all Pending Forms are transmitted or rejected before generating new forms`,
        })
    }

    // =============================================================================NEW TABLE UI
    let userHasElectronicSignature = authUser?.attributes["custom:ElectronicSignature"] == "Accepted";

    const handleViewFn = (form) =>
        dialog2.openDialog(
            <></>,
            <>
                {/* will submit rejection reason in this Component */}
                <EmployeeForm
                    form={form}
                    signaturesRef={signaturesRef}
                    formRef={formRef}
                    pdfRef={pdfRef}
                    canSign={false}
                    mainDialog={dialog2}
                />
            </>,
            [
                // { body: <a href={form?.outputFile} target="_blank" className="btn black-btn text-uppercase">download</a>, onClick: () => { dialog2.closeDialog() } },
                // { body: <Button type="button" className="black-outlined-btn">close</Button>, onClick: () => { dialog2.closeDialog() } }
            ]


        )

    const HandleSignCore = (form, withSelfFlag, spouseFlag = false) => {
        openLoader();
        const { empDOB, empKey, empName, empSSN, feedID, executionID, SK, outputFile, isBatch } = form

        let employeeObiect = {}
        employeeObiect.empDOB = empDOB || ''
        employeeObiect.empKey = empKey || ''
        employeeObiect.empName = empName || ''
        employeeObiect.empSSN = empSSN || ''
        employeeObiect.feedID = feedID || ''
        employeeObiect.executionID = executionID || ''
        employeeObiect.SK = SK || ''
        employeeObiect.outputFile = outputFile || ''
        if (spouseFlag) employeeObiect.depSignature = '1'
        if (isBatch) employeeObiect.isBatch = isBatch.toUpperCase() === "TRUE" ? true : false


        let bodyObject = {
            empArray: [{ ...employeeObiect }]
        }

        Apis.transmitEmpoleyeesPendingApproval(bodyObject)
            .then(r => {
                getFormsPerFeed(feedID, withSelfFlag ? 'self' : 'other', true, true)
            })
            .catch((error) => {
                console.log('Sign Form Error:', error);
            }).finally((t) => {
                dialog.closeDialog()
                // close wet Signature dialog
                dialog3.closeDialog();
                closeLoader()
            });
    }

    let getGeneratedEmployeeFormHandler = async (form, withSelfFlag) => {
        openLoader();
        const forms = await Apis.getGeneratedEmployeeForms(form.FeedID, form?.IsolvedEmployeeID);
        const generatedForm = await Apis.getGeneratedEmployeeForm(forms[0].PK, forms[0].SK);
        closeLoader();
        ConfirmSignFormModal(
            dialog1,
            form,
            (spouseFlag) => {
                HandleSignCore(form, withSelfFlag, spouseFlag);
            },
            'Employee',
            // canEdit (Fields)
            false,
            openLoader,
            closeLoader,
            generatedForm
        )
    }



    const handleSignFn = (form, withSelfFlag) => ConfirmModal(
        dialog,
        () => {
            getGeneratedEmployeeFormHandler(form, withSelfFlag)
        },
        {
            title: "Please Confirm",
            question: `<div class="text-center client-form-sign-confirmation-modal">I have agreed to the Terms of Use and Consent to Electronic Signature. I certify the terms and conditions of the agreement are still in effect. I have reviewed the content of the enrollment form and it is true and complete to the best of my knowledge. By selecting ‘Sign’ below, I acknowledge I am electronically signing the enrollment form.</div>`,
        }, "", { yes: "Sign", no: "Cancel" });

    const handleWetSignatureUploader = (form) => dialog3.openDialog('Upload File', <UploadWetSignatureInput form={form} dialog={dialog3} HandleSignCore={HandleSignCore} />)


    const handleRejctFn = (form, withSelfFlag) => {
        dialog.openDialog(
            <div>
                <h5 className="black1 fw-700 text-left mb-0 fs-18">Rejection Reason</h5>
            </div>,
            <>
                {/* will submit rejection reason in this Component */}
                <EmployeeDashboardPendingFormsTabRejectionModal
                    form={form}
                    dialog={dialog}
                    regetFormsHandler={() =>
                        getFormsPerFeed(form?.feedID, withSelfFlag ? 'self' : 'other', true, true)}
                />
            </>

        )
    }
    const columnBaseArrayWithSelfFlag = (withSelfFlag) => {
        const columnBaseArray = [
            {
                name: 'Employee Name',
                sortable: false,
                id: 'empName',
                selector: form => form?.empName
            },
            {
                name: 'Employee Name',
                sortable: false,
                id: 'carrierName',
                selector: form => form?.carrierName,
            },
            {
                name: 'Form Type',
                sortable: false,
                id: 'formType',
                selector: form => form?.formType?.replace('Enrolment', 'Enrollment')
            },
            {
                name: 'Generation Timestamp',
                sortable: false,
                id: 'formGenerationTime',
                selector: form => form?.formGenerationTime ? Moment(toESTTime(form?.formGenerationTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A",
            },
            {
                name: 'Generation Reason',
                sortable: false,
                id: 'formGenerationTime',
                selector: form => <button className="btn-like-link fs-13 link-style" style={{ color: "#2c87f0" }} onClick={(e) => {
                    e.stopPropagation(); e.preventDefault(); dialog.openDialog("Changed Fields:",
                        <div id="last-sent-file-modal">
                            {/* <h5 className="black1 fw-700">Changed Fields:</h5>
                            <Divider /> */}
                            <ChangedDetails dsxUrl={form?.trackedFile} dfElements={dfElements} />
                        </div>, []
                    )
                }}>
                    Change Details
                </button>
            },
            {
                name: 'Actions',
                sortable: false,
                id: 'Actions',
                selector: form => <div className="col d-flex flex-column">
                    <div className="d-flex my-1" role="button" onClick={() => handleViewFn(form)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" >
                            <use xlinkHref={`${viewActionItemSVG}#viewAIIcon`}></use>
                        </svg>
                        <span className="ml-2">View</span>
                    </div>
                    {/* form?.selfFlag && form?.selfFlag == "1" && */}
                    {withSelfFlag &&
                        <>
                            {userHasElectronicSignature ?
                                <>
                                    {form?.isWetSignature ?
                                        <CustomTooltip TransitionComponent={Zoom}
                                            title='Upload Signed Form'>
                                            <div className="d-flex my-1" role="button" onClick={() => handleWetSignatureUploader(form)}>
                                                <FileUploadIcon /><span className="ml-2">Upload</span>
                                            </div>
                                        </CustomTooltip> :
                                        <div className="d-flex my-1" role="button" onClick={() => handleSignFn(form, withSelfFlag)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" >
                                                <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                                            </svg>
                                            <span className="ml-2">Sign</span>
                                        </div>
                                    }
                                </>
                                :
                                <CustomTooltip TransitionComponent={Zoom}
                                    title='Upload Signed Form'>
                                    <div className="d-flex my-1" role="button" onClick={() => handleWetSignatureUploader(form)}>
                                        <FileUploadIcon /><span className="ml-2">Upload</span>
                                    </div>
                                </CustomTooltip>
                            }
                            <div className="d-flex my-1" role="button" onClick={() => handleRejctFn(form, withSelfFlag)}>
                                <HighlightOffIcon /><span className="ml-2">Reject</span>
                            </div>
                        </>
                    }
                </div>
            },

        ]

        return columnBaseArray;
    }

    return (
        <>
            <div className="w-100 d-flex mt-1 py-2">
                <div className="" role="button" onClick={() => handleExpandFeed(feed?.FeedID)}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <p className="">{feed?.CarrierName ? feed?.CarrierName : 'Carrier Name'} {
                    feed?.FeedFormMainTypes?.length > 0 && <> - ({feed?.FeedFormMainTypes?.map((el, elIndex) =>
                        <React.Fragment key={`form-types-${elIndex}`}>
                            {el} {elIndex !== feed?.FeedFormMainTypes?.length - 1 && ', '}
                        </React.Fragment>
                    )})
                    </>
                }</p>
                <Button variant="contained" className="blue-outlined-btn ml-auto" hidden disabled={!feed} style={{ whiteSpace: "nowrap" }} onClick={getTestFileConfirmation}>
                    <label style={{ cursor: "pointer" }} className="mb-0" hidden>Generate Forms</label>
                </Button>
            </div>
            {expanded && <>

                {selfForms ?
                    <>
                        <p className='mt-3'>My Forms</p>
                        <GenericTable
                            columns={[...columnBaseArrayWithSelfFlag(true)]}
                            data={[...selfForms]}
                            onSort={() => { }}
                        />
                    </>
                    : <></>
                }
                {/* ALL Forms */}
                {forms ? <>
                    <p className='mt-3'>All Forms</p>

                    <GenericTable
                        columns={[...columnBaseArrayWithSelfFlag(false)]}
                        data={[...forms]}
                        onSort={() => { }}
                    />
                    <div className="text-center mt-3">
                        <Button className="load-more-btn blue-btn" disabled={lastEvaluatedKey == null} onClick={() => getFormsPerFeed(feed?.FeedID, 'other', false, true)}>Load More Results</Button>
                    </div>

                </>
                    : <></>
                }
            </>
            }

        </>
    )
}
export default ClientFormsPendingEmployeeSignatureTabSingleFeedWithForms
