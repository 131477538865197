import React from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GroupConnectivityRequestsTable from "./GroupConnectivityRequestsTable";
import { Divider } from "@material-ui/core";
import { Zoom, } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import CancelConnectivity from "../ViewEditGroupRequest/CancelConnectivity";
import { useHistory } from "react-router-dom";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { SuccessModal } from "../../Modals/SuccessModal";
import { FailureModal } from "../../Modals/FailureModal";
import { AlertModal } from "../../Modals/AlertModal";
import { useAuthentication } from "../../../Data/Authentication";
import { useLoader } from "../../Layout/Loader";
import { CustomTooltip } from "../../Layout/Tooltip";

function ConnectivityRequests({ groupReq, setGroupReq, setSelectedSubgroupsView, submitAddedConnectRequest, onEditRequest, setOnEditRequest, groupRequestID, connectivities, ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [expanded, setExpanded] = React.useState("connectivityRequest");

    const [addConnectivityClicked, setAddConnectivityClicked] = React.useState(false);
    const [isLastActive, setIsLastActive] = React.useState(false);
    const [cancelledConnectivities, setCancelledConnectivities] = React.useState([]);

    const cancelConnectivityModal = useModalDialog(0);
    const routeHistory = useHistory();
    let checkStatus = connectivities?.filter((c) => (c.ConnectivityRequestStatusID !== 7 && c.ConnectivityRequestType !== "Forms")).length > 0;
    let checkForms = connectivities?.filter((c) => (c.ConnectivityRequestType === "Forms")).length > 0;
    let isDisableAddOE = connectivities?.filter((c) => (c.ConnectivityRequestType === "Forms" || c.ConnectivityRequestStatusID !== 7)).length === connectivities?.length;

    React.useEffect(() => {
        const cancelledConnectivitiesArray = connectivities?.filter(f => f.ConnectivityRequestStatusName === "Cancelled");
        setCancelledConnectivities(cancelledConnectivitiesArray);
        if (connectivities?.length - cancelledConnectivitiesArray?.length === 1)
            setIsLastActive(true);
        else setIsLastActive(false);
    }, [connectivities])


    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            case 3:
                return AlertModal(dialog, handleFunction, content);
            default:
                break;
        }
    }
    const getTitleOEButton = () => {

        if (checkStatus && checkForms) {
            return <div>You can add OE Requests only for the production EDI connectivity requests</div>;
        } else if (checkStatus) {
            return <div> You can add  OE Requests only for the production connectivity requests</div>;
        }
        else if (checkForms) {
            return <div>You can not add  OE Requests for forms connectivity requests</div>;
        } else {
            return "";
        }

    }

    return (
        <>
        {onEditRequest && (
            <div className="d-flex ml-auto" style={{justifyContent:'flex-end'}} >
                {(userActions?.includes("9") ||
                    userActions?.includes("20")) && (
                        <Button variant="contained" className="blue-btn mr-2" onClick={() => routeHistory.push(`/ConnectivityRequests/CreateConnectivityRequest/${groupReq?.groupRequest?.GroupRequestID}`)}>
                            <label style={{ cursor: "pointer" }} className="mb-0">Add Connectivity</label>
                        </Button>
                    )
                }
                {(userActions?.includes("11") || userActions?.includes("20")) && (
                    <Button variant="contained" className="blue-outlined-btn" onClick={isLastActive ? (() => {
                        openModal(3, () =>
                            cancelConnectivityModal.openDialog(<CancelConnectivity cancelledConnectivities={cancelledConnectivities} groupReq={groupReq} connectivities={connectivities} openLoader={openLoader} />), {
                            title: 'Alert! Only one active connectivity left!',
                            info: 'If all connectivities are cancelled, the current group request will be cancelled too.',
                            question: `Are you sure you want to proceed?`,
                        })
                    }) : (
                        () =>
                            cancelConnectivityModal.openDialog("Connectivity Requests",<CancelConnectivity cancelledConnectivities={cancelledConnectivities} groupReq={groupReq} connectivities={connectivities} openLoader={openLoader} />,[])
                    )}>
                        <label style={{ cursor: "pointer" }} className="mb-0">Cancel Connectivity</label>
                    </Button>
                )
                }

                <CustomTooltip TransitionComponent={Zoom} title={isDisableAddOE ? getTitleOEButton() : ""}>
                    <span>
                        <Button disabled={isDisableAddOE} variant="contained" className="blue-btn ml-2" onClick={() => routeHistory.push(`/OERequests/CreateOERequests/${groupReq?.groupRequest?.GroupRequestID}`)}>
                            <label style={{ cursor: "pointer" }} className="mb-0">Add OE Request</label>

                        </Button>
                    </span>
                </CustomTooltip>

            </div>
        )
        }
        <Accordion className="connectivity-req-acc" expanded={expanded === `connectivityRequest`}>
            <AccordionSummary
                onClick={() => expanded === `connectivityRequest` ? setExpanded("") : setExpanded("connectivityRequest")}
                className="pl-0 pr-0"
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="expand-connectivity-requests"
            >
                <span className="ml-2">Connectivity Requests</span>
            </AccordionSummary>
            {/* <Divider className="mb-5" /> */}
            
            <AccordionDetails>
                <GroupConnectivityRequestsTable groupReq={groupReq} setGroupReq={setGroupReq} setSelectedSubgroupsView={setSelectedSubgroupsView} addConnectivityClicked={addConnectivityClicked} setAddConnectivityClicked={setAddConnectivityClicked} connectivities={connectivities} />
            </AccordionDetails>
        </Accordion >
        </>
    )
}

export default ConnectivityRequests;