import React, { useState } from "react";
import logoSVG from "../../Images/imageLogo.svg";
import LoginImageSVG from "../../Images/loginImage.svg";
import LoginSignInImageSVG from "../../Images/loginSignInImage.svg";
import { FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, Link, Paper } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import LockIcon from '@material-ui/icons/Lock';
import { useLoader } from "../Layout/Loader";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

function ForgotPasswordMidLayer({ onCognitoUser, email, setEmail, ...props }) {

    const { openLoader, closeLoader } = useLoader();
    const [error, setError] = React.useState("");
    const routeHistory = useHistory();

    const emailInput = React.useRef();

    React.useEffect(() => {
        emailInput.current.focus();
    }, [])

    React.useEffect(() => {
        if (email) setError(""); else setError("Email Address cannot be empty");
    }, [email])

    return (
        <div id="login">
            <div className="row">
                <div className="col-1 login-first-corner-div"></div>
                <div className="col-10 login-div"></div>
                <div className="col-1 login-last-corner-div"></div>
            </div>
            <div className="login-content row">
                <div className="col-6" style={{ display: "inline-flex", justifyContent: "center" }}>
                    <svg viewBox="0 0 73 14" width="60%">
                        <use xlinkHref={`${logoSVG}#logoSVG`}></use>
                    </svg>
                </div>
                <div className="col-6">
                    <div elevation={0} style={{ height: "100%", padding: "30px 100px" }}>
                        <SettingsBackupRestoreIcon style={{ fontSize: "70px", color: "var(--pink-primary-color)" }} />
                        <h4 className="mt-3 black1 color fw-700">RESET PASSWORD ASSISTANT</h4>
                        <form autoComplete="new-password" onSubmit={(e) => {
                            e.preventDefault();
                            Auth.forgotPassword(email)
                                .then((r) => {
                                    onCognitoUser(r);
                                    routeHistory.push("/ResetPassword/Verify");
                                    closeLoader();
                                })
                                .catch((error) => {
                                    setError(!error.message ? error : error.message);
                                });
                        }}>
                            <div className="text-left">
                                <h5 className="color black2 mb-4 mt-5">{`Please enter the email address associated to isolved Account`}</h5>
                            </div>
                            <input autoComplete="new-password" ref={emailInput} type="text" required className="mt-4 text-input w-100" placeholder="Email Address" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {error && error !== "" && (
                                <div className="mt-2" style={{ color: "red", textAlign: "left", fontSize: "14px" }}>
                                    <label>Error: <span>{error}</span></label>
                                </div>
                            )}
                            <Button type="submit" variant="contained" className="signin-btn">Verify</Button>
                        </form>
                        {/* <div className="mt-3">
                            <label className="mt-3 grey2 color"><LockIcon className="mr-2" />Your Info is safely secured</label>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ForgotPasswordMidLayer;