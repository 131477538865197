import React from "react"
import { Divider, Paper, Button } from "@material-ui/core"
import { useModalDialog } from "../Layout/ModalDialogCustomized"

function RejectionReason({
    modalTitle,
    forceRejectWithReason,
    formType,//self or other (used @ client DF views (batch submission tab))
    form,
    withSelfFlag,
    ...props
}) {

    const dialog = useModalDialog(0)

    const [rejectReason, setRejectReason] = React.useState("")
    const [rejectionValidation, setRejectionValidation] = React.useState("")
    const [rejectSubmitted, setRejectSubmitted] = React.useState(false);

    React.useEffect(() => {
        if (!rejectReason) setRejectionValidation("Rejection reason is missing"); else setRejectionValidation("");
    }, [rejectReason])

    return (
        <div id="last-sent-file-modal" style={{ width: "900px" }}>
            <Paper elevation={0} className="mt-3 p-3">
                <div className="container">
                    <div className="mt-3">
                        <textarea
                            type="text"
                            className="action-item-textarea w-100"
                            style={{ fontFamily: 'Manrope', fontSize: "15px" }}
                            value={rejectReason}
                            onChange={e => setRejectReason(e.target.value)}
                            rows={4}
                        />
                        <div className="mt-2 text-danger" style={{ fontSize: "13px", fontFamily: 'Manrope', }} hidden={!rejectSubmitted}>
                            {rejectionValidation}
                        </div>
                    </div>
                </div>
                <div className="row w-100 mt-5 mb-3" style={{ justifyContent: "flex-end" }}>
                    <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => dialog.closeDialog()}>
                        Cancel
                    </Button>
                    <Button variant="contained" className="blue-btn" onClick={() => {
                        setRejectSubmitted(true);
                        if (form) {
                            forceRejectWithReason(form, withSelfFlag, rejectReason, rejectionValidation)
                        } else {
                            forceRejectWithReason(rejectReason, rejectionValidation, formType ?? '');
                        }
                    }}>
                        Confirm
                    </Button>
                </div>
            </Paper>
        </div>
    )
}

export default RejectionReason;
