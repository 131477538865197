import React, { useEffect } from "react";
import ErrorIcon from '@mui/icons-material/Error';
import HomeProductionDashboard from "./HomeProductionTransmissions/HomeProductionDashboard";
import HomeConnectivityRequestDashboard from "./HomeConnectivityRequests/HomeConnectivityRequestsDashboard";
import HomeOERequestDashboard from "./HomeOERequests/HomeOERequestsDashboard";
import * as APIs from "../../Data/APIs";
import { useLoader } from '../Layout/Loader';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import ClientFormsTabs from "../ClientFormsTabs/ClientFormsTabs";
import { useAuthentication } from "../../Data/Authentication";
import { TabsTitlesWithIcons } from "../Layout/TabsTitles";

function HomeDashboard() {

    const { openLoader, closeLoader } = useLoader();
    const { isAuthenticating, isAuthenticated, authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const [productionDashboardClicked, setProductionDashboardClicked] = React.useState(true);
    const [connectivityRequestsClicked, setConnectivityRequestsClicked] = React.useState(false);
    const [oeRequestsClicked, setOERequestsClicked] = React.useState(false);
    const [formsClicked, setFormsClicked] = React.useState(false);
    const [oeRequestsListCallback, setOERequestsListCallback] = React.useState([]);
    const [hasOEPendingActions, setHasOEPendingActions] = React.useState(false);

    const [tabValue, setTabValue] = React.useState(0);
    const [tabstitles, setTabsTitle] = React.useState([
        { label: 'Daily Production Transmissions' },
        { label: 'Connectivity Requests' },
        { label: 'Open Enrollments' }
    ]);
    const [tabsComponents, setTabsComponents] = React.useState([
        <HomeProductionDashboard />,
        <HomeConnectivityRequestDashboard />,
        <HomeOERequestDashboard
            onHasOEPendingActions={s => setHasOEPendingActions(s)}
            onOERequestsListCallback={s => setOERequestsListCallback(s)}
        />
    ]);

    useEffect(() => {
        if (userRoles?.includes("6")) {
            setTabsTitle([
                ...tabstitles,
                { label: 'Forms' },
            ])
            setTabsComponents([
                ...tabsComponents,
                <ClientFormsTabs />
            ])
        }
    }, [])


    React.useEffect(() => {
        APIs.getOERequestsList({}).then((r) => {
            setOERequestsListCallback(r?.data);

            if (Object.keys(r?.data).length && r?.data.any(s => s?.SendOEFileDone === 0)) {
                let newTabtitles = [];
                if (userRoles?.includes("6")) {
                    newTabtitles = [...tabstitles, { label: 'Forms' }];
                } else {
                    newTabtitles = [...tabstitles];
                }
                tabstitles[2].tooltip = "Some OE Requests are pending data to be ready";
                setTabsTitle([...newTabtitles])
            } else {
                // setHasOEPendingActions(false);
            }
            closeLoader()
        })
    }, [])

    return (
        <div id="home-dashboard">
            <div className="home-greeting">Welcome to isolved Carrier Connectivity Platform</div>

            <TabsTitlesWithIcons tabTitle={tabstitles} value={tabValue} setValue={setTabValue} />
            {tabsComponents[tabValue]}
        </div>
    )
}

export default HomeDashboard;
