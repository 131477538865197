import React from "react";
import { Divider, Paper, Select, TextField, Button } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import RepeatIcon from '@material-ui/icons/Repeat';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Chip from '@material-ui/core/Chip';
import Moment from 'moment';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import calendarClockIcon from "../../../../Icons/calendar-clock.svg";
import clockIcon from "../../../../Icons/clock.svg";
import repeatIcon from "../../../../Icons/arrows-repeat.svg";
import calendarRangeSchedulerSVG from "../../../../Icons/calendar-range-scheduler.svg";
import arrowsRepeatSchedulerSVG from "../../../../Icons/arrows-repeat-scheduler.svg";
import clockSchedulerSVG from "../../../../Icons/clock-scheduler.svg";

function AnalystViewScheduler({
    index,
    scheduler,
    feedStatusID,
    ...props
}) {


    const allWeekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const weekendOnly = ["Saturday", "Sunday"];
    const allWeekdaysNoWeekend = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
    const monthlyWeekParts = ["First", "Second", "Third", "Fourth", "Fifth", "Last"];

    const [expanded, setExpanded] = React.useState("");

    return (
        <div id="create-scheduler" key={`scheduler-number-${index}`}>
            <Accordion className="scheduler-feed mt-2" expanded={expanded === `SelectScheduler`} id={`scheduler-num-${index}`}>
                <AccordionSummary
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                    // onClick={() => expanded === `SelectScheduler` ? setExpanded("") : setExpanded("SelectScheduler")}
                    // expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="expand-SelectScheduler"
                >
                    <div>
                        <svg width="28" height="25" viewBox="0 0 28 25" className="color pink-primary-color mr-2" >
                            <use xlinkHref={`${calendarClockIcon}#calendar-clock`}></use>
                        </svg>
                        Occurs
                        {scheduler?.Recurrence == 2 ? " biweekly " : scheduler?.Type === "WeeklyScheduler" ? " every week " : scheduler?.Type === "MonthlyScheduler" ? " every month " : "..."}
                        on &nbsp;
                        {scheduler?.Type === null && "..."}
                        {scheduler?.Type === "WeeklyScheduler" && (scheduler?.WeekDay?.length === weekendOnly.length && weekendOnly.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "weekends only " :
                            scheduler?.WeekDay?.length === allWeekdaysNoWeekend.length && allWeekdaysNoWeekend.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "weekdays only " :
                                scheduler?.WeekDay?.length === allWeekdays.length && allWeekdays.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "all days " :
                                    scheduler?.WeekDay?.length > 0 ? scheduler?.WeekDay?.map((d => `${d} `)).join(", ") : "...")}
                        {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 1 && (scheduler?.DayOfMonth ? `day ${scheduler?.DayOfMonth}  of each month ` : "...")}
                        {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 2 && (scheduler?.PartType ? `the  ${scheduler?.PartType} ` : "...")}
                        {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType == 2 && (scheduler?.WeekDay ? ` ${scheduler?.WeekDay === "WeekDay" ? "Weekday" : scheduler?.WeekDay === "WeekEndDay" ? "Weekend" : scheduler?.WeekDay} of each month ` : '...')}
                        at &nbsp;
                        {scheduler?.StartDateTime ? Moment(scheduler?.StartDateTime).format('HH:mm') : "..."}
                        &nbsp; Schedule will be used starting on &nbsp;
                        {scheduler?.StartDate ? Moment(scheduler?.StartDate).format('MM/DD/YYYY') : "..."}  &nbsp;
                        ({scheduler?.Enabled ? 'Enabled' : (feedStatusID === 8 ? 'Disabled Due To OE' : 'Disabled')})
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <div className="row alignvh-center mb-4">
                            <div className="text-left">
                                <span className="fw-400">Enable this schedule</span>
                                <Switch disabled checked={scheduler?.Enabled} />
                            </div>
                        </div>
                        <div id="scheduler-frequency">
                            <div className="row text-center">
                                <div className="col-xl-3 pl-3 pr-3">
                                    <h6 className="black1 fw-500 fs-16">Frequency</h6>
                                </div>
                                <div className="col-xl-5 pl-3 pr-3">
                                    <h6 className="black1 fw-500 fs-16">Recurrence</h6>
                                </div>
                                <div className="col-xl-4 pl-3 pr-3">
                                    <h6 className="black1 fw-500 fs-16">Duration (Date &amp; Time)</h6>
                                </div>
                            </div>
                            <div className="row mt-2 text-center">
                                <div className="col-xl-3 pr-3 pl-3">
                                    <div className="scheduler-info-div">
                                        <div style={{ flex: "1", textAlignLast: "left" }}>
                                            <svg width="32" height="32" viewBox="0 0 22 22">
                                                <use xlinkHref={`${calendarRangeSchedulerSVG}#calendarRangeScheduler`}></use>
                                            </svg>
                                            <span>Occurs</span>
                                        </div>
                                        <div style={{ flex: "2", textAlignLast: "right" }}>
                                            <Chip label={scheduler?.Type === "WeeklyScheduler" && scheduler?.Recurrence === "2" ? "Biweekly" : scheduler?.Type === "WeeklyScheduler" && scheduler?.Recurrence === "1" ? "Weekly" : "Monthly"} className="scheduler-chip" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 pr-3 pl-3">
                                    <div className="scheduler-info-div">
                                        <div style={{ flex: "1", textAlignLast: "left" }}>
                                            <svg width="32" height="32" viewBox="0 0 22 22">
                                                <use xlinkHref={`${arrowsRepeatSchedulerSVG}#arrowsRepeatScheduler`}></use>
                                            </svg>
                                            <span>Recurs</span>
                                        </div>
                                        <div style={{ flex: "3", textAlignLast: "right" }}>
                                            {scheduler?.Type === "WeeklyScheduler" && (
                                                <>
                                                    <Chip className="scheduler-chip" label={
                                                        scheduler?.WeekDay?.length === weekendOnly.length && weekendOnly.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "Weekends only" :
                                                            scheduler?.WeekDay?.length === allWeekdaysNoWeekend.length && allWeekdaysNoWeekend.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "Weekdays only" :
                                                                scheduler?.WeekDay?.length === allWeekdays.length && allWeekdays.every(item => scheduler?.WeekDay?.indexOf(item) > -1) ? "All days" : scheduler?.WeekDay?.length > 0 ? scheduler?.WeekDay?.map((d => d)).join(", ") : "..."} />
                                                </>
                                            )}
                                            {scheduler?.Type === "MonthlyScheduler" && (
                                                <>
                                                    {scheduler?.MonthlyType === "1" && (
                                                        <>
                                                            <span className="ml-2">on day</span>
                                                            <Chip className="scheduler-chip ml-2" label={scheduler?.DayOfMonth} />
                                                            <span className="ml-2">of each month</span>
                                                        </>
                                                    )}
                                                    {scheduler?.MonthlyType === "2" && (
                                                        <>
                                                            <span className="ml-2">on the</span>
                                                            <Chip className="scheduler-chip ml-2" label={scheduler?.PartType} />
                                                            <Chip className="scheduler-chip ml-2" label={scheduler?.WeekDay} />
                                                            <span className="ml-2">of each month</span>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 pr-3 pl-3">
                                    <div className="scheduler-info-div">
                                        <div style={{ flex: "1", textAlignLast: "left" }}>
                                            <svg width="32" height="32" viewBox="0 0 22 22">
                                                <use xlinkHref={`${clockSchedulerSVG}#clockScheduler`}></use>
                                            </svg>
                                            <span>Starts on</span>
                                        </div>
                                        <div style={{ flex: "2", textAlignLast: "right" }}>
                                            <Chip className="ml-2 scheduler-chip" label={Moment(scheduler?.StartDate).format("MM/DD/YYYY")} />
                                            <span className="ml-2">at</span>
                                            <Chip className="ml-2 scheduler-chip" label={Moment(scheduler?.StartDateTime).format("HH:mm")} />                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12" style={{ minHeight: "10px" }}></div>
                                <div className="col-xl-3 pr-3 pl-3">
                                    <ToggleButtonGroup orientation="vertical" value={scheduler?.Type} exclusive>
                                        <ToggleButton value="WeeklyScheduler">Weekly</ToggleButton>
                                        <ToggleButton value="MonthlyScheduler">Monthly</ToggleButton>
                                    </ToggleButtonGroup>
                                    {scheduler?.Type === "WeeklyScheduler" && (
                                        <div style={{ textAlignLast: "left" }}>
                                            <FormControlLabel
                                                className="mt-2"
                                                control={
                                                    <Switch
                                                        checked={scheduler?.Type === "WeeklyScheduler" && scheduler?.Recurrence === "1"}
                                                    />
                                                }
                                                label="Weekly"
                                            />
                                            <br />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={scheduler?.Type === "WeeklyScheduler" && scheduler?.Recurrence === "2"}
                                                    />
                                                }
                                                label="Biweekly"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="col-xl-5 pr-3 pl-3">
                                    {scheduler?.Type === "WeeklyScheduler" ? (
                                        <div>
                                            <ToggleButtonGroup orientation="vertical" value={scheduler?.WeekDay?.map(s => s)} exclusive>
                                                {allWeekdays?.map((w, wi) => (
                                                    <ToggleButton key={`weekdays-view-${wi}`} value={w}>
                                                        {w}
                                                    </ToggleButton>
                                                ))}
                                            </ToggleButtonGroup>
                                            <div style={{ textAlignLast: "left" }}>
                                                <FormControlLabel
                                                    className="mt-2"
                                                    control={
                                                        <Switch
                                                            checked={allWeekdays.every(item => scheduler?.WeekDay?.indexOf(item) > -1)}
                                                        />
                                                    }
                                                    label="All days"
                                                />
                                                <br />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={weekendOnly.every(item => scheduler?.WeekDay?.indexOf(item) > -1) && weekendOnly.length === scheduler?.WeekDay?.length && !(
                                                                allWeekdays.every(item => scheduler?.WeekDay?.indexOf(item) > -1) &&
                                                                allWeekdaysNoWeekend.every(item => scheduler?.WeekDay?.indexOf(item) > -1)
                                                            )}
                                                        />
                                                    }
                                                    label="Weekends only"
                                                />
                                                <br />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={allWeekdaysNoWeekend.every(item => scheduler?.WeekDay?.indexOf(item) > -1) && allWeekdaysNoWeekend.length === scheduler?.WeekDay?.length && !(
                                                                weekendOnly.every(item => scheduler?.WeekDay?.indexOf(item) > -1) &&
                                                                allWeekdays.every(item => scheduler?.WeekDay?.indexOf(item) > -1)
                                                            )}
                                                        />
                                                    }
                                                    label="Weekdays only"
                                                />
                                                <br />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={!(
                                                                (weekendOnly.every(item => scheduler?.WeekDay?.indexOf(item) > -1) && weekendOnly.length === scheduler?.WeekDay?.length) ||
                                                                (allWeekdays.every(item => scheduler?.WeekDay?.indexOf(item) > -1) && allWeekdays.length === scheduler?.WeekDay?.length) ||
                                                                (allWeekdaysNoWeekend.every(item => scheduler?.WeekDay?.indexOf(item) > -1) && allWeekdaysNoWeekend.length === scheduler?.WeekDay?.length)
                                                            )}
                                                        />
                                                    }
                                                    label="Custom days"
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div id="monthly-scheduler">
                                            <div style={{ textAlignLast: "left" }}>
                                                <FormControlLabel
                                                    className="mt-2"
                                                    control={
                                                        <Switch
                                                            checked={scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType === "1"}
                                                        />
                                                    }
                                                    label="Day in a month"
                                                />
                                                <br />
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType === "2"}
                                                        />
                                                    }
                                                    label="Weekday in a month"
                                                />
                                            </div>
                                            <div className="row alignvh-center text-left">
                                                {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType === "2" && (
                                                    <>
                                                        <div className="col-xl-3">
                                                            <span>Week</span>
                                                        </div>
                                                        <div className="col-xl-9">
                                                            <div className="textfield-div">
                                                                {scheduler?.PartType}
                                                            </div>
                                                        </div>

                                                        <div className="col-xl-3 mt-2">
                                                            <span>Week day</span>
                                                        </div>
                                                        <div className="col-xl-9 mt-2">
                                                            <div className="textfield-div">
                                                                {scheduler?.WeekDay === "WeekDay" ? "Weekday" : scheduler?.WeekDay === "WeekEndDay" ? "Weekend" : scheduler?.WeekDay}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {scheduler?.Type === "MonthlyScheduler" && scheduler?.MonthlyType === "1" && (
                                                    <>
                                                        <div className="col-xl-3">
                                                            <span>Day</span>
                                                        </div>
                                                        <div className="col-xl-9">
                                                            <div className="textfield-div">
                                                                {scheduler?.DayOfMonth}
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-xl-4 pr-3 pl-3">
                                    <div className="row scheduler-table">
                                        <div className="col-3 scheduler-header" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>Date</div>
                                        <div className="col-9 scheduler-cell" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                                            <span className="ml-auto mr-auto">{scheduler?.StartDate ? Moment(scheduler?.StartDate).format("MM/DD/YYYY") : null}</span>
                                            <div style={{ marginRight: "20px" }}>
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-3 scheduler-header">Time</div>
                                        <div className="col-9 scheduler-cell">
                                            <span className="ml-auto mr-auto">{Moment(scheduler?.StartDateTime).format("HH:mm")}</span>
                                            <div style={{ marginRight: "20px" }}>
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default AnalystViewScheduler;