import React, { useState } from 'react'
import BreadcrumbSteps from '../Layout/BreadcrumbSteps';
import MultipleTabsTitles from "../Layout/MultipleTabsTitles";
import EmployeeDashboardPendingFormsTab from './EmployeeDashboardPendingFormsTab';
import EmployeeDashboardRejectedFormsTab from './EmployeeDashboardRejectedFormsTab';
import EmployeeDashboardSignedFormsTab from './EmployeeDashboardSignedFormsTab';
import EmployeeDashboardTransmittedFormsTab from './EmployeeDashboardTransmittedFormsTab';


const employeeTabs = ['pending forms', 'Pending HR Review', 'transmitted forms', 'rejected forms']
const employeeTabsComponents = [
    <EmployeeDashboardPendingFormsTab />,
    <EmployeeDashboardSignedFormsTab />,
    <EmployeeDashboardTransmittedFormsTab />,
    <EmployeeDashboardRejectedFormsTab />
]

const EmployeeDashboard = () => {
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    };

    return (
        <div>
            {/* <BreadcrumbSteps
                breadcrumbTitleCurrent={`Client Name`}
                isCurrent={true}
                withHomeLink={false}
            /> */}
            <div className="d-flex">
                <MultipleTabsTitles
                    tabTitle={employeeTabs}
                    value={tabValue}
                    onChange={handleTabChange}
                />
            </div>

            {employeeTabsComponents[tabValue]}
        </div>
    )
}

export default EmployeeDashboard
