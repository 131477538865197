import React from "react";
import Moment from 'moment';
import ViewSVG from "../../Images/view.svg";
import Pagination from "../Layout/Pagination";
import GenericTable from "../Layout/GenericTable";

function FormsTable({
    formsList,
    setFormsList,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    ...props
}) {

    const [sortOrder, setSortOrder] = React.useState("ASC");

    // -------------------------------- NEW TABLE ---------------------------------
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        onSortFieldFilter(column?.sortingkey);
        onSortOrderFilter(sortDirection?.toUpperCase());

    };

    const baseColumnsArray = [
        {
            name: 'Digital Form Name',
            sortable: true,
            sortingkey: 'Name',
            id: 'Name',
            selector: (c) => c?.Name
        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: (c) =>  c?.CarrierName
        },
        {
            name: 'Digitized By',
            sortable: true,
            sortingkey: 'CreatedBy',
            id: 'CreatedBy',
            selector: (c) => c?.CreatedBy
        },
        {
            name: 'Created Date',
            sortable: true,
            sortingkey: 'CreatedDate',
            id: 'CreatedDate',
            selector: (c) => Moment(c?.CreatedDate).format('MM/DD/YYYY')
        },
        {
            name: 'Last Modified By',
            sortable: true,
            sortingkey: 'LastModifiedBy',
            id: 'LastModifiedBy',
            selector: (c) =>  c?.lastModifiedBy
        },
        {
            name: 'Last Modified Date',
            sortable: true,
            sortingkey: 'lastModifiedDate',
            id: 'lastModifiedDate',
            selector: (c) =>  Moment(c?.LastModifiedDate).format('MM/DD/YYYY')
        }
            
    ];

    return (
        <div className="mt-5" id="connectivity-group-requests-table">
            <GenericTable
                columns={[...baseColumnsArray]}
                data={formsList}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection) : () => { }}
                />
            <div>
                <Pagination
                    stateLimit={true}
                    pageSize={pageSize}
                    page={selectedPage}
                    onChange={(t, v) => {
                        if (t === 1) setPageSize(v);
                        else if (t === 2) setSelectedPage(v);
                    }}
                    count={totalCount}
                />
            </div>
        </div>
    )
}

export default FormsTable;