import React from "react";
import ConnectivityRequestsStatistics from "./ConnectivityRequestsStatistics";
import ConnectivityRequestsListView from "./ConnectivityRequestsListView";
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import Moment from 'moment';
import { useParams } from "react-router-dom";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0)

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function ConnectivityRequestsDashboard() {

    const { CRStatusURL } = useParams();
    const { CRPhaseURL } = useParams();
    const tableRef = React.useRef();
    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);
    const [refreshClicked, setRefreshClicked] = React.useState(false);
    const { openLoader, closeLoader } = useLoader();
    const [dashboardCRStatus, setDashboardCRStatus] = React.useState([]);
    const [dashboardCRPhases, setDashboardCRPhases] = React.useState([]);
    const [connectivityRequestsList, setConnectivityRequestsList] = React.useState([]);
    const [connectivityRequestsStatus, setConnectivityRequestsStatus] = React.useState([]);
    const [connectivityRequestsPhase, setConnectivityRequestsPhase] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [groupNameFilter, setGroupNameFilter] = React.useState("");
    const [peoNameFilter, setPEONameFilter] = React.useState("");
    const [assignedToFilter, setAssignedToFilter] = React.useState("");
    const [carrierNameFilter, setCarrierNameFilter] = React.useState("");

    const [partnerFilter, setPartnerFilter] = React.useState("");

    const [feedNameFilter, setFeedNameFilter] = React.useState("");
    const [feedIDFilter, setFeedIDFilter] = React.useState("");
    const [feedStructureTypeFilter, setFeedStructureTypeFilter] = React.useState("");
    const [feedDataTypeFilter, setFeedDataTypeFilter] = React.useState("");
    const [groupRequestIDFilter, setGroupRequestIDFilter] = React.useState("");
    const [connectivityRequestIDFilter, setConnectivityRequestIDFilter] = React.useState("");
    const [createdFromDateFilter, setCreatedFromDateFilter] = React.useState(null);
    const [createdToDateFilter, setCreatedToDateFilter] = React.useState(null);
    const [clientEffectiveDateFromFilter, setClientEffectiveDateFromFilter] = React.useState(null);
    const [clientEffectiveDateToFilter, setClientEffectiveDateToFilter] = React.useState(null);
    const [connectivityRequestTypeFilter, setConnectivityRequestTypeFilter] = React.useState("");
    const [connectivityRequestSubTypeFilter, setConnectivityRequestSubTypeFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState();
    const [phaseFilter, setPhaseFilter] = React.useState();
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    const [includePMFFilter, setIncludePMFFilter] = React.useState(null);
    const [migrationFilter, setMigrationFilter] = React.useState("");
    const [agingConnectivityFilter, setAgingConnectivityFilter] = React.useState();
    const [onPhaseDashboardChange, setOnPhaseDashboardChange] = React.useState(false);
    const [onStatusDashboardChange, setOnStatusDashboardChange] = React.useState(false);
    const [responsiblePartyFilter, setResponsiblePartyFilter] = React.useState("");
    const [isPEOFilter, setIsPEOFilter] = React.useState("");
    //advanced filters
    const [selectedGroupsFilter, setSelectedGroupsFilter] = React.useState([]);
    const [selectedPEOsFilter, setSelectedPEOsFilter] = React.useState([]);
    const [selectedStatusesFilter, setSelectedStatusesFilter] = React.useState([]);
    const [selectedCarriersFilter, setSelectedCarriersFilter] = React.useState([]);
    const [selectedPartnerFilter, setSelectedPartnerFilter] = React.useState([]);
    const [selectedFeedsFilter, setSelectedFeedsFilter] = React.useState([]);
    const [selectedFeedStructureTypesFilter, setSelectedFeedStructureTypesFilter] = React.useState([]);
    const [selectedFeedDataTypesFilter, setSelectedFeedDataTypesFilter] = React.useState("");
    const [selectedAssignedToFilter, setSelectedAssignedToFilter] = React.useState([]);
    const [selectedPhasesFilter, setSelectedPhasesFilter] = React.useState([]);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("");
    const [connectivityRequestIDAdvFilter, setConnectivityRequestIDAdvFilter] = React.useState("");
    const [feedIDAdvFilter, setFeedIDAdvFilter] = React.useState("");
    const [createdFromDateAdvFilter, setCreatedFromDateAdvFilter] = React.useState(null);
    const [createdToDateAdvFilter, setCreatedToDateAdvFilter] = React.useState(null);
    const [includePMFAdvFilter, setIncludePMFAdvFilter] = React.useState(null);
    const [migrationAdvFilter, setMigrationAdvFilter] = React.useState("");
    const [migrationStartDateFromDateAdvFilter, setMigrationStartDateFromDateAdvFilter] = React.useState(null);
    const [migrationStartDateToDateAdvFilter, setMigrationStartDateToDateAdvFilter] = React.useState(null);
    const [plannedFirstProductionDateFromDateAdvFilter, setPlannedFirstProductionDateFromDateAdvFilter] = React.useState(null);
    const [plannedFirstProductionDateToDateAdvFilter, setPlannedFirstProductionDateToDateAdvFilter] = React.useState(null);
    const [migrationPhasesAdvFilter, setMigrationPhasesAdvFilter] = React.useState([]);
    const [selectedConnectivityRequestTypesFilter, setSelectedConnectivityRequestTypesFilter] = React.useState([]);
    const [selectedConnectivityRequestSubTypesFilter, setSelectedConnectivityRequestSubTypesFilter] = React.useState([]);
    const [isPEOAdvFilter, setIsPEOAdvFilter] = React.useState("");
    const [agingConnectivityAdvFilter, setAgingConnectivityAdvFilter] = React.useState();
    const [responsiblePartyAdvFilter, setResponsiblePartyAdvFilter] = React.useState([]);
    const [clientEffectiveDateFromAdvFilter, setClientEffectiveDateFromAdvFilter] = React.useState(null);
    const [clientEffectiveDateToAdvFilter, setClientEffectiveDateToAdvFilter] = React.useState(null);
    // status for Dashboard Cards 
    const [statusesFilterCard, setStatusesFilterCard] = React.useState([]);
    const [filterCard, setFilterCard] = React.useState(false);
    const [subTypeList, setSubTypeList] = React.useState([]);

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestsDashboard().then((r) => (
            setDashboardCRStatus(r?.data?.StatusPercentage),
            setDashboardCRPhases(r?.data?.phasesPercentage),
            closeLoader()
        ));
    }, [refreshClicked])

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestEDISubTypes().then((r) => {
            setSubTypeList(r?.data);
        });
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestStatuses().then((r) => (
            !(r?.data?.length) ? setConnectivityRequestsStatus([]) : setConnectivityRequestsStatus(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestPhases().then((r) => (
            !(r?.data?.length) ? setConnectivityRequestsPhase([]) : setConnectivityRequestsPhase(r?.data),
            closeLoader()
        ));
    }, [])




    const getDataQuickFilters = () => {
        openLoader();
        const requestParameters = {};
        if (CRStatusURL === undefined && CRPhaseURL === undefined) {
            requestParameters.RequestDateFrom = createdFromDateFilter ? Moment(createdFromDateFilter).format('YYYY-MM-DD') : null;
            requestParameters.RequestDateTo = createdToDateFilter ? Moment(createdToDateFilter).format('YYYY-MM-DD') : null;
            if (selectedPage >= 1) {
                requestParameters.pageNumber = selectedPage;
                requestParameters.limit = pageSize;
            }
            if (pageSize >= 10) {
                requestParameters.limit = pageSize;
                requestParameters.pageNumber = selectedPage;
            }
            if (groupNameFilter !== "")
                requestParameters.GroupName = groupNameFilter;
            if (peoNameFilter !== "")
                requestParameters.PeoName = peoNameFilter;
            if (migrationFilter?.length > 0)
                requestParameters.MigrationConnectivityRequestsArray = [migrationFilter];
            if (assignedToFilter !== "")
                requestParameters.AssignedTo = assignedToFilter.CognitoID;
            if (carrierNameFilter !== "")
                requestParameters.CarrierName = carrierNameFilter;
            if (partnerFilter !== "")
                requestParameters.PartnerName = partnerFilter;
            if (feedNameFilter !== "")
                requestParameters.FeedName = feedNameFilter;
            if (feedDataTypeFilter !== "")
                requestParameters.DataType = feedDataTypeFilter;
            if (connectivityRequestTypeFilter !== "")
                requestParameters.ConnectivityRequestTypesArray = [connectivityRequestTypeFilter];
            if (connectivityRequestSubTypeFilter !== "")
                requestParameters.ConnectivityRequestEDISubTypeIDsArray = [connectivityRequestSubTypeFilter];
            if (feedStructureTypeFilter !== "")
                requestParameters.StructureTypeIDsArray = [feedStructureTypeFilter];
            if (feedIDFilter !== "")
                requestParameters.FeedID = feedIDFilter;
            if (groupRequestIDFilter !== "")
                requestParameters.GroupRequestID = groupRequestIDFilter;
            if (connectivityRequestIDFilter !== "")
                requestParameters.ConnectivityRequestID = connectivityRequestIDFilter;
            if (statusFilter !== "")
                requestParameters.StatusTypeId = statusFilter;
            if (phaseFilter !== "")
                requestParameters.PhaseId = phaseFilter;
            if (includePMFFilter !== null)
                requestParameters.PreMappingAttached = parseInt(includePMFFilter);
            if (sortFieldFilter !== "")
                requestParameters.sortField = sortFieldFilter;
            if (sortOrderFilter !== "")
                requestParameters.sortOrder = sortOrderFilter;
            if (statusesFilterCard.length > 0)
                requestParameters.StatusIDsArray = statusesFilterCard;
            if (responsiblePartyFilter !== "")
                requestParameters.AIResponsiblePartyIDsArray = [parseInt(responsiblePartyFilter)];
            if (agingConnectivityFilter !== null)
                requestParameters.AgingConnectivities = parseInt(agingConnectivityFilter);
            if (clientEffectiveDateFromFilter !== null)
                requestParameters.ClientEffectiveDateFrom = clientEffectiveDateFromFilter ? Moment(clientEffectiveDateFromFilter).format('YYYY-MM-DD') : null;
            if (clientEffectiveDateToFilter !== null)
                requestParameters.ClientEffectiveDateTo = clientEffectiveDateToFilter ? Moment(clientEffectiveDateToFilter).format('YYYY-MM-DD') : null;

        } else if (CRStatusURL !== undefined) {
            if (CRStatusURL === "In Progress") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.StatusTypeId = 1;
            } else if (CRStatusURL === "On Hold") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.StatusTypeId = 2;
            } else if (CRStatusURL === "Blocked") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.StatusTypeId = 3;
            } else if (CRStatusURL === "Cancelled") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.StatusTypeId = 5;
            } else if (CRStatusURL === "Not Started") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.StatusTypeId = 6;
            } else if (CRStatusURL === "Production") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.StatusTypeId = 7;
            } else if (CRStatusURL === "Production Disabled") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.StatusTypeId = 8;
            }
            if (selectedPage >= 1) {
                requestParameters.pageNumber = selectedPage;
                requestParameters.limit = pageSize;
            }
            if (pageSize >= 10) {
                requestParameters.limit = pageSize;
                requestParameters.pageNumber = selectedPage;
            }
            if (groupNameFilter !== "")
                requestParameters.GroupName = groupNameFilter;
            if (peoNameFilter !== "")
                requestParameters.PeoName = peoNameFilter;
            if (migrationFilter?.length > 0)
                requestParameters.MigrationConnectivityRequestsArray = [migrationFilter];
            if (assignedToFilter !== "")
                requestParameters.AssignedTo = assignedToFilter.CognitoID;
            if (carrierNameFilter !== "")
                requestParameters.CarrierName = carrierNameFilter;
            if (partnerFilter !== "")
                requestParameters.PartnerName = partnerFilter;
            if (feedNameFilter !== "")
                requestParameters.FeedName = feedNameFilter;
            if (feedDataTypeFilter !== "")
                requestParameters.DataType = feedDataTypeFilter;
            if (connectivityRequestTypeFilter !== "")
                requestParameters.ConnectivityRequestTypesArray = [connectivityRequestTypeFilter];
            if (connectivityRequestSubTypeFilter !== "")
                requestParameters.ConnectivityRequestEDISubTypeIDsArray = [connectivityRequestSubTypeFilter];
            if (feedStructureTypeFilter !== "")
                requestParameters.StructureTypeIDsArray = [feedStructureTypeFilter];
            if (feedIDFilter !== "")
                requestParameters.FeedID = feedIDFilter;
            if (groupRequestIDFilter !== "")
                requestParameters.GroupRequestID = groupRequestIDFilter;
            if (connectivityRequestIDFilter !== "")
                requestParameters.ConnectivityRequestID = connectivityRequestIDFilter;
            if (phaseFilter !== "")
                requestParameters.PhaseId = phaseFilter;
            if (includePMFFilter !== null)
                requestParameters.PreMappingAttached = parseInt(includePMFFilter);
            if (sortFieldFilter !== "")
                requestParameters.sortField = sortFieldFilter;
            if (sortOrderFilter !== "")
                requestParameters.sortOrder = sortOrderFilter;
            if (responsiblePartyFilter !== "")
                requestParameters.AIResponsiblePartyIDsArray = [parseInt(responsiblePartyFilter)];
            if (agingConnectivityFilter !== null)
                requestParameters.AgingConnectivities = parseInt(agingConnectivityFilter);
            if (clientEffectiveDateFromFilter !== null)
                requestParameters.ClientEffectiveDateFrom = clientEffectiveDateFromFilter ? Moment(clientEffectiveDateFromFilter).format('YYYY-MM-DD') : null;
            if (clientEffectiveDateToFilter !== null)
                requestParameters.ClientEffectiveDateTo = clientEffectiveDateToFilter ? Moment(clientEffectiveDateToFilter).format('YYYY-MM-DD') : null;
        } else if (CRPhaseURL !== undefined) {
            if (CRPhaseURL === "Requirements Gathering") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.PhaseId = 1;
            } else if (CRPhaseURL === "Obtain Customer Dataset") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.PhaseId = 2;
            } else if (CRPhaseURL === "Resource Assignment") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.PhaseId = 3;
            } else if (CRPhaseURL === "Dataset Validation") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.PhaseId = 4;
            } else if (CRPhaseURL === "Mapping") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.PhaseId = 5;
            } else if (CRPhaseURL === "Testing") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.PhaseId = 6;
            } else if (CRPhaseURL === "Production") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.PhaseId = 7;
            } else if (CRPhaseURL === "Not Started") {
                requestParameters.RequestDateFrom = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.RequestDateTo = todayDate ? Moment(todayDate).format('YYYY-MM-DD') : null;
                requestParameters.PhaseId = 8;
            }
            if (selectedPage >= 1) {
                requestParameters.pageNumber = selectedPage;
                requestParameters.limit = pageSize;
            }
            if (pageSize >= 10) {
                requestParameters.limit = pageSize;
                requestParameters.pageNumber = selectedPage;
            }
            if (groupNameFilter !== "")
                requestParameters.GroupName = groupNameFilter;
            if (peoNameFilter !== "")
                requestParameters.PeoName = peoNameFilter;
            if (migrationFilter?.length > 0)
                requestParameters.MigrationConnectivityRequestsArray = [migrationFilter];
            if (assignedToFilter !== "")
                requestParameters.AssignedTo = assignedToFilter.CognitoID;
            if (carrierNameFilter !== "")
                requestParameters.CarrierName = carrierNameFilter;
            if (partnerFilter !== "")
                requestParameters.PartnerName = partnerFilter;
            if (feedNameFilter !== "")
                requestParameters.FeedName = feedNameFilter;
            if (feedDataTypeFilter !== "")
                requestParameters.DataType = feedDataTypeFilter;
            if (connectivityRequestTypeFilter !== "")
                requestParameters.ConnectivityRequestTypesArray = [connectivityRequestTypeFilter];
            if (connectivityRequestSubTypeFilter !== "")
                requestParameters.ConnectivityRequestEDISubTypeIDsArray = [connectivityRequestSubTypeFilter];
            if (feedStructureTypeFilter !== "")
                requestParameters.StructureTypeIDsArray = [feedStructureTypeFilter];
            if (feedIDFilter !== "")
                requestParameters.FeedID = feedIDFilter;
            if (groupRequestIDFilter !== "")
                requestParameters.GroupRequestID = groupRequestIDFilter;
            if (connectivityRequestIDFilter !== "")
                requestParameters.ConnectivityRequestID = connectivityRequestIDFilter;
            if (statusFilter !== "")
                requestParameters.StatusTypeId = statusFilter;
            if (sortFieldFilter !== "")
                requestParameters.sortField = sortFieldFilter;
            if (sortOrderFilter !== "")
                requestParameters.sortOrder = sortOrderFilter;
            if (responsiblePartyFilter !== "")
                requestParameters.AIResponsiblePartyIDsArray = [parseInt(responsiblePartyFilter)];
            if (agingConnectivityFilter !== null)
                requestParameters.AgingConnectivities = parseInt(agingConnectivityFilter);


            if (clientEffectiveDateFromFilter !== null)
                requestParameters.ClientEffectiveDateFrom = clientEffectiveDateFromFilter ? Moment(clientEffectiveDateFromFilter).format('YYYY-MM-DD') : null;
            if (clientEffectiveDateToFilter !== null)
                requestParameters.ClientEffectiveDateTo = clientEffectiveDateToFilter ? Moment(clientEffectiveDateToFilter).format('YYYY-MM-DD') : null;

        }
        if (isPEOFilter !== null) {
            requestParameters.IsPEO = isPEOFilter;
        }
        APIs.getConnectivityRequests(requestParameters).then((r) => (
            setConnectivityRequestsList(r?.data?.list),
            setFilterCard(false),
            setRefreshClicked(false),
            setTotalCount(r?.data?.totalCount ?? 0)
        )).catch((error) => {
            console.log('Get Connectivity Requests Error', error);
        }).finally((t) => closeLoader());;
    }

    const getDataAdvancedFilters = () => {
        openLoader();
        const requestParameters = {};
        requestParameters.RequestDateFrom = createdFromDateAdvFilter ? Moment(createdFromDateAdvFilter).format('YYYY-MM-DD') : null;
        requestParameters.RequestDateTo = createdToDateAdvFilter ? Moment(createdToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (selectedPageAdv >= 1) {
            requestParameters.pageNumber = selectedPageAdv;
            requestParameters.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            requestParameters.limit = pageSizeAdv;
            requestParameters.pageNumber = selectedPageAdv;
        }
        if (connectivityRequestIDAdvFilter !== "")
            requestParameters.ConnectivityRequestID = connectivityRequestIDAdvFilter;
        if (selectedGroupsFilter.length > 0)
            requestParameters.GroupIDsArray = selectedGroupsFilter?.map(s => s?.GroupID);
        if (selectedPEOsFilter.length > 0)
            requestParameters.PeoIDsArray = selectedPEOsFilter?.map(s => s?.PeoId);
        if (selectedCarriersFilter.length > 0)
            requestParameters.CarrierIDsArray = selectedCarriersFilter?.map(s => s?.CarrierID);
        if (selectedPartnerFilter.length > 0)
            requestParameters.PartnerNameArray = selectedPartnerFilter;
        if (feedIDAdvFilter !== "")
            requestParameters.FeedID = feedIDAdvFilter;
        if (selectedFeedsFilter.length > 0)
            requestParameters.FeedIDsArray = selectedFeedsFilter?.map(s => s?.FeedID);
        if (selectedConnectivityRequestTypesFilter.length > 0)
            requestParameters.ConnectivityRequestTypesArray = selectedConnectivityRequestTypesFilter;
        if (selectedConnectivityRequestSubTypesFilter.length > 0)
            requestParameters.ConnectivityRequestEDISubTypeIDsArray = selectedConnectivityRequestSubTypesFilter;
        if (selectedFeedDataTypesFilter !== "")
            requestParameters.DataType = selectedFeedDataTypesFilter;
        if (selectedFeedStructureTypesFilter.length > 0)
            requestParameters.StructureTypeIDsArray = selectedFeedStructureTypesFilter?.map(s => s?.StructureTypeID);
        if (selectedAssignedToFilter.length > 0)
            requestParameters.AssignedToArray = selectedAssignedToFilter?.map(s => s?.CognitoID);
        if (selectedStatusesFilter.length > 0)
            requestParameters.StatusIDsArray = selectedStatusesFilter?.map(s => s?.ConnectivityRequestStatusID);
        if (selectedPhasesFilter.length > 0)
            requestParameters.PhaseIdArray = selectedPhasesFilter?.map(s => s?.ConnectivityRequestPhaseID);
        if (includePMFAdvFilter !== null)
            requestParameters.PreMappingAttached = parseInt(includePMFAdvFilter);
        if (migrationAdvFilter?.length > 0)
            requestParameters.MigrationConnectivityRequestsArray = migrationAdvFilter;
        if (sortFieldAdvFilter !== "")
            requestParameters.sortField = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            requestParameters.sortOrder = sortOrderAdvFilter;


        if (migrationPhasesAdvFilter.length > 0)
            requestParameters.MigrationPhaseIDsArray = migrationPhasesAdvFilter?.map(s => s?.Id);

        if (migrationStartDateFromDateAdvFilter !== null)
            requestParameters.MigrationStartDateFrom = migrationStartDateFromDateAdvFilter ? Moment(migrationStartDateFromDateAdvFilter).format('YYYY-MM-DD') : null;

        if (migrationStartDateToDateAdvFilter !== null)
            requestParameters.MigrationStartDateTo = migrationStartDateToDateAdvFilter ? Moment(migrationStartDateToDateAdvFilter).format('YYYY-MM-DD') : null;


        if (plannedFirstProductionDateFromDateAdvFilter !== null)
            requestParameters.PlannedFirstProductionDateFrom = plannedFirstProductionDateFromDateAdvFilter ? Moment(plannedFirstProductionDateFromDateAdvFilter).format('YYYY-MM-DD') : null;

        if (plannedFirstProductionDateToDateAdvFilter !== null)
            requestParameters.PlannedFirstProductionDateTo = plannedFirstProductionDateToDateAdvFilter ? Moment(plannedFirstProductionDateToDateAdvFilter).format('YYYY-MM-DD') : null;
        if (responsiblePartyAdvFilter.length > 0)
            requestParameters.AIResponsiblePartyIDsArray = responsiblePartyAdvFilter?.map(s => s?.Id);
        if (agingConnectivityAdvFilter !== null)
            requestParameters.AgingConnectivities = parseInt(agingConnectivityAdvFilter);
        if (clientEffectiveDateFromAdvFilter !== null)
            requestParameters.ClientEffectiveDateFrom = clientEffectiveDateFromAdvFilter ? Moment(clientEffectiveDateFromAdvFilter).format('YYYY-MM-DD') : null;
        if (clientEffectiveDateToAdvFilter !== null)
            requestParameters.ClientEffectiveDateTo = clientEffectiveDateToAdvFilter ? Moment(clientEffectiveDateToAdvFilter).format('YYYY-MM-DD') : null;
        if (isPEOAdvFilter !== null) {
            requestParameters.IsPEO = isPEOAdvFilter;
        }
        APIs.getConnectivityRequests(requestParameters).then((r) => (
            setConnectivityRequestsList(r?.data?.list),
            setTotalCount(r?.data?.totalCount ?? 0),
            setAdvancedFiltersAppliedClicked(false),
            closeLoader()
        ));
    }


    React.useEffect(() => {
        if (quickFiltersClicked && !filterCard) getDataQuickFilters();
    }, [filterCard, quickFiltersClicked === true, connectivityRequestTypeFilter, connectivityRequestSubTypeFilter, selectedPage, pageSize, groupNameFilter, peoNameFilter, assignedToFilter, carrierNameFilter, partnerFilter, feedNameFilter, feedStructureTypeFilter, feedDataTypeFilter, feedIDFilter, groupRequestIDFilter, connectivityRequestIDFilter, createdFromDateFilter, createdToDateFilter, statusFilter, phaseFilter, includePMFFilter, migrationFilter, sortFieldFilter, sortOrderFilter, responsiblePartyFilter, agingConnectivityFilter, clientEffectiveDateFromFilter, clientEffectiveDateToFilter, isPEOFilter, refreshClicked])


    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])


    React.useEffect(() => {
        if (advancedFiltersClicked === true) {
            getDataAdvancedFilters();
        }
    }, [advancedFiltersClicked === true])

    return (
        <div>
            {CRStatusURL === undefined && CRPhaseURL === undefined && (
                <ConnectivityRequestsStatistics
                    dashboardCRStatus={dashboardCRStatus}
                    dashboardCRPhases={dashboardCRPhases}
                    onPhaseFilter={(s) => setPhaseFilter(s)}
                    onStatusFilter={(s) => setStatusFilter(s)}
                    tableRef={tableRef}
                    onSetOnPhaseDashboardChange={(s) => setOnPhaseDashboardChange(s)}
                    onSetOnStatusDashboardChange={(s) => setOnStatusDashboardChange(s)}
                    onSetCreatedFromDateFilter={(d) => setCreatedFromDateFilter(d)}
                    onSetCreatedToDateFilter={(d) => setCreatedToDateFilter(d)}
                    onSetStatusesFilterCard={(s) => setStatusesFilterCard(s)}
                    setQuickFiltersClicked={setQuickFiltersClicked}
                    onSetFilterCard={(s) => setFilterCard(s)}

                />
            )}
            <ConnectivityRequestsListView
                connectivityRequestsList={connectivityRequestsList}
                setConnectivityRequestsList={setConnectivityRequestsList}
                selectedPage={selectedPage}
                onSelectedPage={(p) => setSelectedPage(p)}
                selectedPageAdv={selectedPageAdv}
                onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                pageSize={pageSize}
                onPageSize={(p) => setPageSize(p)}
                pageSizeAdv={pageSizeAdv}
                onPageSizeAdv={(p) => setPageSizeAdv(p)}
                onGroupNameFilter={(g) => setGroupNameFilter(g)}
                groupNameFilter={groupNameFilter}
                onPEONameFilter={(g) => setPEONameFilter(g)}
                peoNameFilter={peoNameFilter}
                onAssignedToFilter={(g) => setAssignedToFilter(g)}
                assignedToFilter={assignedToFilter}
                onCarrierNameFilter={(g) => setCarrierNameFilter(g)}
                carrierNameFilter={carrierNameFilter}
                onPartnerFilter={(g) => setPartnerFilter(g)}
                partnerFilter={partnerFilter}
                onFeedNameFilter={(g) => setFeedNameFilter(g)}
                feedNameFilter={feedNameFilter}
                onFeedStructureTypeFilter={(g) => setFeedStructureTypeFilter(g)}
                feedStructureTypeFilter={feedStructureTypeFilter}
                onFeedDataTypeFilter={(g) => setFeedDataTypeFilter(g)}
                feedDataTypeFilter={feedDataTypeFilter}
                onFeedIDFilter={(g) => setFeedIDFilter(g)}
                feedIDFilter={feedIDFilter}
                onFeedIDAdvFilter={(g) => setFeedIDAdvFilter(g)}
                feedIDAdvFilter={feedIDAdvFilter}
                onGroupRequestIDFilter={(i) => setGroupRequestIDFilter(i)}
                groupRequestIDFilter={groupRequestIDFilter}
                onConnectivityRequestIDFilter={(i) => setConnectivityRequestIDFilter(i)}
                connectivityRequestIDFilter={connectivityRequestIDFilter}
                onConnectivityRequestIDAdvFilter={(i) => setConnectivityRequestIDAdvFilter(i)}
                connectivityRequestIDAdvFilter={connectivityRequestIDAdvFilter}
                onCreatedFromDateFilter={(d) => setCreatedFromDateFilter(d)}
                createdFromDateFilter={createdFromDateFilter}
                onCreatedToDateFilter={(d) => setCreatedToDateFilter(d)}
                createdToDateFilter={createdToDateFilter}
                onCreatedFromDateAdvFilter={(d) => setCreatedFromDateAdvFilter(d)}
                createdFromDateAdvFilter={createdFromDateAdvFilter}
                onCreatedToDateAdvFilter={(d) => setCreatedToDateAdvFilter(d)}
                createdToDateAdvFilter={createdToDateAdvFilter}
                totalCount={totalCount}
                setTotalCount={setTotalCount}
                onStatusFilter={(s) => setStatusFilter(s)}
                statusFilter={statusFilter}
                onPhaseFilter={(s) => setPhaseFilter(s)}
                phaseFilter={phaseFilter}
                onIncludePMFFilter={(s) => setIncludePMFFilter(s)}
                includePMFFilter={includePMFFilter}
                onIncludePMFAdvFilter={(s) => setIncludePMFAdvFilter(s)}
                includePMFAdvFilter={includePMFAdvFilter}
                onSortFieldFilter={(s) => setSortFieldFilter(s)}
                onSortOrderFilter={(s) => setSortOrderFilter(s)}
                onSortFieldAdvFilter={(s) => setSortFieldAdvFilter(s)}
                onSortOrderAdvFilter={(s) => setSortOrderAdvFilter(s)}
                connectivityRequestsStatus={connectivityRequestsStatus}
                connectivityRequestsPhase={connectivityRequestsPhase}
                CRStatus={CRStatusURL}
                CRPhase={CRPhaseURL}
                subTypeList={subTypeList}
                onSelectedGroupsFilter={(s) => setSelectedGroupsFilter(s)}
                selectedGroupsFilter={selectedGroupsFilter}
                onSelectedPEOsFilter={(s) => setSelectedPEOsFilter(s)}
                selectedPEOsFilter={selectedPEOsFilter}
                onSelectedStatusesFilter={(s) => setSelectedStatusesFilter(s)}
                selectedStatusesFilter={selectedStatusesFilter}
                onSelectedCarriersFilter={(s) => setSelectedCarriersFilter(s)}
                selectedCarriersFilter={selectedCarriersFilter}
                onSelectedPartnerFilter={(s) => setSelectedPartnerFilter(s)}
                selectedPartnerFilter={selectedPartnerFilter}
                onSelectedFeedsFilter={(s) => setSelectedFeedsFilter(s)}
                selectedFeedsFilter={selectedFeedsFilter}
                onSelectedFeedStructureTypesFilter={(s) => setSelectedFeedStructureTypesFilter(s)}
                selectedFeedStructureTypesFilter={selectedFeedStructureTypesFilter}
                onSelectedFeedDataTypesFilter={(s) => setSelectedFeedDataTypesFilter(s)}
                selectedFeedDataTypesFilter={selectedFeedDataTypesFilter}
                onSelectedAssignedToFilter={(s) => setSelectedAssignedToFilter(s)}
                selectedAssignedToFilter={selectedAssignedToFilter}
                onSelectedPhasesFilter={(s) => setSelectedPhasesFilter(s)}
                selectedPhasesFilter={selectedPhasesFilter}
                quickFiltersClicked={quickFiltersClicked}
                setQuickFiltersClicked={setQuickFiltersClicked}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                onMigrationFilter={s => setMigrationFilter(s)}
                migrationFilter={migrationFilter}
                onMigrationAdvFilter={s => setMigrationAdvFilter(s)}
                migrationAdvFilter={migrationAdvFilter}
                tableRef={tableRef}
                onMigrationStartFromDateAdvFilter={(s) => setMigrationStartDateFromDateAdvFilter(s)}
                onMigrationStartToDateAdvFilter={(s) => setMigrationStartDateToDateAdvFilter(s)}
                onPlannedFirstProductionFromDateAdvFilter={(s) => setPlannedFirstProductionDateFromDateAdvFilter(s)}
                onPlannedFirstProductionToDateAdvFilter={(s) => setPlannedFirstProductionDateToDateAdvFilter(s)}
                onSelectedMigrationPhasesAdvFilter={(s) => setMigrationPhasesAdvFilter(s)}
                migrationStartDateFromDateAdvFilter={migrationStartDateFromDateAdvFilter}
                migrationStartDateToDateAdvFilter={migrationStartDateToDateAdvFilter}
                plannedFirstProductionDateFromDateAdvFilter={plannedFirstProductionDateFromDateAdvFilter}
                plannedFirstProductionDateToDateAdvFilter={plannedFirstProductionDateToDateAdvFilter}
                migrationPhasesAdvFilter={migrationPhasesAdvFilter}
                onPhaseDashboardChange={onPhaseDashboardChange}
                onStatusDashboardChange={onStatusDashboardChange}
                onSetOnPhaseDashboardChange={(s) => setOnPhaseDashboardChange(s)}
                onSetOnStatusDashboardChange={(s) => setOnStatusDashboardChange(s)}
                onSetStatusesFilterCard={(s) => setStatusesFilterCard(s)}
                statusesFilterCard={statusesFilterCard}
                onsetFilterCard={(s) => setFilterCard(s)}
                connectivityRequestTypeFilter={connectivityRequestTypeFilter}
                connectivityRequestSubTypeFilter={connectivityRequestSubTypeFilter}
                selectedConnectivityRequestTypesFilter={selectedConnectivityRequestTypesFilter}
                selectedConnectivityRequestSubTypesFilter={selectedConnectivityRequestSubTypesFilter}
                onConnectivityRequestTypeFilter={(s) => setConnectivityRequestTypeFilter(s)}
                onConnectivityRequestSubTypeFilter={(s) => setConnectivityRequestSubTypeFilter(s)}
                onSelectedConnectivityRequestTypesFilter={(s) => setSelectedConnectivityRequestTypesFilter(s)}
                onSelectedConnectivityRequestSubTypesFilter={(s) => setSelectedConnectivityRequestSubTypesFilter(s)}
                agingConnectivityFilter={agingConnectivityFilter}
                onSetAgingConnectivityFilter={(s) => setAgingConnectivityFilter(s)}
                agingConnectivityAdvFilter={agingConnectivityAdvFilter}
                onSetAgingConnectivityAdvFilter={(s) => setAgingConnectivityAdvFilter(s)}
                onSetResponsiblePartyFilter={(s) => setResponsiblePartyFilter(s)}
                responsiblePartyFilter={responsiblePartyFilter}
                onSetResponsiblePartyAdvFilter={(s) => setResponsiblePartyAdvFilter(s)}
                responsiblePartyAdvFilter={responsiblePartyAdvFilter}
                setRefreshClicked={(s) => setRefreshClicked(s)}
                clientEffectiveDateFromFilter={clientEffectiveDateFromFilter}
                onSetClientEffectiveDateFromFilter={(s) => setClientEffectiveDateFromFilter(s)}
                clientEffectiveDateToFilter={clientEffectiveDateToFilter}
                onSetClientEffectiveDateToFilter={(s) => setClientEffectiveDateToFilter(s)}
                clientEffectiveDateFromAdvFilter={clientEffectiveDateFromAdvFilter}
                onSetClientEffectiveDateFromAdvFilter={(s) => setClientEffectiveDateFromAdvFilter(s)}
                clientEffectiveDateToAdvFilter={clientEffectiveDateToAdvFilter}
                onSetClientEffectiveDateToAdvFilter={(s) => setClientEffectiveDateToAdvFilter(s)}
                isPEOFilter={isPEOFilter}
                onSetIsPEOFilter={setIsPEOFilter}
                isPEOAdvFilter={isPEOAdvFilter}
                onSetIsPEOAdvFilter={setIsPEOAdvFilter}
            />
        </div>
    )
}

export default ConnectivityRequestsDashboard;
