import React, { useEffect, useState } from 'react';
import * as Apis from '../../../../Data/APIs'
import { useLoader } from '../../../Layout/Loader';
import ClientFormsPendingBatchSubmissionTabSingleFeedWithForms from './ClientFormsPendingBatchSubmissionTabSingleFeedWithForms';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ClientFormsPendingBatchSubmissionTab = () => {
    const { openLoader, closeLoader } = useLoader();
    const [feeds, setFeeds] = useState([]);

    const [openSuccessSnackbar, setOpenSuccessSnackbar] = React.useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessSnackbar(false);
        setOpenErrorSnackbar(false);
    };

    const getFeedsHandler = () => {
        openLoader();
        Apis.getClientormsFeed({
            lsi: '14',
            HrSign: '0'
        }).then(r => {
            // debugger
            if (r.msg == "Success") {
                setFeeds(r?.data)
                // setTotalCount(r?.totalCount)
            } else setFeeds([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }

    useEffect(() => {
        getFeedsHandler()
    }, [])

    return (
        <div className="mt-3">
            <div className="row">
                <div className="d-flex flex-column w-100">
                    {feeds && feeds?.length > 0 ?
                        <>
                            {feeds.map((feed, feedIndex) =>
                                <div className="d-flex flex-column w-100" key={feedIndex}>
                                    <ClientFormsPendingBatchSubmissionTabSingleFeedWithForms
                                        openSuccessSnackbar={openSuccessSnackbar}
                                        onOpenSuccessSnackbar={(s) => setOpenSuccessSnackbar(s)}
                                        openErrorSnackbar={openErrorSnackbar}
                                        onOpenErrorSnackbar={(s) => setOpenErrorSnackbar(s)}
                                        feed={feed}
                                    />
                                </div>
                            )}
                        </>
                        :
                        <div className="no-data-alert">
                            <h6>No Results Found!</h6>
                        </div>

                    }
                </div>
            </div>
            <Snackbar open={openSuccessSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Transmission has started, it might take few minutes to complete.
                </Alert>
            </Snackbar>
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Transmission was unable to start. Please try again.
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ClientFormsPendingBatchSubmissionTab
