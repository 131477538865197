import React, { useState } from "react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ClearIcon from '@material-ui/icons/Clear';
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import SaveIcon from '@material-ui/icons/Save';
import { Button, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import { emailPattern } from "../ViewEditGroupRequest/ViewGroupRequestClientContactsRow";
import deleteForeverSVG from "../../../Icons/trash-xmark.svg";

function CreateContactRow({
    contact,
    index,
    fullContacts,
    parentForceUpdate,
    requestSubmitted,
    carrierContactTypes,
    ...props
}) {
    const [firstNameValidation, setFirstNameValidation] = useState("")
    const [lastNameValidation, setLastValidation] = useState("")
    const [emailValidation, setEmailValidation] = useState("")
    const [contactTypeValidation, setContactTypeValidation] = useState("")

    // First Name Validation
    React.useEffect(() => {
        if (!contact.ConnectivityRequestContactFirstName ||
            contact.ConnectivityRequestContactFirstName.split(" ").join("").length == 0)
            setFirstNameValidation("This Field is Required");
        else setFirstNameValidation("");
    }, [contact.ConnectivityRequestContactFirstName, firstNameValidation])

    // Last Name Validation
    React.useEffect(() => {
        if (!contact.ConnectivityRequestContactLastName
            || contact.ConnectivityRequestContactLastName.split(" ").join("").length == 0
        ) setLastValidation("This Field is Required");
        else setLastValidation("");
    }, [contact.ConnectivityRequestContactLastName, lastNameValidation])

    // Type Validation
    React.useEffect(() => {
        if (!contact.CarrierContactType) setContactTypeValidation("This Field is Required");
        else setContactTypeValidation("");
    }, [contact.CarrierContactType, contactTypeValidation])

    // Email Validation
    React.useEffect(() => {
        if (!contact.ConnectivityRequestContactEmail
            || contact.ConnectivityRequestContactEmail.split(" ").join("").length == 0) {
            setEmailValidation("This Field is Required");
        } else if (!emailPattern.test(contact.ConnectivityRequestContactEmail)) {
            setEmailValidation("Invalid Email Format");
        }
        else setEmailValidation("");
    }, [contact.ConnectivityRequestContactEmail, emailValidation])

    return (
        <div>
            <div className="row table-row">
                <div className="col-2 table-cell first-cell d-flex flex-column">
                    <input required type="text" className="text-input w-100" placeholder="Write first name here..." value={contact.ConnectivityRequestContactFirstName ?? ""}
                        onChange={e => {
                            contact.ConnectivityRequestContactFirstName = e.target.value;
                            parentForceUpdate();
                        }} />
                    {requestSubmitted && firstNameValidation &&
                        <span className="text-danger">
                            {firstNameValidation}
                        </span>
                    }
                </div>
                <div className="col-2 table-cell d-flex flex-column">
                    <input required type="text" className="text-input w-100" placeholder="Write last name here..." value={contact.ConnectivityRequestContactLastName ?? ""} onChange={e => { contact.ConnectivityRequestContactLastName = e.target.value; parentForceUpdate(); }} />
                    {requestSubmitted && lastNameValidation &&
                        <span className="text-danger">
                            {lastNameValidation}
                        </span>
                    }

                </div>
                <div className="col-3 table-cell d-flex flex-column">
                    <input required type="email" autoComplete='new-password' placeholder="Write email here..." className="text-input w-100" value={contact.ConnectivityRequestContactEmail ?? ""} onChange={e => { contact.ConnectivityRequestContactEmail = e.target.value; parentForceUpdate(); }} />
                    {requestSubmitted && emailValidation &&
                        <span className="text-danger">
                            {emailValidation}
                        </span>
                    }
                </div>
                <div className="col-3 table-cell d-flex flex-column">
                    <FormControl required label="Select Phase" focused={false} variant="outlined" className="w-100">
                        <Select style={{ textAlign: "left" }} className="text-dropdown" value={contact.CarrierContactType} onChange={e => { contact.CarrierContactType = e.target.value; parentForceUpdate(); }}>
                            {carrierContactTypes?.map((c, ci) => (
                                <MenuItem key={`carrier-contact-type-${ci}`} value={c}>{c}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {requestSubmitted && contactTypeValidation &&
                        <span className="text-danger">
                            {contactTypeValidation}
                        </span>
                    }
                </div>
                <div className="col-2 table-cell" style={{ justifyContent: "center" }}>
                    <Button className="icon-btn" color="inherit" onClick={e => {
                        fullContacts && fullContacts?.splice(index, 1);
                        parentForceUpdate();
                    }}>
                        {/* <DeleteForeverIcon style={{ fontSize: 30 }} /> */}
                        <svg  width="21" height="24" viewBox="0 0 21 24" >
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                    </Button>
                </div>
            </div>
        </div >
    )
}

export default CreateContactRow;
