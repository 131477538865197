import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import Dropdown from 'react-bootstrap/Dropdown'; // Import Dropdown components
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TabsTitlesWithMoreDD = ({ tabTitle, value, setValue, ...props }) => {
    const [visibleCount, setVisibleCount] = useState(0);
    const containerRef = useRef(null);
    const tabsRef = useRef([]);

    const updateVisibleCount = useCallback(() => {
        const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
        let totalWidth = 0;
        let count = 0;

        for (let i = 0; i < tabTitle.length; i++) {
            const tabWidth = tabsRef.current[i] ? tabsRef.current[i].offsetWidth : 0;
            totalWidth += (tabWidth + 1);

            if (totalWidth > containerWidth) {
                break;
            } else {
                count++;
            }
        }

        setVisibleCount(count);
    }, [tabTitle]);

    useEffect(() => {
        updateVisibleCount();
    }, [tabTitle, updateVisibleCount]);

    useEffect(() => {
        // Update the visible count after the component has mounted
        setTimeout(() => {
            updateVisibleCount();
        }, 0);
    }, [updateVisibleCount]);

    const handleMenuItemClick = (index) => {
        setValue(index);
    };

    const visibleTabs = tabTitle.slice(0, visibleCount);
    const hiddenTabs = tabTitle.slice(visibleCount);

    return (
        <div className='d-flex w-100'>
            <div ref={containerRef} className='w-100-minus-81 new-tabs' style={{ overflow: 'hidden' }}>
                <div
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {visibleTabs.map((title, index) => (
                        <button
                            className={`MuiTab-root ${value === index ? 'selected' : ''}`}
                            label={title}
                            key={index}
                            ref={(el) => (tabsRef.current[index] = el)}
                            onClick={() => setValue(index)}
                            type='button'
                        >
                            {title}
                        </button>
                    ))}
                </div>
            </div>
            {hiddenTabs.length > 0 && (
                <Dropdown className='more-btn-dd'>
                    <Dropdown.Toggle className={`more-tab-btn w-100 ${value >= visibleCount ? 'active' : ''}`} id="dropdown-basic">
                        More <KeyboardArrowDownIcon />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='more-tab-menu py-0'>
                        {hiddenTabs.map((title, index) => (
                            <Dropdown.Item
                                key={index + visibleCount}
                                active={(index + visibleCount) === value}
                                onClick={() => handleMenuItemClick(index + visibleCount)}
                                className='more-tab-menu-item'
                            >
                                {title}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </div>
    );
};

export default TabsTitlesWithMoreDD;
