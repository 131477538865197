import React from "react";
import Pagination from "../Layout/Pagination";
import { Button } from "@material-ui/core";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import VerifiedIcon from '@mui/icons-material/Verified';
import GenericTable from "../Layout/GenericTable";

function CarriersTable({
    carriersList,
    setCarriersList,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    quickFiltersClicked,
    ...props
}) {

    const [sortOrder, setSortOrder] = React.useState("ASC");

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();

    // -------------------------------- NEW TABLE ---------------------------------
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        onSortFieldFilter(column?.sortingkey);
        onSortOrderFilter(sortDirection?.toUpperCase());

    };

    const baseColumnsArray = [
        {
            name: 'Carrier Code',
            sortable: true,
            sortingkey: 'CarrierNumber',
            id: 'CarrierNumber',
            selector: (c) => c?.CarrierNumber
        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: (c) =>  <a href={`/Carriers/ViewCarrier/${c?.CarrierID}`}>{c?.CarrierName}</a>
        }

    ];

    return (
        <div className="mt-5" id="connectivity-group-requests-table">
            <GenericTable
                columns={[...baseColumnsArray]}
                data={carriersList}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection) : () => { }}
                />
            <div>
                <Pagination
                    stateLimit={true}
                    pageSize={pageSize}
                    page={selectedPage}
                    onChange={(t, v) => {
                        if (t === 1) setPageSize(v);
                        else if (t === 2) setSelectedPage(v);
                    }}
                    count={totalCount}
                />
            </div>
        </div>
    )
}

export default CarriersTable;