import React from "react";
import { Auth, API } from "aws-amplify";
import config from "../config";

const _jqtSessionStorageName = "iSolvedJWTStorage";
window.addEventListener("storage", function (event) {
  if (event.key === "getSessionStorage") {
    let savedSession = sessionStorage.getItem(_jqtSessionStorageName);
    if (!savedSession || savedSession.length === 0 || savedSession === "null") return;
    localStorage.setItem(
      "eBNLocalStorageAction",
      JSON.stringify({
        action: 4,
        value: CustomStorage._dataMemory,
      })
    );
    localStorage.removeItem("eBNLocalStorageAction");
  } else if (event.key === "eBNLocalStorageAction") {
    if (!event.newValue || event.newValue.length === 0 || event.newValue === "null") return;
    let action = JSON.parse(event.newValue);
    CustomStorage.handleAction(action.action, action.key, action.value);
  }
});

export class CustomStorage {
  // https://aws-amplify.github.io/docs/js/authentication#managing-security-tokens
  static _dataMemory = {};
  static _isWaitingForSession = false;

  static submitAction(action, key, value) {
    let result = CustomStorage.handleAction(action, key, value);
    localStorage.setItem(
      "eBNLocalStorageAction",
      JSON.stringify({
        action,
        key,
        value,
      })
    );
    localStorage.removeItem("eBNLocalStorageAction");
    return result;
  }
  static handleAction(action, key, value) {
    let result;
    if (action === 1) {
      CustomStorage._dataMemory[key] = value;
      sessionStorage.setItem(_jqtSessionStorageName, JSON.stringify(CustomStorage._dataMemory));
      result = value;
    } else if (action === 2) {
      result = delete CustomStorage._dataMemory[key];
    } else if (action === 3) {
      CustomStorage._dataMemory = {};
      result = CustomStorage._dataMemory;
    } else if (action === 4) {
      //refresh data
      CustomStorage._dataMemory = value ?? {};
      result = CustomStorage._dataMemory;
    }

    sessionStorage.setItem(_jqtSessionStorageName, JSON.stringify(CustomStorage._dataMemory));
    if (CustomStorage._isWaitingForSession) {
      CustomStorage._isWaitingForSession = false;
      if (action === 4 && value) window.location.reload();
    }
    return result;
  }

  static setItem(key, value) {
    return CustomStorage.submitAction(1, key, value);
  }
  static removeItem(key) {
    return CustomStorage.submitAction(2, key);
  }
  static clear() {
    return CustomStorage.submitAction(3);
  }

  static getItem(key) {
    return Object.prototype.hasOwnProperty.call(CustomStorage._dataMemory, key) ? CustomStorage._dataMemory[key] : undefined;
  }

  static async sync() {
    let savedSession = sessionStorage.getItem(_jqtSessionStorageName);
    if (!savedSession || savedSession.length === 0 || savedSession === "null") {
      CustomStorage._isWaitingForSession = true;
      localStorage.setItem("getSessionStorage", Date.now());
      return;
    }
    CustomStorage._dataMemory = JSON.parse(savedSession) ?? {};
  }
}

let env = process.env.REACT_APP_ENV?.toLowerCase()?.trim();
if (!env) env = "dev";

function parseJWT(token) {
  if (!token) return;
  else {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = JSON.parse(
      decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
    return jsonPayload;
  }
}
function parseLocalJWT(token) {
  var base64Url = token.split(".")[1];
  var jsonPayload = JSON.parse(
    decodeURIComponent(
      window
        .atob(base64Url)
    )
  );
  return jsonPayload;
}
function saveJWT(data) {
  let value = [];
  value.push(window.btoa(encodeURIComponent(JSON.stringify("time"))))
  value.push(window.btoa(encodeURIComponent(JSON.stringify(data))))
  return value.join(".");
}

function checkTokenExpiration() {
  const clientId = config.cognito[env + "APP_CLIENT_ID"];
  const userId = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);
  const accessToken = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${userId}.accessToken`)
  let localToken = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${userId}.localToken`)
  const payload = parseJWT(accessToken);
  let timeData = localToken ? parseLocalJWT(localToken) : undefined;
  if (payload?.exp * 1000 >= Date.now() || (timeData && timeData.exp * 1000 >= Date.now())) {
    timeData = { exp: Math.floor((Date.now() / 1000) + (20 * 60)) };
    localToken = saveJWT(timeData);
    localStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${userId}.localToken`, localToken);
  }
  else
    throw "Session Expired!";
}

// function checkTokenExpirationOld() {
//   const clientId = config.cognito[env + "APP_CLIENT_ID"];
//   const userId = CustomStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);
//   const accessToken = CustomStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${userId}.accessToken`)
//   let localToken = CustomStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${userId}.localToken`)
//   const payload = parseJWT(accessToken);
//   let timeData = localToken ? parseLocalJWT(localToken) : undefined;
//   if (payload?.exp * 1000 >= Date.now() || (timeData && timeData.exp * 1000 >= Date.now())) {
//     timeData = { exp: Math.floor((Date.now() / 1000) + (20 * 60)) };
//     localToken = saveJWT(timeData);
//     CustomStorage.setItem(`CognitoIdentityServiceProvider.${clientId}.${userId}.localToken`, localToken);
//   }
//   else
//     throw "Session Expired!";
// }
export async function getJwtToken() {
  try {
    checkTokenExpiration();
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    if (error !== "not authenticated" && error !== "No current user") {
      console.log("Auth Error:", error);
    }
  }
}
export async function getJwtAccessToken() {
  try {
    checkTokenExpiration();
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (error) {
    if (error !== "not authenticated" && error !== "No current user") {
      console.log("Auth Error:", error);
    }
  }
}

Array.prototype.any = function (condition) {
  for (let i = 0; i < this.length; i++) {
    if (!condition || condition(this[i])) return true;
  }
  return false;
};
Array.prototype.first = function (condition) {
  for (let i = 0; i < this.length; i++) {
    if (!condition || condition(this[i])) return this[i];
  }
  return undefined;
};
String.prototype.inList = function (list) {
  const value = this.toLowerCase();
  for (let i = 0; i < list.length; i++) {
    if (list[i]?.toLowerCase() === value) return true;
  }
  return false;
};
String.prototype.inAllList = function (list) {
  const value = this.toLowerCase();
  if (list.length === 0) return false;
  for (let i = 0; i < list.length; i++) {
    if (list[i]?.toLowerCase() !== value) return false;
  }
  return true;
};

//https://github.com/jspruance/aws-cognito-tutorial-complete
export const AuthContext = React.createContext({
  isAuthenticated: false,
  isAuthenticating: true,
  authUser: null,
  authRefresh: (bypassCache, call, onFinally) => { console.log(""); },
  signOut: () => { },
});
function signOut(call, onFinally) {
  Auth.signOut()
    .then((r) => {
      if (call) call(r);
    })
    .finally(() => {
      if (onFinally) onFinally();
    });
}
export function useAuthentication() {
  const authContext = React.useContext(AuthContext);
  return authContext;
}
export function signInUsingCode(code, call, onError, onFinally) {
  API.post("AuthApiCall", `api/jwt/from/code`, { body: { code: code } })
    .then((d) => {
      CustomStorage.setItem(`CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.${d.username}.idToken`, d.IdToken);
      CustomStorage.setItem(`CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.${d.username}.accessToken`, d.AccessToken);
      CustomStorage.setItem(`CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.${d.username}.refreshToken`, d.RefreshToken);
      CustomStorage.setItem(`CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.${d.username}.clockDrift`, "1");
      CustomStorage.setItem(`CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.LastAuthUser`, d.username);
      if (call) call(d);
    })
    .catch((err) => {
      console.log("Error signInUsingCode:", err);
      CustomStorage.clear();
      if (onError) onError(err);
    })
    .finally(() => {
      if (onFinally) onFinally();
    });
}
export function generateAuthCode(username, password, call, onError, onFinally) {
  API.post("AuthApiCall", `apidb/jwt/create/code`, {
    body: { username: username, password: password },
  })
    .then((d) => {
      if (call) call(d.code);
    })
    .catch((err) => {
      console.log("Error generateAuthCode:", err);
      if (onError) onError(err);
    })
    .finally(() => {
      if (onFinally) onFinally();
    });
}

export function AuthContextProvider({ ...props }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isAuthenticating, setIsAuthenticating] = React.useState(true);
  const [authUser, setAuthUser] = React.useState();
  const [isConsultantUser, setIsConsultantUser] = React.useState(false);
  const authRefresh = React.useCallback((bypassCache, call, onFinally) => {
    (async () => {
      checkTokenExpiration();
      const session = await Auth.currentSession();
      setIsAuthenticated(true);
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: bypassCache === true,
      });


      setAuthUser(user);
      if (user.signInUserSession && user.signInUserSession.accessToken && user.signInUserSession.accessToken.payload["cognito:groups"])
        user.signInUserSession.accessToken.payload["cognito:groups"].forEach((g) => {
          if (g.toLowerCase() === "consultant") setIsConsultantUser(true);
        });
      session.getAccessToken();
      if (call) call(user, session);
    })()
      .catch((error) => {
        console.log("Auth Error:", error);
        if (error !== "not authenticated" && error !== "No current user") {
          console.log("Auth Error:", error);
        }
      })
      .finally(() => {
        setIsAuthenticating(false);
        if (onFinally) onFinally();
      });
  }, []);
  React.useEffect(() => {
    authRefresh();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isAuthenticating,
        authUser,
        authRefresh,
        isConsultantUser,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
