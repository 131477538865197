import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ConnectivityRow from "./ConnectivityRow";
import { useAuthentication } from "../../../Data/Authentication";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));



function GroupConnectivityRequestsTable({ groupReq, setGroupReq, setSelectedSubgroupsView, addConnectivityClicked, setAddConnectivityClicked, connectivities, ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const classes = useStyles();
    const [connectivitiesList, setConnectivitiesList] = React.useState([]);
    const carrierContactTypes = ["Test", "Production/Data Issues"];


    React.useEffect(() => {
        if (!connectivities) return;
        setConnectivitiesList(groupReq.connectivities);
    }, [connectivities]);


    return (
        <div className="connectivity-request-parent">
            <div id="connectivity-requests" className={"cr-section " + classes.root}>
                <div className="row table-header">
                    <div className="col table-cell">
                        CR ID
                    </div>
                    <div className="col table-cell">
                        Carrier Name
                    </div>
                    <div className="col table-cell">
                        Carrier Code
                    </div>
                    <div className="col table-cell">
                        Type
                    </div>
                    <div className="col table-cell">
                        Carrier Plans
                    </div>
                    <div className="col table-cell">
                        Status
                    </div>
                    <div className="col-7 table-cell">
                        Carrier Contacts
                    </div>
                </div>
                <div className="row table-subheader">
                    <div className="col-1">

                    </div>
                    <div className="col-4">

                    </div>
                    <div className="col-7 px-0">
                        <div className="row">

                            <div className="col table-cell">
                                First Name
                            </div>
                            <div className="col table-cell">
                                Last Name
                            </div>
                            {(userActions?.includes("23") ||
                                userActions?.includes("20")) && (
                                    <>
                                        <div className="col-5 table-cell">
                                            Email
                                        </div>
                                        <div className="col table-cell">
                                            Type
                                        </div>
                                        <div className="col-1 table-cell">
                                            Actions
                                        </div>
                                    </>
                                )
                            }
                            {!(userActions?.includes("23") ||
                                userActions?.includes("20")) && (
                                    <>
                                        <div className="col-5 table-cell">
                                            Email
                                        </div>
                                        <div className="col table-cell">
                                            Type
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
                {connectivitiesList?.map((c, ci) => (
                    <ConnectivityRow
                        key={`connectivity-row-${ci}`}
                        connectivityKey={ci}
                        connectivity={c}
                        addConnectivityClicked={addConnectivityClicked}
                        groupReq={groupReq}
                        setGroupReq={setGroupReq}
                        setSelectedSubgroupsView={setSelectedSubgroupsView}
                        setAddConnectivityClicked={setAddConnectivityClicked}
                        setConnectivitiesList={setConnectivitiesList}
                        carrierContactTypes={carrierContactTypes}
                    />
                ))}
            </div>
        </div>
    )
}

export default GroupConnectivityRequestsTable;