import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Logo from '../../Data/WhiteLabelData.json';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import falUser from "../../IconsSidebar/fal-user.svg";
import falSignOut from "../../IconsSidebar/fal-sign-out.svg";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "../../Data/Authentication";
import config from "../../config.json";
import UserAvatar from "./UserAvatar";

const drawerWidth = 110;

let env = process.env.REACT_APP_ENV?.toLowerCase()?.trim();
if (!env) env = "dev";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Header({ open, onOpen, instanceURL, userRoles, ...props }) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const routeHistory = useHistory();
  const { authUser } = useAuthentication();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClose = (key) => {
    setAnchorElUser(null);
    if (key?.target.textContent?.toLowerCase() === "profile") {
      routeHistory.push("/Profile");
    }
    if (key?.target.textContent?.toLowerCase() === "sign out") {
      handleSignOut();
    }
  };

  const handleDrawerOpen = () => {
    onOpen(true);
  };

  function handleSignOut() {
    Auth.signOut()
      .then((r) => {
        const clientId = authUser?.signInUserSession.accessToken.payload["client_id"];
        const domain = config.api[env + "CognitoDomainURL"];
        const logoutURI = `${instanceURL}/logout`;
        //this route is the logout endpoint from aws amplify and should match the allowed sign out URLs inside the aws console.
        window.location.href = `${domain}/logout?client_id=${clientId}&logout_uri=${logoutURI}`
      })
      .catch((error) => {
        console.log("Signout Error:", !error.message ? error : error.message);
      });
  }

  return (
    <AppBar style={{ background: 'var(--black-primary-color)' }} position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: 'none' }),
          }}
        >
          {userRoles?.some(x => x !== "6" && x !== "10") && <MenuIcon />}
        </IconButton>
        <Link className='header-logo' href="/">
          <Box
            component="img"
            sx={{ height: 24 }}
            alt="Logo"
            src={Logo[0].iconUrl}
          />
        </Link>

        <Box className={anchorElUser ? "avatar-navbar selected" : "avatar-navbar"}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <UserAvatar user={authUser?.attributes ?? ""} />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="account-menu"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            slotprops={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }
            }}
          >
            {!userRoles?.includes("10") && (
              <>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <svg viewBox="0 0 14 16" width="14" height="16">
                      <use xlinkHref={`${falUser}#fal-user`}></use>
                    </svg>
                  </ListItemIcon>
                  Profile
                </MenuItem>
                <Divider />
              </>
            )}
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <svg viewBox="0 0 16 12" width="16" height="12">
                  <use xlinkHref={`${falSignOut}#fal-sign-out`}></use>
                </svg>
              </ListItemIcon>
              Sign Out
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar >
  );
}
