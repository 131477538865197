import React from "react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Divider, Button } from "@material-ui/core";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useAuthentication } from "../../../../Data/Authentication";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { FailureModal } from "../../../Modals/FailureModal";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";

function GenericNewSectionAnalyst({ onEdit, genericSection, currentTimestamp, setIsNextActive, setOnDeletedSection, setIsSaveActive = (_) => { }, setIsNewSectionAdded = (_) => { }, ...props }) {

    const dialog = useModalDialog(0);
    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const { authUser } = useAuthentication();

    const [connectivityReq, setConnectivityReq] = React.useState();

    const [genericArray, setGenericArray] = React.useState(genericSection.Model ?? []);

    React.useEffect(() => {
        if (genericSection) genericSection.Model = genericArray;
    }, [genericArray]);

    const [, forceUpdate] = React.useReducer(x => !x, false);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        if (connectivityReq) closeLoader();
        else openLoader();
    }, [connectivityReq])

    React.useEffect(() => {
        const carrierSectionCondition = genericSection.Model.map(s => s.CarrierSection).filter(f => f === "");
        const commentsCondition = genericSection.Model.map(s => s.Comment.Text).filter(f => f === "");
        if (genericSection.Model.length > 0 && carrierSectionCondition.length <= 0 && commentsCondition.length <= 0) setIsNextActive(true); else setIsNextActive(false);
        if (genericSection.Model.length === 0) { setIsSaveActive(false); }
        else {
            if (genericSection.Model.length > 0 && carrierSectionCondition.length <= 0 && commentsCondition.length <= 0) { setIsSaveActive(true); setIsNewSectionAdded(false); }
            else setIsSaveActive(false);
        }



    }, [JSON.stringify(genericSection.Model)])

    const handleDeleteSectionConfirm = () => {
        openModal(0, () => { setOnDeletedSection(true); setIsSaveActive(true); setIsNewSectionAdded(false); }, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this section?`,
        })
    }

    return (
        <div>
            <div className="row mt-5 w-100 alignvh-center">
                <h5 className="black1 fw-700" style={{ textTransform: "capitalize" }}>{genericSection.Title} Section</h5>
                <div className="ml-auto">
                    <Button variant="contained" className="blue-btn" onClick={() => {
                        genericSection.Model = ([...genericSection.Model, {
                            GUID: uuidv4(),
                            CarrierSection: "",
                            Action: 1,
                            Comment: {
                                Timestamp: currentTimestamp,
                                Text: "",
                                UserID: authUser?.attributes["sub"],
                                Name: `${authUser?.attributes["custom:FirstName"]} ${authUser?.attributes["custom:LastName"]}`,
                                Email: `${authUser?.attributes["email"]}`
                            }
                        }]); forceUpdate();
                    }}>
                        <label style={{ cursor: "pointer" }} className="mb-0">
                        <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                                            </svg>
                             Add {genericSection.Title}</label>
                    </Button>
                    <Button variant="contained" className="blue-outlined-btn ml-3" onClick={handleDeleteSectionConfirm}>
                        <label style={{ cursor: "pointer" }} className="mb-0">Delete Section</label>
                    </Button>
                </div>
            </div>
            <Divider className="mt-3 mb-5" />

            {genericSection.Model.length <= 0 && (
                <div className="no-data-alert">
                    <h6>Please start adding {genericSection.Title}</h6>
                </div>
            )}

            {genericSection.Model?.map((p, pi) => (
                <div key={`generic-${p.GUID}`} className="row mb-2">
                    <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Carrier {genericSection.Title}</h6>
                        <span className="text-danger mb-2 ml-2 mr-4">*</span>
                        <input value={p.CarrierSection ?? ""} onChange={e => { p.CarrierSection = e.target.value; forceUpdate(); }} type="text" className="text-input w-100" />
                    </div>
                    <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Analyst Comment</h6>
                        <span className="text-danger mb-2 ml-2 mr-4">*</span>
                        <textarea value={p.Comment?.Text ?? ""} onChange={e => { p.Comment.Text = e.target.value; forceUpdate(); }} style={{ minWidth: "unset", width: "100%" }} rows={3} />
                    </div>
                    <div className="col-xl-1 alignvh-center">
                        <CustomTooltip TransitionComponent={Zoom} title={genericSection.Model.length === 1 ? `There should be at least one matched ${genericSection.Title}` : ""}>
                            <span>
                                <Button className="icon-btn" disabled={genericSection.Model.length === 1} onClick={e => { genericSection.Model.splice(pi, 1); forceUpdate() }}>
                                    {/* <DeleteForeverIcon style={{ width: "30px", height: "30px" }} color="action" /> */}
                                    <svg  width="21" height="24" viewBox="0 0 21 24"  style={{ cursor: "pointer" }}>
                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                    </svg>
                                </Button>
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            ))}
        </div >
    )
}

export default GenericNewSectionAnalyst;