import React, { useState } from 'react'
import Moment from 'moment'
import * as Apis from '../../Data/APIs'
import { useLoader } from '../Layout/Loader';
import { useEffect } from 'react';
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import Truncate from 'react-truncate';
import { Link } from "react-router-dom";
import { toESTTime } from './EmployeeDashboardPendingFormsTab';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from "@material-ui/core";
import { useAuthentication } from '../../Data/Authentication';
import downloadIconSVG from "../../Icons/downloadIcon.svg";
import GenericTable from '../Layout/GenericTable';

const EmployeeDashboardRejectedFormsTab = () => {
    const dialog = useModalDialog(0);

    const { openLoader, closeLoader } = useLoader();
    const [rejectedForms, setRejctedForms] = useState([])
    const [lastEvaluatedKey, setlastEvaluatedKey] = useState(null);
    const { authUser } = useAuthentication();
    let userLegalID = authUser?.attributes["custom:LegalID"]
    let userAccountID = authUser?.attributes["custom:AccountID"]
    let userClientID = authUser?.attributes["custom:ClientID"]

    const getFormsHandler = () => {
        openLoader();
        let currentFeedInfo = {
            // 4 >> Rejected Forms
            roleID: "10",
            lsi: '4',
            userClientID: userClientID ?? '',
            userAccountID: userAccountID ?? '',
            userLegalID: userLegalID ?? ''
        };
        if (lastEvaluatedKey) currentFeedInfo.LastEvaluatedKey = lastEvaluatedKey
        Apis.getEmployeeForms({ ...currentFeedInfo }).then(r => {
            // debugger
            if (r.msg == "Success") {
                setRejctedForms([...rejectedForms, ...r?.data]);
                setlastEvaluatedKey(r?.LastEvaluatedKey ? r?.LastEvaluatedKey : null)
            } else setRejctedForms([])

        }).catch((error) => {
            console.log('Get Rejected Forms Error', error);
        }).finally((t) => closeLoader());
    }

    useEffect(() => {
        getFormsHandler()
    }, [])

    const handleDownloadFile = (form) => {
        let viewFormObject = {
            OutputFile: form?.outputFile,
            isDF: true,
            feedID: form?.feedID,
            executionID: form?.executionID,
            fileName:
                form?.empName + " - " +
                form?.carrierName + " - " +
                (form?.formGenerationTime ? Moment(toESTTime(form?.formGenerationTime)).format('MM-DD-YYYY') : "N/A")
        }
        openLoader();
        Apis.generateOldPdfUrlForEmployees(viewFormObject).then((r) => {
            window.open(r?.data);
            closeLoader();
        });
    }
    // ================================================================NEW TABLE UI 
    const baseColumnArray = [
        {
            name: 'Carrier',
            sortable: false,
            id: 'carrierName',
            selector: form => form?.carrierName
        },
        {
            name: 'Form Type',
            sortable: false,
            id: 'formType',
            selector: form => form?.formType?.replace('Enrolment', 'Enrollment')
        },
        {
            name: 'Generation Timestamp',
            sortable: false,
            id: 'formGenerationTime',
            selector: form => form.formGenerationTime ? Moment(toESTTime(form?.formGenerationTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"
        },
        {
            name: 'Rejection Timestamp',
            sortable: false,
            id: 'rejectionTime',
            selector: form => form.rejectionTime ? Moment(toESTTime(form?.rejectionTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"
        },
        {
            name: 'Rejected By',
            sortable: false,
            id: 'rejectedBy',
            selector: form => form?.rejectedBy
        },
        {
            name: 'Rejection Reason',
            sortable: false,
            id: 'rejectionReason',
            selector: form =>
                <Truncate lines={2} ellipsis={<span>... <Link to="/" style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        dialog.openDialog(<span style={{ whiteSpace: "break-spaces", fontSize: 12 }}>{form.rejectionReason}</span>)
                    }}>Read More</Link></span>}>
                    <span>{form.rejectionReason}</span>
                </Truncate>
        },
        {
            name: 'Actions',
            sortable: false,
            id: 'Actions',
            selector: form =>
                <button className="d-flex my-1 btn" type='buttton' onClick={() => handleDownloadFile(form)}>
                    <svg width="20" height="20" viewBox="0 0 20 20" >
                        <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                    </svg>
                    <span className="ml-2 fs-12" style={{ color: "var(--dt-cell-gray)", textWrap: "nowrap" }}>Download</span>
                </button>
        },

    ]

    return (
        <div>
            <GenericTable
                columns={[...baseColumnArray]}
                data={rejectedForms || []}
                onSort={() => { }}
            />
            <div className="text-center mt-3">
                <Button className="load-more-btn blue-btn" disabled={lastEvaluatedKey == null} onClick={() => getFormsHandler()}>Load More Results</Button>
            </div>
        </div>
    )
}

export default EmployeeDashboardRejectedFormsTab
