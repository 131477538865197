import React from "react";
import Truncate from 'react-truncate';
import { Link } from "@material-ui/core";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Button from "@material-ui/core/Button";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { useCommentsModalDialog } from "../../../Layout/CommentsModalDialog";
import { Comments } from "../../Comments";
import ContentBody from "../../ContentBody";
import { useAuthentication } from "../../../../Data/Authentication";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";

function GenericNewSectionAnalystView({ onEdit, currentTimestamp, genericSection, setIsNextActive, setOnDeletedSection, setIsSaveActive, ...props }) {

    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const commentsDialog = useCommentsModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [customerPlans, setCustomerPlans] = React.useState([]);
    const [carrierPlans, setCarrierPlans] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [index, setIndex] = React.useState(-1);
    const { authUser } = useAuthentication();

    const [genericArray, setGenericArray] = React.useState([]);
    const [genericSectionOriginalLength, setGenericSectionOriginalLength] = React.useState();

    const _colorOptions = {
        primaryAnalystColor: "var(--yellow-secondary-color)",
        primaryAdminColor: "var(--purple)"
    };

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupPlans(connectivityReq?.ConnectivityRequestID).then((r) => (
            setCustomerPlans(r?.data),
            closeLoader()
        ));
    }, [connectivityReq])

    React.useEffect(() => {
        setCarrierPlans(genericSection?.Model.map(s => ({
            carrierPlanName: s.CarrierPlanName,
            carrierPlanType: s.PlanType
        })))
    }, [genericSection])

    const handleFunction = (value) => {
        setIndex(value);
        const commentsBody = {
            Type: genericSection.Type,
            GUID: genericSection.Model[value].GUID
        }

        APIs.getPreMappingComments(commentsBody).then((r) => {
            setComments(r?.data); forceUpdate();
        })
    }

    React.useEffect(() => {
        if (index === -1) return;
        handleFunction(index); forceUpdate();
    }, [index])

    React.useEffect(() => {
        if (comments === undefined || index === -1) return;
        genericSection.Model[index].Comment = comments[comments?.length - 1];
    }, [comments])

    React.useEffect(() => {
        if (onEdit === true)
            genericSection.Model.map(s => s.Action = 2)
        else return;
    }, [onEdit === true])

    React.useEffect(() => {
        setGenericSectionOriginalLength(genericSection.Model?.length);
    }, [])

    React.useEffect(() => {
        let lastElement = genericArray[genericArray.length - 1];
        if (genericSection && genericArray.length > 0) genericSection.Model = [...genericSection.Model, lastElement];
    }, [genericArray]);

    React.useEffect(() => {
        const carrierSectionCondition = genericArray.map(s => s.CarrierSection).filter(f => f === "");
        const commentsCondition = genericArray.map(s => s.Comment.Text).filter(f => f === "");
        if (carrierSectionCondition.length <= 0 && commentsCondition.length <= 0) { setIsNextActive(true); setIsSaveActive(true); } else { setIsNextActive(false); setIsSaveActive(false); };
        if (genericArray.length === 0 ? setIsSaveActive(false) : (carrierSectionCondition.length <= 0 && commentsCondition.length <= 0) ? setIsSaveActive(true) : setIsSaveActive(false));

    }, [JSON.stringify(genericSection.Model), JSON.stringify(genericArray)])

    const handleDeleteRecord = (index, value) => {
        if (genericSection.Model[index].Action === 2)
            genericSection.Model.splice(index, 1);
        else {
            genericSection.Model.splice(index, 1);
            genericArray.splice((genericArray.indexOf(value)), 1);
        }
        forceUpdate();
    }

    React.useEffect(() => {
        if (!onEdit) setGenericArray([]);
    }, [onEdit])

    return (
        <div id="generic-new-section">
            <div className="row mt-3">
                <div className="col-12">
                    <div className="table-header row">
                        <div className="col-xl-3 table-cell first-cell">Mapping {genericSection.Title}</div>
                        <div className="col-xl-9 table-cell">Comments</div>
                    </div>
                </div>
            </div>
            <div className="table-subheader row">
                <div className="col-xl-3 table-cell first-cell">Carrier {genericSection.Title}</div>
                <div className="col-xl-1 table-cell">User</div>
                {onEdit ? (
                    <>
                        <div className="col-xl-6 table-cell">Latest Comment</div>
                        <div className="col-xl-2 table-cell">Actions</div>
                    </>
                ) : (
                    <>
                        <div className="col-xl-7 table-cell">Latest Comment</div>
                        <div className="col-xl-1 table-cell">Actions</div>
                    </>
                )}
            </div>
            {genericSection?.Model?.map((p, pi) => (
                <div key={`generic-section-${pi}`} className="table-row row">
                    <div className="col-xl-3 table-cell first-cell">
                        {p.CarrierSection}
                    </div>
                    <div className="col-xl-1 table-cell">
                        <div className="userInfo">
                            <CustomTooltip TransitionComponent={Zoom} title={<div><span>{p.Comment?.Name}</span><br /><span>{p.Comment?.Email}</span></div>}>
                                <span className="user" style={{ backgroundColor: _colorOptions.primaryAnalystColor }}>{p.Comment?.Name.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                            </CustomTooltip>
                        </div>
                    </div>
                    {onEdit ? (
                        <>
                            <div className="col-xl-6 table-cell">
                                <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog(<span>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                                    <span>{p.Comment?.Text}</span>
                                </Truncate>
                            </div>
                            <div className="col-xl-2 table-cell">
                                <div className="comments-section-single-column">
                                    <Button onClick={e => {
                                        handleFunction(pi); Comments(commentsDialog, {
                                            title: p.CarrierSection,
                                            body: <ContentBody section={genericSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                        });
                                    }}>
                                        <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                                    </Button>
                                    <CustomTooltip TransitionComponent={Zoom} title={genericSection.Model.length === 1 || (genericSection.Model.length === 1 && genericArray.length === 1) ? `There should be at least one matched ${genericSection.Title}` : ""}>
                                        <span>
                                            <Button className="icon-btn" disabled={genericSection.Model.length === 1 || (genericSection.Model.length === 1 && genericArray.length === 1)} onClick={e => {
                                                openModal(0, () => {
                                                    handleDeleteRecord(pi, p)
                                                }, {
                                                    title: 'Please Confirm',
                                                    question: `Are you sure you want to delete this record?`,
                                                });
                                            }}>
                                                {/* <DeleteForeverIcon style={{ width: "25px", height: "25px" }} color="action" /> */}
                                                <svg  width="21" height="24" viewBox="0 0 21 24" >
                                                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                </svg>
                                            </Button>
                                        </span>
                                    </CustomTooltip>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-xl-7 table-cell">
                                <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog(<span>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                                    <span>{p.Comment?.Text}</span>
                                </Truncate>
                            </div>
                            <div className="col-xl-1 table-cell">
                                <div className="comments-section-single-column">
                                    <Button onClick={e => {
                                        handleFunction(pi); Comments(commentsDialog, {
                                            title: p.CarrierSection,
                                            body: <ContentBody section={genericSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                        });
                                    }}>
                                        <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                                    </Button>
                                </div>
                            </div>
                        </>
                    )
                    }
                </div >
            ))}

            {
                onEdit && (
                    <>
                        <div className="row mt-5 mb-5 w-100 alignvh-center">
                            <Button variant="contained" className="blue-btn" onClick={() => {
                                setGenericArray([...genericArray, {
                                    GUID: uuidv4(),
                                    CarrierSection: "",
                                    Action: 1,
                                    Comment: {
                                        Timestamp: currentTimestamp,
                                        Text: "",
                                        UserID: authUser?.attributes["sub"],
                                        Name: `${authUser?.attributes["custom:FirstName"]} ${authUser?.attributes["custom:LastName"]}`,
                                        Email: `${authUser?.attributes["email"]}`
                                    }
                                }]); forceUpdate();
                            }}>
                                <label style={{ cursor: "pointer" }} className="mb-0">
                                <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                                            </svg>
                                     Add {genericSection.Title}</label>
                            </Button>
                        </div>

                        {genericArray.length <= 0 && (
                            <div className="no-data-alert">
                                <h6>Please start adding {genericSection.Title}</h6>
                            </div>
                        )}

                        {genericArray?.map((p, pi) => (
                            <div key={`generic-${p.GUID}`} className="row mb-2">
                                <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                    <h6 className="form-title">Carrier {genericSection.Title}</h6>
                                    <span className="text-danger mb-2 ml-2 mr-4">*</span>
                                    <input value={p.CarrierSection ?? ""} onChange={e => { p.CarrierSection = e.target.value; forceUpdate(); }} type="text" className="text-input w-100" />
                                </div>
                                <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                                    <h6 className="form-title">Analyst Comment</h6>
                                    <span className="text-danger mb-2 ml-2 mr-4">*</span>
                                    <textarea value={p.Comment?.Text ?? ""} onChange={e => { p.Comment.Text = e.target.value; forceUpdate(); }} style={{ minWidth: "unset", width: "100%" }} rows={3} />
                                </div>
                                <div className="col-xl-1 alignvh-center">
                                    <CustomTooltip TransitionComponent={Zoom} title={(genericSection.Model.length === 1 && genericArray.length === 1) ? `There should be at least one matched ${genericSection.Title}` : ""}>
                                        <span>
                                            <Button disabled={genericSection.Model.length === 1 && genericArray.length === 1} className="icon-btn" onClick={e => {
                                                openModal(0, () => {
                                                    genericArray.splice(pi, 1);
                                                    genericSection.Model.splice((genericSection.Model.indexOf(p)), 1);
                                                    forceUpdate();
                                                }, {
                                                    title: 'Please Confirm',
                                                    question: `Are you sure you want to delete this record?`,
                                                });
                                            }}>
                                                {/* <DeleteForeverIcon style={{ width: "30px", height: "30px" }} color="action" /> */}
                                                <svg  width="21" height="24" viewBox="0 0 21 24" >
                                                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                </svg>
                                            </Button>
                                        </span>
                                    </CustomTooltip>
                                </div>
                            </div>
                        ))}
                    </>
                )
            }
        </div >
    )
}

export default GenericNewSectionAnalystView;