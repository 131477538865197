import React from "react";
import { Paper, TextField, Button, Select, Chip } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ClearIcon from '@material-ui/icons/Clear';
import { useAuthentication } from "../../../Data/Authentication";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HomeProductionDashboardFilterModalBody from "./HomeProductionDashboardFilterModalBody";
import Moment from 'moment';
import TruncatedChip from "../../Layout/TruncatedChip";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import refreshSVG from "../../../Icons/refresh.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../Icons/advancedFilter.svg";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const yearDate = new Date();
yearDate.setHours(0, 0, 0, 0)
yearDate.setFullYear(todayDate.getFullYear() - 1);

function HomeProductionDashboardFilter({
    onFeedIDFilter,
    onExecutionStatusFilter,
    onLastRunFromDateFilter,
    lastRunFromDateFilter,
    onLastRunToDateFilter,
    lastRunToDateFilter,
    onNextRunFromDateFilter,
    nextRunFromDateFilter,
    onNextRunToDateFilter,
    nextRunToDateFilter,
    setRefreshClicked,
    selectedPage,
    onSelectedPage,
    pageSize,
    onPageSize,
    totalCount,
    setTotalCount,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSizeAdv,
    onPageSizeAdv,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    feedIDAdvFilter,
    onFeedIDAdvFilter,
    selectedFeedNameAdvFilter,
    onSelectedFeedNameAdvFilter,
    selectedExecutionStatusAdvFilter,
    onSelectedExecutionStatusAdvFilter,
    lastRunFromDateAdvFilter,
    onLastRunFromDateAdvFilter,
    lastRunToDateAdvFilter,
    onLastRunToDateAdvFilter,
    nextRunFromDateAdvFilter,
    onNextRunFromDateAdvFilter,
    nextRunToDateAdvFilter,
    onNextRunToDateAdvFilter,
    ...props
}) {


    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [feedsList, setFeedsList] = React.useState([]);
    const [executionStatusesList, setExecutionStatusesList] = React.useState([]);

    const [selectedLastRunFromDate, setSelectedLastRunFromDate] = React.useState(null);
    const [selectedLastRunToDate, setSelectedLastRunToDate] = React.useState(null);
    const [selectedNextRunFromDate, setSelectedNextRunFromDate] = React.useState(null);
    const [selectedNextRunToDate, setSelectedNextRunToDate] = React.useState(null);
    const [feedName, setFeedName] = React.useState("");
    const [feedNameAutoValue, setFeedNameAutoValue] = React.useState("");
    const [feedID, setFeedID] = React.useState("");
    const [executionStatus, setExecutionStatus] = React.useState("");

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        onFeedIDFilter("");
        setFeedID("");
        setFeedName("");
        onExecutionStatusFilter("");
        setExecutionStatus("");
        onLastRunFromDateFilter(null);
        setSelectedLastRunFromDate(null);
        onLastRunToDateFilter(null);
        setSelectedLastRunToDate(null);
        onNextRunFromDateFilter(null);
        setSelectedNextRunFromDate(null);
        onNextRunToDateFilter(null);
        setSelectedNextRunToDate(null);
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
    };

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedName ?? "" }).then((r) => !(r?.data?.length) ? setFeedsList([]) : setFeedsList(r?.data));
        setFeedNameAutoValue(feedName);
    }, [feedName])

    React.useEffect(() => {
        APIs.getProductionExecutionStatusesList().then((r) => setExecutionStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        if (feedsList)
            closeLoader();
        else openLoader()
    }, [feedsList])

    const handleLastRunDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedLastRunFromDate(date);
            onLastRunFromDateFilter(date);
        }
        else {
            setSelectedLastRunToDate(date);
            onLastRunToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleNextRunDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedNextRunFromDate(date);
            onNextRunFromDateFilter(date);
        }
        else {
            setSelectedNextRunToDate(date);
            onNextRunToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleExecutionStatusChange = (e) => {
        setExecutionStatus(e.target.value);
        onExecutionStatusFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedIDFilter = (e) => {
        setFeedID(e.target.value);
        onFeedIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleClearSearch = () => {
        setFeedName("");
        setFeedID("");
        onFeedIDFilter("");
        setExecutionStatus("");
        onExecutionStatusFilter("");
        setSelectedLastRunFromDate(null);
        onLastRunFromDateFilter(null);
        setSelectedLastRunToDate(null);
        onLastRunToDateFilter(null);
        setSelectedNextRunFromDate(null);
        onNextRunFromDateFilter(null);
        setSelectedNextRunToDate(null);
        onNextRunToDateFilter(null);
        onSelectedPage(1);
    }

    const handleClearSearchReset = () => {
        setFeedName("");
        setFeedID("");
        onFeedIDFilter("");
        setExecutionStatus("");
        onExecutionStatusFilter("");
        setSelectedLastRunFromDate(null);
        onLastRunFromDateFilter(null);
        setSelectedLastRunToDate(null);
        onLastRunToDateFilter(null);
        setSelectedNextRunFromDate(null);
        onNextRunFromDateFilter(null);
        setSelectedNextRunToDate(null);
        onNextRunToDateFilter(null);
        onSelectedPage(1);
        onFeedIDAdvFilter("");
        onSelectedFeedNameAdvFilter([]);
        onSelectedExecutionStatusAdvFilter([]);
        onLastRunFromDateAdvFilter(null);
        onLastRunToDateAdvFilter(null);
        onNextRunFromDateAdvFilter(null);
        onNextRunToDateAdvFilter(null);
        onSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
    }

    return (
        <div>
            <div className="search-container">
                <div className="row alignvh-center">
                    <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                    <Button
                        hidden
                        className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                        onClick={handleClickOpenDialog}
                        style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1" >
                            <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                        </svg>Advanced Filters
                    </Button>
                    <React.Fragment>
                        {feedName && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feed: ${feedName}`} />}
                        {selectedFeedNameAdvFilter && selectedFeedNameAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Feeds: ${selectedFeedNameAdvFilter?.map(s => s.FeedName).join(" - ")}`} />}
                        {executionStatus && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Status: ${executionStatusesList?.filter(s => s.Id == executionStatus)[0].Name}`} />}
                        {selectedExecutionStatusAdvFilter && selectedExecutionStatusAdvFilter?.length > 0 && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Statuses: ${selectedExecutionStatusAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                        {quickFiltersClicked && lastRunFromDateFilter && lastRunToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Last Run Date: From: ${Moment(lastRunFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(lastRunToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && lastRunFromDateFilter && !lastRunToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Last Run Date: From: ${Moment(lastRunFromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !lastRunFromDateFilter && lastRunToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Last Run Date: To: ${Moment(lastRunToDateFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && lastRunFromDateAdvFilter && lastRunToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Last Run Date: From: ${Moment(lastRunFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(lastRunToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && lastRunFromDateAdvFilter && !lastRunToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Last Run Date: From: ${Moment(lastRunFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !lastRunFromDateAdvFilter && lastRunToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Last Run Date: To: ${Moment(lastRunToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && nextRunFromDateFilter && nextRunToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Next Run Date: From: ${Moment(nextRunFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(nextRunToDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && nextRunFromDateFilter && !nextRunToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Next Run Date: From: ${Moment(nextRunFromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !nextRunFromDateFilter && nextRunToDateFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Next Run Date: To: ${Moment(nextRunToDateFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && nextRunFromDateAdvFilter && nextRunToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Next Run Date: From: ${Moment(nextRunFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(nextRunToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && nextRunFromDateAdvFilter && !nextRunToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Next Run Date: From: ${Moment(nextRunFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !nextRunFromDateAdvFilter && nextRunToDateAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Next Run Date: To: ${Moment(nextRunToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                    </React.Fragment>
                </div>
                {quickFiltersClicked && (
                    <div className="row">
                        <div className="col-xl-3 mt-3">
                            <span>Feed Name:</span>
                            <br />
                            <Autocomplete
                                options={feedsList}
                                autoHighlight
                                className="search-dropdown w-100"
                                inputValue={feedNameAutoValue ?? ""}
                                getOptionLabel={(option) => option?.FeedName}
                                onChange={(e, value) => {
                                    setFeedName(value?.FeedName ?? "");
                                    onFeedIDFilter(value?.FeedID ?? "");
                                    setFeedID(value?.FeedID ?? "");
                                    onSelectedPage(1);
                                    onPageSize(30);
                                }}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option?.FeedName}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onChange={e => setFeedNameAutoValue(e.target.value)}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Status:</span>
                            <br />
                            <FormControl label="Select execution status" focused={false} className="w-100">
                                <Select
                                    className="search-dropdown"
                                    value={executionStatus}
                                    onChange={handleExecutionStatusChange}
                                >
                                    <MenuItem value="">None</MenuItem>

                                    {executionStatusesList?.map((c, ci) => (
                                        <MenuItem key={`execution-status-${ci}`} value={c?.Id}>{c?.Name}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </div>
                        <div hidden className="col-xl-3 mt-3">
                            <span>Last Run Date:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100">
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={lastRunFromDateFilter}
                                        onChange={handleLastRunDateChange("fromDate")}
                                        placeholder="From Daye"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16" >
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div hidden className="col-xl-3 mt-3">
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100" >
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={lastRunToDateFilter}
                                        onChange={handleLastRunDateChange("toDate")}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16" >
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <span>Next Run Date:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100" >
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={nextRunFromDateFilter}
                                        onChange={handleNextRunDateChange("fromDate")}
                                        placeholder="From Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16" >
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xl-3 mt-3">
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center w-100" >
                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={nextRunToDateFilter}
                                        onChange={handleNextRunDateChange("toDate")}
                                        placeholder="To Date"
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                )}
                <div className="col-xl-12 mt-4" >
                    <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                            <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                        </svg>
                        <span className="ml-1">Refresh List</span>
                    </Button>
                    <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                        </svg> Clear Search
                    </Button>
                </div>

            </div>

            <HomeProductionDashboardFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                onPageSizeAdv={onPageSizeAdv}
                onSelectedPageAdv={onSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                onCloseClearReset={handleClearSearchReset}
                onSelectedFeedNameAdvFilter={onSelectedFeedNameAdvFilter}
                onSelectedExecutionStatusAdvFilter={onSelectedExecutionStatusAdvFilter}
            />
        </div>
    )
}

export default HomeProductionDashboardFilter;