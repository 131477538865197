import React from 'react'
import { FormControl, Divider, Paper, Button, Select, MenuItem, Zoom } from "@material-ui/core";
import { useAuthentication } from "../../Data/Authentication";
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { Auth } from 'aws-amplify';
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { FailureModal } from "../Modals/FailureModal";

const EditProfile = ({ }) => {
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");


    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    // Validations
    const [firstNameValidation, setFirstNameValidation] = React.useState("");
    const [lastNameValidation, setLastNameValidation] = React.useState("");

    React.useEffect(() => {
        setFirstName(authUser?.attributes["custom:FirstName"] ?? "");
        setLastName(authUser?.attributes["custom:LastName"] ?? "");
    }, [authUser])

    React.useEffect(() => {
        if (!firstName) setFirstNameValidation("First name is missing, please write the User's first name."); else setFirstNameValidation("");
    }, [firstName, firstNameValidation])
    React.useEffect(() => {
        if (!lastName) setLastNameValidation("Last name is missing, please write the User's last name."); else setLastNameValidation("");
    }, [lastName, lastNameValidation])


    const enableBtn = () => {
        if (firstName == authUser?.attributes["custom:FirstName"] && lastName == authUser?.attributes["custom:LastName"]) {
            return true;
        }
        return false;

    }
    const submitEditProfile = (e) => {
        e.preventDefault();
        let updateUserAuth = {};
        let updateUserObject = {};
        if (requestSubmitted) {
            if (firstNameValidation || lastNameValidation) return;
            openLoader();
            updateUserAuth = {
                "custom:FirstName": firstName,
                "custom:LastName": lastName,

            }

            updateUserObject = {
                CognitoID: authUser?.attributes?.sub,
                FirstName: firstName,
                LastName: lastName,
                PhoneNumber: authUser?.attributes["phone_number"],

            }
            // Update user in data base 
            APIs.editUser(updateUserObject).then((r) => {

                if (r?.success === "0") {
                    FailureModal(dialog, {
                        title: 'Failure',
                        body: r?.msg,
                    });
                } else {
                    // Update user in Auth 
                    Auth.updateUserAttributes(authUser, updateUserAuth).then((r) => {
                        if (r?.success === "0") {
                            FailureModal(dialog, {
                                title: 'Failure',
                                body: r?.msg,
                            });
                        } else {

                            window.location.reload();
                        }
                    }).catch((s) => FailureModal(dialog, {
                        title: 'Failure',
                        body: 'Error processing request',
                    })).finally((t) => closeLoader());
                }
            }).catch((s) => FailureModal(dialog, {
                title: 'Failure',
                body: 'Error processing request',
            })).finally((t) => closeLoader());
        }
    }

    return (

        <div className='col-xl-6  outer-card-settings align-items-stretch  '>
            <form onSubmit={submitEditProfile} >
                <div className="row">
                    <div className="col-xl-12 alignvh-center">
                        <h6 className="form-title">First Name </h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-12  alignvh-center">
                        {!userRoles?.includes("6") ?(
                        <input required type="text" placeholder="First Name" className="text-input w-100" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        ):
                        <div className="textfield-div w-100">
                            {authUser?.attributes["custom:FirstName"]}
                        </div>
                        }
                        </div>
                    <div className="col-xl-12  alignvh-center">
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{firstNameValidation}</div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-12 alignvh-center">
                        <h6 className="form-title">Last Name </h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-12  alignvh-center">
                    {!userRoles?.includes("6") ?(
                        <input required type="text" placeholder="Last Name" className="text-input w-100" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    ):
                    <div className="textfield-div w-100">
                            {authUser?.attributes["custom:LastName"]}
                    </div>
                    }
                </div>
                    <div className="col-xl-12  alignvh-center">
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{lastNameValidation}</div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-12 alignvh-center">
                        <h6 className="form-title">Email </h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-12 alignvh-center">
                        <div className="textfield-div w-100">
                            {authUser?.attributes["email"]}
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xl-12 alignvh-center">
                        <h6 className="form-title">Phone Number  </h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-12  alignvh-center">
                        <div className="textfield-div w-100">
                            {authUser?.attributes["phone_number"]}
                        </div>
                    </div>

                </div>

                <div className="w-100 text-right mt-3 pt-3">
                    <Button type="submit" variant="contained" className="blue-btn" 
                    onClick={() => { setRequestSubmitted(true); }}
                    disabled= {userRoles?.includes("6")} // disabled in case role is client
                    >Save Changes </Button>
                </div>

            </form>
        </div>

    )
}

export default EditProfile



