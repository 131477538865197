import React from "react";
import Moment from 'moment';
import Pagination from "../../Layout/Pagination";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { useLoader } from "../../Layout/Loader";
import * as APIs from "../../../Data/APIs";
import { useHistory } from "react-router-dom";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { FailureModal } from "../../Modals/FailureModal";
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import { useAuthentication } from "../../../Data/Authentication";
import PreviewIcon from '@mui/icons-material/Preview';
import AddBoxIcon from '@mui/icons-material/AddBox';
import addActionItemSVG from "../../../Icons/addActionItem.svg";
import viewActionItemSVG from "../../../Icons/viewActionItem.svg";
import GenericTable from "../../Layout/GenericTable";
import viewPreMappingIcon from "../../../Icons/clipboard-check.svg";


function ConnectivityRequestsTable({
    tableRef,
    advancedFiltersClicked,
    quickFiltersClicked,
    connectivityRequestsList,
    setConnectivityRequestsList,
    selectedPage,
    pageSize,
    onPageSize,
    onSelectedPage,
    totalCount,
    onSortFieldFilter,
    onSortOrderFilter,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSizeAdv,
    onPageSizeAdv,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const dialog = useModalDialog(0);
    const [sortOrder, setSortOrder] = React.useState("ASC");
    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const [connectivityRequestID, setConnectivityRequestID] = React.useState();
    const [responseMsg, setResponseMsg] = React.useState("");
    const [index, setIndex] = React.useState(-1);
    const [openPMFClicked, setOpenPMFClicked] = React.useState(false);
    const [preMappingObject, setPreMappingObject] = React.useState();
    const [preMappingBasicData, setPreMappingObjectBasicData] = React.useState();
    const [preMappingSections, setPreMappingSections] = React.useState([]);

    // //=================================================================SORTING

    const handleCheckAdmin = (indexValue) => {
        openLoader();
        APIs.getPreMapping(connectivityRequestsList[indexValue].ConnectivityRequestID).then((r) => {
            setPreMappingObject(r?.data);
            setPreMappingObjectBasicData(r?.data?.PreMappingBasicData);
            setPreMappingSections(r?.data?.Sections);
            setResponseMsg(r?.msg);
            if (r?.msg === "Invalid  ConnectivityRequestID") {
                FailureModal(dialog, {
                    title: 'Cannot Open!',
                    body: `There is no Pre-Mapping form for this Connectivity Request`,
                });
            }
            else if (r?.msg !== "Invalid  ConnectivityRequestID") {
                if (r?.data?.Sections[0]?.Model.any(s => (s.GroupPlans)))
                    routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestsList[indexValue]?.ConnectivityRequestID}/ViewPreMappingFormISolved`);
                else routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestsList[indexValue]?.ConnectivityRequestID}/FillPreMappingFormISolved`);
            }
            setOpenPMFClicked(false);
            closeLoader();
        });
    }

    const handleCheckAnalyst = (indexValue) => {
        openLoader();
        APIs.getPreMapping(connectivityRequestsList[indexValue].ConnectivityRequestID).then((r) => {
            setPreMappingObject(r?.data);
            setPreMappingObjectBasicData(r?.data?.PreMappingBasicData);
            setPreMappingSections(r?.data?.Sections);
            setResponseMsg(r?.msg);
            if (r?.msg === "Invalid  ConnectivityRequestID")
                routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestsList[indexValue]?.ConnectivityRequestID}/CreatePreMappingFormAnalyst`);
            else routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestsList[indexValue]?.ConnectivityRequestID}/ViewPreMappingFormAnalyst`);
            setOpenPMFClicked(false);
            closeLoader();
        });
    }

    const isIsovedSuperAdminOrSuperAdmin = () => {
        //attached action ID to isolvedSuperAdmin
        return userActions?.includes("60") || userActions?.includes("20");
    }

    const notisIsovedSuperAdminOriSolvedUser = () => {
        //attached user roles ID for analyst and super admin
        return (userRoles?.includes("1") ||
            userRoles?.includes("7"));
    }
    // -----------------------------------------------------------------NEW TABLE
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        if (type === "quick") {
            onSortFieldFilter(column?.sortingkey);
            onSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            onSortFieldAdvFilter(column?.sortingkey);
            onSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const baseColumnsArray = [
        {
            name: 'Client Name',
            sortable: true,
            sortingkey: 'GroupName',
            id: 'GroupName',
            selector: c => <a href={`/GroupRequests/ViewGroupRequest/${c?.GroupRequestID}`}>{c?.PeoName ? c?.PeoName : c?.GroupName}</a>

        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: c => <a href={`/ConnectivityRequests/ViewConnectivityRequest/${c?.ConnectivityRequestID}`}>{c?.CarrierName === "Third Party" ? `${c?.CarrierName} (${c?.ThirdPartyName})` : c?.CarrierName}</a>
        },
        // =====>partner Here (2)
        {
            name: 'Type',
            sortable: true,
            width: '60px',
            sortingkey: 'ConnectivityRequestType',
            id: 'ConnectivityRequestType',
            selector: c => c?.ConnectivityRequestType
        },
        {
            name: 'Sub Type',
            sortable: true,
            sortingkey: 'ConnectivityRequestEDISubTypeName',
            id: 'ConnectivityRequestEDISubTypeName',
            selector: c => c?.ConnectivityRequestEDISubTypeName
        },
        {
            name: 'Feed Data Type',
            sortable: true,
            sortingkey: 'DataType',
            id: 'DataType',
            selector: c => c?.DataType
        },
        {
            name: 'Created Date',
            sortable: true,
            sortingkey: 'Created',
            id: 'Created',
            selector: c => Moment(c?.Created).format('MM/DD/YYYY')
        },
        {
            name: 'Assigned To',
            sortable: true,
            sortingkey: 'AssignedTo',
            id: 'AssignedTo',
            selector: c => c?.AssignedTo
        },
        {
            name: 'Status',
            sortable: true,
            sortingkey: 'ConnectivityRequestStatusName',
            id: 'ConnectivityRequestStatusName',
            selector: c => <div className={`status-div 
                ${c?.ConnectivityRequestStatusName === "In Progress" ? `inProgress` :
                    c?.ConnectivityRequestStatusName === "On Hold" ? `onHold` :
                        c?.ConnectivityRequestStatusName === "Blocked" ? `blocked` :
                            c?.ConnectivityRequestStatusName === "Cancelled" ? `cancelled` :
                                c?.ConnectivityRequestStatusName === "Not Started" ? `notStarted` :
                                    c?.ConnectivityRequestStatusName === "Production" ? `production` : `productionDisabled`}`}>
                {c?.ConnectivityRequestStatusName}
            </div>
        },
        {
            name: 'Phase',
            sortable: true,
            sortingkey: 'ConnectivityRequestPhasesName',
            id: 'ConnectivityRequestPhasesName',
            selector: c => <div style={{ wordBreak: "keep-all" }} className={`phase-div 
                ${c?.ConnectivityRequestPhasesName === "Not Started" ? `notStarted` :
                    c?.ConnectivityRequestPhasesName === "Requirements Gathering" ? `requirementGathering` :
                        c?.ConnectivityRequestPhasesName === "Obtain Customer Dataset" ? `obtainCustomerDataset` :
                            c?.ConnectivityRequestPhasesName === "Resource Assignment" ? `resourceAssignment` :
                                c?.ConnectivityRequestPhasesName === "Dataset Validation" ? `datasetValidation` :
                                    c?.ConnectivityRequestPhasesName === "Mapping" ? `mapping` :
                                        c?.ConnectivityRequestPhasesName === "Testing" ? `testing` :
                                            c?.ConnectivityRequestPhasesName === "Ready for Production" ? `readyforproduction` : `production`}`}>
                {c?.ConnectivityRequestPhasesName}
            </div>
        },
        {
            name: 'Migration',
            sortable: true,
            sortingkey: 'MigrationConnectivityRequest',
            id: 'MigrationConnectivityRequest',
            selector: c => c?.MigrationConnectivityRequest
        },
        {
            name: 'Pre-Mapping Form',
            sortable: false,
            id: 'PreMappingForm',
            selector: (c, ci) => <>
                {(userActions?.includes("21")) && (
                    <div>
                        <button className="btn-like-link" onClick={(e) => {
                            e.preventDefault();
                            setOpenPMFClicked(true);
                            handleCheckAdmin(ci);
                        }}>
                            {c?.PreMappingAttached === true ?
                                <CustomTooltip TransitionComponent={Zoom} title="View Pre-Mapping Form">
                                    <svg width="19" height="18" viewBox="0 0 19 18" style={{ cursor: "pointer" }}>
                                        <use xlinkHref={`${viewPreMappingIcon}#clipboard-check`}></use>
                                    </svg>
                                </CustomTooltip> : <></>
                                // <CustomTooltip TransitionComponent={Zoom} title="No Pre-Mapping Form">
                                //     <svg width="18" height="18" viewBox="0 0 18 18" style={{ cursor: "pointer" }}>
                                //         <use xlinkHref={`${addActionItemSVG}#addAIIcon`}></use>
                                //     </svg>
                                // </CustomTooltip>
                            }
                        </button>
                    </div>
                )
                }
                {(userActions?.includes("14") ||
                    userActions?.includes("20")) && (
                        <div>
                            <button
                                className="btn-like-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setOpenPMFClicked(true);
                                    handleCheckAnalyst(ci);
                                }}>
                                {c?.PreMappingAttached === true ?
                                    <CustomTooltip TransitionComponent={Zoom} title="View Pre-Mapping Form">
                                        <svg width="19" height="18" viewBox="0 0 19 18" style={{ cursor: "pointer" }}>
                                            <use xlinkHref={`${viewPreMappingIcon}#clipboard-check`}></use>
                                        </svg>
                                    </CustomTooltip> :
                                    <CustomTooltip TransitionComponent={Zoom} title="Create Pre-Mapping Form">
                                        <svg width="18" height="18" viewBox="0 0 18 18" style={{ cursor: "pointer" }}>
                                            <use xlinkHref={`${addActionItemSVG}#addAIIcon`}></use>
                                        </svg>
                                    </CustomTooltip>
                                }
                            </button>
                        </div>
                    )
                }
            </>
        },
        {
            name: 'Action Items',
            sortable: false,
            id: 'ActionItems',
            selector: c => <div style={{ justifyContent: "space-evenly", display: "flex" }}>
                <div>
                    <a href={`/ActionItems/ViewConnectivityRequest/${c.ConnectivityRequestID}/Dashboard`}>
                        <CustomTooltip TransitionComponent={Zoom} title="View Action Items">
                            {/* <PreviewIcon style={{ cursor: "pointer", fontSize: "30px" }} /> */}
                            <svg width="18" height="18" viewBox="0 0 18 18" >
                                <use xlinkHref={`${viewActionItemSVG}#viewAIIcon`}></use>
                            </svg>
                        </CustomTooltip>
                    </a>
                </div>
                <div>
                    <a href={`/ActionItems/ViewConnectivityRequest/${c.ConnectivityRequestID}/CreateActionItem`}>
                        <CustomTooltip TransitionComponent={Zoom} title="Add Action Item">
                            {/* <AddBoxIcon style={{ cursor: "pointer", fontSize: "30px" }} /> */}
                            <svg width="18" height="18" viewBox="0 0 18 18" >
                                <use xlinkHref={`${addActionItemSVG}#addAIIcon`}></use>
                            </svg>
                        </CustomTooltip>
                    </a>
                </div>
            </div>
        },
    ]

    const pushToColumnsBasedOnUserAction = () => {
        let checkif43or20 = (userActions?.includes("43") || userActions?.includes("20"))

        let itemsToInsert = [
            {
                index: 0,
                value: {
                    name: 'CR ID',
                    sortable: true,
                    width: '60px',

                    sortingkey: 'ConnectivityRequestID',
                    id: 'ConnectivityRequestID',
                    selector: c => <a href={`/ConnectivityRequests/ViewConnectivityRequest/${c?.ConnectivityRequestID}`}>{c?.ConnectivityRequestID}</a>
                },
                condition: checkif43or20
            },
            {
                index: checkif43or20 ? 3 : 2,
                value: {
                    name: 'Partner',
                    sortable: true,
                    sortingkey: 'PartnerName',
                    id: 'PartnerName',
                    selector: c => c?.PartnerName == 'Direct' ? 'Direct' : c?.PartnerName != null ? c?.PartnerName : c?.PartnerName == null && c?.IsPEO == 1 ? "PEO" : 'Network'
                },
                condition: isIsovedSuperAdminOrSuperAdmin()
            },
            {
                index: checkif43or20 && (isIsovedSuperAdminOrSuperAdmin() ? 6 : 5),
                value: {
                    name: 'Feed ID',
                    width: '60px',

                    sortable: true,
                    sortingkey: 'FeedID',
                    id: 'FeedID',
                    selector: c => <a href={`/Feeds/ViewFeed/${c?.FeedID}`}>{c?.FeedID}</a>
                },
                condition: checkif43or20
            },
            {
                index: checkif43or20 && (isIsovedSuperAdminOrSuperAdmin() ? 7 : 6),
                value: {
                    name: 'Feed Name',
                    sortable: true,
                    sortingkey: 'FeedName',
                    id: 'FeedName',
                    selector: c => <a href={`/Feeds/ViewFeed/${c?.FeedID}`}>{c?.FeedName}</a>
                },
                condition: checkif43or20
            },
            {
                index: checkif43or20 ? (isIsovedSuperAdminOrSuperAdmin() ? 8 : 7) : (isIsovedSuperAdminOrSuperAdmin() ? 5 : 4),
                value: {
                    name: 'Feed Structure Type',
                    sortable: true,
                    sortingkey: 'StructureTypeName',
                    id: 'StructureTypeName',
                    selector: c => c?.StructureTypeName
                },
                condition: checkif43or20 ? true : notisIsovedSuperAdminOriSolvedUser()
            },
        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumnsArray];


        itemsToInsert.forEach(item => {
            if (item?.condition)
                finalColumnsArray.splice(item.index, 0, item.value);
        });


        return finalColumnsArray;
    };


    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(connectivityRequestsList)) {
            return [];
        }
        return connectivityRequestsList
    }

    return (
        <div className="mt-5" ref={tableRef}>
            <GenericTable
                columns={[...pushToColumnsBasedOnUserAction()]}
                data={FilteredAndAddedIsExpand()}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
            />

            <div>
                <Pagination
                    stateLimit={true}
                    pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                    page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                    onChange={(t, v) => {
                        if (quickFiltersClicked) {
                            if (t === 1) onPageSize(v);
                            else if (t === 2) onSelectedPage(v);
                        } else if (advancedFiltersClicked) {
                            if (t === 1) onPageSizeAdv(v);
                            else if (t === 2) onSelectedPageAdv(v);
                        }

                    }}
                    count={totalCount}
                />
            </div>
        </div >

    )
}

export default ConnectivityRequestsTable;
