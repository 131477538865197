import React from "react";
import PropTypes from 'prop-types';
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import dateTimeSVG from "../../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import { Tabs, Tab, Box, AccordionSummary, Accordion, FormControlLabel, Checkbox, InputBase, NativeSelect, withStyles, FormControl, Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper } from '@material-ui/core/';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { useAuthentication } from "../../../../Data/Authentication";
import { styled } from '@mui/system';
import ListSubheader from '@mui/material/ListSubheader';
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

function CRActionItemsFilterModalBody({
    setTotalCoun,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    setPageSizeAdv,
    setSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    setActionItemIDAdvFilter,
    setActionItemAdvFilter,
    setResponsiblePartyAdvFilter,
    setSelectedContactsFilter,
    setSelectedRequestorsFilter,
    setSelectedLastModifiedByFilter,
    setSelectedLastModifiedFromDateAdvFilter,
    setSelectedLastModifiedToDateAdvFilter,
    setSelectedStartFromDateAdvFilter,
    setSelectedStartToDateAdvFilter,
    setSelectedDueFromDateAdvFilter,
    setSelectedDueToDateAdvFilter,
    setSelectedCompletionFromDateAdvFilter,
    setSelectedCompletionToDateAdvFilter,
    setSelectedStatusesFilter,
    setEscalatedAdvFilter,
    setPrimaryAnalystAdvFilter,
    setIsolvedRepresentativeAdvFilter,
    setAgingActionAdvFilter,
    groupsAndOEContactsList,
    onCloseClearReset,
    ...props
}) {


    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);

    const [tabValue, setTabValue] = React.useState(0);

    const [actionItemID, setActionItemID] = React.useState("");
    const [actionItem, setActionItem] = React.useState("");
    const [responsiblePartiesList, setResponsiblePartiesList] = React.useState([]);
    const [responsibleParty, setResponsibleParty] = React.useState([]);
    const [contactsList, setContactsList] = React.useState([]);
    const [contactsAnalystsList, setContactsAnalystsList] = React.useState([]);
    const [contactNameSearchValue, setContactNameSearchValue] = React.useState("");
    const [selectedContacts, setSelectedContacts] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [requestorSearchValue, setRequestorSearchValue] = React.useState("");
    const [selectedRequestor, setSelectedRequestor] = React.useState([]);
    const [lastModifiedBySearchValue, setLastModifiedBySearchValue] = React.useState("");
    const [selectedModifiedBy, setSelectedModifiedBy] = React.useState([]);
    const [actionItemsStatusesList, setActionItemsStatusesList] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [selectedLastModifiedFromDate, setSelectedLastModifiedFromDate] = React.useState(null);
    const [selectedLastModifiedToDate, setSelectedLastModifiedToDate] = React.useState(null);
    const [selectedStartFromDate, setSelectedStartFromDate] = React.useState(null);
    const [selectedStartToDate, setSelectedStartToDate] = React.useState(null);
    const [selectedDueFromDate, setSelectedDueFromDate] = React.useState(null);
    const [selectedDueToDate, setSelectedDueToDate] = React.useState(null);
    const [selectedCompletionFromDate, setSelectedCompletionFromDate] = React.useState(null);
    const [selectedCompletionToDate, setSelectedCompletionToDate] = React.useState(null);
    const [escalated, setEscalated] = React.useState("");
    const [primaryAnalystSearchValue, setPrimaryAnalystSearchValue] = React.useState("");
    const [selectedPrimaryAnalyst, setSelectedPrimaryAnalyst] = React.useState([]);
    const [isolvedRepresentativeSearchValue, setIsolvedRepresentativeSearchValue] = React.useState("");
    const [selectedIsolvedRepresentative, setSelectedIsolvedRepresentative] = React.useState([]);
    const [selectedAgingActionItem, setselectedAgingActionItem] = React.useState("");


    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        APIs.getAllUsers().then((r) => setUsersList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getActionItemStatusesList().then(r => !(r?.data?.length) ? setActionItemsStatusesList([]) : setActionItemsStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getResponsiblePartiesList().then(r => !(r?.data?.length) ? setResponsiblePartiesList([]) : setResponsiblePartiesList(r?.data));
    }, [])

    React.useEffect(() => {
        setSelectedContacts([]);
        setSelectedContactsFilter([]);
        const contactsBody = {
            ResponsiblePartyID: parseInt(responsibleParty),
        }

        APIs.getContactsList(contactsBody).then((r) => {
            !(r?.data?.length) ? setContactsList([]) : setContactsList(r?.data);
        })
    }, [responsibleParty])

    React.useEffect(() => {
        const contactsBody = {
            ResponsiblePartyID: 3,
        }

        APIs.getContactsList(contactsBody).then((r) => {
            !(r?.data?.length) ? setContactsAnalystsList([]) : setContactsAnalystsList(r?.data);
        })
    }, [responsibleParty])

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handleActionItemIDFilter = (e) => {
        setActionItemID(e.target.value);
        setActionItemIDAdvFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }
    const handleAgingActionItemsFilter = (e) => {
        setselectedAgingActionItem(e.target.value);
        setAgingActionAdvFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };

    const handleActionItemFilter = (e) => {
        setActionItem(e.target.value);
        setActionItemAdvFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleResponsiblePartyFilter = (e) => {
        setResponsibleParty(e.target.value);
        setResponsiblePartyAdvFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleContactNameClick = (value) => (event) => {
        let selectedContactNamesArray = selectedContacts;
        if (!value || value === "") selectedContactNamesArray = [];
        else if (selectedContactNamesArray.indexOf(value) >= 0) {
            selectedContactNamesArray.splice(selectedContactNamesArray.indexOf(value), 1);
        }
        else {
            selectedContactNamesArray.push(value);
        }
        setSelectedContacts([...selectedContactNamesArray]);
        setSelectedContactsFilter([...selectedContactNamesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleRequestorClick = (value) => (event) => {
        let selectedRequestorsArray = selectedRequestor;
        if (!value || value === "") selectedRequestorsArray = [];
        else if (selectedRequestorsArray.indexOf(value) >= 0) {
            selectedRequestorsArray.splice(selectedRequestorsArray.indexOf(value), 1);
        }
        else {
            selectedRequestorsArray.push(value);
        }
        setSelectedRequestor([...selectedRequestorsArray]);
        setSelectedRequestorsFilter([...selectedRequestorsArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleModifiedByClick = (value) => (event) => {
        let selectedModifiedByArray = selectedModifiedBy;
        if (!value || value === "") selectedModifiedByArray = [];
        else if (selectedModifiedByArray.indexOf(value) >= 0) {
            selectedModifiedByArray.splice(selectedModifiedByArray.indexOf(value), 1);
        }
        else {
            selectedModifiedByArray.push(value);
        }
        setSelectedModifiedBy([...selectedModifiedByArray]);
        setSelectedLastModifiedByFilter([...selectedModifiedByArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleLastModifiedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedLastModifiedFromDate(date);
            setSelectedLastModifiedFromDateAdvFilter(date);
        }
        else {
            setSelectedLastModifiedToDate(date);
            setSelectedLastModifiedToDateAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };
    const handlePrimaryAnalystClick = (value) => (event) => {
        let selectedPrimaryAnalystArray = selectedPrimaryAnalyst;
        if (!value || value === "") selectedPrimaryAnalystArray = [];
        else if (selectedPrimaryAnalystArray.indexOf(value) >= 0) {
            selectedPrimaryAnalystArray.splice(selectedPrimaryAnalystArray.indexOf(value), 1);
        }
        else {
            selectedPrimaryAnalystArray.push(value);
        }
        setSelectedPrimaryAnalyst([...selectedPrimaryAnalystArray]);
        setPrimaryAnalystAdvFilter([...selectedPrimaryAnalystArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }
    const handleIsolvedRepresentativeClick = (value) => (event) => {
        let selectedIsolvedRepresentativeArray = selectedIsolvedRepresentative;
        if (!value || value === "") selectedIsolvedRepresentativeArray = [];
        else if (selectedIsolvedRepresentativeArray.indexOf(value) >= 0) {
            selectedIsolvedRepresentativeArray.splice(selectedIsolvedRepresentativeArray.indexOf(value), 1);
        }
        else {
            selectedIsolvedRepresentativeArray.push(value);
        }
        setSelectedIsolvedRepresentative([...selectedIsolvedRepresentativeArray]);
        setIsolvedRepresentativeAdvFilter([...selectedIsolvedRepresentativeArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }
    const handleStartDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedStartFromDate(date);
            setSelectedStartFromDateAdvFilter(date);
        }
        else {
            setSelectedStartToDate(date);
            setSelectedStartToDateAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };

    const handleDueDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedDueFromDate(date);
            setSelectedDueFromDateAdvFilter(date);
        }
        else {
            setSelectedDueToDate(date);
            setSelectedDueToDateAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };

    const handleCompletionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedCompletionFromDate(date);
            setSelectedCompletionFromDateAdvFilter(date);
        }
        else {
            setSelectedCompletionToDate(date);
            setSelectedCompletionToDateAdvFilter(date);
        }
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    };
    const handleEscalatedFilter = (e, newValue) => {
        setEscalated(e.target.value);
        setEscalatedAdvFilter(e.target.value);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }
    const handleActionItemsStatusesClick = (value) => (event) => {
        let selectedActionItemsStatusesArray = selectedStatuses;
        if (!value || value === "") selectedActionItemsStatusesArray = [];
        else if (selectedActionItemsStatusesArray.indexOf(value) >= 0) {
            selectedActionItemsStatusesArray.splice(selectedActionItemsStatusesArray.indexOf(value), 1);
        }
        else {
            selectedActionItemsStatusesArray.push(value);
        }
        setSelectedStatuses([...selectedActionItemsStatusesArray]);
        setSelectedStatusesFilter([...selectedActionItemsStatusesArray]);
        setSelectedPageAdv(1);
        setPageSizeAdv(30);
    }

    const handleClearSearch = () => {
        setActionItemIDAdvFilter("");
        setActionItemID("");
        setActionItemAdvFilter("");
        setActionItem("");
        setResponsiblePartyAdvFilter("");
        setResponsibleParty("");
        setSelectedContacts([]);
        setSelectedContactsFilter([]);
        setSelectedRequestor([]);
        setSelectedRequestorsFilter([]);
        setSelectedLastModifiedByFilter([]);
        setSelectedModifiedBy([]);
        setSelectedLastModifiedFromDate(null);
        setSelectedLastModifiedFromDateAdvFilter(null);
        setSelectedLastModifiedToDate(null);
        setSelectedLastModifiedToDateAdvFilter(null);
        setSelectedStartFromDate(null);
        setSelectedStartFromDateAdvFilter(null);
        setSelectedStartToDate(null);
        setSelectedStartToDateAdvFilter(null);
        setSelectedDueFromDate(null);
        setSelectedDueFromDateAdvFilter(null);
        setSelectedDueToDate(null);
        setSelectedDueToDateAdvFilter(null);
        setSelectedCompletionFromDate(null);
        setSelectedCompletionFromDateAdvFilter(null);
        setSelectedCompletionToDate(null);
        setSelectedCompletionToDateAdvFilter(null);
        setEscalated("");
        setEscalatedAdvFilter("");
        setSelectedStatuses([]);
        setPrimaryAnalystAdvFilter([]);
        setIsolvedRepresentativeAdvFilter([]);
        setPrimaryAnalystSearchValue("");
        setSelectedPrimaryAnalyst([]);
        setIsolvedRepresentativeSearchValue("");
        setSelectedIsolvedRepresentative([]);
        setselectedAgingActionItem("");
        setAgingActionAdvFilter("");
        setSelectedStatusesFilter([]);
        setSelectedPageAdv(1);
    }

    React.useEffect(() => {
        setActionItemIDAdvFilter("");
        setActionItemID("");
        setActionItem("");
        setActionItemAdvFilter("");
        setResponsiblePartyAdvFilter("");
        setResponsibleParty("");
        setSelectedContacts([]);
        setSelectedContactsFilter([]);
        setSelectedRequestor([]);
        setSelectedRequestorsFilter([]);
        setSelectedLastModifiedByFilter([]);
        setSelectedModifiedBy([]);
        setPrimaryAnalystAdvFilter([]);
        setIsolvedRepresentativeAdvFilter([]);
        setselectedAgingActionItem("");
        setPrimaryAnalystSearchValue("");
        setSelectedPrimaryAnalyst([]);
        setIsolvedRepresentativeSearchValue("");
        setSelectedIsolvedRepresentative([]);
        setSelectedLastModifiedFromDate(null);
        setSelectedLastModifiedFromDateAdvFilter(null);
        setSelectedLastModifiedToDate(null);
        setSelectedLastModifiedToDateAdvFilter(null);
        setSelectedStartFromDate(null);
        setSelectedStartFromDateAdvFilter(null);
        setSelectedStartToDate(null);
        setSelectedStartToDateAdvFilter(null);
        setSelectedDueFromDate(null);
        setSelectedDueFromDateAdvFilter(null);
        setSelectedDueToDate(null);
        setSelectedDueToDateAdvFilter(null);
        setSelectedCompletionFromDate(null);
        setSelectedCompletionFromDateAdvFilter(null);
        setSelectedCompletionToDate(null);
        setSelectedCompletionToDateAdvFilter(null);
        setEscalated("");
        setEscalatedAdvFilter("");
        setSelectedStatuses([]);
        setSelectedStatusesFilter([]);
        setSelectedPageAdv(1);
    }, [advancedFiltersClicked === false])

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }

    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            setSelectedPageAdv(1);
            setPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            setSelectedPageAdv(1);
            setPageSizeAdv(30);
        }
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced Action Items Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            {(userActions?.includes("43") || userActions?.includes("20")) ? (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab className="filter-tab-btn" label="Action Item ID" {...a11yProps(0)} />
                                <Tab className="filter-tab-btn" label="Action Item" {...a11yProps(1)} />
                                <Tab className="filter-tab-btn" label="Responsible Party" {...a11yProps(2)} />
                                <Tab disabled={!responsibleParty || responsibleParty === "2" || contactsList.length <= 0} className="filter-tab-btn" label="Contact Name" {...a11yProps(3)} />
                                <Tab className="filter-tab-btn" label="Requestor" {...a11yProps(4)} />
                                <Tab className="filter-tab-btn" label="Last Modified By" {...a11yProps(5)} />
                                <Tab className="filter-tab-btn" label="Last Modified Date" {...a11yProps(6)} />
                                <Tab className="filter-tab-btn" label="Start Date" {...a11yProps(7)} />
                                <Tab className="filter-tab-btn" label="Due Date" {...a11yProps(8)} />
                                <Tab className="filter-tab-btn" label="Completion Date" {...a11yProps(9)} />
                                <Tab className="filter-tab-btn" label="Action Item Status" {...a11yProps(10)} />
                                <Tab className="filter-tab-btn" label="Escalated Action Items" {...a11yProps(11)} />
                                <Tab className="filter-tab-btn" label="Primary Analyst" {...a11yProps(12)} />
                                <Tab className="filter-tab-btn" label="isolvedRepresentative" {...a11yProps(13)} />
                                <Tab className="filter-tab-btn" label="Include Aging" {...a11yProps(14)} />
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <span>Please filter by only one action item ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Action Item ID" value={actionItemID} onChange={handleActionItemIDFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <span>Please filter by only one action item title:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Action Item Title" value={actionItem} onChange={handleActionItemFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <span>Please filter by only one responsible party:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={responsibleParty}
                                        onChange={handleResponsiblePartyFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {responsiblePartiesList?.map((p, pi) => (
                                            <option key={`responsible-parties-list-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <input type="text" className="text-input w-100" placeholder="Search contacts here..." value={contactNameSearchValue} onChange={(e) => setContactNameSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`contacts-select-all`}>
                                        <AccordionSummary
                                            aria-controls="contacts-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`contacts-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedContacts && selectedContacts.length === contactsList.length}
                                                            onClick={handelSelectAll(selectedContacts, contactsList, setSelectedContacts, setSelectedContactsFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {contactsList?.filter(s => s.IsEnabled === 1)?.filter(s => contactNameSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(contactNameSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`contacts-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="contacts-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`contact-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedContacts && selectedContacts.indexOf(c) >= 0}
                                                                onClick={handleContactNameClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {contactsList?.filter(s => s.IsEnabled !== 1)?.filter(s => contactNameSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(contactNameSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`contacts-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="contacts-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`contact-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedContacts && selectedContacts.indexOf(c) >= 0}
                                                                onClick={handleContactNameClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <input type="text" className="text-input w-100" placeholder="Search requestors here..." value={requestorSearchValue} onChange={(e) => setRequestorSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`requestors-select-all`}>
                                        <AccordionSummary
                                            aria-controls="requestors-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`requestors-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedRequestor && selectedRequestor.length === usersList.length}
                                                            onClick={handelSelectAll(selectedRequestor, usersList, setSelectedRequestor, setSelectedRequestorsFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled === 1)?.filter(s => requestorSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(requestorSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`requestors-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="requestors-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`requestor-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedRequestor && selectedRequestor.indexOf(c) >= 0}
                                                                onClick={handleRequestorClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled !== 1)?.filter(s => requestorSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(requestorSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`requestors-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="requestors-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`requestor-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedRequestor && selectedRequestor.indexOf(c) >= 0}
                                                                onClick={handleRequestorClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <input type="text" className="text-input w-100" placeholder="Search users here..." value={lastModifiedBySearchValue} onChange={(e) => setLastModifiedBySearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`users-modified-all`}>
                                        <AccordionSummary
                                            aria-controls="users-modified-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-modified-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedModifiedBy && selectedModifiedBy.length === usersList.length}
                                                            onClick={handelSelectAll(selectedModifiedBy, usersList, setSelectedModifiedBy, setSelectedLastModifiedByFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled === 1)?.filter(s => lastModifiedBySearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(lastModifiedBySearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-modified-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-modified-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedModifiedBy && selectedModifiedBy.indexOf(c) >= 0}
                                                                onClick={handleModifiedByClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled !== 1)?.filter(s => lastModifiedBySearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(lastModifiedBySearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-modified-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-modified-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedModifiedBy && selectedModifiedBy.indexOf(c) >= 0}
                                                                onClick={handleModifiedByClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <span>From:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedLastModifiedFromDate}
                                            onChange={handleLastModifiedDateChange("fromDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedLastModifiedToDate}
                                            onChange={handleLastModifiedDateChange("toDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TabPanel>
                            <TabPanel value={tabValue} index={7}>
                                <span>From:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedStartFromDate}
                                            onChange={handleStartDateChange("fromDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedStartToDate}
                                            onChange={handleStartDateChange("toDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TabPanel>
                            <TabPanel value={tabValue} index={8}>
                                <span>From:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedDueFromDate}
                                            onChange={handleDueDateChange("fromDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedDueToDate}
                                            onChange={handleDueDateChange("toDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TabPanel>
                            <TabPanel value={tabValue} index={9}>
                                <span>From:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedCompletionFromDate}
                                            onChange={handleCompletionDateChange("fromDate")}

                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedCompletionToDate}
                                            onChange={handleCompletionDateChange("toDate")}

                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                            keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>}
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TabPanel>
                            <TabPanel value={tabValue} index={10}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`action-items-status-all`}>
                                        <AccordionSummary
                                            aria-controls="action-items-status-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`action-items-status-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.length === actionItemsStatusesList.length}
                                                            onClick={handelSelectAll(selectedStatuses, actionItemsStatusesList, setSelectedStatuses, setSelectedStatusesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {actionItemsStatusesList.map((f, fi) => (
                                        <Accordion key={`action-items-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="action-items-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`action-items-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                                onClick={handleActionItemsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={11}>

                                <span>Escalated Action Items:</span>
                                <br />

                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={escalated ?? ""}
                                        onChange={handleEscalatedFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="Escalated">Yes</option>

                                    </NativeSelect>
                                </FormControl>

                            </TabPanel>
                            <TabPanel value={tabValue} index={12}>
                                <input type="text" className="text-input w-100" placeholder="Search users here..." value={primaryAnalystSearchValue} onChange={(e) => setPrimaryAnalystSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`users-select-all`}>
                                        <AccordionSummary
                                            aria-controls="users-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPrimaryAnalyst && selectedPrimaryAnalyst.length === contactsAnalystsList.length}
                                                            onClick={handelSelectAll(selectedPrimaryAnalyst, contactsAnalystsList, setSelectedPrimaryAnalyst, setPrimaryAnalystAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {contactsAnalystsList?.filter(s => s.IsEnabled === 1)?.filter(s => primaryAnalystSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(primaryAnalystSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPrimaryAnalyst && selectedPrimaryAnalyst.indexOf(c) >= 0}
                                                                onClick={handlePrimaryAnalystClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {contactsAnalystsList?.filter(s => s.IsEnabled !== 1)?.filter(s => primaryAnalystSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(primaryAnalystSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPrimaryAnalyst && selectedPrimaryAnalyst.indexOf(c) >= 0}
                                                                onClick={handlePrimaryAnalystClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={13}>
                                <input type="text" className="text-input w-100" placeholder="Search contacts here..." value={isolvedRepresentativeSearchValue} onChange={(e) => setIsolvedRepresentativeSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`contact-select-all`}>
                                        <AccordionSummary
                                            aria-controls="contact-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`contacts-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedIsolvedRepresentative && selectedIsolvedRepresentative.length === groupsAndOEContactsList.length}
                                                            onClick={handelSelectAll(selectedIsolvedRepresentative, groupsAndOEContactsList, setSelectedIsolvedRepresentative, setIsolvedRepresentativeAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.filter(s => isolvedRepresentativeSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(isolvedRepresentativeSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`contacts-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="contacts-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`contact-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedIsolvedRepresentative && selectedIsolvedRepresentative.indexOf(c) >= 0}
                                                                onClick={handleIsolvedRepresentativeClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {groupsAndOEContactsList?.filter(s => s.IsEnabled !== 1)?.filter(s => isolvedRepresentativeSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(isolvedRepresentativeSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`contacts-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="contacts-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`contact-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedIsolvedRepresentative && selectedIsolvedRepresentative.indexOf(c) >= 0}
                                                                onClick={handleIsolvedRepresentativeClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={14}>
                                <span>Include Aging:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedAgingActionItem ?? ""}
                                        onChange={handleAgingActionItemsFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="null" />
                                        <option value="1">Yes</option>
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                        </Box>
                    </div >
                </DialogContent>
            ) : (
                <DialogContent>
                    <div className="filter-body-div">
                        <Box style={{ display: 'flex' }}>
                            <Tabs
                                style={{ maxHeight: "370px", overflow: "auto" }}
                                orientation="vertical"
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                                TabIndicatorProps={{
                                    style: {
                                        display: "none",
                                    },
                                }}
                            >
                                <Tab className="filter-tab-btn" label="Responsible Party" {...a11yProps(0)} />
                                <Tab disabled={!responsibleParty || responsibleParty === "2" || contactsList.length <= 0} className="filter-tab-btn" label="Contact Name" {...a11yProps(1)} />
                                <Tab className="filter-tab-btn" label="Action Item" {...a11yProps(2)} />
                                <Tab className="filter-tab-btn" label="Requestor" {...a11yProps(3)} />
                                <Tab className="filter-tab-btn" label="Last Modified By" {...a11yProps(4)} />
                                <Tab className="filter-tab-btn" label="Last Modified Date" {...a11yProps(5)} />
                                <Tab className="filter-tab-btn" label="Action Item Status" {...a11yProps(6)} />
                                <Tab className="filter-tab-btn" label="Start Date" {...a11yProps(7)} />
                                <Tab className="filter-tab-btn" label="Due Date" {...a11yProps(8)} />
                                <Tab className="filter-tab-btn" label="Completion Date" {...a11yProps(9)} />
                                <Tab className="filter-tab-btn" label="Escalated Action Items" {...a11yProps(10)} />
                                <Tab className="filter-tab-btn" label="Primary Analyst" {...a11yProps(11)} />
                                <Tab className="filter-tab-btn" label="isolvedRepresentative" {...a11yProps(12)} />
                                <Tab className="filter-tab-btn" label="Include Aging" {...a11yProps(13)} />
                            </Tabs>
                            <TabPanel value={tabValue} index={0}>
                                <span>Please filter by only one responsible party:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={responsibleParty}
                                        onChange={handleResponsiblePartyFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {responsiblePartiesList?.map((p, pi) => (
                                            <option key={`responsible-parties-list-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <input type="text" className="text-input w-100" placeholder="Search contacts here..." value={contactNameSearchValue} onChange={(e) => setContactNameSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`contacts-select-all`}>
                                        <AccordionSummary
                                            aria-controls="contacts-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`contacts-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedContacts && selectedContacts.length === contactsList.length}
                                                            onClick={handelSelectAll(selectedContacts, contactsList, setSelectedContacts, setSelectedContactsFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {contactsList?.filter(s => s.IsEnabled === 1)?.filter(s => contactNameSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(contactNameSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`contacts-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="contacts-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`contact-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedContacts && selectedContacts.indexOf(c) >= 0}
                                                                onClick={handleContactNameClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {contactsList?.filter(s => s.IsEnabled !== 1)?.filter(s => contactNameSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(contactNameSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`contacts-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="contacts-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`contact-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedContacts && selectedContacts.indexOf(c) >= 0}
                                                                onClick={handleContactNameClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={2}>
                                <span>Please filter by only one action item title:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Action Item Title" value={actionItem} onChange={handleActionItemFilter} />
                            </TabPanel>
                            <TabPanel value={tabValue} index={3}>
                                <input type="text" className="text-input w-100" placeholder="Search requestors here..." value={requestorSearchValue} onChange={(e) => setRequestorSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`requestors-select-all`}>
                                        <AccordionSummary
                                            aria-controls="requestors-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`requestors-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedRequestor && selectedRequestor.length === usersList.length}
                                                            onClick={handelSelectAll(selectedRequestor, usersList, setSelectedRequestor, setSelectedRequestorsFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled === 1)?.filter(s => requestorSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(requestorSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`requestors-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="requestors-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`requestor-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedRequestor && selectedRequestor.indexOf(c) >= 0}
                                                                onClick={handleRequestorClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled !== 1)?.filter(s => requestorSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(requestorSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`requestors-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="requestors-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`requestor-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedRequestor && selectedRequestor.indexOf(c) >= 0}
                                                                onClick={handleRequestorClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={4}>
                                <input type="text" className="text-input w-100" placeholder="Search users here..." value={lastModifiedBySearchValue} onChange={(e) => setLastModifiedBySearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`users-modified-all`}>
                                        <AccordionSummary
                                            aria-controls="users-modified-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-modified-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedModifiedBy && selectedModifiedBy.length === usersList.length}
                                                            onClick={handelSelectAll(selectedModifiedBy, usersList, setSelectedModifiedBy, setSelectedLastModifiedByFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled === 1)?.filter(s => lastModifiedBySearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(lastModifiedBySearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-modified-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-modified-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedModifiedBy && selectedModifiedBy.indexOf(c) >= 0}
                                                                onClick={handleModifiedByClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {usersList?.filter(s => s.IsEnabled !== 1)?.filter(s => lastModifiedBySearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(lastModifiedBySearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-modified-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-modified-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedModifiedBy && selectedModifiedBy.indexOf(c) >= 0}
                                                                onClick={handleModifiedByClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={5}>
                                <span>From:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedLastModifiedFromDate}
                                            onChange={handleLastModifiedDateChange("fromDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedLastModifiedToDate}
                                            onChange={handleLastModifiedDateChange("toDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TabPanel>
                            <TabPanel value={tabValue} index={6}>
                                <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                    <Accordion key={`action-items-status-all`}>
                                        <AccordionSummary
                                            aria-controls="action-items-status-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`action-items-status-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.length === actionItemsStatusesList.length}
                                                            onClick={handelSelectAll(selectedStatuses, actionItemsStatusesList, setSelectedStatuses, setSelectedStatusesFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                    {actionItemsStatusesList.map((f, fi) => (
                                        <Accordion key={`action-items-status-${fi}`}>
                                            <AccordionSummary
                                                aria-controls="action-items-statuses-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`action-items-status-option-${fi}`}
                                                                value={f}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                                onClick={handleActionItemsStatusesClick(f)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={f?.Name}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={7}>
                                <span>From:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedStartFromDate}
                                            onChange={handleStartDateChange("fromDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedStartToDate}
                                            onChange={handleStartDateChange("toDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TabPanel>
                            <TabPanel value={tabValue} index={8}>
                                <span>From:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedDueFromDate}
                                            onChange={handleDueDateChange("fromDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedDueToDate}
                                            onChange={handleDueDateChange("toDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TabPanel>
                            <TabPanel value={tabValue} index={9}>
                                <span>From:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedCompletionFromDate}
                                            onChange={handleCompletionDateChange("fromDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                                <br />
                                <br />
                                <span>To:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center">

                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedCompletionToDate}
                                            onChange={handleCompletionDateChange("toDate")}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </TabPanel>
                            <TabPanel value={tabValue} index={10}>

                                <span>Escalated Action Items:</span>
                                <br />

                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={escalated ?? ""}
                                        onChange={handleEscalatedFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="Escalated">Yes</option>

                                    </NativeSelect>
                                </FormControl>

                            </TabPanel>
                            <TabPanel value={tabValue} index={11}>
                                <input type="text" className="text-input w-100" placeholder="Search users here..." value={primaryAnalystSearchValue} onChange={(e) => setPrimaryAnalystSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`users-select-all`}>
                                        <AccordionSummary
                                            aria-controls="users-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`users-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPrimaryAnalyst && selectedPrimaryAnalyst.length === contactsAnalystsList.length}
                                                            onClick={handelSelectAll(selectedPrimaryAnalyst, contactsAnalystsList, setSelectedPrimaryAnalyst, setPrimaryAnalystAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {contactsAnalystsList?.filter(s => s.IsEnabled === 1)?.filter(s => primaryAnalystSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(primaryAnalystSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPrimaryAnalyst && selectedPrimaryAnalyst.indexOf(c) >= 0}
                                                                onClick={handlePrimaryAnalystClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {contactsAnalystsList?.filter(s => s.IsEnabled !== 1)?.filter(s => primaryAnalystSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(primaryAnalystSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`users-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="users-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`user-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedPrimaryAnalyst && selectedPrimaryAnalyst.indexOf(c) >= 0}
                                                                onClick={handlePrimaryAnalystClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={12}>
                                <input type="text" className="text-input w-100" placeholder="Search contacts here..." value={isolvedRepresentativeSearchValue} onChange={(e) => setIsolvedRepresentativeSearchValue(e.target.value)} />
                                <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>

                                    <Accordion key={`contact-select-all`}>
                                        <AccordionSummary
                                            aria-controls="contact-select-select-all"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`contacts-select-option-all}`}
                                                            value="Select All"
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedIsolvedRepresentative && selectedIsolvedRepresentative.length === groupsAndOEContactsList.length}
                                                            onClick={handelSelectAll(selectedIsolvedRepresentative, groupsAndOEContactsList, setSelectedIsolvedRepresentative, setIsolvedRepresentativeAdvFilter)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label="Select All"
                                            />
                                        </AccordionSummary>
                                    </Accordion>

                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                    {groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.filter(s => isolvedRepresentativeSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(isolvedRepresentativeSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`contacts-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="contacts-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`contact-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedIsolvedRepresentative && selectedIsolvedRepresentative.indexOf(c) >= 0}
                                                                onClick={handleIsolvedRepresentativeClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                    {groupsAndOEContactsList?.filter(s => s.IsEnabled !== 1)?.filter(s => isolvedRepresentativeSearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(isolvedRepresentativeSearchValue.toLowerCase()) : s).map((c, ci) => (
                                        <Accordion key={`contacts-${ci}`}>
                                            <AccordionSummary
                                                aria-controls="contacts-select"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`contact-option-${ci}`}
                                                                value={c}
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={selectedIsolvedRepresentative && selectedIsolvedRepresentative.indexOf(c) >= 0}
                                                                onClick={handleIsolvedRepresentativeClick(c)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label={`${c.FirstName} ${c.LastName}`}
                                                />
                                            </AccordionSummary>
                                        </Accordion>
                                    ))}
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={13}>
                                <span>Include Aging:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={selectedAgingActionItem ?? ""}
                                        onChange={handleAgingActionItemsFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="null" />
                                        <option value="1">Yes</option>
                                    </NativeSelect>
                                </FormControl>
                            </TabPanel>
                        </Box>
                    </div >
                </DialogContent>
            )}
            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CRActionItemsFilterModalBody;