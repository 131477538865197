import React from "react";
import logoSVG from "../../Images/imageLogo.svg";
import LoginImageSVG from "../../Images/loginImage.svg";
import { Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useLoader } from "../Layout/Loader";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

function MFA({ cognitoUser, currentURLOnLogin, ...props }) {

    const [mfaCode, setMFACode] = React.useState("");
    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const [error, setError] = React.useState("");

    React.useEffect(() => {
        if (cognitoUser === undefined)
            window.location.href = "/";
        else return;
    }, [])

    const verifyInput = React.useRef();

    React.useEffect(() => {
        verifyInput.current.focus();
    }, [])

    return (
        <div id="login">
            <div className="row">
                <div className="col-1 login-first-corner-div"></div>
                <div className="col-10 login-div"></div>
                <div className="col-1 login-last-corner-div"></div>
            </div>
            <div className="login-content-mfa row">
                <div className="col-6" style={{ display: "inline-flex", justifyContent: "center" }}>
                    <svg viewBox="0 0 73 14" width="60%">
                        <use xlinkHref={`${logoSVG}#logoSVG`}></use>
                    </svg>
                </div>
                <div className="col-6">
                    <div elevation={0} style={{ height: "100%", padding: "30px 85px" }}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            if (!mfaCode) { setError("confirmation code cannot be empty"); closeLoader(); return; }
                            openLoader();
                            Auth.confirmSignIn(cognitoUser, mfaCode, "SMS_MFA")
                                .then((r) => { setError(""); window.location.href = currentURLOnLogin; closeLoader(); })
                                .catch((err) => {
                                    if (err.message.includes("session is expired"))
                                        setError("Session is expired, please try again!")
                                    else if (err.message.includes("Invalid code"))
                                        setError("confirmation code is incorrect, please try again!")
                                    else if (err.message.includes("Too many invalid credentials attempts"))
                                        window.location.href = "/";
                                    else setError(err);
                                    closeLoader();
                                });
                        }}>
                            <div className="text-left">
                                <h2 style={{ fontWeight: "400", color: "var(--black1)" }}>Additional security verification</h2>
                                <h5 className="color black2 mb-4 mt-5">{`We have sent a text message to your phone at ${cognitoUser?.challengeParam?.CODE_DELIVERY_DESTINATION}`}</h5>
                                <h6 className="color grey3" style={{ fontWeight: "450" }}>When you receive the verification code, enter it here</h6>
                                <input ref={verifyInput} type="text" className="text-input mt-2" style={{ minHeight: "40px" }} value={mfaCode} onInput={(e) => setMFACode(e.target.value)} />
                                {error && error !== "" && (
                                    <div style={{ color: "red", textAlign: "left", fontSize: "14px", marginTop: "10px" }}>
                                        <label>Error: <span>{error}</span></label>
                                    </div>
                                )}
                            </div>

                            <div className="text-right mt-5">
                                <Button className="cancel-mfa-btn mfa-btn mr-2" onClick={() => routeHistory.push("/")}>Cancel</Button>
                                <Button type="submit" className="verify-mfa-btn mfa-btn">Verify</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MFA;