import React from "react";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ConfirmModal } from "../Modals/ConfirmModal";
import { FailureModal } from "../Modals/FailureModal";
import Moment from 'moment';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { useAuthentication } from "../../Data/Authentication";
import deleteForeverSVG from "../../Icons/trash-xmark.svg";

function EncryptionKeyRow({
    keyItem,
    index,
    carrierDetails,
    carrierID,
    ...props
}) {

    const dialog = useModalDialog(0);
    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'This key is currently being used and cannot be deleted.'
                });
            default:
                break;
        }
    }

    const handleDeleteKey = () => {
        openModal(0, deleteKey, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this key?`,
        })
    }

    const deleteKey = () => {
        openLoader();
        const submitDeleteKey = {
            GUID: carrierDetails?.EncryptionKeys[index]?.GUID,
            CarrierID: carrierID
        };

        APIs.deleteEncryptionKey(submitDeleteKey).then((r) => {
            if (r.msg === "This key is currently being used and cannot be deleted.") {
                openModal(1);
                closeLoader();
            }
            else window.location.reload();
        });
    }

    return (
        <div className="row text-center table-row">
            <div className="col-3 table-cell first-cell" style={{ wordBreak: "break-all" }}>
                {keyItem.Name}
            </div>
            <div className="col-3 table-cell" style={{ wordBreak: "break-all" }}>
                {keyItem.KeyID}
            </div>
            <div className="col-3 table-cell" style={{ wordBreak: "break-all" }}>
                {keyItem.Fingerprint}
            </div>
            {(userActions?.includes("37") ||
                userActions?.includes("20")) ? (
                <>
                    <div className="col-2 table-cell">
                        {keyItem.Created ? Moment(keyItem.Created).format('MM/DD/YYYY') : null}
                    </div>
                    <div className="col-1 table-cell" style={{ justifyContent: "center" }}>
                        {/* <DeleteForeverIcon onClick={handleDeleteKey} color="action" style={{ fontSize: "30px", cursor: "pointer" }} /> */}
                        <svg width="21" height="24" viewBox="0 0 21 24" onClick={handleDeleteKey} style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                    </div>
                </>
            ) : (
                <div className="col-3 table-cell">
                    {keyItem.Created ? Moment(keyItem.Created).format('MM/DD/YYYY') : null}
                </div>
            )}
        </div>
    )
}

export default EncryptionKeyRow;