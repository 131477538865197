import React from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ListIcon from '@material-ui/icons/List';
import { Paper, Select } from "@material-ui/core";
import CarrierPlansAnalyst from "./CarrierPlansAnalyst";
import RelationshipCodesAnalyst from "./RelationshipCodesAnalyst";
import SummaryAnalyst from "./SummaryAnalyst";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import GenericNewSectionAnalyst from "./GenericNewSectionAnalyst";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { v4 as uuidv4 } from 'uuid';
import { useAuthentication } from "../../../../Data/Authentication";
import { useHistory } from "react-router-dom";
import { FailureModal } from "../../../Modals/FailureModal";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";

function PreMappingStepperAnalyst({ onPreMapSubmitted, connectivityReq, primaryAnalyst, secondaryAnalyst, ...props }) {

    const dialog = useModalDialog(0);
    const [stepsList, setStepsList] = React.useState(
        [
            {
                title: 'Carrier Plans',
                icon: <LocalHospitalIcon />
            },
            {
                title: 'Relationship Codes',
                icon: <SupervisorAccountIcon />
            },
            {
                title: 'Summary',
                icon: <ListIcon />
            }
        ]
    );

    const { openLoader, closeLoader } = useLoader();
    const routeHistory = useHistory();
    const { authUser } = useAuthentication();
    let currentTimestamp = new Date().getTime();

    const [isNextActive, setIsNextActive] = React.useState(false);

    const [sections, setSections] = React.useState([]);

    const [addSectionClicked, setAddSectionClicked] = React.useState(false);

    const [section, setSection] = React.useState();
    const [customizedSection, setCustomizedSection] = React.useState("");

    const [activeStep, setActiveStep] = React.useState(0);

    const [preMapSubmitted, setPreMapSubmitted] = React.useState(false);


    const [contentList, setContentList] = React.useState([
        {
            Title: "Carrier Plans",
            Type: "carrierPlan",
            Index: 0,
            Model: []
        },
        {
            Title: "Relationship Codes",
            Type: "relationshipCode",
            Index: 1,
            IncludeDependents: false,
            Model: []
        }
    ]);

    const [deletedSection, setDeletedSection] = React.useState(false);

    React.useEffect(() => {
        openLoader();
        APIs.getSectionsList().then((r) => (
            setSections(r?.data),
            closeLoader()
        ));
    }, [])

    const iconDefault = <AddIcon />;

    const handleSectionChange = (e) => {
        setSection(e.target.value);
    };

    const handleCustomizedSectionChange = (e) => {
        setCustomizedSection(e.target.value);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAddSection = () => {
        if (stepsList.map(s => s.title).indexOf(section) > 0 || stepsList.map(s => s.title?.toLowerCase()).indexOf(customizedSection?.toLowerCase()) > 0) {
            FailureModal(dialog, {
                title: 'Failure!',
                body: 'This section already exists.'
            }); return;
        }
        let stepsListArray = [...stepsList];
        let contentListArray = [...contentList];
        if (section !== "Not Listed...") {
            stepsListArray.splice(stepsList.length - 1, 0, { title: section, icon: iconDefault });
            contentListArray.splice(contentList.length, 0, {
                Title: section,
                Type: section?.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
                Index: contentList.length,
                Model: []
            })
        } else {
            stepsListArray.splice(stepsList.length - 1, 0, { title: customizedSection, icon: iconDefault });
            contentListArray.splice(contentList.length, 0, {
                Title: customizedSection,
                Type: customizedSection?.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
                Index: contentList.length,
                Model: []
            });
        }
        setStepsList(stepsListArray);
        setContentList(contentListArray);
        handleCancelAddSection();
    }

    const handleCancelAddSection = () => {
        setAddSectionClicked(false);
        setSection();
        setCustomizedSection("");
    }

    const handlePreMappingSubmit = () => {
        setPreMapSubmitted(true);
        onPreMapSubmitted(true);
        if (primaryAnalyst === "") return;
        openLoader();
        let submitObject = {};
        if (secondaryAnalyst === "") {
            submitObject = {
                GUID: uuidv4(),
                ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
                SubmissionDate: currentTimestamp,
                PreMappingBasicData: {
                    GroupName: connectivityReq?.GroupName,
                    CarrierName: connectivityReq?.CarrierName,
                    Analysts: [
                        {
                            Name: `${primaryAnalyst?.FirstName} ${primaryAnalyst?.LastName}`,
                            Email: primaryAnalyst?.Email,
                            TypeID: 1,
                            TypeName: "Primary",
                            UserID: primaryAnalyst?.CognitoID
                        }
                    ]
                },
                Sections: contentList
            }
        } else {
            submitObject = {
                GUID: uuidv4(),
                ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
                SubmissionDate: currentTimestamp,
                PreMappingBasicData: {
                    GroupName: connectivityReq?.GroupName,
                    CarrierName: connectivityReq?.CarrierName,
                    Analysts: [
                        {
                            Name: `${primaryAnalyst?.FirstName} ${primaryAnalyst?.LastName}`,
                            Email: primaryAnalyst?.Email,
                            TypeID: 1,
                            TypeName: "Primary",
                            UserID: primaryAnalyst?.CognitoID
                        },
                        {
                            Name: `${secondaryAnalyst?.FirstName} ${secondaryAnalyst?.LastName}`,
                            Email: secondaryAnalyst?.Email,
                            TypeID: 2,
                            TypeName: "Secondary",
                            UserID: secondaryAnalyst?.CognitoID
                        }
                    ]
                },
                Sections: contentList
            }
        }

        APIs.submitPreMappingFormAnalyst(submitObject).then((r) => (
            routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${r.ConnectivityRequestID}/ViewPreMappingFormAnalyst`)
        ));
    }

    React.useEffect(() => {
        let contentListArray = [...contentList];
        let stepsListArray = [...stepsList];
        if (deletedSection) {
            contentListArray.splice(activeStep, 1);
            stepsListArray.splice(activeStep, 1);
            let contentListArrayIndex = contentListArray.map(s => s.Index);
            contentListArrayIndex.length = 0;
            for (var i = 0; i <= contentListArray.length - 1; i++) {
                contentListArrayIndex.push(i);
                contentListArray[i].Index = i;
            }
        } else return;
        setStepsList(stepsListArray);
        setContentList(contentListArray);
        setActiveStep(activeStep - 1);
        setDeletedSection(false);
    }, [deletedSection])

    return (
        <div id="premapping-form">
            <Paper elevation={0} className="p-5 mb-3">
                <div className="row alignvh-center">
                    <Button onClick={() => setAddSectionClicked(true)} variant="contained" className="blue-btn mr-5">
                        <label style={{ cursor: "pointer" }} className="mb-0">Add New Section</label>
                    </Button>
                    {addSectionClicked === true && (
                        <>
                            <FormControl label="Select section" focused={false} variant="outlined" style={{ minWidth: "250px" }}>
                                <Select
                                    className="text-dropdown"
                                    value={section ?? ""}
                                    onChange={handleSectionChange}
                                >
                                    {sections.map((s, si) => (
                                        <MenuItem key={`sections-list-${si}`} value={s.Name}>{s.Name}</MenuItem>
                                    ))}
                                    <MenuItem value="Not Listed...">Not Listed...</MenuItem>
                                </Select>
                            </FormControl>
                            {section === "Not Listed..." && (
                                <div className="row alignvh-center ml-5" style={{ whiteSpace: "nowrap" }}>
                                    <h6 className="form-title">Section Name</h6>
                                    <span className="text-danger mb-2 ml-2 mr-2">*</span>
                                    <input type="text" className="text-input" style={{ minWidth: "300px" }} onChange={handleCustomizedSectionChange} value={customizedSection ?? ""} />
                                </div>
                            )}
                            <Button disabled={!section || (section === "Not Listed..." && customizedSection === "")} onClick={handleAddSection} variant="contained" className="blue-btn ml-5">
                                <label style={{ cursor: "pointer" }} className="mb-0">Add</label>
                            </Button>
                            <Button onClick={handleCancelAddSection} variant="contained" className="blue-outlined-btn ml-2">
                                <label style={{ cursor: "pointer" }} className="mb-0">Cancel</label>
                            </Button>
                        </>
                    )}
                </div>
            </Paper>
            <Paper elevation={0} className="p-5 mb-3">
                <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
                    {stepsList.map((s, si) => (
                        <Step className={activeStep === si ? "active" : ""} key={`steps-list-${si}`}>
                            <StepLabel icon={s.icon}>{s.title}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className="mt-5">
                    <div>
                        {contentList.filter(c => c.Index === activeStep).map(c =>
                            c.Type?.toLowerCase() === "carrierplan" ? <CarrierPlansAnalyst carrierPlansSection={c} currentTimestamp={currentTimestamp} setIsNextActive={setIsNextActive} />
                                : c.Type?.toLowerCase() === "relationshipcode" ? <RelationshipCodesAnalyst relationshipCodesSection={c} currentTimestamp={currentTimestamp} setIsNextActive={setIsNextActive} />
                                    : <GenericNewSectionAnalyst genericSection={c} currentTimestamp={currentTimestamp} setIsNextActive={setIsNextActive} setOnDeletedSection={setDeletedSection} />)[0]}
                        {activeStep === contentList.length && (
                            <SummaryAnalyst contentList={contentList} setActiveStep={setActiveStep} />
                        )}
                    </div>
                    <div style={{ textAlign: "end" }} className="mt-5">
                        <Button disabled={activeStep === 0} className="blue-outlined-btn mr-2" onClick={handleBack}>Back</Button>
                        <Button className="blue-btn" disabled={!isNextActive && activeStep !== stepsList.length - 1} onClick={activeStep === stepsList.length - 1 ? handlePreMappingSubmit : handleNext}>
                            {activeStep === stepsList.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                    </div>
                </div>
            </Paper>
        </div >
    )
}

export default PreMappingStepperAnalyst;