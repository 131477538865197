import React, { useEffect, useState } from 'react';
import * as Apis from '../../../../Data/APIs'
import { useLoader } from '../../../Layout/Loader';
import ClientFormsTransmissionsTabSingleFeedWithForms from './ClientFormsTransmissionsTabSingleFeedWithForms';

const ClientFormsTransmissionsTab = () => {
    const { openLoader, closeLoader } = useLoader();
    const [feeds, setFeeds] = useState([]);

    const getFeedsHandler = () => {
        openLoader();
        Apis.getClientormsFeed({
            lsi: '5',
        }).then(r => {
            if (r.msg == "Success") {
                setFeeds(r?.data)
            } else setFeeds([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }

    useEffect(() => {
        getFeedsHandler()
    }, [])

    return (
        <div className="mt-3">
            <div className="row">
                <div className="d-flex flex-column w-100">
                    {feeds && feeds?.length > 0 ?
                        <>
                            {feeds.map((feed, feedIndex) =>
                                <div className="d-flex flex-column w-100" key={feedIndex}>
                                    <ClientFormsTransmissionsTabSingleFeedWithForms
                                        feed={feed}
                                    />
                                </div>
                            )}
                        </>
                        :
                        <div className="no-data-alert">
                            <h6>No Results Found!</h6>
                        </div>

                    }
                </div>
            </div>
        </div>
    )
}

export default ClientFormsTransmissionsTab
