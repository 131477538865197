import React from 'react'
import { FormControl, Divider, Paper, Button, Select, MenuItem, Zoom } from "@material-ui/core";
import { useAuthentication } from "../../Data/Authentication";
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { CustomTooltip } from "../Layout/Tooltip";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Auth } from 'aws-amplify';
import { SuccessModal } from "../Modals/SuccessModal";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { useHistory } from "react-router-dom";
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { FailureModal } from '../Modals/FailureModal';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    margin: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
const Notifications = ({ }) => {

    const { openLoader, closeLoader } = useLoader();
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const userID = authUser?.attributes["sub"];
    const [subscribePendingForms, setSubscribePendingForms] = React.useState(true);
    const [subscribeRejectedForms, setSubscribeRejectedForms] = React.useState(true);

    const dialog = useModalDialog(0);


const getNotificationDetails =()=>{
    openLoader();
    APIs.getUserNotifications(userID).then((r) => {
        const {PendingForms , RejectedForms} = r?.data
        setSubscribePendingForms(PendingForms == 1)
        setSubscribeRejectedForms(RejectedForms  == 1)
    });
    closeLoader();
}

React.useEffect(() => {
    getNotificationDetails();
}, [])


const saveNotification = (e) => {
    e.preventDefault();
    openLoader();
    let updateUserObject = {};

        updateUserObject = {
            CognitoID: authUser?.attributes?.sub,
            FirstName: authUser?.attributes["custom:FirstName"],
            LastName: authUser?.attributes["custom:LastName"],
            PhoneNumber: authUser?.attributes["phone_number"],
            PendingForms: subscribePendingForms ? 1 : 0,
            RejectedForms: subscribeRejectedForms ? 1 : 0 

        }
        // Update user in data base 
        APIs.editUser(updateUserObject).then((r) => {
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: 'Error processing request',
                });
            } 
        }).finally(() => closeLoader());
    
}

    return (
        <div id="view-edit-profile" >
            <div className="row w-100 alignvh-center ">
                <h5 className="black1 fw-700 mt-5 mb-3">Notifications
                </h5>
            </div>
            <div className='outer-card-settings'>
                <div className=' alignvh-center notification-card'>
                    <div>
                        <h6 className="form-title">
                            New AIs
                            <Chip label="Coming Soon" className=' mx-2' style={{ color: "var(--black3)" }} />
                        </h6>
                        <div>
                            Subscribe to    "New AIs"   notification
                        </div>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            style={{ margin: 0, }}
                            control={<IOSSwitch sx={{ m: 1 }} />}
                            label=""
                            disabled
                        />
                    </FormGroup>

                </div>
                {userRoles?.includes("6") && (
                    <>
                <br/>
                <div className=' alignvh-center notification-card'>
                    <div>
                        <h6 className="form-title">
                            Pending Forms
                        </h6>
                        <div>
                            Subscribe to    "Pending Forms"   notification
                        </div>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            style={{ margin: 0, }}
                            control={<IOSSwitch
                                checked={subscribePendingForms}
                                onChange={() => setSubscribePendingForms(!subscribePendingForms) }
                                sx={{ m: 1 }} />}
                            label=""
                            
                        />
                    </FormGroup>

                </div>
                <br/>
                <div className=' alignvh-center notification-card'>
                    <div>
                        <h6 className="form-title">
                            Rejected Forms
                        </h6>
                        <div>
                            Subscribe to    "Rejected Forms"   notification
                        </div>
                    </div>
                    <FormGroup>
                        <FormControlLabel
                            style={{ margin: 0, }}
                            control={<IOSSwitch
                                checked={subscribeRejectedForms}
                                onChange={() => setSubscribeRejectedForms(!subscribeRejectedForms)}
                                sx={{ m: 1 }} />}
                            label=""
                            
                        />
                    </FormGroup>

                </div>
                
                <div className="w-100 text-right mt-3 pt-3">
                <Button type="button" variant="contained" className="blue-btn" 
                onClick={saveNotification}>Save Notifications Settings</Button>
                </div>
            </>
                )}
            </div>
        </div >
    )
}

export default Notifications



