import React, { useState } from 'react';
import { Button, Modal, Box } from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';

const EditFormInputModal = ({
    showChild,
    setshowChild,
    currentEditField,
    currentEditTextInput,
    setCurrentEditTextInput,
    currentEditFields,
    saveEditField,
    ...props
}) => {

    const handleClose = () => {
        setshowChild(false);
    };

    return (
        <React.Fragment>
            <Modal
                open={true}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                onBackdropClick={handleClose}
                onEscapeKeyDown={handleClose}
            >

                <Box className="custom-modal-for-edit-df-form">
                    <div className="main-box p-2">
                        <CloseIcon onClick={handleClose} role="button" className="closeBtn ml-auto mb-2" />
                        <div className="mt-2">
                            <h6 className="form-title" id="child-modal-title">{currentEditField?._name}</h6>
                            <input className="text-input w-100" type="text" vlaue={currentEditTextInput} onChange={e => setCurrentEditTextInput(e.target.value)} />
                        </div>
                        <button className="btn ml-auto mt-auto blue-btn text-uppercase" type='button' onClick={() => {
                            currentEditFields[currentEditField._id] = currentEditTextInput;
                            saveEditField(currentEditField, currentEditTextInput);
                            setCurrentEditTextInput('');
                            setshowChild(false)
                        }}>Sumbit</button>
                    </div>
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export default EditFormInputModal
