import { LinearProgress } from "@material-ui/core";
import React from "react";

function LinearProgressBar({
    title,
    percentage,
    count,
    level,
    onClickHandler
}) {
    return (
        <div className="progress-container" onClick={() => onClickHandler()} role="button">
            <span>{title}</span>
            <LinearProgress className={`linearprogress-stats ${level}`} variant="determinate" value={percentage} />
            <div className="stats-counts d-flex">
                <div style={{ flex: "1", textAlignLast: "left" }}>
                    {`${count} Requests`}
                </div>
                <div style={{ flex: "2", textAlignLast: "right" }}>
                    {`${percentage}%`}
                </div>
            </div>
        </div>
    )
}

export default LinearProgressBar;