import React from "react";
import { Divider, LinearProgress, Paper } from "@material-ui/core";
import BorderCircularProgress from "../../Charts/BorderCircularProgress";
import Chart from "react-google-charts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PieChartIcon from '@mui/icons-material/PieChart';
import LinearProgressBar from "../../Layout/LinearProgressBar";
function GroupRequestsStatistics({
    dashboardGRStatus,
    dashboardGRPhase,
    onPhaseFilter,
    onStatusFilter,
    tableRef,
    onSetOnPhaseDashboardChange,
    onSetOnStatusDashboardChange,
    onSetFromDateFilter,
    onSetToDateFilter,
    onSetFilterCard,
    ...props }) {

    const _statusColors = [
        "#00C305",
        "#F22525",
        "#B1B1B1",
        "#EBA400"
    ];

    const _phaseColors = [
        "#CCCCCC",
        "#5382a1",
        "#00C305",
        "#F22525",
        "#0e4c8a"
    ];

    const sizes = [250, 225, 200, 175];
    function handlePhase(phase) {
        onSetFilterCard(true);
        onSetOnPhaseDashboardChange(true);
        onSetOnStatusDashboardChange(false);
        onSetFromDateFilter(null);
        onSetToDateFilter(null);
        onStatusFilter("");
        onPhaseFilter(phase);
        tableRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    function handleStatus(status) {
        onSetFilterCard(true);
        onSetOnStatusDashboardChange(true);
        onSetOnPhaseDashboardChange(false);
        onSetFromDateFilter(null);
        onSetToDateFilter(null);
        onPhaseFilter("");
        onStatusFilter(status);
        tableRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div id="group-requests-statistics">
            <div className="row w-100 alignvh-center mb-4">
                <h5 className="black1 fw-400 ml-3">Group Requests Dashboard</h5>
            </div>
            <Accordion className="exchangeAccordion mb-4">
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel-content"
                    id="panel-header"
                    style={{ minHeight: "65px" }}
                >
                    <div style={{ fontWeight: "500" }}>
                        <PieChartIcon className="color pink-primary-color mr-3" />
                        <span>Group Requests Statistics</span>
                    </div>
                </AccordionSummary>
                <AccordionDetails style={{ borderTop: "1px solid gainsboro" }}>
                    <div className="mb-5">
                        {dashboardGRStatus && dashboardGRStatus.length > 0 && (
                            <>
                                <h6 className="fw-500">Statuses</h6>
                                <div className="row mt-3">
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardGRStatus[0]?.name} percentage={dashboardGRStatus[0]?.percentage} count={dashboardGRStatus[0]?.count} level="blue" onClickHandler={() => handleStatus(1)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardGRStatus[3]?.name} percentage={dashboardGRStatus[3]?.percentage} count={dashboardGRStatus[3]?.count} level="green" onClickHandler={() => handleStatus(4)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardGRStatus[1]?.name} percentage={dashboardGRStatus[1]?.percentage} count={dashboardGRStatus[1]?.count} level="red" onClickHandler={() => handleStatus(2)} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {dashboardGRPhase && dashboardGRPhase.length > 0 && (
                            <>
                                <h6 className="fw-500">Phases</h6>
                                <div className="row mt-3">
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardGRPhase[4]?.name} percentage={dashboardGRPhase[4]?.percentage} count={dashboardGRPhase[4]?.count} level="blue" onClickHandler={() => handlePhase(5)} />
                                        <LinearProgressBar title={dashboardGRPhase[2]?.name} percentage={dashboardGRPhase[2]?.percentage} count={dashboardGRPhase[2]?.count} level="green" onClickHandler={() => handlePhase(3)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardGRPhase[0]?.name} percentage={dashboardGRPhase[0]?.percentage} count={dashboardGRPhase[0]?.count} level="red" onClickHandler={() => handlePhase(1)} />
                                        <LinearProgressBar title={dashboardGRPhase[1]?.name} percentage={dashboardGRPhase[1]?.percentage} count={dashboardGRPhase[1]?.count} level="red" onClickHandler={() => handlePhase(2)} />
                                    </div>
                                    <div className="col-xl-4">
                                        <LinearProgressBar title={dashboardGRPhase[3]?.name} percentage={dashboardGRPhase[3]?.percentage} count={dashboardGRPhase[3]?.count} level="red" onClickHandler={() => handlePhase(4)} />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {dashboardGRStatus.length <= 0 && dashboardGRPhase.length <= 0 && (
                        <div className="no-data-alert">
                            <h5>No Data Available!</h5>
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default GroupRequestsStatistics;