import React from "react";
import HomeConnectivityRequestsListView from "./HomeConnectivityRequestsListView";
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import Moment from 'moment';
import { useParams } from "react-router-dom";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0)

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function HomeConnectivityRequestsDashboard() {

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);
    const [refreshClicked, setRefreshClicked] = React.useState(false);
    const { openLoader, closeLoader } = useLoader();
    const [connectivityRequestsList, setConnectivityRequestsList] = React.useState([]);
    const [connectivityRequestsStatus, setConnectivityRequestsStatus] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [groupNameFilter, setGroupNameFilter] = React.useState("");
    const [peoNameFilter, setPEONameFilter] = React.useState("");
    const [carrierNameFilter, setCarrierNameFilter] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState();
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("");
    //advanced filters
    const [selectedGroupsFilter, setSelectedGroupsFilter] = React.useState([]);
    const [selectedPEOsFilter, setSelectedPEOsFilter] = React.useState([]);
    const [selectedStatusesFilter, setSelectedStatusesFilter] = React.useState([]);
    const [selectedCarriersFilter, setSelectedCarriersFilter] = React.useState([]);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("");

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestStatuses().then((r) => (
            !(r?.data?.length) ? setConnectivityRequestsStatus([]) : setConnectivityRequestsStatus(r?.data),
            closeLoader()
        ));
    }, [])

    const getDataQuickFilters = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPage >= 1) {
            requestParameters.pageNumber = selectedPage;
            requestParameters.limit = pageSize;
        }
        if (pageSize >= 10) {
            requestParameters.limit = pageSize;
            requestParameters.pageNumber = selectedPage;
        }
        if (groupNameFilter !== "")
            requestParameters.GroupName = groupNameFilter;
        if (peoNameFilter !== "")
            requestParameters.PeoName = peoNameFilter;
        if (carrierNameFilter !== "")
            requestParameters.CarrierName = carrierNameFilter;
        if (statusFilter !== "")
            requestParameters.StatusTypeId = statusFilter;
        if (sortFieldFilter !== "")
            requestParameters.sortField = sortFieldFilter;
        if (sortOrderFilter !== "")
            requestParameters.sortOrder = sortOrderFilter;

        APIs.getConnectivityRequests(requestParameters).then((r) => (
            setConnectivityRequestsList(r?.data?.list),
            setRefreshClicked(false),
            closeLoader(),
            setTotalCount(r?.data?.totalCount ?? 0)
        ));
    }

    const getDataAdvancedFilters = () => {
        openLoader();
        const requestParameters = {};
        if (selectedPageAdv >= 1) {
            requestParameters.pageNumber = selectedPageAdv;
            requestParameters.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            requestParameters.limit = pageSizeAdv;
            requestParameters.pageNumber = selectedPageAdv;
        }
        if (selectedGroupsFilter.length > 0)
            requestParameters.GroupIDsArray = selectedGroupsFilter?.map(s => s?.GroupID);
        if (selectedPEOsFilter.length > 0)
            requestParameters.PeoIDsArray = selectedPEOsFilter?.map(s => s?.PeoId);
        if (selectedCarriersFilter.length > 0)
            requestParameters.CarrierIDsArray = selectedCarriersFilter?.map(s => s?.CarrierID);
        if (selectedStatusesFilter.length > 0)
            requestParameters.StatusIDsArray = selectedStatusesFilter?.map(s => s?.ConnectivityRequestStatusID);
        if (sortFieldAdvFilter !== "")
            requestParameters.sortField = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            requestParameters.sortOrder = sortOrderAdvFilter;
        APIs.getConnectivityRequests(requestParameters).then((r) => (
            setConnectivityRequestsList(r?.data?.list),
            setTotalCount(r?.data?.totalCount ?? 0),
            setAdvancedFiltersAppliedClicked(false),
            closeLoader()
        ));
    }


    React.useEffect(() => {
        if (quickFiltersClicked) getDataQuickFilters();
    }, [quickFiltersClicked === true, selectedPage, pageSize, groupNameFilter, peoNameFilter, carrierNameFilter, statusFilter, sortFieldFilter, sortOrderFilter, refreshClicked])


    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])


    React.useEffect(() => {
        if (advancedFiltersClicked === true) {
            getDataAdvancedFilters();
        }
    }, [advancedFiltersClicked === true])

    return (
        <div>
            <HomeConnectivityRequestsListView
                connectivityRequestsList={connectivityRequestsList}
                setConnectivityRequestsList={setConnectivityRequestsList}
                selectedPage={selectedPage}
                onSelectedPage={(p) => setSelectedPage(p)}
                selectedPageAdv={selectedPageAdv}
                onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                pageSize={pageSize}
                onPageSize={(p) => setPageSize(p)}
                pageSizeAdv={pageSizeAdv}
                onPageSizeAdv={(p) => setPageSizeAdv(p)}
                onGroupNameFilter={(g) => setGroupNameFilter(g)}
                groupNameFilter={groupNameFilter}
                onPEONameFilter={(g) => setPEONameFilter(g)}
                peoNameFilter={peoNameFilter}
                onCarrierNameFilter={(g) => setCarrierNameFilter(g)}
                carrierNameFilter={carrierNameFilter}
                totalCount={totalCount}
                setTotalCount={setTotalCount}
                onStatusFilter={(s) => setStatusFilter(s)}
                statusFilter={statusFilter}
                onSortFieldFilter={(s) => setSortFieldFilter(s)}
                onSortOrderFilter={(s) => setSortOrderFilter(s)}
                onSortFieldAdvFilter={(s) => setSortFieldAdvFilter(s)}
                onSortOrderAdvFilter={(s) => setSortOrderAdvFilter(s)}
                connectivityRequestsStatus={connectivityRequestsStatus}
                onSelectedGroupsFilter={(s) => setSelectedGroupsFilter(s)}
                selectedGroupsFilter={selectedGroupsFilter}
                onSelectedPEOsFilter={(s) => setSelectedPEOsFilter(s)}
                selectedPEOsFilter={selectedPEOsFilter}
                onSelectedStatusesFilter={(s) => setSelectedStatusesFilter(s)}
                selectedStatusesFilter={selectedStatusesFilter}
                onSelectedCarriersFilter={(s) => setSelectedCarriersFilter(s)}
                selectedCarriersFilter={selectedCarriersFilter}
                quickFiltersClicked={quickFiltersClicked}
                setQuickFiltersClicked={setQuickFiltersClicked}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                setRefreshClicked={(s) => setRefreshClicked(s)}
            />
        </div>
    )
}

export default HomeConnectivityRequestsDashboard;