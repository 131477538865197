import React from "react";
import { Divider, Paper, IconButton, Button } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReplyIcon from '@material-ui/icons/Reply';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import BackupIcon from '@material-ui/icons/Backup';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import FormRecord from "./FormRecord";
import { DropzoneArea } from 'material-ui-dropzone';
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { FailureModal } from "../Modals/FailureModal";
import deleteForeverSVG from "../../Icons/trash-xmark.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function FormsAttachments({
    groupReq,
    selectedCarriers,
    requestSubmitted,
    requestSaved,
    // onSelectFormMainTypesValidationForm,
    // onSelectFormSubTypesValidationForm,
    // selectFormMainTypesValidationForm,
    // selectFormSubTypesValidationForm,
    attachFormsValidationForm,
    parentForceUpdate,
    index,
    ...props
}) {

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [expanded, setExpanded] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [uploadedForms, setUploadedForms] = React.useState([]);
    const [formAttached, setFormAttached] = React.useState();
    const [formAttachedBase64, setFormAttachedBase64] = React.useState();

    const [invalidFormatError, setInvalidFormatError] = React.useState("");

    const [tempGUID, setTempGUID] = React.useState("");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // React.useEffect(() => {
    //     if (!selectedCarriers) return;
    //     const selectedCarrier = selectedCarriers[value];
    //     if (!selectedCarrier) return;
    //     if (selectedCarrier.FormsAttachments?.length > 0 && selectedCarrier.FormsAttachments?.any(s => !s.MainTypes)) onSelectFormMainTypesValidationForm("Missing form main type, please select at least one"); else onSelectFormMainTypesValidationForm("");
    // }, [JSON.stringify(selectedCarriers), selectFormMainTypesValidationForm])

    // React.useEffect(() => {
    //     if (!selectedCarriers) return;
    //     const selectedCarrier = selectedCarriers[value];
    //     if (!selectedCarrier) return;
    //     if (selectedCarrier.FormsAttachments?.length > 0 && selectedCarrier.FormsAttachments?.any(s => !s.SubTypes)) onSelectFormSubTypesValidationForm("Missing form sub type, please select at least one"); else onSelectFormSubTypesValidationForm("");
    // }, [JSON.stringify(selectedCarriers), selectFormSubTypesValidationForm])

    React.useEffect(() => {
        if (!selectedCarriers) return;
        const selectedCarrier = selectedCarriers.filter(x => x.SelectedCRTypes?.any(s => s === "Forms"))[index];
        if (!selectedCarrier) return;
        selectedCarrier.FormsAttachments = selectedCarrier.FormsAttachments ?? [];
        selectedCarrier.formAttached = selectedCarrier.formAttached ?? undefined;
        setUploadedForms(selectedCarrier.FormsAttachments);
        setFormAttached(selectedCarrier.formAttached);
    }, [index, selectedCarriers]);

    const encodeFormAttachedBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result.split(',')[1];
                setFormAttachedBase64(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };

    const handleFileAttachedChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileAttachedBase64(files);
        }
    }

    const encodeFileAttachedBase64 = async (files) => {
        files.map((file) => {
            if (file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    var Base64 = reader.result?.split(',')[1];
                    AddFileToUi(file, Base64);
                };
                reader.onerror = (error) => {
                    console.log("error: ", error);
                };
            }
        });
    };
    const AddFileToUi = (formAttached, formAttachedBase64) => {
        if (formAttached === undefined) return;
        openLoader();
        let UploadedFormsArray = uploadedForms;
        let newForm = {
            Extension: formAttached?.name?.substring(formAttached?.name.lastIndexOf('.') + 1, formAttached?.name.length),
            Name: formAttached?.name,
        }
        let newFormUploadAPI = {
            Extension: formAttached?.name?.substring(formAttached?.name.lastIndexOf('.') + 1, formAttached?.name.length),
            Value: formAttachedBase64,
        }
        APIs.addFormAttachment(newFormUploadAPI).then(r => {
            newForm.GUID = r.GUID;
            setTempGUID(r?.GUID);
        });
        UploadedFormsArray.push(newForm);
        selectedCarriers.filter(x => x.SelectedCRTypes?.any(s => s === "Forms"))[index].FormsAttachments = [...UploadedFormsArray];
        setUploadedForms(selectedCarriers?.filter(x => x.SelectedCRTypes?.any(s => s === "Forms"))[index].FormsAttachments);
    }


    React.useEffect(() => {
        if (uploadedForms?.any(s => !s.GUID)) {
            openLoader();
        }
        else closeLoader();
    }, [tempGUID])

    const fileInputRef = React.useRef();

    return (
        <div className="mt-5 forms-attachments-section">
            <Paper elevation={0}>
                <Accordion expanded={true}>
                    <AccordionSummary
                        onClick={(e) =>
                            e?.stopPropagation()
                            //  expanded === `FormsAttachments` ? setExpanded("") : setExpanded("FormsAttachments")
                        }
                        // className="pl-0 pr-0"
                        // expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="expand-carrier-contacts"
                    >
                        <span >Attach Forms For Digitization</span>
                        <div className="col-xl-10 text-danger mt-1" hidden={(!requestSubmitted && !requestSaved) || groupReq?.groupRequest?.GroupRequestStatusID === 4}>{attachFormsValidationForm}</div>
                    </AccordionSummary>
                    <AccordionDetails className="p-3 w-100">

                        <div className="row w-100" id="connectivity-request-forms-section">
                            <div className="col-4 pl-0">
                                <div className="label-text">Upload Files</div>
                                <DropzoneArea
                                    maxFileSize={20 * 1024 * 1024}
                                    // add max size 20mb 
                                    dropzoneParagraphClass={"MuiDropzoneArea-text-updated-grayBlack"}
                                    filesLimit={100}
                                    dropzoneClass={"containerClassDrag"}
                                    dropzoneText={
                                        <div className="dz-message" data-dz-message style={{ display: "grid" }}>
                                            <b>
                                                Click here to add form
                                            </b>
                                            (Supported Format: PDF Only)
                                        </div>
                                    }
                                    onDrop={(loadedFiles) => handleFileAttachedChangeDrag(loadedFiles)}
                                    acceptedFiles={["application/pdf", ".pdf"]}
                                    showPreviewsInDropzone={false}
                                    showAlerts={false}
                                />
                                <div className="text-danger mt-2">{invalidFormatError}</div>
                            </div>

                            {uploadedForms?.length <= 0 ? (
                                <div className="col-8 pr-0">
                                    <div className="label-text">Uploads</div>
                                    <div className="forms-list-div">
                                        <h6 className="color grey2">No forms were attached</h6>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-8 pr-0">
                                    <div className="label-text">Uploads</div>
                                    <div className="forms-list-div">
                                        {uploadedForms?.length > 0 && uploadedForms?.map((l, li) => (
                                            <div key={`uploaded-form-${li}`} className="single-file-div">
                                                <div>{l?.Name}</div>
                                                <div>
                                                    <svg width="21" height="24" viewBox="0 0 21 24"
                                                        onClick={(e) => {
                                                            uploadedForms?.splice(li, 1);
                                                            forceUpdate();
                                                        }}
                                                        style={{ cursor: "pointer", justifySelf: "center" }}>
                                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                        </div>



                    </AccordionDetails>
                </Accordion>
            </Paper>
        </div >
    )
}

export default FormsAttachments;