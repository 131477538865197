import React from "react";
import { IconButton, Select, Typography } from "@material-ui/core";

function Pagination({ className, pageSize = 10, page = 1, count = 0, onChange, stateLimit, ...props }) {
    const totalPagesState = React.useState(1);
    const pageRangeState = React.useState([0, 0]);

    React.useEffect(() => {
        if (pageSize === 0 || !pageSize) {
            if (onChange) onChange(1, 10);
            return;
        }
        if (page === 0 || !page) {
            if (onChange) onChange(2, 1);
            return;
        }
        if (count < pageSize * (page - 1)) {
            if (onChange) onChange(2, 1);
            return;
        }
        const totalPages = Math.ceil(count / pageSize);
        totalPagesState[1](totalPages);
        const startNo = Math.min(count, 1 + pageSize * (page - 1));
        const endNo = Math.min(count, pageSize * page);
        if (startNo === NaN) startNo = 0;
        if (endNo === NaN) endNo = 0;
        pageRangeState[1]([startNo, endNo]);
    }, [page, pageSize, count]);

    return (
        <div className={`row mt-4 mb-4 pagination ${className}`} {...props}>

            {stateLimit && (
                <>
                    <div className="alignvh-center mb-4 mr-2">
                        <Typography noWrap style={{ fontSize: "14px", marginRight: "10px" }}>Rows per page:</Typography>
                        <Select
                            className="mr-5"
                            native
                            value={pageSize ?? 10}
                            onChange={(e) => {
                                if (onChange) onChange(1, parseInt(e.target.value) ?? 10);
                            }}
                        >
                            {[10, 15, 20, 25, 30].map((o, oi) => (
                                <option value={o} key={`Page-Option-${o}-${oi}`}>
                                    {o}
                                </option>
                            ))}
                        </Select>
                    </div>
                </>
            )}

            <div className="alignvh-center mb-4 mr-4">
                <Typography noWrap className='mr-3' style={{ fontSize: "14px" }}>
                    {`${pageRangeState[0][0]} - ${pageRangeState[0][1]} of ${count}`}
                </Typography>
                <IconButton
                    className="pagination-arrow"
                    onClick={(e) => {
                        if (onChange) onChange(2, page - 1);
                    }}
                    disabled={pageRangeState[0][0] === 0 || pageRangeState[0][0] === 1}
                >
                    <svg viewBox="0 0 10 17" width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.72471 15.3578L1.54557 8.50882C1.50427 8.46942 1.50427 8.40351 1.54557 8.36411L8.72471 1.51514"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>
                </IconButton>
                <IconButton
                    className="pagination-arrow"
                    onClick={(e) => {
                        if (onChange) onChange(2, page + 1);
                    }}
                    disabled={pageRangeState[0][1] >= count}
                >
                    <svg viewBox="0 0 10 17" width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.999512 15.3578L8.17865 8.50882C8.21995 8.46942 8.21994 8.40351 8.17865 8.36411L0.999512 1.51514"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </svg>
                </IconButton>
            </div>
        </div>
    );
}

export default Pagination;