import React from "react";
import { Select, FormControl, Divider, Paper, Button, MenuItem } from "@material-ui/core";
import PreMappingStepperAnalystView from './PreMappingStepperAnalystView';
import { useAuthentication } from "../../../../Data/Authentication";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import EditRequestSVG from "../../../../Images/editRequest.svg";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import editRequestIcon from "../../../../Icons/editIcon.svg";

function PreMappingFormAnalystView() {

    const { authUser } = useAuthentication();
    const { openLoader, closeLoader } = useLoader();
    const { connectivityRequestIDURL } = useParams();
    const [preMappingObject, setPreMappingObject] = React.useState();
    const [preMappingBasicData, setPreMappingObjectBasicData] = React.useState();
    const [preMappingSections, setPreMappingSections] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [primaryAnalyst, setPrimaryAnalyst] = React.useState("");
    const [secondaryAnalyst, setSecondaryAnalyst] = React.useState("");
    const [primaryAnalystValidation, setPrimaryAnalystValidation] = React.useState("");
    const [preMapSubmitted, setPreMapSubmitted] = React.useState(false);

    const [isSaveActive, setIsSaveActive] = React.useState(true);

    const [onEdit, setOnEdit] = React.useState(false);
    const [, forceUpdate] = React.useReducer(x => !x, false);

    React.useEffect(() => {
        openLoader();
        APIs.getPreMapping(connectivityRequestIDURL).then((r) => {
            setPreMappingObject(r?.data);
            setPreMappingObjectBasicData(r?.data?.PreMappingBasicData);
            setPreMappingSections(r?.data?.Sections);
            closeLoader();
        });
    }, [])

    React.useEffect(() => {
        if (!primaryAnalyst) { setPrimaryAnalystValidation("Please select a primary Analyst for this Pre-Mapping form."); } else { setPrimaryAnalystValidation(""); };
    }, [primaryAnalyst, primaryAnalystValidation])

    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])

    const handlePrimaryAnalystChange = (e, newValue) => {
        setPrimaryAnalyst(e.target.value);
        setIsSaveActive(true);

    }

    const handleSecondaryAnalystChange = (e, newValue) => {
        setSecondaryAnalyst(e.target.value);
        setIsSaveActive(true);

    }

    React.useEffect(() => {
        setPrimaryAnalyst(...usersList.filter(s => `${s.FirstName} ${s.LastName}` === preMappingBasicData?.Analysts[0]?.Name));
        setSecondaryAnalyst(...usersList.filter(s => `${s.FirstName} ${s.LastName}` === preMappingBasicData?.Analysts[1]?.Name) ?? "");
    }, [onEdit === true])

    return (
        <div id="pre-mapping-form">
            <BreadcrumbSteps
                breadcrumbTitle={`Connectivity Request #${connectivityRequestIDURL}`}
                isCurrent={false}
                breadcrumbTitleCurrent="View Pre-Mapping Form"
                breadcrumbLink={`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`}
            />
            <Paper elevation={0} className="p-5 mb-3 mt-3">
                <div className="row w-100 alignvh-center">
                    <h5 className="black1 fw-700">Pre-Mapping Form</h5>
                    <div className="ml-auto">
                        <Button variant="contained" className="blue-outlined-btn" onClick={() => setOnEdit(true)}>
                        <svg width="20" height="24" viewBox="0 0 25 24">
                                                <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                            </svg>
                            <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Form</label>
                        </Button>
                    </div>
                </div>
                <Divider className="mt-3 mb-5" />
                <div className="row">
                    <div className="col-xl-2 alignvh-center">
                        <h6 className="form-title">Client Name</h6>
                    </div>
                    <div className="col-xl-10">
                        <div className="textfield-div">
                            {preMappingBasicData?.Peo?.PeoName ? preMappingBasicData?.Peo?.PeoName : preMappingBasicData?.GroupName}
                        </div>
                    </div>

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Carrier Name</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <div className="textfield-div">
                            {preMappingBasicData?.CarrierName}
                        </div>
                    </div>

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Primary Analyst</h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                    </div>
                    <div className="col-xl-10 mt-3">
                        {onEdit ? (
                            <FormControl label="Select primary analyst" focused={false} variant="outlined" className="w-100">
                                <Select
                                    className="text-dropdown"
                                    value={primaryAnalyst ?? ""}
                                    onChange={handlePrimaryAnalystChange}
                                >
                                    <MenuItem value="">None</MenuItem>
                                    {usersList?.map((c, ci) => (
                                        <MenuItem key={`users-list-${ci}`} value={c}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (
                            <div className="textfield-div">
                                {preMappingBasicData?.Analysts[0]?.Name}
                            </div>
                        )}
                    </div>
                    {onEdit && (
                        <>
                            <div className="col-xl-2 alignvh-center" hidden={!preMapSubmitted}></div>
                            <div className="col-xl-10 mt-2 text-danger" hidden={!preMapSubmitted}>{primaryAnalystValidation}</div>
                        </>
                    )}

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Primary Analyst Email</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        {onEdit ? (
                            <div className="textfield-div">
                                {primaryAnalyst?.Email ?? ""}
                            </div>
                        ) : (
                            <div className="textfield-div">
                                {preMappingBasicData?.Analysts[0]?.Email}
                            </div>
                        )}
                    </div>

                    {preMappingBasicData?.Analysts && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Analyst</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                {onEdit ? (
                                    <FormControl label="Select secondary analyst" focused={false} variant="outlined" className="w-100">
                                        <Select
                                            className="text-dropdown"
                                            value={secondaryAnalyst ?? ""}
                                            onChange={handleSecondaryAnalystChange}
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {usersList?.map((c, ci) => (
                                                <MenuItem key={`users-list-${ci}`} value={c}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <div className="textfield-div">
                                        {preMappingBasicData?.Analysts[1]?.Name === "undefined undefined" ? "" : preMappingBasicData?.Analysts[1]?.Name}
                                    </div>
                                )}
                            </div>

                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Analyst Email</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                {onEdit ? (
                                    <div className="textfield-div">
                                        {secondaryAnalyst?.Email ?? ""}
                                    </div>
                                ) : (
                                    <div className="textfield-div">
                                        {preMappingBasicData?.Analysts[1]?.Email}
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {preMappingBasicData?.Admins && preMappingBasicData?.Admins[0]?.Name !== "undefined undefined" && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Primary Admin</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                <div className="textfield-div">
                                    {preMappingBasicData?.Admins[0]?.Name}
                                </div>
                            </div>

                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Primary Admin Email</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                <div className="textfield-div">
                                    {preMappingBasicData?.Admins[0]?.Email}
                                </div>
                            </div>
                        </>
                    )}

                    {preMappingBasicData?.Admins && preMappingBasicData?.Admins[1] && preMappingBasicData?.Admins[1]?.Name !== "undefined undefined" && (
                        <>
                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Admin</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                <div className="textfield-div">
                                    {preMappingBasicData?.Admins[1]?.Name}
                                </div>
                            </div>

                            <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Secondary Admin Email</h6>
                            </div>
                            <div className="col-xl-10 mt-3">
                                <div className="textfield-div">
                                    {preMappingBasicData?.Admins[1]?.Email}
                                </div>
                            </div>
                        </>
                    )}

                    <div className="col-xl-2 mt-3 alignvh-center">
                        <h6 className="form-title">Submission Date</h6>
                    </div>
                    <div className="col-xl-10 mt-3">
                        <div className="textfield-div">
                            {Intl.DateTimeFormat('en-US').format(preMappingObject?.SubmissionDate)}
                        </div>
                    </div>
                </div>
            </Paper>
            <PreMappingStepperAnalystView primaryAnalyst={primaryAnalyst} secondaryAnalyst={secondaryAnalyst} onPreMapSubmitted={s => setPreMapSubmitted(s)} preMappingObject={preMappingObject} setPreMappingObject={setPreMappingObject} preMappingBasicData={preMappingBasicData} setPreMappingObjectBasicData={setPreMappingObjectBasicData} preMappingSections={preMappingSections} setPreMappingSections={setPreMappingSections} onEdit={onEdit} setOnEdit={setOnEdit} isSaveActive={isSaveActive} setIsSaveActive={(s) => setIsSaveActive(s)} />
        </div>
    )
}

export default PreMappingFormAnalystView;