import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper, FormControl } from '@material-ui/core/';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { useAuthentication } from "../../../Data/Authentication";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


function HomeProductionDashboardFilterModalBody({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    onPageSizeAdv,
    onSelectedPageAdv,
    setAdvancedFiltersAppliedClicked,
    onCloseClearReset,
    onSelectedFeedNameAdvFilter,
    onSelectedExecutionStatusAdvFilter,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);

    const [feedIDAdv, setFeedIDAdv] = React.useState("");
    const [selectedFeedNameAdv, setSelectedFeedNameAdv] = React.useState([]);
    const [feedsList, setFeedsList] = React.useState([]);
    const [feedName, setFeedName] = React.useState();
    const [feedSearchValue, setFeedSearchValue] = React.useState("");
    const [selectedExecutionStatusAdv, setSelectedExecutionStatusAdv] = React.useState([]);
    const [executionStatusesList, setExecutionStatusesList] = React.useState([]);
    const [lastRunFromDateAdv, setLastRunFromDateAdv] = React.useState(todayDate);
    const [lastRunToDateAdv, setLastRunToDateAdv] = React.useState(todayDate);

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedName ?? "" }).then((r) => !(r?.data?.length) ? setFeedsList([]) : setFeedsList(r?.data));
    }, [feedName])

    React.useEffect(() => {
        APIs.getProductionExecutionStatusesList().then((r) => setExecutionStatusesList(r?.data));
    }, [])

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    const handleTabChange = (event, value) => {
        setTabValue(value);
    };

    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
    }

    const handleClearSearch = () => {
        setFeedIDAdv("");
        setSelectedFeedNameAdv([]);
        onSelectedFeedNameAdvFilter([]);
        setSelectedExecutionStatusAdv([]);
        onSelectedExecutionStatusAdvFilter([]);
        setLastRunFromDateAdv(monthDate);
        setLastRunToDateAdv(todayDate);
        onSelectedPageAdv(1);
    }

    React.useEffect(() => {
        setFeedIDAdv("");
        setSelectedFeedNameAdv([]);
        onSelectedFeedNameAdvFilter([]);
        setSelectedExecutionStatusAdv([]);
        onSelectedExecutionStatusAdvFilter([]);
        setLastRunFromDateAdv(todayDate);
        setLastRunToDateAdv(todayDate);
        onSelectedPageAdv(1);
    }, [advancedFiltersClicked === false])

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }

    const handleFeedNameClick = (value) => (event) => {
        let selectedFeedsArray = selectedFeedNameAdv;
        if (!value || value === "") selectedFeedsArray = [];
        else if (selectedFeedsArray.indexOf(value) >= 0) {
            selectedFeedsArray.splice(selectedFeedsArray.indexOf(value), 1);
        }
        else {
            selectedFeedsArray.push(value);
        }
        setSelectedFeedNameAdv([...selectedFeedsArray]);
        onSelectedFeedNameAdvFilter([...selectedFeedsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleExecutionStatusesClick = (value) => (event) => {
        let selectedExecutionStatusesArray = selectedExecutionStatusAdv;
        if (!value || value === "") selectedExecutionStatusesArray = [];
        else if (selectedExecutionStatusesArray.indexOf(value) >= 0) {
            selectedExecutionStatusesArray.splice(selectedExecutionStatusesArray.indexOf(value), 1);
        }
        else {
            selectedExecutionStatusesArray.push(value);
        }
        setSelectedExecutionStatusAdv([...selectedExecutionStatusesArray]);
        onSelectedExecutionStatusAdvFilter([...selectedExecutionStatusesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced Transmissions Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="filter-body-div">
                    <Box style={{ display: 'flex' }}>
                        <Tabs
                            style={{ maxHeight: "370px", overflow: "auto" }}
                            orientation="vertical"
                            // variant="scrollable"
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                        >
                            <Tab className="filter-tab-btn" label="Feed Name" {...a11yProps(0)} />
                            <Tab className="filter-tab-btn" label="Status" {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <input type="text" className="text-input w-100" placeholder="Search feeds here..." value={feedSearchValue} onChange={(e) => setFeedSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                {feedsList?.filter(s => feedSearchValue ? s.FeedName?.toLowerCase().includes(feedSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`feeds-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="feeds-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`feed-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedFeedNameAdv && selectedFeedNameAdv.indexOf(c) >= 0}
                                                            onClick={handleFeedNameClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.FeedName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                {executionStatusesList.map((f, fi) => (
                                    <Accordion key={`execution-status-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="execution-statuses-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`execution-status-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedExecutionStatusAdv && selectedExecutionStatusAdv.indexOf(f) >= 0}
                                                            onClick={handleExecutionStatusesClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.Name}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                    </Box>
                </div >
            </DialogContent>

            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog>
    )

}

export default HomeProductionDashboardFilterModalBody;