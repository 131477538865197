import React from "react";
import { FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { emailPattern } from "../ViewEditGroupRequest/ViewGroupRequestClientContactsRow";
import infoCircleSVG from "../../../Icons/info-circle.svg";


function SelectSingleConnectivityRequestSubType({
    carrier,
    selectedCRTypes,
    subTypeList,
    setSubTypeList,
    formsSubTypeList,
    setFormsSubTypeList,
    selectCRHREmailValidationForm,
    requestSubmitted,
    parentForceUpdate,
    ...props
}) {

    const [FormsSubTypeValidation, setFormsSubTypeValidation] = React.useState('');
    const [emailValidation, setEmailValidation] = React.useState('');

    const handleCrSubTypeValueChange = (event) => {
        carrier.ConnectivityRequestEDISubTypeID = +(event.target.value)
        if (parentForceUpdate) parentForceUpdate();
    };

    const handleCrFormsSubTypeValueChange = (event) => {
        carrier.ConnectivityRequestFormsSubTypeID = +(event.target.value);
        parentForceUpdate();
    };

    const handleFormsEmailValueChange = (e) => {
        carrier.HREmail = e.target.value;
        parentForceUpdate();
    }

    React.useEffect(() => {
        if (!carrier.ConnectivityRequestFormsSubTypeID) setFormsSubTypeValidation("This Field is Required");
        else setFormsSubTypeValidation("");
    }, [carrier.ConnectivityRequestFormsSubTypeID, FormsSubTypeValidation])

    React.useEffect(() => {
        var emails = carrier.HREmail?.replace(/\s/g, '').split(",");
        var valid = true;

        for (var i = 0; i < emails?.length; i++) {
            if (emails[i] == "" || !emailPattern.test(emails[i])) {
                valid = false;
            }
        }
        if (!carrier.HREmail) {
            setEmailValidation("This Field is Required");
        }
        else if (!valid) {
            setEmailValidation("Invalid Email Format");
        }
        else setEmailValidation("");
    }, [carrier.HREmail, emailValidation])


    return (
        <div className="col-12 mt-3">
            <div>
                <div label="Select CR Sub Type" className="w-100 row"  >
                    {carrier?.SelectedCRTypes?.includes("EDI") &&
                        <div className="col-6"><h6 className="form-title">EDI Sub Type: </h6> <br />
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={carrier.ConnectivityRequestEDISubTypeID ?? 1}
                                    onChange={handleCrSubTypeValueChange}
                                >

                                    {subTypeList?.map((s, si) => (
                                        <FormControlLabel key={`CR-EDI-SubType-${si}`} value={s.Id}
                                            control={<Radio sx={{
                                                '&, &.Mui-checked': {
                                                    color: '#F7008C',
                                                },
                                            }}
                                            />}

                                            label={s.Name} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    }
                    {carrier?.SelectedCRTypes?.includes("Forms") &&
                        <div className="col-6"><h6 className="form-title">Form Transmission Type: </h6> <br />
                            <FormControl component="fieldset">
                                <RadioGroup
                                    required
                                    component="fieldset"
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={carrier.ConnectivityRequestFormsSubTypeID ?? ""}
                                    onChange={handleCrFormsSubTypeValueChange}
                                >

                                    {formsSubTypeList?.map((s, si) => (

                                        <FormControlLabel key={`CR-Forms-SubType-${si}`} value={s.Id}
                                            control={<Radio
                                                sx={{
                                                    '&, &.Mui-checked': {
                                                        color: '#F7008C',
                                                    },
                                                }}
                                            />}
                                            label={
                                                <>
                                                    <>
                                                        {s?.Name}
                                                    </>
                                                    <CustomTooltip style={{ marginLeft: "10px", marginRight: "23px" }} TransitionComponent={Zoom} title={s?.Name == "Bypass Admin Approval" ? "Once an employee signs the form, it will automatically be transmitted to the carrier."
                                                        : s?.Name == "Require Admin Approval" ? "HR will be required to approve and transmit the form after the employee signs the form." : ""}>
                                                        {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                                        <svg width="20" height="24" viewBox="0 0 25 24" >
                                                            <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                                        </svg>
                                                    </CustomTooltip>
                                                </>}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            {requestSubmitted && <span className="text-danger mb-2 ml-2">{FormsSubTypeValidation}</span>}

                            <div className="col-xl-10 mt-3 w-100 d-flex flex-column ">
                                <h6 className="form-title">HR Email
                                    <CustomTooltip style={{ marginLeft: "10px", marginRight: "23px" }} TransitionComponent={Zoom} title={"This is the contact who will receive the notification emails regarding Forms"}>
                                        {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                        <svg width="20" height="24" viewBox="0 0 25 24" >
                                            <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                        </svg>
                                    </CustomTooltip>
                                </h6>
                                {/* <span className="text-danger mb-2 ml-2">*</span> */}

                            </div>
                            <div className="col-xl-10 mt-3 w-100 d-flex flex-column">

                                <input type="email" multiple className="text-input w-100"
                                    style={{ padding: "0 20px" }}
                                    value={carrier?.HREmail ?? ""}
                                    required
                                    onChange={handleFormsEmailValueChange}
                                />
                                <span className="fs-12">You can enter multiple emails here separated by commas</span>
                                {requestSubmitted && <span className="text-danger mb-2 ml-2">{emailValidation}</span>}
                            </div>
                        </div>

                    }
                </div>
            </div>

        </div>
    )
}

export default SelectSingleConnectivityRequestSubType;
