import React, { useEffect, useState } from 'react'
import { useLoader } from '../../../Layout/Loader';
import * as Apis from '../../../../Data/APIs'
import ClientFormsPendingEmployeeSignatureTabSingleFeedWithForms from './ClientFormsPendingEmployeeSignatureTabSingleFeedWithForms';

const ClientFormsPendingEmployeeSignatureTab = () => {
    const { openLoader, closeLoader } = useLoader();
    const [feeds, setFeeds] = useState([]);


    const getFeedsHandler = () => {
        openLoader();
        Apis.getClientormsFeed({
            // 15 >> pending Forms
            lsi: '15',
        }).then(r => {
            if (r.msg == "Success") {
                setFeeds(r?.data)
                // setTotalCount(r?.totalCount)
            } else setFeeds([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }

    useEffect(() => {
        getFeedsHandler()
    }, [])

    return (
        <div className="mt-3">
            <div className="row">
                <div className="d-flex flex-column w-100">
                    {feeds && feeds?.length > 0  ?
                        <>
                            {feeds.map((feed, feedIndex) =>
                                <div className="d-flex flex-column w-100" key={feedIndex}>
                                    <ClientFormsPendingEmployeeSignatureTabSingleFeedWithForms
                                        feed={feed}
                                    />
                                </div>
                            )}
                        </>
                        :
                        <div className="no-data-alert">
                            <h6>No Results Found!</h6>
                        </div>

                    }
                </div>
            </div>
        </div>
    )
}

export default ClientFormsPendingEmployeeSignatureTab
