import React from "react";
import { Divider, Paper, IconButton, Button } from "@material-ui/core";
import ConnectivityRequestFileRow from "./ConnectivityRequestFileRow";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ConnectivityRequestUploadedFileRow from "./ConnectivityRequestUploadedFileRow";
import ReplyIcon from '@material-ui/icons/Reply';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { useAuthentication } from "../../Data/Authentication";
import { DropzoneArea } from 'material-ui-dropzone';
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { FailureModal } from "../Modals/FailureModal";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ConnectivityRequestFilesAttachments({ connectivityReq, value, setValue, onUploadedFilesArray, filesList, selectedPlans, otherPlanTypes, ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const [tempGUID, setTempGUID] = React.useState("");
    const { openLoader, closeLoader } = useLoader();
    const [uploadedFiles, setUploadedFiles] = React.useState([]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const handleCompanionGuideChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "CarrierCompanionGuide", title: "Companion Guide" });
        }
    }

    const handleGroupStructureChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "GroupStructureDocument", title: "Group Structure" });
        }
    }

    const handleConnectivityInfoChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "ConnectivityInfoDocument", title: "Connectivity Info" });
        }
    }

    const handleNamingConventionInfoChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "NamingConventionInfoDocument", title: "Naming Convention Info" });
        }
    }

    const handleCensusFileChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "CensusFile", title: "Census File" });
        }
    }

    const handleEncryptionFileChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "EncryptionFile", title: "Encryption key" });
        }
    }

    const handleOtherFileChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, type: "OtherFile", title: "Other" });
        }
    }


    const encodeFileBase64 = async ({ files, type, title }) => {
        files.map((file) => {
            var reader = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    var Base64 = reader.result.split(',')[1];
                    addCurrentFilesInArray(file, Base64, type, title);
                };
                reader.onerror = (error) => {
                    console.log("error: ", error);
                };
            }
        });
    };

    const addCurrentFilesInArray = (file, fileBase64, type, title) => {
        if (file === undefined) return;
        openLoader();
        let UploadedFilesArray = uploadedFiles;
        let newFile = {
            Type: type,
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Title: title,
            Name: file?.name,
        }
        let newFileUploadAPI = {
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Value: fileBase64,
        }
        APIs.addAttachment(newFileUploadAPI).then(r => {
            newFile.GUID = r.GUID;
            setTempGUID(r?.GUID);
        });
        UploadedFilesArray.push(newFile);
        connectivityReq.Attachments = [...UploadedFilesArray];
        setUploadedFiles(connectivityReq.Attachments);
        onUploadedFilesArray(connectivityReq.Attachments);
    }

    React.useEffect(() => {
        if (uploadedFiles?.any(s => !s.GUID)) {
            openLoader();
        }
        else closeLoader();
    }, [tempGUID])

    return (
        <div className="mt-5" id="connectivity-request-files-section">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel-header-attach-docs"
                >
                    <span>Attach Documents</span>
                </AccordionSummary>
                <AccordionDetails>
                    <AppBar position="static" color="default">
                        <div className="row">
                            <Tabs
                                className="new-tabs"
                                value={value}
                                onChange={handleChange}
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs"
                            >
                                <Tab label="Uploaded Files" />
                                {(userActions?.includes("25") ||
                                    userActions?.includes("20")) && (
                                        <Tab label="+ Add New" />
                                    )
                                }
                            </Tabs>
                        </div>
                    </AppBar>
                    {value === 0 && (
                        <div>
                            {filesList?.length <= 0 ? (
                                <div className="files-list text-center mt-3">
                                    <h6 className="color grey2">No files were uploaded</h6>
                                </div>
                            ) : (
                                <>
                                    <div className="row text-center table-header">
                                        <div className="col-2 table-cell first-cell">File Extension</div>
                                        <div className="col-4 table-cell">File Name</div>
                                        <div className="col-2 table-cell">File Type</div>
                                        <div className="col-2 table-cell">Uploaded Date</div>
                                        <div className="col-2 table-cell last-cell">Actions</div>
                                    </div>
                                    {filesList?.map((a, ai) => (
                                        <ConnectivityRequestUploadedFileRow
                                            filesList={filesList}
                                            key={`uploaded-files-${ai}`}
                                            connectivityReq={connectivityReq}
                                            uploadedFile={a}
                                            index={ai}
                                            selectedPlans={selectedPlans}
                                            otherPlanTypes={otherPlanTypes}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    )}
                    {value === 1 && (
                        <div className="row mt-3">
                            <div className="col-3 pl-0">
                                <div className="label-text mb-1">Choose Files</div>
                                <div className="w-100 mt-2 mb-auto">
                                    <DropzoneArea
                                        dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                        filesLimit={100}
                                        dropzoneText={"Companion Guide"}
                                        onDrop={(files) => handleCompanionGuideChangeDrag(files)}
                                        showPreviewsInDropzone={false}
                                        showAlerts={false}
                                    />
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Group Structure"}
                                            onDrop={(files) => handleGroupStructureChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Connectivity Info"}
                                            onDrop={(files) => handleConnectivityInfoChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Name Convention"}
                                            onDrop={(files) => handleNamingConventionInfoChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Census File"}
                                            onDrop={(files) => handleCensusFileChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Encryption Key"}
                                            onDrop={(files) => handleEncryptionFileChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                    <div className="mt-2 w-100">
                                        <DropzoneArea
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                            filesLimit={100}
                                            dropzoneText={"Other"}
                                            onDrop={(files) => handleOtherFileChangeDrag(files)}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            {uploadedFiles?.length <= 0 ? (
                                <div className="col-9 pr-0">
                                    <div className="label-text">Attached Files</div>
                                    <div className="files-list-div">
                                        <h6 className="color grey2">No files were attached</h6>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-9 pr-0">
                                    <div className="label-text">Attached Files</div>
                                    <div className="files-list-div">
                                        {uploadedFiles?.length > 0 && uploadedFiles?.map((l, li) => (
                                            <ConnectivityRequestFileRow
                                                key={`files-list-${li}`}
                                                file={l}
                                                index={li}
                                                uploadedFiles={uploadedFiles}
                                                setUploadedFiles={setUploadedFiles}
                                                onUploadedFilesArray={onUploadedFilesArray}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ConnectivityRequestFilesAttachments;