import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import { Button, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import * as APIs from "../../../Data/APIs";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { SuccessModal } from "../../Modals/SuccessModal";
import { FailureModal } from "../../Modals/FailureModal";
import { useLoader } from "../../Layout/Loader";
import { useAuthentication } from "../../../Data/Authentication";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import { emailPattern } from "../../GroupRequest/ViewEditGroupRequest/ViewGroupRequestClientContactsRow";
import editPenIcon from "../../../Icons/editPen.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import deleteForeverSVG from "../../../Icons/trash-xmark.svg";
import saveIconSVG from "../../../Icons/save.svg";

function AnalystConnectivityRequestSingleContactRow({ carrierContactTypes, contact, index, connectivityReq, parentForceUpdate, setConnectivityReq, selectedPlans, otherPlanTypes, ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const [editContactClicked, setEditContactClicked] = React.useState(false);
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();

    const [saveButtonClicked, setSaveButtonClicked] = React.useState(false);

    // validations
    const [firstNameValidation, setFirstNameValidation] = React.useState('');
    const [lastNameValidation, setLastNameValidation] = React.useState('');
    const [emailValidation, setEmailValidation] = React.useState('');
    const [typeValidation, setTypeValidation] = React.useState('');


    // Validation UseEffects
    // First Name Validation
    React.useEffect(() => {
        if (!contact.ConnectivityRequestContactFirstName || contact.ConnectivityRequestContactFirstName.split(" ").join("").length == 0) setFirstNameValidation("This Field is Required");
        else setFirstNameValidation("");
    }, [contact.ConnectivityRequestContactFirstName, firstNameValidation])

    // Last Name Validation
    React.useEffect(() => {
        if (!contact.ConnectivityRequestContactLastName || contact.ConnectivityRequestContactLastName.split(" ").join("").length == 0) setLastNameValidation("This Field is Required");
        else setLastNameValidation("");
    }, [contact.ConnectivityRequestContactLastName, lastNameValidation])

    // Email Validation
    React.useEffect(() => {
        if (!contact.ConnectivityRequestContactEmail ||
            contact.ConnectivityRequestContactEmail.split(" ").join("").length == 0) {
            setEmailValidation("This Field is Required");
        } else if (!emailPattern.test(contact.ConnectivityRequestContactEmail)) {
            setEmailValidation("Invalid Email Format");
        }
        else setEmailValidation("");
    }, [contact.ConnectivityRequestContactEmail, emailValidation])

    // type Validation
    React.useEffect(() => {
        if (!contact.CarrierContactType || contact.CarrierContactType.split(" ").join("").length == 0) setTypeValidation("This Field is Required");
        else setTypeValidation("");
    }, [contact.CarrierContactType, typeValidation])
    // // Validation UseEffects


    React.useEffect(() => {
        if (!connectivityReq) return;
        connectivityReq.ConnectivityRequestContacts = connectivityReq.ConnectivityRequestContacts ?? [];
    }, [connectivityReq, JSON.stringify(connectivityReq?.ConnectivityRequestContacts)]);


    const reGetConnectivityRequestData = () => {
        openLoader();
        APIs.getConnectivityRequest(connectivityReq?.ConnectivityRequestID)
            .then((s) => { setConnectivityReq(s?.data); setEditContactClicked(false); closeLoader(); })

    }

    const handleEditedInputs = () => {
        setSaveButtonClicked(true)
        if (firstNameValidation || lastNameValidation || emailValidation || typeValidation) return;
        openLoader();
        connectivityReq.ConnectivityRequestContacts[index].isDelete = false;
        let updatedContacts = {};

        updatedContacts = {
            ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
            CarriersContacts: connectivityReq.ConnectivityRequestContacts,
        };

        APIs.editConnectivityRequestContacts(updatedContacts).then(r => {
            reGetConnectivityRequestData();
        });
    }

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const handleDeleteContactConfirm = () => {
        openModal(0, handleDeleteContact, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this Carrier contact?`,
        })
    }

    const handleDeleteContact = () => {
        openLoader();
        contact.isDelete = true;

        let deleteContacts = {};

        deleteContacts = {
            ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
            CarriersContacts: [contact],
        };

        APIs.editConnectivityRequestContacts(deleteContacts).then(r => {
            APIs.getConnectivityRequest(connectivityReq?.ConnectivityRequestID).then((s) => { setConnectivityReq(s?.data); setEditContactClicked(false); closeLoader(); })
        });
    }


    return (
        <div className="row table-row">
            <div className="col-2 table-cell">
                {editContactClicked ? (
                    <div>
                        <TextField style={{ width: "80%" }} className="mx-auto" variant="outlined" value={contact.ConnectivityRequestContactFirstName ?? ""} onChange={(e) => { contact.ConnectivityRequestContactFirstName = e.target.value; parentForceUpdate(); }} placeholder="Write first name here..." />
                        {saveButtonClicked && firstNameValidation &&
                            <span className="text-danger">
                                {firstNameValidation}
                            </span>
                        }
                    </div>
                ) : (
                    contact.ConnectivityRequestContactFirstName
                )}
            </div>
            <div className="col-2 table-cell">
                {editContactClicked ? (
                    <div>
                        <TextField style={{ width: "80%" }} className="mx-auto" variant="outlined" value={contact.ConnectivityRequestContactLastName ?? ""} onChange={(e) => { contact.ConnectivityRequestContactLastName = e.target.value; parentForceUpdate(); }} placeholder="Write last name here..." />
                        {saveButtonClicked && lastNameValidation &&
                            <span className="text-danger">
                                {lastNameValidation}
                            </span>
                        }
                    </div>
                ) : (
                    contact.ConnectivityRequestContactLastName
                )}
            </div>
            {(userActions?.includes("23") || userActions?.includes("20")) && (
                <>
                    <div className="col-4 table-cell">
                        {editContactClicked ? (
                            <div className="w-100">
                                <TextField style={{ width: "80%" }} variant="outlined"
                                    value={contact.ConnectivityRequestContactEmail ?? ""}
                                    onChange={(e) => { contact.ConnectivityRequestContactEmail = e.target.value; parentForceUpdate(); }}
                                    name="Email"
                                    placeholder="Write last name here..."
                                    className="mx-auto"
                                />

                                {saveButtonClicked && emailValidation &&
                                    <span className="text-danger mx-auto">
                                        {emailValidation}
                                    </span>
                                }
                            </div>
                        ) : (
                            contact.ConnectivityRequestContactEmail
                        )}
                    </div>
                    <div className="col-2 table-cell">
                        {editContactClicked ? (
                            <div className="editable-textfield w-100">
                                <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                    <Select style={{ textAlign: "left", display: "grid" }} className="text-dropdown" value={contact.CarrierContactType} onChange={(e) => { contact.CarrierContactType = e.target.value; parentForceUpdate(); }}>
                                        {carrierContactTypes?.map((c, ci) => (
                                            <MenuItem value={c}>{c}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {saveButtonClicked && typeValidation &&
                                    <span className="text-danger">
                                        {typeValidation}
                                    </span>
                                }
                            </div>
                        ) : (
                            contact.CarrierContactType
                        )}
                    </div>
                    <div className="col-2 table-cell">
                        {editContactClicked ? (
                            <div>
                                <Button className="icon-btn" color="inherit" onClick={handleEditedInputs}>
                                    <svg width="15" height="21" viewBox="0 0 22 21" >
                                        <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
                                    </svg>
                                </Button>
                                <CustomTooltip TransitionComponent={Zoom} title="Cancel">
                                    <Button className="pl-0 icon-btn" color="inherit" onClick={() => {
                                        reGetConnectivityRequestData()
                                    }}>
                                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                        </svg>
                                    </Button>
                                </CustomTooltip>
                                <CustomTooltip TransitionComponent={Zoom} title={connectivityReq.ConnectivityRequestContacts.length < 2 ? "Connectivity should have at least one contact" : ""}>
                                    <span>
                                        <Button className="icon-btn" color="inherit" disabled={connectivityReq.ConnectivityRequestContacts.length < 2} onClick={handleDeleteContactConfirm}>
                                            <svg width="21" height="24" viewBox="0 0 21 24" style={{ cursor: "pointer" }}>
                                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                            </svg>
                                        </Button>
                                    </span>
                                </CustomTooltip>
                            </div>
                        ) : (
                            <Button onClick={() => setEditContactClicked(true)} color="inherit">
                                <svg width="20" height="20" viewBox="0 0 20 20" >
                                    <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                                </svg>
                            </Button>
                        )}
                    </div>
                </>
            )}
            {!(userActions?.includes("23") || userActions?.includes("20")) && (
                <>
                    <div className="col-5 table-cell">
                        {editContactClicked ? (
                            <div className="w-100">
                                <TextField style={{ width: "80%" }} variant="outlined"
                                    value={contact.ConnectivityRequestContactEmail ?? ""}
                                    onChange={(e) => { contact.ConnectivityRequestContactEmail = e.target.value; parentForceUpdate(); }}
                                    name="Email"
                                    placeholder="Write last name here..."
                                    className="mx-auto"
                                />

                                {saveButtonClicked && emailValidation &&
                                    <span className="text-danger mx-auto">
                                        {emailValidation}
                                    </span>
                                }
                            </div>
                        ) : (
                            contact.ConnectivityRequestContactEmail
                        )}
                    </div>
                    <div className="col-3 table-cell">
                        {editContactClicked ? (
                            <div className="editable-textfield">
                                <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                    <Select style={{ textAlign: "left", display: "grid" }} className="text-dropdown" value={contact.CarrierContactType} onChange={(e) => { contact.CarrierContactType = e.target.value; parentForceUpdate(); }}>
                                        {carrierContactTypes?.map((c, ci) => (
                                            <MenuItem value={c}>{c}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        ) : (
                            contact.CarrierContactType
                        )}
                    </div>
                </>
            )}
        </div >
    )
}

export default AnalystConnectivityRequestSingleContactRow;
