import React from "react";
import BreadcrumbSteps from "../../Layout/BreadcrumbSteps";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { useLoader } from "../../Layout/Loader";
import * as APIs from "../../../Data/APIs";
import { useAuthentication } from "../../../Data/Authentication";
import { useParams } from "react-router-dom";
import { Zoom, Paper, Tabs } from "@material-ui/core";
import TabsTitles from "../../Layout/TabsTitles";
import { AppBar, FormControl, Tab } from '@mui/material';
import CreateOERequestComponent from "./CreateOERequestComponent";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, } from "@material-ui/core";
import { FailureModal } from "../../Modals/FailureModal";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { useHistory } from "react-router-dom";
import { CustomTooltip } from "../../Layout/Tooltip";
import { AlertModal } from "../../Modals/AlertModal";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Moment from 'moment';
import TabsTitlesWithMoreDD from "../../Layout/TabsTitlesWithMoreDD";

class OEForm {
    constructor({ ConnectivityRequestID, PlanYearStartDate, ClientDataExpectedDate, IsolvedDataChangesChecked, UpdatedGroupStructureChecked, OEChanges, Attachment, FileName, Extension, Value, OEContact, DraftChecked }) {
        this.ConnectivityRequestID = ConnectivityRequestID;
        this.PlanYearStartDate = PlanYearStartDate;
        this.ClientDataExpectedDate = ClientDataExpectedDate;
        this.IsolvedDataChangesChecked = IsolvedDataChangesChecked;
        this.UpdatedGroupStructureChecked = UpdatedGroupStructureChecked;
        this.OEChanges = OEChanges;
        this.Attachment = Attachment;
        this.FileName = FileName;
        this.Extension = Extension;
        this.Value = Value;
        this.OEContact = OEContact;
        this.DraftChecked = DraftChecked;
    }
}
function CreateOERequestsView() {
    const { groupRequestIDURL } = useParams();
    const [groupReq, setGroupReq] = React.useState();
    const { openLoader, closeLoader } = useLoader();
    const [connectivitiesList, setConnectivitiesList] = React.useState([]);
    const [checkedConnectivitie, setCheckedConnectivitie] = React.useState(false);
    const [selectedConnectivities, setSelectedConnectivities] = React.useState([]);
    const [oERequestBody, setOERequestBody] = React.useState([]);
    const [valueTab, setValueTab] = React.useState(0);
    const [expanded, setExpanded] = React.useState("SelectOE");
    const [oERequests, setOERequests] = React.useState([]);
    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [formPlanYearStartDayValidation, setFormPlanYearStartDayValidation] = React.useState("");
    const [formExpectedDayValidation, setFormExpectedDayValidation] = React.useState("");
    const [selectAllConnectivitiesChecked, setSelectAllConnectivitiesChecked] = React.useState(false);
    // const [formFileAttachedValidation, setFormFileAttachedValidation] = React.useState("");
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const dialog = useModalDialog(0);
    const routeHistory = useHistory();
    const { authUser } = useAuthentication();
    // Get group request data to the view group request page
    React.useEffect(() => {
        openLoader();
        APIs.getGroupRequestData(groupRequestIDURL).then((r) => setGroupReq({ groupRequest: r?.data?.GroupRequest, connectivities: r?.data?.connectivities }),);
    }, [])

    React.useEffect(() => {
        if (!groupReq) return;
        setConnectivitiesList(groupReq.connectivities);

        closeLoader();
    }, [groupReq]);

    const handleChangeTabs = (event, newValue) => {
        setValueTab(newValue);
    };
    const handleSelectAllConnectivitiesChange = (value) => (e) => {
        setSelectAllConnectivitiesChecked(e.target.checked);
    }
    const handleSelectConnectivitiesChange = (value) => (e) => {
        setCheckedConnectivitie(e.target.checked);
    }
    const handleSelectAllConnectivitiesClick = (value) => (event) => {
        let oERequestsArray = oERequests;
        let selectedConnectivitiesArray = selectedConnectivities;
        if (!value || value === "") { selectedConnectivitiesArray = []; oERequestsArray = []; }
        else if (selectedConnectivitiesArray.indexOf(...value) >= 0) {
            oERequestsArray.splice(selectedConnectivitiesArray.indexOf(...value));
            selectedConnectivitiesArray.splice(selectedConnectivitiesArray.indexOf(...value));
        }
        else {
            selectedConnectivitiesArray.length = 0;
            selectedConnectivitiesArray.push(...value);
            value.map((v, vi) => {
                let listNew = new OEForm({
                    ConnectivityRequestID: v.ConnectivityRequestID, PlanYearStartDate: v.PlanYearStartDate ? Moment(v.PlanYearStartDate).format('YYYY-MM-DD') : null,
                    ClientDataExpectedDate: v.ClientDataExpectedDate ? Moment(v.ClientDataExpectedDate).format('YYYY-MM-DD') : null, IsolvedDataChangesChecked: false, DraftChecked: false, UpdatedGroupStructureChecked: false, OEChanges: "", FileName: "", Extension: "", Value: "", OEContact: authUser?.attributes?.sub
                });
                oERequestsArray.push(listNew);
            })
        }
        setOERequests([...oERequestsArray]);
        setSelectedConnectivities([...selectedConnectivitiesArray]);

    }

    const handleConnectivitiesChange = (value) => (event) => {
        let oERequestsArray = oERequests;
        let selectedConnectivitiesArray = selectedConnectivities;
        if (!value || value === "") { selectedConnectivitiesArray = []; oERequestsArray = []; }
        else if (selectedConnectivitiesArray.indexOf(value) >= 0) {
            oERequestsArray.splice(selectedConnectivitiesArray.indexOf(value), 1);
            selectedConnectivitiesArray.splice(selectedConnectivitiesArray.indexOf(value), 1);
        }
        else {
            selectedConnectivitiesArray.push(value);
            let listNew = new OEForm({
                ConnectivityRequestID: value.ConnectivityRequestID, PlanYearStartDate: value.PlanYearStartDate ? Moment(value.PlanYearStartDate).format('YYYY-MM-DD') : null,
                ClientDataExpectedDate: value.ClientDataExpectedDate ? Moment(value.ClientDataExpectedDate).format('YYYY-MM-DD') : null, IsolvedDataChangesChecked: false, DraftChecked: false, UpdatedGroupStructureChecked: false, Attachment: "", FileName: "", Extension: "", Value: "", OEContact: authUser?.attributes?.sub
            });
            oERequestsArray.push(listNew);
        }

        setOERequests([...oERequestsArray]);
        setSelectedConnectivities([...selectedConnectivitiesArray]);
        setValueTab(0);
    }

    const submitOERequest = (e) => {
        e.preventDefault();
        if (formPlanYearStartDayValidation || formExpectedDayValidation) return;

        openLoader();
        let oERequestBody = [];
        oERequests?.map((c, ci) => {
            oERequestBody.push(
                {
                    ConnectivityRequestID: c.ConnectivityRequestID,
                    PlanYearStartDate: c.PlanYearStartDate,
                    ClientDataExpectedDate: c.ClientDataExpectedDate,
                    ISolvedDataChanges: c.IsolvedDataChangesChecked ? 1 : 0,
                    UpdatedGroupStructure: c.UpdatedGroupStructureChecked ? 1 : 0,
                    IsDraftOERequest: c.DraftChecked ? 1 : 0,
                    OEChanges: c.OEChanges,
                    IsolvedContact: c.OEContact,
                    Attachment: c.UpdatedGroupStructureChecked ? {
                        FileName: c.FileName,
                        Extension: c.Extension,
                        Value: c.Value
                    } : null,
                }
            )
        });


        let checkDuplicatedBody = {
            ConnectivityRequestID: selectedConnectivities.map(s => `${s.ConnectivityRequestID}`),
            PlanYearStartDate: oERequests?.map(c => c.PlanYearStartDate),
            ConnectivityRequestFeedID: selectedConnectivities.map(s => `${s.ConnectivityRequestFeedID}`),
        }


        // check if OE Request is Duplicated for same start Plan Year
        APIs.checkDuplicateOERequest(checkDuplicatedBody).then((r) => {
            closeLoader();
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg,
                });
            } else {
                if (r?.Duplicate === true) {
                    AlertModal(dialog, () => {
                        openLoader();
                        // Api for add Multiple OE Requests
                        APIs.createMultipleOERequests({ OERequests: oERequestBody }).then((r) => {
                            if (r?.success === "0") {
                                FailureModal(dialog, {
                                    title: 'Failure',
                                    body: r?.msg,
                                });
                            } else {
                                routeHistory.push(`/GroupRequests/ViewGroupRequest/${groupRequestIDURL}`)
                            }
                        }).catch((s) => FailureModal(dialog, {
                            title: 'Failure',
                            body: 'Error processing request',
                        })).finally((t) => closeLoader());
                    }
                        ,
                        {
                            title: 'Alert! One or more OE Request(s) is/are duplicated!',
                            info:
                                <div>

                                    {r?.DuplicateList?.length === 0 ? <div className="text-left fs-12">

                                        You added Duplicate OE Requests in the same Plan Year Start Date for the Connectivity Requests ....
                                    </div> :
                                        <div className="text-left fs-12">
                                            Duplicated OE Requests:
                                            {r?.DuplicateList?.map(s =>
                                                s?.OERequestIDs?.map(d =>
                                                    <a className='warningLink' key={`oeReques-${s?.OERequestIDs}-${d}`} href={`/OERequests/ViewConnectivityRequest/${s?.ConnectivityRequestID}/ViewOERequest/${d}`} target="_blank" rel="noopener noreferrer">- {d}</a>
                                                )
                                            )}
                                        </div >}
                                </div >
                            ,
                            question: `Are you sure you want to proceed?`,
                        });
                } else {

                    // Api for add Multiple OE Requests
                    APIs.createMultipleOERequests({ OERequests: oERequestBody }).then((r) => {
                        if (r?.success === "0") {
                            FailureModal(dialog, {
                                title: 'Failure',
                                body: r?.msg,
                            });
                        } else {
                            routeHistory.push(`/GroupRequests/ViewGroupRequest/${groupRequestIDURL}`)
                        }
                    }).catch((s) => FailureModal(dialog, {
                        title: 'Failure',
                        body: 'Error processing request',
                    })).finally((t) => closeLoader());
                }
            }
        }).catch((s) => FailureModal(dialog, {
            title: 'Failure',
            body: 'Error processing request',
        })).finally((t) => closeLoader());



    }


    function getStatuseClass(status) {
        switch (status) {
            case "In Progress":
                return 'inProgress';
            case "On Hold":
                return 'inProgress';
            case "Blocked":
                return 'blocked';
            case "Cancelled":
                return 'cancelled';
            case "Not Started":
                return 'notStarted';
            case "Production":
                return 'production';
            case "Production Disabled":
                return 'productionDisabled';
            default:
                return '';
        }
    }

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data),
            closeLoader()
        ));
    }, [])

    const getTitleCheckBox = (c) => {
        if (c.StructureTypeName === "401K") {
            return "No OE Requests to be submitted for contribution/401k feeds"
        }
        else if (c.CancellationDate && c.ConnectivityRequestStatusName !== "Cancelled") {
            return `${c.CarrierName} connectivity request has a future cancellation effective ${Moment(c.CancellationDate).format('MM/DD/YYYY')}`
        }
        else if (c.ConnectivityRequestStatusName === "Cancelled") {
            if (c.CancellationDate) {
                return `${c.CarrierName} connectivity request is cancelled effective ${Moment(c.CancellationDate).format('MM/DD/YYYY')}`
            }
            else {
                return `${c.CarrierName} connectivity request is cancelled`
            }
        }
        else {
            return "";
        }

    }


    const getTheWarmingMessage = () => {
        let checkStatus = connectivitiesList?.filter((c) => (c.ConnectivityRequestStatusID !== 7 && c.ConnectivityRequestType !== "Forms")).length > 0;
        let checkForms = connectivitiesList?.filter((c) => (c.ConnectivityRequestType === "Forms")).length > 0;
        if (checkStatus && checkForms) {
            return <div><ErrorOutlineIcon className="mr-1" />You can add OE Requests only for the production (not 401k) EDI connectivity requests</div>;
        } else if (checkStatus) {
            return <div><ErrorOutlineIcon className="mr-1" /> You can add OE Requests only for the production (not 401k) connectivity requests</div>;
        }
        else if (checkForms) {
            return <div><ErrorOutlineIcon className="mr-1" />You can not add OE Requests for forms connectivity requests</div>;
        } else {
            return "";
        }

    }

    return (
        <div id="create-OE-Request">
            <div className="d-flex flex-wrap">

                {groupReq && (
                    <BreadcrumbSteps
                        breadcrumbTitle={`View Group Request "${groupReq?.groupRequest?.Peo?.PeoName ? groupReq?.groupRequest?.Peo?.PeoName : groupReq?.groupRequest?.GroupName}"`}
                        isCurrent={false}
                        breadcrumbTitleCurrent="Add OE Requests"
                        breadcrumbLink={`/GroupRequests/ViewGroupRequest/${groupReq?.groupRequest?.GroupRequestID}`}
                    />
                )}
                <div className="warmingOERequest ml-auto">  {getTheWarmingMessage()}</div>
            </div>

            <TabsTitles tabTitle={["Add OE Requests"]} />

            <Paper elevation={0} className="p-5">

                <div id="connectivitiesList-items-table">
                    <div className="row table-header">
                        <div className="col-2 table-cell first-cell">
                            <CustomTooltip TransitionComponent={Zoom} title="Select All">
                                <span>
                                    <FormControlLabel
                                        style={{ display: "unset", margin: "0" }}
                                        aria-label="Acknowledge"
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                id={`connectivities-select-all`}
                                                value={connectivitiesList?.filter((c) => ((c.ConnectivityRequestStatusID === 7 && c.ConnectivityRequestType !== "Forms" && c.StructureTypeName !== "401K" && !c.CancellationDate)))}
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checked={connectivitiesList?.filter((c) => ((c.ConnectivityRequestStatusID === 7 && c.ConnectivityRequestType !== "Forms" && c.StructureTypeName !== "401K" && !c.CancellationDate))).length > 0 && selectedConnectivities?.length === connectivitiesList?.filter((c) => ((c.ConnectivityRequestStatusID === 7 && c.ConnectivityRequestType !== "Forms" && c.StructureTypeName !== "401K" && !c.CancellationDate))).length}
                                                onChange={handleSelectAllConnectivitiesChange(connectivitiesList?.filter((c) => ((c.ConnectivityRequestStatusID === 7 && c.ConnectivityRequestType !== "Forms" && c.StructureTypeName !== "401K" && !c.CancellationDate))))}
                                                onClick={handleSelectAllConnectivitiesClick(connectivitiesList?.filter((c) => ((c.ConnectivityRequestStatusID === 7 && c.ConnectivityRequestType !== "Forms" && c.StructureTypeName !== "401K" && !c.CancellationDate))))}
                                                checkedIcon={<DoneOutlineIcon />}
                                                color="default"
                                            />
                                        }
                                    />
                                </span>
                            </CustomTooltip>
                        </div>
                        <div className="col table-cell" style={{ cursor: "pointer" }} >Connectivity Request ID</div>
                        <div className="col table-cell" style={{ cursor: "pointer" }}>Carrier Name</div>
                        <div className="col table-cell last-cell" style={{ cursor: "pointer" }} >Status</div>
                    </div>

                    {connectivitiesList?.length > 0 && connectivitiesList?.map((c, ci) => (
                        <div className="row table-row-with-border-bottom table-row-bordered-l" key={`connectivities-list-${ci}`}>
                            <div className="col-2 table-cell first-cell">
                                <React.Fragment key={`connectivities-list-${ci}`}>
                                    <CustomTooltip TransitionComponent={Zoom} title={getTitleCheckBox(c)}>
                                        <FormControlLabel disabled={(c.ConnectivityRequestStatusID !== 7 || c.ConnectivityRequestType === "Forms" || c.StructureTypeName === "401K" || c.CancellationDate) ? true : false}
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}

                                            labelPlacement='top'
                                            control={
                                                <Checkbox id={`connectivities-option-${ci}`}
                                                    value={c} icon={<CheckBoxOutlineBlankIcon />}
                                                    checked={(selectedConnectivities && selectedConnectivities.indexOf(c) >= 0)}
                                                    onChange={handleSelectConnectivitiesChange(c)}
                                                    onClick={handleConnectivitiesChange(c)}

                                                    checkedIcon={<DoneOutlineIcon />}
                                                    color="default" />
                                            } />
                                    </CustomTooltip>
                                </React.Fragment>
                            </div>


                            <div className="col table-cell ">
                                <a style={{ cursor: "pointer" }} href={`/ConnectivityRequests/ViewConnectivityRequest/${c?.ConnectivityRequestID}`}>
                                    {c.ConnectivityRequestID}
                                </a>
                            </div>

                            <div className="col table-cell">
                                <a style={{ cursor: "pointer" }} href={`/ConnectivityRequests/ViewConnectivityRequest/${c?.ConnectivityRequestID}`}>
                                    {c.IsolvedCarrierName === "Third Party" ? `${c.IsolvedCarrierName} (${c.ThirdPartyName})` : c.IsolvedCarrierName}
                                </a>
                            </div>
                            <div className="col table-cell last-cell">
                                <div className={`status-div m-auto ${getStatuseClass(c.ConnectivityRequestStatusName)}`}>
                                    {c.ConnectivityRequestStatusName}
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

                {/* tab here */}

                {selectedConnectivities?.length > 0 &&
                    <div className="mt-4 newUi-collapse">
                        <Accordion className="carriers-contact-acc oe-edit-class add-oe-request-from-gr" expanded={expanded === `SelectOE`} id="select-carrier-plans">
                            <AccordionSummary
                                onClick={() => expanded === `SelectOE` ? setExpanded("") : setExpanded("SelectOE")}
                                className="pl-0 pr-0"
                                expandIcon={<ExpandMoreIcon />}
                                aria-label="Expand"
                                aria-controls="expand-oe"
                            >
                                <span>OE Requests</span>
                            </AccordionSummary>

                            <AccordionDetails>
                                <div className="w-100 border-bottom">
                                    <TabsTitlesWithMoreDD
                                        tabTitle={[...selectedConnectivities.map(sc => sc?.CarrierName === "Third Party" ? `${sc.CarrierName} (${sc.ThirdPartyName})` : sc.CarrierName)]}
                                        value={valueTab}
                                        setValue={setValueTab}
                                    />
                                </div>

                                <form onSubmit={submitOERequest} >
                                    {selectedConnectivities?.map((s, si) => (
                                        si === valueTab && (
                                            <CreateOERequestComponent
                                                groupReq={groupReq}
                                                connectivity={s}
                                                index={si}
                                                oERequest={oERequests[si]}
                                                key={`single-connectivity-${si}`}
                                                oERequestBody={oERequestBody}
                                                onSetOERequestBody={(s) => setOERequestBody(s)}
                                                oERequests={oERequests}
                                                onSetOERequests={(s) => setOERequests(s)}
                                                requestSubmitted={requestSubmitted}
                                                onSetFormPlanYearStartDayValidation={(s) => setFormPlanYearStartDayValidation(s)}
                                                onSetFormExpectedDayValidation={(s) => setFormExpectedDayValidation(s)}
                                                // onSetFormFileAttachedValidation={(s) => setFormFileAttachedValidation(s)}
                                                groupsAndOEContactsList={groupsAndOEContactsList}
                                            />
                                        )
                                    ))}
                                    <div className="w-100 text-right mt-5">
                                        <Button type="submit" variant="contained" className="blue-btn" onClick={() => { setRequestSubmitted(true); }}>
                                            Submit OE
                                            {oERequests.length <= 1 ? " Request" : " Requests"}
                                        </Button>
                                    </div>
                                </form>
                            </AccordionDetails>
                        </Accordion>
                    </div >
                }
            </Paper>
        </div>
    )



}

export default CreateOERequestsView;