import React from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { SuccessModal } from "../Modals/SuccessModal";
import { FailureModal } from "../Modals/FailureModal";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import Moment from 'moment';
import DownloadLink from "react-download-link";
import { useAuthentication } from "../../Data/Authentication";
import deleteForeverSVG from "../../Icons/trash-xmark.svg";
import downloadIconSVG from "../../Icons/downloadIcon.svg";


function ConnectivityRequestUploadedFileRow({
    filesList,
    connectivityReq,
    uploadedFile,
    index,
    setUploadedFiles,
    selectedPlans,
    otherPlanTypes,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [decodedString, setDecodedString] = React.useState("");


    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }


    const handleDeleteFile = () => {
        openModal(0, deleteFile, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this file?`,
        })
    }

    const deleteFile = () => {
        openLoader();
        const submitDeleteFile = {
            ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
            GroupRequestID: connectivityReq?.GroupRequestID,
            ConnectivityRequestType: connectivityReq?.ConnectivityRequestType,
            ConnectivityRequestStatusID: connectivityReq?.ConnectivityRequestStatusID,
            ConnectivityRequestPhaseID: connectivityReq?.ConnectivityRequestPhaseID,
            ConnectivityRequestFormsSubTypeID: connectivityReq?.ConnectivityRequestFormsSubTypeID,
            ConnectivityRequestEDISubTypeID: connectivityReq?.ConnectivityRequestEDISubTypeID,
            HREmail: connectivityReq?.HREmail,
            CarrierGroupNumber: connectivityReq?.CarrierGroupNumber,
            MigrationConnectivityRequest: connectivityReq?.MigrationConnectivityRequest,
            MigrationStartDate: connectivityReq?.MigrationStartDate,
            PlannedFirstProductionDate: connectivityReq?.PlannedFirstProductionDate,
            MigrationPhaseID: connectivityReq?.MigrationPhaseID,
            ConnectivityRequestFeedID: connectivityReq?.ConnectivityRequestFeedID,
            ConnectivityRequestConfigurations: connectivityReq?.ConnectivityRequestConfigurations,
            ReceivedEncryptionRequirements: connectivityReq?.ReceivedEncryptionRequirements,
            ClientEffectiveDate: connectivityReq?.ClientEffectiveDate,
            ClientContact: connectivityReq?.ClientContact,
            IsolvedCarrier: {
                Code: connectivityReq?.IsolvedCarrierCode,
                Name: connectivityReq?.IsolvedCarrierName,
                Type: connectivityReq?.IsolvedCarrierType,
                isNew: false
            },
            NewAttachments: [{
                Type: uploadedFile.type,
                Name: uploadedFile.Name,
                Extension: uploadedFile.extension,
                GUID: uploadedFile.guid,
                isDeleted: true
            }],
            // CarriersContacts: connectivityReq.ConnectivityRequestContacts
            PlanTypes: selectedPlans?.filter(s => s !== "Other") ?? [],
            OtherPlanTypes: otherPlanTypes?.split(/\r\n|\r|\n/).length === 1 && otherPlanTypes?.split(/\r\n|\r|\n/)[0] === "" ? [] : otherPlanTypes?.split(/\r\n|\r|\n/) ?? [],
        };

        if (userRoles?.includes("1") ||
            userRoles?.includes("7")) {
            APIs.getEditConnectivityRequestForAnalyst(submitDeleteFile).then((r) => {
                window.location.reload();
            });
        }
        else {
            APIs.editConnectivityRequestPlans(submitDeleteFile).then((r) => {
                window.location.reload();
            });
        }
    }

    const handleDownloadFile = () => {
        const submitDownloadFile = {
            ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
            guid: uploadedFile.guid,
            attachmentExtension: uploadedFile.extension,
        }

        APIs.getSingleFileAttachment(submitDownloadFile).then((r) => {
            setDecodedString(r?.data);
        });
    }

    React.useEffect(() => {
        handleDownloadFile();
    }, [decodedString])

    return (
        <div>
            <div className="row text-center table-row table-row-bordered-l-r">
                <div className="col-2 table-cell first-cell">
                    {uploadedFile?.extension?.toUpperCase()}
                </div>
                <div className="col-4 table-cell color black2 fw-500">
                    {uploadedFile?.Name}
                </div>
                <div className="col-2 table-cell">
                    {uploadedFile?.type === "CarrierCompanionGuide" ? "Companion Guide" : uploadedFile?.type === "GroupStructureDocument" ? "Group Structure" : uploadedFile?.type === "ConnectivityInfoDocument" ? "Connectivity Info" : uploadedFile?.type === "NamingConventionInfoDocument" ? "Naming Convention Info" : uploadedFile?.type === "CensusFile" ? "Census File" : uploadedFile?.type === "EncryptionFile" ? "Encryption Key" : uploadedFile?.type === "OtherFile" ? "Other" : ""}
                </div>
                <div className="col-2 table-cell color black2 fw-500">
                    {uploadedFile?.Created ? Moment(uploadedFile?.Created).format('MM/DD/YYYY') : null}
                </div>
                <div className="col-2 table-cell last-cell" style={{ display: "flex", justifyContent: "center" }}>
                    <DownloadLink
                        label={
                            <svg width="20" height="20" viewBox="0 0 20 20" onClick={handleDownloadFile} style={{ cursor: "pointer" }}>
                                <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                            </svg>

                        }
                        filename={uploadedFile.Name}
                        exportFile={() => {
                            let binaryString = window.atob(decodedString);
                            let binaryLen = binaryString.length;
                            let ab = new ArrayBuffer(binaryLen);
                            let ia = new Uint8Array(ab);
                            for (let i = 0; i < binaryLen; i++) {
                                ia[i] = binaryString.charCodeAt(i);
                            }
                            let bb = new Blob([ab]);
                            return bb;
                        }}
                        style={{marginTop:"-2px", marginRight:"2px"}}
                    />
                    <svg width="21" height="24" viewBox="0 0 21 24" onClick={handleDeleteFile} style={{ cursor: "pointer" }}>
                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default ConnectivityRequestUploadedFileRow;