import { useEffect } from "react"
import { signOut } from 'next-auth/react'

export default function SignoutPage() {

    useEffect(() => {
        setTimeout(() => {
            signOut({ callbackUrl: window.location.origin });
        }, 1000);
    }, []);

    return (
        <>
            <div className="loader">
                Loading..
            </div>
            <style jsx>{`
        .loader{
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transform: -webkit-translate(-50%, -50%);
          transform: -moz-translate(-50%, -50%);
          transform: -ms-translate(-50%, -50%);
        }
      `}</style>
        </>
    )
}