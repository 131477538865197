import React, { useState } from 'react';
import MultipleTabsTitles from "../Layout/MultipleTabsTitles";
import ClientFormsPendingEmployeeSignatureTab from './tabs/ClientFormsPendingEmployeeSignatureTab/ClientFormsPendingEmployeeSignatureTab';
import ClientFormsPendingBatchSubmissionTab from './tabs/ClientFormsPendingBatchSubmissionTab/ClientFormsPendingBatchSubmissionTab';
import ClientFormsPendingHRSignatureTab from './tabs/ClientFormsPendingHRSignatureTab/ClientFormsPendingHRSignatureTab';
import ClientFormsTransmissionsTab from './tabs/ClientFormsTransmissionsTab/ClientFormsTransmissionsTab';
import ClientFormsRejectedTab from './tabs/ClientFormsRejectedTab/ClientFormsRejectedTab';


const clientFormsTabsArray = ['Pending Employee Signature', 'Pending HR Signature', 'Pending Batch Transmission', 'Transmissions', 'Rejected']
const clientFormsTabsComponents = [
    <ClientFormsPendingEmployeeSignatureTab />,
    <ClientFormsPendingHRSignatureTab />,
    <ClientFormsPendingBatchSubmissionTab />,
    <ClientFormsTransmissionsTab />,
    <ClientFormsRejectedTab />


]
const ClientFormsTabs = () => {
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    };

    return (
        <div className='mt-5'>
            <div className="d-flex overflow-x-auto-tabs">
                <MultipleTabsTitles
                    tabTitle={clientFormsTabsArray}
                    value={tabValue}
                    onChange={handleTabChange}
                />
            </div>

            {clientFormsTabsComponents[tabValue]}
        </div>
    )
}

export default ClientFormsTabs
