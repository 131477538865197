import React from "react";
import Moment from 'moment';
import Pagination from "../../Layout/Pagination";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { useLoader } from "../../Layout/Loader";
import * as APIs from "../../../Data/APIs";
import { useHistory } from "react-router-dom";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { FailureModal } from "../../Modals/FailureModal";
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import { useAuthentication } from "../../../Data/Authentication";
import PreviewIcon from '@mui/icons-material/Preview';
import AddBoxIcon from '@mui/icons-material/AddBox';
import GenericTable from "../../Layout/GenericTable";

function HomeConnectivityRequestsTable({
    advancedFiltersClicked,
    quickFiltersClicked,
    connectivityRequestsList,
    setConnectivityRequestsList,
    selectedPage,
    pageSize,
    onPageSize,
    onSelectedPage,
    totalCount,
    onSortFieldFilter,
    onSortOrderFilter,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSizeAdv,
    onPageSizeAdv,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    ...props
}) {
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const dialog = useModalDialog(0);
    const [sortOrder, setSortOrder] = React.useState("ASC");
    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const [connectivityRequestID, setConnectivityRequestID] = React.useState();
    const [responseMsg, setResponseMsg] = React.useState("");
    const [index, setIndex] = React.useState(-1);
    const [openPMFClicked, setOpenPMFClicked] = React.useState(false);
    const [preMappingObject, setPreMappingObject] = React.useState();
    const [preMappingBasicData, setPreMappingObjectBasicData] = React.useState();
    const [preMappingSections, setPreMappingSections] = React.useState([]);

    // -------------------------------- NEW TABLE ---------------------------------
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        if (type === "quick") {
            onSortFieldFilter(column?.sortingkey);
            onSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            onSortFieldAdvFilter(column?.sortingkey);
            onSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const baseColumnsArray = [
        {
            name: 'Client Name',
            sortable: true,
            sortingkey: 'GroupName',
            id: 'GroupName',
            selector: (c) => isClient() ?
                <>
                    {c?.PeoName ? c?.PeoName : c?.GroupName}
                </> :
                <>
                    <a href={`/GroupRequests/ViewGroupRequest/${c?.GroupRequestID}`}>{c?.PeoName ? c?.PeoName : c?.GroupName}</a>
                </>

        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: (c) => isClient() ?
                <>
                    {c?.CarrierName === "Third Party" ? `${c.CarrierName} (${c.ThirdPartyName})` : c.CarrierName}
                </> :
                <>
                    <a href={`/ConnectivityRequests/ViewConnectivityRequest/${c?.ConnectivityRequestID}`}>{c?.CarrierName === "Third Party" ? `${c.CarrierName} (${c.ThirdPartyName})` : c.CarrierName}</a>
                </>

        },
        {
            name: 'Status',
            sortable: true,
            sortingkey: 'ConnectivityRequestStatusID',
            id: 'ConnectivityRequestStatusID',
            selector: (c) => <div className={`status-div 
                ${c?.ConnectivityRequestStatusName === "In Progress" ? `inProgress` :
                    c?.ConnectivityRequestStatusName === "On Hold" ? `onHold` :
                        c?.ConnectivityRequestStatusName === "Blocked" ? `blocked` :
                            c?.ConnectivityRequestStatusName === "Cancelled" ? `cancelled` :
                                c?.ConnectivityRequestStatusName === "Not Started" ? `notStarted` :
                                    c?.ConnectivityRequestStatusName === "Production" ? `production` : `productionDisabled`}`}>
                {c?.ConnectivityRequestStatusName}
            </div>
        }
    ];

    const isClient = () => {
        return userRoles?.includes("6")
    }

    return (
        <div>
            <div className="mt-5" id="connectivity-group-requests-table">
                <GenericTable
                    columns={[...baseColumnsArray]}
                    data={connectivityRequestsList || []}
                    onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
                />

                <div>
                    <Pagination
                        stateLimit={true}
                        pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                        page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                        onChange={(t, v) => {
                            if (quickFiltersClicked) {
                                if (t === 1) onPageSize(v);
                                else if (t === 2) onSelectedPage(v);
                            } else if (advancedFiltersClicked) {
                                if (t === 1) onPageSizeAdv(v);
                                else if (t === 2) onSelectedPageAdv(v);
                            }

                        }}
                        count={totalCount}
                    />
                </div>
            </div>
        </div>
    )
}

export default HomeConnectivityRequestsTable;
