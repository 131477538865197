import React from "react";
import { Divider, FormControl, Select, MenuItem, Button } from "@material-ui/core";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";

function AnalystFeedFormAttachMap({
    feed,
    groupPlans,
    filteredFormPlans,
    parentForceUpdate,
    form,
    filteredMatchedPlans,
    carrierMaps,
    ...props
}) {

    const dialog = useModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [mapID, setMapID] = React.useState();

    React.useEffect(() => {
        setMapID(form?.MapId)
    }, [])

    const attachMap = (e) => {
        e.preventDefault();
        const attachingMapObject = {
            MapsArray: [
                {
                    MapId: mapID,
                    Id: form?.Id,
                }
            ],
            FeedID: feed?.FeedID,
        }

        APIs.matchMapsForDigitalForms(attachingMapObject).then((r) => {
            if (r.message === "Success")
                window.location.reload();
            else return;
        });
    }

    const handleMapChange = (e) => {
        setMapID(e.target.value);
    };

    return (
        <div id="df-matching-plans-modal">
            {/* <h5 className="black1 fw-700">{form?.DigitalFormName} / Plans Matching</h5>
            <Divider /> */}
            <div className="row alignvh-center">
                <div className="col-xl-2 mt-3 alignvh-center">
                    <h6 className="form-title">Map</h6>
                </div>
                <div className="col-xl-10 mt-3">
                    <FormControl label="Select Map" focused={false} variant="outlined" className="w-100">
                        <Select
                            className="text-dropdown"
                            value={mapID ?? ""}
                            onChange={handleMapChange}
                        >
                            <MenuItem value="">None</MenuItem>
                            {carrierMaps?.map((s, si) => (
                                <MenuItem key={`feed-map-${si}`} value={s?.Id}>{s?.Name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="row w-100 mt-5" style={{ justifyContent: "flex-end" }}>
                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => window.location.reload()}>Cancel</Button>
                <Button variant="contained" className="blue-btn" onClick={attachMap}>Attach Map</Button>
            </div>
        </div>
    )
}

export default AnalystFeedFormAttachMap;