import React from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Divider, IconButton } from "@material-ui/core";
import deleteForeverSVG from "../../Icons/trash-xmark.svg";


function ConnectivityRequestFileRow({
    file,
    index,
    uploadedFiles,
    setUploadedFiles,
    onUploadedFilesArray,
    ...props
}) {

    const [editIndex, setEditIndex] = React.useState(index);


    const handleDeleteFile = () => {
        uploadedFiles.splice(editIndex, 1);
        uploadedFiles = [...(uploadedFiles ?? [])];
        setUploadedFiles(uploadedFiles);
        onUploadedFilesArray(uploadedFiles);
    }

    return (
        <div className="single-file-div">
            <div>{file?.Title}</div>
            <div>{file?.Name}</div>
            <div>
                <svg width="21" height="24" viewBox="0 0 21 24" onClick={handleDeleteFile} style={{ cursor: "pointer" }}>
                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                </svg>
            </div>
        </div>
    )
}

export default ConnectivityRequestFileRow;