import React from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ListIcon from '@material-ui/icons/List';
import { Paper, Select, FormControl, MenuItem } from "@material-ui/core";
import CarrierPlansAnalystView from "./CarrierPlansAnalystView";
import RelationshipCodesAnalystView from "./RelationshipCodesAnalystView";
import AddIcon from '@material-ui/icons/Add';
import GenericNewSectionAnalystView from "./GenericNewSectionAnalystView";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { FailureModal } from "../../../Modals/FailureModal";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import GenericNewSectionAnalyst from "../CreatePreMappingForm/GenericNewSectionAnalyst";
import SummaryAnalystView from "./SummaryAnalystView";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";


function PreMappingStepperAnalystView({ onPreMapSubmitted, preMappingObject, setPreMappingObject, preMappingBasicData, setPreMappingObjectBasicData, preMappingSections, setPreMappingSections, connectivityReq, primaryAnalyst, secondaryAnalyst, onEdit, setOnEdit, isSaveActive, setIsSaveActive, ...props }) {

    const { connectivityRequestIDURL } = useParams();
    const [stepsList, setStepsList] = React.useState([]);
    const { openLoader, closeLoader } = useLoader();

    const [activeStep, setActiveStep] = React.useState(0);

    let currentTimestamp = new Date().getTime();
    const dialog = useModalDialog(0);
    const [sections, setSections] = React.useState([]);
    const [addSectionClicked, setAddSectionClicked] = React.useState(false);
    const [section, setSection] = React.useState();
    const [customizedSection, setCustomizedSection] = React.useState("");
    const [contentList, setContentList] = React.useState([]);
    const [deletedSection, setDeletedSection] = React.useState(false);
    const [isNextActive, setIsNextActive] = React.useState(false);
    const [isNewSectionAdded, setIsNewSectionAdded] = React.useState(false);
    const [preMapSubmitted, setPreMapSubmitted] = React.useState(false);
    const routeHistory = useHistory();

    React.useEffect(() => {
        setStepsList(preMappingSections?.map(s => ({
            title: s.Title,
            icon: s.Title === "Carrier Plans" ? <LocalHospitalIcon /> : s.Title === "Relationship Codes" ? <SupervisorAccountIcon /> : <AddIcon />
        })))
    }, [preMappingSections])

    React.useEffect(() => {
        openLoader();
        APIs.getSectionsList().then((r) => (
            setSections(r?.data),
            closeLoader()
        ));
    }, [])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSectionChange = (e) => {
        setSection(e.target.value);
    };

    const handleCustomizedSectionChange = (e) => {
        setCustomizedSection(e.target.value);
    };

    const handleAddSection = () => {
        if (stepsList.map(s => s.title).indexOf(section) > 0 || stepsList.map(s => s.title?.toLowerCase()).indexOf(customizedSection?.toLowerCase()) > 0) {
            FailureModal(dialog, {
                title: 'Failure!',
                body: 'This section already exists.'
            }); return;
        }
        let stepsListArray = [...stepsList];
        let contentListArray = [...contentList];
        if (section !== "Not Listed...") {
            stepsListArray.splice(stepsList.length - 1, 0, { title: section, icon: <AddIcon /> });
            contentListArray.splice(contentList.length, 0, {
                Title: section,
                Type: section?.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
                Index: preMappingSections.length + contentList.length,
                Model: []
            })
        } else {
            stepsListArray.splice(stepsList.length - 1, 0, { title: customizedSection, icon: <AddIcon /> });
            contentListArray.splice(contentList.length, 0, {
                Title: customizedSection,
                Type: customizedSection?.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase()),
                Index: preMappingSections.length + contentList.length,
                Model: []
            });
        }
        setStepsList(stepsListArray);
        setContentList(contentListArray);
        handleCancelAddSection();
        setIsNewSectionAdded(true);
    }

    const handleCancelAddSection = () => {
        setAddSectionClicked(false);
        setSection();
        setCustomizedSection("");
    }

    const handleCancelEdit = () => {
        openLoader();
        APIs.getPreMapping(connectivityRequestIDURL).then((r) => {
            setPreMappingObject(r?.data);
            setPreMappingObjectBasicData(r?.data?.PreMappingBasicData);
            setPreMappingSections(r?.data?.Sections);
            setOnEdit(false);
            setActiveStep(0);
            closeLoader();
        });
    }

    React.useEffect(() => {
        if (!primaryAnalyst) return;
        else preMappingBasicData.Analysts[0] = {
            Name: `${primaryAnalyst?.FirstName} ${primaryAnalyst?.LastName}`,
            Email: primaryAnalyst?.Email,
            TypeID: 1,
            TypeName: "Primary",
            UserID: primaryAnalyst?.CognitoID
        }
        if (secondaryAnalyst === "") preMappingBasicData.Analysts.splice(1, 1);
        else if (!secondaryAnalyst) return;
        else preMappingBasicData.Analysts[1] = {
            Name: `${secondaryAnalyst?.FirstName} ${secondaryAnalyst?.LastName}`,
            Email: secondaryAnalyst?.Email,
            TypeID: 2,
            TypeName: "Secondary",
            UserID: secondaryAnalyst?.CognitoID
        }
    }, [primaryAnalyst, secondaryAnalyst])

    React.useEffect(() => {
        if (onEdit) {
            let stepsListArray = stepsList;
            stepsListArray.push({
                title: 'Summary',
                icon: <ListIcon />
            });
            setStepsList([...stepsListArray]);
        }
    }, [onEdit === true])

    React.useEffect(() => {
        let contentListArray = [...contentList];
        let stepsListArray = [...stepsList];
        if (deletedSection) {
            contentListArray.splice((activeStep > preMappingSections.length ? activeStep - preMappingSections.length : preMappingSections.length - activeStep), 1);
            stepsListArray.splice(activeStep, 1);
            let contentListArrayIndex = contentListArray.map(s => s.Index);
            contentListArrayIndex.length = 0;
            for (var i = 0; i <= contentListArray.length - 1; i++) {
                contentListArrayIndex.push(preMappingSections.length + i);
                contentListArray[i].Index = preMappingSections.length + i;
            }
        } else return;
        setStepsList(stepsListArray);
        setContentList(contentListArray);
        setActiveStep(activeStep - 1);
        setDeletedSection(false);
    }, [deletedSection])

    const handlePreMappingSubmit = () => {
        openLoader();

        setPreMapSubmitted(true);
        onPreMapSubmitted(true);
        if (primaryAnalyst === "") return;
        const submitObject = {
            GUID: preMappingObject.GUID,
            ConnectivityRequestID: preMappingObject.ConnectivityRequestID,
            SubmissionDate: preMappingObject.SubmissionDate,
            PreMappingBasicData: preMappingBasicData,
            Sections: [...preMappingSections, ...contentList]
        }

        APIs.submitPreMappingFormAnalyst(submitObject).then((r) => (
            window.location.reload()
        ));
    }

    return (
        <div id="premapping-form">
            {onEdit && (
                <Paper elevation={0} className="p-5 mb-3">
                    <div className="row alignvh-center">
                        <Button onClick={() => setAddSectionClicked(true)} variant="contained" className="blue-btn mr-5">
                            <label style={{ cursor: "pointer" }} className="mb-0">Add New Section</label>
                        </Button>
                        {addSectionClicked === true && (
                            <>
                                <FormControl label="Select section" focused={false} variant="outlined" style={{ minWidth: "250px" }}>
                                    <Select
                                        className="text-dropdown"
                                        value={section ?? ""}
                                        onChange={handleSectionChange}
                                    >
                                        {sections.map((s, si) => (
                                            <MenuItem key={`sections-list-${si}`} value={s.Name}>{s.Name}</MenuItem>
                                        ))}
                                        <MenuItem value="Not Listed...">Not Listed...</MenuItem>
                                    </Select>
                                </FormControl>
                                {section === "Not Listed..." && (
                                    <div className="row alignvh-center ml-5" style={{ whiteSpace: "nowrap" }}>
                                        <h6 className="form-title">Section Name</h6>
                                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                                        <input type="text" className="text-input" style={{ minWidth: "300px" }} onChange={handleCustomizedSectionChange} value={customizedSection ?? ""} />
                                    </div>
                                )}
                                <Button disabled={!section || (section === "Not Listed..." && customizedSection === "")} onClick={handleAddSection} variant="contained" className="blue-btn ml-5">
                                    <label style={{ cursor: "pointer" }} className="mb-0">Add</label>
                                </Button>
                                <Button onClick={handleCancelAddSection} variant="contained" className="blue-outlined-btn ml-2">
                                    <label style={{ cursor: "pointer" }} className="mb-0">Cancel</label>
                                </Button>
                            </>
                        )}
                    </div>
                </Paper>
            )}

            <Paper elevation={0} className="p-5 mb-3">
                <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
                    {stepsList?.map((s, si) => (
                        <Step className={activeStep === si ? "active" : ""} key={`steps-list-${si}`}>
                            <StepLabel icon={s.icon}>{s.title}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className="mt-5">
                    <div>
                        {preMappingSections?.map((c, ci) =>
                            activeStep === ci && (
                                c.Type?.toLowerCase() === "carrierplan" ? <CarrierPlansAnalystView onEdit={onEdit} currentTimestamp={currentTimestamp} key={`carrier-plan-view-${ci}`} carrierPlansSection={c} setIsNextActive={setIsNextActive} setIsSaveActive={setIsSaveActive} />
                                    : c.Type?.toLowerCase() === "relationshipcode" ? <RelationshipCodesAnalystView onEdit={onEdit} currentTimestamp={currentTimestamp} key={`relationship-codes-view-${ci}`} relationshipCodesSection={c} setIsNextActive={setIsNextActive} setIsSaveActive={setIsSaveActive} />
                                        : <GenericNewSectionAnalystView onEdit={onEdit} currentTimestamp={currentTimestamp} key={`generic-view-${ci}`} genericSection={c} setIsNextActive={setIsNextActive} setIsSaveActive={setIsSaveActive} />)
                        )}
                    </div>
                    <div>
                        {contentList?.map((l, li) => (
                            activeStep === preMappingSections.length + li && (
                                <GenericNewSectionAnalyst onEdit={onEdit} key={`generic-new-section-analyst-${li}`} genericSection={l} currentTimestamp={currentTimestamp} setIsNextActive={setIsNextActive} setOnDeletedSection={setDeletedSection} setIsSaveActive={setIsSaveActive} setIsNewSectionAdded={setIsNewSectionAdded} />
                            )
                        ))}
                        {onEdit && activeStep === (contentList.length + preMappingSections.length) && (
                            <SummaryAnalystView contentList={contentList} preMappingSections={preMappingSections} onEdit={onEdit} setActiveStep={setActiveStep} />
                        )}
                    </div>
                    <div style={{ textAlign: "end" }} className="mt-5">
                        <Button disabled={activeStep === 0 || (onEdit && !isNextActive)} className="blue-outlined-btn mr-2" onClick={handleBack}>Back</Button>
                        <Button disabled={
                            onEdit ? !isNextActive : (activeStep === stepsList?.length - 1)} className="blue-btn mr-2" onClick={
                                onEdit ? activeStep === (preMappingSections.length + contentList.length) ? handlePreMappingSubmit : handleNext : handleNext}>
                            {onEdit ? activeStep === (preMappingSections.length + contentList.length) ? 'Submit' : 'Next' : "Next"}
                        </Button>
                        {/* {onEdit && (<Button className="pink-outlined-btn" onClick={handleCancelEdit}>Cancel</Button>)} */}
                        {onEdit && (
                            <>
                                {activeStep !== (preMappingSections.length + contentList.length) &&
                                    <Button disabled={!isSaveActive || isNewSectionAdded} className="blue-btn mr-2" onClick={handlePreMappingSubmit}>
                                        Save
                                    </Button>
                                }
                                <Button className="blue-outlined-btn" onClick={handleCancelEdit}>Cancel</Button>

                            </>
                        )}


                    </div>
                </div>
            </Paper>
        </div >
    )
}

export default PreMappingStepperAnalystView;