import React, { useState } from "react";
import logoSVG from "../../Images/imageLogo.svg";
import LoginImageSVG from "../../Images/loginImage.svg";
import LoginSignInImageSVG from "../../Images/loginSignInImage.svg";
import { FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, Link, Paper } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import LockIcon from '@material-ui/icons/Lock';
import { useLoader } from "../Layout/Loader";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import goToIcon from "../../Icons/right-to-bracket.svg";

function Login({ onCognitoUser, username, setUsername, password, setPassword, error, setError, ...props }) {

    const [showPassword, setShowPassword] = React.useState(false);

    const [loginClicked, setLoginClicked] = React.useState(false);

    const location = useLocation();

    const { openLoader, closeLoader } = useLoader();
    const routeHistory = useHistory();


    // React.useEffect(() => {
    //     if (location?.search) {
    //         const params = new URLSearchParams(location.search);
    //         const authenticatedUsername = params.get("user");
    //         if (authenticatedUsername && authenticatedUsername !== "") {
    //             Auth.signIn(authenticatedUsername).then(r => {
    //                 if (r.challengeName === "CUSTOM_CHALLENGE") {
    //                     Auth.sendCustomChallengeAnswer(r, "test").then(r => {
    //                         params.delete("user");
    //                         const paramsStr = params.toString();
    //                         if (paramsStr && paramsStr !== "")
    //                             window.location.href = `${location.pathname}?${paramsStr}`;
    //                         else window.location.href = location.pathname;
    //                     });
    //                 }
    //             });
    //         }
    //     }
    // }, [location?.search])


    const handleClickShowPassword = () => {
        setPassword(password);
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    React.useEffect(() => {
        if (loginClicked === true && error === "")
            openLoader();
        else closeLoader();
    }, [loginClicked, error])

    const signInBtn = React.useRef();

    React.useEffect(() => {
        signInBtn.current.focus();
        setError("");
    }, [])

    return (
        <div id="login">
            <div className="row">
                <div className="col-1 login-first-corner-div"></div>
                <div className="col-10 login-div"></div>
                <div className="col-1 login-last-corner-div"></div>
            </div>
            <div className="login-content row">
                <div className="col-6" style={{ display: "inline-flex", justifyContent: "center" }}>
                    <svg viewBox="0 0 73 14" width="60%">
                        <use xlinkHref={`${logoSVG}#logoSVG`}></use>
                    </svg>
                </div>
                <div className="col-6">
                    <div elevation={0} style={{ height: "100%", padding: "30px 100px" }}>
                        {/* <ExitToAppIcon style={{ fontSize: "70px", color: "var(--pink-primary-color)" }} /> */}
                        <svg width="25" height="21" viewBox="0 0 25 21" style={{ color: "var(--pink-primary-color)" }}>
                                                <use xlinkHref={`${goToIcon}#right-to-bracket`}></use>
                                            </svg>
                        <h4 className="mt-3 black1 color fw-700">SIGN IN</h4>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            Auth.signIn(username, password)
                                .then((r) => {
                                    onCognitoUser(r);
                                    if (r?.challengeName && r?.challengeName === "NEW_PASSWORD_REQUIRED") {
                                        routeHistory.push("/ChangePassword");
                                        closeLoader();
                                    }
                                    else if (r?.challengeName && r?.challengeName === "SMS_MFA") {
                                        r.username = r.challengeParam.USER_ID_FOR_SRP;
                                        routeHistory.push("/MFAAuthentication");
                                        closeLoader();
                                    }
                                    else if (r?.challengeName && r?.challengeName === "SOFTWARE_TOKEN_MFA") {
                                        routeHistory.push("/TOTP");
                                        closeLoader();
                                    }
                                    else window.location.reload();
                                })
                                .catch((error) => {
                                    if (!password && !username) setError("Username and Password cannot be empty");
                                    else if (!password) setError("Password cannot be empty");
                                    else setError(!error.message ? error : error.message);
                                });
                            setLoginClicked(true);
                            setError("");
                        }}>
                            <TextField className="mt-4" fullWidth required id="standard-basic" label="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                            <FormControl className="mt-4" fullWidth required>
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{ position: "relative" }}
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end" style={{ zIndex: "100", position: "absolute", width: "100%", justifyContent: "flex-end" }}>
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <div className="mt-4 row alignvh-center w-100">
                                {/* <FormControlLabel
                                    className="mb-0"
                                    value="Remember Password"
                                    control={<Checkbox color="primary" />}
                                    label="Remember Password"
                                    labelPlacement="end"
                                /> */}
                                <Link className="fw-500" color="inherit" style={{ cursor: "pointer" }} onClick={(e) => {
                                    routeHistory.push("/ResetPassword");
                                }}>Forgot password?</Link>
                            </div>
                            {error && error !== "" && (
                                <div style={{ color: "red", textAlign: "left", fontSize: "14px" }}>
                                    <label>Error: <span>{error}</span></label>
                                </div>
                            )}
                            <Button ref={signInBtn} type="submit" variant="contained" className="signin-btn">Sign In</Button>
                        </form>
                        <div className="mt-3">
                            <label className="mt-3 grey2 color"><LockIcon className="mr-2" />Your Info is safely secured</label>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Login;