import React from "react";
import { Divider, Paper, Button, Zoom } from "@material-ui/core";
import * as APIs from "../../../../Data/APIs";
import Moment from 'moment';
import { useLoader } from "../../../Layout/Loader";
import AnalystSingleTransmissionFilter from "./AnalystSingleTransmissionFilter";
import AnalystSingleFeedTransmissionRow from "./AnalystSingleFeedTransmissionRow";
import { useAuthentication } from "../../../../Data/Authentication";
import Pagination from "../../../Layout/Pagination";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Truncate from "react-truncate";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { SingleFeedTransmissionNew, withCustomProps } from "../../../Transmissions/SingleFeedTransmissionNew";
import SingleEmployeeFormTransmissionNew from "../../../TransmissionsForms/SingleEmployeeFormTransmissionNew";
import GenericTable from "../../../Layout/GenericTable";
import { FailureModal } from "../../../Modals/FailureModal";
import SingleFeedTransmissionsPlans from "../../../Transmissions/SingleFeedTransmissionsPlans";
import downloadIconSVG from "../../../../Icons/downloadIcon.svg";
import fileNameSVG from "../../../../Icons/fileName.svg";
import schedulerIconSVG from "../../../../Icons/scheduler.svg";
import viewPlansSVG from "../../../../Icons/viewPlans.svg";
import CopyToClipboardButton from "../../../Layout/CopyToClipboardButton";
import GppGoodIcon from '@mui/icons-material/GppGood';
import verifyCarrierSVG from "../../../../Icons/shield-check.svg";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { ConfirmModal } from "../../../Modals/ConfirmModal";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0);
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function AnalystSingleFeedTransmission({ feedID, carrierID, feed, ...props }) {

    const { authUser } = useAuthentication();
    const dialog = useModalDialog(0);
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [singleFeedTransmissionsList, setSingleFeedTransmissionsList] = React.useState([]);
    const [feedsTransmissionsResponse, setFeedsTransmissionsResponse] = React.useState();

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);

    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [fromDateFilter, setFromDateFilter] = React.useState(sixMonthDate);
    const [toDateFilter, setToDateFilter] = React.useState(todayDate);
    const [sentFromDateFilter, setSentFromDateFilter] = React.useState(null);
    const [sentToDateFilter, setSentToDateFilter] = React.useState(null);
    const [feedTypeFilter, setFeedTypeFilter] = React.useState("");
    const [feedStatusFilter, setFeedStatusFilter] = React.useState("");
    const [feedPhaseFilter, setFeedPhaseFilter] = React.useState("");
    const [executionStatusFilter, setExecutionStatusFilter] = React.useState("");
    const [executionIDFilter, setExecutionIDFilter] = React.useState("");
    const [processingStepFilter, setProcessingStepFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("DESC");
    const [refreshClicked, setRefreshClicked] = React.useState(false);

    const [feedsFilterObject, setFeedsFilterObject] = React.useState();

    //Advanced Filters
    const [selectedFeedStatusAdvFilter, setSelectedFeedStatusAdvFilter] = React.useState([]);
    const [selectedFeedPhaseAdvFilter, setSelectedFeedPhaseAdvFilter] = React.useState([]);
    const [executionIDAdvFilter, setExecutionIDAdvFilter] = React.useState("");
    const [selectedExecutionStatusAdvFilter, setSelectedExecutionStatusAdvFilter] = React.useState([]);
    const [selectedProcessingStepAdvFilter, setSelectedProcessingStepAdvFilter] = React.useState([]);
    const [requestFromDateAdvFilter, setRequestFromDateAdvFilter] = React.useState(todayDate);
    const [requestToDateAdvFilter, setRequestToDateAdvFilter] = React.useState(todayDate);
    const [sentFromDateAdvFilter, setSentFromDateAdvFilter] = React.useState(null);
    const [sentToDateAdvFilter, setSentToDateAdvFilter] = React.useState(null);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("DESC");

    const [expandedRows, setExpandedRows] = React.useState({});

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const getDataQuickFilters = () => {
        openLoader();
        if (!feedID || feedID === undefined || !carrierID || carrierID === undefined) return;
        else {
            const singleFeedTransmissions = {};
            singleFeedTransmissions.feedID = [`${feedID}`];
            singleFeedTransmissions.carrierID = [`${carrierID}`];
            if (selectedPage >= 1) {
                singleFeedTransmissions.page = selectedPage;
                singleFeedTransmissions.limit = pageSize;
            }
            if (pageSize >= 10) {
                singleFeedTransmissions.limit = pageSize;
                singleFeedTransmissions.page = selectedPage;
            }
            if (fromDateFilter)
                singleFeedTransmissions.fromDate = Moment(fromDateFilter).format("YYYY-MM-DD");
            if (toDateFilter)
                singleFeedTransmissions.toDate = Moment(toDateFilter).format("YYYY-MM-DD");
            if (sentFromDateFilter)
                singleFeedTransmissions.sentFromDate = Moment(sentFromDateFilter).format("YYYY-MM-DD");
            if (sentToDateFilter)
                singleFeedTransmissions.sentToDate = Moment(sentToDateFilter).format("YYYY-MM-DD");
            if (executionStatusFilter !== "")
                singleFeedTransmissions.executionStatus = [`${executionStatusFilter}`];
            if (processingStepFilter !== "")
                singleFeedTransmissions.processingStep = [`${processingStepFilter}`];
            if (feedTypeFilter !== "")
                singleFeedTransmissions.feedTypeID = [`${feedTypeFilter}`];
            if (feedStatusFilter !== "")
                singleFeedTransmissions.feedStatusID = [`${feedStatusFilter}`];
            if (feedPhaseFilter !== "")
                singleFeedTransmissions.feedPhaseID = [`${feedPhaseFilter}`];
            if (executionIDFilter !== "")
                singleFeedTransmissions.executionID = executionIDFilter;
            if (sortFieldFilter !== "")
                singleFeedTransmissions.sortType = sortFieldFilter;
            if (sortOrderFilter !== "")
                singleFeedTransmissions.sortOrder = sortOrderFilter;

            setFeedsFilterObject(singleFeedTransmissions);

            APIs.getFeedsTranmissionsNEW(singleFeedTransmissions).then((r) => {
                setSingleFeedTransmissionsList(r?.data);
                setRefreshClicked(false);
                setTotalCount(r?.totalCount ?? 0);
                setFeedsTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
                closeLoader();
            });
        }
    };

    const getDataAdvancedFilters = () => {
        openLoader();
        if (!feedID || feedID === undefined || !carrierID || carrierID === undefined) return;
        else {
            const singleFeedTransmissions = {};
            singleFeedTransmissions.feedID = [`${feedID}`];
            singleFeedTransmissions.carrierID = [`${carrierID}`];
            if (selectedPageAdv >= 1) {
                singleFeedTransmissions.page = selectedPageAdv;
                singleFeedTransmissions.limit = pageSizeAdv;
            }
            if (pageSizeAdv >= 10) {
                singleFeedTransmissions.limit = pageSizeAdv;
                singleFeedTransmissions.page = selectedPageAdv;
            }
            if (requestFromDateAdvFilter)
                singleFeedTransmissions.fromDate = Moment(requestFromDateAdvFilter).format("YYYY-MM-DD");
            if (requestToDateAdvFilter)
                singleFeedTransmissions.toDate = Moment(requestToDateAdvFilter).format("YYYY-MM-DD");
            if (sentFromDateAdvFilter)
                singleFeedTransmissions.sentFromDate = Moment(sentFromDateAdvFilter).format("YYYY-MM-DD");
            if (sentToDateAdvFilter)
                singleFeedTransmissions.sentToDate = Moment(sentToDateAdvFilter).format("YYYY-MM-DD");
            if (selectedExecutionStatusAdvFilter.length > 0)
                singleFeedTransmissions.executionStatus = selectedExecutionStatusAdvFilter?.map(s => `${s?.Id}`);
            if (selectedProcessingStepAdvFilter.length > 0)
                singleFeedTransmissions.processingStep = selectedProcessingStepAdvFilter?.map(s => `${s?.Id}`);
            if (selectedFeedStatusAdvFilter.length > 0)
                singleFeedTransmissions.feedStatusID = selectedFeedStatusAdvFilter?.map(s => `${s?.FeedStatusID}`);
            if (selectedFeedPhaseAdvFilter.length > 0)
                singleFeedTransmissions.feedPhaseID = selectedFeedPhaseAdvFilter?.map(s => `${s?.FeedPhaseID}`);
            if (executionIDAdvFilter !== "")
                singleFeedTransmissions.executionID = executionIDAdvFilter;
            if (sortFieldAdvFilter !== "")
                singleFeedTransmissions.sortType = sortFieldAdvFilter;
            if (sortOrderAdvFilter !== "")
                singleFeedTransmissions.sortOrder = sortOrderAdvFilter;

            setFeedsFilterObject(singleFeedTransmissions);

            APIs.getFeedsTranmissionsNEW(singleFeedTransmissions).then((r) => {
                setSingleFeedTransmissionsList(r?.data);
                setRefreshClicked(false);
                setTotalCount(r?.totalCount ?? 0);
                setAdvancedFiltersAppliedClicked(false);
                closeLoader();
                setFeedsTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
            });
        }
    };

    React.useEffect(() => {
        if (quickFiltersClicked) getDataQuickFilters();
    }, [selectedPage, pageSize, fromDateFilter, toDateFilter, sentFromDateFilter, sentToDateFilter, executionStatusFilter, feedStatusFilter, feedPhaseFilter, feedTypeFilter, executionIDFilter, processingStepFilter, sortFieldFilter, sortOrderFilter, refreshClicked === true, feedID])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])

    React.useEffect(() => {
        getDataQuickFilters();
    }, [quickFiltersClicked === true])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [advancedFiltersClicked === true])

    // ---------------------------------------------------------------------NEW TABLE UI
    const handleSort = (column, sortDirection, type) => {
        if (type === "quick") {
            setSortFieldFilter(column?.sortingkey);
            setSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            setSortFieldAdvFilter(column?.sortingkey);
            setSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const handleRowExpandToggle = async (row) => {
        if (expandedRows[row.id]?.isExpanded) {
            setExpandedRows((prev) => ({ ...prev, [row.SK]: { ...prev[row.SK], isExpanded: false } }));
        } else {
            setExpandedRows((prev) => ({
                ...prev,
                [row.SK]: { isExpanded: !prev[row.SK]?.isExpanded }
            }));
        }
    };

    const expandableRowExpanded = (row) => row.isExpanded || false;

    const ExpandableRowsComponentWithProps = withCustomProps(SingleFeedTransmissionNew, {
        refreshClicked: refreshClicked,
        setRefreshClicked: setRefreshClicked,
    });

    const getExactFileNameHandle = (feedTransmission) => {
        openLoader()
        APIs.getExactFileName(feedTransmission?.SK)
            .then(r => {
                if (r?.success === "1") {
                    dialog.openDialog(
                        <div>
                            <h5 className="black1 fw-700">File name</h5>
                            {/* <Divider /> */}
                        </div>,
                        <>
                            <div >{r?.fileName}</div>
                            <div className="row" style={{ justifyContent: "flex-end" }}><CopyToClipboardButton textToCopy={r?.fileName} /></div>
                        </>

                    )
                }
                else {
                    FailureModal(dialog, {
                        title: 'Failure!',
                        body: 'Failed Retrieving File Name'
                    });
                }
            })
            .catch(err => console.log(err))
            .finally(() => closeLoader())
    }

    const sortActualPlans = (plans) => {
        plans.sort((a, b) => {
            if (a.isExported && !b.isExported) {
                return -1; // a should come before b
            } else if (!a.isExported && b.isExported) {
                return 1; // b should come before a
            } else {
                return 0; // the order of a and b doesn't matter
            }
        });

        return plans
    }

    const getActualPlansHandle = (feedTransmission) => {
        openLoader()
        APIs.getActualPlans(feedTransmission?.SK)
            .then(r => {
                dialog.openDialog(
                    <div>
                        <h5 className="black1 fw-700 text-left mb-0">Actual Plans</h5>
                    </div>,
                    <>
                        <SingleFeedTransmissionsPlans plans={r?.length > 0 ? sortActualPlans(r) : []} />
                    </>

                )


            })
            .catch(err => FailureModal(dialog, {
                title: 'Failure!',
                body: err
            }))
            .finally(() => closeLoader())
    }

    const handleAcceptFile = (FT) => {
        const body = {
            executionID: FT?.SK,
            markasfinished: "Yes"
        }
        openModal(0, () => handleAcceptFileAPI(body), {
            title: "Please Confirm",
            question: `Are you sure you want to accept the file ?`,
        });
    }

    const handleAcceptFileAPI = (body) => {
        openLoader()
        APIs.markAsFinished(body).then(r => (
            setRefreshClicked(true)
        )).catch(err => FailureModal(dialog, {
            title: 'Failure!',
            body: err
        })).finally(() => closeLoader());
    }

    const baseColumns = [
        // Feed ID
        // ================Difference here
        {
            name: 'Feed Status',
            sortable: true,
            sortingkey: 'FeedStatusName',
            id: 'FeedStatusName',
            selector: feedTransmission => feedTransmission?.FeedStatus
        },
        {
            name: 'Feed Phase',
            sortable: true,
            sortingkey: 'FeedPhaseName',
            id: 'FeedPhaseName',
            selector: feedTransmission => feedTransmission?.FeedPhase
        },
        // Request Timestamp
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"Date and Time of when the feed started processing or reprocessing"}>
                <span>
                    <div>
                        Request Timestamp
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            sortingkey: 'requestTimestamp',
            id: 'requestTimestamp',
            selector: feedTransmission => Moment(feedTransmission?.RequestDate).format("MM/DD/YYYY HH:mm:ss")
        },
        // Sent Timestamp
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"Date and Time of when the file feed was transmitted"}>
                <span>
                    <div>
                        Sent Timestamp
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            sortingkey: 'SentTimeStamp',
            id: 'SentTimeStamp',
            selector: feedTransmission =>
                <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom} title={feedTransmission?.SentDate === "N/A" ? "File is not transmitted yet" : ""}>
                    <span>
                        {`${feedTransmission?.SentDate === "N/A" ? "N/A" : Moment(feedTransmission?.SentDate).format("MM/DD/YYYY HH:mm:ss")}`}
                    </span>
                </CustomTooltip>
        },
        //  Processing Step
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"The step in which the feed is currently processing"}>
                <span>
                    <div>
                        Processing Step
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            sortingkey: 'ProcessingStepID',
            id: 'ProcessingStepID',
            selector: feedTransmission => feedTransmission.ProcessingStep
        },
        //  Processing Log
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"The status of the Processing Step"}>
                <span>
                    <div>
                        Processing Log
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            width: '100px',
            sortingkey: 'ProcessingLog',
            id: 'ProcessingLog',
            selector: feedTransmission => <div className="w-100" style={{ wordBreak: "break-all" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                <Truncate lines={4} ellipsis={<span>... <button className="btn-like-link" style={{ color: "#2c87f0" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog("Processing Logs:",<div><p style={{ wordBreak: "break-word" }}>{feedTransmission?.ProcessingLog}</p></div>,[]) }}>Read More</button></span>}>
                    {feedTransmission?.ProcessingLog}
                </Truncate>
            </div>
        },
        // Transmission Status
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"Identifies if the feed processing was completed, failed, stopped, or pending"}>
                <span>
                    <div>
                        Transmission Status
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            sortingkey: 'tes.Name',
            id: 'tes.Name',
            selector: feedTransmission => <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom}
                title={(feedTransmission?.ExecutionStatus)?.includes("Progress") ? "The feed is still processing" :
                    (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "Current processing step is completed" :
                        (feedTransmission?.ExecutionStatus)?.includes("Parsing") ? "Failed to convert the data file to the required format" :
                            (feedTransmission?.ExecutionStatus)?.includes("Tracking") ? "Failed to process the data" :
                                (feedTransmission?.ExecutionStatus)?.includes("Mapping") ? "There is an error in the output file" :
                                    (feedTransmission?.ExecutionStatus)?.includes("Serializing") ? "Unable to successfully generate a file" :
                                        (feedTransmission?.ExecutionStatus)?.includes("Encrypting") ? "Failed to encrypt the output file" :
                                            (feedTransmission?.ExecutionStatus)?.includes("Failed Transmitting") ? "Failed to submit the file" :
                                                (feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "File needs to be manually submitted" :
                                                    (feedTransmission?.ExecutionStatus)?.includes("Pending") ? "The feed didn't start processing yet" :
                                                        (feedTransmission?.ExecutionStatus)?.includes("File Pulling Failed") ? "Failed to extract a data file" :
                                                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "There are no changes on the output file" :
                                                                ""}>
                <span>
                    <div style={(feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                        className={`
            ${(feedTransmission?.ExecutionStatus).includes("Fail") ? "red background status-div" :
                                (feedTransmission?.ExecutionStatus).includes("Pending Transmission") ? "yellow-secondary-color background status-div" :
                                    (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "green background status-div" :
                                        (feedTransmission?.ExecutionStatus)?.includes("In Progress") ? "purple1 background status-div" :
                                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "lightgreen background status-div" :
                                                ""}`}
                    >
                        {feedTransmission?.ExecutionStatus}
                    </div>
                </span>
            </CustomTooltip>
        },
        // Actions
        {
            name: "Actions",
            sortable: false,
            id: 'Actions',
            width: '200px',
            selector: feedTransmission => <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>

                <CustomTooltip TransitionComponent={Zoom}
                    title={feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" ? `No readable file available for this step` :
                        (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") ? `No readable file available for failed mapping transmission` :
                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "No readable file available for empty file" :
                                `Download Readable File`}>
                    <button
                        className="btn-like-link"
                        style={{ cursor: feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" || (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") || (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "default" : "", textDecoration: feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" ? "none" : "" }} onClick={() => {
                            if (feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" || (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") || (feedTransmission?.ExecutionStatus)?.includes("Finished"))
                                return;
                            else APIs.getDownloadReadableExcel(feedTransmission?.SK).then(r => window.open(r?.url));
                        }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" className="mt-2">
                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                        </svg>
                    </button>
                </CustomTooltip>

                {/* File name show only when transmission is completed */}
                {feedTransmission?.ProcessingLog === "Transmission Completed" && (
                    <CustomTooltip TransitionComponent={Zoom} title="File Name">
                        <button className="btn-like-link" onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            getExactFileNameHandle(feedTransmission);


                        }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" >
                                <use xlinkHref={`${fileNameSVG}#fileNameIcon`}></use>
                            </svg>
                        </button>
                    </CustomTooltip>
                )}

                {feedTransmission?.Schedules && (
                    <CustomTooltip TransitionComponent={Zoom} title="Scheduler">
                        <button
                            className="btn-like-link"
                            onClick={(e) => {
                                e.stopPropagation(); e.preventDefault(); dialog.openDialog(
                                    <div>
                                        <h5 className="black1 fw-700">Scheduler</h5>
                                        {/* <Divider /> */}
                                    </div>,
                                    <>
                                        <div>{feedTransmission?.Schedules?.split('\n').map(s => <p>{s}</p>)}</div>
                                        <div className="row" style={{ justifyContent: "flex-end" }}><CopyToClipboardButton textToCopy={feedTransmission?.Schedules} /></div>
                                    </>

                                )

                            }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" >
                                <use xlinkHref={`${schedulerIconSVG}#schedulerIcon`}></use>
                            </svg>
                        </button>

                    </CustomTooltip>
                )}



                {/* mapper or above && @mapper case (it should have a file) */}
                {((feedTransmission?.ProcessingStep === "Mapper" &&
                    (feedTransmission?.ProcessingLog == "Invalid Output File" || feedTransmission?.ProcessingLog == "Mapping Completed")
                ) ||
                    feedTransmission?.ProcessingStep === "Serializer" ||
                    feedTransmission?.ProcessingStep === "Encrypter" ||
                    feedTransmission?.ProcessingStep === "Transmitter") &&
                    <CustomTooltip TransitionComponent={Zoom} title="View Plans">
                        <button
                            className="btn-like-link"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                getActualPlansHandle(feedTransmission);
                            }}>
                            <svg width="14" height="14" viewBox="0 0 14 14" >
                                <use xlinkHref={`${viewPlansSVG}#viewPlansIcon`}></use>
                            </svg>
                        </button>
                    </CustomTooltip>

                }
                    {(feedTransmission?.ProcessingStep === "Mapper" && feedTransmission?.ProcessingLog == "Invalid Output File") &&
                        feed?.DataType == "Changes Only File" && feed?.FeedStatusName == "Production" &&
                        <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title="Accept">
                             <button
                            className="btn-like-link" onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleAcceptFile(feedTransmission);
                            }}>
                                <svg width="15" height="20" viewBox="0 0 24 24" className="mr-2" >
                                    <use xlinkHref={`${verifyCarrierSVG}#shield-check`}></use>
                                </svg>
                                </button>
                        </CustomTooltip>
                    }

            </div>
        },
        // ===============================
        // Actions
    ]


    const pushToColumnsBasedOnUserAction = () => {
        const columnsToAdd = [
            {
                name: 'Execution ID',
                sortable: true,
                sortingkey: 'ExecutionID',
                id: 'ExecutionID',
                selector: feedTransmission => feedTransmission?.SK,
            },

        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumns];

        if (userActions?.includes("43") || userActions?.includes("20")) {
            finalColumnsArray.unshift(...columnsToAdd);
        }

        return finalColumnsArray;
    };





    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(singleFeedTransmissionsList)) {
            return [];
        }

        return singleFeedTransmissionsList
            .filter((obj, index) =>
                singleFeedTransmissionsList.findIndex((item) => item.FeedID === obj.FeedID && item.SK === obj.SK) === index
            )
    }

    return (
        <div id="transmission-dashboard-table">
            <Paper className="content-card-paper">
                <AnalystSingleTransmissionFilter
                    onExecutionStatusFilter={(d) => setExecutionStatusFilter(d)}
                    onProcessingStepFilter={(d) => setProcessingStepFilter(d)}
                    onFeedTypeFilter={(d) => setFeedTypeFilter(d)}
                    onFeedStatusFilter={(d) => setFeedStatusFilter(d)}
                    onFeedPhaseFilter={(d) => setFeedPhaseFilter(d)}
                    onExecutionIDFilter={(d) => setExecutionIDFilter(d)}
                    onFromDateFilter={(d) => setFromDateFilter(d)}
                    fromDateFilter={fromDateFilter}
                    onToDateFilter={(d) => setToDateFilter(d)}
                    toDateFilter={toDateFilter}
                    onSentFromDateFilter={(d) => setSentFromDateFilter(d)}
                    sentFromDateFilter={sentFromDateFilter}
                    onSentToDateFilter={(d) => setSentToDateFilter(d)}
                    sentToDateFilter={sentToDateFilter}
                    setRefreshClicked={setRefreshClicked}
                    selectedPage={selectedPage}
                    onSelectedPage={(p) => setSelectedPage(p)}
                    pageSize={pageSize}
                    onPageSize={(p) => setPageSize(p)}
                    totalCount={totalCount}
                    setTotalCount={setTotalCount}
                    selectedPageAdv={selectedPageAdv}
                    onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                    pageSizeAdv={pageSizeAdv}
                    onPageSizeAdv={(p) => setPageSizeAdv(p)}
                    quickFiltersClicked={quickFiltersClicked}
                    setQuickFiltersClicked={setQuickFiltersClicked}
                    advancedFiltersClicked={advancedFiltersClicked}
                    setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                    advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                    setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                    selectedFeedStatusAdvFilter={selectedFeedStatusAdvFilter}
                    onSelectedFeedStatusAdvFilter={s => setSelectedFeedStatusAdvFilter(s)}
                    selectedFeedPhaseAdvFilter={selectedFeedPhaseAdvFilter}
                    onSelectedFeedPhaseAdvFilter={s => setSelectedFeedPhaseAdvFilter(s)}
                    executionIDAdvFilter={executionIDAdvFilter}
                    onExecutionIDAdvFilter={s => setExecutionIDAdvFilter(s)}
                    selectedExecutionStatusAdvFilter={selectedExecutionStatusAdvFilter}
                    onSelectedExecutionStatusAdvFilter={s => setSelectedExecutionStatusAdvFilter(s)}
                    selectedProcessingStepAdvFilter={selectedProcessingStepAdvFilter}
                    onSelectedProcessingStepAdvFilter={s => setSelectedProcessingStepAdvFilter(s)}
                    requestFromDateAdvFilter={requestFromDateAdvFilter}
                    onRequestFromDateAdvFilter={s => setRequestFromDateAdvFilter(s)}
                    requestToDateAdvFilter={requestToDateAdvFilter}
                    onRequestToDateAdvFilter={s => setRequestToDateAdvFilter(s)}
                    sentFromDateAdvFilter={sentFromDateAdvFilter}
                    onSentFromDateAdvFilter={s => setSentFromDateAdvFilter(s)}
                    sentToDateAdvFilter={sentToDateAdvFilter}
                    onSentToDateAdvFilter={s => setSentToDateAdvFilter(s)}
                />

                <GenericTable
                    columns={[...pushToColumnsBasedOnUserAction()]}
                    data={[...FilteredAndAddedIsExpand()]}
                    onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}

                    expandableRows
                    expandableRowsComponent={ExpandableRowsComponentWithProps}
                    expandedRows={expandedRows}
                    handleRowExpandToggle={handleRowExpandToggle}
                    expandableRowExpanded={expandableRowExpanded}
                />

                <div>
                    <Pagination
                        stateLimit={true}
                        pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                        page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                        onChange={(t, v) => {
                            if (quickFiltersClicked) {
                                if (t === 1) setPageSize(v);
                                else if (t === 2) setSelectedPage(v);
                            } else if (advancedFiltersClicked) {
                                if (t === 1) setPageSizeAdv(v);
                                else if (t === 2) setSelectedPageAdv(v);
                            }
                        }}
                        count={totalCount}
                    />
                </div>
            </Paper>
        </div >
    )
}

export default AnalystSingleFeedTransmission;
