import React, { useEffect, useState } from 'react'
import { useLoader } from '../Layout/Loader';
import * as Apis from '../../Data/APIs'

const EmployeeDashboardPendingFormsTabRejectionModal = ({ dialog, form, regetFormsHandler, ...props }) => {
    const { openLoader, closeLoader } = useLoader();

    const [rejectionReason, setRejectionReason] = useState("");
    const [rejectionReasonValidation, setRejectionReasonValidation] = useState("");
    const [submittedClicked, setSubmittedClicked] = useState(false);


    useEffect(() => {
        if (rejectionReason.split(" ").join("").length == 0) {
            setRejectionReasonValidation('this field is required');
        } else setRejectionReasonValidation('');

    }, [rejectionReason])



    const handleRejectionCore = () => {
        setSubmittedClicked(true)
        if (rejectionReason.split(" ").join("").length == 0) return
        openLoader();
        const { empDOB, empKey, empName, empSSN, feedID, executionID, SK, outputFile } = form


        let employeeObiect = {}
        employeeObiect.empDOB = empDOB || ''
        employeeObiect.empKey = empKey || ''
        employeeObiect.empName = empName || ''
        employeeObiect.empSSN = empSSN || ''
        employeeObiect.feedID = feedID || ''
        employeeObiect.executionID = executionID || ''
        employeeObiect.SK = SK || ''
        employeeObiect.outputFile = outputFile || ''
        employeeObiect.rejectionReason = rejectionReason

        let bodyObject = {
            empArray: [{ ...employeeObiect }]
        }
        Apis.rejectHrApprovalEmployees(bodyObject)
            .then(r => {
                regetFormsHandler()
            })
            .catch((error) => {
                console.log('Reject Form Error:', error);
            }).finally((t) => closeLoader());


        // regetFormsHandler()
        dialog.closeDialog()
        setRejectionReason('')
    }


    return (
        <>
            <textarea
                className='action-item-textarea w-100'
                value={rejectionReason}
                onChange={e => {
                    setRejectionReason(e.target.value)
                }}
            />
            {submittedClicked &&
                <div className="mt-2 text-danger fs-14">{rejectionReasonValidation}</div>
            }


            <div className="d-flex mt-3">
                <button className='MuiButtonBase-root MuiButton-root MuiButton-text blue-btn ml-auto mr-2' onClick={() => handleRejectionCore(form)}>Yes</button>
                <button className='MuiButtonBase-root MuiButton-root MuiButton-text blue-outlined-btn' onClick={() => dialog.closeDialog()}>No</button>
            </div>
        </>
    )
}

export default EmployeeDashboardPendingFormsTabRejectionModal
