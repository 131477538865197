import React from "react";
import { Divider, Paper, Select, Switch, TextField, Button } from "@material-ui/core";
import MultipleTabsTitles from "../../Layout/MultipleTabsTitles";
import dateTimeSVG from "../../../Images/dateTime.svg";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import EmptyActivityLogsSVG from "../../../Images/emptyActivityLogs.svg"

import BreadcrumbSteps from "../../Layout/BreadcrumbSteps";
import { useParams } from "react-router-dom";
import { useLoader } from "../../Layout/Loader";
import * as APIs from "../../../Data/APIs";
import Moment from 'moment';
import EditRequestSVG from "../../../Images/editRequest.svg";
import ConnectivityRequestFilesAttachments from "../../FilesAttachments/ConnectivityRequestFilesAttachments";
import ConnectivityRequestFormsAttachments from "../../FormsAttachments/ConnectivityRequestFormsAttachments";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { parseISO } from 'date-fns';
import AnalystConnectivityRequestContacts from "./AnalystConnectivityRequestContacts";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AnalystConnectivityRequestComments from "./AnalystConnectivityRequestComments";
import { useHistory } from "react-router-dom";
import AnalystConnectivityRequestActionItems from "./ActionItems/AnalystConnectivityRequestActionItems";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { FailureModal } from "../../Modals/FailureModal";
import { useAuthentication } from "../../../Data/Authentication";
import AnalystConnectivityRequestOEList from "./OEList/AnalystConnectivityRequestOEList";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AlertModal } from "../../Modals/AlertModal";
import { styled } from '@mui/system';
import ListSubheader from '@mui/material/ListSubheader';
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import infoCircleSVG from "../../../Icons/info-circle.svg";
import editRequestIcon from "../../../Icons/editIcon.svg";
import goToIcon from "../../../Icons/right-to-bracket.svg";
import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export let emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,3}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

function AnalystConnectivityRequestConfig() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const dialog = useModalDialog(0);
    const { connectivityRequestIDURL } = useParams();
    const routeHistory = useHistory();
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [connectivityRequestsStatus, setConnectivityRequestsStatus] = React.useState([]);
    const [connectivityRequestsPhase, setConnectivityRequestsPhase] = React.useState([]);
    const [carrierFeeds, setCarrierFeeds] = React.useState([]);
    const [status, setStatus] = React.useState();
    const [phase, setPhase] = React.useState();
    const { openLoader, closeLoader } = useLoader();
    const [onEdit, setOnEdit] = React.useState(false);
    const [onEditPlans, setOnEditPlans] = React.useState(false);
    const [feed, setFeed] = React.useState();
    const [paramConfig, setParamConfig] = React.useState("");
    const [formsTabValue, setFormsTabValue] = React.useState(0);
    const [filesTabValue, setFilesTabValue] = React.useState(0);
    const [uploadedFilesArray, setUploadedFilesArray] = React.useState([]);
    const [uploadedFormsArray, setUploadedFormsArray] = React.useState([]);
    const [filesList, setFilesList] = React.useState([]);
    const [formsList, setFormsList] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [assignedTo, setAssignedTo] = React.useState("");
    const [planTypesList, setPlanTypesList] = React.useState([]);
    const [selectedPlans, setSelectedPlans] = React.useState([]);
    const [checkedPlan, setCheckedPlan] = React.useState(false);
    const [otherChecked, setOtherChecked] = React.useState(false);
    const [otherPlanTypes, setOtherPlanTypes] = React.useState([]);
    const [selectAllPlansChecked, setSelectAllPlansChecked] = React.useState(false);
    const [selectPlanValidationForm, setSelectPlanValidationForm] = React.useState("");
    const [requestSubmitted, setRequestSubmitted] = React.useState(false);
    const [tabValue, setTabValue] = React.useState(0);
    const [groupReqStatus, setGroupReqStatus] = React.useState("");
    const [responseMsg, setResponseMsg] = React.useState("");
    const [openPMFClicked, setOpenPMFClicked] = React.useState(false);
    const [preMappingObject, setPreMappingObject] = React.useState();
    const [preMappingBasicData, setPreMappingObjectBasicData] = React.useState();
    const [preMappingSections, setPreMappingSections] = React.useState([]);
    const [needEncryptionChecked, setNeedEncryptionChecked] = React.useState(false);
    const [migration, setMigration] = React.useState("");
    const [subType, setSubType] = React.useState("");
    const [formsSubType, setFormsSubType] = React.useState("");
    const [hrEmail, setHrEmail] = React.useState("");
    const [subTypeList, setSubTypeList] = React.useState([]);
    const [formsSubTypeList, setFormsSubTypeList] = React.useState([]);
    // const [selectFormMainTypesValidationForm, setSelectFormMainTypesValidationForm] = React.useState("");
    // const [selectFormSubTypesValidationForm, setSelectFormSubTypesValidationForm] = React.useState("");
    const [groupsAndOEContactsList, setGroupsAndOEContactsList] = React.useState([]);
    const connectivityTabs = ["Connectivity Request Configuration", "Action Items", "OE Requests"];
    const carrierContactTypes = ["Test", "Production/Data Issues"];

    const [migrationStartDate, setMigrationStartDate] = React.useState(null);
    const [plannedFirstProductionDate, setPlannedFirstProductionDate] = React.useState(null);
    const [migrationPhase, setMigrationPhase] = React.useState("");
    const [migrationPhasesList, setMigrationPhasesList] = React.useState([]);

    const [reasonStatus, setReasonStatus] = React.useState("");
    const [dateCancelation, setDateCancelation] = React.useState(null);
    const [clientEffDate, setClientEffDate] = React.useState(null);
    const [clientContact, setClientContact] = React.useState("");
    const [carrierGroupNumber, setCarrierGroupNumber] = React.useState("");
    // validation 
    const [selectMigrationStarDateValidationForm, setSelectMigrationStarDateValidationForm] = React.useState("");
    const [selectPlannedFirstProductionDateValidationForm, setSelectPlannedFirstProductionDateValidationForm] = React.useState("");
    const [selectMigrationPhaseValidationForm, setSelectMigrationPhaseValidationForm] = React.useState("");
    const [selectStatusReasonValidation, setSelectStatusReasonValidation] = React.useState("");
    const [selectMigrationValidationForm, setSelectMigrationValidationForm] = React.useState("");
    const [selectClientContactValidationForm, setSelectClientContactValidationForm] = React.useState("");
    const [selectClientEffectiveDateValidationForm, setSelectClientEffectiveDateValidationForm] = React.useState(null);
    const [selectHREmailValidationForm, setSelectHREmailValidationForm] = React.useState("");
    const [cancellationDateValidationForm, setCancellationDateValidationForm] = React.useState("");
    const [emptyCancellationDateValidationForm, setEmptyCancellationDateValidationForm] = React.useState("");
    const [formsSubTypeValidation, setFormsSubTypeValidation] = React.useState("");


    // to validate if Forms Submission Type Form by Form >> HrSignatureRequired shouldn't be required
    let handleOnFormSubmissionType = () => {
        // formsSubType = 1 ==> (form by form)
        if (formsSubType == 1 && feed) {
            let currentFeed = carrierFeeds?.find((cf) => cf.FeedID == feed);
            if (currentFeed?.HrSignatureRequired == 1) {
                setFormsSubTypeValidation('Feed Requires HR Signature, so the transmission type needs to be kept as Require Admin Approval');
            } else setFormsSubTypeValidation('');
        } else setFormsSubTypeValidation('')
    }

    React.useEffect(() => {
        handleOnFormSubmissionType();
    }, [formsSubTypeValidation, formsSubType, feed])



    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => { setConnectivityReq(r?.data); }).finally(() => closeLoader());
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestEDISubTypes().then((r) => {
            setSubTypeList(r?.data);
        });
    }, [])

    React.useEffect(() => {
        APIs.getConnectivityRequestFormsSubTypes().then((r) => {
            setFormsSubTypeList(r?.data);
        });
    }, [])


    const getGroupStatus = () => {
        openLoader();
        APIs.getGroupRequestData(connectivityReq?.GroupRequestID).then((r) => {
            setGroupReqStatus(r?.data?.GroupRequest?.GroupRequestStatusID);
        });
    }
    React.useEffect(() => {
        getGroupStatus();
    }, [connectivityReq]);

    React.useEffect(() => {
        if (dateCancelation !== null && dateCancelation !== "Invalid date" && !reasonStatus?.replaceAll(" ", "").replaceAll("\n", "")) {
            setSelectStatusReasonValidation("Cancellation reason cannot be empty ");
        } else if ((status === 2 || status === 3 || status === 5) && !reasonStatus?.replaceAll(" ", "").replaceAll("\n", "")) {
            switch (status) {
                case 2:
                    return setSelectStatusReasonValidation("On hold reason cannot be empty ");
                case 3:
                    return setSelectStatusReasonValidation("Block reason cannot be empty ");
                case 5:
                    return setSelectStatusReasonValidation("Cancellation reason cannot be empty ");
                default:
                    return setSelectStatusReasonValidation("");
            }
        }
        else setSelectStatusReasonValidation("");
    }, [status, reasonStatus, dateCancelation, selectStatusReasonValidation]);

    React.useEffect(() => {
        if (status === 5 && dateCancelation === null) setDateCancelation(Moment(todayDate).format('YYYY-MM-DD'));
    }, [status]);

    React.useEffect(() => {
        openLoader();
        APIs.getCarrierFeeds(connectivityReq?.ConnectivityRequestCarrierID).then((r) => (
            setCarrierFeeds(r?.data)
        ));
    }, [connectivityReq?.ConnectivityRequestCarrierID])

    React.useEffect(() => {
        openLoader();
        APIs.getAllGroupsAndOEContacts().then((r) => (
            !(r?.data?.length) ? setGroupsAndOEContactsList([]) : setGroupsAndOEContactsList(r?.data)
        ));
    }, [])
    // get Migration phases List  apis 
    React.useEffect(() => {
        APIs.getMigrationPhases().then(r => !(r?.data?.length) ? setMigrationPhasesList([]) : setMigrationPhasesList(r?.data));

    }, [])

    React.useEffect(() => {
        if (dateCancelation !== null && connectivityReq?.ConnectivityRequestStatusID !== 5) {
            if (status === 5 && (dateCancelation === "Invalid date" || new Date(dateCancelation) < new Date(todayDate))) {
                setEmptyCancellationDateValidationForm("Cancellation Date can't be empty or invalid");
                setCancellationDateValidationForm("");

            } else if (status === 5 && dateCancelation !== Moment(todayDate).format('YYYY-MM-DD') && dateCancelation !== "Invalid date") {
                setCancellationDateValidationForm("Connectivity Request can't be set as Cancelled if it has a future cancellation date, please update the status or change the cancellation date to today's date.");
                setEmptyCancellationDateValidationForm("");
            } else if (status !== 5 && (dateCancelation < Moment(todayDate).format('YYYY-MM-DD'))) {
                setEmptyCancellationDateValidationForm("Cancellation Date can't be invalid");
                setCancellationDateValidationForm("");
            } else {
                setCancellationDateValidationForm("");
                setEmptyCancellationDateValidationForm("");

            }
        } else if (status === 5 && dateCancelation === null) {
            setEmptyCancellationDateValidationForm("Cancellation Date can't be empty or invalid");
            setCancellationDateValidationForm("");
        } else if (status === 5 && ((connectivityReq?.ConnectivityRequestStatusID !== 5 && (dateCancelation === "Invalid date" || new Date(dateCancelation) < new Date(todayDate))) || (connectivityReq?.ConnectivityRequestStatusID === 5 && dateCancelation === "Invalid date"))) {
            setEmptyCancellationDateValidationForm("Cancellation Date can't be empty or invalid");
            setCancellationDateValidationForm("");
        } else if (status !== 5 && connectivityReq?.ConnectivityRequestStatusID === 5 && (dateCancelation === "Invalid date" || new Date(dateCancelation) < new Date(todayDate))) {
            setEmptyCancellationDateValidationForm("Cancellation Date can't be empty or invalid, please change the cancellation date to today's date or later");
            setCancellationDateValidationForm("");
        } else if (status === 5 && dateCancelation !== null && Moment(dateCancelation).format('YYYY-MM-DD') > Moment(todayDate).format('YYYY-MM-DD')) {
            setCancellationDateValidationForm("Connectivity Request can't be set as Cancelled if it has a future cancellation date, please update the status or change the cancellation date to today's date.");
            setEmptyCancellationDateValidationForm("");
        } else {
            setCancellationDateValidationForm("");
            setEmptyCancellationDateValidationForm("");

        }
    }, [dateCancelation, status, emptyCancellationDateValidationForm, cancellationDateValidationForm]);

    React.useEffect(() => {
        if ((!migrationStartDate || migrationStartDate === "Invalid date") && onEdit === true) setSelectMigrationStarDateValidationForm("Migration start date  cannot be empty"); else setSelectMigrationStarDateValidationForm("");
    }, [migrationStartDate, selectMigrationStarDateValidationForm])

    React.useEffect(() => {
        if (!clientContact) setSelectClientContactValidationForm(`iSolved contact is missing, please select a iSolved contact from the list`); else setSelectClientContactValidationForm("");
    }, [clientContact, selectClientContactValidationForm])

    React.useEffect(() => {
        if (!clientEffDate) setSelectClientEffectiveDateValidationForm(`Client Effective Date is missing, please select a Client Effective Date`); else setSelectClientEffectiveDateValidationForm(null);
    }, [clientEffDate, selectClientEffectiveDateValidationForm])

    React.useEffect(() => {
        var emails = hrEmail?.replace(/\s/g, '').split(",");
        var valid = true;

        for (var i = 0; i < emails?.length; i++) {
            if (emails[i] == "" || !emailPattern.test(emails[i])) {
                valid = false;
            }
        }
        if (!hrEmail && connectivityReq?.ConnectivityRequestType === "Forms") setSelectHREmailValidationForm(`HR Email is missing, please enter an email`);
        else if (!valid && connectivityReq?.ConnectivityRequestType === "Forms") setSelectHREmailValidationForm(`Invalid Email format, please enter a valid email`);
        else setSelectHREmailValidationForm(null);
    }, [hrEmail, selectHREmailValidationForm])


    React.useEffect(() => {
        if ((!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date") && onEdit === true) setSelectPlannedFirstProductionDateValidationForm("Planned first production date  cannot be empty"); else setSelectPlannedFirstProductionDateValidationForm("");
    }, [plannedFirstProductionDate, selectPlannedFirstProductionDateValidationForm])

    React.useEffect(() => {
        if (!migrationPhase && onEdit === true) setSelectMigrationPhaseValidationForm("Migration phase is missing, please select a phase from the list"); else setSelectMigrationPhaseValidationForm("");
    }, [migrationPhase, selectMigrationPhaseValidationForm])

    // React.useEffect(() => {
    //     if (uploadedFormsArray?.length > 0 && uploadedFormsArray?.any(s => !s.MainTypes || s.MainTypes?.length <= 0)) setSelectFormMainTypesValidationForm("Missing form main type, please select at least one"); else setSelectFormMainTypesValidationForm("");
    // }, [JSON.stringify(uploadedFormsArray), selectFormMainTypesValidationForm])

    // React.useEffect(() => {
    //     if (uploadedFormsArray?.length > 0 && uploadedFormsArray?.any(s => !s.SubTypes || s.SubTypes?.length <= 0)) setSelectFormSubTypesValidationForm("Missing form sub type, please select at least one"); else setSelectFormSubTypesValidationForm("");
    // }, [JSON.stringify(uploadedFormsArray), selectFormSubTypesValidationForm])

    React.useEffect(() => {
        if ((!migration || migration === null) && onEdit === true) setSelectMigrationValidationForm("Migration cannot be empty"); else setSelectMigrationValidationForm("");
    }, [migration, selectMigrationValidationForm, requestSubmitted])

    const handleMigrationStartDateChange = (date) => {
        setMigrationStartDate(Moment(date).format('YYYY-MM-DD'));

    };
    const handlePlannedFirstProductionDateChange = (date) => {
        setPlannedFirstProductionDate(Moment(date).format('YYYY-MM-DD'));

    };
    const handelClientContact = (e) => {
        setClientContact(e.target.value);
    }

    const handleTabChange = (e, newValue) => {
        openLoader();
        setTabValue(newValue);
        if (newValue === 1)
            routeHistory.push(`/ActionItems/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`);
        else if (newValue === 2)
            routeHistory.push(`/OERequests/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`);
        else routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`);
    };



    React.useEffect(() => {
        APIs.getPlanTypes().then((r) => setPlanTypesList(r?.data?.sort((a, b) => a.PlanTypeName.toLowerCase() > b.PlanTypeName.toLowerCase() ? 1 : -1)));
    }, [])

    React.useEffect(() => {
        openLoader();
        const contactsBody = {
            ResponsiblePartyID: 3,
            ConnectivityRequestID: connectivityRequestIDURL,
        }
        APIs.getContactsList(contactsBody).then(r => {
            !(r?.data?.length) ? setUsersList([]) : setUsersList(r?.data);
        })
        // APIs.getAllUsers().then((r) => (
        //     setUsersList(r?.data)
        // ));
    }, [])

    const handleAssignedToChange = (e, newValue) => {
        setAssignedTo(e.target.value);
    }

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestStatuses().then((r) => (
            setConnectivityRequestsStatus(r?.data)
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequestPhases().then((r) => (
            setConnectivityRequestsPhase(r?.data)
        ));
    }, [])


    React.useEffect(() => {
        setStatus(connectivityReq?.ConnectivityRequestStatusID);
        setPhase(connectivityReq?.ConnectivityRequestPhaseID);
        setFeed(connectivityReq?.ConnectivityRequestFeedID);
        setParamConfig(connectivityReq?.ConnectivityRequestConfigurations);
        setReasonStatus(connectivityReq?.StatusReason);
        setAssignedTo(connectivityReq?.AssignedToCognitoID);
        const filteredOtherPlansArray = connectivityReq?.PlanTypes?.filter(value => !(planTypesList?.map(s => s.PlanTypeID)?.includes(value.PlanTypeID)));
        const filteredPlansArray = connectivityReq?.PlanTypes?.filter(value => (planTypesList?.map(s => s.PlanTypeID)?.includes(value.PlanTypeID)));
        if (filteredOtherPlansArray?.length > 0) filteredPlansArray.push({ PlanTypeID: "Other" });
        setSelectedPlans(filteredPlansArray?.map(p => p.PlanTypeID) ?? []);
        setOtherPlanTypes(filteredOtherPlansArray?.map(p => p.PlanTypeName).join("\n") ?? []);
        connectivityReq?.ReceivedEncryptionRequirements === 1 ? setNeedEncryptionChecked(true) : setNeedEncryptionChecked(false);
        setMigration(connectivityReq?.MigrationConnectivityRequest);
        setSubType(connectivityReq?.ConnectivityRequestEDISubTypeID)
        setFormsSubType(connectivityReq?.ConnectivityRequestFormsSubTypeID)
        setHrEmail(connectivityReq?.HREmail)
        setMigrationStartDate((connectivityReq?.MigrationStartDate !== "0000-00-00" && connectivityReq?.MigrationStartDate !== null) ? connectivityReq?.MigrationStartDate : null);
        setPlannedFirstProductionDate((connectivityReq?.PlannedFirstProductionDate !== "0000-00-00" && connectivityReq?.PlannedFirstProductionDate !== null) ? connectivityReq?.PlannedFirstProductionDate : null)
        setMigrationPhase(connectivityReq?.MigrationPhaseID);
        setClientEffDate(connectivityReq?.ClientEffectiveDate);
        setClientContact(connectivityReq?.ClientContact);
        setCarrierGroupNumber(connectivityReq?.CarrierGroupNumber)
        setDateCancelation(connectivityReq?.CancellationDate);
    }, [onEdit === true, onEditPlans === true])

    React.useEffect(() => {
        const filteredOtherPlansArray = connectivityReq?.PlanTypes?.filter(value => !(planTypesList?.map(s => s.PlanTypeID)?.includes(value.PlanTypeID)));
        const filteredPlansArray = connectivityReq?.PlanTypes?.filter(value => (planTypesList?.map(s => s.PlanTypeID)?.includes(value.PlanTypeID)));
        setSelectedPlans(filteredPlansArray?.map(p => p.PlanTypeID) ?? []);
        setOtherPlanTypes(filteredOtherPlansArray?.map(p => p.PlanTypeName).join("\n") ?? []);
    }, [connectivityReq, planTypesList])

    React.useEffect(() => {
        const filteredOtherPlansArray = connectivityReq?.PlanTypes?.filter(value => !(planTypesList?.map(s => s.PlanTypeID)?.includes(value.PlanTypeID)));
        const filteredPlansArray = connectivityReq?.PlanTypes?.filter(value => (planTypesList?.map(s => s.PlanTypeID)?.includes(value.PlanTypeID)));
        setSelectedPlans(filteredPlansArray?.map(p => p.PlanTypeID) ?? []);
        setOtherPlanTypes(filteredOtherPlansArray?.map(p => p.PlanTypeName).join("\n") ?? []);
        setMigration(connectivityReq?.MigrationConnectivityRequest);
        setSubType(connectivityReq?.ConnectivityRequestEDISubTypeID);
        setFormsSubType(connectivityReq?.ConnectivityRequestFormsSubTypeID);

    }, [connectivityReq])

    React.useEffect(() => {
        if (otherPlanTypes?.length > 0) setOtherChecked(true); else setOtherChecked(false);
    }, [otherPlanTypes])

    React.useEffect(() => {
        setFilesList(connectivityReq?.Attachments);
        setFormsList(connectivityReq?.DigitalForms ?? []);
    }, [connectivityReq]);

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handlePhaseChange = (e) => {
        setPhase(e.target.value);
    };

    const handleParamConfigChange = (e) => {
        setParamConfig(e.target.value);
    }

    const handleFeedChange = (e) => {
        setFeed(e.target.value);
    };

    const handlePlanChange = (e) => {
        setCheckedPlan(e.target.checked);
        setSelectAllPlansChecked(!e.target.checked);
    }

    const handleOtherPlanChange = (e) => {
        setOtherChecked(!otherChecked);
    }

    const handleOtherPlanTypesChange = (e) => {
        setOtherPlanTypes(e.target.value);
    }

    const handlePlanClick = (value) => (event) => {
        let selectedPlansArray = selectedPlans;
        if (!value || value === "") selectedPlansArray = [];
        else if (selectedPlansArray.indexOf(value) >= 0) {
            selectedPlansArray.splice(selectedPlansArray.indexOf(value), 1);
        }
        else selectedPlansArray.push(value);
        setSelectedPlans([...selectedPlansArray]);
    }

    const handleSelectAllPlanChange = (e) => {
        setSelectAllPlansChecked(e.target.checked);
        if (e.target.checked === true)
            setOtherChecked(false);
        else return;
    }

    const handleSelectAllPlanClick = (value) => (event) => {
        let selectedPlanArray = selectedPlans;
        if (!value || value === "") selectedPlanArray = [];
        else if (selectedPlanArray.filter(s => (value?.map(s => s.PlanTypeID)?.includes(s.PlanTypeID))) && selectedPlanArray.length !== 0) {
            selectedPlanArray.length = 0;
        }
        else {
            selectedPlanArray.push(...value);
            selectedPlanArray = Array.from(new Set(selectedPlanArray));
        }
        setSelectedPlans([...selectedPlanArray]);
    }

    React.useEffect(() => {
        if (otherChecked === false)
            setOtherPlanTypes([]);
        else return;
    }, [otherChecked])

    React.useEffect(() => {
        if (onEdit && groupReqStatus !== 4 && (!otherChecked && ((selectedPlans?.filter(s => s !== "Other").length === 0) || (selectedPlans.length === 1 && selectedPlans[0] === "Other"))) || (otherChecked && otherPlanTypes === ""))
            setSelectPlanValidationForm("Please select at least one plan"); else setSelectPlanValidationForm("");
    }, [selectedPlans, otherPlanTypes, otherChecked])


    React.useEffect(() => {
        if (!otherChecked) setSelectedPlans(selectedPlans.filter(s => s !== "Other"));
    }, [otherChecked])

    React.useEffect(() => {
        openLoader();
        APIs.getPreMapping(connectivityRequestIDURL).then((r) => {
            setPreMappingObject(r?.data);
            setPreMappingObjectBasicData(r?.data?.PreMappingBasicData);
            setPreMappingSections(r?.data?.Sections);
            setResponseMsg(r?.msg);
        });
    }, [])

    const handleClientEffDateChange = (date) => {
        setClientEffDate(date == null ? null : Moment(date).format('YYYY-MM-DD'));
    };
    const handleCheckAdmin = () => {
        if (responseMsg !== "") {
            if (responseMsg === "Invalid  ConnectivityRequestID") {
                FailureModal(dialog, {
                    title: 'Cannot Open!',
                    body: `There is no Pre-Mapping form for this Connectivity Request`,
                });
                setResponseMsg("");
            }
            else if (responseMsg !== "Invalid  ConnectivityRequestID")
                if (preMappingSections[0].Model.any(s => (s.GroupPlans)))
                    routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityReq?.ConnectivityRequestID}/ViewPreMappingFormISolved`);
                else routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityReq?.ConnectivityRequestID}/FillPreMappingFormISolved`);
        } else return;
    }

    const handleCheckAnayst = () => {
        if (responseMsg === "Invalid  ConnectivityRequestID")
            routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityReq?.ConnectivityRequestID}/CreatePreMappingFormAnalyst`);
        else routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityReq?.ConnectivityRequestID}/ViewPreMappingFormAnalyst`);
    }

    // Submit Edit CR in case (super admin and Analyst)
    const submitUpdatedConnectRequestConfig = (e) => {
        e.preventDefault();
        if (requestSubmitted) {
            let updatedConnectRequestConfig = {};
            // if (validateSelectedFeedHrRequired()) return;
            if (groupReqStatus !== 4 && selectPlanValidationForm) return; //not draft GR with no plans
            if (onEdit === true && uploadedFilesArray?.length <= 0 && uploadedFormsArray?.length <= 0) {
                if ((migration !== "No" && !migrationPhase) || selectHREmailValidationForm || selectPlanValidationForm || (migration !== "No" && (!migrationStartDate || migrationStartDate === "Invalid date")) || (migration !== "No" && (!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date")) || (!clientEffDate || clientEffDate === "Invalid date" || selectClientEffectiveDateValidationForm)) return;
                if (selectStatusReasonValidation || cancellationDateValidationForm || emptyCancellationDateValidationForm || selectClientContactValidationForm || selectClientEffectiveDateValidationForm || formsSubTypeValidation) return;
                openLoader();
                updatedConnectRequestConfig = {
                    ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
                    GroupRequestID: connectivityReq?.GroupRequestID,
                    ConnectivityRequestType: connectivityReq?.ConnectivityRequestType,
                    ConnectivityRequestEDISubTypeID: subType,
                    ConnectivityRequestFormsSubTypeID: formsSubType,
                    HREmail: hrEmail,
                    ConnectivityRequestStatusID: status,
                    CancellationDate: (dateCancelation !== "Invalid date") ? dateCancelation : null,
                    StatusReason: (status === 2 || status === 3 || status === 5 || (dateCancelation !== "Invalid date" && dateCancelation !== null)) ? reasonStatus : "",
                    ConnectivityRequestPhaseID: phase,
                    MigrationConnectivityRequest: migration,
                    MigrationStartDate: migration !== "No" ? migrationStartDate : null,
                    PlannedFirstProductionDate: migration !== "No" ? plannedFirstProductionDate : null,
                    MigrationPhaseID: migration !== "No" ? migrationPhase : null,
                    ConnectivityRequestFeedID: feed,
                    ConnectivityRequestConfigurations: paramConfig,
                    AssignedTo: assignedTo,
                    ClientEffectiveDate: clientEffDate !== "Invalid date" ? clientEffDate : null,
                    ClientContact: clientContact,
                    CarrierGroupNumber: carrierGroupNumber,
                    ReceivedEncryptionRequirements: needEncryptionChecked === true ? 1 : 0,
                    IsolvedCarrier: {
                        Code: connectivityReq?.IsolvedCarrierCode,
                        Name: connectivityReq?.IsolvedCarrierName,
                        Type: connectivityReq?.IsolvedCarrierType,
                        isNew: false
                    },
                    CarriersContacts: connectivityReq.ConnectivityRequestContacts,
                    PlanTypes: selectedPlans?.filter(s => s !== "Other") ?? [],
                    OtherPlanTypes: otherChecked && otherPlanTypes.length > 0 ? otherPlanTypes?.split(/\r\n|\r|\n/) : [],
                };
            } else if (onEdit === false && uploadedFilesArray?.length > 0 && uploadedFormsArray?.length <= 0) {
                openLoader();
                updatedConnectRequestConfig = {
                    ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
                    GroupRequestID: connectivityReq?.GroupRequestID,
                    ConnectivityRequestType: connectivityReq?.ConnectivityRequestType,
                    ConnectivityRequestEDISubTypeID: connectivityReq?.ConnectivityRequestEDISubTypeID,
                    ConnectivityRequestFormsSubTypeID: connectivityReq?.ConnectivityRequestFormsSubTypeID,
                    HREmail: connectivityReq?.HREmail,
                    ConnectivityRequestStatusID: connectivityReq?.ConnectivityRequestStatusID,
                    StatusReason: connectivityReq.StatusReason,
                    CancellationDate: connectivityReq?.CancellationDate,
                    ConnectivityRequestPhaseID: connectivityReq?.ConnectivityRequestPhaseID,
                    MigrationConnectivityRequest: connectivityReq?.MigrationConnectivityRequest,
                    MigrationStartDate: connectivityReq?.MigrationStartDate,
                    PlannedFirstProductionDate: connectivityReq?.PlannedFirstProductionDate,
                    MigrationPhaseID: connectivityReq?.MigrationPhaseID,
                    ConnectivityRequestFeedID: connectivityReq?.ConnectivityRequestFeedID,
                    AssignedTo: connectivityReq?.AssignedToCognitoID,
                    ConnectivityRequestConfigurations: connectivityReq?.ConnectivityRequestConfigurations,
                    ReceivedEncryptionRequirements: connectivityReq?.ReceivedEncryptionRequirements,
                    ClientEffectiveDate: connectivityReq?.ClientEffectiveDate,
                    ClientContact: connectivityReq?.ClientContact,
                    CarrierGroupNumber: connectivityReq?.CarrierGroupNumber,
                    IsolvedCarrier: {
                        Code: connectivityReq?.IsolvedCarrierCode,
                        Name: connectivityReq?.IsolvedCarrierName,
                        Type: connectivityReq?.IsolvedCarrierType,
                        isNew: false
                    },
                    NewAttachments: uploadedFilesArray?.map((file) => ({
                        Type: file.Type,
                        Name: file.Name,
                        Extension: file.Extension,
                        GUID: file.GUID,
                        isDeleted: false
                    })),
                    CarriersContacts: connectivityReq.ConnectivityRequestContacts,
                    PlanTypes: selectedPlans?.filter(s => s !== "Other") ?? [],
                    OtherPlanTypes: otherChecked && otherPlanTypes.length > 0 ? otherPlanTypes?.split(/\r\n|\r|\n/) : [],
                };
            } else if (onEdit === false && uploadedFilesArray?.length <= 0 && uploadedFormsArray?.length > 0) {
                openLoader();
                updatedConnectRequestConfig = {
                    ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
                    GroupRequestID: connectivityReq?.GroupRequestID,
                    ConnectivityRequestType: connectivityReq?.ConnectivityRequestType,
                    ConnectivityRequestEDISubTypeID: connectivityReq?.ConnectivityRequestEDISubTypeID,
                    ConnectivityRequestFormsSubTypeID: connectivityReq?.ConnectivityRequestFormsSubTypeID,
                    HREmail: connectivityReq?.HREmail,
                    ConnectivityRequestStatusID: connectivityReq?.ConnectivityRequestStatusID,
                    StatusReason: connectivityReq.StatusReason,
                    CancellationDate: connectivityReq?.CancellationDate,
                    ConnectivityRequestPhaseID: connectivityReq?.ConnectivityRequestPhaseID,
                    MigrationConnectivityRequest: connectivityReq?.MigrationConnectivityRequest,
                    MigrationStartDate: connectivityReq?.MigrationStartDate,
                    PlannedFirstProductionDate: connectivityReq?.PlannedFirstProductionDate,
                    MigrationPhaseID: connectivityReq?.MigrationPhaseID,
                    ConnectivityRequestFeedID: connectivityReq?.ConnectivityRequestFeedID,
                    AssignedTo: connectivityReq?.AssignedToCognitoID,
                    ConnectivityRequestConfigurations: connectivityReq?.ConnectivityRequestConfigurations,
                    ReceivedEncryptionRequirements: connectivityReq?.ReceivedEncryptionRequirements,
                    ClientEffectiveDate: connectivityReq?.ClientEffectiveDate,
                    ClientContact: connectivityReq?.ClientContact,
                    CarrierGroupNumber: connectivityReq?.CarrierGroupNumber,

                    IsolvedCarrier: {
                        Code: connectivityReq?.IsolvedCarrierCode,
                        Name: connectivityReq?.IsolvedCarrierName,
                        Type: connectivityReq?.IsolvedCarrierType,
                        isNew: false
                    },
                    DigitalForms: uploadedFormsArray?.map((form) => ({
                        Name: form?.Name,
                        Type: "DigitalForm",
                        GUID: form?.GUID,
                        Extension: form?.Extension,
                        // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                        // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                    })),
                    CarriersContacts: connectivityReq.ConnectivityRequestContacts,
                    PlanTypes: selectedPlans?.filter(s => s !== "Other") ?? [],
                    OtherPlanTypes: otherChecked ? otherPlanTypes?.split(/\r\n|\r|\n/) : [],
                };

            } else if (onEdit === false && uploadedFilesArray?.length > 0 && uploadedFormsArray?.length > 0) {
                openLoader();
                updatedConnectRequestConfig = {
                    ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
                    GroupRequestID: connectivityReq?.GroupRequestID,
                    ConnectivityRequestType: connectivityReq?.ConnectivityRequestType,
                    ConnectivityRequestEDISubTypeID: connectivityReq?.ConnectivityRequestEDISubTypeID,
                    ConnectivityRequestFormsSubTypeID: connectivityReq?.ConnectivityRequestFormsSubTypeID,
                    HREmail: connectivityReq?.HREmail,
                    ConnectivityRequestStatusID: connectivityReq?.ConnectivityRequestStatusID,
                    StatusReason: connectivityReq.StatusReason,
                    CancellationDate: connectivityReq?.CancellationDate,
                    ConnectivityRequestPhaseID: connectivityReq?.ConnectivityRequestPhaseID,
                    MigrationConnectivityRequest: connectivityReq?.MigrationConnectivityRequest,
                    MigrationStartDate: connectivityReq?.MigrationStartDate,
                    PlannedFirstProductionDate: connectivityReq?.PlannedFirstProductionDate,
                    MigrationPhaseID: connectivityReq?.MigrationPhaseID,
                    ConnectivityRequestFeedID: connectivityReq?.ConnectivityRequestFeedID,
                    AssignedTo: connectivityReq?.AssignedToCognitoID,
                    ConnectivityRequestConfigurations: connectivityReq?.ConnectivityRequestConfigurations,
                    ReceivedEncryptionRequirements: connectivityReq?.ReceivedEncryptionRequirements,
                    ClientEffectiveDate: connectivityReq?.ClientEffectiveDate,
                    ClientContact: connectivityReq?.ClientContact,
                    CarrierGroupNumber: connectivityReq?.CarrierGroupNumber,
                    IsolvedCarrier: {
                        Code: connectivityReq?.IsolvedCarrierCode,
                        Name: connectivityReq?.IsolvedCarrierName,
                        Type: connectivityReq?.IsolvedCarrierType,
                        isNew: false
                    },
                    NewAttachments: uploadedFilesArray?.map((file) => ({
                        Type: file.Type,
                        Name: file.Name,
                        Extension: file.Extension,
                        GUID: file.GUID,
                        isDeleted: false
                    })),
                    DigitalForms: uploadedFormsArray?.map((form) => ({
                        Name: form?.Name,
                        Type: "DigitalForm",
                        GUID: form?.GUID,
                        Extension: form?.Extension,
                    })),
                    CarriersContacts: connectivityReq.ConnectivityRequestContacts,
                    PlanTypes: selectedPlans?.filter(s => s !== "Other") ?? [],
                    OtherPlanTypes: otherChecked ? otherPlanTypes?.split(/\r\n|\r|\n/) : [],
                };
            } else {
                if ((migration !== "No" && !migrationPhase) || selectPlanValidationForm || (migration !== "No" && (!migrationStartDate || migrationStartDate === "Invalid date")) || (migration !== "No" && (!plannedFirstProductionDate || plannedFirstProductionDate === "Invalid date"))) return;
                if (selectStatusReasonValidation || cancellationDateValidationForm || emptyCancellationDateValidationForm || selectClientContactValidationForm || selectClientEffectiveDateValidationForm) return;
                openLoader();
                updatedConnectRequestConfig = {
                    ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
                    GroupRequestID: connectivityReq?.GroupRequestID,
                    ConnectivityRequestType: connectivityReq?.ConnectivityRequestType,
                    ConnectivityRequestEDISubTypeID: subType,
                    ConnectivityRequestFormsSubTypeID: formsSubType,
                    HREmail: hrEmail,
                    ConnectivityRequestStatusID: status,
                    CancellationDate: (dateCancelation !== "Invalid date") ? dateCancelation : null,
                    StatusReason: (status === 2 || status === 3 || status === 5 || (dateCancelation !== "Invalid date" && dateCancelation !== null)) ? reasonStatus : "",
                    ConnectivityRequestPhaseID: phase,
                    MigrationConnectivityRequest: migration,
                    MigrationStartDate: migration !== "No" ? migrationStartDate : null,
                    PlannedFirstProductionDate: migration !== "No" ? plannedFirstProductionDate : null,
                    MigrationPhaseID: migration !== "No" ? migrationPhase : null,
                    ConnectivityRequestFeedID: feed,
                    ConnectivityRequestConfigurations: paramConfig,
                    AssignedTo: assignedTo,
                    ReceivedEncryptionRequirements: needEncryptionChecked === true ? 1 : 0,
                    ClientEffectiveDate: clientEffDate !== "Invalid date" ? clientEffDate : null,
                    ClientContact: clientContact,
                    CarrierGroupNumber: carrierGroupNumber,
                    IsolvedCarrier: {
                        Code: connectivityReq?.IsolvedCarrierCode,
                        Name: connectivityReq?.IsolvedCarrierName,
                        Type: connectivityReq?.IsolvedCarrierType,
                        isNew: false
                    },
                    NewAttachments: uploadedFilesArray?.map((file) => ({
                        Type: file.Type,
                        Name: file.Name,
                        Extension: file.Extension,
                        GUID: file.GUID,
                        isDeleted: false
                    })),
                    DigitalForms: uploadedFormsArray?.map((form) => ({
                        Name: form?.Name,
                        Type: "DigitalForm",
                        GUID: form?.GUID,
                        Extension: form?.Extension,
                        // MainTypesIDs: form?.MainTypes?.map(s => s.Id),
                        // SubTypesIDs: form?.SubTypes?.map(s => s.Id),
                    })),
                    PlanTypes: selectedPlans?.filter(s => s !== "Other") ?? [],
                    OtherPlanTypes: otherChecked && otherPlanTypes.length > 0 ? otherPlanTypes?.split(/\r\n|\r|\n/) : [],
                };
            }

            if (groupReqStatus == 4) {
                AlertModal(dialog, () => {
                    openLoader();
                    if (userRoles?.includes("1") ||
                        userRoles?.includes("7")) {
                        APIs.getEditConnectivityRequestForAnalyst(updatedConnectRequestConfig).then((r) => {
                            window.location.reload();
                        });
                    }
                    else {
                        APIs.editConnectivityRequestPlans(updatedConnectRequestConfig).then((r) => {
                            window.location.reload();
                        });
                    }
                }, {

                    title: 'Alert!',
                    info: <div className="text-left fs-12">Kindly note that this connectivity request is attached to a draft group request</div>,
                    question: `Do you want to proceed?`,
                });
                closeLoader();
            } else {
                openLoader();
                if (userRoles?.includes("1") ||
                    userRoles?.includes("7")) {
                    APIs.getEditConnectivityRequestForAnalyst(updatedConnectRequestConfig).then((r) => {
                        window.location.reload();
                    });
                }
                else {
                    APIs.editConnectivityRequestPlans(updatedConnectRequestConfig).then((r) => {
                        window.location.reload();
                    });
                }

            }


        }
    }
    // Submit Edit CR in case (other Roles except superadmin and Analyst)
    const updatePlans = (e) => {

        e.preventDefault();
        setRequestSubmitted(true)
        // client Effective Date validation
        if (!clientEffDate || clientEffDate == "Invalid date" || selectClientEffectiveDateValidationForm || selectHREmailValidationForm) return;
        openLoader();

        let updatedPlans = {
            ConnectivityRequestID: connectivityReq?.ConnectivityRequestID,
            ConnectivityRequestFormsSubTypeID: formsSubType,
            HREmail: hrEmail,
            PlanTypes: selectedPlans?.filter(s => s !== "Other") ?? [],
            OtherPlanTypes: otherChecked && otherPlanTypes.length > 0 ? otherPlanTypes?.split(/\r\n|\r|\n/) : [],
            ClientContact: clientContact,
            // CarrierGroupNumber: carrierGroupNumber,
        }

        // insert Client Effective date to payload if it is valid and there is a change into ClientEffectiveDate
        if (clientEffDate && clientEffDate !== "Invalid date" && clientEffDate != connectivityReq?.ClientEffectiveDate) updatedPlans.ClientEffectiveDate = clientEffDate
        // insert Client carrierGroupNumber to payload if it is valid and there is a change into CarrierGroupNumber
        if (carrierGroupNumber && carrierGroupNumber != connectivityReq?.CarrierGroupNumber) updatedPlans.CarrierGroupNumber = carrierGroupNumber

        if (groupReqStatus == 4) {
            AlertModal(dialog, () => {
                openLoader();
                APIs.editConnectivityRequestPlans(updatedPlans).then((r) => {
                    window.location.reload();
                });
            }, {

                title: 'Alert!',
                info: <div className="text-left fs-12">Kindly note that this connectivity request is attached to a draft group request</div>,
                question: `Do you want to proceed?`,
            });
            closeLoader();
        } else {
            openLoader();
            APIs.editConnectivityRequestPlans(updatedPlans).then((r) => {
                window.location.reload();
            });
        }

    }

    const handleCREDISubTypeChange = (e) => {
        setSubType(+e.target.value);
    };

    const handleCRFormsSubTypeChange = (e) => {
        setFormsSubType(+e.target.value);
    };


    const escFunction = React.useCallback((event) => {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setOnEdit(false);
            setFormsTabValue(0);
            setFilesTabValue(0);
        }
    }, []);

    React.useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    const notisIsovedSuperAdminOriSolvedUser = () => {
        //attached user roles ID for analyst and super admin
        return (userRoles?.includes("1") ||
            userRoles?.includes("7"));
    }

    return (
        <div id="connect-req-config">

            <div className="d-flex flex-wrap">
                {connectivityReq && (
                    <BreadcrumbSteps
                        breadcrumbTitleCurrent={`${connectivityReq?.Peo?.PeoName ? connectivityReq?.Peo?.PeoName : connectivityReq?.GroupName}, ${connectivityReq?.CarrierName === "Third Party" ? `${connectivityReq?.CarrierName} (${connectivityReq?.ThirdPartyName})` : connectivityReq?.CarrierName}`}
                        isCurrent={true}
                    />
                )}
                {groupReqStatus && groupReqStatus == 4 && <div className="warmingOERequest  ml-auto">  <ErrorOutlineIcon className="mr-1" />Kindly note that this connectivity request is attached to a draft group request</div>}
            </div>

            <div className="d-flex w-100">
                <div className="d-flex" style={{ width: 'calc(100% - 220px)' }}>
                    <MultipleTabsTitles tabTitle={connectivityTabs} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />
                </div>

                {tabValue === 0 && (
                    <Button variant="contained" className=" blue-btn ml-auto" style={{ whiteSpace: "nowrap", marginTop: 15, minWidth: 180 }} disabled={!connectivityReq} onClick={(e) => {
                        e.preventDefault();
                        setResponseMsg("");
                        if (userActions?.includes("21")) {
                            handleCheckAdmin();
                        } else if ((userActions?.includes("14") ||
                            userActions.includes("20"))) {
                            handleCheckAnayst();
                        }

                    }}>
                        <label style={{ cursor: "pointer" }} className="mb-0" >Pre-Mapping Form</label>
                    </Button>
                    // <div className="alignvh-center" style={{ position: "relative", justifyContent: "flex-end" }}>
                    //     </div>
                )}


            </div>
            {tabValue === 0 && (
                <Paper elevation={0} className="p-5">
                    <form onSubmit={submitUpdatedConnectRequestConfig}>
                        <div style={{ textAlignLast: "right", marginBottom: "15px" }}>
                            {(userActions?.includes("10") || userActions?.includes("20")) && (
                                <Button variant="contained" className="blue-outlined-btn mr-2" disabled={!connectivityReq} onClick={() => { setOnEdit(!onEdit); setFilesTabValue(1); setFormsTabValue(1); }}>
                                    <svg width="20" height="24" viewBox="0 0 25 24">
                                        <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                    </svg>
                                    <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Configuration</label>
                                </Button>
                            )}
                            {(userActions?.includes("42")) && !(userActions?.includes("20")) && (
                                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => { setOnEditPlans(!onEditPlans); }}>
                                    <svg width="20" height="24" viewBox="0 0 25 24">
                                        <use xlinkHref={`${editRequestIcon}#editRequest`}></use>
                                    </svg>
                                    <label style={{ cursor: "pointer" }} className="ml-2 mb-0">Edit Configuration</label>
                                </Button>
                            )}
                        </div>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                aria-controls="panel2-content"
                                id="panel-header-cr-config"
                                onClick={(event) => {
                                    event.stopPropagation();
                                }}
                                style={{ pointerEvents: 'none' }}
                            >
                                <span>Request Information</span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    {connectivityReq?.IsPEO !== 1 && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Client Name</h6>
                                            <div className="textfield-div">
                                                {connectivityReq?.GroupName}
                                            </div>
                                        </Grid>
                                    )}
                                    {connectivityReq?.IsPEO === 1 && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">PEO Name</h6>
                                            <div className="textfield-div">
                                                {connectivityReq?.Peo?.PeoName}
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Group Request ID</h6>
                                        <div className="textfield-div">
                                            {connectivityReq?.GroupRequestID}
                                            <a href={`/GroupRequests/ViewGroupRequest/${connectivityReq?.GroupRequestID}`} className="ml-auto alignvh-center color blue-main-color">
                                                <svg width="20" height="21" viewBox="0 0 25 21">
                                                    <use xlinkHref={`${goToIcon}#right-to-bracket`}></use>
                                                </svg>
                                                <span className="ml-1 fw-500">Go</span>
                                            </a>
                                        </div>
                                    </Grid>
                                    {connectivityReq?.ThirdPartyName && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Third Party Name</h6>
                                            <div className="textfield-div">
                                                {connectivityReq?.ThirdPartyName}
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Carrier Name</h6>
                                        <div className="textfield-div">
                                            {connectivityReq?.CarrierName}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Carrier Code</h6>
                                        <div className="textfield-div">
                                            {connectivityReq?.IsolvedCarrierCode}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">{connectivityReq?.IsPEO !== 1 ? 'Client Effective Date' : 'PEO Effective Date'}</h6>
                                        {(onEdit || onEditPlans) ? (
                                            <div className="text-input" style={{ padding: "0 20px" }}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        className="w-100 m-auto"
                                                        autoOk={true}
                                                        disableToolbar
                                                        variant="inline"
                                                        format="MM/dd/yyyy"
                                                        margin="normal"
                                                        id="frequency-date"
                                                        value={clientEffDate === null ? clientEffDate : parseISO(clientEffDate)}
                                                        onChange={handleClientEffDateChange}
                                                        keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                        </svg>}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.ClientEffectiveDate ? Moment(connectivityReq?.ClientEffectiveDate).format('MM/DD/YYYY') : null}
                                                <div className="ml-auto">
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                </div>

                                            </div>
                                        )}
                                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectClientEffectiveDateValidationForm}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">iSolved Contact</h6>
                                        {((onEdit || onEditPlans) && (userActions?.includes("59") || userActions?.includes("20"))) ? (
                                            <FormControl label="Select iSolved contact" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={clientContact ?? ""}
                                                    onChange={handelClientContact}
                                                >
                                                    <MenuItem value="">None</MenuItem>
                                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                                    {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                                        <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                                    ))}
                                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                                    {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled !== 1)?.map((c, ci) => (
                                                        <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.ClientContactName}
                                            </div>
                                        )}
                                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectClientContactValidationForm}</div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Carrier Group Number</h6>
                                        {(onEdit || onEditPlans) ? (
                                            <input type="text" className="text-input w-100"
                                                value={carrierGroupNumber ?? ""}
                                                onChange={e => setCarrierGroupNumber(e.target.value)}
                                            />
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.CarrierGroupNumber}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Type</h6>
                                        <div className="textfield-div">
                                            {connectivityReq?.ConnectivityRequestType}
                                        </div>
                                    </Grid>
                                    {connectivityReq?.ConnectivityRequestType === "EDI" && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Sub Type</h6>
                                            {onEdit ? (
                                                <FormControl label="Select CR Sub Type" focused={false} variant="outlined" className="w-100">
                                                    <Select
                                                        className="text-dropdown"
                                                        value={subType ?? ""}
                                                        onChange={handleCREDISubTypeChange}
                                                    >

                                                        {subTypeList?.map((s, si) => (

                                                            <MenuItem key={`CR-EDI-SubType-${si}`} value={s?.Id} >{s.Name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <div className="textfield-div">
                                                    {connectivityReq?.ConnectivityRequestEDISubTypeName}
                                                </div>
                                            )}
                                        </Grid>
                                    )}
                                    {connectivityReq?.ConnectivityRequestType === "Forms" && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Transmission Type</h6>
                                            {(onEdit || onEditPlans) ? (
                                                <FormControl label="Select CR Forms Sub Type" focused={false} variant="outlined" className="w-100">
                                                    <Select
                                                        className="text-dropdown"
                                                        value={formsSubType ?? ""}
                                                        onChange={handleCRFormsSubTypeChange}
                                                    >

                                                        {formsSubTypeList?.map((s, si) => (

                                                            <MenuItem key={`CR-Forms-SubType-${si}`} value={s?.Id} >{s.Name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            ) : (
                                                <div className="textfield-div">
                                                    {connectivityReq?.ConnectivityRequestFormsSubTypeName}
                                                </div>
                                            )}
                                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{formsSubTypeValidation}</div>
                                        </Grid>
                                    )}
                                    {connectivityReq?.ConnectivityRequestType === "Forms" && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <div style={{ display: "flex" }}>
                                                <h6 className="form-title mr-1">HR Email</h6>
                                                <CustomTooltip TransitionComponent={Zoom} title={"This is the contact who will receive the notification emails regarding Forms"}>
                                                    <svg width="20" height="15" viewBox="0 0 25 24" >
                                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                                    </svg>
                                                </CustomTooltip>
                                            </div>
                                            {(onEdit || onEditPlans) ? (
                                                <>
                                                    <input type="email" multiple className="text-input w-100"
                                                        value={hrEmail ?? ""}
                                                        onChange={e => setHrEmail(e.target.value)}
                                                    />
                                                    <span>You can enter multiple emails here separated by commas</span>
                                                </>
                                            ) : (
                                                <div className="textfield-div">
                                                    {connectivityReq?.HREmail}
                                                </div>
                                            )}
                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{selectHREmailValidationForm}</div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Feed</h6>
                                        {onEdit ? (
                                            <FormControl label="Select Feed" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={feed ?? ""}
                                                    onChange={handleFeedChange}
                                                >
                                                    <MenuItem value={null}>None</MenuItem>
                                                    {carrierFeeds?.length > 0 && carrierFeeds?.map((s, si) => (
                                                        <MenuItem key={`connect-status-${si}`} value={s?.FeedID}>{s?.FeedName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.FeedName}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Feed ID</h6>
                                        <div className="textfield-div">
                                            {connectivityReq?.ConnectivityRequestFeedID}
                                            {connectivityReq?.FeedName !== null && (
                                                <a href={`/Feeds/ViewFeed/${connectivityReq?.ConnectivityRequestFeedID}`} className="ml-auto alignvh-center color blue-main-color">
                                                    <svg width="20" height="21" viewBox="0 0 25 21">
                                                        <use xlinkHref={`${goToIcon}#right-to-bracket`}></use>
                                                    </svg>
                                                    <span className="ml-1 fw-500">Go</span>
                                                </a>
                                            )}
                                        </div>
                                    </Grid>
                                    {notisIsovedSuperAdminOriSolvedUser() && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Feed Structure Type</h6>
                                            <div className="textfield-div">
                                                {connectivityReq?.StructureTypeName}
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Feed Data Type</h6>
                                        <div className="textfield-div">
                                            {connectivityReq?.DataType}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Status</h6>
                                        {(onEdit && connectivityReq.GroupRequestStatusID !== 2 && ((!userActions?.includes("20") && connectivityReq.ConnectivityRequestStatusID !== 5) || userActions?.includes("20"))) ? (
                                            <FormControl label="Select Status" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={status ?? ""}
                                                    onChange={handleStatusChange}
                                                >
                                                    {connectivityRequestsStatus?.map((s, si) => (
                                                        <MenuItem key={`connect-status-${si}`} value={s?.ConnectivityRequestStatusID}>{s?.ConnectivityRequestStatusName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.ConnectivityRequestStatusName}
                                            </div>
                                        )}
                                    </Grid>
                                    {(onEdit || (!onEdit && connectivityReq?.CancellationDate !== null)) && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title" >Cancellation Date</h6>
                                            {(onEdit && ((!userActions?.includes("20") && connectivityReq.ConnectivityRequestStatusID !== 5) || userActions?.includes("20"))) ? (
                                                <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px", backgroundColor: "white" }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            className=" w-100"
                                                            autoOk={true}
                                                            disableToolbar
                                                            minDate={(status !== 5 && connectivityReq?.ConnectivityRequestStatusID === 5 || connectivityReq.ConnectivityRequestStatusID !== 5) && todayDate}
                                                            variant="inline"
                                                            format="MM/dd/yyyy"
                                                            margin="normal"
                                                            id="cancelation-date"
                                                            value={dateCancelation === null ? dateCancelation : parseISO(dateCancelation)}
                                                            onChange={(date) => {
                                                                date ? setDateCancelation(Moment(date).format('YYYY-MM-DD')) : setDateCancelation(null)
                                                            }}
                                                            keyboardIcon={
                                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                                </svg>
                                                            }
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            ) : (
                                                <div className="textfield-div">
                                                    {connectivityReq?.CancellationDate ? Moment(connectivityReq?.CancellationDate).format('MM/DD/YYYY') : null}
                                                    <div className="ml-auto">
                                                        <svg width="14" height="16" viewBox="0 0 14 16">
                                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="mt-3 text-danger">{cancellationDateValidationForm}</div>
                                            <div className="mt-3 text-danger">{emptyCancellationDateValidationForm}</div>
                                        </Grid>
                                    )}
                                    {(connectivityReq?.CancellationDate !== null || connectivityReq?.ConnectivityRequestStatusID === 2 || connectivityReq?.ConnectivityRequestStatusID === 3 || connectivityReq?.ConnectivityRequestStatusID === 5) && !onEdit && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">
                                                {(connectivityReq?.CancellationDate !== null ? "Cancellation " : (connectivityReq?.ConnectivityRequestStatusID === 2 ? "On Hold " : connectivityReq?.ConnectivityRequestStatusID === 3 ? "Block " : "Cancellation"))}
                                                Reason
                                            </h6>
                                            <div className="textfield-div no-height">
                                                <span style={{ whiteSpace: "break-spaces" }}>
                                                    {connectivityReq?.StatusReason}
                                                </span>
                                            </div>
                                        </Grid>
                                    )}
                                    {(status === 2 || status === 3 || status === 5 || dateCancelation !== null) && onEdit && (
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">
                                                {dateCancelation === null ? status === 2 ? "On Hold " : status === 3 ? "Block " : "Cancellation " : "Cancellation "}
                                                Reason
                                            </h6>
                                            <textarea
                                                type="text"
                                                className="action-item-textarea w-100"
                                                value={reasonStatus}
                                                onChange={(e) => {
                                                    setReasonStatus(e.target.value);
                                                }}
                                                rows={4}
                                            />
                                            <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectStatusReasonValidation}</div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Phase</h6>
                                        {onEdit ? (
                                            <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={phase ?? ""}
                                                    onChange={handlePhaseChange}
                                                >
                                                    {connectivityRequestsPhase?.map((s, si) => (
                                                        <MenuItem key={`connect-phase-${si}`} value={s?.ConnectivityRequestPhaseID}>{s?.ConnectivityRequestPhasesName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.ConnectivityRequestPhasesName}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Assigned To</h6>
                                        {(onEdit) ? (
                                            <FormControl label="Select assigned to" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={assignedTo ?? ""}
                                                    onChange={e => setAssignedTo(e.target.value)}
                                                >
                                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                                    {usersList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                                        <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                                    ))}
                                                    <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                                    {usersList?.filter(s => s.IsEnabled !== 1)?.map((c, ci) => (
                                                        <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.AssignedTo}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Need Encryption</h6>
                                        {onEdit ? (
                                            <FormControlLabel
                                                className="dependents-switch"
                                                control={
                                                    <Switch
                                                        checked={needEncryptionChecked}
                                                        onChange={() => setNeedEncryptionChecked(!needEncryptionChecked)}
                                                    />
                                                }
                                                label=""
                                            />
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.ReceivedEncryptionRequirements === 1 ? "Yes" : "No"}
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Migration</h6>
                                        {onEdit ? (
                                            <FormControl label="Select Migration" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={migration ?? ""}
                                                    onChange={e => setMigration(e.target.value)}
                                                >
                                                    <MenuItem value="No">No</MenuItem>
                                                    <MenuItem value="eBenefits Network">eBenefits Network</MenuItem>
                                                    <MenuItem value="Everything Benefits">Everything Benefits</MenuItem>
                                                </Select>
                                            </FormControl>
                                        ) : (
                                            <div className="textfield-div">
                                                {connectivityReq?.MigrationConnectivityRequest}
                                            </div>
                                        )}
                                        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{selectMigrationValidationForm}</div>
                                    </Grid>
                                    {((onEdit && (migration === "eBenefits Network" || migration === "Everything Benefits")) ||
                                        (!onEdit && (connectivityReq?.MigrationConnectivityRequest === "Everything Benefits" || connectivityReq?.MigrationConnectivityRequest === "eBenefits Network"))) && (
                                            <>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <h6 className="form-title">Migration Start Date</h6>
                                                    {onEdit ? (
                                                        <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px" }}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    className="w-100"
                                                                    autoOk={true}
                                                                    disableToolbar
                                                                    variant="inline"
                                                                    format="MM/dd/yyyy"
                                                                    margin="normal"
                                                                    id="migrationStart-date"
                                                                    value={migrationStartDate}
                                                                    onChange={handleMigrationStartDateChange}
                                                                    keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                                    </svg>}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </div>
                                                    ) : (
                                                        <div className="textfield-div">
                                                            {connectivityReq?.MigrationStartDate ? Moment(connectivityReq?.MigrationStartDate).format('MM/DD/YYYY') : null}
                                                            <div className="ml-auto">
                                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="mt-3 text-danger" hidden={!requestSubmitted}>{selectMigrationStarDateValidationForm}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <h6 className="form-title">Planned First Production Date</h6>
                                                    {onEdit ? (
                                                        <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px" }}>
                                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                <KeyboardDatePicker
                                                                    className="w-100"
                                                                    autoOk={true}
                                                                    disableToolbar
                                                                    variant="inline"
                                                                    format="MM/dd/yyyy"
                                                                    margin="normal"
                                                                    id="migrationPlannedStart-date"
                                                                    value={plannedFirstProductionDate}
                                                                    onChange={handlePlannedFirstProductionDateChange}
                                                                    keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                                    </svg>}
                                                                />
                                                            </MuiPickersUtilsProvider>
                                                        </div>
                                                    ) : (
                                                        <div className="textfield-div">
                                                            {connectivityReq?.PlannedFirstProductionDate ? Moment(connectivityReq?.PlannedFirstProductionDate).format('MM/DD/YYYY') : null}
                                                            <div className="ml-auto">
                                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="col-xl-10 mt-3 text-danger" hidden={!requestSubmitted}>{selectPlannedFirstProductionDateValidationForm}</div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <h6 className="form-title">Migration Phase</h6>
                                                    {onEdit ? (
                                                        <FormControl label="Select migration phase" focused={false} variant="outlined" className="w-100">
                                                            <Select
                                                                className="text-dropdown"
                                                                value={migrationPhase ?? ""}
                                                                onChange={e => setMigrationPhase(e.target.value)}
                                                            >
                                                                {migrationPhasesList && migrationPhasesList?.map((c, ci) => (
                                                                    <MenuItem key={`migration-phase-${ci}`} value={c.Id}>{c.Name}</MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>) : (
                                                        <div className="textfield-div">
                                                            {connectivityReq?.MigrationPhaseName}
                                                        </div>
                                                    )}
                                                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{selectMigrationPhaseValidationForm}</div>
                                                </Grid>
                                            </>
                                        )}
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Last Modified Date</h6>
                                        <div className="textfield-div">
                                            {connectivityReq?.lastModifiedDate ? Moment(connectivityReq?.lastModifiedDate).format('MM/DD/YYYY') : null}
                                            <div className="ml-auto">
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Last Modified By</h6>
                                        <div className="textfield-div">
                                            {connectivityReq?.lastModifiedBy}
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <h6 className="form-title">Carrier Plans</h6>
                                        {onEdit || onEditPlans ? (
                                            <div className="row plans-list-div container-div p-4 checkbox-list">
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <Checkbox
                                                            id={`plans-option-select-all`}
                                                            value={planTypesList}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPlans && selectedPlans.length === planTypesList.length && !selectedPlans.includes("Other")}
                                                            onChange={handleSelectAllPlanChange}
                                                            onClick={handleSelectAllPlanClick(planTypesList.map(s => s.PlanTypeID))}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    }
                                                    label="Select All"
                                                />
                                                <br />
                                                {planTypesList.map((p, pi) => (
                                                    <React.Fragment key={`plan-type-${pi}`}>
                                                        <FormControlLabel
                                                            aria-label="Acknowledge"
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={
                                                                <Checkbox
                                                                    id={`plan-option-${pi}`}
                                                                    value={p}
                                                                    checked={selectedPlans && selectedPlans.indexOf(p.PlanTypeID) >= 0}
                                                                    onChange={handlePlanChange}
                                                                    onClick={handlePlanClick(p.PlanTypeID)}
                                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                                    checkedIcon={<DoneOutlineIcon />}
                                                                />}
                                                            label={p.PlanTypeName}
                                                        />
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <Checkbox
                                                            id={`plan-option-other`}
                                                            value="Other"
                                                            checked={otherChecked}
                                                            onChange={handleOtherPlanChange}
                                                            onClick={handlePlanClick("Other")}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                        />}
                                                    label="Other"
                                                />
                                                <br />
                                                {otherChecked && (
                                                    <textarea
                                                        placeholder="Please enter plan types separated by new lines."
                                                        className="pt-2 pb-2 text-input w-100"
                                                        value={otherPlanTypes ?? []}
                                                        rows={3}
                                                        onChange={handleOtherPlanTypesChange}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div className="textfield-div no-height">
                                                {connectivityReq?.PlanTypes?.map(plan => plan.PlanTypeName).join(" , ")}
                                            </div>
                                        )}
                                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{selectPlanValidationForm}</div>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>


                        <AnalystConnectivityRequestContacts
                            connectivityReq={connectivityReq}
                            onEdit={onEdit}
                            setConnectivityReq={setConnectivityReq}
                            selectedPlans={selectedPlans}
                            otherPlanTypes={otherPlanTypes}
                            carrierContactTypes={carrierContactTypes}
                        />

                        <ConnectivityRequestFilesAttachments
                            connectivityReq={connectivityReq}
                            value={filesTabValue}
                            setValue={setFilesTabValue}
                            onUploadedFilesArray={f => setUploadedFilesArray(f)}
                            filesList={filesList}
                            selectedPlans={selectedPlans}
                            otherPlanTypes={otherPlanTypes}
                        />

                        {connectivityReq?.ConnectivityRequestType === "Forms" && (
                            <ConnectivityRequestFormsAttachments
                                connectivityReq={connectivityReq}
                                value={formsTabValue}
                                setValue={setFormsTabValue}
                                onUploadedFormsArray={f => setUploadedFormsArray(f)}
                                formsList={formsList}
                                selectedPlans={selectedPlans}
                                otherPlanTypes={otherPlanTypes}
                                requestSubmitted={requestSubmitted}
                            />
                        )}

                        {(onEdit || filesTabValue === 1 || formsTabValue === 1) && (
                            <div className="row w-100 mt-4" style={{ justifyContent: "flex-end" }}>
                                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => { setOnEdit(false); setFilesTabValue(0); setFormsTabValue(0); }}>Cancel</Button>
                                <CustomTooltip TransitionComponent={Zoom} title={(uploadedFilesArray.length <= 0 && uploadedFormsArray.length <= 0) && onEdit === false ? "Nothing changed to submit" : ""}>
                                    <span>
                                        <Button type="submit" disabled={(uploadedFilesArray.length <= 0 && uploadedFormsArray.length <= 0) && onEdit === false} variant="contained" className="blue-btn" onClick={() => setRequestSubmitted(true)}>Submit</Button>
                                    </span>
                                </CustomTooltip>
                            </div>
                        )}
                        {onEditPlans && (
                            <div className="col-xl-12" style={{ textAlign: "end", marginTop: "15px" }}>
                                <Button onClick={updatePlans} variant="contained" className="blue-btn">
                                    <label style={{ cursor: "pointer" }} className="mb-0">Submit</label>
                                </Button>
                            </div >
                        )}

                        <AnalystConnectivityRequestComments
                            connectivityReq={connectivityReq}
                        />

                    </form >
                </Paper >
            )
            }

            {
                tabValue === 1 && (
                    <AnalystConnectivityRequestActionItems />
                )
            }

            {
                tabValue === 2 && (
                    <AnalystConnectivityRequestOEList />
                )
            }
        </div >
    )
}

export default AnalystConnectivityRequestConfig;
