import React from "react";
import { Button, Divider, Paper, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import ClearIcon from "@material-ui/icons/Clear";
import * as APIs from "../../../Data/APIs";
// import AnalystConnectivityRequestSingleContactRow from "./AnalystConnectivityRequestSingleContactRow";
import { useLoader } from "../../Layout/Loader";
import { useAuthentication } from "../../../Data/Authentication";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import plusIconSVG from "../../../Icons/PlusIconWhite.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import saveIconSVG from "../../../Icons/save.svg";

import ViewGroupRequestClientContactsRow, { emailPattern } from "./ViewGroupRequestClientContactsRow";

function ViewGroupReqestClientContacts({ groupReq, setGroupReq, addContactClicked, setAddContactClicked, ...props }) {
  const { authUser } = useAuthentication();

  // const [addContactClicked, setAddContactClicked] = React.useState(false);
  const [inputFNValue, setInputFNValue] = React.useState("");
  const [inputLNValue, setInputLNValue] = React.useState("");
  const [inputEMValue, setInputEMValue] = React.useState("");
  const [inputRecieveNotificationValue, setInputRecieveNotificationValue] = React.useState(false);

  const [inputSaved, setInputSaved] = React.useState(false);
  const [saveButtonClicked, setSaveButtonClicked] = React.useState(false);

  const { openLoader, closeLoader } = useLoader();
  const [, forceUpdate] = React.useReducer((x) => !x, false);

  // validations
  const [firstNameValidation, setFirstNameValidation] = React.useState('');
  const [lastNameValidation, setLastNameValidation] = React.useState('');
  const [emailValidation, setEmailValidation] = React.useState('');

  // Validation UseEffects
  // First Name Validation
  React.useEffect(() => {
    if (!inputFNValue) setFirstNameValidation("This Field is Required");
    else setFirstNameValidation("");
  }, [inputFNValue, firstNameValidation])

  // Last Name Validation
  React.useEffect(() => {
    if (!inputLNValue) setLastNameValidation("This Field is Required");
    else setLastNameValidation("");
  }, [inputLNValue, lastNameValidation])

  // Email Validation
  React.useEffect(() => {
    if (!inputEMValue) {
      setEmailValidation("This Field is Required");
    } else if (!emailPattern.test(inputEMValue)) {
      setEmailValidation("Invalid Email Format");
    }
    else setEmailValidation("");
  }, [inputEMValue, emailValidation])
  // // Validation UseEffects

  React.useEffect(() => {
    if (!groupReq) return;
    groupReq.GroupRequestContacts = groupReq.GroupRequestContacts ?? [];
  }, [groupReq, JSON.stringify(groupReq?.GroupRequestContacts)]);

  const textValidatorCheck = React.useRef();

  const handleChangeInputFNValue = (e) => {
    setInputFNValue(e.target.value);
  };

  const handleChangeInputLNValue = (e) => {
    setInputLNValue(e.target.value);
  };

  const handleChangeInputEMValue = (e) => {
    setInputEMValue(e.target.value);
  };
  const handleInputRecieveNotificationValueChange = (e) => {
    setInputRecieveNotificationValue(prev => !prev);
  };

  const handleSaveAllInputs = () => {
    setSaveButtonClicked(true)
    if (firstNameValidation || lastNameValidation || emailValidation) return;
    openLoader();
    setAddContactClicked(false);
    setInputSaved(true);
    const newContact = {
      GroupRequestContactFirstName: inputFNValue,
      GroupRequestContactLastName: inputLNValue,
      GroupRequestContactEmail: inputEMValue,
      ReceiveNotifications: inputRecieveNotificationValue,
      isDelete: false,
    };

    groupReq.GroupRequestContacts = [
      ...(groupReq.GroupRequestContacts ?? []),
      newContact,
    ];

    let updatedContacts = {};

    updatedContacts = {
      GroupRequestID: groupReq?.groupRequest?.GroupRequestID,
      ClientsContacts: groupReq.GroupRequestContacts,
    };

    APIs.updateGroupRequestContacts(updatedContacts).then((r) => {
      APIs.getGroupRequestData(groupReq?.groupRequest?.GroupRequestID).then(
        (s) => {
          setGroupReq({
            groupRequest: s?.data?.GroupRequest,
            GroupRequestContacts: s?.data?.GroupRequestContacts,
            connectivities: s?.data?.connectivities,
          });
          setAddContactClicked(false);
          setInputFNValue("");
          setInputLNValue("");
          setInputEMValue("");
          setSaveButtonClicked(false)
          closeLoader();
        }
      );
    });
  };

  return (
    <>
      <div className="row table-header">
        <div className="col-3 table-cell first-cell">First Name</div>
        <div className="col-3 table-cell">Last Name</div>
        <div className="col-2 table-cell">Email</div>
        <div className="col-2 table-cell">Recieve Notifications</div>
        <div className="col-2 table-cell">Actions</div>
      </div>

      {groupReq?.GroupRequestContacts?.length > 0 ? (
        groupReq?.GroupRequestContacts?.map((c, ci) => (
          <div className="" key={`single-contact-row-${ci}`}>
            <ViewGroupRequestClientContactsRow
              contact={c}
              index={ci}
              groupReq={groupReq}
              setGroupReq={setGroupReq}
              parentForceUpdate={forceUpdate}
            />
          </div>
        ))
      ) : // if Add Contact Clicked and there is no contacts
        !addContactClicked ? (
          <div className="no-data-alert">
            <h6>No Contacts Added!</h6>
          </div>
        ) : (
          <></>
        )}

      {addContactClicked && (
        <div className="row table-row">
          <div className="col-3 table-cell first-cell">
            <div className="mt-2 d-flex flex-column align-items-center">
              <TextField
                style={{ width: "80%" }}
                variant="outlined"
                value={inputFNValue}
                onChange={handleChangeInputFNValue}
                placeholder="Write first name here..."
              />
              {saveButtonClicked && firstNameValidation &&
                <span className="text-danger">
                  {firstNameValidation}
                </span>
              }
            </div>
          </div>
          <div className="col-3 table-cell">
            <div className="mt-2 d-flex flex-column  align-items-center">
              <TextField
                style={{ width: "80%" }}
                variant="outlined"
                value={inputLNValue}
                onChange={handleChangeInputLNValue}
                placeholder="Write last name here..."
              />
              {saveButtonClicked && lastNameValidation &&
                <span className="text-danger">
                  {lastNameValidation}
                </span>
              }
            </div>
          </div>
          <div className="col-2 table-cell">
            <div className="mt-2 d-flex flex-column align-items-center">
              <TextField
                type="email"
                style={{ width: "80%" }}
                variant="outlined"
                value={inputEMValue}
                onChange={handleChangeInputEMValue}
                placeholder="Write email here..."
              />
              {saveButtonClicked && emailValidation &&
                <span className="text-danger">
                  {emailValidation}
                </span>
              }
            </div>
          </div>
          <div className="col-2 table-cell">
            <div className="mt-2 d-flex flex-column align-items-center">
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon />}
                value={inputRecieveNotificationValue}
                onChange={handleInputRecieveNotificationValueChange}
                checkedIcon={<DoneOutlineIcon />}
                color="default"
              />
            </div>
          </div>
          <div className="col-2 table-cell">
            <Button
              className="icon-btn"
              color="inherit"
              onClick={handleSaveAllInputs}
            >
              <svg width="15" height="21" viewBox="0 0 22 21" >
                <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
              </svg>
            </Button>
            <CustomTooltip TransitionComponent={Zoom} title="Cancel">
              <Button
                className="icon-btn"
                color="inherit"
                onClick={() => {
                  setAddContactClicked(false);
                  setSaveButtonClicked(false);
                  setInputFNValue("");
                  setInputLNValue("");
                  setInputEMValue("");
                }}
              >
                <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                  <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                </svg>
              </Button>
            </CustomTooltip>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewGroupReqestClientContacts;
