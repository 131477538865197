import React, { useEffect } from "react";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Button, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import checkboxIcon from "../../../Icons/Checkbox.svg";
import deleteForeverSVG from "../../../Icons/trash-xmark.svg";

function ClientContactRow({
    contact,
    index,
    fullContacts,
    parentForceUpdate,
    ...props
}) {

    return (

        <div className="row table-row">
            <div className="col-3 table-cell first-cell">
                <input required type="text" className="text-input w-100" placeholder="Write first name here..."
                    value={contact.GroupRequestContactFirstName ?? ""}
                    onChange={e => {
                        contact.GroupRequestContactFirstName = e.target.value;
                        parentForceUpdate();
                    }} />
            </div>
            <div className="col-3 table-cell">
                <input required type="text" className="text-input w-100" placeholder="Write last name here..."
                    value={contact.GroupRequestContactLastName ?? ""}
                    onChange={e => {
                        contact.GroupRequestContactLastName = e.target.value;
                        parentForceUpdate();
                    }} />
            </div>
            <div className="col-3 table-cell">
                <input required type="email" autoComplete='new-password' placeholder="Write email here..." className="text-input w-100"
                    value={contact.GroupRequestContactEmail ?? ""}
                    onChange={e => {
                        contact.GroupRequestContactEmail = e.target.value;
                        parentForceUpdate();
                    }} />
            </div>
            <div className="col-2 table-cell">
                <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    value={contact.ReceiveNotifications}
                    onChange={
                        (e) => {
                            contact.ReceiveNotifications = !contact.ReceiveNotifications;
                            parentForceUpdate()
                        }
                    }
                    checkedIcon={<DoneOutlineIcon />}
                    className="mx-auto"
                    color="default"
                />
            </div>
            <div className="col-1 table-cell" style={{ justifyContent: "center" }}>
                <Button className="icon-btn" color="inherit" onClick={e => {
                    fullContacts && fullContacts?.splice(index, 1);
                    parentForceUpdate();
                }}>
                    {/* <DeleteForeverIcon style={{ fontSize: 30 }} /> */}
                    <svg  width="21" height="24" viewBox="0 0 21 24" >
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                </Button>
            </div>
        </div>

    )
}

export default ClientContactRow;
