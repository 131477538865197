import React, { useEffect } from 'react'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Button, DialogActions } from '@mui/material';
import * as Apis from '../../Data/APIs'
import { useLoader } from '../Layout/Loader';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import moment from 'moment';
import { toESTTime } from '../EmployeeViews/EmployeeDashboardPendingFormsTab';
// import { FailureModal } from '../Modals/FailureModal';
import deleteForeverSVG from "../../Icons/trash-xmark.svg";

const UploadWetSignatureInput = ({ form, withSelfFlag, dialog, HandleSignCore, ...props }) => {
    const fileInputRef = React.useRef();
    const [fileAttached, setFileAttached] = React.useState("");
    const [fileAttachedBase64, setFileAttachedBase64] = React.useState();
    const [fileValidation, setFileValidation] = React.useState("");
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [uploadFileError, setUploadFileError] = React.useState("");
    const { openLoader, closeLoader } = useLoader()

    const handleFileAttachedChange = (files) => {
        if (files[0]?.size === 0) {
            setFileValidation('File is empty!')
        } else {
            setFileAttached(files[0]);
            encodeFileAttachedBase64(files[0]);
        }
    }

    const encodeFileAttachedBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result.split(',')[1];
                setFileAttachedBase64(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };

    const handleUploadWetSignatureForm = () => {
        setIsSubmitted(true)
        if (fileValidation) return
        let fileFormBody = {
            empKey: form?.empKey,
            SK: form?.SK,
            feedID: form?.feedID,
            executionID: form?.executionID,

            fileName: fileAttached?.name?.substring(0, fileAttached?.name.lastIndexOf('.')) ?? "",
            Extension: fileAttached?.name?.substring(fileAttached?.name.lastIndexOf('.') + 1, fileAttached?.name.length) ?? "",
            Value: fileAttachedBase64 ?? ""
        }

        // HandleSignCore(form);
        openLoader()
        Apis.uploadWetSintureEndpoint(fileFormBody).then(r => {

            if (r.success === "1") {
                HandleSignCore(form, withSelfFlag);
            } else {
                setUploadFileError(r?.msg);

                // After 30 seconds, perform second setState
                const timer = setTimeout(() => {
                    setUploadFileError();
                }, 30000);

                // Cleanup the timer to avoid memory leaks
                closeLoader();
                return () => clearTimeout(timer);
            }
        }).catch(err => {
            console.log('error:', err);
            closeLoader()
        }).finally(() => { })
    }

    const OutputFormFileName = () => {
        return form?.empName + " - " +
            form?.carrierName + " - " +
            (form?.formGenerationTime ? moment(toESTTime(form.formGenerationTime)).format('MM-DD-YYYY') : "N/A")
    }


    const validateFile = () => {
        // required
        if (!fileAttached || fileAttached == "" || fileAttached == undefined) {
            setFileValidation('File Is Required')
        }
        // pdf extension
        else if (fileAttached.type !== "application/pdf") {
            setFileValidation('File Should be PDF')
        }
        // same name with downloaded one
        else if (!(fileAttached?.path?.includes(OutputFormFileName()))) {
            setFileValidation(`File Name Should be ${OutputFormFileName()}.pdf`)
        }
        // file size
        else if (fileAttached?.size > (20 * 1000 * 1000)) {
            setFileValidation(`Max File Size is 20MBs`)
        }
        // clear
        else {
            setFileValidation(``)
        }
    }


    useEffect(() => {
        validateFile();
    }, [fileAttached])

    return (
        <div className='row'>

            <div className="alert alert-warning w-100" role="alert">
                <p className="mb-0">
                    Please make sure you are uploading the full form with the same name
                </p>
            </div>
            <DropzoneAreaBase
                dropzoneParagraphClass={"MuiDropzoneArea-text-updated-grayBlack"}
                filesLimit={1}
                dropzoneClass={"containerClassDrag"}
                dropzoneText={<div className="dz-message" data-dz-message>Click Here to add form <b>(Supported Format: PDF Only)</b></div>}
                onDrop={(e) => handleFileAttachedChange(e)}
                acceptedFiles={["application/pdf", ".pdf"]}
                showPreviewsInDropzone={false}
                showAlerts={false}
            />
            {fileAttached &&
                <>
                    <div className="col-9 table-cell first-cell">
                        {fileAttached.name}
                    </div>
                    <div className="col-3 table-cell">
                        <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); }} style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                        {/* <DeleteForeverIcon
                            onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); }}
                            color="action" style={{ fontSize: "30px", cursor: "pointer", justifySelf: "center" }} /> */}
                    </div>
                </>
            }
            {isSubmitted &&
                <div className="col-11 col-offset-1">
                    <div className="mt-2 text-danger fs-14">{fileValidation}</div>
                </div>
            }
            {uploadFileError &&
                <div className="col-11 col-offset-1">
                    <div className="mt-2 text-danger fs-14">{uploadFileError}</div>
                </div>
            }
            <DialogActions>
                {<button type="button" className="blue-btn text-uppercase" onClick={handleUploadWetSignatureForm}>Save</button>}
                <Button type="button" className="blue-outlined-btn" onClick={() => dialog.closeDialog()}>close</Button>
            </DialogActions>
        </div>
    )
}

export default UploadWetSignatureInput
