import React, { useEffect } from "react";
import { Divider, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Button, TextField } from "@material-ui/core";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import Moment from 'moment';
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import MomentTZ from 'moment-timezone';
import { Autocomplete } from "@mui/material";

export function toESTTime(timeString, format = undefined) {
    const localTime = MomentTZ.utc(timeString).toDate();
    return MomentTZ(localTime).tz("America/New_York").format(format ?? "YYYY-MM-DD HH:mm:ss")
}

function SetLastSentFileModalForms({ feed, ...props }) {

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [availableEmployees, setAvailableEmployees] = React.useState([]);
    const [selectedEmployee, setSelectedEmployee] = React.useState({});
    const [employeeFullNameAutoCompleteInput, setEmployeeFullNameAutoCompleteInput] = React.useState("");

    const [availableDatesList, setAvailableDatesList] = React.useState([]);
    const [lastSentFileDate, setLastSentFileDate] = React.useState("");
    const [filteredLSFObject, setFilteredLSFObject] = React.useState();

    const handleLastSentFileChange = (e) => {
        setLastSentFileDate(e.target.value);
    };

    const getFeedAvailableEmployees = () => {
        openLoader();
        const requestParameters = {};
        requestParameters.FeedID = feed?.FeedID + '';
        APIs.fetchEmployeesForFeed(requestParameters).then((r) => {
            setAvailableEmployees(r?.data);
            closeLoader();
        });
    }

    React.useEffect(() => {
        getFeedAvailableEmployees();
    }, [])

    const fetchDataSetForEmployeeHandler = () => {
        openLoader();
        const requestParameters = {
            PK: selectedEmployee?.PK + "",
            SK: selectedEmployee?.SK + ""
        };

        APIs.fetchDatasetsForEmployeeForFeed(requestParameters).then((r) => {
            setAvailableDatesList(r?.data);
            closeLoader();
        });
    }

    useEffect(() => {
        if (Object.keys(selectedEmployee ?? {}).length > 0) {
            fetchDataSetForEmployeeHandler()
            setEmployeeFullNameAutoCompleteInput(selectedEmployee?.firstName + " " + selectedEmployee?.lastName)
        } else {
            setEmployeeFullNameAutoCompleteInput("");
            setAvailableDatesList([]);
            setLastSentFileDate("")
        }
    }, [selectedEmployee])


    React.useEffect(() => {
        setFilteredLSFObject(...availableDatesList.filter(d => d.datestamp === lastSentFileDate));
    }, [lastSentFileDate])

    const updateLastSentFile = () => {
        dialog.closeDialog();
        openLoader();
        const lastSentFileBody = {
            ExecutionID: filteredLSFObject.ExecutionID,
            PK: filteredLSFObject.PK,
            SK: filteredLSFObject.SK
        }
        APIs.setLastSentFileForEmployees(lastSentFileBody).then(r => {
            if (r?.success == 1)
                window.location.reload()
        });
    }

    return (
        <div id="last-sent-file-modal" style={{minWidth: "600px"}}>
            {/* <h5 className="black1 fw-700">Last Sent File Forms</h5> */}
            {/* <Divider /> */}
            <div className="w-100">
                <span>Employee Name:</span>
                <br />
                <Autocomplete
                    options={availableEmployees}
                    autoHighlight
                    className="search-dropdown w-100"
                    style={{ maxWidth: '100%' }}
                    inputValue={employeeFullNameAutoCompleteInput ?? ""}
                    onChange={(e, value) => {
                        setSelectedEmployee(value)

                    }}

                    getOptionLabel={(option) => option?.firstName + " " + option?.lastName}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            onChange={e => {
                                setEmployeeFullNameAutoCompleteInput(e.target.value)
                            }}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>

            <Paper elevation={0} className="mt-3 p-5">
                <>
                    {availableDatesList?.length > 0 && availableDatesList?.map((a, ai) => (
                        <FormControl key={`last-sent-file-${ai}`} component="fieldset">
                            <RadioGroup aria-label="date" name="dates" value={lastSentFileDate} onChange={handleLastSentFileChange}>
                                <FormControlLabel value={a.datestamp} control={<Radio />} label={toESTTime(Moment(a.datestamp).format('MM/DD/YYYY HH:mm:ss'))} />
                            </RadioGroup>
                        </FormControl>
                    ))}
                </>
            </Paper>
                <div className="row w-100 mt-5" style={{ justifyContent: "flex-end" }}>
                    <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => dialog.closeDialog()}>Cancel</Button>
                    <Button variant="contained" className="blue-btn" disabled={!lastSentFileDate} onClick={updateLastSentFile}>Confirm</Button>
                </div>
        </div>
    )
}

export default SetLastSentFileModalForms;