import React from "react"
import { Divider, Paper, Button } from "@material-ui/core"
import Moment from "moment"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import dateTimeSVG from "../../../../Images/dateTime.svg"
import DateFnsUtils from "@date-io/date-fns"
import { useModalDialog } from "../../../Layout/ModalDialogCustomized"
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";


const todayDate = new Date()
todayDate.setHours(0, 0, 0, 0)

function CloseActionModal({ actionItem, contact, onsetCompletionDate, onsetActionItemClosureReason, onSetStatus, onsetStatusDialogSubmit, ...props }) {

  const dialog = useModalDialog(0)

  const [actionItemClosureReason, setActionItemClosureReason] = React.useState("")
  const [completionDate, setCompletionDate] = React.useState(todayDate)

  const [requestSubmitted, setRequestSubmitted] = React.useState(false)

  // validation
  const [selectCompletionDateValidation, setSelectCompletionDateValidation] = React.useState("")
  const [selectClosureReasonValidation, setSelectClosureReasonValidation] = React.useState("")

  const handleCompletionDateChange = date => {
    setCompletionDate(Moment(date).format("YYYY-MM-DD"))
    onsetCompletionDate(Moment(date).format("YYYY-MM-DD"))
  }

  React.useEffect(() => {
    if (!completionDate || completionDate === "Invalid date") setSelectCompletionDateValidation("Completion date cannot be empty if the action item is closed"); else setSelectCompletionDateValidation("")
  }, [completionDate, selectCompletionDateValidation])

  React.useEffect(() => {
    if (!actionItemClosureReason?.replaceAll(" ", "").replaceAll("\n", "")) setSelectClosureReasonValidation("Closure reason cannot be empty if the action item is closed"); else setSelectClosureReasonValidation("")
  }, [actionItemClosureReason, selectClosureReasonValidation])

  const updateActionStatus = () => {
    setRequestSubmitted(true)
    if (selectCompletionDateValidation || selectClosureReasonValidation) return
    onsetStatusDialogSubmit(true)
    dialog.closeDialog()
  }

  return (
    <div id="last-sent-file-modal" style={{ width: "900px" }}>
      {/* <h5 className="black1 fw-700">Close Action Item</h5> */}
      {/* <Divider /> */}
      <Paper elevation={0} className="mt-3 p-3 ">
        <div className="container">
          <div className="row">
            <div className="col-xl-2 mt-3 alignvh-center">
              <h6 className="form-title" style={{ fontFamily: 'Manrope' }}>Closure Reason</h6>
              <span className="text-danger mb-2 ml-2">*</span>
            </div>
            <div className="col-xl-10 mt-3">
              <textarea
                type="text"
                className="action-item-textarea w-100"
                style={{ fontFamily: 'Manrope', fontSize: "12px" }}
                value={actionItemClosureReason}
                onChange={e => {
                  setActionItemClosureReason(e.target.value)
                  onsetActionItemClosureReason(e.target.value)
                  onSetStatus(2)
                }}
                rows={4}
              />
            </div>
            <div className="col-xl-2  mt-2 alignvh-center" hidden={!requestSubmitted}></div>
            <div className="col-xl-10 mt-2 text-danger" style={{ fontSize: "13px", fontFamily: 'Manrope', }} hidden={!requestSubmitted}>
              {selectClosureReasonValidation}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-2 mt-3 alignvh-center">
              <h6 className="form-title" style={{ fontFamily: 'Manrope' }}>Completion Date</h6>
              <span className="text-danger mb-2 ml-2">*</span>
            </div>
            <div className="col-xl-10 mt-3">
              <div className="text-input w-100" style={{ padding: "0px 8px 0px 12px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="w-100 mt-0 mb-0"
                    autoOk={true}
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="frequency-date"
                    value={completionDate}
                    onChange={handleCompletionDateChange}
                    keyboardIcon={
                      <svg  width="14" height="16" viewBox="0 0 14 16">
                          <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                      </svg>
                    }
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="col-xl-2 mt-3 alignvh-center" hidden={!requestSubmitted}></div>
            <div className="col-xl-10 mt-3 text-danger" style={{ fontSize: "13px", fontFamily: 'Manrope', }} hidden={!requestSubmitted}>
              {selectCompletionDateValidation}
            </div>
          </div>
        </div>
      </Paper>
        <div className="row w-100 mt-5 mb-3" style={{ justifyContent: "flex-end" }}>
          <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => dialog.closeDialog()}>
            Cancel
          </Button>
          <Button variant="contained" className="blue-btn" onClick={updateActionStatus}>
            Confirm
          </Button>
        </div>
    </div>
  )
}

export default CloseActionModal
