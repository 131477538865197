import React from "react";
import AnalystFeedFormRecord from "./AnalystFeedFormRecord";

function AnalystFeedForms({ feed, onMatchingAllPlansValidation, ...props }) {
    return (
        <div id="feed-forms">
            <div className="row table-header">
                <div className="col-1 table-cell first-cell">Digital Form ID</div>
                <div className="col-2 table-cell">Digital Form</div>
                <div className="col-2 table-cell">Main Types</div>
                <div className="col-2 table-cell">Sub Types</div>
                <div className="col-1 table-cell">Matched Plans</div>
                <div className="col-1 table-cell">Map</div>
                <div className="col-1 table-cell">Digitized By</div>
                <div className="col-1 table-cell">Created Date</div>
                <div className="col-1 table-cell last-cell">Actions</div>
            </div>

            {feed?.DigitalForms?.length > 0 ? (
                feed?.DigitalForms?.map((f, fi) => (
                    <AnalystFeedFormRecord
                        form={f}
                        index={fi}
                        feed={feed}
                        key={`DF-${fi}`}
                        onMatchingAllPlansValidation={onMatchingAllPlansValidation}
                    />
                ))
            ) : (
                <div className="files-list text-center mt-3">
                    <h6 className="color grey2">No forms were attached to this feed</h6>
                </div>
            )}

        </div>
    )
}

export default AnalystFeedForms;