import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

export const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        color: "white",
        backgroundColor: "#717171",
        opacity: "1",
        fontSize: "11px",
        whiteSpace: "break-spaces",
    },
}))(Tooltip);