import React from 'react';
import DataTable from 'react-data-table-component';
import PropTypes from 'prop-types';
import { CustomTooltip } from './Tooltip';

const GenericTable = ({
    columns,
    data,
    onSort,
    actions,
    isStickeyHeader,
    expandableRowsComponent,
    expandedRows,
    handleRowExpandToggle,
    expandableRowExpanded,
    isExpandable = false,
    evenAndOddStyles = true,
    centerText = false, // New prop for centering text
    ...props
}) => {
    // Add rowIndex to each row for styling purposes
    const processedData = data.length > 0 ?
        data.map((item, index) => ({ ...item, rowIndex: index })) : [];

    // Prepare conditional styles for column widths
    const conditionalCellStyles = columns.map(column => ({
        when: () => true,
        style: {
            padding: '0px 5px',
            minWidth: column.width || 'auto',
            // alignItems: 'center',
            width: centerText ? '100%' : 'auto', // Apply text alignment based on centerText prop
            // justifyContent: 'center',
            textAlign: centerText ? 'center' : 'left', // Apply text alignment based on centerText prop
        },
    }));

    const conditionalRowStyles = [
        {
            when: row => row.rowIndex % 2 === 0,
            classNames: ['rdt-even-row'],
        },
        {
            when: row => row.rowIndex % 2 !== 0,
            classNames: ['rdt-odd-row'],
        },
    ];

    // Enhance columns with tooltip for header
    const enhancedColumns = columns.map(column => ({
        ...column,
        name: (
            column?.doesNotHaveToolTip ?
                <div style={{ width: column.centerText ? '100%' : '100%', textAlign: column.centerText ? 'center' : 'left', padding: '5px 0' }}>{column?.name}</div> :
                <CustomTooltip title={column?.tooltip || column?.name} arrow>
                    <div style={{ width: column.centerText ? '100%' : '100%', textAlign: column.centerText ? 'center' : 'left', padding: '5px 0' }}>{column?.name}</div>
                </CustomTooltip>
        ),
        cell: (row, index) => {
            const cellContent = column.selector ? column.selector(row, index) : row[column.selector];
            return (
                <div style={{
                    textAlign: column.centerText ? 'center' : 'left',
                    width: column.centerText ? '100%' : '100%',
                    padding: '5px 0'
                }}>
                    {cellContent}
                </div>
            );
        },
    }));

    return (
        <div className="table-container">
            <DataTable
                columns={enhancedColumns}
                data={processedData}
                onSort={onSort}
                sortServer

                expandableRows={isExpandable}
                expandableRowsComponent={expandableRowsComponent}
                expandableRowExpanded={expandableRowExpanded} // Pass the function to check if the row is expanded
                onRowExpandToggled={(expanded, row) => handleRowExpandToggle(row)} // Handle row expand toggle

                actions={actions}
                noDataComponent={
                    <div className="no-data-alert">
                        <h6>No Results Found!</h6>
                        <h6>Please select correct search criteria.</h6>
                    </div>
                }
                fixedHeader={isStickeyHeader}
                persistTableHead
                conditionalRowStyles={evenAndOddStyles && conditionalRowStyles}
                conditionalCellStyles={conditionalCellStyles} // Apply conditional cell styles for widths
                {...props}
            />
        </div>
    );
};

GenericTable.propTypes = {
    columns: PropTypes.array.isRequired,
    // data: PropTypes.array.isRequired,
    onSort: PropTypes.func.isRequired,
    actions: PropTypes.element,
    isStickeyHeader: PropTypes.bool,
    expandableRowsComponent: PropTypes.func,
    expandedRows: PropTypes.object,
    handleRowExpandToggle: PropTypes.func,
    expandableRowExpanded: PropTypes.func,
    centerText: PropTypes.bool, // Add PropTypes for centerText
};

export default GenericTable;
