import React, { useEffect, useState } from 'react'
import Moment from 'moment'
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from '../Layout/Pagination';
import * as Apis from '../../Data/APIs'
import { useLoader } from '../Layout/Loader';
import { toESTTime } from './EmployeeDashboardPendingFormsTab';
import { useModalDialog } from '../Layout/ModalDialogCustomized';
import { EmployeeForm } from './EmployeeSignFormModal';
import { createRef } from 'react';
import { Button } from "@material-ui/core";
import { useAuthentication } from '../../Data/Authentication';
import viewActionItemSVG from "../../Icons/viewActionItem.svg";
import GenericTable from '../Layout/GenericTable';


const EmployeeDashboardSignedFormsTab = () => {
    const { openLoader, closeLoader } = useLoader();
    const dialog2 = useModalDialog(2);

    const [signedForms, setSignedForms] = useState([]);
    const [lastEvaluatedKey, setlastEvaluatedKey] = useState(null);


    const signaturesRef = createRef();
    const formRef = createRef();
    const pdfRef = createRef();
    const { authUser } = useAuthentication();
    let userLegalID = authUser?.attributes["custom:LegalID"]
    let userAccountID = authUser?.attributes["custom:AccountID"]
    let userClientID = authUser?.attributes["custom:ClientID"]

    const handleViewFn = (form) =>
        dialog2.openDialog(
            <></>,
            <>
                {/* will submit rejection reason in this Component */}
                <EmployeeForm
                    form={form}
                    signaturesRef={signaturesRef}
                    formRef={formRef}
                    pdfRef={pdfRef}
                    canSign={false}
                    canEdit={false}
                    mainDialog={dialog2}
                />
            </>,
            [
                // { body: <a href={form?.OutputFile} target="_blank" className="btn black-btn text-uppercase">download</a>, onClick: () => { dialog2.closeDialog() } },
                // { body: <Button type="button" className="black-outlined-btn">close</Button>, onClick: () => { dialog2.closeDialog() } }
            ]

        )


    const getFormsHandler = () => {
        openLoader();
        let currentFeedInfo = {
            // 14 >> Signed Forms
            roleID: "10",
            lsi: '14',
            userClientID: userClientID ?? '',
            userAccountID: userAccountID ?? '',
            userLegalID: userLegalID ?? ''
        };
        if (lastEvaluatedKey) currentFeedInfo.LastEvaluatedKey = lastEvaluatedKey

        Apis.getEmployeeForms({ ...currentFeedInfo }).then(r => {
            // debugger
            if (r.msg == "Success") {
                setSignedForms([...signedForms, ...r?.data])
                setlastEvaluatedKey(r?.LastEvaluatedKey ? r?.LastEvaluatedKey : null)
            } else setSignedForms([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }

    useEffect(() => {
        getFormsHandler()
    }, [])
    // =================================================================NEW TABLE UI
    const baseColumnArray = [
        {
            name: 'Carrier',
            sortable: false,
            id: 'Carrier',
            selector: form => form.carrierName
        },
        {
            name: 'Form Type',
            sortable: false,
            id: 'Carrier',
            selector: form => form?.formType?.replace('Enrolment', 'Enrollment')
        },
        {
            name: 'Generation Timestamp',
            sortable: false,
            id: 'formGenerationTime',
            selector: form => form?.formGenerationTime ? Moment(toESTTime(form?.formGenerationTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"
        },
        {
            name: 'Signed Time',
            sortable: false,
            id: 'signedTime',
            selector: form => form?.signedTime ? Moment(toESTTime(form?.signedTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"
        },
        {
            name: 'Actions',
            sortable: false,
            id: 'Actions',
            selector: form =>
                <div className="d-flex my-1" role="button" onClick={() => handleViewFn(form)}>
                    <svg width="18" height="18" viewBox="0 0 18 18" >
                        <use xlinkHref={`${viewActionItemSVG}#viewAIIcon`}></use>
                    </svg>
                    <span className="ml-2">View</span>
                </div>
        },
    ]

    return (
        <div>
            <GenericTable
                columns={[...baseColumnArray]}
                data={signedForms || []}
                onSort={() => { }}
            />
            <div className="text-center mt-3">
                <Button className="load-more-btn blue-btn" disabled={lastEvaluatedKey == null} onClick={() => getFormsHandler()}>Load More Results</Button>
            </div>
        </div>
    )
}

export default EmployeeDashboardSignedFormsTab
