import React, { useState } from 'react';
import ClientContactRow from './ClientContactRow'
import plusIconSVG from "../../../Icons/PlusIconWhite.svg";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



const CreateGroupRequestClientContacts = ({
    clientContacts,
    handleAddClientContact,
    forceUpdate,
    expanded,
    ...props
}) => {
    return (
        <div id= "gr-client-contacts">
        <Accordion defaultExpanded  expanded={expanded}>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-contacts"
                >
                    <span>Client Contacts</span>
                    
                <div className="add-record-btn ml-auto" onClick={handleAddClientContact}>
                    <svg width="12" height="12" viewBox="0 0 12 12" className="mr-2">
                        <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                    </svg> ADD</div>
            
                </AccordionSummary>
                <AccordionDetails className="no-padding-accordion-details"></AccordionDetails>
            
            <div className="row table-header">
                <div className="col-3 table-cell first-cell">
                    First Name
                </div>
                <div className="col-3 table-cell">
                    Last Name
                </div>
                <div className="col-3 table-cell">
                    Email
                </div>
                <div className="col-2 table-cell">
                    Receive Notifications
                </div>
                <div className="col-1 table-cell">
                    Actions
                </div>
            </div>
            {(!clientContacts || clientContacts.length <= 0) && (
                <div className="no-data-alert">
                    <h6>No Contacts Added!</h6>
                </div>
            )}
            {clientContacts?.map((c, ci) => (<div key={`client-contact-row-${ci}`}>
                <ClientContactRow
                    contact={c}
                    index={ci}
                    fullContacts={clientContacts}
                    parentForceUpdate={forceUpdate}
                />
            </div>
            ))}
            </Accordion>
        </div>
    )
}

export default CreateGroupRequestClientContacts
