import React from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Divider, Button } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useAuthentication } from "../../../../Data/Authentication";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";

function RelationshipCodesAnalyst({ relationshipCodesSection, currentTimestamp, setIsNextActive, ...props }) {

    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const { authUser } = useAuthentication();

    const [connectivityReq, setConnectivityReq] = React.useState();
    const [customerRelationshipCodes, setCustomerRelationshipCodes] = React.useState([]);
    const [relationshipArray, setRelationshipsArray] = React.useState(relationshipCodesSection.Model ?? []);


    React.useEffect(() => {
        if (relationshipCodesSection) relationshipCodesSection.Model = relationshipArray;
    }, [relationshipArray]);

    const [, forceUpdate] = React.useReducer(x => !x, false);

    const [checkIncludeDependents, setCheckIncludeDependents] = React.useState(false);

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        if (connectivityReq) closeLoader();
        else openLoader();
    }, [connectivityReq])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupRelationshipCodes(connectivityReq?.ConnectivityRequestID).then((r) => {
            setCustomerRelationshipCodes(r?.data);
            closeLoader();
        });
    }, [connectivityReq])

    const handleCheckIncludeDependentsChange = () => {
        setCheckIncludeDependents(!checkIncludeDependents);
        relationshipCodesSection.IncludeDependents = (!checkIncludeDependents);

        if (relationshipCodesSection.IncludeDependents === false) relationshipArray.length = 0;
    };

    React.useEffect(() => {
        const carrierRelationshipCodeCondition = relationshipArray.map(s => s.CarrierRelationshipCode).filter(f => f === "");
        const commentsCondition = relationshipArray.map(s => s.Comment.Text).filter(f => f === "");
        if (!relationshipCodesSection.IncludeDependents || (relationshipCodesSection.IncludeDependents && relationshipArray.length > 0 && carrierRelationshipCodeCondition.length <= 0 && commentsCondition.length <= 0)) setIsNextActive(true); else setIsNextActive(false);
    }, [relationshipCodesSection.IncludeDependents, JSON.stringify(relationshipArray)])

    return (
        <div id="relationship-codes-section">
            <div>
                <div className="table-header">
                    <div className="table-cell first-cell">
                        Group Relationship Codes
                    </div>
                </div>
                <div style={{ maxHeight: "195px", overflow: "auto" }}>
                    {customerRelationshipCodes.map((c, ci) => (
                        <div key={`customer-relationship-codes-${ci}`} className="table-row">
                            <div className="table-cell first-cell">
                                {c.Name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="row mt-5 w-100 alignvh-center">
                <h5 className="black1 fw-700">Relationship Codes Section</h5>
                <div className="ml-auto">
                    <CustomTooltip TransitionComponent={Zoom} title={!relationshipCodesSection.IncludeDependents ? "You can add relationship codes if the file will include dependents" : ""}>
                        <span>
                            <Button disabled={!relationshipCodesSection.IncludeDependents} variant="contained" className="blue-btn" onClick={() => setRelationshipsArray([...relationshipArray, {
                                GUID: uuidv4(),
                                CarrierRelationshipCode: "",
                                Action: 1,
                                Comment: {
                                    Timestamp: currentTimestamp,
                                    Text: "",
                                    UserID: authUser?.attributes["sub"],
                                    Name: `${authUser?.attributes["custom:FirstName"]} ${authUser?.attributes["custom:LastName"]}`,
                                    Email: `${authUser?.attributes["email"]}`
                                }
                            }])}>
                                <label style={{ cursor: "pointer" }} className="mb-0">
                                <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                                            </svg>
                                     Add Relationship Code</label>
                            </Button>
                        </span>
                    </CustomTooltip>
                </div>
            </div>
            <Divider className="mt-3 mb-5" />

            {relationshipCodesSection.IncludeDependents && relationshipArray.length <= 0 && (
                <div className="no-data-alert">
                    <h6>Please start adding relationship codes</h6>
                </div>
            )}

            {relationshipCodesSection.IncludeDependents && relationshipArray.map((p, pi) => (
                <div key={`generic-${p.GUID}`} className="row mb-2">
                    <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Carrier Relationship Code</h6>
                        <span className="text-danger mb-2 ml-2 mr-4">*</span>
                        <input value={p.CarrierRelationshipCode ?? ""} onChange={e => { p.CarrierRelationshipCode = e.target.value; forceUpdate(); }} type="text" className="text-input w-100" />
                    </div>
                    <div className="col-xl-5 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Analyst Comment</h6>
                        <span className="text-danger mb-2 ml-2 mr-4">*</span>
                        <textarea value={p.Comment?.Text ?? ""} onChange={e => { p.Comment.Text = e.target.value; forceUpdate(); }} style={{ minWidth: "unset", width: "100%" }} rows={3} />
                    </div>
                    <div className="col-xl-1 alignvh-center">
                        <CustomTooltip TransitionComponent={Zoom} title={relationshipArray.length === 1 ? "There should be at least one matched relationship code" : ""}>
                            <span>
                                <Button className="icon-btn" disabled={relationshipArray.length === 1} onClick={e => { relationshipArray.splice(pi, 1); forceUpdate() }}>
                                    {/* <DeleteForeverIcon style={{ width: "30px", height: "30px" }} color="action" /> */}
                                    <svg  width="21" height="24" viewBox="0 0 21 24"   style={{ cursor: "pointer" }}>
                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                    </svg>
                                </Button>
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            ))}
            <div className="row alignvh-center mb-5 mt-5">
                <FormControlLabel
                    className="dependents-switch"
                    control={
                        <Switch
                            checked={relationshipCodesSection.IncludeDependents}
                            onChange={handleCheckIncludeDependentsChange}
                        />
                    }
                    label="Include Dependents?"
                />
            </div>
        </div>
    )
}

export default RelationshipCodesAnalyst;