

import React from "react";
import Truncate from 'react-truncate';
import Chip from '@mui/material/Chip';
function TruncatedChip({ body, ...props }) {
    const [expanded, setExpanded] = React.useState(false);
    const [truncated, setTruncated] = React.useState(false);
    const [lines, setLines] = React.useState(2);

    const handleTruncate = (truncat) => {
        if (truncated !== truncat) {
            setTruncated(
                truncat
            );
        }
    }

    const toggleLines = (e) => {
        e.preventDefault();
        setExpanded(
            !expanded
        );
    }
    return (
        <>
            <Truncate
                className="truncedChip mt-1 mr-2 mb-1 ml-2"
                lines={!expanded && lines}
                ellipsis={(
                    <span>... <a href='#' style={{ color: "#007bff" }} onClick={toggleLines}>Show more</a></span>
                )}
                onTruncate={handleTruncate}

            >
                {body}
                {!truncated && expanded && (
                    <span> ...<a href='#' style={{ color: "#007bff" }} onClick={toggleLines}>Show less</a></span>
                )}
            </Truncate>

        </>
    )



}
export default TruncatedChip;