import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { useAuthentication } from "../../Data/Authentication";
import { DropzoneArea } from 'material-ui-dropzone';
import ConnectivityRequestUploadedFormRecord from "./ConnectivityRequestUploadedFormRecord";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { FailureModal } from "../Modals/FailureModal";
import deleteForeverSVG from "../../Icons/trash-xmark.svg";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ConnectivityRequestFormsAttachments({ connectivityReq, value, setValue, onUploadedFormsArray, formsList, selectedPlans, otherPlanTypes, requestSubmitted, ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [uploadedForms, setUploadedForms] = React.useState([]);
    const [formAttached, setFormAttached] = React.useState();
    const [formAttachedBase64, setFormAttachedBase64] = React.useState();

    const [invalidFormatError, setInvalidFormatError] = React.useState("");

    const [tempGUID, setTempGUID] = React.useState("");


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const encodeFormAttachedBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result.split(',')[1];
                setFormAttachedBase64(Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }
    };


    const handleFileAttachedChangeDrag = async (files) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileAttachedBase64(files);
        }
    }

    const encodeFileAttachedBase64 = async (files) => {
        files.map((file) => {
            if (file) {
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    var Base64 = reader.result?.split(',')[1];
                    AddFileToUi(file, Base64);
                };
                reader.onerror = (error) => {
                    console.log("error: ", error);
                };
            }
        });
    };
    const AddFileToUi = (formAttached, formAttachedBase64) => {
        if (formAttached === undefined) return;
        openLoader();
        let UploadedFormsArray = uploadedForms;
        let newForm = {
            Extension: formAttached?.name?.substring(formAttached?.name.lastIndexOf('.') + 1, formAttached?.name.length),
            Name: formAttached?.name,
        }
        let newFormUploadAPI = {
            Extension: formAttached?.name?.substring(formAttached?.name.lastIndexOf('.') + 1, formAttached?.name.length),
            Value: formAttachedBase64,
        }
        APIs.addFormAttachment(newFormUploadAPI).then(r => {
            newForm.GUID = r.GUID;
            setTempGUID(r?.GUID);
            // closeLoader();
        });
        UploadedFormsArray.push(newForm);
        connectivityReq.FormsAttachments = [...UploadedFormsArray];
        setUploadedForms(connectivityReq.FormsAttachments);
        onUploadedFormsArray(connectivityReq.FormsAttachments);

    }

    React.useEffect(() => {
        if (uploadedForms?.any(s => !s.GUID)) {
            openLoader();
        }
        else closeLoader();
    }, [tempGUID])

    const fileInputRef = React.useRef();

    return (
        <div className="mt-5" id="connectivity-request-forms-section">
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel-header-attach-docs"
                >
                    <span>Attach Forms</span>
                </AccordionSummary>
                <AccordionDetails>
                    <AppBar position="static" color="default">
                        <div className="row">
                            <Tabs
                                className="new-tabs"
                                value={value}
                                onChange={handleChange}
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs"
                            >
                                <Tab label="Uploaded Forms" />
                                {(userActions?.includes("25") ||
                                    userActions?.includes("20")) && (
                                        <Tab label="+ Add New" />
                                    )
                                }
                            </Tabs>
                        </div>
                    </AppBar>
                    {value === 0 && (
                        <div>
                            {formsList?.length <= 0 ? (
                                <div className="files-list text-center mt-3">
                                    <h6 className="color grey2">No forms were uploaded</h6>
                                </div>
                            ) : (
                                <div className="forms-list">
                                    <div className="row table-header">
                                        <div className="col-6 table-cell first-cell">Form</div>
                                        <div className="col-3 table-cell">Uploaded Date</div>
                                        <div className="col-3 table-cell last-cell">Actions</div>
                                    </div>

                                    {formsList?.map((f, fi) => (
                                        <ConnectivityRequestUploadedFormRecord
                                            form={f}
                                            index={fi}
                                            key={`form-${fi}`}
                                            connectivityReq={connectivityReq}
                                            selectedPlans={selectedPlans}
                                            otherPlanTypes={otherPlanTypes}
                                            formsList={formsList}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {value === 1 && (
                        <div className="row mt-3">
                            <div className="col-4 pl-0">
                                <div className="label-text">Upload Files</div>
                                <DropzoneArea
                                    maxFileSize={20 * 1024 * 1024}
                                    dropzoneParagraphClass={"MuiDropzoneArea-text-updated-grayBlack"}
                                    filesLimit={100}
                                    dropzoneClass={"containerClassDrag"}
                                    dropzoneText={
                                        <div className="dz-message" data-dz-message style={{ display: "grid" }}>
                                            <b>
                                                Click here to add form
                                            </b>
                                            (Supported Format: PDF Only)
                                        </div>
                                    }
                                    onDrop={(loadedFiles) => handleFileAttachedChangeDrag(loadedFiles)}
                                    acceptedFiles={["application/pdf", ".pdf"]}
                                    showPreviewsInDropzone={false}
                                    showAlerts={false}
                                />
                                <div className="text-danger m-3">{invalidFormatError}</div>
                            </div>
                            {uploadedForms?.length <= 0 ? (
                                <div className="col-8 pr-0">
                                    <div className="label-text">Uploads</div>
                                    <div className="forms-list-div">
                                        <h6 className="color grey2">No forms were attached</h6>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-8 pr-0">
                                    <div className="label-text">Uploads</div>
                                    <div className="forms-list-div">
                                        {uploadedForms?.length > 0 && uploadedForms?.map((l, li) => (
                                            <div key={`uploaded-form-${li}`} className="single-file-div">
                                                <div>{l?.Name}</div>
                                                <div>
                                                    <svg width="21" height="24" viewBox="0 0 21 24"
                                                        onClick={(e) => {
                                                            uploadedForms?.splice(li, 1);
                                                            forceUpdate();
                                                        }}
                                                        style={{ cursor: "pointer", justifySelf: "center" }}>
                                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

export default ConnectivityRequestFormsAttachments;