import React from "react";
import Truncate from 'react-truncate';
import { Link } from "@material-ui/core";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Button from "@material-ui/core/Button";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { useCommentsModalDialog } from "../../../Layout/CommentsModalDialog";
import { Comments } from "../../Comments";
import ContentBody from "../../ContentBody";
import { useAuthentication } from "../../../../Data/Authentication";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";

function GenericNewSectionAdminView({ genericSection, ...props }) {

    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const commentsDialog = useCommentsModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [customerPlans, setCustomerPlans] = React.useState([]);
    const [carrierPlans, setCarrierPlans] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [index, setIndex] = React.useState(-1);

    const _colorOptions = {
        primaryAnalystColor: "var(--yellow-secondary-color)",
        primaryAdminColor: "var(--purple)"
    };

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupPlans(connectivityReq?.ConnectivityRequestID).then((r) => (
            setCustomerPlans(r?.data),
            closeLoader()
        ));
    }, [connectivityReq])

    React.useEffect(() => {
        setCarrierPlans(genericSection?.Model.map(s => ({
            carrierPlanName: s.CarrierPlanName,
            carrierPlanType: s.PlanType
        })))
    }, [genericSection])

    const handleFunction = (value) => {
        setIndex(value);
        const commentsBody = {
            Type: genericSection.Type,
            GUID: genericSection.Model[value].GUID
        }

        APIs.getPreMappingComments(commentsBody).then((r) => {
            setComments(r?.data); forceUpdate();
        })
    }

    React.useEffect(() => {
        if (index === -1) return;
        handleFunction(index); forceUpdate();
    }, [index])

    React.useEffect(() => {
        if (comments === undefined || index === -1) return;
        genericSection.Model[index].Comment = comments[comments?.length - 1];
    }, [comments])

    return (
        <div id="generic-new-section">
            <div className="row mt-3">
                <div className="col-12">
                    <div className="table-header row">
                        <div className="col-xl-3 table-cell first-cell">Mapping {genericSection.Title}</div>
                        <div className="col-xl-9 table-cell">Comments</div>
                    </div>
                </div>
            </div>
            <div className="table-subheader row">
                <div className="col-xl-3 table-cell first-cell">Carrier {genericSection.Title}</div>
                <div className="col-xl-1 table-cell">User</div>
                <div className="col-xl-7 table-cell">Latest Comment</div>
                <div className="col-xl-1 table-cell">Actions</div>
            </div>
            {genericSection?.Model?.map((p, pi) => (
                <div key={`generic-section-${pi}`} className="table-row row">
                    <div className="col-xl-3 table-cell first-cell">
                        {p.CarrierSection}
                    </div>
                    <div className="col-xl-1 table-cell">
                        <div className="userInfo">
                            <CustomTooltip TransitionComponent={Zoom} title={<div><span>{p.Comment?.Name}</span><br /><span>{p.Comment?.Email}</span></div>}>
                                <span className="user" style={{ backgroundColor: _colorOptions.primaryAnalystColor }}>{p.Comment?.Name.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                            </CustomTooltip>
                        </div>
                    </div>
                    <div className="col-xl-7 table-cell">
                        <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog(<span>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                            <span>{p.Comment?.Text}</span>
                        </Truncate>
                    </div>
                    <div className="col-xl-1 table-cell">
                        <div className="comments-section-single-column">
                            <Button onClick={e => {
                                handleFunction(pi); Comments(commentsDialog, {
                                    title: p.CarrierSection,
                                    body: <ContentBody section={genericSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                });
                            }}>
                                <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                            </Button>
                        </div>
                    </div>
                </div>
            ))
            }
        </div >
    )
}

export default GenericNewSectionAdminView;