import React from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SelectCarrierPlansInternal from "./SelectCarrierPlansInternal";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

function SelectCarrierPlans({ groupReq, isPEOClicked, selectedOtherCarriers, selectPlanValidationForm, requestSubmitted, parentForceUpdate, carriers, setSelectedCarriers, selectedCarriers, ...props }) {

    const [expanded, setExpanded] = React.useState("SelectPlans");

    return (
        <Accordion expanded={expanded === `SelectPlans`}>
            <AccordionSummary
                onClick={() => expanded === `SelectPlans` ? setExpanded("") : setExpanded("SelectPlans")}
                // className="pl-0 pr-0"
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="expand-carrier-contacts"
            >
                <span >Carrier Plans</span>
            </AccordionSummary>
            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}></div>
            <div className="col-xl-10 mt-2 mb-3 text-danger" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}>{selectPlanValidationForm}</div>
            <AccordionDetails>
                <SelectCarrierPlansInternal isPEOClicked={isPEOClicked} selectedOtherCarriers={selectedOtherCarriers} parentForceUpdate={parentForceUpdate} carriers={carriers} setSelectedCarriers={setSelectedCarriers} selectedCarriers={selectedCarriers} />
            </AccordionDetails>
        </Accordion>
    )
}

export default SelectCarrierPlans;