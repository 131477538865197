import React from "react";
import { Divider, FormControl, Select, MenuItem, Button } from "@material-ui/core";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";

function AnalystFeedFormPlansMatching({
    feed,
    groupPlans,
    filteredFormPlans,
    parentForceUpdate,
    form,
    filteredMatchedPlans,
    ...props
}) {

    const dialog = useModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);

    const matchPlans = () => {
        const matchingObject = {
            feedId: feed?.FeedID,
            digitalFormId: form?.DigitalFormID,
            plans: groupPlans?.filter(s => s.MatchedFormPlan).map(plan => (
                {
                    BenefitId: plan?.BenefitId,
                    DigitalFormPlanId: plan?.MatchedFormPlan
                }
            ))
        };

        APIs.matchPlansGroupDigitalForms(matchingObject).then(r => window.location.reload());
    }

    React.useEffect(() => {
        for (var i = 0; i <= groupPlans.length - 1; i++) {
            for (var j = 0; j <= filteredMatchedPlans.length - 1; j++) {
                if (groupPlans[i]?.BenefitId === filteredMatchedPlans[j]?.BenefitId) {
                    groupPlans[i].MatchedFormPlan = filteredMatchedPlans[j]?.DigitalFormPlanId
                }
            }
        }
        forceUpdate();
    }, [filteredMatchedPlans, groupPlans])

    return (
        <div id="df-matching-plans-modal" style={{ minWidth: 950 }}>
            {/* <h5 className="black1 fw-700">{form?.DigitalFormName} / Plans Matching</h5>
            <Divider /> */}
            <div className="table-header row mt-3">
                <div className="col-xl-6 table-cell first-cell">Group Plans</div>
                <div className="col-xl-6 table-cell">Form Plans</div>
            </div>

            <div className="table-subheader row">
                <div className="col-xl-2 table-cell first-cell">Carrier Name</div>
                <div className="col-xl-2 table-cell">Plan Name</div>
                <div className="col-xl-2 table-cell">Plan Type</div>
                <div className="col-xl-3 table-cell">Plan Name</div>
                <div className="col-xl-3 table-cell">Plan Type</div>
            </div>
            {groupPlans?.map((p, pi) => (
                <div key={`group-plans-${pi}`} className="table-row row table-row-bordered-l-r">
                    <div className="col-xl-2 table-cell first-cell">
                        <span>{
                            p?.CarrierName ? p?.CarrierName : `N/A`
                        }</span>
                    </div>
                    <div className="col-xl-2 table-cell">
                        <span>{p?.BenefitName}</span>
                    </div>
                    <div className="col-xl-2 table-cell">
                        <span>{p?.BenefitType}</span>
                    </div>
                    <div className="col-xl-3 table-cell">
                        <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                            <Select
                                className="text-dropdown pr-3"
                                value={p.MatchedFormPlan ?? ""}
                                onChange={e => { p.MatchedFormPlan = e.target.value; forceUpdate(); }}
                            >
                                <MenuItem value="">None</MenuItem>
                                {filteredFormPlans && filteredFormPlans?.map((s, si) => (
                                    <MenuItem key={`match-plan-${si}`} value={s?.DigitalFormPlanId ?? ""}>{s?.DigitalFormPlanName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-xl-3 table-cell">
                        <span>{filteredFormPlans?.filter(s => s.DigitalFormPlanId === p.MatchedFormPlan)[0]?.PlanTypeName}</span>
                    </div>
                </div>
            ))}
            <div className="row w-100 mt-5" style={{ justifyContent: "flex-end" }}>
                <Button variant="contained" className="blue-outlined-btn mr-2" onClick={() => window.location.reload()}>Cancel</Button>
                <Button variant="contained" className="blue-btn" onClick={matchPlans}>Match Plans</Button>
            </div>
        </div>
    )
}

export default AnalystFeedFormPlansMatching;