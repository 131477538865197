import React from "react";
import { Paper, Button, FormControlLabel, Checkbox, Divider } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import { Select } from "@material-ui/core";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import * as APIs from "../../../Data/APIs";
import CreateThirdPartyContactRow from "./CreateThirdPartyContactRow";
import { DropzoneArea } from "material-ui-dropzone";
import TPFileRow from "./TPFileRow";
import { parseISO } from 'date-fns';
import Moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateTimeSVG from "../../../Images/dateTime.svg";
import MenuItem from '@material-ui/core/MenuItem';
import { useLoader } from "../../Layout/Loader";
import { FailureModal } from "../../Modals/FailureModal";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import SelectSingleConnectivityRequestType from "./SelectSingleConnectivityRequestType"
import { emailPattern } from "../ViewEditGroupRequest/ViewGroupRequestClientContactsRow";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import PlusIconWhite from "../../../Icons/PlusIconWhite.svg";
import deleteForeverSVG from "../../../Icons/trash-xmark.svg";
import TabsTitlesWithMoreDD from "../../Layout/TabsTitlesWithMoreDD";
import plusIconSVG from "../../../Icons/plusIcon.svg";
import Grid from '@mui/material/Grid';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div className="p-3">{children}</div>

            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

function CreateThirdParty({
    selectedThirdParties,
    parentForceUpdate,
    selectPlanValidationForm,
    onSelectClientContactValidationForm,
    onSelectClientEffectiveDateValidationForm,
    onThirdPartyNameValidationForm,
    onThirdPartyPlansValidationForm,

    onThirdPartyContactsValidationForm,
    onThirdPartyContactsFirstNameValidationForm,
    onThirdPartyContactsLastNameValidationForm,
    onThirdPartyContactsEmailValidationForm,
    onThirdPartyContactsTypeValidationForm,

    onSelectTPMigrationValidationForm,
    onSelectTPMigrationStarDateValidationForm,
    onSelectTPMigrationPhaseValidationForm,
    onSelectTPPlannedFirstProductionDateValidationForm,
    requestSubmitted,
    requestSaved,
    groupReq,
    groupsAndOEContactsList,
    migrationPhasesList,
    carrierContactTypes,
    isPEOClicked,
    subTypeList,
    setSubTypeList,
    formsSubTypeList,
    setFormsSubTypeList,
    onThirdPartyTypeValidationForm,
    OnThirdPartyAttachFormsValidationForm,
    ...props
}) {

    const classes = useStyles();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);

    const [tabValue, setTabValue] = React.useState(0);
    const [expanded, setExpanded] = React.useState("ThirdParty");
    const [tabDefaultValue, setTabDefaultValue] = React.useState("[Third Party Name]")

    const [planTypesTPList, setPlanTypesTPList] = React.useState([]);
    const [tempGUID, setTempGUID] = React.useState("");
    const [clientContactValidation, setClientContactValidation] = React.useState("");
    const [clientEffectiveDateValidation, setClientEffectiveDateValidation] = React.useState("");

    const [thirdPartyNameValidation, setThirdPartyNameValidation] = React.useState("");
    const [thirdPartyTypeValidation, setThirdPartyTypeValidation] = React.useState("");
    const [thirdPartyPlansValidation, setThirdPartyPlansValidation] = React.useState("");

    const [thirdPartyContactsValidation, setThirdPartyContactsValidation] = React.useState("");
    const [thirdPartyContactsFirstNameValidation, setThirdPartyContactsFirstNameValidation] = React.useState("");
    const [thirdPartyContactsLastNameValidation, setThirdPartyContactsLastNameValidation] = React.useState("");
    const [thirdPartyContactsEmailValidation, setThirdPartyContactsEmailValidation] = React.useState("");
    const [thirdPartyContactsTypeValidation, setThirdPartyContactsTypeValidation] = React.useState("");

    const [selectMigrationValidationForm, setSelectMigrationValidationForm] = React.useState("");
    const [selectMigrationStarDateValidationForm, setSelectMigrationStarDateValidationForm] = React.useState("");
    const [selectPlannedFirstProductionDateValidationForm, setSelectPlannedFirstProductionDateValidationForm] = React.useState("");
    const [selectMigrationPhaseValidationForm, setSelectMigrationPhaseValidationForm] = React.useState("");

    const [thirdPartyAttachmentFormValidation, setThirdPartyAttachmentFormValidation] = React.useState("");

    const [, forceUpdate] = React.useReducer(x => !x, false);

    React.useEffect(() => {
        APIs.getPlanTypes().then((r) => setPlanTypesTPList(r?.data?.sort((a, b) => a.PlanTypeName.toLowerCase() > b.PlanTypeName.toLowerCase() ? 1 : -1)));
    }, [])

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        if (!selectedThirdParties) return;
        else {
            selectedThirdParties.thirdPartiesFullArray = [
                {
                    CarrierID: 86,
                    IsolvedCarrier: {
                        Code: null,
                        Name: "Third Party",
                        isNew: false
                    },
                    ReceivedEncryptionRequirements: 0,
                    SelectedTPPlans: [],
                    OtherTPChecked: false,
                    OtherTPPlanTypes: "",
                    SelectAllTPPlansChecked: false,
                    Attachments: [],
                    Migration: groupReq?.groupRequest?.MigrationGroupRequest,
                    MigrationStartDate: null,
                    PlannedFirstProductionDate: null,
                    MigrationPhase: groupReq?.groupRequest?.MigrationGroupRequest == "No" ? 0 : 2,
                    SelectedCRTypes: ["EDI"],
                    ConnectivityRequestEDISubTypeID: groupReq?.groupRequest?.ConnectivityRequestEDISubTypeID == undefined ? 1 : groupReq?.groupRequest?.ConnectivityRequestEDISubTypeID,
                    ConnectivityRequestFormsSubTypeID: groupReq?.groupRequest?.ConnectivityRequestFormsSubTypeID == undefined ? null : groupReq?.groupRequest?.ConnectivityRequestFormsSubTypeID
                }
            ]
            parentForceUpdate();
        }
    }, [selectedThirdParties])


    const handleOtherTPPlanTypesChange = (s) => (e) => {
        s.OtherTPPlanTypes = (e.target.value);
        parentForceUpdate();
    }
    const handelClientContact = (s) => (e) => {
        s.ClientContact = e.target.value;
        parentForceUpdate();
    }

    const handleTPPlanClick = (value, f) => (event) => {
        if (!value || value === "") f.SelectedTPPlans = [];
        else if (f.SelectedTPPlans.indexOf(value) >= 0) {
            f.SelectedTPPlans.splice(f.SelectedTPPlans.indexOf(value), 1);
            if (value === `Other`) {
                f.OtherTPChecked = false;
                f.OtherTPPlanTypes = "";
                if (f.SelectedTPPlans.length === planTypesTPList.length && !f.SelectedTPPlans.includes("Other"))
                    f.SelectAllTPPlansChecked = true; else f.SelectAllTPPlansChecked = false;
            }
        }
        else {
            f.SelectedTPPlans.push(value);
            if (value === `Other`) {
                f.OtherTPChecked = true;
                f.OtherTPPlanTypes = "";
                if (f.SelectedTPPlans.length === planTypesTPList.length && !f.SelectedTPPlans.includes("Other")) {
                    f.SelectAllTPPlansChecked = true;
                    f.OtherTPChecked = false;
                    f.OtherTPPlanTypes = "";
                } else f.SelectAllTPPlansChecked = false;
            }
        }
        f.SelectedTPPlans = ([...f.SelectedTPPlans]);

        parentForceUpdate();
    }

    const handleSelectAllTPPlanClick = (value, f) => (event) => {
        if (!value || value === "") f.SelectedTPPlans = [];
        else if (f.SelectedTPPlans.sort().join(',') === value.sort().join(',')) {
            f.SelectedTPPlans = [];
            f.SelectAllTPPlansChecked = false;
            if (f.SelectedTPPlans.any(p => p === "Other"))
                f.OtherTPChecked = true; else {
                f.OtherTPChecked = false;
                f.SelectedTPPlans.splice(f.SelectedTPPlans.indexOf("Other"), 1);
                f.OtherTPPlanTypes = "";
            }
        }
        else {
            f.SelectedTPPlans.push(...value);
            f.SelectedTPPlans = Array.from(new Set(f.SelectedTPPlans));
            f.SelectAllTPPlansChecked = true;
            if (f.SelectedTPPlans.any(p => p === "Other")) {
                f.OtherTPChecked = false;
                f.SelectedTPPlans.splice(f.SelectedTPPlans.indexOf("Other"), 1);
                f.OtherTPPlanTypes = "";
            }
        }
        f.SelectedTPPlans = ([...f.SelectedTPPlans]);
        parentForceUpdate();
    }


    const handleCompanionGuideChangeDrag = async (files, f) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, Attachments: f, type: "CarrierCompanionGuide", title: "Companion Guide" });
            parentForceUpdate();
        }
    }

    const handleGroupStructureChangeDrag = async (files, f) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, Attachments: f, type: "GroupStructureDocument", title: "Group Structure" });
            parentForceUpdate();
        }
    }


    const handleConnectivityInfoChangeDrag = async (files, f) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, Attachments: f, type: "ConnectivityInfoDocument", title: "Connectivity Info" });
            parentForceUpdate();
        }
    }


    const handleNamingConventionInfoChangeDrag = async (files, f) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, Attachments: f, type: "NamingConventionInfoDocument", title: "Naming Convention Info" });
            parentForceUpdate();
        }
    }


    const handleCensusFileChangeDrag = async (files, f) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, Attachments: f, type: "CensusFile", title: "Census File" });
            parentForceUpdate();
        }
    }


    const handleEncryptionFileChangeDrag = async (files, f) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, Attachments: f, type: "EncryptionFile", title: "Encryption key" });
            parentForceUpdate();
        }
    }

    const handleOtherFileChangeDrag = async (files, f) => {
        if (files.length === 0) return;
        if (files.any(s => s?.size === 0)) {
            if (files.length === 1) {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'File is empty!'
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure!',
                    body: 'One or more files are empty!'
                });
            }
        }
        else {
            await encodeFileBase64({ files: files, Attachments: f, type: "OtherFile", title: "Other" });
        }
    }

    const encodeFileBase64 = async ({ files, Attachments, type, title }) => {
        files.map((file) => {
            var reader = new FileReader();
            if (file) {
                reader.readAsDataURL(file);
                reader.onload = () => {
                    var Base64 = reader.result.split(',')[1];
                    addCurrentFilesInArray(file, Attachments, Base64, type, title);
                };
                reader.onerror = (error) => {
                    console.log("error: ", error);
                };
            }
        });
    };


    const addCurrentFilesInArray = (file, Attachments, fileBase64, type, title) => {
        if (file === undefined) return;
        openLoader();
        let UploadedFilesArray = Attachments;
        let newFile = {
            Type: type,
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Title: title,
            Name: file?.name,
        }
        let newFileUploadAPI = {
            Extension: file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length),
            Value: fileBase64,
        }
        APIs.addAttachment(newFileUploadAPI).then(r => {
            newFile.GUID = r.GUID;
            setTempGUID(r?.GUID);
        });
        UploadedFilesArray.push(newFile);
        Attachments = [...UploadedFilesArray];
        parentForceUpdate();
    }


    React.useEffect(() => {
        selectedThirdParties?.thirdPartiesFullArray.map(s => {
            if (s.Attachments?.any(a => !a.GUID)) {
                openLoader();
            }
            else closeLoader();
        })
    }, [tempGUID])


    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => !s.ClientContact).length > 0) {
            onSelectClientContactValidationForm("iSolved contact is missing, please select a iSolved contact from the list");
            setClientContactValidation("iSolved contact is missing, please select a iSolved contact from the list");
        } else {
            onSelectClientContactValidationForm("");
            setClientContactValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), clientContactValidation])

    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => !s.ClientEffectiveDate).length > 0) {
            onSelectClientEffectiveDateValidationForm("Client Effective Date is missing, please select a Client Effective Date");
            setClientEffectiveDateValidation("Client Effective Date is missing, please select a Client Effective Date");
        } else {
            onSelectClientEffectiveDateValidationForm("");
            setClientEffectiveDateValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), clientEffectiveDateValidation])

    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => !s.ThirdPartyName?.replaceAll(" ", "") || s.ThirdPartyName?.replaceAll(" ", "") === "").length > 0) {
            onThirdPartyNameValidationForm("Third Party name is missing, please add a name");
            setThirdPartyNameValidation("Third Party name is missing, please add a name");
        } else {
            onThirdPartyNameValidationForm("");
            setThirdPartyNameValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyNameValidation])

    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => (!s.SelectedCRTypes || s.SelectedCRTypes.length <= 0)).length > 0) {
            onThirdPartyTypeValidationForm("Third Party type is missing, Please select at least one third party type for each Third Party selected");
            setThirdPartyTypeValidation("Third Party type is missing, Please select at least one third party type for each Third Party selected");
        }
        else if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray.filter(s => s.SelectedCRTypes?.includes("Forms"))?.filter(d => d.ConnectivityRequestFormsSubTypeID?.length <= 0 || !d.ConnectivityRequestFormsSubTypeID).length > 0) {
            onThirdPartyTypeValidationForm("Please select at least one sub type for each third party selected");
            setThirdPartyTypeValidation("Please select at least one sub type for each third party selected");
        }
        else {
            onThirdPartyTypeValidationForm("");
            setThirdPartyTypeValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyTypeValidation])

    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => ((!s.SelectedTPPlans || s.SelectedTPPlans.length <= 0) && (!s.OtherTPPlanTypes || s.OtherTPPlanTypes === "")) || (s.SelectedTPPlans.filter(s => s !== "Other").length <= 0 && (!s.OtherTPPlanTypes || s.OtherTPPlanTypes === ""))).length > 0) {
            onThirdPartyPlansValidationForm("Please select at least one plan for each Third Party added");
            setThirdPartyPlansValidation("Please select at least one plan for each Third Party added");
        } else {
            onThirdPartyPlansValidationForm("");
            setThirdPartyPlansValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyPlansValidation])

    // ================Contacts Validation
    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => !s.Contacts || s.Contacts?.length <= 0).length > 0) {
            onThirdPartyContactsValidationForm("Please add at least one Third Party contact for each Third Party added");
            setThirdPartyContactsValidation("Please add at least one Third Party contact for each Third Party added");
        } else {
            onThirdPartyContactsValidationForm("");
            setThirdPartyContactsValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyContactsValidation])


    // First Name
    React.useEffect(() => {
        let notValidContactsFirstNameValidation = []
        if (selectedThirdParties?.thirdPartiesFullArray) {
            selectedThirdParties?.thirdPartiesFullArray?.map(c => {
                return c.Contacts?.map(contact => {
                    if (contact.ConnectivityRequestContactFirstName.split(" ").join("").length == 0) {
                        notValidContactsFirstNameValidation.push(contact)
                    }
                })
            });
        }

        if (notValidContactsFirstNameValidation.length > 0) {
            onThirdPartyContactsFirstNameValidationForm("Please add at least one Third Party First Name for each contact at each Carrier selected");
            setThirdPartyContactsFirstNameValidation("Please add at least one Third Party First Name for each contact at each Carrier selected");
        }
        else {
            onThirdPartyContactsFirstNameValidationForm("");
            setThirdPartyContactsFirstNameValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyContactsFirstNameValidation])

    // Last Name
    React.useEffect(() => {
        let notValidContactsLastNameValidation = []
        if (selectedThirdParties?.thirdPartiesFullArray) {
            selectedThirdParties?.thirdPartiesFullArray?.map(c => {
                return c.Contacts?.map(contact => {
                    if (contact.ConnectivityRequestContactLastName.split(" ").join("").length == 0) {
                        notValidContactsLastNameValidation.push(contact)
                    }
                })
            });
        }

        if (notValidContactsLastNameValidation.length > 0) {
            onThirdPartyContactsLastNameValidationForm("Please add at least one Third Party Last Name for each contact at each Carrier selected");
            setThirdPartyContactsLastNameValidation("Please add at least one Third Party Last Name for each contact at each Carrier selected");
        }
        else {
            onThirdPartyContactsLastNameValidationForm("");
            setThirdPartyContactsLastNameValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyContactsLastNameValidation])

    // Email
    React.useEffect(() => {
        let notValidContactsEmailValidation = []
        let notValidFormatContactsEmailValidation = []

        if (selectedThirdParties?.thirdPartiesFullArray) {
            selectedThirdParties?.thirdPartiesFullArray?.map(c => {
                return c.Contacts?.map(contact => {
                    if (contact.ConnectivityRequestContactEmail.split(" ").join("").length == 0) {
                        notValidContactsEmailValidation.push(contact)
                    } else if (!emailPattern.test(contact.ConnectivityRequestContactEmail)) {
                        notValidFormatContactsEmailValidation.push(contact)
                    }
                })
            });
        }

        if (notValidContactsEmailValidation.length > 0) {
            onThirdPartyContactsEmailValidationForm("Please add at least one Third Party Email for each contact at each Carrier selected");
            setThirdPartyContactsEmailValidation("Please add at least one Third Party Email for each contact at each Carrier selected");
        }
        else if (notValidFormatContactsEmailValidation.length > 0) {
            onThirdPartyContactsEmailValidationForm("Please add  Third Party Valid Email for each contact at each Carrier selected");
            setThirdPartyContactsEmailValidation("Please add Third Party Valid Email for each contact at each Carrier selected");
        }
        else {
            onThirdPartyContactsEmailValidationForm("");
            setThirdPartyContactsEmailValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyContactsEmailValidation])

    // Type
    React.useEffect(() => {
        let notValidContactsTypeValidation = []
        if (selectedThirdParties?.thirdPartiesFullArray) {
            selectedThirdParties?.thirdPartiesFullArray?.map(c => {
                return c.Contacts?.map(contact => {
                    if (contact.CarrierContactType.split(" ").join("").length == 0) {
                        notValidContactsTypeValidation.push(contact)
                    }
                })
            });
        }

        if (notValidContactsTypeValidation.length > 0) {
            onThirdPartyContactsTypeValidationForm("Please add at least one Third Party Type for each contact at each Carrier selected");
            setThirdPartyContactsTypeValidation("Please add at least one Third Party Type for each contact at each Carrier selected");
        }
        else {
            onThirdPartyContactsTypeValidationForm("");
            setThirdPartyContactsTypeValidation("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyContactsTypeValidation])

    //  // ================Contacts Validation

    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => (!s.Migration || s.Migration === "")).length > 0) {
            onSelectTPMigrationValidationForm("Migration type is missing, please select a type from the list for each Third Party added");
            setSelectMigrationValidationForm("Migration type is missing, please select a type from the list for each Third Party added");
        } else {
            onSelectTPMigrationValidationForm("");
            setSelectMigrationValidationForm("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), selectMigrationValidationForm])

    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => s.Migration !== "No" && (!s.MigrationStartDate || s.MigrationStartDate === "" || s.MigrationStartDate === "Invalid date")).length > 0) {
            onSelectTPMigrationStarDateValidationForm("Migration start date cannot be empty for each Third Party added");
            setSelectMigrationStarDateValidationForm("Migration start date cannot be empty for each Third Party added");
        } else {
            onSelectTPMigrationStarDateValidationForm("");
            setSelectMigrationStarDateValidationForm("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), selectMigrationStarDateValidationForm])

    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => s.Migration !== "No" && (!s.PlannedFirstProductionDate || s.PlannedFirstProductionDate === "" || s.PlannedFirstProductionDate === "Invalid date")).length > 0) {
            setSelectPlannedFirstProductionDateValidationForm("Planned first production date cannot be empty for each Third Party added");
            onSelectTPPlannedFirstProductionDateValidationForm("Planned first production date cannot be empty for each Third Party added");
        } else {
            setSelectPlannedFirstProductionDateValidationForm("");
            onSelectTPPlannedFirstProductionDateValidationForm("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), selectPlannedFirstProductionDateValidationForm])

    React.useEffect(() => {
        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => s.Migration !== "No" && (!s.MigrationPhase || s.MigrationPhase === "")).length > 0) {
            setSelectMigrationPhaseValidationForm("Migration phase is missing, please select a phase from the list for each Third Party added");
            onSelectTPMigrationPhaseValidationForm("Migration phase is missing, please select a phase from the list for each Third Party added");
        } else {
            setSelectMigrationPhaseValidationForm("");
            onSelectTPMigrationPhaseValidationForm("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), selectMigrationPhaseValidationForm])


    // Forms File Validation
    React.useEffect(() => {

        if (selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties?.thirdPartiesFullArray?.filter(s => s.SelectedCRTypes?.includes("Forms"))?.filter(d => d.FormsAttachments?.length <= 0 || !d.FormsAttachments).length > 0) {
            setThirdPartyAttachmentFormValidation("Please attach at least one form for each form Connectivity Request selected");
            OnThirdPartyAttachFormsValidationForm("Please attach at least one form for each form Connectivity Request selected");
        }
        else {
            setThirdPartyAttachmentFormValidation("");
            OnThirdPartyAttachFormsValidationForm("");
        }
    }, [JSON.stringify(selectedThirdParties.thirdPartiesFullArray), thirdPartyAttachmentFormValidation])


    return (
        <div id="third-party-section">
            <Paper elevation={0}>
                <Accordion expanded={expanded === `ThirdParty`}>
                    <AccordionSummary
                        onClick={() => expanded === `ThirdParty` ? setExpanded("ThirdParty") : setExpanded("ThirdParty")}
                        // className="pl-0 pr-0"
                        // expandIcon={<ExpandMoreIcon />}
                        aria-label="Expand"
                        aria-controls="expand-carrier-contacts"
                    >
                        <span >Third Party / Vendor</span>
                        
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-100">
                        <div style={{ textAlign: "end" }}>
                        <Button variant="contained" className="blue-btn" onClick={() => {
                            selectedThirdParties.thirdPartiesFullArray = ([...selectedThirdParties.thirdPartiesFullArray, {
                                CarrierID: 86,
                                IsolvedCarrier: {
                                    Code: null,
                                    Name: "Third Party",
                                    isNew: false
                                },
                                ReceivedEncryptionRequirements: 0,
                                SelectedTPPlans: [],
                                OtherTPChecked: false,
                                OtherTPPlanTypes: "",
                                SelectAllTPPlansChecked: false,
                                Attachments: [],

                                Migration: groupReq?.groupRequest?.MigrationGroupRequest,
                                MigrationStartDate: null,
                                PlannedFirstProductionDate: null,
                                MigrationPhase: groupReq?.groupRequest?.MigrationGroupRequest == "No" ? 0 : 2,
                                SelectedCRTypes: ["EDI"],
                                ConnectivityRequestEDISubTypeID: 1,
                                ConnectivityRequestFormsSubTypeID: null
                            }]);
                            parentForceUpdate();
                        }
                        }>
                            <label style={{ cursor: "pointer" }} className="mb-0">
                                <svg width="12" height="12" viewBox="0 0 12 12" className="mt--2 mr-2">
                                    <use xlinkHref={`${PlusIconWhite}#PlusIconWhite`}></use>
                                </svg>
                                Add Third Party</label>
                        </Button>
                        </div>

                            {selectedThirdParties?.thirdPartiesFullArray && selectedThirdParties.thirdPartiesFullArray?.length > 0 && (
                                <div id="third-parties" className={classes.root}>
                                    <div className="border-bottom">
                                        <TabsTitlesWithMoreDD
                                            tabTitle={[
                                                ...(selectedThirdParties?.thirdPartiesFullArray?.map(s => s?.ThirdPartyName ?? tabDefaultValue) || [])
                                            ]}
                                            value={tabValue}
                                            setValue={setTabValue}
                                        />
                                    </div>
                                    {/* <AppBar position="static" color="default">
                                        <div className="row">
                                            <div className="col-10 pl-0">
                                                <Tabs
                                                    className="tab-style"
                                                    value={tabValue}
                                                    onChange={handleChange}
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs"
                                                >
                                                    {selectedThirdParties?.thirdPartiesFullArray?.map((s, si) => (
                                                        <Tab
                                                            key={`tp-tab-${si}`}
                                                            label={s?.ThirdPartyName ?? tabDefaultValue}
                                                        />
                                                    ))}
                                                </Tabs>
                                            </div>
                                        </div>
                                    </AppBar> */}
                                    {selectedThirdParties?.thirdPartiesFullArray?.map((s, si) => (
                                        si === tabValue && (
                                            <div key={`tp-record-${si}`}>
                                                <TabPanel value={tabValue} index={tabValue}>
                                                    {selectedThirdParties?.thirdPartiesFullArray?.length >= 2 && (
                                                        <div className="mb-4">
                                                            <Button variant="contained" className="blue-outlined-btn ml-3" onClick={() => {
                                                                selectedThirdParties?.thirdPartiesFullArray?.splice(selectedThirdParties?.thirdPartiesFullArray.indexOf(s), 1);
                                                                if (tabValue === 0) setTabValue(0);
                                                                else setTabValue(tabValue - 1);
                                                                parentForceUpdate();
                                                            }}>
                                                                <svg width="21" height="24" viewBox="0 0 21 24" className="mr-2 mt-2" style={{ cursor: "pointer" }}>
                                                                    <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                                                </svg>
                                                                <label style={{ cursor: "pointer" }} className="mb-0">Delete Third Party</label>
                                                            </Button>
                                                        </div>
                                                    )}
                                                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <h6 className="form-title">Third Party Name
                                                                <span className="text-danger mb-2 ml-2">*</span> </h6>

                                                            <input type="text" className="text-input w-100" value={s.ThirdPartyName ?? ""} onChange={e => { s.ThirdPartyName = e.target.value; parentForceUpdate(); }} />
                                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{thirdPartyNameValidation}</div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <h6 className="form-title">Third Party Type
                                                                <span className="text-danger mb-2 ml-2">*</span> </h6>


                                                            {selectedThirdParties.thirdPartiesFullArray?.map((s, si) => (
                                                                si === tabValue && (
                                                                    <SelectSingleConnectivityRequestType
                                                                        carrier={s}
                                                                        index={si}
                                                                        key={`single-carrier-plans-${si}`}
                                                                        subTypeList={subTypeList}
                                                                        setSubTypeList={setSubTypeList}
                                                                        formsSubTypeList={formsSubTypeList}
                                                                        setFormsSubTypeList={setFormsSubTypeList}
                                                                        parentForceUpdate={parentForceUpdate}
                                                                        isPEOClicked={isPEOClicked}
                                                                        attachFormsValidationForm={thirdPartyAttachmentFormValidation}
                                                                        requestSubmitted={requestSubmitted}
                                                                        requestSaved={requestSaved}
                                                                        selectedCarriers={selectedThirdParties?.thirdPartiesFullArray}

                                                                    />
                                                                )
                                                            ))}
                                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{thirdPartyTypeValidation}</div>
                                                        </Grid>
                                                        {/* Plans */}
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <h6 className="form-title">Third Party Plans</h6>

                                                            <div className="row plans-list-div p-4" style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}>
                                                                <FormControlLabel
                                                                    aria-label="Acknowledge"
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    onFocus={(event) => event.stopPropagation()}
                                                                    control={
                                                                        <Checkbox
                                                                            id={`plans-tp-option-select-all`}
                                                                            value={planTypesTPList}
                                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                                            checked={s.SelectedTPPlans && s.SelectedTPPlans.length === planTypesTPList.length && !s.SelectedTPPlans.includes("Other")}
                                                                            onClick={handleSelectAllTPPlanClick(planTypesTPList.map(s => s.PlanTypeID), s)}
                                                                            checkedIcon={<DoneOutlineIcon />}
                                                                            color="default"
                                                                        />
                                                                    }
                                                                    label="Select All"
                                                                />
                                                                <br />
                                                                {planTypesTPList.map((p, pi) => (
                                                                    <React.Fragment key={`plan-type-tp-${pi}`}>
                                                                        <FormControlLabel
                                                                            aria-label="Acknowledge"
                                                                            onClick={(event) => event.stopPropagation()}
                                                                            onFocus={(event) => event.stopPropagation()}
                                                                            control={
                                                                                <Checkbox
                                                                                    id={`plan-tp-option-${pi}`}
                                                                                    value={p}
                                                                                    checked={s.SelectedTPPlans && s.SelectedTPPlans.indexOf(p.PlanTypeID) >= 0}
                                                                                    onClick={handleTPPlanClick(p.PlanTypeID, s)}
                                                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                                                    checkedIcon={<DoneOutlineIcon />}
                                                                                />}
                                                                            label={p.PlanTypeName}
                                                                        />
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                                <FormControlLabel
                                                                    aria-label="Acknowledge"
                                                                    onClick={(event) => event.stopPropagation()}
                                                                    onFocus={(event) => event.stopPropagation()}
                                                                    control={
                                                                        <Checkbox
                                                                            id={`plan-tp-option-other`}
                                                                            value="Other"
                                                                            checked={s.SelectedTPPlans && s.SelectedTPPlans.indexOf("Other") >= 0}
                                                                            onClick={handleTPPlanClick("Other", s)}
                                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                                            checkedIcon={<DoneOutlineIcon />}
                                                                        />}
                                                                    label="Other"
                                                                />
                                                                <br />
                                                                {s.OtherTPChecked && (
                                                                    <textarea
                                                                        placeholder="Please enter plan types separated by new lines."
                                                                        className="pt-2 pb-2 text-input w-100"
                                                                        value={s.OtherTPPlanTypes ?? []}
                                                                        rows={3}
                                                                        onChange={handleOtherTPPlanTypesChange(s)}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}></div>
                                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}>{thirdPartyPlansValidation}</div>
                                                        </Grid>
                                                        {/* Contacts */}
                                                        <Grid item xs={12}>

                                                            <Accordion defaultExpanded expanded={expanded === `ThirdParty`}>
                                                                <AccordionSummary
                                                                    onClick={() => expanded === `ThirdParty` ? setExpanded("ThirdParty") : setExpanded("ThirdParty")}
                                                                    aria-controls="panel2-content"
                                                                    id="panel-header-cr-config"
                                                                // onClick={(event) => {
                                                                //     // event.stopPropagation();
                                                                //     expanded === `ThirdParty` ? setExpanded("ThirdParty") : setExpanded("ThirdParty")
                                                                // }}
                                                                // style={{ pointerEvents: 'none' }}
                                                                >
                                                                    <span>Third Party Contacts</span>

                                                                    <div className="add-record-btn ml-auto" onClick={() => {
                                                                        if (selectedThirdParties.thirdPartiesFullArray[si].Contacts === undefined) selectedThirdParties.thirdPartiesFullArray[si].Contacts = [];
                                                                        selectedThirdParties.thirdPartiesFullArray[si].Contacts = ([...selectedThirdParties.thirdPartiesFullArray[si].Contacts, {
                                                                            ConnectivityRequestContactFirstName: "",
                                                                            ConnectivityRequestContactLastName: "",
                                                                            ConnectivityRequestContactEmail: "",
                                                                            CarrierContactType: "Test",
                                                                        }]);
                                                                        parentForceUpdate();
                                                                    }
                                                                    }><svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                                                            <use xlinkHref={`${PlusIconWhite}#PlusIconWhite`}></use>
                                                                        </svg> ADD
                                                                    </div>
                                                                    {/* </div> */}
                                                                </AccordionSummary>
                                                                <AccordionDetails className="d-flex flex-column">


                                                                    <Grid item xs={12}>
                                                                        <div className="row table-header">
                                                                            <div className="col-2 table-cell first-cell">
                                                                                First Name
                                                                            </div>
                                                                            <div className="col-2 table-cell">
                                                                                Last Name
                                                                            </div>
                                                                            <div className="col-3 table-cell">
                                                                                Email
                                                                            </div>
                                                                            <div className="col-3 table-cell">
                                                                                Type
                                                                            </div>
                                                                            <div className="col-2 table-cell">
                                                                                Actions
                                                                            </div>
                                                                        </div>
                                                                        {(!selectedThirdParties.thirdPartiesFullArray[si]?.Contacts || selectedThirdParties.thirdPartiesFullArray[si]?.Contacts?.length <= 0) && (
                                                                            <div className="no-data-alert">
                                                                                <h6>No Contacts Added!</h6>
                                                                            </div>
                                                                        )}
                                                                        {selectedThirdParties.thirdPartiesFullArray[si]?.Contacts?.map((c, ci) => (
                                                                            <CreateThirdPartyContactRow
                                                                                contact={c}
                                                                                index={ci}
                                                                                key={`contact-tp-${ci}`}
                                                                                fullContacts={selectedThirdParties.thirdPartiesFullArray[si].Contacts}
                                                                                parentForceUpdate={parentForceUpdate}
                                                                                carrierContactTypes={carrierContactTypes}
                                                                                requestSubmitted={requestSubmitted}
                                                                            />
                                                                        ))}
                                                                    </Grid>
                                                                    <div className="mt-2 text-danger" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}>
                                                                        <div className="d-flex flex-column">
                                                                            <div>{thirdPartyContactsValidation}</div>
                                                                            <div>{thirdPartyContactsFirstNameValidation}</div>
                                                                            <div>{thirdPartyContactsLastNameValidation}</div>
                                                                            <div>{thirdPartyContactsEmailValidation}</div>
                                                                            <div>{thirdPartyContactsTypeValidation}</div>
                                                                        </div>
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </Grid>

                                                        {/* Client Effective Date  */}

                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <h6 className="form-title">Client Effective Date <span className="text-danger mb-2 ml-2">*</span></h6>

                                                            <div className=" text-input w-100" style={{ padding: "0 20px" }}>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        className="w-100 mt-0 mb-0"
                                                                        autoOk={true}
                                                                        disableToolbar
                                                                        variant="inline"
                                                                        format="MM/dd/yyyy"
                                                                        margin="normal"
                                                                        id="frequency-date"
                                                                        value={s.ClientEffectiveDate !== (null || undefined) ? parseISO(s.ClientEffectiveDate) : null}
                                                                        onChange={(date) => {
                                                                            s.ClientEffectiveDate = (date == null ? null : Moment(date).format('YYYY-MM-DD'));
                                                                            parentForceUpdate();
                                                                        }}
                                                                        keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                                        </svg>}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{clientEffectiveDateValidation}</div>
                                                        </Grid>
                                                        {groupReq && (
                                                            <>
                                                                <Grid item xs={12} sm={12} md={6}>
                                                                    <h6 className="form-title">iSolved Contact
                                                                        <span className="text-danger mb-2 ml-2">*</span> </h6>

                                                                    <FormControl label="Select iSolved contact" focused={false} variant="outlined" className="w-100">
                                                                        <Select
                                                                            className="text-dropdown"
                                                                            value={s.ClientContact ?? ""}
                                                                            onChange={handelClientContact(s)}
                                                                        >
                                                                            <MenuItem value="">None</MenuItem>
                                                                            {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                                                                <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                                                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{clientContactValidation}</div>
                                                                </Grid>
                                                            </>
                                                        )}

                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <h6 className="form-title">Carrier Group Number</h6>

                                                            <input type="text" className="text-input w-100"
                                                                value={s.CarrierGroupNumber ?? ""}
                                                                onChange={e => {
                                                                    s.CarrierGroupNumber = e.target.value;
                                                                    parentForceUpdate();
                                                                }} />

                                                        </Grid>
                                                        {/* Migration */}
                                                        {groupReq && (
                                                            <>
                                                                <Grid item xs={12} sm={12} md={6}>
                                                                    <h6 className="form-title">Migration
                                                                        <span className="text-danger mb-2 ml-2">*</span> </h6>

                                                                    <FormControl label="Select Migration" focused={false} variant="outlined" className="w-100">
                                                                        <Select
                                                                            className="text-dropdown"
                                                                            value={s.Migration ?? groupReq?.groupRequest?.MigrationGroupRequest}
                                                                            onChange={e => {
                                                                                s.Migration = (e.target.value);
                                                                                // on change Migration >> change MigrationPhase
                                                                                // Migration == "No" >>  MigrationPhase = 0
                                                                                //  Migration == "EBN or Everything" >>  default migration phase (Phase 1 - Map Built) (2)
                                                                                s.MigrationPhase = (e.target.value == "No") ? 0 : 2
                                                                                parentForceUpdate();
                                                                            }}
                                                                        >
                                                                            <MenuItem value="No">No</MenuItem>
                                                                            <MenuItem value="eBenefits Network">eBenefits Network</MenuItem>
                                                                            <MenuItem value="Everything Benefits">Everything Benefits</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                                                    <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationValidationForm}</div>
                                                                </Grid>
                                                                {s.Migration && (s.Migration === "eBenefits Network" || s.Migration === "Everything Benefits") &&
                                                                    <>
                                                                        <Grid item xs={12} sm={12} md={6}>
                                                                            <h6 className="form-title">Migration Start Date
                                                                                <span className="text-danger mb-2 ml-2">*</span> </h6>

                                                                            <div className=" text-input w-100" style={{ padding: "0 20px" }}>
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <KeyboardDatePicker
                                                                                        className="w-100"
                                                                                        autoOk={true}
                                                                                        disableToolbar
                                                                                        variant="inline"
                                                                                        format="MM/dd/yyyy"
                                                                                        margin="normal"
                                                                                        id="migrationStart-date"
                                                                                        value={s.MigrationStartDate ? Moment(s.MigrationStartDate).format('MM/DD/YYYY') : null}
                                                                                        onChange={(date) => {
                                                                                            s.MigrationStartDate = (date == null ? null : Moment(date).format('YYYY-MM-DD'));
                                                                                            parentForceUpdate();
                                                                                        }}
                                                                                        keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                                                        </svg>}
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                            </div>
                                                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationStarDateValidationForm}</div>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={6}>
                                                                            <h6 className="form-title">Planned First Production Date
                                                                                <span className="text-danger mb-2 ml-1">*</span> </h6>

                                                                            <div className=" text-input w-100" style={{ padding: "0 20px" }}>
                                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                                    <KeyboardDatePicker
                                                                                        className="w-100"
                                                                                        autoOk={true}
                                                                                        disableToolbar
                                                                                        variant="inline"
                                                                                        format="MM/dd/yyyy"
                                                                                        margin="normal"
                                                                                        id="migrationStart-date"
                                                                                        value={s.PlannedFirstProductionDate ? Moment(s.PlannedFirstProductionDate).format('MM/DD/YYYY') : null}
                                                                                        onChange={(date) => {
                                                                                            s.PlannedFirstProductionDate = (date == null ? null : Moment(date).format('YYYY-MM-DD'));
                                                                                            parentForceUpdate();
                                                                                        }}
                                                                                        keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                                                                            <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                                                        </svg>}
                                                                                    />
                                                                                </MuiPickersUtilsProvider>
                                                                            </div>
                                                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectPlannedFirstProductionDateValidationForm}</div>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} md={6}>
                                                                            <h6 className="form-title">Migration Phase
                                                                                <span className="text-danger mb-2 ml-2">*</span> </h6>

                                                                            <FormControl label="Select migration phase" focused={false} variant="outlined" className="w-100">
                                                                                <Select
                                                                                    className="text-dropdown"
                                                                                    value={s.MigrationPhase ?? groupReq?.groupRequest?.MigrationPhaseID}
                                                                                    onChange={e => {
                                                                                        s.MigrationPhase = (e.target.value);
                                                                                        parentForceUpdate();
                                                                                    }}
                                                                                >
                                                                                    {migrationPhasesList && migrationPhasesList?.map((c, ci) => (
                                                                                        <MenuItem key={`migration-phase-${ci}`} value={c.Id}>{c.Name}</MenuItem>
                                                                                    ))}

                                                                                </Select>
                                                                            </FormControl>
                                                                            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
                                                                            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationPhaseValidationForm}</div>
                                                                        </Grid>
                                                                    </>
                                                                }
                                                            </>
                                                        )}
                                                        {/* Attachments */}
                                                        <Grid item xs={12}>
                                                            <h6 className="form-title">Third Party Attachments</h6>

                                                            <div className="row p-4" style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }} id="connectivity-request-files-section">
                                                                <div className="col-3">
                                                                    <div className="label-text mb-1">Choose Files</div>

                                                                    <div className="mt-2 w-100">
                                                                        <DropzoneArea
                                                                            maxFileSize={20 * 1024 * 1024}
                                                                            // add max size 20mb                                                                         
                                                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                                                            filesLimit={100}
                                                                            dropzoneText={"Companion Guide"}
                                                                            onDrop={(files) => handleCompanionGuideChangeDrag(files, s.Attachments)}
                                                                            showPreviewsInDropzone={false}
                                                                            showAlerts={false}
                                                                        />
                                                                    </div>
                                                                    <div className="mt-2 w-100">
                                                                        <DropzoneArea
                                                                            maxFileSize={20 * 1024 * 1024}
                                                                            // add max size 20mb                                                                             
                                                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                                                            filesLimit={100}
                                                                            dropzoneText={"Group Structure"}
                                                                            onDrop={(files) => handleGroupStructureChangeDrag(files, s.Attachments)}
                                                                            showPreviewsInDropzone={false}
                                                                            showAlerts={false}
                                                                        />
                                                                    </div>
                                                                    <div className="mt-2 w-100">
                                                                        <DropzoneArea
                                                                            maxFileSize={20 * 1024 * 1024}
                                                                            // add max size 20mb                                                                             
                                                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                                                            filesLimit={100}
                                                                            dropzoneText={"Connectivity Info"}
                                                                            onDrop={(files) => handleConnectivityInfoChangeDrag(files, s.Attachments)}
                                                                            showPreviewsInDropzone={false}
                                                                            showAlerts={false}
                                                                        />
                                                                    </div>
                                                                    <div className="mt-2 w-100">
                                                                        <DropzoneArea
                                                                            maxFileSize={20 * 1024 * 1024}
                                                                            // add max size 20mb                                                                             
                                                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                                                            filesLimit={100}
                                                                            dropzoneText={"Name Convention"}
                                                                            onDrop={(files) => handleNamingConventionInfoChangeDrag(files, s.Attachments)}
                                                                            showPreviewsInDropzone={false}
                                                                            showAlerts={false}
                                                                        />
                                                                    </div>
                                                                    <div className="mt-2 w-100">
                                                                        <DropzoneArea
                                                                            maxFileSize={20 * 1024 * 1024}
                                                                            // add max size 20mb                                                                             
                                                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                                                            filesLimit={100}
                                                                            dropzoneText={"Census File"}
                                                                            onDrop={(files) => handleCensusFileChangeDrag(files, s.Attachments)}
                                                                            showPreviewsInDropzone={false}
                                                                            showAlerts={false}
                                                                        />
                                                                    </div>
                                                                    <div className="mt-2 w-100">
                                                                        <DropzoneArea
                                                                            maxFileSize={20 * 1024 * 1024}
                                                                            // add max size 20mb                                                                             
                                                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                                                            filesLimit={100}
                                                                            dropzoneText={"Encryption Key"}
                                                                            onDrop={(files) => handleEncryptionFileChangeDrag(files, s.Attachments)}
                                                                            showPreviewsInDropzone={false}
                                                                            showAlerts={false}
                                                                        />
                                                                    </div>
                                                                    <div className="mt-2 w-100">
                                                                        <DropzoneArea
                                                                            maxFileSize={20 * 1024 * 1024}
                                                                            // add max size 20mb                                                                             
                                                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated"}
                                                                            filesLimit={100}
                                                                            dropzoneText={"Other"}
                                                                            onDrop={(files) => handleOtherFileChangeDrag(files, s.Attachments)}
                                                                            showPreviewsInDropzone={false}
                                                                            showAlerts={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {selectedThirdParties.thirdPartiesFullArray[si].Attachments?.length <= 0 ? (
                                                                    <div className="col-9 pr-0">
                                                                        <div className="label-text">Attached Files</div>
                                                                        <div className="files-list-div">
                                                                            <h6 className="color grey2">No files were attached</h6>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="col-9 files-list-div">
                                                                        <div className="label-text">Attached Files</div>

                                                                        <div className="files-list-div">
                                                                            {selectedThirdParties.thirdPartiesFullArray[si].Attachments?.length > 0 && selectedThirdParties.thirdPartiesFullArray[si].Attachments?.map((l, li) => (
                                                                                <TPFileRow
                                                                                    key={`files-list-${li}`}
                                                                                    file={l}
                                                                                    index={li}
                                                                                    uploadedFiles={selectedThirdParties.thirdPartiesFullArray[si].Attachments}
                                                                                    parentForceUpdate={parentForceUpdate}
                                                                                />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                            </div>
                                        )
                                    ))}
                                </div>
                            )
                            }
                        </div>
                    </AccordionDetails >
                </Accordion >
            </Paper >
        </div >
    )
}

export default CreateThirdParty;
