import React from "react";
import { Button } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";


function AnalystCreateFeedFormDictionary({ dictionaryList, onDictionaryList, parentForceUpdate, ...props }) {

    const [dictionaryArray, setDictionaryArray] = React.useState([]);
    const [, forceUpdate] = React.useReducer(x => !x, false);

    React.useEffect(() => {
        if (dictionaryList) onDictionaryList(dictionaryArray);
    }, [dictionaryArray]);

    return (
        <div>
            {dictionaryArray.length <= 0 && (
                <>
                    <div className="no-data-alert">
                        <h6>Please start adding dictionary words here if any</h6>
                    </div>
                    <div className="text-center">
                        <Button variant="contained" className="blue-btn" onClick={() => {
                            setDictionaryArray([...dictionaryArray, {
                                Key: "",
                                Value: "",
                            }]);
                            onDictionaryList([...dictionaryArray, {
                                Key: "",
                                Value: "",
                            }]);
                            forceUpdate();
                            parentForceUpdate();
                        }
                        }>
                            <label style={{ cursor: "pointer" }} className="mb-0">+ Dictionary Value</label>
                        </Button>
                    </div>
                </>
            )}

            {dictionaryArray.length > 0 && (
                <div className="text-right mb-2">
                    <Button variant="contained" className="blue-btn" onClick={() => {
                        setDictionaryArray([...dictionaryArray, {
                            Key: "",
                            Value: "",
                        }]);
                        onDictionaryList([...dictionaryArray, {
                            Key: "",
                            Value: "",
                        }]);
                        forceUpdate();
                        parentForceUpdate();
                    }
                    }>
                        <label style={{ cursor: "pointer" }} className="mb-0">+ Dictionary Value</label>
                    </Button>
                </div>
            )}

            {dictionaryArray.map((d, di) => (
                <div className="row mb-2" key={`dictionary-word-${di}`}>
                    <div className="col-xl-4 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Key</h6>
                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                        <input required type="text" className="text-input w-100" value={d.Key ?? ""} onChange={e => { d.Key = e.target.value; forceUpdate(); parentForceUpdate(); }} />
                    </div>
                    <div className="col-xl-4 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Value</h6>
                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                        <input required type="text" className="text-input w-100" value={d.Value ?? ""} onChange={e => { d.Value = e.target.value; forceUpdate(); parentForceUpdate(); }} />
                    </div>
                    <div className="col-xl-1 ml-2 alignvh-center">
                        <Button className="icon-btn" onClick={e => { dictionaryArray.splice(di, 1); forceUpdate(); parentForceUpdate(); }}>
                            {/* <DeleteForeverIcon style={{ width: "30px", height: "30px" }} color="action" /> */}
                            <svg  width="21" height="24" viewBox="0 0 21 24" >
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AnalystCreateFeedFormDictionary;