import React, { createRef, useEffect, useRef, useState } from "react";
import { Button, Switch, FormControlLabel } from "@material-ui/core";
import { getFormBlob, getFormOutputPDFBlob, saveSignedForm } from "../../Data/APIs"
import { Fields } from "pdf-wasm";
import { getDocument, GlobalWorkerOptions, version } from "pdfjs-dist";
import { useAuthentication } from "../../Data/Authentication";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { DialogActions } from "@mui/material";
import * as Apis from '../../Data/APIs';
import EditIcon from '@mui/icons-material/Edit';
import EditFormInputModal from "../ClientFormsTabs/tabs/EditFormInputModal";
import { useLoader } from "../Layout/Loader";
import moment from "moment";
import { toESTTime } from "./EmployeeDashboardPendingFormsTab";


GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`;
export { PDFDocumentProxy } from "pdfjs-dist";
// actAs >> Employee or Client
export function ConfirmSignFormModal(dialog, form, onSuccess, actAs, canEdit, openLoader, closeLoader, generatedForm = {}) {

    const signaturesRef = createRef();
    const fieldsRef = createRef();
    const formRef = createRef();
    const pdfRef = createRef();


    dialog.openDialog(
        (<div className="modal-head">
            Sign Form
        </div>),
        (<div className="modal-body-content">
            <EmployeeForm form={form} fieldsRef={fieldsRef} signaturesRef={signaturesRef} formRef={formRef} pdfRef={pdfRef} actAs={actAs} canEdit={canEdit} mainDialog={dialog} generatedForm={generatedForm} onSuccess={onSuccess} />
        </div>),
        [
            // { body: <Button type="button" className="black-btn">Sign</Button>, onClick: () => { saveSignatures() } },
            // { body: <Button type="button" className="black-outlined-btn">Cancel</Button>, onClick: () => { dialog.closeDialog() } }
        ], "full-screen"
    );
}
export function blobToBytes(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob);
        reader.onloadend = function () {
            resolve(reader.result);
        };
    });
}
async function openFieldsFromBlob(formBlob, actAs, loadAll = false) {
    const buffer = await blobToBytes(formBlob);
    let wsField = undefined;
    try {
        wsField = Fields.loadFormFields(new Uint8Array(buffer));
        let fields = wsField.getFields();
        let signatureFields = [];
        let textFields = [];
        let checkFields = [];
        let dependentSignatures = [];
        if (fields) {
            signatureFields = fields.filter(f => (f._type === "signature" && (!actAs || f._name.includes((actAs === "Client" ? "Client Signature" : 'Employee Signature')))));
            textFields = fields.filter(f => (f._type === "text" && (loadAll || f._isPortalEditable === true)));
            checkFields = fields.filter(f => (f._type === "check" && (loadAll || f._isPortalEditable === true)));
            dependentSignatures = fields.filter(f => (f._type === "signature") && f._name.includes('Dependent Signature'));
        }
        return [signatureFields, textFields, checkFields, dependentSignatures];
    } catch (err) {
        console.log(err);
    } finally {
        if (wsField) wsField.free();
    }
}
async function getPDFDocument(file) {
    const fileData = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            resolve(e?.target?.result);
        };
        reader.onerror = (err) => {
            reject(err);
        };
        reader.readAsDataURL(file);
    });
    if (fileData) {
        return getDocument(fileData).promise;
    }
}
async function loadPdfPageInfo(canvas, pdf, pageNo, scale = 1) {
    //const canvas = document.createElement("canvas");
    //const canvas = document.getElementById("mainPageCanvas");
    try {
        const page = await pdf.getPage(pageNo);
        const viewport = page.getViewport({ scale: scale });
        const context = canvas.getContext("2d");
        context.reset();
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context, viewport: viewport }).promise;

        //const data = context.getImageData(0, 0, canvas.width, canvas.height).data;

        //return { imageData: canvas.toDataURL(), width: viewport.width, height: viewport.height, imagePoints: data };
        return { width: viewport.width, height: viewport.height };
    } finally {
        //canvas.remove();
    }
}
async function convertTextToPath(text, textFont) {
    if (global.opentype) {
        const pen = 0.2;
        return await global.opentype
            .load("/signature-font.ttf")
            .then((font) => {
                let commands = [];
                if (text) {
                    //https://www.npmjs.com/package/opentype.js/v/0.3.0
                    const path = font.getPath(text, 0, 0, textFont);
                    commands.push({ PEN: [pen] });
                    path.commands.forEach((c) => {
                        if (c.type === "M") {
                            commands.push({ M: [c.x, c.y] });
                        } else if (c.type === "L") {
                            commands.push({ L: [c.x, c.y] });
                        } else if (c.type === "C") {
                            commands.push({
                                C: [c.x1, c.y1, c.x2, c.y2, c.x, c.y],
                            });
                        } else if (c.type === "Q") {
                            commands.push({ Q: [c.x1, c.y1, c.x, c.y] });
                        } else if (c.type === "Z") {
                            commands.push({ Z: [] });
                        }
                    });
                }
                return commands;
            })
            .catch((err) => console.log("Error fetching font:", err));
    }
}



export const EmployeeForm = ({
    form,
    signaturesRef,
    fieldsRef,
    formRef,
    pdfRef,
    canSign = true,
    actAs,
    canEdit,
    // setCanEdit,
    mainDialog,
    generatedForm,
    onSuccess,
    ...props
}) => {
    const { authUser } = useAuthentication();

    const [signatures, setSignatures] = useState({});
    const [editFields, setEditFields] = useState({});
    const [signatureFields, setSignatureFields] = useState();
    const [signatureDateFields, setSignatureDateFields] = useState();
    const [dependentSignaturesFields, setDependentSignaturesFields] = useState();
    const [signaturesError, setSignaturesError] = useState();
    const [submitClicked, setSumbitClicked] = useState();

    const [textFields, setTextFields] = useState();
    const [checkFields, setCheckFields] = useState();
    const [pdfDocument, setPdfDocument] = useState();
    const [page, setPage] = useState(1);
    const [scale, setScale] = useState(1.5);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const [userCanEdit, setuserCanEdit] = useState(false)
    const [showChild, setshowChild] = useState(false)
    const [currentEditField, setCurrentEditField] = useState(null);
    const [currentEditTextInput, setCurrentEditTextInput] = useState("");
    const [fieldsValues, setFieldsValues] = useState({});


    useEffect(() => {
        if (Object.getOwnPropertyNames(signatures).length > 0) {
            // check if there is required signature is missing
            let requiredFieldsArray = checkAllRequiredFieldsHandler();
            const allRequiredFieldsPresent =
                FilterReqiredFields(signatureFields, requiredFieldsArray).every(field => Object.getOwnPropertyNames(signatures).includes(field));

            if (allRequiredFieldsPresent) {
                setSignaturesError();
            } else {
                setSignaturesError('please fill all required signatures');
            }
        } else {
            setSignaturesError('please fill at least one signature');
        }
    }, [signatures])


    const pageCanvas = useRef();

    function checkForTrue(obj) {
        const filteredArray = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key].some(v => ["yes", "y", "1", "true"].includes(v.toLocaleLowerCase()))) {
                    filteredArray.push(key);
                }
            }
        }
        return filteredArray;
    }

    const checkAllRequiredFieldsHandler = () => {
        let requiredFieldsArray = []
        if (Object.values(fieldsValues).length > 0) {
            requiredFieldsArray = checkForTrue(fieldsValues)
        }

        return requiredFieldsArray;
    }

    const FilterReqiredFields = (ArrayToCheck, requiredFieldsArray) => {
        // ArrayToCheck (signatureFields or dependentSignaturesFields) , requiredFieldsArray
        const genericRequiredFieldsArray = [];
        ArrayToCheck.forEach(element => {
            if (requiredFieldsArray?.includes(element?._id)) genericRequiredFieldsArray.push(element?._id)
        });
        return genericRequiredFieldsArray
        // genericRequiredFieldsArray (array of form IDs)
    }


    const saveSignaturesCore = async (signatures) => {
        const formBuffer = await blobToBytes(formRef.current);
        const pdfBuffer = await blobToBytes(pdfRef.current);
        let wsForm = undefined;
        try {
            wsForm = Fields.loadFormFields(new Uint8Array(formBuffer));
            const outputPdfBuffer = wsForm.signForm(new Uint8Array(pdfBuffer), signatures);
            const blob = new Blob([outputPdfBuffer], {
                type: "application/pdf",
            });
            await saveSignedForm(form.PK, form.SK, blob, openLoader, closeLoader);
            if (onSuccess) {
                // we will send flag if there is required dependent signature to backend to prevent transmitting there form just to change a flag 
                onSuccess(FilterReqiredFields(dependentSignaturesFields, checkAllRequiredFieldsHandler()).length > 0);
            }
        } catch (err) {
            console.log(err);
        } finally {
            if (wsForm) wsForm.free();
        }
        mainDialog.closeDialog();
    }

    const saveSignatures = async () => {
        setSumbitClicked(true)


        if (signaturesRef.current && formRef.current && pdfRef.current) {
            const signatures = {};
            for (const p of Object.getOwnPropertyNames(signaturesRef.current)) {
                if (signaturesRef.current[p] && signaturesRef.current[p].length > 0) {
                    signatures[p] = JSON.stringify(signaturesRef.current[p]);
                }
            }
            // check if there is required signature is missing
            if (!signaturesError) {
                saveSignaturesCore(signatures);
            }

        }
    }


    const saveEditFields = async () => {
        if (pdfRef.current) {
            await Apis.saveSignedForm(form.PK, form.SK, pdfRef.current, openLoader, closeLoader);
            mainDialog.closeDialog();
        }
    }
    const updatePDFBlob = async (pdfBlob) => {
        pdfRef.current = pdfBlob;
        const pdfDocument = await getPDFDocument(pdfBlob);
        setPdfDocument(pdfDocument);
        const size = await loadPdfPageInfo(pageCanvas.current, pdfDocument, page, scale);
        setWidth(size.width ?? 0);
        setHeight(size.height ?? 0);
    }
    useEffect(() => {
        async function getFormBlobHandler() {
            if (form) {
                const formBlob = await getFormBlob(form.formId);
                formRef.current = formBlob;
                const [formSignatureFields, formTextFields, formCheckFields, dependentSignatures] = await openFieldsFromBlob(formBlob, actAs);
                setSignatureFields(formSignatureFields.filter(f => !f._name.toLowerCase().includes("date")));
                setSignatureDateFields(formSignatureFields.filter(f => f._name.toLowerCase().includes("date")));
                setSignatureFields(formSignatureFields.filter(f => !f._name.toLowerCase().includes("date")));
                setTextFields(formTextFields);
                setCheckFields(formCheckFields);
                setDependentSignaturesFields(dependentSignatures)
                const pdfBlob = await getFormOutputPDFBlob(form.PK, form.SK);
                await updatePDFBlob(pdfBlob);

                if (generatedForm?.formJson) {
                    try {
                        setFieldsValues(JSON.parse(generatedForm.formJson));
                    }
                    catch {
                        setFieldsValues({});
                    }
                }
            }
        }
        getFormBlobHandler();
    }, [form]);

    useEffect(() => {
        async function loadPdfPageInfoHandler() {
            if (pdfDocument?._pdfInfo) {
                const size = await loadPdfPageInfo(pageCanvas.current, pdfDocument, page, scale);
                setWidth(size.width ?? 0);
                setHeight(size.height ?? 0);
            }
        }
        loadPdfPageInfoHandler();
    }, [page, scale])



    useEffect(() => { if (signaturesRef) signaturesRef.current = signatures }, [signatures]);
    useEffect(() => { if (fieldsRef) fieldsRef.current = editFields }, [editFields]);
    const getFieldStyle = (f) => {
        const bLeft = pageCanvas.current?.offsetLeft;
        const bTop = pageCanvas.current?.offsetTop;
        return {
            width: `${scale * f._size.x}px`,
            height: `${scale * f._size.y}px`,
            left: `${scale * f._location.x}px`,
            top: `${scale * f._location.y}px`,
            display: f._pageIndex === (page - 1) ? "block" : "none"
        };
    }
    const toggleSignature = async (f) => {
        const currentSignatures = { ...(signatures ?? {}) };
        const dateField = signatureDateFields?.filter(d => d._name.toLowerCase().replace(" date", "") === f._name.toLowerCase())[0];
        if (currentSignatures[f._id]) {
            delete currentSignatures[f._id];
            if (dateField && currentSignatures[dateField._id]) {
                delete currentSignatures[dateField._id];
            }
        } else {
            let clientName = authUser?.attributes["custom:FirstName"] + ' ' + authUser?.attributes["custom:LastName"]
            currentSignatures[f._id] = await convertTextToPath(actAs == 'Client' ? clientName : form.empName, f._fontSize);
            if (dateField) {
                currentSignatures[dateField._id] = await convertTextToPath(new Date().toLocaleDateString(), f._fontSize);
            }
        }


        setSignatures(currentSignatures);
    }
    const getFieldPath = (f, type) => {
        if (f._pageIndex === (page - 1)) {
            switch (type) {
                case "signature":
                    const commands = (signatures ?? {})[f._id]
                    if (commands) {
                        let path = [];
                        let minY = 100000,
                            maxY = -100000;
                        let minX = 100000,
                            maxX = -100000;
                        commands.forEach((c) => {
                            let vs = c.M ?? c.L ?? c.Q ?? c.C ?? [];
                            vs.forEach((v, i) => {
                                if (i % 2 === 0) {
                                    if (v < minX) minX = v;
                                    if (v > maxX) maxX = v;
                                } else if (i % 2 === 1) {
                                    if (v < minY) minY = v;
                                    if (v > maxY) maxY = v;
                                }
                            });
                        });
                        let dX = 0 - minX;
                        if (f._hAlign === "middle") {
                            dX = f._size.x / 2 - (maxX - minX) / 2;
                        } else if (f._hAlign === "end") {
                            dX = f._size.x - maxX;
                        }
                        minX += dX;
                        maxX += dX;

                        let dY = 0 - maxY;
                        if (f._vAlign === "middle") {
                            dY = (maxY - minY) / 2 - f._size.y / 2;
                        } else if (f._vAlign === "start") {
                            dY = 0 - minY - f._size.y;
                        }
                        minY += dY;
                        maxY += dY;

                        let strokeWidth = '0';
                        let fill = "blue";
                        commands.forEach((c) => {
                            if (c) {
                                switch (Object.getOwnPropertyNames(c)[0]) {
                                    case "M":
                                        path.push(`M ${c.M[0] + dX} ${c.M[1] + dY}`);
                                        break;
                                    case "L":
                                        path.push(`L ${c.L[0] + dX} ${c.L[1] + dY}`);
                                        break;
                                    case "Q":
                                        path.push(
                                            `Q ${c.Q[0] + dX} ${c.Q[1] + dY} ${c.Q[2] + dX
                                            } ${c.Q[3] + dY}`
                                        );
                                        break;
                                    case "C":
                                        path.push(
                                            `C ${c.C[0] + dX} ${c.C[1] + dY} ${c.C[2] + dX
                                            } ${c.C[3] + dY} ${c.C[4] + dX} ${c.C[5] + dY
                                            }`
                                        );
                                        break;
                                    case "Z":
                                        path.push(`Z`);
                                        break;
                                    case "PEN":
                                        strokeWidth = `${c.PEN[0]}`;
                                        break;
                                    case "FILL":
                                        fill = "transparent";
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });

                        return (<>
                            {type == "signature" &&
                                <rect x="-1" y="-1" width={f._size.x + 2} height={f._size.y + 2} stroke="red" fill="transparent">
                                </rect>
                            }

                            <path
                                stroke="blue"
                                strokeWidth={strokeWidth}
                                fill={fill}
                                style={{ "pointerEvents": "none" }}
                                d={path?.join(" ")}
                                transform={`translate(0, ${f._size.y})`} />
                        </>);
                    }
                    return (<>
                        <rect x="-1" y="-1" width={f._size.x + 2} height={f._size.y + 2} stroke="red" fill="transparent">
                        </rect>
                        <text x="86" y="9" textAnchor="middle" alignmentBaseline="middle" fill="black" fontSize="10" className="signatureFont">
                            Please Sign Here
                        </text>
                    </>);

                case "date":
                    const newCommands = (signatures ?? {})[f._id]
                    if (newCommands) {
                        let path = [];
                        let minY = 100000,
                            maxY = -100000;
                        let minX = 100000,
                            maxX = -100000;
                        newCommands.forEach((c) => {
                            let vs = c.M ?? c.L ?? c.Q ?? c.C ?? [];
                            vs.forEach((v, i) => {
                                if (i % 2 === 0) {
                                    if (v < minX) minX = v;
                                    if (v > maxX) maxX = v;
                                } else if (i % 2 === 1) {
                                    if (v < minY) minY = v;
                                    if (v > maxY) maxY = v;
                                }
                            });
                        });
                        let dX = 0 - minX;
                        if (f._hAlign === "middle") {
                            dX = f._size.x / 2 - (maxX - minX) / 2;
                        } else if (f._hAlign === "end") {
                            dX = f._size.x - maxX;
                        }
                        minX += dX;
                        maxX += dX;

                        let dY = 0 - maxY;
                        if (f._vAlign === "middle") {
                            dY = (maxY - minY) / 2 - f._size.y / 2;
                        } else if (f._vAlign === "start") {
                            dY = 0 - minY - f._size.y;
                        }
                        minY += dY;
                        maxY += dY;

                        let strokeWidth = '0';
                        let fill = "blue";
                        newCommands.forEach((c) => {
                            if (c) {
                                switch (Object.getOwnPropertyNames(c)[0]) {
                                    case "M":
                                        path.push(`M ${c.M[0] + dX} ${c.M[1] + dY}`);
                                        break;
                                    case "L":
                                        path.push(`L ${c.L[0] + dX} ${c.L[1] + dY}`);
                                        break;
                                    case "Q":
                                        path.push(
                                            `Q ${c.Q[0] + dX} ${c.Q[1] + dY} ${c.Q[2] + dX
                                            } ${c.Q[3] + dY}`
                                        );
                                        break;
                                    case "C":
                                        path.push(
                                            `C ${c.C[0] + dX} ${c.C[1] + dY} ${c.C[2] + dX
                                            } ${c.C[3] + dY} ${c.C[4] + dX} ${c.C[5] + dY
                                            }`
                                        );
                                        break;
                                    case "Z":
                                        path.push(`Z`);
                                        break;
                                    case "PEN":
                                        strokeWidth = `${c.PEN[0]}`;
                                        break;
                                    case "FILL":
                                        fill = "transparent";
                                        break;
                                    default:
                                        break;
                                }
                            }
                        });

                        return (<>
                            <path
                                stroke="blue"
                                strokeWidth={strokeWidth}
                                fill={fill}
                                style={{ "pointerEvents": "none" }}
                                d={path?.join(" ")}
                                transform={`translate(0, ${f._size.y})`} />
                        </>);
                    }
                    return (<>
                        <rect x="-1" y="-1" width={f._size.x + 2} height={f._size.y + 2} stroke="transparent" fill="transparent">
                        </rect>

                    </>);
                case "text":
                    const textValue = (editFields ?? {})[f._id]
                    return (<>
                        <rect x="-1" y="-1" width={f._size.x + 2} height={f._size.y + 2} stroke="red" fill="transparent">
                        </rect>
                        {/* <DFTextField field={f} value={textValue} scale={scale} /> */}
                    </>)
                case "check":
                    const checkValue = (editFields ?? {})[f._id]
                    return (<>
                        <rect x="-1" y="-1" width={f._size.x + 2} height={f._size.y + 2} stroke="red" fill="transparent">
                        </rect>
                        {/* <DFCheckField field={f} value={checkValue} /> */}
                    </>)
                default:
                    break;

            }
        }
    }
    const editTextField = async (f) => {
        const currentEditFields = { ...(editFields ?? {}) };
        if (currentEditFields[f._id]) {
            delete currentEditFields[f._id];
            saveEditField(f, null);
        } else {
            // TODO: Steven add modal to enter a new text, and for now just add static text
            setCurrentEditField(f);
            setshowChild(true);
            //the following two steps will happen @the popup editField modal
            // currentEditFields[f._id] = currentEditTextInput;
            // saveEditField(f, currentEditTextInput);
        }
        setEditFields(currentEditFields);
    }
    const editCheckField = async (f) => {
        const currentEditFields = { ...(editFields ?? {}) };
        if (currentEditFields[f._id]) {
            delete currentEditFields[f._id];
            saveEditField(f, false);
        } else {
            currentEditFields[f._id] = true;
            saveEditField(f, true);
        }
        setEditFields(currentEditFields);
    }
    const saveEditField = async (f, value) => {
        if (fieldsRef?.current && formRef?.current && pdfRef?.current) {
            const formBuffer = await blobToBytes(formRef.current);
            const pdfBuffer = await blobToBytes(pdfRef.current);
            let wsForm = undefined;
            try {
                wsForm = Fields.loadFormFields(new Uint8Array(formBuffer));
                const outputPdfBuffer = wsForm.editForm(new Uint8Array(pdfBuffer), { [f._id]: value });
                const blob = new Blob([outputPdfBuffer], {
                    type: "application/pdf",
                });
                updatePDFBlob(blob);
            } catch (err) {
                console.log(err);
            } finally {
                if (wsForm) wsForm.free();
            }
        }
    }


    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();

    const handleDownloadFile = () => {
        let viewFormObject = {
            OutputFile: form?.outputFile,
            isDF: true,
            feedID: form?.feedID,
            executionID: form?.executionID,
            fileName:
                form?.empName + " - " +
                form?.carrierName + " - " +
                (form?.formGenerationTime ? moment(toESTTime(form.formGenerationTime)).format('MM-DD-YYYY') : "N/A")
        }
        openLoader();
        Apis.generateOldPdfUrlForEmployees(viewFormObject).then((r) => {
            window.open(r?.data);
            closeLoader();
            mainDialog.closeDialog()
        });
    }


    return (pdfDocument?._pdfInfo ? <>
        <div className="toolbar d-flex py-1">
            {/* TODO: add buttons */}
            Page ({`${page} - ${pdfDocument?._pdfInfo?.numPages}`}): <input type="range" step={1} min={1} max={pdfDocument?._pdfInfo?.numPages} value={page} onChange={(v) => setPage(+v.target.value)} />
            <span style={{ marginLeft: "4px", marginRight: "4px" }}>|</span>
            Scale ({`${(scale * 100).toFixed(0)}%`}): <input type="range" step={0.1} min={0.5} max={4} value={scale} onChange={(v) => setScale(+v.target.value)} />
            {canEdit &&
                <div className="d-flex ml-auto" >
                    <FormControlLabel
                        className={`dependents-switch ${userCanEdit && 'active'}`}
                        control={
                            <Switch
                                checked={userCanEdit}
                                onChange={() => setuserCanEdit(!userCanEdit)}
                            />
                        }
                        label="Edit"
                    />

                    {/* <EditIcon /><span className="ml-2">Edit</span> */}
                </div>
            }

        </div>
        <div className="body">
            {showChild &&
                <EditFormInputModal
                    showChild={showChild}
                    setshowChild={setshowChild}
                    currentEditField={currentEditField}
                    currentEditTextInput={currentEditTextInput}
                    setCurrentEditTextInput={setCurrentEditTextInput}
                    currentEditFields={editFields}
                    saveEditField={saveEditField}
                />}
            <div className="w-100 d-flex flex-column">
                {canSign && FilterReqiredFields(dependentSignaturesFields, checkAllRequiredFieldsHandler()).length > 0 &&
                    <div class="alert alert-warning fs-14 text-left lineHeight-1-6em mt-2" style={{ textWrap: 'wrap' }} role="alert">
                        "The dependent signature is required, once you save please download the form from View,
                        then re-upload a signed copy through the Upload button"
                    </div>
                }
                <div style={{ position: "relative" }}>
                    <canvas ref={pageCanvas} style={{ position: "relative" }} />
                    {(canSign || userCanEdit) &&
                        <svg xmlns="http://www.w3.org/2000/svg"
                            width={width}
                            height={height}
                            style={{ position: "absolute", left: 0, top: 0 }}>
                            {canSign && signatureFields?.map ? signatureFields.map((f, i) => (<g key={`${f._id}-${i}`} transform={`translate(${scale * f._location.x}, ${scale * f._location.y}) scale(${scale}, ${scale})`} onClick={() => toggleSignature(f)}>{getFieldPath(f, 'signature')}</g>)) : undefined}
                            {canSign && signatureDateFields.length > 0 ? signatureDateFields.map((f, i) => (<g key={`${f._id}-${i}`} transform={`translate(${scale * f._location.x}, ${scale * f._location.y}) scale(${scale}, ${scale})`}>{getFieldPath(f, 'date')}</g>)) : undefined}
                            {userCanEdit && textFields?.map ? textFields.map((f, i) => (<g key={`${f._id}-${i}`} transform={`translate(${scale * f._location.x}, ${scale * f._location.y}) scale(${scale}, ${scale})`} onClick={() => editTextField(f)}>{getFieldPath(f, 'text')}</g>)) : undefined}
                            {userCanEdit && checkFields?.map ? checkFields.map((f, i) => (<g key={`${f._id}-${i}`} transform={`translate(${scale * f._location.x}, ${scale * f._location.y}) scale(${scale}, ${scale})`} onClick={() => editCheckField(f)}>{getFieldPath(f, 'check')}</g>)) : undefined}
                        </svg>}
                </div>
            </div>
        </div>
        {submitClicked && signaturesError &&
            <p className="w-100 text-left fs-14 mt-2 text-danger">{signaturesError}</p>
        }
        {!canSign &&
            <DialogActions>
                {userCanEdit && <Button type="button" className="blue-btn" onClick={saveEditFields}>Save</Button>}
                <Button target="_blank" className="btn blue-btn text-uppercase" onClick={handleDownloadFile}>download</Button>
                <Button type="button" className="blue-outlined-btn" onClick={() => mainDialog.closeDialog()}>close</Button>
            </DialogActions>
        }
        {canSign &&
            <DialogActions>
                {<Button type="button" className="blue-btn" onClick={saveSignatures}>Save</Button>}
                <Button type="button" className="blue-outlined-btn" onClick={() => mainDialog.closeDialog()}>close</Button>
            </DialogActions>
        }

    </> : <>Loading ...</>);
}

// the following is rendering the fields in the HTML elements, but I replaced them with
// saving the updated fields in the PDF  direct, then we can remove them after confirming the logic.
const DFTextField = ({ field, value, scale, ...props }) => {
    let parts = [];
    if (field._splitFormat) {
        const formatParts = field._splitFormat.split(",");
        const formatNumbers = [];
        let units = 0;
        formatParts.forEach((p) => {
            const fNo = {
                p: parseInt(p.trim().split(" ")[0]) ?? 0,
                l: parseInt(p.trim().split(" ")[1]) ?? 1,
                w: parseFloat(p.trim().split(" ")[2]) ?? 0,
                s: 0,
                e: 0,
            };
            if (isNaN(fNo.p) || fNo.p < 0) fNo.p = 0;
            if (isNaN(fNo.l) || fNo.l < 0) fNo.l = 1;
            if (fNo.w <= 0 || isNaN(fNo.w)) {
                fNo.w = fNo.l === 0 ? 1 : fNo.l;
            }
            formatNumbers.push(fNo);
            units += fNo.w;
        });
        if (units) {
            formatNumbers.forEach((p, i) => {
                if (i === 0) {
                    p.s = 0;
                    p.e = p.s + (p.w * field._size.x) / units;
                } else {
                    p.s = formatNumbers[i - 1].e;
                    p.e = p.s + (p.w * field._size.x) / units;
                }
            });
            //parts = [formatNumbers[0]];
            parts = formatNumbers;
        } else parts = [];
    }
    function adjustTextPosition(x, y, w, h, element) {
        if (!element || !field) {
            return;
        }
        let dx = 0, dy = 0, dy1 = 0;
        if (field._vAlign === "start") {
            dy = 0;
            dy1 = element.getBoundingClientRect().height / 2 / scale;
        } else if (field._vAlign === "middle") {
            dy = h / 2;
            dy1 = dy + element.getBoundingClientRect().height / 4 / scale;
        } else {
            dy = h;
            dy1 = dy;
        }
        if (field._hAlign === "start") {
            dx = 0;
        } else if (field._hAlign === "middle") {
            dx = w / 2;
        } else {
            dx = w;
        }
        element.setAttribute("x", `${x + dx}`);
        element.setAttribute("y", `${y + dy1}`);
    }
    function getPartText(part) {
        if (part.l <= 0) return "";
        let text = value ?? "";
        if (part.p >= text.length) return "";
        text = text.substring(part.p);
        if (part.l > text.length) return text;
        return text.substring(0, part.l);
    }
    return (!parts || parts.length === 0 ? (<text
        ref={(f) => {
            adjustTextPosition(0, 0, field._size.x, field._size.y, f);
        }}
        textAnchor={field._hAlign}
        alignmentBaseline="baseline"
        fontSize={field._fontSize}
        fill="blue"
    >
        {value}
    </text>)
        : parts.map(p => (<text
            key={`Part-${p.s}`}
            ref={(f) => {
                adjustTextPosition(p.s, 0, p.e - p.s, field._size.y, f);
            }}
            textAnchor={field._hAlign}
            alignmentBaseline="baseline"
            fontSize={field._fontSize}
            fill="blue"
        >
            {getPartText(p)}
        </text>)));
}
const _checkPoints1 = [
    { x: 0.955592307692308, y: 9.39e-6 },
    { x: 0.960515384615385, y: 0.00973746153846154 },
    { x: 0.9641, y: 0.0211181538461538 },
    { x: 0.975284615384615, y: 0.0247239230769231 },
    { x: 0.730153076923077, y: 0.24984 },
    { x: 0.515692307692308, y: 0.505643076923077 },
    { x: 0.361434615384615, y: 0.821746153846154 },
    { x: 0.311076153846154, y: 0.852307692307692 },
    { x: 0.266973846153846, y: 0.889023076923077 },
    { x: 0.217845384615385, y: 0.920815384615385 },
    { x: 0.161128461538462, y: 0.786138461538461 },
    { x: 0.130153846153846, y: 0.625535384615385 },
    { x: 2.93438461538462e-7, y: 0.564312307692308 },
    { x: 0.0384618461538462, y: 0.522977692307692 },
    { x: 0.101333076923077, y: 0.466149230769231 },
    { x: 0.148513076923077, y: 0.480215384615385 },
    { x: 0.219999230769231, y: 0.501436923076923 },
    { x: 0.241538461538462, y: 0.608107692307692 },
    { x: 0.27723, y: 0.678156923076923 },
    { x: 0.446256153846154, y: 0.426053846153846 },
    { x: 0.656718461538462, y: 0.168616923076923 },
    { x: 0.955592307692308, y: 9.39e-9 },
];
const _checkPoints2 = [
    { x: 0, y: 0 },
    { x: 0.1, y: 0 },
    { x: 0.5, y: 0.45 },
    { x: 0.9, y: 0 },
    { x: 1, y: 0 },
    { x: 0.55, y: 0.5 },
    { x: 1, y: 1 },
    { x: 0.9, y: 1 },
    { x: 0.5, y: 0.55 },
    { x: 0.1, y: 1 },
    { x: 0, y: 1 },
    { x: 0.45, y: 0.5 },
    { x: 0, y: 0 },
    { x: 0.1, y: 0 },
];
const DFCheckField = ({ field, value, ...props }) => {
    if (!value) {
        return <></>;
    }
    const width = field._size.x;
    const height = field._size.y;
    if (field._checkType === "circle") {
        return (<circle
            cx={width / 2}
            cy={height / 2}
            r={Math.min(width, height) / 2}
            fill="blue"
            strokeWidth={1}
            stroke="blue" />);
    }
    if (field._checkType === "rectangle") {
        return (<rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill="blue"
            strokeWidth={1}
            stroke="blue" />);
    }
    let pathData = "";
    if (width > 0 && height > 0) {
        const data = [];
        if (field._checkType === "checkSign") {
            const checkPoints = _checkPoints1;
            data.push(`M ${checkPoints[0].x * width} ${checkPoints[0].y * height}`);
            for (let i = 1; i < checkPoints.length - 2; i += 3) {
                data.push(
                    `C ${checkPoints[i].x * width} ${checkPoints[i].y * height}, ${checkPoints[i + 1].x * width} ${checkPoints[i + 1].y * height}, ${checkPoints[i + 2].x * width
                    } ${checkPoints[i + 2].y * height}`
                );
            }
        } else if (field._checkType === "xSign") {
            const checkPoints = _checkPoints2;
            data.push(`M ${checkPoints[0].x * width} ${checkPoints[0].y * height}`);
            for (let i = 1; i < checkPoints.length; i++) {
                data.push(`L ${checkPoints[i].x * width} ${checkPoints[i].y * height}`);
            }
        }
        pathData = data.join(" ");
    }
    return (<path
        d={pathData}
        stroke="blue"
        fill="blue"
        style={{ pointerEvents: "none" }} />);
}

// ======================================================[NEW COMPONENT FOR EMPLOYEE]
export const EmployeeFormView = ({
    form,
    generatedForm,
    formRef,
    pdfRef,
    mainDialog,
    ...props
}) => {
    const { authUser } = useAuthentication();

    const [signatures, setSignatures] = useState({});
    const [editFields, setEditFields] = useState({});
    const [signatureFields, setSignatureFields] = useState();
    const [signatureDateFields, setSignatureDateFields] = useState();
    const [textFields, setTextFields] = useState();
    const [checkFields, setCheckFields] = useState();
    const [pdfDocument, setPdfDocument] = useState();
    const [scale, setScale] = useState(1.5);
    const [pageSizes, setPageSizes] = useState([]);
    const [fieldsValues, setFieldsValues] = useState({});

    const pagesCanvas = useRef([]);
    const updatePDFBlob = async (pdfBlob) => {
        pdfRef.current = pdfBlob;
        const pdfDocument = await getPDFDocument(pdfBlob);
        pagesCanvas.current = [];
        setPdfDocument(pdfDocument);
        let sizes = [];
        for (var i = 0; i < pagesCanvas.current.length; i++) {
            sizes.push(await loadPdfPageInfo(pagesCanvas.current[i], pdfDocument, i + 1, scale));
        }
        setPageSizes(sizes);
    }
    useEffect(() => {
        async function getFormBlobHandler() {
            if (form) {
                const formBlob = await getFormBlob(form.formId);
                formRef.current = formBlob;
                const [formSignatureFields, formTextFields, formCheckFields] = await openFieldsFromBlob(formBlob, undefined, true);
                setSignatureFields(formSignatureFields.filter(f => !f._name.toLowerCase().includes("date")));
                setSignatureDateFields(formSignatureFields.filter(f => f._name.toLowerCase().includes("date")));
                setTextFields(formTextFields);
                setCheckFields(formCheckFields);
                const pdfBlob = await getFormOutputPDFBlob(form.PK, form.SK);
                await updatePDFBlob(pdfBlob);
                if (generatedForm?.formJson) {
                    try {
                        setFieldsValues(JSON.parse(generatedForm.formJson));
                    }
                    catch {
                        setFieldsValues({});
                    }
                }
            }
        }
        getFormBlobHandler();
    }, [form]);

    useEffect(() => {
        async function loadPdfPageInfoHandler() {
            if (pdfDocument?._pdfInfo) {
                let sizes = [];
                for (var i = 0; i < pagesCanvas.current.length; i++) {
                    sizes.push(await loadPdfPageInfo(pagesCanvas.current[i], pdfDocument, i + 1, scale));
                }
                setPageSizes(sizes);
            }
        }
        loadPdfPageInfoHandler();
    }, [scale])


    const getFieldPath = (f, pageIndex) => {
        if (f?._pageIndex === pageIndex && f?._id && generatedForm?.formMissingFields && generatedForm?.formMissingFields.includes(f?._id)) {
            return (<rect x="-1" y="-1" width={f._size.x + 2} height={f._size.y + 2} stroke="red" fill="transparent">
            </rect>);
        }
        else if (f?._pageIndex === pageIndex && f._type === "signature" && fieldsValues) {
            const fValue = fieldsValues[f._id];
            if (fValue?.length > 0 && fValue.some(v => ["yes", "y", "1", "true"].includes(v.toLocaleLowerCase()))) {
                return (<rect x="-1" y="-1" width={f._size.x + 2} height={f._size.y + 2} stroke="red" fill="transparent">
                </rect>);
            }
        }
    }
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();

    const handleDownloadFile = () => {
        let viewFormObject = {
            OutputFile: form?.outputFile,
            isDF: true,
            feedID: form?.feedID,
            executionID: form?.executionID,
            fileName:
                form?.empName + " - " +
                form?.carrierName + " - " +
                (form?.formGenerationTime ? moment(toESTTime(form.formGenerationTime)).format('MM-DD-YYYY') : "N/A")
        }
        openLoader();
        Apis.generateOldPdfUrlForEmployees(viewFormObject).then((r) => {
            window.open(r?.data);
            closeLoader();
            mainDialog.closeDialog()
        });
    }

    // pdfDocument?._pdfInfo?.numPages
    return (pdfDocument?._pdfInfo ? <>
        <div className="toolbar d-flex py-1">
            Scale ({`${(scale * 100).toFixed(0)}%`}): <input type="range" step={0.1} min={0.5} max={4} value={scale} onChange={(v) => setScale(+v.target.value)} />
        </div>
        <div className="body" style={{ overflow: "auto", width: "80vw", height: "70vh" }}>
            {[...Array(pdfDocument?._pdfInfo?.numPages ?? 0)].map((_, pi) =>
                <div key={`Page-${pi}`} style={{ position: "relative", display: "table", borderStyle: "solid", borderWidth: 1 }}>
                    <canvas ref={(r) => pagesCanvas.current[pi] = r} style={{ position: "relative", display: "block" }} />
                    <svg xmlns="http://www.w3.org/2000/svg"
                        width={pageSizes[pi]?.width}
                        height={pageSizes[pi]?.height}
                        style={{ position: "absolute", left: 0, top: 0 }}>
                        {textFields?.map ? textFields.map((f, i) => (<g key={`${f._id}-${i}`} transform={`translate(${scale * f._location.x}, ${scale * f._location.y}) scale(${scale}, ${scale})`} >{getFieldPath(f, pi)}</g>)) : undefined}
                        {checkFields?.map ? checkFields.map((f, i) => (<g key={`${f._id}-${i}`} transform={`translate(${scale * f._location.x}, ${scale * f._location.y}) scale(${scale}, ${scale})`} >{getFieldPath(f, pi)}</g>)) : undefined}
                        {signatureFields?.map ? signatureFields.map((f, i) => (<g key={`${f._id}-${i}`} transform={`translate(${scale * f._location.x}, ${scale * f._location.y}) scale(${scale}, ${scale})`} >{getFieldPath(f, pi)}</g>)) : undefined}
                        {signatureDateFields?.map ? signatureDateFields.map((f, i) => (<g key={`${f._id}-${i}`} transform={`translate(${scale * f._location.x}, ${scale * f._location.y}) scale(${scale}, ${scale})`} >{getFieldPath(f, pi)}</g>)) : undefined}
                    </svg>
                </div>
            )}
        </div>
        <DialogActions>
            <Button target="_blank" className="btn blue-btn text-uppercase" onClick={handleDownloadFile}>download</Button>
            <Button type="button" className="blue-outlined-btn" onClick={() => mainDialog.closeDialog()}>close</Button>
        </DialogActions>
    </> : <>Loading ...</>);
}
