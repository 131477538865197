import React from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Paper, Select } from "@material-ui/core";
import CarrierPlansAdmin from "./CarrierPlansAdmin";
import RelationshipCodesAdmin from "./RelationshipCodesAdmin";
import AddIcon from '@material-ui/icons/Add';
import GenericNewSectionAdmin from "./GenericNewSectionAdmin";
import { useLoader } from "../../../Layout/Loader";
import { useHistory } from "react-router-dom";
import * as APIs from "../../../../Data/APIs";

function PreMappingStepperAdmin({ onPreMapSubmitted, preMappingObject, preMappingBasicData, setPreMappingObjectBasicData, preMappingSections, primaryAdmin, secondaryAdmin, isSaveActive, setIsSaveActive, ...props }) {

    const [stepsList, setStepsList] = React.useState([]);
    const { openLoader, closeLoader } = useLoader();
    const routeHistory = useHistory();
    const [isNextActive, setIsNextActive] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [preMapSubmitted, setPreMapSubmitted] = React.useState(false);

    const [admins, setAdmins] = React.useState([]);

    React.useEffect(() => {
        setStepsList(preMappingSections?.map(s => ({
            title: s.Title,
            icon: s.Title === "Carrier Plans" ? <LocalHospitalIcon /> : s.Title === "Relationship Codes" ? <SupervisorAccountIcon /> : <AddIcon />
        })))
    }, [preMappingSections])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    React.useEffect(() => {
        setAdmins([
            {
                Name: `${primaryAdmin?.FirstName} ${primaryAdmin?.LastName}`,
                Email: primaryAdmin?.Email,
                TypeID: 1,
                TypeName: "Primary",
                UserID: primaryAdmin?.CognitoID
            },
            {
                Name: `${secondaryAdmin?.FirstName} ${secondaryAdmin?.LastName}`,
                Email: secondaryAdmin?.Email,
                TypeID: 2,
                TypeName: "Secondary",
                UserID: secondaryAdmin?.CognitoID
            }] ?? [])
    }, [primaryAdmin, secondaryAdmin])


    React.useEffect(() => {
        if (!preMappingBasicData) return;
        preMappingBasicData.Admins = admins;
    }, [admins])

    const handlePreMappingSubmit = () => {
        setPreMapSubmitted(true);
        onPreMapSubmitted(true);
        if (primaryAdmin === "") return;
        const submitObject = preMappingObject;

        APIs.submitPreMappingFormAdmin(submitObject).then((r) => (
            routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${r.ConnectivityRequestID}/ViewPreMappingFormISolved`)
        ));
    }

    return (
        <div id="premapping-form">
            <Paper elevation={0} className="p-5 mb-3">
                <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
                    {stepsList?.map((s, si) => (
                        <Step className={activeStep === si ? "active" : ""} key={`steps-list-${si}`}>
                            <StepLabel icon={s.icon}>{s.title}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className="mt-5">
                    <div>
                        {preMappingSections?.map((c, ci) =>
                            activeStep === ci && (
                                c.Type?.toLowerCase() === "carrierplan" ? <CarrierPlansAdmin key={`carrier-plan-view-${ci}`} carrierPlansSection={c} setIsNextActive={setIsNextActive} setIsSaveActive={setIsSaveActive} />
                                    : c.Type?.toLowerCase() === "relationshipcode" ? <RelationshipCodesAdmin key={`relationship-codes-view-${ci}`} relationshipCodesSection={c} />
                                        : <GenericNewSectionAdmin key={`generic-view-${ci}`} genericSection={c} />)
                        )}
                    </div>
                    <div style={{ textAlign: "end" }} className="mt-5">
                        <Button disabled={activeStep === 0} className="blue-outlined-btn mr-2" onClick={handleBack}>Back</Button>
                        <Button className="blue-btn" disabled={!isNextActive && activeStep !== stepsList?.length - 1} onClick={activeStep === preMappingSections?.length - 1 ? handlePreMappingSubmit : handleNext}>
                            {activeStep === preMappingSections?.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                        {activeStep !== preMappingSections?.length - 1 &&
                            <Button disabled={!isSaveActive} className="blue-btn ml-2" onClick={handlePreMappingSubmit}>
                                Save
                            </Button>}
                    </div>
                </div>
            </Paper>
        </div >
    )
}

export default PreMappingStepperAdmin;