import React, { createRef } from "react";
import { useParams } from "react-router-dom";
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import FormsTransmissionLogEmployeesDashboardFilter from "./FormsTransmissionLogEmployeesDashboardFilter";
import SingleEmployeeFormTransmissions from "./SingleEmployeeFormTransmissions";
import { Button, Paper, FormControlLabel, Checkbox } from "@material-ui/core";
import BreadcrumbSteps from "../Layout/BreadcrumbSteps";
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { SuccessModal } from "../Modals/SuccessModal";
import { ConfirmModal } from "../Modals/ConfirmModal";
import { FailureModal } from "../Modals/FailureModal";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import RejectionReasonModal from "./RejectionReasonModal";
import Moment from 'moment';
import { toESTTime } from "../../_helper";
import GenericTable from "../Layout/GenericTable";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';

import { EmployeeFormView } from "../EmployeeViews/EmployeeSignFormModal";

import downloadIconSVG from "../../Icons/downloadIcon.svg";
import viewActionItemSVG from "../../Icons/viewActionItem.svg";
import thumbsDown from "../../Icons/thumbs-down.svg";
import Truncate from "../Layout/Truncate";

function FormsTransmissionLogEmployeesDashboard() {

    const { ExecutionID } = useParams();
    const { StepID } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);

    const [employeesTransmissionsList, setEmployeesTransmissionsList] = React.useState([]);
    const [employeesTransmissionsResponse, setEmployeesTransmissionsResponse] = React.useState("");
    const [employeesFilterObject, setEmployeesFilterObject] = React.useState();

    const [employeeNameFilter, setEmployeeNameFilter] = React.useState("");
    const [employeeSSNFilter, setEmployeeSSNFilter] = React.useState("");

    const [selectedEmployees, setSelectedEmployees] = React.useState([]);
    const [refreshClicked, setRefreshClicked] = React.useState(false);

    const [selectAllEmployeesChecked, setSelectAllEmployeesChecked] = React.useState(false);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const getData = () => {
        openLoader();
        const employeesTransmissions = {};
        employeesTransmissions.transmissionID = ExecutionID;
        employeesTransmissions.lsi = StepID;
        if (employeeNameFilter !== "")
            employeesTransmissions.name = [`${employeeNameFilter}`];
        if (employeeSSNFilter !== "")
            employeesTransmissions.ssn = [`${employeeSSNFilter}`];

        setEmployeesFilterObject(employeesTransmissions);

        APIs.getEmployeesFromDigitalFormTransmissionLogs(employeesTransmissions).then((r) => {
            setEmployeesTransmissionsList(Array.isArray(r?.data) ? r?.data : []);

            setRefreshClicked(false);
            closeLoader();
            setEmployeesTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
        });
    };


    React.useEffect(() => {
        getData();
    }, [employeeNameFilter, employeeSSNFilter, refreshClicked === true])

    const handleAddMore = () => {
        if (employeesTransmissionsResponse === null) return;
        APIs.getEmployeesFromDigitalFormTransmissionLogs({ ...employeesTransmissionsResponse, ...employeesFilterObject }).then((r) => {
            let employeesTransmissionsListArray = [...employeesTransmissionsList];
            for (var i = 0; i < r?.data?.length; i++) {
                employeesTransmissionsListArray.push(r?.data[i]);
            }
            setEmployeesTransmissionsList(employeesTransmissionsListArray);
            setRefreshClicked(false);
            closeLoader();
            setEmployeesTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
        });
    }

    const handleRejectForms = (rejectReason, rejectionValidationForm) => {
        if (rejectionValidationForm) return;
        openLoader();
        dialog.closeDialog();
        let rejectFormObject = {
            empArray: selectedEmployees?.map(employee => (
                {
                    SK: employee?.SK,
                    empDOB: employee?.empDOB,
                    empKey: employee?.empKey,
                    empName: employee?.empName,
                    empSSN: employee?.empSSN,
                    outputFile: employee?.OutputFile,

                    executionID: ExecutionID,
                    feedID: selectedEmployees[0]?.FeedID,
                    rejectionReason: rejectReason,
                }
            ))
        }

        APIs.rejectForm(rejectFormObject).then((r) => {
            window.location.reload();
        });
    }

    const handleTransmitForms = () => {
        openLoader();
        let transmitFormObject = {
            feedID: selectedEmployees[0]?.FeedID,
            empArray: selectedEmployees?.map(employee => (
                {
                    SK: employee?.SK,
                    empDOB: employee?.empDOB,
                    empKey: employee?.empKey,
                    empName: employee?.empName,
                    empSSN: employee?.empSSN,
                    outputFile: employee?.OutputFile,
                    executionID: ExecutionID,
                    formType: employee?.formType
                }
            ))
        }

        APIs.transmitForm(transmitFormObject).then((r) => {
            window.location.reload();
        });
    }

    const handleSelectAllEmployeesChange = (value) => (e) => {
        setSelectAllEmployeesChecked(e.target.checked);
    }

    const handleSelectAllEmployeesClick = (value) => (event) => {
        let selectedEmployeesArray = selectedEmployees;
        if (!value || value === "") selectedEmployeesArray = [];
        else if (selectedEmployeesArray.indexOf(...value) >= 0) {
            selectedEmployeesArray.splice(selectedEmployeesArray.indexOf(...value));
        }
        else {
            selectedEmployeesArray.length = 0;
            selectedEmployeesArray.push(...value);
        }
        setSelectedEmployees([...selectedEmployeesArray]);
    }

    const checkExecutionStatusForSelectAll = (s) => {
        return s.ExecutionStatus !== 'Event not captured in available forms' &&
            s.ExecutionStatus !== 'Failed Empolyeee Generator' &&
            s.ExecutionStatus !== "Rejected" &&
            s.ExecutionStatus !== "Pass"
    }
    // =============================================================NEW TABLE UI 
    const handleEmployeeSelectionClick = (value) => (event) => {
        let selectedEmployeesArray = selectedEmployees;

        let currentIndex = selectedEmployeesArray.findIndex(item => item?.SK == value?.SK)

        if (!value || value === "") selectedEmployeesArray = [];
        else if (currentIndex >= 0) {
            selectedEmployeesArray.splice(currentIndex, 1);
        }
        else {
            selectedEmployeesArray.push(value);
        }
        setSelectedEmployees([...selectedEmployeesArray]);
    }

    const handleViewNewForm = (form) => async () => {
        const pk = `EMP__${ExecutionID}`;
        const sk = form.SK;
        const forms = await APIs.getGeneratedEmployeeForms(form.FeedID, form.isolvedEmployeeID);
        const generatedForm = await APIs.getGeneratedEmployeeForm(forms[0].PK, forms[0].SK);
        const modalForm = {
            PK: pk,
            SK: sk,
            formId: generatedForm.formId,
            empName: form.empName,
            empSSN: form.empSSN,
            outputFile: form.OutputFile,
            feedID: form.FeedID,
            executionID: ExecutionID,
            carrierName: form.CarrierName,
            formGenerationTime: form.ProcessingEndTime
        };
        const formRef = createRef();
        const pdfRef = createRef();
        dialog.openDialog(
            <></>,
            <>
                <EmployeeFormView
                    form={modalForm}
                    generatedForm={generatedForm}
                    formRef={formRef}
                    pdfRef={pdfRef}
                    mainDialog={dialog}
                />
            </>,
        )
    }

    const handleViewForm = (empRecord) => (e) => {
        if (!((empRecord.ExecutionStatus === 'Event not captured in available forms'
            && empRecord?.ProcessingLog !== "Missing Required Field") ||
            empRecord.ExecutionStatus === 'Failed Empolyeee Generator')) {
            openLoader();
            let viewFormObject = {
                executionId: ExecutionID,
                feedID: empRecord?.FeedID,
                employeeObject:
                {
                    empDOB: empRecord?.empDOB,
                    empKey: empRecord?.empKey,
                    empName: empRecord?.empName,
                    empSSN: empRecord?.empSSN
                }
            }
            APIs.generatePdfUrlForEmployees(viewFormObject).then((r) => {
                window.open(r?.filePath);
                closeLoader();
            });
        }
    }


    const handleViewOldForm = (empRecord) => (e) => {
        if (!((empRecord.ExecutionStatus === 'Event not captured in available forms'
            && empRecord?.ProcessingLog !== "Missing Required Field") ||
            empRecord.ExecutionStatus === 'Failed Empolyeee Generator')) {
            openLoader();
            let viewFormObject = {
                OutputFile: empRecord?.OutputFile?.split("?AWSAccessKey")[0].substring(
                    empRecord?.OutputFile?.split("?AWSAccessKey")[0]?.indexOf("Engine/"),
                    empRecord?.OutputFile?.split("?AWSAccessKey")[0]?.length),
            }

            APIs.generateOldPdfUrlForEmployees(viewFormObject).then((r) => {
                window.open(r?.data);
                closeLoader();
            });
        }
    }


    const baseColumnsArray = [
        {
            name: 'Employee Name',
            sortable: false,
            id: 'empName',
            selector: employeeTransmission => employeeTransmission?.empName
        },
        {
            name: 'Employee SSN',
            sortable: false,
            width: '130px',
            id: 'empSSN',
            selector: employeeTransmission => employeeTransmission?.empSSN
        },
        {
            name: 'Employee Date of Birth',
            width: '130px',
            sortable: false,
            id: 'empDOB',
            selector: employeeTransmission => Moment(employeeTransmission?.empDOB).format('MM/DD/YYYY')
        },
        {
            name: 'Timestamp',
            width: '100px',
            sortable: false,
            id: 'ProcessingStartTime',
            selector: employeeTransmission => Moment(toESTTime(employeeTransmission?.ProcessingStartTime)).format("MM/DD/YYYY HH:mm:ss")
        },
        {
            name: 'Execution Status',
            sortable: false,
            id: 'ExecutionStatus',
            selector: employeeTransmission =>
                // StepID == "4"
                StepID == "4" ?
                    <>
                        <div className={employeeTransmission?.ExecutionStatus === "Rejected" ? "d-block" : ""}>
                            <div style={(employeeTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                                className={`${(employeeTransmission?.ExecutionStatus)?.includes("Fail") ? "red background status-div" : (employeeTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "yellow-secondary-color background status-div" : ""}`} >
                                {employeeTransmission?.ExecutionStatus}
                            </div>
                            {
                                employeeTransmission?.ExecutionStatus === "Rejected" && (
                                    <CustomTooltip TransitionComponent={Zoom} title={`Rejection Reason: \n${employeeTransmission?.rejectionReason}`}>
                                        <NotListedLocationIcon />
                                    </CustomTooltip>
                                )
                            }
                        </div >
                    </> :
                    // StepID == "5"
                    StepID == "5" ?
                        <>
                            <div
                                style={(employeeTransmission?.ExecutionStatus).includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                                className={`${(employeeTransmission?.ExecutionStatus).includes("Fail") ? "red background status-div" : (employeeTransmission?.ExecutionStatus).includes("Pending Transmission") ? "yellow-secondary-color background status-div" : ""}`} >
                                {employeeTransmission?.ExecutionStatus}
                            </div>
                        </> :
                        // =====StepID (not 4 or 5)
                        <>
                            <div style={(employeeTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                                className={`${(employeeTransmission?.ExecutionStatus)?.includes("Fail") ? "red background status-div" : (employeeTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "yellow-secondary-color background status-div" : ""} d-flex`} >
                                {
                                    <>
                                        {employeeTransmission?.ExecutionStatus}
                                        {/* 3 : Tracker */}
                                        {StepID === "3" && (
                                            <>

                                                <a href={employeeTransmission?.OutputFile} className="ml-auto mr-2 btn-like-link" role="button">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" >
                                                        <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                                                    </svg> Download
                                                </a>
                                            </>)}
                                    </>
                                }
                            </div>
                        </>

        },
        {
            name: 'Processing Log',
            sortable: false,
            id: 'ProcessingLog',
            sortable: false,
            // width: '200px',
            selector: employeeTransmission => <Truncate lines={3} ellipsis={<span>... <a className="fs-13" style={{ color: "#2c87f0", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog("Processing Logs:", <div><p style={{ wordBreak: "break-word" }}>{employeeTransmission?.ProcessingLog}</p></div>, []) }}>Read More</a></span>}>
                {employeeTransmission?.ProcessingLog}
            </Truncate>
        },
    ];


    const pushToArrayBasedOnStepID = () => {

        let itemsToInsert = [
            {
                index: 0,
                value: {
                    name: <CustomTooltip TransitionComponent={Zoom}
                        title={employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)).length <= 0 ? "There are no employees left to be selected" : "Select All"}>
                        <span>
                            <FormControlLabel
                                style={{ display: "unset", margin: "0" }}
                                aria-label="Acknowledge"
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={
                                    <Checkbox
                                        disabled={employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)).length <= 0}
                                        id={`employees-select-all`}
                                        value={employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s))}
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        checked={
                                            employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)).length > 0 &&
                                            selectedEmployees.length === employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)).length}
                                        onChange={handleSelectAllEmployeesChange(employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)))}
                                        onClick={handleSelectAllEmployeesClick(employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)))}
                                        checkedIcon={<DoneOutlineIcon />}
                                        color="default"
                                    />
                                }
                            />
                        </span>
                    </CustomTooltip>,
                    width: '100px',
                    centerText: true,
                    sortable: false,
                    doesNotHaveToolTip: true,
                    id: 'selectAll',
                    selector: employeeTransmission =>
                        <FormControlLabel
                            style={{ margin: "0", display: "unset" }}
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={
                                <Checkbox
                                    disabled={(employeeTransmission.ExecutionStatus === 'Event not captured in available forms' ||
                                        employeeTransmission.ExecutionStatus === "Failed Empolyeee Generator" ||
                                        employeeTransmission.ExecutionStatus === 'Rejected' ||
                                        employeeTransmission.ExecutionStatus === 'Pass'
                                    )}
                                    value={employeeTransmission}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checked={selectedEmployees && selectedEmployees.findIndex(item => item?.SK == employeeTransmission?.SK) >= 0}
                                    // selectedOEs && selectedOEs.findIndex(item => item?.Id == o?.Id) >= 0
                                    onClick={handleEmployeeSelectionClick(employeeTransmission)}
                                    checkedIcon={<DoneOutlineIcon />}
                                    color="default"
                                />
                            }
                        />
                },
                condition: StepID == "4"
            },


        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumnsArray];

        itemsToInsert.forEach(item => {
            if (item?.condition)
                finalColumnsArray.splice(item.index, 0, item.value);
        });

        let ActionsColumn = {
            name: 'Actions',
            sortable: false,
            id: 'Actions',
            width: '200px',
            selector: employeeTransmission =>
                <>
                    {StepID == "4" && (
                        <>
                            <CustomTooltip TransitionComponent={Zoom}
                                title={((employeeTransmission.ExecutionStatus === 'Event not captured in available forms' && employeeTransmission?.ProcessingLog !== "Missing Required Field") ||
                                    employeeTransmission.ExecutionStatus === 'Failed Empolyeee Generator') ? employeeTransmission.ExecutionStatus : "View Form"}>
                                <div style={{ display: 'grid' }}>
                                    <Button
                                        disabled={((employeeTransmission.ExecutionStatus === 'Event not captured in available forms' && employeeTransmission?.ProcessingLog !== "Missing Required Field") ||
                                            employeeTransmission.ExecutionStatus === 'Failed Empolyeee Generator')
                                        }
                                        onClick={handleViewNewForm(employeeTransmission)}>

                                        <svg width="18" height="18" viewBox="0 0 18 18" className="mr-1" >
                                            <use xlinkHref={`${viewActionItemSVG}#viewAIIcon`}></use>
                                        </svg>
                                        <span className="mr-auto">Open</span>
                                    </Button>
                                </div>
                            </CustomTooltip>

                            <CustomTooltip TransitionComponent={Zoom}
                                title={((employeeTransmission.ExecutionStatus === 'Event not captured in available forms' && employeeTransmission?.ProcessingLog !== "Missing Required Field") ||
                                    employeeTransmission.ExecutionStatus === 'Failed Empolyeee Generator') ? employeeTransmission.ExecutionStatus : "Download Form"}>
                                <div style={{ display: 'grid' }}>
                                    <Button
                                        disabled={((employeeTransmission.ExecutionStatus === 'Event not captured in available forms' && employeeTransmission?.ProcessingLog !== "Missing Required Field") ||
                                            employeeTransmission.ExecutionStatus === 'Failed Empolyeee Generator')
                                        }
                                        onClick={employeeTransmission?.OutputFile === null ? handleViewForm(employeeTransmission) : handleViewOldForm(employeeTransmission)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" >
                                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                                        </svg><span className="mr-auto">Download</span>
                                    </Button>
                                </div>
                            </CustomTooltip>
                        </>
                    )}
                    {StepID == "5" && (
                        <>
                            <CustomTooltip TransitionComponent={Zoom} title="View Form">
                                <Button onClick={employeeTransmission?.OutputFile === null ? handleViewForm(employeeTransmission) : handleViewOldForm(employeeTransmission)}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" className="mr-1" >
                                        <use xlinkHref={`${viewActionItemSVG}#viewAIIcon`}></use>
                                    </svg>
                                    <span>View</span>
                                </Button>
                            </CustomTooltip>
                        </>
                    )}
                </>
        }

        if (StepID == "4" || StepID == "5") {
            finalColumnsArray.push(ActionsColumn);
        }

        return finalColumnsArray;

    }

    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(employeesTransmissionsList)) {
            return [];
        }

        return employeesTransmissionsList
    }



    return (
        <div id="transmission-dashboard-table">
            {employeesTransmissionsList?.length > 0 && (
                <BreadcrumbSteps
                    breadcrumbTitle={`Feed "${employeesTransmissionsList[0]?.FeedName}"`}
                    isCurrent={false}
                    breadcrumbTitleCurrent={`${employeesTransmissionsList[0]?.LSI}`}
                    breadcrumbLink={`/Feeds/ViewFeed/${employeesTransmissionsList[0]?.FeedID}`}
                />
            )}
            <div className="row w-100 alignvh-center mt-5">
                <h5 className="black1 fw-400 ml-3">Members Dashboard</h5>
            </div>
            <Paper className="content-card-paper">
                <FormsTransmissionLogEmployeesDashboardFilter
                    onEmployeeNameFilter={(d) => setEmployeeNameFilter(d)}
                    onEmployeeSSNFilter={(d) => setEmployeeSSNFilter(d)}
                    setRefreshClicked={setRefreshClicked}
                />
                {StepID === "4" &&
                    (FilteredAndAddedIsExpand().any(s => checkExecutionStatusForSelectAll(s))) && (
                        <div className="mt-5 text-right">
                            <Button variant="contained" className="blue-btn mr-2" style={{ whiteSpace: "nowrap" }} disabled={selectedEmployees?.length <= 0} onClick={() => {
                                openModal(0, handleTransmitForms, {
                                    title: "Please Confirm",
                                    question: `Please confirm the selected form(s) have been reviewed for accuracy and are ready to be transmitted <br/> to the Carrier for processing `,
                                })
                            }}>
                                <label style={{ cursor: "pointer" }} className="mb-0"><KeyboardTabIcon className="mr-2" /><span>Transmit Forms</span></label>
                            </Button>

                            <Button variant="contained" className="blue-btn" style={{ whiteSpace: "nowrap" }} disabled={selectedEmployees?.length <= 0} onClick={() => {
                                openModal(0, dialog.openDialog("Rejection Reason", <RejectionReasonModal forceRejectWithReason={handleRejectForms} />, []), {
                                    title: "Please Confirm",
                                    question: `Are you sure you want to reject the selected forms?`,
                                })
                            }}>
                                <label style={{ cursor: "pointer" }} className="mb-0">
                                    {/* <ThumbDownOffAltIcon className="mr-2" /> */}
                                    <svg width="24" height="22" viewBox="0 0 24 22" className="mr-2" >
                                        <use xlinkHref={`${thumbsDown}#thumbs-down`}></use>
                                    </svg>
                                    <span>Reject Forms</span></label>
                            </Button>
                        </div>
                    )}


                <div className="mt-5">
                    <GenericTable
                        columns={[...pushToArrayBasedOnStepID()]}
                        data={[...FilteredAndAddedIsExpand()]}
                        onSort={() => { }}
                    />
                </div>
            </Paper>



            {/* <Paper elevation={0} className="mt-3">
                {StepID === "4" ? (
                    <div className="row table-header">
                        <div className="col-1 table-cell first-cell">
                            <CustomTooltip TransitionComponent={Zoom}
                                title={employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)).length <= 0 ? "There are no employees left to be selected" : "Select All"}>
                                <span>
                                    <FormControlLabel
                                        style={{ display: "unset", margin: "0" }}
                                        aria-label="Acknowledge"
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        control={
                                            <Checkbox
                                                disabled={employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)).length <= 0}
                                                id={`employees-select-all`}
                                                value={employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s))}
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checked={
                                                    employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)).length > 0 &&
                                                    selectedEmployees.length === employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)).length}
                                                onChange={handleSelectAllEmployeesChange(employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)))}
                                                onClick={handleSelectAllEmployeesClick(employeesTransmissionsList?.filter(s => checkExecutionStatusForSelectAll(s)))}
                                                checkedIcon={<DoneOutlineIcon />}
                                                color="default"
                                            />
                                        }
                                    />
                                </span>
                            </CustomTooltip>
                        </div>

                        <div className="col-2 table-cell">Employee Name</div>
                        <div className="col-2 table-cell">Employee SSN</div>
                        <div className="col-1 table-cell">Employee Date of Birth</div>
                        <div className="col-1 table-cell">Timestamp</div>
                        <div className="col-2 table-cell">Execution Status</div>
                        <div className="col-2 table-cell">Processing Log</div>

                        <div className="col-1 table-cell">Actions</div>
                    </div>
                ) : StepID === "5" ? (
                    <div className="row table-header">
                        <div className="col-2 table-cell first">Employee Name</div>
                        <div className="col-2 table-cell">Employee SSN</div>
                        <div className="col-1 table-cell">Employee Date of Birth</div>
                        <div className="col-1 table-cell">Timestamp</div>
                        <div className="col-2 table-cell">Execution Status</div>
                        <div className="col-2 table-cell">Processing Log</div>
                        <div className="col-2 table-cell">Actions</div>
                    </div>
                ) : (
                    <div className="row table-header">
                        <div className="col-2 table-cell first">Employee Name</div>
                        <div className="col-2 table-cell">Employee SSN</div>
                        <div className="col-2 table-cell">Employee Date of Birth</div>
                        <div className="col-2 table-cell">Timestamp</div>
                        <div className="col-2 table-cell">Execution Status</div>
                        <div className="col-2 table-cell">Processing Log</div>
                    </div>
                )}

                {employeesTransmissionsList && employeesTransmissionsList?.length > 0 && employeesTransmissionsList?.map((e, ei) => (
                    <div key={`feeds-transmissions-${ei}`}>
                        <SingleEmployeeFormTransmissions
                            employeeTransmission={e}
                            index={ei}
                            refreshClicked={refreshClicked}
                            setRefreshClicked={setRefreshClicked}
                            employeesTransmissionsList={employeesTransmissionsList}
                            selectedEmployees={selectedEmployees}
                            onSelectedEmployees={s => setSelectedEmployees(s)}
                        />
                    </div>
                ))}

                {employeesTransmissionsList && Object.keys(employeesTransmissionsList).length === 0 && employeesTransmissionsList.constructor === Object && (
                    <div className="no-data-alert">
                        <h6>No Results Found!</h6>
                        <h6>Please select correct search criteria.</h6>
                    </div>
                )}

                <div className="text-center">
                    <Button className="load-more-btn blue-btn" disabled={employeesTransmissionsResponse === null} onClick={handleAddMore}>Load More Results</Button>
                </div>
            </Paper> */}
        </div >
    )
}

export default FormsTransmissionLogEmployeesDashboard;
