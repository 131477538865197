import React from "react";


export function Comments(commentsDialog, content) {
    commentsDialog.openDialog(
        <div className="comments-modal-header">
            {content.title}
        </div>,
        <div className="comments-modal-body">
            {content.body}
        </div>
    )
}