import React from "react";
import PropTypes from 'prop-types';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import Moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Paper } from '@material-ui/core/';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { useAuthentication } from "../../../Data/Authentication";
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { styled } from '@mui/system';
import ListSubheader from '@mui/material/ListSubheader';
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";


function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="filter-body-main-content"
            style={{ flexGrow: "12" }}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));


function GroupRequestsFilterModalBody({
    setTotalCount,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    openDialog,
    setOpenDialog,
    onPageSizeAdv,
    onSelectedPageAdv,
    onSelectedGroups,
    onSelectedPEOs,
    onSelectedStatuses,
    onSelectedPhases,
    onGroupRequestIDFilter,
    onGroupRequestIDAdvFilter,
    onGroupIDFilter,
    onGroupIDAdvFilter,
    onClientNumberAdvFilter,
    onPEOIDFilter,
    onPEOIDAdvFilter,
    onMigrationAdvFilter,
    onFromDateAdvFilter,
    onToDateAdvFilter,
    setAdvancedFiltersAppliedClicked,
    onMigrationStartFromDateAdvFilter,
    onMigrationStartToDateAdvFilter,
    onPlannedFirstProductionFromDateAdvFilter,
    onPlannedFirstProductionToDateAdvFilter,
    onSelectedMigrationPhasesAdvFilter,
    onSelectedCreatedByAdvFilter,
    onCloseClearReset,
    onSetIsPEOAdvFilter,
    ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [tabValue, setTabValue] = React.useState(0);
    const [grpRequestID, setGrpRequestID] = React.useState("");
    const [grpID, setGrpID] = React.useState("");
    const [clientNumber, setClientNumber] = React.useState("");
    const [peoID, setPEOID] = React.useState("");
    const [groupsList, setGroupsList] = React.useState([]);
    const [groupSearchValue, setGroupSearchValue] = React.useState("");
    const [selectedGroups, setSelectedGroups] = React.useState([]);
    const [grpName, setGrpName] = React.useState("");
    const [peosList, setPEOsList] = React.useState([]);
    const [peoSearchValue, setPEOSearchValue] = React.useState("");
    const [selectedPEOs, setSelectedPEOs] = React.useState([]);
    const [peoName, setPEOName] = React.useState("");
    const [selectedSubmissionFromDate, setSelectedSubmissionFromDate] = React.useState(null);
    const [selectedSubmissionToDate, setSelectedSubmissionToDate] = React.useState(null);
    const [groupRequestsStatusesList, setGroupRequestsStatusesList] = React.useState([]);
    const [selectedStatuses, setSelectedStatuses] = React.useState([]);
    const [groupRequestsPhasesList, setGroupRequestsPhasesList] = React.useState([]);
    const [selectedPhases, setSelectedPhases] = React.useState([]);
    const [migrationList, setMigrationList] = React.useState(["No", "eBenefits Network", "Everything Benefits"]);
    const [selectedMigrations, setSelectedMigrations] = React.useState([]);
    const [createdBySearchValue, setCreatedBySearchValue] = React.useState("");
    const [createdBy, setCreatedBy] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [isPEOFilter, setIsPEOFilter] = React.useState("");
    const [selectedMigrationStartDateFromDate, setSelectedMigrationStartDateFromDate] = React.useState(null);
    const [selectedMigrationStartDateToDate, setSelectedMigrationStartDateToDate] = React.useState(null);


    const [selectedPlannedFirstProductionDateFromDate, setSelectedPlannedFirstProductionDateFromDate] = React.useState(null);
    const [selectedPlannedFirstProductionDateToDate, setSelectedPlannedFirstProductionDateToDate] = React.useState(null);

    const [selectedMigrationPhases, setSelectedMigrationPhases] = React.useState([]);
    const [migrationPhasesList, setMigrationPhasesList] = React.useState([]);

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            default:
                break;
        }
    }

    React.useEffect(() => {
        APIs.getGroupsListByName({ GroupName: grpName ?? "" }).then((r) => !(r?.data?.length) ? setGroupsList([]) : setGroupsList(r?.data));
    }, [grpName])

    React.useEffect(() => {
        APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
    }, [peoName])

    React.useEffect(() => {
        APIs.getGroupRequestStatuses().then((r) => !(r?.data?.length) ? setGroupRequestsStatusesList([]) : setGroupRequestsStatusesList(r?.data));
    }, [])

    React.useEffect(() => {
        APIs.getGroupRequestPhases().then((r) => !(r?.data?.length) ? setGroupRequestsPhasesList([]) : setGroupRequestsPhasesList(r?.data));
    }, [])
    // get Migration phases List  apis 
    React.useEffect(() => {
        APIs.getMigrationPhases().then(r => !(r?.data?.length) ? setMigrationPhasesList([]) : setMigrationPhasesList(r?.data));

    }, [])
    const handleTabChange = (event, value) => {
        setTabValue(value);
    };
    React.useEffect(() => {
        APIs.getAllUsers().then((r) => setUsersList(r?.data));
    }, [])
    const handleGroupRequestIDFilter = (e) => {
        setGrpRequestID(e.target.value);
        onGroupRequestIDAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleGroupIDFilter = (e) => {
        setGrpID(e.target.value);
        onGroupIDAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleClientNumberFilter = (e) => {
        setClientNumber(e.target.value);
        onClientNumberAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handlePEOIDFilter = (e) => {
        setPEOID(e.target.value);
        onPEOIDAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleCreatedByClick = (value) => (event) => {
        let createdByArray = createdBy;
        if (!value || value === "") createdByArray = [];
        else if (createdByArray.indexOf(value) >= 0) {
            createdByArray.splice(createdByArray.indexOf(value), 1);
        }
        else {
            createdByArray.push(value);
        }
        setCreatedBy([...createdByArray]);
        onSelectedCreatedByAdvFilter([...createdByArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }
    const handleGroupClick = (value) => (event) => {
        let selectedGroupsArray = selectedGroups;
        if (!value || value === "") selectedGroupsArray = [];
        else if (selectedGroupsArray.indexOf(value) >= 0) {
            selectedGroupsArray.splice(selectedGroupsArray.indexOf(value), 1);
        }
        else {
            selectedGroupsArray.push(value);
        }
        setSelectedGroups([...selectedGroupsArray]);
        onSelectedGroups([...selectedGroupsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handlePEOClick = (value) => (event) => {
        let selectedPEOsArray = selectedPEOs;
        if (!value || value === "") selectedPEOsArray = [];
        else if (selectedPEOsArray.indexOf(value) >= 0) {
            selectedPEOsArray.splice(selectedPEOsArray.indexOf(value), 1);
        }
        else {
            selectedPEOsArray.push(value);
        }
        setSelectedPEOs([...selectedPEOsArray]);
        onSelectedPEOs([...selectedPEOsArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleGroupRequestsStatusesClick = (value) => (event) => {
        let selectedGroupRequestsStatusesArray = selectedStatuses;
        if (!value || value === "") selectedGroupRequestsStatusesArray = [];
        else if (selectedGroupRequestsStatusesArray.indexOf(value) >= 0) {
            selectedGroupRequestsStatusesArray.splice(selectedGroupRequestsStatusesArray.indexOf(value), 1);
        }
        else {
            selectedGroupRequestsStatusesArray.push(value);
        }
        setSelectedStatuses([...selectedGroupRequestsStatusesArray]);
        onSelectedStatuses([...selectedGroupRequestsStatusesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleMigrationPhasesClick = (value) => (event) => {
        let selectedMigrationPhasesArray = selectedMigrationPhases;
        if (!value || value === "") selectedMigrationPhasesArray = [];
        else if (selectedMigrationPhasesArray.indexOf(value) >= 0) {
            selectedMigrationPhasesArray.splice(selectedMigrationPhasesArray.indexOf(value), 1);
        }
        else {
            selectedMigrationPhasesArray.push(value);
        }
        setSelectedMigrationPhases([...selectedMigrationPhasesArray]);
        onSelectedMigrationPhasesAdvFilter([...selectedMigrationPhasesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleGroupRequestsPhasesClick = (value) => (event) => {
        let selectedGroupRequestsPhasesArray = selectedPhases;
        if (!value || value === "") selectedGroupRequestsPhasesArray = [];
        else if (selectedGroupRequestsPhasesArray.indexOf(value) >= 0) {
            selectedGroupRequestsPhasesArray.splice(selectedGroupRequestsPhasesArray.indexOf(value), 1);
        }
        else {
            selectedGroupRequestsPhasesArray.push(value);
        }
        setSelectedPhases([...selectedGroupRequestsPhasesArray]);
        onSelectedPhases([...selectedGroupRequestsPhasesArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }
    const handelIsPEOFilter = (e, newValue) => {
        setIsPEOFilter(e.target.value);
        onSetIsPEOAdvFilter(e.target.value);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }
    const handleSubmissionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedSubmissionFromDate(date);
            onFromDateAdvFilter(date);
        }
        else {
            setSelectedSubmissionToDate(date);
            onToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };



    const handleMigrationStartDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedMigrationStartDateFromDate(date);
            onMigrationStartFromDateAdvFilter(date);
        }
        else {
            setSelectedMigrationStartDateToDate(date);
            onMigrationStartToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };



    const handlePlannedFirstProductionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedPlannedFirstProductionDateFromDate(date);
            onPlannedFirstProductionFromDateAdvFilter(date);
        }
        else {
            setSelectedPlannedFirstProductionDateToDate(date);
            onPlannedFirstProductionToDateAdvFilter(date);
        }
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    };
    const handleMigrationClick = (value) => (event) => {
        let selectedMigrationArray = selectedMigrations;
        if (!value || value === "") selectedMigrationArray = [];
        else if (selectedMigrationArray.indexOf(value) >= 0) {
            selectedMigrationArray.splice(selectedMigrationArray.indexOf(value), 1);
        }
        else {
            selectedMigrationArray.push(value);
        }
        setSelectedMigrations([...selectedMigrationArray]);
        onMigrationAdvFilter([...selectedMigrationArray]);
        onSelectedPageAdv(1);
        onPageSizeAdv(30);
    }

    const handleClearSearch = () => {
        setGrpRequestID("");
        onGroupRequestIDFilter("");
        onGroupRequestIDAdvFilter("");
        setGrpID("");
        setClientNumber("");
        onGroupIDFilter("");
        onGroupIDAdvFilter("");
        onClientNumberAdvFilter("");
        setPEOID("");
        onPEOIDFilter("");
        onPEOIDAdvFilter("");
        setSelectedGroups([]);
        setSelectedPEOs([]);
        onSelectedGroups([]);
        onSelectedPEOs([]);
        setSelectedStatuses([]);
        onSelectedStatuses([]);
        setSelectedPhases([]);
        onSelectedPhases([]);
        setSelectedSubmissionFromDate(null);
        onFromDateAdvFilter(null);
        setSelectedSubmissionToDate(null);
        onToDateAdvFilter(null);
        onSelectedPageAdv(1);
        onMigrationAdvFilter([]);
        setSelectedMigrations([]);
        setSelectedMigrationStartDateFromDate(null);
        onMigrationStartFromDateAdvFilter(null);
        setSelectedMigrationStartDateToDate(null);
        onMigrationStartToDateAdvFilter(null);
        setSelectedPlannedFirstProductionDateFromDate(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        setSelectedPlannedFirstProductionDateToDate(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        setSelectedMigrations([]);
        onMigrationAdvFilter([]);
        onSelectedMigrationPhasesAdvFilter([]);
        setSelectedMigrationPhases([]);
        onSelectedCreatedByAdvFilter([]);
        setCreatedBy([]);
        onSetIsPEOAdvFilter("");
        setIsPEOFilter("");
    }

    React.useEffect(() => {
        setGrpRequestID("");
        onGroupRequestIDFilter("");
        onGroupRequestIDAdvFilter("");
        setGrpID("");
        onClientNumberAdvFilter("");
        setClientNumber("");
        onGroupIDFilter("");
        onGroupIDAdvFilter("");
        setPEOID("");
        onPEOIDFilter("");
        onPEOIDAdvFilter("");
        setSelectedGroups([]);
        setSelectedPEOs([]);
        onSelectedGroups([]);
        onSelectedPEOs([]);
        setSelectedStatuses([]);
        onSelectedStatuses([]);
        setSelectedPhases([]);
        onSelectedPhases([]);
        setSelectedSubmissionFromDate(null);
        onFromDateAdvFilter(null);
        setSelectedSubmissionToDate(null);
        onToDateAdvFilter(null);
        onSelectedPageAdv(1);
        setSelectedMigrations([]);
        onMigrationAdvFilter([]);
        setSelectedMigrationStartDateFromDate(null);
        onMigrationStartFromDateAdvFilter(null);
        setSelectedMigrationStartDateToDate(null);
        onMigrationStartToDateAdvFilter(null);
        setSelectedPlannedFirstProductionDateFromDate(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        setSelectedPlannedFirstProductionDateToDate(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        setSelectedMigrations([]);
        onMigrationAdvFilter([]);
        onSelectedMigrationPhasesAdvFilter([]);
        setSelectedMigrationPhases([]);
        onSelectedCreatedByAdvFilter([]);
        onSetIsPEOAdvFilter("");
        setIsPEOFilter("");
        setCreatedBy([]);
    }, [advancedFiltersClicked === false])

    const handleCloseDialogResetConfirm = () => {
        openModal(0, handleCloseDialogReset, {
            title: 'Please Confirm',
            question: `Are you sure you want to close the dialog? This will clear all the previous search history.`,
        })
    }

    const handleCloseDialogReset = () => {
        onCloseClearReset();
        setOpenDialog(false);
        setAdvancedFiltersAppliedClicked(false);
    }
    const handelSelectAll = (updatedList, list, updatedFunc, updatedfilterFunc) => (event) => {
        if (updatedList?.length !== list?.length) {
            updatedFunc([...list]);
            updatedfilterFunc([...list]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
        if (updatedList?.length === list?.length) {
            updatedFunc([]);
            updatedfilterFunc([]);
            onSelectedPageAdv(1);
            onPageSizeAdv(30);
        }
    }

    return (
        <Dialog
            className="filter-full-div"
            open={openDialog}
            onClose={() => { setOpenDialog(false); setAdvancedFiltersAppliedClicked(false); }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            disableEscapeKeyDown
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" className="filter-title-div">
                <div className="row w-100">
                    <div>Advanced Group Requests Filter</div>
                    <div className="ml-auto" style={{ cursor: "pointer" }} onClick={handleCloseDialogResetConfirm}><CloseIcon /></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="filter-body-div">
                    <Box style={{ display: 'flex' }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                            TabIndicatorProps={{
                                style: {
                                    display: "none",
                                },
                            }}
                        >
                            {(userActions?.includes("43") ||
                                userActions?.includes("20")) ? (
                                <Tab className="filter-tab-btn" label="Group Request ID" {...a11yProps(0)} />
                            ) : (
                                <Tab className="filter-tab-btn" label="ID" {...a11yProps(0)} />
                            )}
                            <Tab className="filter-tab-btn" label="Client Number" {...a11yProps(1)} />
                            <Tab className="filter-tab-btn" label="Client Name" {...a11yProps(2)} />
                            <Tab className="filter-tab-btn" label="PEO Name" {...a11yProps(3)} />
                            <Tab className="filter-tab-btn" label="Group Request Status" {...a11yProps(4)} />
                            <Tab className="filter-tab-btn" label="Group Request Phase" {...a11yProps(5)} />
                            <Tab className="filter-tab-btn" label="Submission Date" {...a11yProps(6)} />
                            <Tab className="filter-tab-btn" label="Migration" {...a11yProps(7)} />
                            <Tab className="filter-tab-btn" label="Migration Start Date" {...a11yProps(8)} />
                            <Tab className="filter-tab-btn" label="Planned First Production Date" {...a11yProps(9)} />
                            <Tab className="filter-tab-btn" label="Migration Phase" {...a11yProps(10)} />
                            <Tab className="filter-tab-btn" label="Include PEO" {...a11yProps(11)} />
                            {(userActions?.includes("53") ||
                                userActions?.includes("20")) && (
                                    <Tab className="filter-tab-btn" label="Created By " {...a11yProps(12)} />
                                )}



                        </Tabs>
                        {(userActions?.includes("43") ||
                            userActions?.includes("20")) ? (
                            <TabPanel value={tabValue} index={0}>
                                <span>Please filter by only one group request ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="Group Request ID" value={grpRequestID} onChange={handleGroupRequestIDFilter} />
                            </TabPanel>
                        ) : (
                            <TabPanel value={tabValue} index={0}>
                                <span>Please filter by only one ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" placeholder="ID" value={grpID} onChange={handleGroupIDFilter} />
                            </TabPanel>
                        )}
                        <TabPanel value={tabValue} index={1}>
                            <span>Please filter by only one client Number:</span>
                            <br />
                            <input type="text" className="search-input w-100" placeholder="Client Number" value={clientNumber} onChange={handleClientNumberFilter} />
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <input type="text" className="text-input w-100" placeholder="Search clients here..." value={groupSearchValue} onChange={(e) => setGroupSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`groups-select-all`}>
                                    <AccordionSummary
                                        aria-controls="groups-select-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`groups-select-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedGroups && selectedGroups.length === groupsList.length}
                                                        onClick={handelSelectAll(selectedGroups, groupsList, setSelectedGroups, onSelectedGroups)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>


                                {groupsList?.filter(s => groupSearchValue ? s.GroupName?.toLowerCase().includes(groupSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`groups-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="groups-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`group-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedGroups && selectedGroups.indexOf(c) >= 0}
                                                            onClick={handleGroupClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.GroupName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <input type="text" className="text-input w-100" placeholder="Search peos here..." value={peoSearchValue} onChange={(e) => setPEOSearchValue(e.target.value)} />
                            <div style={{ maxHeight: "285px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`peos-select-all`}>
                                    <AccordionSummary
                                        aria-controls="peos-select-select-all"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`peos-select-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedPEOs && selectedPEOs.length === peosList.length}
                                                        onClick={handelSelectAll(selectedPEOs, peosList, setSelectedPEOs, onSelectedPEOs)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>


                                {peosList?.filter(s => peoSearchValue ? s.PeoName?.toLowerCase().includes(peoSearchValue.toLowerCase()) : s).map((c, ci) => (
                                    <Accordion key={`peos-${ci}`}>
                                        <AccordionSummary
                                            aria-controls="peos-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`peo-option-${ci}`}
                                                            value={c}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPEOs && selectedPEOs.indexOf(c) >= 0}
                                                            onClick={handlePEOClick(c)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={c?.PeoName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`group-requests-status-all`}>
                                    <AccordionSummary
                                        aria-controls="group-requests-statuses-select"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`group-requests-status-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedStatuses && selectedStatuses.length === groupRequestsStatusesList.length}
                                                        onClick={handelSelectAll(selectedStatuses, groupRequestsStatusesList, setSelectedStatuses, onSelectedStatuses)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>


                                {groupRequestsStatusesList.map((f, fi) => (
                                    <Accordion key={`group-requests-status-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="group-requests-statuses-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`group-requests-status-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedStatuses && selectedStatuses.indexOf(f) >= 0}
                                                            onClick={handleGroupRequestsStatusesClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.GroupRequestStatusName}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={5}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`group-requests-phase-all`}>
                                    <AccordionSummary
                                        aria-controls="group-requests-phasees-select"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`group-requests-phase-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedPhases && selectedPhases.length === groupRequestsPhasesList.length}
                                                        onClick={handelSelectAll(selectedPhases, groupRequestsPhasesList, setSelectedPhases, onSelectedPhases)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>


                                {groupRequestsPhasesList.map((f, fi) => (
                                    <Accordion key={`group-requests-phase-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="group-requests-phases-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`group-requests-phase-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedPhases && selectedPhases.indexOf(f) >= 0}
                                                            onClick={handleGroupRequestsPhasesClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.Name}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={6}>
                            <span>From:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedSubmissionFromDate}
                                        onChange={handleSubmissionDateChange("fromDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedSubmissionToDate}
                                        onChange={handleSubmissionDateChange("toDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </TabPanel>
                        <TabPanel value={tabValue} index={7}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                <Accordion key={`group-requests-migration-all`}>
                                    <AccordionSummary
                                        aria-controls="group-requests-migration-select"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`group-requests-migration-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedMigrations && selectedMigrations.length === migrationList.length}
                                                        onClick={handelSelectAll(selectedMigrations, migrationList, setSelectedMigrations, onMigrationAdvFilter)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>
                                {migrationList.map((f, fi) => (
                                    <Accordion key={`migration-${fi}`}>
                                        <AccordionSummary
                                            aria-controls="migration-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`migration-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedMigrations && selectedMigrations.indexOf(f) >= 0}
                                                            onClick={handleMigrationClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div >
                        </TabPanel>
                        <TabPanel value={tabValue} index={8}>
                            <span>From:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedMigrationStartDateFromDate}
                                        onChange={handleMigrationStartDateChange("fromDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedMigrationStartDateToDate}
                                        onChange={handleMigrationStartDateChange("toDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </TabPanel>
                        <TabPanel value={tabValue} index={9}>
                            <span>From:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedPlannedFirstProductionDateFromDate}
                                        onChange={handlePlannedFirstProductionDateChange("fromDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                            <br />
                            <br />
                            <span>To:</span>
                            <br />
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="date-div alignvh-center">

                                    <KeyboardDatePicker
                                        autoOk={true}
                                        className="ml-xl-1 col-12"
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        id="date-picker-inline"
                                        value={selectedPlannedFirstProductionDateToDate}
                                        onChange={handlePlannedFirstProductionDateChange("toDate")}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                        keyboardIcon={
                                            <svg width="14" height="16" viewBox="0 0 14 16">
                                                <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                            </svg>
                                        }
                                    />
                                </div>
                            </MuiPickersUtilsProvider>
                        </TabPanel>
                        <TabPanel value={tabValue} index={10}>
                            <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>

                                <Accordion key={`group-requests-migration-phase-all`}>
                                    <AccordionSummary
                                        aria-controls="group-requests-migration-phase-select"
                                        className="accordion-checkboxes"
                                    >
                                        <FormControlLabel
                                            aria-label="Acknowledge"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <>
                                                    <Checkbox
                                                        id={`group-requests-migration-phase-option-all}`}
                                                        value="Select All"
                                                        icon={<CheckBoxOutlineBlankIcon />}
                                                        checked={selectedMigrationPhases && selectedMigrationPhases.length === migrationPhasesList.length}
                                                        onClick={handelSelectAll(selectedMigrationPhases, migrationPhasesList, setSelectedMigrationPhases, onSelectedMigrationPhasesAdvFilter)}
                                                        checkedIcon={<DoneOutlineIcon />}
                                                        color="default"
                                                    />
                                                </>
                                            }
                                            label="Select All"
                                        />
                                    </AccordionSummary>
                                </Accordion>

                                {migrationPhasesList?.map((f, fi) => (
                                    <Accordion key={`migration-phase${fi}`}>
                                        <AccordionSummary
                                            aria-controls="migration-phases-select"
                                            className="accordion-checkboxes"
                                        >
                                            <FormControlLabel
                                                aria-label="Acknowledge"
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                control={
                                                    <>
                                                        <Checkbox
                                                            id={`migration-phase-option-${fi}`}
                                                            value={f}
                                                            icon={<CheckBoxOutlineBlankIcon />}
                                                            checked={selectedMigrationPhases && selectedMigrationPhases.indexOf(f) >= 0}
                                                            onClick={handleMigrationPhasesClick(f)}
                                                            checkedIcon={<DoneOutlineIcon />}
                                                            color="default"
                                                        />
                                                    </>
                                                }
                                                label={f?.Name}
                                            />
                                        </AccordionSummary>
                                    </Accordion>
                                ))}
                            </div>
                        </TabPanel>
                        <TabPanel value={tabValue} index={11}>
                            <span>Include PEO:</span>
                            <br />
                            <FormControl className="search-dropdown w-100">
                                <NativeSelect
                                    value={isPEOFilter ?? ""}
                                    onChange={handelIsPEOFilter}
                                    input={<BootstrapInput />}
                                >
                                    <option aria-label="None" value="" />
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </NativeSelect >
                            </FormControl >
                        </TabPanel>
                        {(userActions?.includes("53") ||
                            userActions?.includes("20")) && (
                                <TabPanel value={tabValue} index={12}>
                                    <input type="text" className="text-input w-100" placeholder="Search created by here..." value={createdBySearchValue} onChange={(e) => setCreatedBySearchValue(e.target.value)} />
                                    <div style={{ maxHeight: "340px", overflowX: "hidden", overflowY: "auto" }}>
                                        <Accordion key={`createdBy-select-all`}>
                                            <AccordionSummary
                                                aria-controls="createdBy-select-select-all"
                                                className="accordion-checkboxes"
                                            >
                                                <FormControlLabel
                                                    aria-label="Acknowledge"
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                    control={
                                                        <>
                                                            <Checkbox
                                                                id={`createdBy-select-option-all}`}
                                                                value="Select All"
                                                                icon={<CheckBoxOutlineBlankIcon />}
                                                                checked={createdBy && createdBy.length === usersList.length}
                                                                onClick={handelSelectAll(createdBy, usersList, setCreatedBy, onSelectedCreatedByAdvFilter)}
                                                                checkedIcon={<DoneOutlineIcon />}
                                                                color="default"
                                                            />
                                                        </>
                                                    }
                                                    label="Select All"
                                                />
                                            </AccordionSummary>
                                        </Accordion>

                                        <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Enabled</GroupHeader></ListSubheader>
                                        {usersList?.filter(s => s.IsEnabled === 1)?.filter(s => createdBySearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(createdBySearchValue.toLowerCase()) : s).map((c, ci) => (
                                            <Accordion key={`requestors-${ci}`}>
                                                <AccordionSummary
                                                    aria-controls="createdBy-select"
                                                    className="accordion-checkboxes"
                                                >
                                                    <FormControlLabel
                                                        aria-label="Acknowledge"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <>
                                                                <Checkbox
                                                                    id={`createdBy-option-${ci}`}
                                                                    value={c}
                                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                                    checked={createdBy && createdBy.indexOf(c) >= 0}
                                                                    onClick={handleCreatedByClick(c)}
                                                                    checkedIcon={<DoneOutlineIcon />}
                                                                    color="default"
                                                                />
                                                            </>
                                                        }
                                                        label={`${c.FirstName} ${c.LastName}`}
                                                    />
                                                </AccordionSummary>
                                            </Accordion>
                                        ))}
                                        <ListSubheader className="p-0" onClickCapture={(e) => e.stopPropagation()}><GroupHeader>Disabled</GroupHeader></ListSubheader>
                                        {usersList?.filter(s => s.IsEnabled !== 1)?.filter(s => createdBySearchValue ? `${s.FirstName} ${s.LastName}`?.toLowerCase().includes(createdBySearchValue.toLowerCase()) : s).map((c, ci) => (
                                            <Accordion key={`requestors-${ci}`}>
                                                <AccordionSummary
                                                    aria-controls="createdBy-select"
                                                    className="accordion-checkboxes"
                                                >
                                                    <FormControlLabel
                                                        aria-label="Acknowledge"
                                                        onClick={(event) => event.stopPropagation()}
                                                        onFocus={(event) => event.stopPropagation()}
                                                        control={
                                                            <>
                                                                <Checkbox
                                                                    id={`createdBy-option-${ci}`}
                                                                    value={c}
                                                                    icon={<CheckBoxOutlineBlankIcon />}
                                                                    checked={createdBy && createdBy.indexOf(c) >= 0}
                                                                    onClick={handleCreatedByClick(c)}
                                                                    checkedIcon={<DoneOutlineIcon />}
                                                                    color="default"
                                                                />
                                                            </>
                                                        }
                                                        label={`${c.FirstName} ${c.LastName}`}
                                                    />
                                                </AccordionSummary>
                                            </Accordion>
                                        ))}
                                    </div>
                                </TabPanel>
                            )}
                    </Box>
                </div >
            </DialogContent>
            <DialogActions className="filter-footer-div">
                <Button className="blue-outlined-btn" onClick={handleClearSearch}>
                    Clear Search
                </Button>
                <Button onClick={() => { setAdvancedFiltersAppliedClicked(true); setOpenDialog(false); }} className="blue-btn">Apply Filter</Button>
            </DialogActions>
        </Dialog>
    )
}

export default GroupRequestsFilterModalBody;
