import React, { useState } from "react";
import logoSVG from "../../Images/imageLogo.svg";
import LoginImageSVG from "../../Images/loginImage.svg";
import LoginSignInImageSVG from "../../Images/loginSignInImage.svg";
import { FormControl, FormControlLabel, IconButton, Input, InputAdornment, InputLabel, Link, Paper } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";
import LockIcon from '@material-ui/icons/Lock';
import { useLoader } from "../Layout/Loader";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';

function ForgotPassword({ cognitoUser, email, error, setError, ...props }) {

    const [verificationCode, setVerificationCode] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
    const [showNewPassword, setShowNewPassword] = React.useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);

    const { openLoader, closeLoader } = useLoader();
    const routeHistory = useHistory();

    React.useEffect(() => {
        if (cognitoUser === undefined)
            window.location.href = "/";
        else return;
    }, [])

    const resetInput = React.useRef();

    React.useEffect(() => {
        resetInput.current.focus();
    }, [])

    React.useEffect(() => {
        if (confirmNewPassword !== newPassword) setError("Your password and confirmation password are not matched"); else setError("");
    }, [confirmNewPassword])

    return (
        <div id="login">
            <div className="row">
                <div className="col-1 login-first-corner-div"></div>
                <div className="col-10 login-div"></div>
                <div className="col-1 login-last-corner-div"></div>
            </div>
            <div className="login-content row">
                <div className="col-6" style={{ display: "inline-flex", justifyContent: "center" }}>
                    <svg viewBox="0 0 73 14" width="60%">
                        <use xlinkHref={`${logoSVG}#logoSVG`}></use>
                    </svg>
                </div>
                <div className="col-6">
                    <div elevation={0} style={{ height: "100%", padding: "30px 100px" }}>
                        <SettingsBackupRestoreIcon style={{ fontSize: "70px", color: "var(--pink-primary-color)" }} />
                        <h4 className="mt-3 black1 color fw-700">RESET PASSWORD</h4>
                        <form autoComplete="new-password" onSubmit={(e) => {
                            e.preventDefault();
                            if (newPassword !== confirmNewPassword) return;
                            openLoader();
                            Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
                                .then(r => {
                                    routeHistory.push("/");
                                })
                                .catch((error) => {
                                    setError(!error.message ? error : error.message);
                                })
                                .finally(() => closeLoader());

                        }}>
                            <div className="text-left">
                                <h5 className="color black2 mb-4 mt-5">{`We have sent an email to your mailbox at ${cognitoUser?.CodeDeliveryDetails?.Destination}`}</h5>
                                <h6 className="color grey3" style={{ fontWeight: "450" }}>When you receive the verification code, enter it here</h6>
                            </div>
                            <input autoComplete="new-password" ref={resetInput} type="text" className="mt-4 text-input w-100" required placeholder="Verification Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
                            <div className="mt-4 w-100 alignvh-center">
                                <input required autoComplete="new-password" type={showNewPassword ? "text" : "password"} className="text-input w-100" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                <span style={{ right: "120px", position: "absolute", cursor: "pointer" }}>
                                    {showNewPassword ? <Visibility onClick={() => setShowNewPassword(false)} /> : <VisibilityOff onClick={() => setShowNewPassword(true)} />}
                                </span>
                            </div>
                            <div className="mt-4 w-100 alignvh-center">
                                <input required autoComplete="new-password" type={showConfirmNewPassword ? "text" : "password"} className="text-input w-100" placeholder="Confirm New Password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                                <span style={{ right: "120px", position: "absolute", cursor: "pointer" }}>
                                    {showConfirmNewPassword ? <Visibility onClick={() => setShowConfirmNewPassword(false)} /> : <VisibilityOff onClick={() => setShowConfirmNewPassword(true)} />}
                                </span>
                            </div>
                            {error && error !== "" && (
                                <div className="mt-2" style={{ color: "red", textAlign: "left", fontSize: "14px" }}>
                                    <label>Error: <span>{error}</span></label>
                                </div>
                            )}
                            <Button type="submit" variant="contained" className="signin-btn">Reset Password</Button>
                        </form>
                        {/* <div className="mt-3">
                            <label className="mt-3 grey2 color"><LockIcon className="mr-2" />Your Info is safely secured</label>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ForgotPassword;