import React from "react";
import Moment from 'moment';
import Pagination from "../../../Layout/Pagination";
import { useAuthentication } from "../../../../Data/Authentication";
import GenericTable from "../../../Layout/GenericTable";

function AnalystConnectivityRequestActionItemsTable({
    advancedFiltersClicked,
    quickFiltersClicked,
    actionItemsList,
    setActionItemsList,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    selectedPageAdv,
    setSelectedPageAdv,
    pageSizeAdv,
    setPageSizeAdv,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    // =========================================================NEW TABLE UI 
    const handleSort = (column, sortDirection, type) => {
        if (type === "quick") {
            onSortFieldFilter(column?.sortingkey);
            onSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            onSortFieldAdvFilter(column?.sortingkey);
            onSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const baseColumnsArray = [
        {
            name: 'Action Item',
            id: 'ActionItemText',
            sortable: true,
            sortingkey: 'ActionItemText',
            selector: c => <a href={`/ActionItems/ViewConnectivityRequest/${c?.ConnectivityRequestID}/ViewActionItem/${c?.Id}`}>{c?.ActionItemText}</a>
            ,
        },
        {
            name: 'Responsible Party',
            id: 'ResponsibleParty',
            sortable: true,
            sortingkey: 'ResponsibleParty',
            selector: c => c?.ResponsibleParty,
        },
        {
            name: 'Contact Name',
            id: 'contactName',
            sortable: false,
            selector: c => c?.ResponsiblePartyID === 2 ? c?.CarrierContactFullName : c?.ContactFullName,
        },
        {
            name: 'Requestor',
            id: 'Requestor',
            sortable: true,
            sortingkey: 'Requestor',
            selector: c => c.Requestor,
        },
        {
            name: 'Last Modified By',
            sortable: true,
            sortingkey: 'LastModifiedBy',
            selector: c => c.LastModifiedBy,
        },
        {
            name: 'Last Modified Date',
            id: 'DateModified',
            sortable: true,
            sortingkey: 'DateModified',
            selector: c => Moment(c?.DateModified).format('MM/DD/YYYY'),
        },
        {
            name: 'Status',
            id: 'Status',
            sortable: true,
            sortingkey: 'Status',
            selector: c => c?.Status,
        },
        {
            name: 'Due Date',
            id: 'DueDate',
            sortable: true,
            sortingkey: 'DueDate',
            selector: c => Moment(c?.DueDate).format('MM/DD/YYYY'),
        },
        {
            name: 'Start Date',
            id: 'StartDate',
            sortable: true,
            sortingkey: 'StartDate',
            selector: c => Moment(c?.StartDate).format('MM/DD/YYYY'),

        },
        {
            name: 'Completion Date',
            id: 'CompletionDate',
            sortable: true,
            sortingkey: 'CompletionDate',
            selector: c =>
                c?.CompletionDate === "Invalid date" || c?.CompletionDate === null || c?.CompletionDate === "0000-00-00 00:00:00" ? "" : Moment(c?.CompletionDate).format('MM/DD/YYYY')

        }
    ];


    const pushToColumnsBasedOnUserAction = () => {
        let checkif43or20 = (userActions?.includes("43") || userActions?.includes("20"))

        let itemsToInsert = [
            {
                index: 0,
                value: {
                    name: 'Action Item ID',
                    id: 'Id',
                    sortable: true,
                    sortingkey: 'Id',
                    selector: c => <a href={`/ActionItems/ViewConnectivityRequest/${c?.ConnectivityRequestID}/ViewActionItem/${c?.Id}`}>{c?.Id}</a>
                },
                condition: checkif43or20
            },


        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumnsArray];


        itemsToInsert.forEach(item => {
            if (item?.condition)
                finalColumnsArray.splice(item.index, 0, item.value);
        });


        return finalColumnsArray;
    };

    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(actionItemsList)) {
            return [];
        }
        return actionItemsList
    }

    return (
        <div className="mt-5">
            <GenericTable
                columns={[...pushToColumnsBasedOnUserAction()]}
                data={[...FilteredAndAddedIsExpand()]}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
            />

            <div>
                <Pagination
                    stateLimit={true}
                    pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                    page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                    onChange={(t, v) => {
                        if (quickFiltersClicked) {
                            if (t === 1) setPageSize(v);
                            else if (t === 2) setSelectedPage(v);
                        } else if (advancedFiltersClicked) {
                            if (t === 1) setPageSizeAdv(v);
                            else if (t === 2) setSelectedPageAdv(v);
                        }

                    }}
                    count={totalCount}
                />
            </div>

        </div>
    )
}

export default AnalystConnectivityRequestActionItemsTable;