import React from "react";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button, TextField } from "@material-ui/core";
import AddBoxIcon from '@material-ui/icons/AddBox';
import Fade from '@material-ui/core/Fade';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import Snackbar from '@material-ui/core/Snackbar';
import * as APIs from "../../../Data/APIs";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { SuccessModal } from "../../Modals/SuccessModal";
import { FailureModal } from "../../Modals/FailureModal";
import ClearIcon from '@material-ui/icons/Clear';
import { useAuthentication } from "../../../Data/Authentication";
import { useLoader } from "../../Layout/Loader"
import { useParams } from "react-router-dom";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import { emailPattern } from "./ViewGroupRequestClientContactsRow";
import editPenIcon from "../../../Icons/editPen.svg";
import addContactSVG from "../../../Icons/addActionItem.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import deleteForeverSVG from "../../../Icons/trash-xmark.svg";
import saveIconSVG from "../../../Icons/save.svg";

function ContactsRow({
    contactKey,
    contactItem,
    connectivity,
    contacts,
    setContacts,
    groupReq,
    setGroupReq,
    setSelectedSubgroupsView,
    carrierContactTypes,
    ...props }) {


    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const { openLoader, closeLoader } = useLoader();
    const { groupRequestIDURL } = useParams();

    const dialog = useModalDialog(0);
    const [inputFNValue, setInputFNValue] = React.useState("");
    const [inputLNValue, setInputLNValue] = React.useState("");
    const [inputEMValue, setInputEMValue] = React.useState("");
    const [inputTypeValue, setInputTypeValue] = React.useState("");
    const [addContactClicked, setAddContactClicked] = React.useState(false);
    const [isContactDeleted, setIsContactDeleted] = React.useState(false);
    const [editIndex, setEditIndex] = React.useState(-1);


    const [saveButtonClicked, setSaveButtonClicked] = React.useState(false);
    // validations
    const [firstNameValidation, setFirstNameValidation] = React.useState('');
    const [lastNameValidation, setLastNameValidation] = React.useState('');
    const [emailValidation, setEmailValidation] = React.useState('');
    const [typeValidation, setTypeValidation] = React.useState('');


    // Validation UseEffects
    // First Name Validation
    React.useEffect(() => {
        if (!inputFNValue || inputFNValue.split(" ").join("").length == 0) setFirstNameValidation("This Field is Required");
        else setFirstNameValidation("");
    }, [inputFNValue, firstNameValidation])

    // Last Name Validation
    React.useEffect(() => {
        if (!inputLNValue || inputLNValue.split(" ").join("").length == 0) setLastNameValidation("This Field is Required");
        else setLastNameValidation("");
    }, [inputLNValue, lastNameValidation])

    // Email Validation
    React.useEffect(() => {
        if (!inputEMValue || inputEMValue.split(" ").join("").length == 0) {
            setEmailValidation("This Field is Required");
        } else if (!emailPattern.test(inputEMValue)) {
            setEmailValidation("Invalid Email Format");
        }
        else setEmailValidation("");
    }, [inputEMValue, emailValidation])

    // type Validation
    React.useEffect(() => {
        if (!inputTypeValue || inputTypeValue.split(" ").join("").length == 0) setTypeValidation("This Field is Required");
        else setTypeValidation("");
    }, [inputTypeValue, typeValidation])
    // // Validation UseEffects


    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }


    React.useEffect(() => {
        if (!connectivity) return;
        connectivity.ConnectivityRequestContacts = connectivity.ConnectivityRequestContacts ?? [];
        setContacts(connectivity.ConnectivityRequestContacts);
        setAddContactClicked(false);
    }, [connectivity, connectivity.ConnectivityRequestContacts, contacts, editIndex]);

    const handleChangeInputFNValue = (e) => {
        setInputFNValue(e.target.value);
    }

    const handleChangeInputLNValue = (e) => {
        setInputLNValue(e.target.value);
    }

    const handleChangeInputEMValue = (e) => {
        setInputEMValue(e.target.value);
    }

    const handleChangeInputTypeValue = (e) => {
        setInputTypeValue(e.target.value);
    }

    const onAddContactClicked = (e) => {
        if (editIndex >= 0) return;
        setAddContactClicked(true);
        setSaveButtonClicked(false);
    }

    //Edit or Add contacts

    const handleAllInputs = (e) => {
        setSaveButtonClicked(true)
        if (firstNameValidation || lastNameValidation || emailValidation || typeValidation) return;
        openLoader();
        e.preventDefault();

        if (editIndex < 0) {
            const newContact = {
                ConnectivityRequestContactFirstName: inputFNValue,
                ConnectivityRequestContactLastName: inputLNValue,
                ConnectivityRequestContactEmail: inputEMValue,
                CarrierContactType: inputTypeValue,
            }
            setAddContactClicked(false);
            connectivity.ConnectivityRequestContacts = [...(connectivity.ConnectivityRequestContacts ?? []), newContact];
            setContacts(connectivity.ConnectivityRequestContacts);
            setIsContactDeleted(false);
        }
        else {
            const currentContact = connectivity?.ConnectivityRequestContacts[editIndex];
            currentContact.ConnectivityRequestContactFirstName = inputFNValue;
            currentContact.ConnectivityRequestContactLastName = inputLNValue;
            currentContact.ConnectivityRequestContactEmail = inputEMValue;
            currentContact.CarrierContactType = inputTypeValue;
            setEditIndex(-1);
            setIsContactDeleted(false);
        }
        setInputFNValue("");
        setInputLNValue("");
        setInputEMValue("");
        setInputTypeValue("");

        const updateContactObject = {
            ConnectivityRequestID: connectivity?.ConnectivityRequestID,
            CarriersContacts: connectivity?.ConnectivityRequestContacts.map((ConnectivityRequestContact) => ({
                isDelete: isContactDeleted,
                ConnectivityRequestContactEmail: ConnectivityRequestContact.ConnectivityRequestContactEmail,
                ConnectivityRequestContactFirstName: ConnectivityRequestContact.ConnectivityRequestContactFirstName,
                ConnectivityRequestContactID: typeof ConnectivityRequestContact.ConnectivityRequestContactID === "undefined" ? null : ConnectivityRequestContact.ConnectivityRequestContactID,
                ConnectivityRequestContactLastName: ConnectivityRequestContact.ConnectivityRequestContactLastName,
                CarrierContactType: ConnectivityRequestContact.CarrierContactType,
            })),
        }

        APIs.editConnectivityRequestContacts(updateContactObject).then((s) => {
            APIs.getGroupRequestData(groupRequestIDURL).then((r) => {
                setGroupReq({ groupRequest: r?.data?.GroupRequest, connectivities: r?.data?.connectivities });
                setSelectedSubgroupsView(r?.data?.GroupRequest?.SubGroups);
                closeLoader();
            });
        });
    }

    const handleDeleteContactConfirm = () => {
        openModal(0, deleteContact, {
            title: 'Please Confirm',
            question: `Are you sure you want to delete this Carrier contact?`,
        })
    }

    const deleteContact = () => {
        openLoader();
        let deletedContact = connectivity.ConnectivityRequestContacts[editIndex];
        deletedContact.isDelete = true;
        connectivity.ConnectivityRequestContacts.splice(editIndex, 1);
        connectivity.ConnectivityRequestContacts = [...(connectivity.ConnectivityRequestContacts ?? [])];
        setContacts(connectivity.ConnectivityRequestContacts);
        setEditIndex(-1);
        setInputFNValue("");
        setInputLNValue("");
        setInputEMValue("");
        setInputTypeValue("");
        setIsContactDeleted(true);

        const deleteContactObject = {
            ConnectivityRequestID: connectivity?.ConnectivityRequestID,
            CarriersContacts: [deletedContact],
        }

        APIs.editConnectivityRequestContacts(deleteContactObject).then((s) => {
            APIs.getGroupRequestData(groupRequestIDURL).then((r) => {
                setGroupReq({ groupRequest: r?.data?.GroupRequest, connectivities: r?.data?.connectivities });
                setSelectedSubgroupsView(r?.data?.GroupRequest?.SubGroups);
                closeLoader();
            });
        });

        // const deleteContactObject = {
        //     ConnectivityRequestID: connectivity?.ConnectivityRequestID,
        //     GroupRequestID: groupReq?.groupRequest?.GroupRequestID,
        //     CarrierID: connectivity?.ConnectivityRequestCarrierID,
        //     CarriersContacts: [deletedContact],
        //     IsolvedCarrier: {
        //         Code: connectivity?.IsolvedCarrierCode,
        //         Name: connectivity?.IsolvedCarrierName,
        //         Type: connectivity?.IsolvedCarrierType
        //     }
        // }

        // APIs.editConnectivityRequest(deleteContactObject).then((r) => (
        //     setContacts(connectivity.ConnectivityRequestContacts)
        // ));

    }

    const escFunction = React.useCallback((event) => {
        if (event.keyCode === 27) {
            //Do whatever when esc is pressed
            setInputFNValue("");
            setInputLNValue("");
            setInputEMValue("");
            setInputTypeValue("");
            setEditIndex(-1);
            setAddContactClicked(false);
        }
    }, []);

    React.useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return (
        <React.Fragment>
            {editIndex !== contactKey ? (
                <>
                    {(userActions?.includes("23") ||
                        userActions?.includes("20")) && (
                            <div className="col-12 px-0" style={{ display: "grid" }}>
                                <div className="row table-row">
                                    <div className="col table-cell" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                        {contactItem.ConnectivityRequestContactFirstName}
                                    </div>
                                    <div className="col table-cell" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                        {contactItem.ConnectivityRequestContactLastName}
                                    </div>
                                    <div className="col-5 table-cell" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                        {contactItem.ConnectivityRequestContactEmail}
                                    </div>
                                    <div className="col table-cell" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                        <div className="d-flex flex-column">

                                            {contactItem.CarrierContactType?.split("/").map((x, xi) => <div style={{ lineHeight: "20px", display: "block" }} key={xi}>{x}</div>)}
                                        </div>
                                    </div>
                                    <div className="col-1 table-cell" style={{ whiteSpace: "nowrap" }}>
                                        <Button className="icon-btn" color="inherit" onClick={() => {
                                            if (editIndex >= 0 || addContactClicked) return;
                                            setEditIndex(contactKey);
                                            setInputFNValue(contactItem.ConnectivityRequestContactFirstName);
                                            setInputLNValue(contactItem.ConnectivityRequestContactLastName);
                                            setInputEMValue(contactItem.ConnectivityRequestContactEmail);
                                            setInputTypeValue(contactItem.CarrierContactType);
                                        }}>

                                            <svg width="20" height="20" viewBox="0 0 20 20" >
                                                <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                                            </svg>
                                        </Button>
                                        {contacts.indexOf(contactItem) === contacts.length - 1 && (
                                            <Button className="icon-btn" color="inherit" disabled={addContactClicked} onClick={onAddContactClicked}>
                                                <svg width="20" height="20" viewBox="0 0 20 20" >
                                                    <use xlinkHref={`${addContactSVG}#addAIIcon`}></use>
                                                </svg>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {!(userActions?.includes("23") ||
                        userActions?.includes("20")) && (
                            <div className="col-12" style={{ display: "grid" }}>
                                <div className="row table-row">
                                    <div className="col table-cell" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                        {contactItem.ConnectivityRequestContactFirstName}
                                    </div>
                                    <div className="col table-cell" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                        {contactItem.ConnectivityRequestContactLastName}
                                    </div>
                                    <div className="col-5 table-cell" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                        {contactItem.ConnectivityRequestContactEmail}
                                    </div>
                                    <div className="col table-cell" style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                        {contactItem.CarrierContactType}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </>
            ) : (
                <>
                    <div className="col-12 text-center" style={{ display: "grid" }}>
                        <div className="row table-row">
                            <div className="col table-cell">
                                <div className="d-flex flex-column align-items-center">
                                    <TextField
                                        variant="outlined"
                                        value={inputFNValue}
                                        onChange={handleChangeInputFNValue}
                                        placeholder="Write first name here..."
                                    />
                                    {saveButtonClicked && firstNameValidation &&
                                        <span className="text-danger" style={{ fontSize: 10 }}>
                                            {firstNameValidation}
                                        </span>
                                    }
                                </div>


                            </div>
                            <div className="col table-cell">
                                <div className="d-flex flex-column align-items-center">
                                    <TextField variant="outlined" value={inputLNValue} onChange={handleChangeInputLNValue} placeholder="Write last name here..." />
                                    {saveButtonClicked && lastNameValidation &&
                                        <span className="text-danger" style={{ fontSize: 10 }}>
                                            {lastNameValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col table-cell">
                                <div className="editable-textfield">

                                    <TextField
                                        type="email"
                                        variant="outlined"
                                        value={inputEMValue}
                                        onChange={handleChangeInputEMValue}
                                        placeholder="Write email here..."
                                    />
                                    {saveButtonClicked && emailValidation &&
                                        <span className="text-danger" style={{ fontSize: 10 }}>
                                            {emailValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col table-cell">
                                <div className="editable-textfield">
                                    <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                        <Select style={{ textAlign: "left", display: "grid" }} className="text-dropdown" value={inputTypeValue} onChange={handleChangeInputTypeValue}>
                                            {carrierContactTypes?.map((c, ci) => (
                                                <MenuItem value={c}>{c}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {saveButtonClicked && typeValidation &&
                                        <span className="text-danger" style={{ fontSize: 10 }}>
                                            {typeValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col-1 table-cell">
                                {/* <div className="d-flex"> */}
                                <Button className="icon-btn" color="inherit"
                                    onClick={handleAllInputs}>
                                    <svg width="15" height="21" viewBox="0 0 22 21" >
                                        <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
                                    </svg>
                                </Button>
                                <CustomTooltip TransitionComponent={Zoom} title="Cancel">
                                    <Button className="icon-btn" color="inherit" onClick={() => {
                                        setEditIndex(-1);
                                        setInputFNValue("");
                                        setInputLNValue("");
                                        setInputEMValue("");
                                        setInputTypeValue("");
                                    }}>
                                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                        </svg>
                                    </Button>
                                </CustomTooltip>
                                {/* </div> */}
                                <CustomTooltip TransitionComponent={Zoom} title={connectivity.ConnectivityRequestContacts.length < 2 ? "Connectivity should have at least one contact" : ""}>
                                    <span>
                                        <Button className="icon-btn" color="inherit" disabled={connectivity.ConnectivityRequestContacts.length < 2} onClick={handleDeleteContactConfirm}>
                                            {/* <DeleteForeverIcon style={{ fontSize: 30 }} /> */}
                                            <svg width="21" height="24" viewBox="0 0 21 24">
                                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                            </svg>
                                        </Button>
                                    </span>
                                </CustomTooltip>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {addContactClicked && editIndex < 0 && contacts.indexOf(contactItem) === contacts.length - 1 && (
                <>
                    <div className="col-12 text-center" style={{ display: "grid" }}>
                        <div className="row table-row">
                            <div className="col table-cell">
                                <div className="editable-textfield">
                                    <TextField variant="outlined" value={inputFNValue} onChange={handleChangeInputFNValue} placeholder="Write first name here..." />
                                    {saveButtonClicked && firstNameValidation &&
                                        <span className="text-danger" style={{ fontSize: 10 }}>
                                            {firstNameValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col table-cell">
                                <div className="editable-textfield">
                                    <TextField variant="outlined" value={inputLNValue} onChange={handleChangeInputLNValue} placeholder="Write last name here..." />
                                    {saveButtonClicked && lastNameValidation &&
                                        <span className="text-danger" style={{ fontSize: 10 }}>
                                            {lastNameValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col table-cell">
                                <div className="editable-textfield">
                                    <TextField
                                        type="email"
                                        variant="outlined"
                                        value={inputEMValue}
                                        onChange={handleChangeInputEMValue}
                                        placeholder="Write email here..."
                                    />
                                    {saveButtonClicked && emailValidation &&
                                        <span className="text-danger" style={{ fontSize: 10 }}>
                                            {emailValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col table-cell">
                                <div className="editable-textfield">
                                    <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                                        <Select style={{ textAlign: "left", display: "grid" }} className="text-dropdown" value={inputTypeValue} onChange={handleChangeInputTypeValue}>
                                            {carrierContactTypes?.map((c, ci) => (
                                                <MenuItem value={c}>{c}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {saveButtonClicked && typeValidation &&
                                        <span className="text-danger" style={{ fontSize: 10 }}>
                                            {typeValidation}
                                        </span>
                                    }
                                </div>
                            </div>
                            <div className="col-1 table-cell">
                                <Button className="icon-btn" color="inherit" onClick={handleAllInputs}>
                                    <svg width="15" height="21" viewBox="0 0 22 21" >
                                        <use xlinkHref={`${saveIconSVG}#saveIcon`}></use>
                                    </svg>
                                </Button>
                                <CustomTooltip TransitionComponent={Zoom} title="Cancel">
                                    <Button className="icon-btn" color="inherit" onClick={() => setAddContactClicked(false)}>
                                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                        </svg>
                                    </Button>
                                </CustomTooltip>
                            </div>
                        </div>
                    </div>

                </>
            )
            }
            {/* <Snackbar
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                TransitionComponent={snackbarState.Transition}
                message="Press 'ESC' to cancel"
                key={snackbarState.Transition.name}
            /> */}
        </React.Fragment >
    )
}

export default ContactsRow;
