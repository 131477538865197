import React from "react";
import "./App.css";
import Header from "./Components/Layout/Header";
import Sidebar from "./Components/Layout/Sidebar";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Login from "./Components/Login/Login";
import { useAuthentication } from "./Data/Authentication";
import MFA from "./Components/Login/MFA";
import iSolvedBackground from "./Images/isolvedLoginBackground.jpg";
import ForgotPassword from "./Components/Login/ForgotPassword";
import ForgotPasswordMidLayer from "./Components/Login/ForgotPasswordMidLayer";
import ChangePassword from "./Components/Login/ChangePassword";
import ReactGA from 'react-ga';
import QRCodeComponent from "./Components/Login/QRCode";
import TOTP from "./Components/Login/TOTP";
import { Dialog } from "@material-ui/core";
import SSOContainer from "./Components/Login/SSO";
import initPDFWasm from "pdf-wasm"
import SignoutPage from "./Components/Login/SignoutPage";
import AllowedURLs from "./Data/AllowedURLs.json";
import ComponentsMap from './Data/ComponentsMap';
import { matchPath } from "react-router-dom/cjs/react-router-dom.min";
import Box from '@mui/material/Box';
import logofooter from "./Images/logo-isolved-footer.svg";

const drawerWidth = 110;
const yearDate = new Date().getFullYear();


const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
  }
}));

function App() {
  const { isAuthenticating, isAuthenticated, authUser } = useAuthentication();
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [errorResetPW, setErrorResetPW] = React.useState("");

  const [cognitoUser, setCognitoUser] = React.useState();
  const [open, setOpen] = React.useState(true);

  const sidebarClasses = useStyles();

  const [currentURLOnLogin, setCurrentURLOnLogin] = React.useState(window.location.href);
  const [instanceURL, setInstanceURL] = React.useState();

  React.useEffect(() => {
    const currentURL = window.location.pathname;
    ReactGA.pageview(currentURL);
    initPDFWasm().catch(console.error);
  }, [])

  React.useEffect(() => { if (authUser) setCognitoUser(authUser) }, [authUser]);

  React.useEffect(() => {
    if (isAuthenticated && currentURLOnLogin.includes("SSO/?SSOClient")) {
      window.location.href = "/"
    }
  }, [currentURLOnLogin, isAuthenticated])

  const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

  React.useEffect(() => {
    setInstanceURL(window.location.origin)
  }, [window.location.href])

  const allowedURLsForIds = AllowedURLs.filter(x => userRoles?.some(id => parseInt(id) === x.id))?.map(s => s.allowedURLs).reduce((acc, obj) => {
    for (const key in obj) {
      acc[key] = { ...acc[key], ...obj[key] };
    }
    return acc;
  }, {});

  const routes = allowedURLsForIds
    ? Object.values(allowedURLsForIds).flatMap(section =>
      Object.values(section).map((link, index) => {
        const Component = ComponentsMap[link.component];
        return <Route key={`${link.url}-${index}`} path={link.url} exact component={Component} />;
      })
    )
    : [];

  const dynamicUrls = allowedURLsForIds && Object.values(allowedURLsForIds).flatMap(section => Object.values(section)).map(x => x.url)?.filter(y => y.includes(":"));

  const isMatch = (path) => {
    return matchPath(window.location.pathname, { path, exact: true });
  };

  return (
    <div>
      {!isAuthenticated && !isAuthenticating && (
        <div style={{ backgroundImage: `url(${iSolvedBackground})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", height: "100vh" }}>
          <Router>
            <Switch>
              <Route path="/ResetPassword/Verify" render={() => <ForgotPassword cognitoUser={cognitoUser} email={email} error={errorResetPW} setError={setErrorResetPW} />} />
              <Route path="/ResetPassword" render={() => <ForgotPasswordMidLayer onCognitoUser={(s) => setCognitoUser(s)} email={email} setEmail={setEmail} error={errorResetPW} setError={setErrorResetPW} />} />
              <Route path="/ChangePassword" render={() => <ChangePassword cognitoUser={cognitoUser} setCognitoUser={setCognitoUser} />} />
              <Route path="/MFAAuthentication" render={() => <MFA cognitoUser={cognitoUser} currentURLOnLogin={currentURLOnLogin} />} />
              <Route path="/TOTP" render={() => <TOTP cognitoUser={cognitoUser} currentURLOnLogin={currentURLOnLogin} />} />
              <Route path="/SSO_New" render={() => <SSOContainer cognitoUser={cognitoUser} currentURLOnLogin={currentURLOnLogin} onCognitoUser={(s) => setCognitoUser(s)} username={username} setUsername={setUsername} password={password} setPassword={setPassword} error={error} setError={setError} />} />
              <Route path="/" render={() => <Login onCognitoUser={(s) => setCognitoUser(s)} username={username} setUsername={setUsername} password={password} setPassword={setPassword} error={error} setError={setError} />} />
              <Route path="/logout" exact component={SignoutPage} />
            </Switch>
          </Router>
        </div>
      )}
      {isAuthenticated && (authUser && (authUser.preferredMFA !== "SOFTWARE_TOKEN_MFA" && authUser.attributes != null && !authUser.attributes.identities)) && (
        <Dialog
          open={true}
          onClose={() => { }}
          aria-labelledby="dialog-title"
          className="qr-dialog"
        >
          <QRCodeComponent cognitoUser={authUser} />
        </Dialog>
      )}

      {isAuthenticated && (
        <Box sx={{ display: 'flex' }}>
          <Router>
            <Header open={open} onOpen={(s) => setOpen(s)} instanceURL={instanceURL} userRoles={userRoles} />
            {userRoles?.some(x => x !== "6" && x !== "10") && <Sidebar open={open} onOpen={(s) => setOpen(s)} />}
            <Box component="main" sx={{ flexGrow: 1 }}>
              {userRoles?.length > 0 &&
                <div className={`${userRoles?.includes("6") || userRoles?.includes("10") ? 'ml-0-imp' : ''}`}>
                  <Switch>
                    {routes.some(route => route.props.path === window.location.pathname || dynamicUrls.some(isMatch)) ? (
                      <>
                        {routes}
                        <div className="footer">
                          <div className="footer-content">
                            <svg viewBox="0 0 72 14" width="25%">
                              <use xlinkHref={`${logofooter}#logofooter`}></use>
                            </svg>
                            <span className="copyright">
                              © {yearDate}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <Redirect to="/" />
                    )}
                  </Switch>
                </div>
              }
            </Box>
          </Router>
        </Box>
      )}
    </div>
  );
}

export default App;
