import React from "react";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ListIcon from '@material-ui/icons/List';
import { Paper, Select } from "@material-ui/core";
import CarrierPlansAdminView from "./CarrierPlansAdminView";
import RelationshipCodesAdminView from "./RelationshipCodesAdminView";
import AddIcon from '@material-ui/icons/Add';
import GenericNewSectionAdminView from "./GenericNewSectionAdminView";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";

function PreMappingStepperAdminView({ onPreMapSubmitted, preMappingObject, preMappingBasicData, preMappingSections, connectivityReq, primaryAdmin, secondaryAdmin, onEdit, setOnEdit, isSaveActive, setIsSaveActive, ...props }) {

    const [stepsList, setStepsList] = React.useState([]);
    const { openLoader, closeLoader } = useLoader();

    const [activeStep, setActiveStep] = React.useState(0);
    const [preMapSubmitted, setPreMapSubmitted] = React.useState(false);

    React.useEffect(() => {
        setStepsList(preMappingSections?.map(s => ({
            title: s.Title,
            icon: s.Title === "Carrier Plans" ? <LocalHospitalIcon /> : s.Title === "Relationship Codes" ? <SupervisorAccountIcon /> : <AddIcon />
        })))
    }, [preMappingSections])

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    React.useEffect(() => {
        if (!primaryAdmin) return;
        else preMappingBasicData.Admins[0] = {
            Name: `${primaryAdmin?.FirstName} ${primaryAdmin?.LastName}`,
            Email: primaryAdmin?.Email,
            TypeID: 1,
            TypeName: "Primary",
            UserID: primaryAdmin?.CognitoID
        }
        if (secondaryAdmin === "") preMappingBasicData.Admins.splice(1, 1);
        else if (!secondaryAdmin) return;
        else preMappingBasicData.Admins[1] = {
            Name: `${secondaryAdmin?.FirstName} ${secondaryAdmin?.LastName}`,
            Email: secondaryAdmin?.Email,
            TypeID: 2,
            TypeName: "Secondary",
            UserID: secondaryAdmin?.CognitoID
        }
    }, [primaryAdmin, secondaryAdmin])

    const handleCancelEdit = () => {
        setOnEdit(false);
        setActiveStep(0);
    }

    const handlePreMappingSubmit = () => {
        openLoader();
        setPreMapSubmitted(true);
        onPreMapSubmitted(true);
        if (primaryAdmin === "") return;

        APIs.submitPreMappingFormAdmin(preMappingObject).then((r) => (
            window.location.reload()
        ));
    }

    return (
        <div id="premapping-form">
            <Paper elevation={0} className="p-5 mb-3">
                <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnector />}>
                    {stepsList?.map((s, si) => (
                        <Step className={activeStep === si ? "active" : ""} key={`steps-list-${si}`}>
                            <StepLabel icon={s.icon}>{s.title}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className="mt-5">
                    <div>
                        {preMappingSections?.map((c, ci) =>
                            activeStep === ci && (
                                c.Type?.toLowerCase() === "carrierplan" ? <CarrierPlansAdminView key={`carrier-plan-view-${ci}`} carrierPlansSection={c} onEdit={onEdit} setIsSaveActive={setIsSaveActive} />
                                    : c.Type?.toLowerCase() === "relationshipcode" ? <RelationshipCodesAdminView key={`relationship-codes-view-${ci}`} relationshipCodesSection={c} />
                                        : <GenericNewSectionAdminView key={`generic-view-${ci}`} genericSection={c} />)
                        )}
                    </div>
                    <div style={{ textAlign: "end" }} className="mt-5">
                        <Button disabled={activeStep === 0} className="blue-outlined-btn mr-2" onClick={handleBack}>Back</Button>
                        <Button disabled={!onEdit && activeStep === preMappingSections?.length - 1} className="blue-btn mr-2" onClick={onEdit ? activeStep === preMappingSections?.length - 1 ? handlePreMappingSubmit : handleNext : handleNext}>
                            {onEdit ? activeStep === preMappingSections?.length - 1 ? 'Submit' : 'Next' : "Next"}
                        </Button>
                        {/* {onEdit && (<Button className="pink-outlined-btn" onClick={handleCancelEdit}>Cancel</Button>)} */}

                        {onEdit && (
                            <>
                                {activeStep !== preMappingSections?.length - 1 &&
                                    <Button disabled={!isSaveActive} className="blue-btn mr-2" onClick={handlePreMappingSubmit}>
                                        Save
                                    </Button>
                                }
                                <Button className="blue-outlined-btn" onClick={handleCancelEdit}>Cancel</Button>

                            </>
                        )}




                    </div>
                </div>
            </Paper>
        </div >
    )
}

export default PreMappingStepperAdminView;