import React from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SelectCarrierPlansInternal from "./SelectCarrierPlansInternal";
import SelectSingleConnectivityRequestType from "./SelectSingleConnectivityRequestType";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SelectSingleConnectivityRequestMigration from "./SelectSingleConnectivityRequestMigration";
import { MenuItem, Select, FormControl } from "@material-ui/core";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import * as APIs from "../../../Data/APIs";
import Moment from 'moment';
import TabsTitlesWithMoreDD from "../../Layout/TabsTitlesWithMoreDD";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SelectConnectivityMigration({
    groupReq,
    isPEOClicked,
    selectedOtherCarriers,
    selectMigrationValidationForm,
    selectMigrationPhaseValidationForm,
    selectMigrationStarDateValidationForm,
    selectPlannedFirstProductionDateValidationForm,
    requestSubmitted,
    requestSaved,
    parentForceUpdate,
    carriers,
    setSelectedCarriers,
    selectedCarriers,
    ...props
}) {

    const [expanded, setExpanded] = React.useState("SelectCRMigration");
    const [tabValue, setTabValue] = React.useState(0);
    const [tabDefaultValue, setTabDefaultValue] = React.useState("[Other Carrier Name]")
    const [migrationPhasesList, setMigrationPhasesList] = React.useState([]);


    React.useEffect(() => {
        APIs.getMigrationPhases().then(r => !(r?.data?.length) ? setMigrationPhasesList([]) : setMigrationPhasesList(r?.data));
    }, [])

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        if (selectedCarriers?.filter(s => !s.Migration).length > 0) {
            selectedCarriers.filter(s => !s.Migration).map(s => s.Migration = groupReq?.groupRequest?.MigrationGroupRequest)
        } else return;
    }, [JSON.stringify(selectedCarriers)])

    React.useEffect(() => {
        if (selectedCarriers?.filter(s => !s.MigrationStartDate).length > 0) {
            selectedCarriers.filter(s => !s.MigrationStartDate).map(s => s.MigrationStartDate = null)
        } else return;
    }, [JSON.stringify(selectedCarriers)])

    React.useEffect(() => {
        if (selectedCarriers?.filter(s => !s.PlannedFirstProductionDate).length > 0) {
            selectedCarriers.filter(s => !s.PlannedFirstProductionDate).map(s => s.PlannedFirstProductionDate = null)
        } else return;
    }, [JSON.stringify(selectedCarriers)])

    React.useEffect(() => {
        if (selectedCarriers?.filter(s => !s.MigrationPhase).length > 0) {
            selectedCarriers.filter(s => !s.MigrationPhase).map(s => s.MigrationPhase = groupReq?.groupRequest?.MigrationPhaseID)
        } else return;
    }, [JSON.stringify(selectedCarriers)])

    // if selectedTab removed from selected Carriers list and it was last item
    const handleTabValue = () => {
        let otherCarrierInCasePEOLength = (selectedOtherCarriers?.selectedOtherCarriersFullArray?.length ?? 0)
        if (tabValue >= (selectedCarriers?.length + otherCarrierInCasePEOLength)) {
            let totalLength = (selectedCarriers.length + otherCarrierInCasePEOLength)
            if (totalLength == 0) {
                return (0)
            } else {
                return (selectedCarriers.length - 1 + otherCarrierInCasePEOLength)
            }
        } else {
            return (tabValue)
        }
    }


    React.useEffect(() => {
        setTabValue(handleTabValue())
    }, [selectedCarriers, tabValue, selectedOtherCarriers?.selectedOtherCarriersFullArray])

    return (
        <Accordion className="carriers-contact-acc" expanded={expanded === `SelectCRMigration`} id="select-carrier-plans">
            <AccordionSummary
                onClick={() => expanded === `SelectCRMigration` ? setExpanded("") : setExpanded("SelectCRMigration")}
                // className="pl-0 pr-0"
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="expand-carrier-contacts"
            >
                <span >Connectivity Requests Migration</span>
            </AccordionSummary>
            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationValidationForm}</div>
            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationStarDateValidationForm}</div>
            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
            <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectMigrationPhaseValidationForm}</div>
            <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted && !requestSaved}></div>
            <div className="col-xl-10 mt-2 mb-3 text-danger" hidden={!requestSubmitted && !requestSaved}>{selectPlannedFirstProductionDateValidationForm}</div>
            <AccordionDetails>
                <div>
                    {/* <AppBar position="static" color="default">
                        <div className="row">
                            <Tabs
                                className="tab-style w-100"
                                value={handleTabValue()}
                                onChange={handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs"
                            >
                                {selectedCarriers?.map((sc, sci) => (
                                    <Tab key={`carrier-contacts-tab-${sci}`} label={isPEOClicked ? sc?.CarrierName : sc?.carrierName} />
                                ))}
                                {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                                    <Tab
                                        key={`tp-tab-${si}`}
                                        label={s?.Name ?? tabDefaultValue}
                                    />
                                ))}
                            </Tabs>
                        </div>
                    </AppBar> */}
                    <div className="w-100">
                        <TabsTitlesWithMoreDD
                            tabTitle={[...(selectedCarriers?.map(sc => isPEOClicked ? sc?.CarrierName : sc?.carrierName) || [])
                                , ...(selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(s => s?.Name ?? tabDefaultValue) || [])]}
                            value={handleTabValue()}
                            setValue={setTabValue}
                        />
                    </div>
                    {selectedCarriers?.map((s, si) => (
                        si === tabValue && (
                            <SelectSingleConnectivityRequestMigration
                                groupReq={groupReq}
                                carrier={s}
                                index={si}
                                key={`single-carrier-plans-${si}`}
                                parentForceUpdate={parentForceUpdate}
                                isPEOClicked={isPEOClicked}
                                migrationPhasesList={migrationPhasesList}
                            />
                        )
                    ))}
                    {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                        si + selectedCarriers.length === tabValue && (
                            <SelectSingleConnectivityRequestMigration
                                groupReq={groupReq}
                                carrier={s}
                                index={si}
                                key={`single-carrier-plans-${si}`}
                                parentForceUpdate={parentForceUpdate}
                                isPEOClicked={isPEOClicked}
                                migrationPhasesList={migrationPhasesList}
                            />
                        )
                    ))}
                </div >
            </AccordionDetails>
        </Accordion>
    )
}

export default SelectConnectivityMigration;
