import React from "react";
import Truncate from 'react-truncate';
import { Link } from "@material-ui/core";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Button from "@material-ui/core/Button";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { useCommentsModalDialog } from "../../../Layout/CommentsModalDialog";
import { Comments } from "../../Comments";
import ContentBody from "../../ContentBody";
import { useAuthentication } from "../../../../Data/Authentication";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';

function CarrierPlansAdminView({ carrierPlansSection, onEdit, setIsSaveActive, ...props }) {

    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const commentsDialog = useCommentsModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [customerPlans, setCustomerPlans] = React.useState([]);
    const [carrierPlans, setCarrierPlans] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [index, setIndex] = React.useState(-1);
    const [customerMatchedPlansArray, setCustomerMatchedPlansArray] = React.useState([]);

    const _colorOptions = {
        primaryAnalystColor: "var(--yellow-secondary-color)",
        primaryAdminColor: "var(--purple)"
    };

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupPlans(connectivityReq?.ConnectivityRequestID).then((r) => (
            setCustomerPlans(r?.data),
            closeLoader()
        ));
    }, [connectivityReq])

    React.useEffect(() => {
        setCarrierPlans(carrierPlansSection?.Model.map(s => ({
            carrierPlanName: s.CarrierPlanName,
            carrierPlanType: s.PlanType
        })))
    }, [carrierPlansSection])

    const handleFunction = (value) => {
        setIndex(value);
        const commentsBody = {
            Type: carrierPlansSection.Type,
            GUID: carrierPlansSection.Model[value].GUID
        }

        APIs.getPreMappingComments(commentsBody).then((r) => {
            setComments(r?.data); forceUpdate();
        })
    }

    React.useEffect(() => {
        if (index === -1) return;
        handleFunction(index); forceUpdate();
    }, [index])

    React.useEffect(() => {
        if (comments === undefined || index === -1) return;
        carrierPlansSection.Model[index].Comment = comments[comments?.length - 1] ?? [];
    }, [comments?.length])

    return (
        <div id="carrier-plans-section">
            <div className="row">
                <div className="col-xl-6">
                    <div className="table-header row mr-1">
                        <div className="col-xl-9 table-cell first-cell">Carrier Plan Names</div>
                        <div className="col-xl-3 table-cell">Plan Type</div>
                    </div>
                    <div className="table-row mr-1">
                        {carrierPlans.map((c, ci) => (
                            <div key={`carrier-plans-view-${ci}`} className="row">
                                <div className="col-xl-9 table-cell first-cell">{c.carrierPlanName}</div>
                                <div className="col-xl-3 table-cell">{c.carrierPlanType}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="col-xl-6">
                    <div className="table-header row ml-1">
                        <div className="col-xl-9 table-cell first-cell">Group Plan Names</div>
                        <div className="col-xl-3 table-cell">Plan Type</div>
                    </div>
                    <div className="table-row ml-1">
                        {customerPlans.map((c, ci) => (
                            <div key={`customer-plans-list-${ci}`} className="row">
                                <div className="col-xl-9 table-cell first-cell">{c.BenefitName}</div>
                                <div className="col-xl-3 table-cell">{c.BenefitType}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="table-header row">
                        <div className="col-xl-6 table-cell first-cell">Mapping Plans</div>
                        <div className="col-xl-6 table-cell">Comments</div>
                    </div>
                </div>
            </div>
            <div className="table-subheader row">
                <div className="col-xl-3 table-cell first-cell">Carrier Plans</div>
                <div className="col-xl-3 table-cell">Group Plans</div>
                <div className="col-xl-1 table-cell">User</div>
                <div className="col-xl-4 table-cell">Latest Comment</div>
                <div className="col-xl-1 table-cell">Actions</div>
            </div>
            {carrierPlansSection?.Model?.map((p, pi) => (
                <div key={`plans-section-view-${pi}`} className="table-row row">
                    <div className="col-xl-3 table-cell first-cell">
                        {p.CarrierPlanName}
                    </div>
                    <div id="customer-match-plan" className="col-xl-3 table-cell">
                        {p.GroupPlans && p.GroupPlans.length > 0 && !onEdit ? (
                            p.GroupPlans.map((s, si) => (
                                <div key={`matched-plans-${si}`}>{s.GroupPlanName}</div>
                            ))
                        ) : (
                            onEdit ? (
                                <React.Fragment>
                                    <FormControl label="Select Matched Plans" focused={false} variant="outlined" className="mt-2 w-100">
                                        <Select
                                            className="text-dropdown"
                                            value={p.GroupPlans ?? []}
                                            onChange={e => {
                                                setIndex(pi);
                                                if (p.GroupPlans === undefined) p.GroupPlans = [];
                                                if (p.GroupPlans.map(s => s.GroupPlanName).indexOf(e.target.value[p.GroupPlans?.length]) >= 0)
                                                    p.GroupPlans.splice(p.GroupPlans.indexOf({
                                                        GroupPlanName: e.target.value[p.GroupPlans?.length],
                                                        GroupPlanID: customerPlans.filter(s => (s.BenefitName === e.target.value[p.GroupPlans?.length]))[0]?.Id,
                                                    }), 1);
                                                else {
                                                    p.GroupPlans = [...p.GroupPlans, {
                                                        GroupPlanName: e.target.value[p.GroupPlans.length],
                                                        GroupPlanID: customerPlans.filter(s => (s.BenefitName === e.target.value[p.GroupPlans.length]))[0]?.Id,
                                                    }];
                                                }
                                                forceUpdate();
                                                setIsSaveActive(true);
                                            }}
                                            variant="outlined"
                                            multiple
                                            input={
                                                <Input />
                                            }
                                            renderValue={(selected) => selected.map(s => s.GroupPlanName).join(', ')}
                                        >
                                            {customerPlans?.map((c, ci) => (
                                                <MenuItem key={`carrier-plans-${ci}`} value={c.BenefitName}>
                                                    <Checkbox checked={p.GroupPlans?.map(s => s.GroupPlanName).indexOf(c.BenefitName) > -1} />
                                                    <ListItemText primary={c.BenefitName} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {p.GroupPlans?.map((s, si) => (
                                        <Chip
                                            className="mt-1 mr-1 d-flex"
                                            key={`plans-chips=${si}`}
                                            label={s.GroupPlanName}
                                            onDelete={() => {
                                                setIndex(pi);
                                                let array = p.GroupPlans;
                                                array.splice(si, 1);
                                                p.GroupPlans = [...array];
                                                setCustomerMatchedPlansArray([...array]);
                                                setIsSaveActive(true);
                                            }}
                                        />
                                    ))}
                                </React.Fragment>
                            ) : (
                                <div className="no-data-alert"><span className="text-danger fw-700">Pending action!</span><br />Please edit form to match new plans.</div>
                            )
                        )}
                    </div>
                    <div className="col-xl-1 table-cell">
                        <div className="userInfo">
                            <CustomTooltip TransitionComponent={Zoom} title={<div><span>{p.Comment?.Name}</span><br /><span>{p.Comment?.Email}</span></div>}>
                                <span className="user" style={{ backgroundColor: _colorOptions.primaryAnalystColor }}>{p.Comment?.Name?.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                            </CustomTooltip>
                        </div>
                    </div>
                    <div className="col-xl-4 table-cell">
                        <div className="mt-1">
                            <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} 
                            onClick={(e) => { 
                                e.stopPropagation(); 
                                e.preventDefault();
                                 dialog.openDialog(<span style={{ whiteSpace: "break-spaces" }}>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                                <span>{p.Comment?.Text}</span>
                            </Truncate>
                        </div>
                    </div>
                    <div className="col-xl-1 table-cell">
                        <div className="comments-section-single-column">
                            <Button onClick={e => {
                                handleFunction(pi); forceUpdate(); Comments(commentsDialog, {
                                    title: p.CarrierPlanName,
                                    body: <ContentBody section={carrierPlansSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                });
                            }}>
                                <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                            </Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default CarrierPlansAdminView; 
