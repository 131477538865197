import React from "react";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Truncate from 'react-truncate';
import { Link } from "@material-ui/core";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import TextsmsIcon from '@material-ui/icons/Textsms';
import Button from "@material-ui/core/Button";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { useCommentsModalDialog } from "../../../Layout/CommentsModalDialog";
import { Comments } from "../../Comments";
import ContentBody from "../../ContentBody";
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";

function RelationshipCodesAdminView({ relationshipCodesSection, ...props }) {

    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const commentsDialog = useCommentsModalDialog(0);
    const [, forceUpdate] = React.useReducer(x => !x, false);
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [customerRelationshipCodes, setCustomerRelationshipCodes] = React.useState([]);
    const [carrierRelationshipCodes, setCarrierRelationshipCodes] = React.useState([]);
    const [comments, setComments] = React.useState([]);
    const [index, setIndex] = React.useState(-1);

    const _colorOptions = {
        primaryAnalystColor: "var(--yellow-secondary-color)",
        primaryAdminColor: "var(--purple)"
    };

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupRelationshipCodes(connectivityReq?.ConnectivityRequestID).then((r) => (
            setCustomerRelationshipCodes(r?.data?.map(s => s.Name)),
            closeLoader()
        ));
    }, [connectivityReq])

    React.useEffect(() => {
        setCarrierRelationshipCodes(relationshipCodesSection?.Model.map(s => s.CarrierRelationshipCode))
    }, [relationshipCodesSection])

    const handleFunction = (value) => {
        setIndex(value);
        const commentsBody = {
            Type: relationshipCodesSection.Type,
            GUID: relationshipCodesSection.Model[value]?.GUID
        }

        APIs.getPreMappingComments(commentsBody).then((r) => {
            setComments(r?.data); forceUpdate();
        })
    }

    React.useEffect(() => {
        if (index === -1) return;
        handleFunction(index); forceUpdate();
    }, [index])

    React.useEffect(() => {
        if (comments === undefined || index === -1) return;
        relationshipCodesSection.Model[index].Comment = comments[comments?.length - 1];
    }, [comments])

    return (
        <div id="relationship-codes-section">
            {relationshipCodesSection.IncludeDependents && (
                <div className="row">
                    <div className="col-xl-6">
                        <div className="table-header row mr-1">
                            <div className="col-xl-12 table-cell first-cell">Carrier Relationship Codes</div>
                        </div>
                        <div className="table-row mr-1">
                            {carrierRelationshipCodes.map((c, ci) => (
                                <div key={`relationship-codes-${ci}`} className="row">
                                    <div className="col-xl-12 table-cell first-cell">{c}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="col-xl-6">
                        <div className="table-header row ml-1">
                            <div className="col-xl-12 table-cell first-cell">Group Relationship Codes</div>
                        </div>
                        <div className="table-row ml-1">
                            {customerRelationshipCodes.map((c, ci) => (
                                <div key={`customer-relationship-codes-${ci}`} className="row">
                                    <div className="col-xl-12 table-cell first-cell">{c}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {!relationshipCodesSection.IncludeDependents && (
                <div className="row alignvh-center mb-5 mt-5">
                    <div className="col-xl-12">
                        <div className="no-data-alert">
                            <h6>This file will not include dependents</h6>
                        </div>
                    </div>
                </div>
            )}
            {relationshipCodesSection.IncludeDependents && (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-header row">
                                <div className="col-xl-3 table-cell first-cell">Mapping Relationship Codes</div>
                                <div className="col-xl-9 table-cell">Comments</div>
                            </div>
                        </div>
                    </div>
                    <div className="table-subheader row">
                        <div className="col-xl-3 table-cell first-cell">Carrier Relationship Codes</div>
                        <div className="col-xl-1 table-cell">User</div>
                        <div className="col-xl-7 table-cell">Latest Comment</div>
                        <div className="col-xl-1 table-cell">Actions</div>
                    </div>
                    {relationshipCodesSection?.Model?.map((p, pi) => (
                        <div key={`relationship-section-${pi}`} className="table-row row">
                            <div className="col-xl-3 table-cell first-cell">
                                {p.CarrierRelationshipCode}
                            </div>
                            <div className="col-xl-1 table-cell">
                                <div className="userInfo">
                                    <CustomTooltip TransitionComponent={Zoom} title={<div><span>{p.Comment?.Name}</span><br /><span>{p.Comment?.Email}</span></div>}>
                                        <span className="user" style={{ backgroundColor: _colorOptions.primaryAnalystColor }}>{p.Comment?.Name.match(/\b(\w)/g).join('').toUpperCase().substring(0, 2)}</span>
                                    </CustomTooltip>
                                </div>
                            </div>
                            <div className="col-xl-7 table-cell">
                                <Truncate lines={2} ellipsis={<span>... <Link style={{ fontSize: "15px", cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog(<span>{p.Comment?.Text}</span>) }}>Read More</Link></span>}>
                                    <span>{p.Comment?.Text}</span>
                                </Truncate>
                            </div>
                            <div className="col-xl-1 table-cell">
                                <div className="comments-section-single-column">
                                    <Button onClick={e => {
                                        handleFunction(pi); Comments(commentsDialog, {
                                            title: p.CarrierRelationshipCode,
                                            body: <ContentBody section={relationshipCodesSection} index={pi} colorOptions={_colorOptions} connectivityRequestIDURL={connectivityRequestIDURL} onComments={s => setComments(s)} />,
                                        });
                                    }}>
                                        <TextsmsIcon style={{ width: "25px", height: "25px" }} color="action" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
        </div >
    )
}

export default RelationshipCodesAdminView;