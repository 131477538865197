import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import SelectSingleCarrierPlan from "./SelectSingleCarrierPlan";
import TabsTitlesWithMoreDD from "../../Layout/TabsTitlesWithMoreDD";




function SelectCarrierPlansInternal({ isPEOClicked, selectedOtherCarriers, parentForceUpdate, carriers, setSelectedCarriers, selectedCarriers, ...props }) {


    const { openLoader, closeLoader } = useLoader();
    const [planTypesList, setPlanTypesList] = React.useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [tabDefaultValue, setTabDefaultValue] = React.useState("[Other Carrier Name]")

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        APIs.getPlanTypes().then((r) => setPlanTypesList(r?.data?.sort((a, b) => a.PlanTypeName.toLowerCase() > b.PlanTypeName.toLowerCase() ? 1 : -1)));
    }, [])

    // if selectedTab removed from selected Carriers list and it was last item
    const handleTabValue = () => {
        let otherCarrierInCasePEOLength = (selectedOtherCarriers?.selectedOtherCarriersFullArray?.length ?? 0)
        if (tabValue >= (selectedCarriers?.length + otherCarrierInCasePEOLength)) {
            let totalLength = (selectedCarriers.length + otherCarrierInCasePEOLength)
            if (totalLength == 0) {
                return (0)
            } else {
                return (selectedCarriers.length - 1 + otherCarrierInCasePEOLength)
            }
        } else {
            return (tabValue)
        }
    }


    React.useEffect(() => {
        setTabValue(handleTabValue())
    }, [selectedCarriers, tabValue, selectedOtherCarriers?.selectedOtherCarriersFullArray])



    return (
        <div style={{ width: "100%" }}>
            <div className="border-bottom">
                <TabsTitlesWithMoreDD
                    tabTitle={[
                        ...(selectedCarriers?.map(sc => isPEOClicked ? sc?.CarrierName : sc?.carrierName) || []),
                        ...(selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(s => s?.Name ?? tabDefaultValue) || [])
                    ]}
                    value={handleTabValue()}
                    setValue={setTabValue}
                />
            </div>
            {/* <AppBar position="static" color="default">
                <div className="row">
                    <Tabs
                        className="tab-style w-100"
                        // if selectedTab removed from selected Carriers list and it was last item
                        value={handleTabValue()}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs"
                    >
                        {selectedCarriers?.map((sc, sci) => (
                            <Tab key={`carrier-contacts-tab-${sci}`} label={isPEOClicked ? sc?.CarrierName : sc?.carrierName} />
                        ))}
                        {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                            <Tab
                                key={`tp-tab-${si}`}
                                label={s?.Name ?? tabDefaultValue}
                            />
                        ))}
                    </Tabs>
                </div>
            </AppBar> */}
            {selectedCarriers?.map((s, si) => (
                si === tabValue && (
                    <SelectSingleCarrierPlan
                        carrier={s}
                        index={si}
                        key={`single-carrier-plans-${si}`}
                        planTypesList={planTypesList}
                        parentForceUpdate={parentForceUpdate}
                    />
                )
            ))}
            {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                si + selectedCarriers.length === tabValue && (
                    <SelectSingleCarrierPlan
                        carrier={s}
                        index={si}
                        key={`single-carrier-plans-${si}`}
                        planTypesList={planTypesList}
                        parentForceUpdate={parentForceUpdate}
                    />
                )
            ))}
        </div >
    )
}

export default SelectCarrierPlansInternal;
