import React from "react";

function SupportView() {
    return (
        <div>

        </div>
    )
}

export default SupportView;