import React from "react";
import { FormControl, Paper } from "@material-ui/core";
import HomeConnectivityRequestsTable from "./HomeConnectivityRequestsTable";
import { Button, TextField } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import ClearIcon from '@material-ui/icons/Clear';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import TruncatedChip from "../../Layout/TruncatedChip";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HomeConnectivityRequestsFilterModalBody from "./HomeConnectivityRequestsFilterModalBody";
import { useAuthentication } from "../../../Data/Authentication";
import refreshSVG from "../../../Icons/refresh.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../Icons/advancedFilter.svg";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function HomeConnectivityRequestsListView({
    connectivityRequestsList,
    setConnectivityRequestsList,
    selectedPage,
    onSelectedPage,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSize,
    onPageSize,
    pageSizeAdv,
    onPageSizeAdv,
    onGroupNameFilter,
    groupNameFilter,
    onPEONameFilter,
    peoNameFilter,
    onCarrierNameFilter,
    carrierNameFilter,
    totalCount,
    setTotalCount,
    onStatusFilter,
    statusFilter,
    onSortFieldFilter,
    onSortOrderFilter,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    connectivityRequestsStatus,
    onSelectedGroupsFilter,
    selectedGroupsFilter,
    onSelectedPEOsFilter,
    selectedPEOsFilter,
    onSelectedStatusesFilter,
    selectedStatusesFilter,
    onSelectedCarriersFilter,
    selectedCarriersFilter,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    setRefreshClicked,
    ...props
}) {
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [value, setValue] = React.useState(0);
    const [grpName, setGrpName] = React.useState();
    const [grpNameAutoValue, setGrpNameAutoValue] = React.useState("");
    const [peoName, setPEOName] = React.useState();
    const [peoNameAutoValue, setPEONameAutoValue] = React.useState("");
    const [carName, setCarName] = React.useState();
    const [carNameAutoValue, setCarNameAutoValue] = React.useState("");
    const [connectRequestStatus, setConnectRequestStatus] = React.useState("");
    const [connectivityRequestsStatuses, setConnectivityRequestsStatuses] = React.useState([]);
    const [groupsList, setGroupsList] = React.useState([]);
    const [peosList, setPEOsList] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        onStatusFilter("");
        onGroupNameFilter("");
        onPEONameFilter("");
        onCarrierNameFilter("");
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
    };

    React.useEffect(() => {
        openLoader();
        if (!userRoles?.includes("6")) {
            APIs.getGroupsListByName({ GroupName: grpName ?? "" }).then((r) => !(r?.data?.length) ? setGroupsList([]) : setGroupsList(r?.data));
            APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
        }
        APIs.getCarriersListByName({ CarrierName: carName ?? "" }).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [])
    React.useEffect(() => {
        setGrpNameAutoValue(grpName);
    }, [grpName])
    React.useEffect(() => {
        setPEONameAutoValue(peoName);
    }, [peoName])
    React.useEffect(() => {
        setCarNameAutoValue(carName);
    }, [carName])

    const handleConnectivityRequestStatusFilter = (e, newValue) => {
        setConnectRequestStatus(e.target.value);
        onStatusFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleClearSearch = () => {
        setConnectRequestStatus("");
        onStatusFilter("");
        setGrpName("");
        onGroupNameFilter("");
        setPEOName("");
        onPEONameFilter("");
        setCarName("");
        onCarrierNameFilter("");
        onSelectedPage(1);
    }

    const handleClearSearchReset = () => {
        setConnectRequestStatus("");
        onStatusFilter("");
        setGrpName("");
        onGroupNameFilter("");
        setPEOName("");
        onPEONameFilter("");
        setCarName("");
        onCarrierNameFilter("");
        onSelectedPageAdv(1);
        onSelectedGroupsFilter([]);
        onSelectedPEOsFilter([]);
        onSelectedStatusesFilter([]);
        onSelectedCarriersFilter([]);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <div>
            {/* remove filters for client view */}
            {!userRoles?.includes("6") &&
                <>
                    <Paper className="content-card-paper search-container">
                        <div className="row alignvh-center">
                            <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                            <Button
                                className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                                onClick={handleClickOpenDialog}
                                style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                            >
                                <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1">
                                    <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                                </svg>
                                Advanced Filters
                            </Button>
                            <React.Fragment>
                                {groupNameFilter && <TruncatedChip body={`Client: ${groupNameFilter}`} />}
                                {selectedGroupsFilter && selectedGroupsFilter?.length > 0 && <TruncatedChip body={`Clients: ${selectedGroupsFilter?.map(s => s.GroupName).join(" - ")}`} />}
                                {peoNameFilter && <TruncatedChip body={`PEO: ${peoNameFilter}`} />}
                                {selectedPEOsFilter && selectedPEOsFilter?.length > 0 && <TruncatedChip body={`PEOs: ${selectedPEOsFilter?.map(s => s.PeoName).join(" - ")}`} />}
                                {carrierNameFilter && <TruncatedChip body={`Carrier: ${carrierNameFilter}`} />}
                                {selectedCarriersFilter && selectedCarriersFilter?.length > 0 && <TruncatedChip body={`Carriers: ${selectedCarriersFilter?.map(s => s.CarrierName).join(" - ")}`} />}
                                {statusFilter && <TruncatedChip body={`Status: ${connectivityRequestsStatus?.filter(s => s.ConnectivityRequestStatusID == statusFilter)[0].ConnectivityRequestStatusName}`} />}
                                {selectedStatusesFilter && selectedStatusesFilter?.length > 0 && <TruncatedChip body={`Status: ${selectedStatusesFilter?.map(s => s.ConnectivityRequestStatusName).join(" - ")}`} />}
                            </React.Fragment>
                        </div>
                        {quickFiltersClicked && (
                            <div className="row">
                                <div className="col-xl-3 mt-3">
                                    <span>Client Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={groupsList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={grpNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.GroupName}
                                        onChange={(e, value) => {
                                            setGrpName(value?.GroupName ?? "");
                                            onGroupNameFilter(value?.GroupName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.GroupName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setGrpNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>PEO Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={peosList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={peoNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.PeoName}
                                        onChange={(e, value) => {
                                            setPEOName(value?.PeoName ?? "");
                                            onPEONameFilter(value?.PeoName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.PeoName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setPEONameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Carrier Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={carriersList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={carNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.CarrierName}
                                        onChange={(e, value) => {
                                            setCarName(value?.CarrierName ?? "");
                                            onCarrierNameFilter(value?.CarrierName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.CarrierName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setCarNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Status:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={statusFilter ?? ""}
                                            onChange={handleConnectivityRequestStatusFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            {connectivityRequestsStatus?.map((p, pi) => (
                                                <option key={`connectivity-request-status-${pi}`} value={p.ConnectivityRequestStatusID}>{p.ConnectivityRequestStatusName}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                            </div >
                        )}
                        <div className="col-xl-12 mt-4" >
                            <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                                <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                                    <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                                </svg>
                                <span className="ml-1">Refresh List</span>
                            </Button>
                            <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                                <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                    <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                </svg> Clear Search
                            </Button>
                        </div>

                        <HomeConnectivityRequestsTable advancedFiltersClicked={advancedFiltersClicked} quickFiltersClicked={quickFiltersClicked} connectivityRequestsList={connectivityRequestsList} setConnectivityRequestsList={setConnectivityRequestsList} selectedPage={selectedPage} onSelectedPage={onSelectedPage} pageSize={pageSize} onPageSize={onPageSize} selectedPageAdv={selectedPageAdv} onSelectedPageAdv={onSelectedPageAdv} pageSizeAdv={pageSizeAdv} onPageSizeAdv={onPageSizeAdv} totalCount={totalCount} onSortFieldFilter={onSortFieldFilter} onSortOrderFilter={onSortOrderFilter} onSortFieldAdvFilter={onSortFieldAdvFilter} onSortOrderAdvFilter={onSortOrderAdvFilter} />

                    </Paper >

                    <HomeConnectivityRequestsFilterModalBody
                        setTotalCount={setTotalCount}
                        advancedFiltersClicked={advancedFiltersClicked}
                        setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        onPageSizeAdv={onPageSizeAdv}
                        onSelectedPageAdv={onSelectedPageAdv}
                        setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                        onSelectedGroupsFilter={onSelectedGroupsFilter}
                        onSelectedPEOsFilter={onSelectedPEOsFilter}
                        onSelectedCarriersFilter={onSelectedCarriersFilter}
                        onSelectedStatusesFilter={onSelectedStatusesFilter}
                        onCloseClearReset={handleClearSearchReset}
                    />
                </>
            }
            {userRoles?.includes("6") &&
                <Paper elevation={0} className="content-card-paper">
                    <HomeConnectivityRequestsTable advancedFiltersClicked={advancedFiltersClicked} quickFiltersClicked={quickFiltersClicked} connectivityRequestsList={connectivityRequestsList} setConnectivityRequestsList={setConnectivityRequestsList} selectedPage={selectedPage} onSelectedPage={onSelectedPage} pageSize={pageSize} onPageSize={onPageSize} selectedPageAdv={selectedPageAdv} onSelectedPageAdv={onSelectedPageAdv} pageSizeAdv={pageSizeAdv} onPageSizeAdv={onPageSizeAdv} totalCount={totalCount} onSortFieldFilter={onSortFieldFilter} onSortOrderFilter={onSortOrderFilter} onSortFieldAdvFilter={onSortFieldAdvFilter} onSortOrderAdvFilter={onSortOrderAdvFilter} />
                </Paper>
            }
        </div >
    )
}

export default HomeConnectivityRequestsListView;
