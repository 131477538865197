import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';
import * as Apis from '../../../../Data/APIs'
import { useLoader } from '../../../Layout/Loader';
import ClientFormsTransmissionsTabSingleFeedWithFormsSingleFormRow from './ClientFormsTransmissionsTabSingleFeedWithFormsSingleFormRow';
import moment from 'moment';
import { Button, Divider } from "@material-ui/core";
import { useModalDialog } from "../../../Layout/ModalDialogCustomized";
import { ConfirmModal } from "../../../Modals/ConfirmModal";
import { SuccessModal } from "../../../Modals/SuccessModal";
import { FailureModal } from "../../../Modals/FailureModal";
import { useAuthentication } from '../../../../Data/Authentication';
import { toESTTime } from '../../../../_helper';
import ChangedDetails from '../../ChangedDetails';
import downloadIconSVG from "../../../../Icons/downloadIcon.svg";
import GenericTable from '../../../Layout/GenericTable';




const ClientFormsTransmissionsTabSingleFeedWithForms = ({ feed, ...props }) => {

    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [expanded, setexpanded] = useState(false);

    const [forms, setForms] = useState([]);
    const [lastEvaluatedKey, setlastEvaluatedKey] = useState(null)

    const [selfForms, setSelfForms] = useState([]);
    const [SelfLastEvaluatedKey, setSelfLastEvaluatedKey] = useState(null);

    const [dfElements, setDfElements] = useState([])

    const { authUser } = useAuthentication();
    let userLegalID = authUser?.attributes["custom:LegalID"]
    let userAccountID = authUser?.attributes["custom:AccountID"]
    let userClientID = authUser?.attributes["custom:ClientID"]
    // formsType ==> all , self , other
    let getFormsPerFeed = (FeedID, formsType, withReset, withResetSelf) => {
        openLoader();
        let currentFeedInfo = {
            //lsi 5 >> Transmissions Tab
            lsi: '5',
            roleID: "6",
            feedID: FeedID,
            userClientID: userClientID ?? '',
            userAccountID: userAccountID ?? '',
            userLegalID: userLegalID ?? ''
        };
        if ((formsType == 'all' || formsType == 'other') && lastEvaluatedKey && !withReset) currentFeedInfo.LastEvaluatedKey = lastEvaluatedKey
        if ((formsType == 'all' || formsType == 'self') && SelfLastEvaluatedKey && !withResetSelf) currentFeedInfo.SelfLastEvaluatedKey = SelfLastEvaluatedKey
        Apis.getEmployeeForms({ ...currentFeedInfo }).then(r => {
            const { selfData, clientData } = r;
            // self Data
            if (selfData.msg == "Success") {
                setDfElements(r.selfData.DFElements)
                if (formsType == 'all' || formsType == 'self') {
                    if (withResetSelf)
                        setSelfForms(selfData?.data);
                    else
                        setSelfForms([...selfForms, ...selfData?.data]);
                    setSelfLastEvaluatedKey(selfData?.SelfLastEvaluatedKey ? selfData?.SelfLastEvaluatedKey : null)
                }
            } else setSelfForms([])

            // all Data
            if (clientData.msg == "Success") {
                if (!dfElements) {
                    setDfElements(r.clientData.DFElements)
                }
                if (formsType == 'all' || formsType == 'other') {
                    let notSelfForms = clientData?.data.filter(f => f.selfFlag !== "1")
                    if (withReset)
                        setForms(notSelfForms);
                    else
                        setForms([...forms, ...notSelfForms]);
                    setlastEvaluatedKey(clientData?.LastEvaluatedKey ? clientData?.LastEvaluatedKey : null)
                }
            } else setForms([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }


    let handleExpandFeed = (FeedID) => {
        if (!expanded) {
            getFormsPerFeed(FeedID, 'all', true, true)
        }
        setexpanded(prev => !prev);
    }
    // Process Feed
    // process Feed and get raw data 
    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content, 'lineHeight-1-6em');
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }
    const getTestFile = (testOnly) => {
        // testOnly =>>will be 1 or 0 , 1 = stops on parser step only
        openLoader();
        Apis.getTestFile({ feedId: feed?.FeedID, testOnly }).then((r) => {
            if (r?.statusCode === 200) {
                SuccessModal(dialog, {
                    title: 'Success!',
                    body: `Your request has been processed successfully!`,
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: `The request has not been processed successfully. Please try again!`,
                });
            }
            closeLoader();
        })
    }
    const getTestFileConfirmation = () => {
        // if (feed?.StructureTypeID === 3) {
        //     dialog.openDialog(
        //         <Set401kProcessFeedDate feed={feed} feedIDURL={feedIDURL} />
        //     )
        // }
        openModal(0, () => getTestFile(0), {
            title: 'Please Confirm',
            question: `Generate Forms will clear all the Forms that are still Pending an action <br/> Please make sure all Pending Forms are transmitted or rejected before generating new forms`,
        })
    }

    // ========================================NEW TABLE UI

    const handleDownloadFile = (form) => {
        let viewFormObject = {
            OutputFile: form?.outputFile,
            isDF: true,
            feedID: feed?.FeedID,
            executionID: form?.executionID,
            fileName:
                form?.empName + " - " +
                form?.carrierName + " - " +
                (form?.formGenerationTime ? moment(toESTTime(form?.formGenerationTime)).format('MM-DD-YYYY') : "N/A")
        }
        openLoader();
        Apis.generateOldPdfUrlForEmployees(viewFormObject).then((r) => {
            window.open(r?.data);
            closeLoader();
        });
    }


    const baseColumnsArrayWithSelfFlag = (withSelfFlag) => {
        const baseColumns = [
            {
                name: "Employee Name",
                id: "empName",
                sortable: false,
                selector: form => form?.empName
            },
            {
                name: "Carrier",
                id: "carrierName",
                sortable: false,
                selector: form => form?.carrierName
            },
            {
                name: "Form Type",
                id: "formType",
                sortable: false,
                selector: form => form?.formType?.replace('Enrolment', 'Enrollment')
            },
            {
                name: "Generation Timestamp",
                id: "formGenerationTime",
                sortable: false,
                selector: form => form?.formGenerationTime ? moment(toESTTime(form?.formGenerationTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"
            },
            {
                name: "Generation Reason",
                id: "GenerationReason",
                sortable: false,
                selector: form =>
                    <button className="fs-13 link-style btn-like-link" style={{ color: "#2c87f0" }} onClick={(e) => {
                        e.stopPropagation(); e.preventDefault(); dialog.openDialog("Changed Fields:",
                            <div id="last-sent-file-modal">
                                {/* <h5 className="black1 fw-700">Changed Fields:</h5>
                                <Divider /> */}
                                <ChangedDetails dsxUrl={form?.trackedFile} dfElements={dfElements} />
                            </div>, []
                        )
                    }}>
                        Change Details
                    </button>
            },
            {
                name: "Transmitted Time",
                id: "transmittedTime",
                sortable: false,
                selector: form => form?.transmittedTime ? moment(toESTTime(form?.transmittedTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"
            },
            {
                name: "Transmitted To",
                id: "",
                sortable: false,
                selector: form =>
                    form?.transmittedTo?.length > 0 &&
                    <>
                        {form?.transmittedTo?.map((email, emailIndex) =>
                        (<div className="w-100" style={{ wordBreak: "break-word" }} key={emailIndex}>
                            <a href={`mailTo:${email}`}>
                                {email}
                            </a>
                        </div>)

                        )}

                    </>
            },
            {
                name: "Actions",
                id: "Actions",
                sortable: false,
                selector: form =>
                    <button className="d-flex my-1 btn" type='buttton' onClick={() => handleDownloadFile(form)}>
                        <svg width="20" height="20" viewBox="0 0 20 20" >
                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                        </svg>
                        <span className="ml-2 fs-12" style={{ color: "var(--dt-cell-gray)", textWrap: "nowrap" }}>Download</span>
                    </button>
            }
        ];

        return baseColumns
    }

    return (
        <>
            <div className="w-100 d-flex mt-1 py-2">
                <div className="" role="button" onClick={() => handleExpandFeed(feed?.FeedID)}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <p className="">{feed?.CarrierName ? feed?.CarrierName : 'Carrier Name'} {
                    feed?.FeedFormMainTypes?.length > 0 && <> - ({feed?.FeedFormMainTypes?.map((el, elIndex) => <>
                        {el} {elIndex !== feed?.FeedFormMainTypes?.length - 1 && ', '}
                    </>)})
                    </>
                }</p>
                <Button variant="contained" className="blue-outlined-btn ml-auto" hidden disabled={!feed} style={{ whiteSpace: "nowrap" }} onClick={getTestFileConfirmation}>
                    <label style={{ cursor: "pointer" }} className="mb-0" hidden>Generate Forms</label>
                </Button>
            </div>
            {expanded && <>
                {/* My Forms */}
                {selfForms &&
                    <>
                        <p className='mt-3'>My Forms</p>
                        <GenericTable
                            columns={[...baseColumnsArrayWithSelfFlag(true)]}
                            data={[...selfForms]}
                            onSort={() => { }}
                        />


                    </>}
                {/* All Forms */}
                {forms &&
                    <>
                        <p className='mt-3'>All Forms</p>
                        <GenericTable
                            columns={[...baseColumnsArrayWithSelfFlag(false)]}
                            data={[...forms]}
                            onSort={() => { }}
                        />

                        {forms?.length >= 0 &&
                            <div className="text-center mt-3">
                                <Button className="load-more-btn blue-btn" disabled={lastEvaluatedKey == null} onClick={() => getFormsPerFeed(feed?.FeedID, 'other', false, true)}>Load More Results</Button>
                            </div>

                        }
                    </>}
            </>}
        </>
    )
}

export default ClientFormsTransmissionsTabSingleFeedWithForms
