import React, { useEffect } from "react";
import { Paper, Switch, FormControlLabel, Zoom, FormControl, MenuItem, Select } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dateTimeSVG from "../../../Images/dateTime.svg";
import BackupIcon from '@material-ui/icons/Backup';
import Moment from 'moment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { DropzoneArea } from 'material-ui-dropzone';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CustomTooltip } from "../../Layout/Tooltip";
import { parseISO } from 'date-fns';
import { useLoader } from "../../Layout/Loader";
import * as APIs from "../../../Data/APIs";
import { FailureModal } from "../../Modals/FailureModal";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import deleteForeverSVG from "../../../Icons/trash-xmark.svg";
import infoCircleSVG from "../../../Icons/info-circle.svg";
import { Grid } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function CreateOERequestComponent({
    groupReq,
    connectivity,
    index,
    oERequest,
    oERequestBody,
    onSetOERequestBody,
    oERequests,
    onSetOERequests,
    requestSubmitted,
    onSetFormPlanYearStartDayValidation,
    onSetFormExpectedDayValidation,
    // onSetFormFileAttachedValidation,
    groupsAndOEContactsList,
    ...props }) {


    const { openLoader, closeLoader } = useLoader();
    const dialog = useModalDialog(0);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [planYearStartDay, setPlanYearStartDay] = React.useState(oERequest.PlanYearStartDate);
    const [expectedDay, setExpectedDay] = React.useState(oERequest.ClientDataExpectedDate);

    const [oERequestDescription, setOERequestDescription] = React.useState(oERequest.OEChanges);
    const [fileAttached, setFileAttached] = React.useState(oERequest.Attachment);
    const [fileAttachedBase64, setFileAttachedBase64] = React.useState();
    const fileInputRef = React.useRef();
    const [updatedGSChecked, setUpdatedGSChecked] = React.useState(oERequest.UpdatedGroupStructureChecked);
    const [isolvedDataChangesChecked, setIsolvedDataChangesChecked] = React.useState(oERequest.IsolvedDataChangesChecked);
    const [draftChecked, setDraftChecked] = React.useState(oERequest.DraftChecked);
    const [OEContact, setOEContact] = React.useState(oERequest.OEContact);

    // validation var 
    const [planYearStartDayValidation, setPlanYearStartDayValidation] = React.useState("");
    const [expectedDayValidation, setExpectedDayValidation] = React.useState("");
    // const [fileAttachedValidation, setFileAttachedValidation] = React.useState("");

    // validation func 
    // planYearStartDay validation
    React.useEffect(() => {
        if (oERequests?.filter((c) => c.PlanYearStartDate === null || c.PlanYearStartDate === "Invalid date").length > 0) {
            setPlanYearStartDayValidation("Please fill all plan year start day fields for all OE requests");
            onSetFormPlanYearStartDayValidation("Please fill all plan year start day fields for all OE requests");
        } else {
            setPlanYearStartDayValidation("");
            onSetFormPlanYearStartDayValidation("");
        };
    }, [planYearStartDay, planYearStartDayValidation, oERequests]);

    //  expectedDay validation
    React.useEffect(() => {
        if (oERequests?.filter((c) => c.ClientDataExpectedDate === null || c.ClientDataExpectedDate === "Invalid date").length > 0) {
            setExpectedDayValidation("Please fill all client's expected data ready date fields for all OE requests");
            onSetFormExpectedDayValidation("Please fill all client's expected data ready date fields for all OE requests");
        }
        else if (oERequests?.filter((c) => c.ClientDataExpectedDate >= c.PlanYearStartDate).length > 0) {
            setExpectedDayValidation("Client's expected data ready date should be smaller than plan year start date");
            onSetFormExpectedDayValidation("Client's expected data ready date should be smaller than plan year start date");
        }
        else {
            setExpectedDayValidation("");
            onSetFormExpectedDayValidation("");
        };
    }, [expectedDay, planYearStartDay, expectedDayValidation, oERequests]);

    // fileAttached validation
    // React.useEffect(() => {
    //     if (oERequests?.filter((c) => !c.Attachment && c.UpdatedGroupStructureChecked).length > 0) {
    //         setFileAttachedValidation("Please fill all group structure  fields for all OE requests");
    //         onSetFormFileAttachedValidation("Please fill all group structure  fields for all OE requests");
    //     } else {
    //         setFileAttachedValidation("");
    //         onSetFormFileAttachedValidation("");
    //     };
    // }, [fileAttached, fileAttachedValidation, updatedGSChecked, oERequests]);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar(false);
    };


    const handleFileAttachedChangeDrag = (files) => {
        if (files.length === 0) return;
        if (files[0]?.size === 0) {
            FailureModal(dialog, {
                title: 'Failure!',
                body: 'File is empty!'
            });
        }
        else {
            encodeFileAttachedBase64(files[0]);
            setFileAttached(files[0]);
            handelAddFileAttachment();
            setOpenSnackBar(true);
        }
    }

    const handelAddFileAttachment = (file, fileBase64) => {
        let newArr = [...oERequests];
        newArr[index].FileName = file?.name?.substring(0, file?.name.lastIndexOf('.')) ?? "";
        newArr[index].Extension = file?.name?.substring(file?.name.lastIndexOf('.') + 1, file?.name.length) ?? "";
        newArr[index].Value = fileBase64 ?? "";
        newArr[index].Attachment = file;
        onSetOERequests(newArr);
    }

    const encodeFileAttachedBase64 = (file) => {
        var reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
            reader.onload = () => {
                var Base64 = reader.result.split(',')[1];
                setFileAttachedBase64(Base64);
                handelAddFileAttachment(file, Base64);
            };
            reader.onerror = (error) => {
                console.log("error: ", error);
            };
        }

    };

    const handlePlanYearStartDateChange = (date) => {
        setPlanYearStartDay(date == null || date == "Invalid Date" ? null : Moment(date).format('YYYY-MM-DD'));
        let newArr = [...oERequests];
        if (!date || date == "Invalid Date") {
            newArr[index].PlanYearStartDate = date;
        } else {
            newArr[index].PlanYearStartDate = Moment(date).format('YYYY-MM-DD');
            newArr?.filter((c, ci) => !c.PlanYearStartDate && ci != index)
                .forEach((c) => c.PlanYearStartDate = Moment(date).format('YYYY-MM-DD'));
        }
        onSetOERequests(newArr);
    };

    const handleExpectedDateChange = (date) => {
        setExpectedDay(date == null || date == "Invalid Date" ? null : Moment(date).format('YYYY-MM-DD'));
        let newArr = [...oERequests];
        if (!date || date == "Invalid Date") {
            newArr[index].ClientDataExpectedDate = date;
        } else {
            newArr[index].ClientDataExpectedDate = Moment(date).format('YYYY-MM-DD');
            newArr?.filter((c, ci) => !c.ClientDataExpectedDate && ci != index)
                .forEach((c) => c.ClientDataExpectedDate = Moment(date).format('YYYY-MM-DD'));
        }
        onSetOERequests(newArr);
    };


    useEffect(() => {
        return () => {
            if (oERequests[index]?.PlanYearStartDate == "Invalid Date") {
                oERequests[index].PlanYearStartDate = null;
            }
            if (oERequests[index]?.ClientDataExpectedDate == "Invalid Date") {
                oERequests[index].ClientDataExpectedDate = null;
            }
        }
    }, [index])


    const handelUpdatedGroupStructure = () => {
        setUpdatedGSChecked(!updatedGSChecked);
        let newArr = [...oERequests];
        newArr[index].UpdatedGroupStructureChecked = !updatedGSChecked;
        onSetOERequests(newArr);
    }



    const handelIsolvedDataChangesChecked = () => {
        setIsolvedDataChangesChecked(!isolvedDataChangesChecked);
        let newArr = [...oERequests];
        newArr[index].IsolvedDataChangesChecked = !isolvedDataChangesChecked;
        onSetOERequests(newArr);
    }

    const handleDraftChecked = () => {
        setDraftChecked(!draftChecked);
        let newArr = [...oERequests];
        newArr[index].DraftChecked = !draftChecked;
        onSetOERequests(newArr);
    }

    return (
        <TabPanel value={index} index={index}>
            <div id="create-OE-Request-component" key={connectivity.id}>

                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {/* Plan Year Start Date */}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">Plan Year Start Date</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                            <CustomTooltip className="ml-1" TransitionComponent={Zoom} title={"The client’s renewal date with the carrier"}>
                                {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                <svg width="15" height="15" viewBox="0 0 25 24" >
                                    <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                </svg>
                            </CustomTooltip>
                        </div>
                        <div className="text-input w-100" style={{ padding: "0 20px" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    className="w-100"
                                    autoOk={true}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    name="PlanYearStartDate"
                                    id={`frequency-date-${index}`}
                                    value={oERequest.PlanYearStartDate === null ? oERequest.PlanYearStartDate : parseISO(oERequest.PlanYearStartDate)}
                                    onChange={handlePlanYearStartDateChange}
                                    keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                    </svg>}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{planYearStartDayValidation}</div>


                    </Grid>
                    {/* Expected Date  */}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">Client's Expected Data Ready Date</h6>
                            <span className="text-danger mb-2 ml-2">*</span>
                            <CustomTooltip className="ml-1" TransitionComponent={Zoom} title={"Client’s expected date of when OE updates/entries will be finalized to send the OE file"}>
                                <svg width="15" height="15" viewBox="0 0 25 24" >
                                    <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                </svg>
                            </CustomTooltip>
                        </div>
                        <div className="text-input w-100" style={{ padding: "0 20px" }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    className="w-100"
                                    autoOk={true}
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    name="ClientDataExpectedDate"
                                    id={`date-${index}`}
                                    value={oERequest.ClientDataExpectedDate === null ? oERequest.ClientDataExpectedDate : parseISO(oERequest.ClientDataExpectedDate)}
                                    onChange={handleExpectedDateChange}
                                    keyboardIcon={<svg width="14" height="16" viewBox="0 0 14 16">
                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                    </svg>}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>{expectedDayValidation}</div>
                    </Grid>
                    {/* isolved data changes */}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">isolved (Data) Changes</h6>
                            <CustomTooltip className="ml-1" TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please check it if there are changes in the data like plan names or coverage level codes, it does not necessary require an updated structure</div>}>
                                {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                <svg width="15" height="15" viewBox="0 0 25 24" >
                                    <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                </svg>
                            </CustomTooltip>
                        </div>

                        <FormControlLabel
                            className="dependents-switch"
                            control={
                                <Switch
                                    checked={oERequest.IsolvedDataChangesChecked}
                                    onChange={handelIsolvedDataChangesChecked}
                                />
                            }
                            label=""
                        />
                    </Grid>
                    {/* updated GS */}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">Updated Group Structure</h6>
                            <CustomTooltip className="ml-1" TransitionComponent={Zoom} title={"Please check it if there is a new group structure for the current open enrollment"}>
                                {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                <svg width="15" height="15" viewBox="0 0 25 24" >
                                    <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                </svg>
                            </CustomTooltip>
                        </div>
                        <FormControlLabel
                            className="dependents-switch"
                            control={
                                <Switch
                                    checked={oERequest.UpdatedGroupStructureChecked}
                                    onChange={handelUpdatedGroupStructure}
                                />
                            }
                            label=""
                        />
                    </Grid>

                    {oERequest.UpdatedGroupStructureChecked && (
                        <>
                            {/* Group Structure  */}
                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Group Structure</h6>
                                {fileAttached ? (<div className="pr-0 pl-0">
                                    <input ref={fileInputRef} hidden type="file" className="text-input w-100" style={{ paddingRight: "50px" }} value={oERequest.Attachment ? oERequest.Attachment?.File?.name : ""} />


                                    <div className="action-item-attachment-added textfield-div">
                                        <span>{oERequest.Attachment?.name}</span>
                                        <span>
                                            <svg width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); handelAddFileAttachment("", ""); }} style={{ cursor: "pointer" }}>
                                                <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                            </svg>
                                            {/* <DeleteForeverIcon className="ml-3 color grey2" style={{ cursor: "pointer" }} onClick={(e) => { setFileAttached(""); setFileAttachedBase64(""); handelAddFileAttachment("", ""); }} /> */}
                                        </span>
                                        <span className="ml-auto color blue-main-color"><BackupIcon /></span>
                                    </div>

                                    <Snackbar open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                                        <Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: '100%' }}>
                                            File {oERequest.Attachment?.name} successfully added.
                                        </Alert>
                                    </Snackbar>
                                </div>
                                ) : (
                                    <div className="mt-3 pr-0 pl-0">
                                        <DropzoneArea
                                            maxFileSize={20 * 1024 * 1024}
                                            // add max size 20mb         
                                            dropzoneParagraphClass={"MuiDropzoneArea-text-updated-gray"}
                                            filesLimit={1}
                                            dropzoneText={
                                                <div className="dz-message fs-14" data-dz-message style={{ display: "grid" }}>
                                                    <b>
                                                        Click here to add Group Structure
                                                    </b>
                                                </div>
                                            } dropzoneClass={"containerClassDrag"}
                                            onChange={(files) => handleFileAttachedChangeDrag(files)}
                                            id={`dropzoneArea-${index}`}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                        />
                                    </div>)}
                            </Grid>

                            {/* error Group Structure  */}
                            {/* <div className="col-xl-2 alignvh-center" hidden={!requestSubmitted}></div>
        <div className="col-xl-10 mt-2 text-danger" hidden={!requestSubmitted}>{fileAttachedValidation}</div> */}

                        </>)}


                    {/* Description */}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">Expected Changes (if any)</h6>
                            <CustomTooltip className="ml-1" TransitionComponent={Zoom} title={"Please list benefit changes that should be considered for the current open enrollment"}>
                                {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                <svg width="15" height="15" viewBox="0 0 25 24" >
                                    <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                </svg>
                            </CustomTooltip>
                        </div>
                        <textarea name="OEChanges" id={`textarea-${index}`} type="text" className="action-item-textarea w-100" value={oERequest.OEChanges} onChange={(e) => { setOERequestDescription(e.target.value); let newArr = [...oERequests]; newArr[index].OEChanges = e.target.value; onSetOERequests(newArr); }} rows={4} ></textarea>
                    </Grid>
                    {/* isolved Contact */}
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">isolved Contact</h6>
                            <CustomTooltip className="ml-1" TransitionComponent={Zoom} title={"Assigned OE Contact"}>
                                {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                <svg width="15" height="15" viewBox="0 0 25 24" >
                                    <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                </svg>
                            </CustomTooltip>
                        </div>
                        <FormControl label="Select OE contact" focused={false} variant="outlined" className="w-100">
                            <Select
                                className="text-dropdown"
                                value={oERequest.OEContact ?? ""}
                                onChange={(e) => { setOEContact(e.target.value); let newArr = [...oERequests]; newArr[index].OEContact = e.target.value; onSetOERequests(newArr); }}
                            >
                                <MenuItem value="">None</MenuItem>
                                {groupsAndOEContactsList && groupsAndOEContactsList?.filter(s => s.IsEnabled === 1)?.map((c, ci) => (
                                    <MenuItem key={`users-list-${ci}`} value={c.CognitoID}>{`${c.FirstName} ${c.LastName}`}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        {/* Draft OE */}
                        <div className="d-flex mt-3">
                            <h6 className="form-title"> Draft</h6>
                            <CustomTooltip className="ml-1" TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please check it to identify this is not a ready yet OE request</div>}>
                                {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                <svg width="15" height="15" viewBox="0 0 25 24" >
                                    <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                </svg>
                            </CustomTooltip>
                        </div>
                        <FormControlLabel
                            className="dependents-switch"
                            control={
                                <Switch
                                    checked={oERequest.DraftChecked}
                                    onChange={handleDraftChecked}
                                />
                            }
                            label=""
                        />

                    </Grid>



                    <Grid item xs={12} sm={12} md={6}>

                    </Grid>
                </Grid>



            </div>
        </TabPanel>
    )
}

export default CreateOERequestComponent;
