import React from "react";
import SelectSingleClientInformation from "./SelectSingleClientInformation";
import TabsTitlesWithMoreDD from "../../Layout/TabsTitlesWithMoreDD";



function SelectClientInformationInternal({
    groupReq,
    isPEOClicked,
    selectedOtherCarriers,
    parentForceUpdate,
    carriers,
    setSelectedCarriers,
    selectedCarriers,
    groupsAndOEContactsList,
    onSelectClientContactValidationForm,
    onSelectClientEffectiveDateValidationForm,
    requestSubmitted,
    requestSaved,
    ...props
}) {

    const [tabValue, setTabValue] = React.useState(0);
    const [tabDefaultValue, setTabDefaultValue] = React.useState("[Other Carrier Name]")

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // if selectedTab removed from selected Carriers list and it was last item
    const handleTabValue = () => {
        let otherCarrierInCasePEOLength = (selectedOtherCarriers?.selectedOtherCarriersFullArray?.length ?? 0)
        if (tabValue >= (selectedCarriers?.length + otherCarrierInCasePEOLength)) {
            let totalLength = (selectedCarriers.length + otherCarrierInCasePEOLength)
            if (totalLength == 0) {
                return (0)
            } else {
                return (selectedCarriers.length - 1 + otherCarrierInCasePEOLength)
            }
        } else {
            return (tabValue)
        }
    }


    React.useEffect(() => {
        setTabValue(handleTabValue())
    }, [selectedCarriers, tabValue, selectedOtherCarriers?.selectedOtherCarriersFullArray])

    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100">
                <div className="border-bottom">
                    <TabsTitlesWithMoreDD
                        tabTitle={[
                            ...(selectedCarriers?.map(sc => isPEOClicked ? sc?.CarrierName : sc?.carrierName) || []),
                            ...(selectedOtherCarriers?.selectedOtherCarriersFullArray?.map(s => s?.Name ?? tabDefaultValue) || [])
                        ]}
                        value={handleTabValue()}
                        setValue={setTabValue}
                    />
                </div>
            </div>
            {selectedCarriers?.map((s, si) => (
                si === tabValue && (
                    <SelectSingleClientInformation
                        carrier={s}
                        index={si}
                        groupsAndOEContactsList={groupsAndOEContactsList}
                        key={`single-carrier-plans-${si}`}
                        parentForceUpdate={parentForceUpdate}
                        groupReq={groupReq}
                        onSelectClientContactValidationForm={onSelectClientContactValidationForm}
                        onSelectClientEffectiveDateValidationForm={onSelectClientEffectiveDateValidationForm}
                        setSelectedCarriers={setSelectedCarriers}
                        selectedCarriers={selectedCarriers}
                        requestSubmitted={requestSubmitted}
                        requestSaved={requestSaved}
                    />
                )
            ))}
            {selectedOtherCarriers?.selectedOtherCarriersFullArray?.map((s, si) => (
                si + selectedCarriers.length === tabValue && (
                    <SelectSingleClientInformation
                        carrier={s}
                        index={si}
                        groupsAndOEContactsList={groupsAndOEContactsList}
                        key={`single-carrier-plans-${si}`}
                        parentForceUpdate={parentForceUpdate}
                        groupReq={groupReq}
                        onSelectClientContactValidationForm={onSelectClientContactValidationForm}
                        onSelectClientEffectiveDateValidationForm={onSelectClientEffectiveDateValidationForm}
                        setSelectedCarriers={setSelectedCarriers}
                        selectedCarriers={selectedCarriers}
                        requestSubmitted={requestSubmitted}
                        requestSaved={requestSaved}
                    />
                )
            ))}
        </div >
    )
}

export default SelectClientInformationInternal;
