import React from "react";
import { Divider, Button, Select } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { useLoader } from "../../../Layout/Loader";
import * as APIs from "../../../../Data/APIs";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useAuthentication } from "../../../../Data/Authentication";
import deleteForeverSVG from "../../../../Icons/trash-xmark.svg";
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";

function CarrierPlansAnalyst({ currentTimestamp, carrierPlansSection, setIsNextActive, ...props }) {

    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const { authUser } = useAuthentication();

    const [connectivityReq, setConnectivityReq] = React.useState();
    const [customerPlans, setCustomerPlans] = React.useState([]);
    const [planTypes, setPlanTypes] = React.useState([]);
    const [plansArray, setPlansArray] = React.useState(carrierPlansSection.Model ?? []);


    React.useEffect(() => {
        if (carrierPlansSection) carrierPlansSection.Model = plansArray;
    }, [plansArray]);


    const [, forceUpdate] = React.useReducer(x => !x, false);


    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => setConnectivityReq(r?.data));
    }, [])

    React.useEffect(() => {
        if (connectivityReq) closeLoader();
        else openLoader();
    }, [connectivityReq])

    React.useEffect(() => {
        openLoader();
        APIs.getPlanTypes().then((r) => setPlanTypes(r?.data?.sort((a, b) => a.PlanTypeName.toLowerCase() > b.PlanTypeName.toLowerCase() ? 1 : -1)));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getGroupPlans(connectivityReq?.ConnectivityRequestID).then((r) => (
            setCustomerPlans(r?.data),
            closeLoader()
        ));
    }, [connectivityReq])

    React.useEffect(() => {
        const carrierPlanNamesCondition = plansArray.map(s => s.CarrierPlanName).filter(f => f === "");
        const planTypesCondition = plansArray.map(s => s.PlanType).filter(f => f === "");
        const commentsCondition = plansArray.map(s => s.Comment.Text).filter(f => f === "");
        if (plansArray.length > 0 && carrierPlanNamesCondition.length <= 0 && planTypesCondition.length <= 0 && commentsCondition.length <= 0) setIsNextActive(true); else setIsNextActive(false);
    }, [JSON.stringify(plansArray)])

    return (
        <div id="carrier-plans-section">
            <div className="row table-header">
                <div className="col-8 table-cell first-cell">
                    Group Plan Names
                </div>
                <div className="col-4 table-cell">
                    Group Plan Types
                </div>
            </div>
            {customerPlans.map((c, ci) => (
                <div className="table-row row" key={`customer-plans-${ci}`}>
                    <div className="table-cell col-8 first-cell">
                        {c?.BenefitName}
                    </div>
                    <div className="table-cell col-4" style={{ borderLeft: "0" }}>
                        {c?.BenefitType}
                    </div>
                </div>
            ))}

            <div className="row mt-5 w-100 alignvh-center">
                <h5 className="black1 fw-700">Carrier Plans Section</h5>
                <div className="ml-auto">
                    <Button variant="contained" className="blue-btn" onClick={() => {
                        setPlansArray([...plansArray, {
                            GUID: uuidv4(),
                            CarrierPlanName: "",
                            PlanType: "",
                            PlanTypeID: "",
                            Action: 1,
                            Comment: {
                                Timestamp: currentTimestamp,
                                Text: "",
                                UserID: authUser?.attributes["sub"],
                                Name: `${authUser?.attributes["custom:FirstName"]} ${authUser?.attributes["custom:LastName"]}`,
                                Email: `${authUser?.attributes["email"]}`
                            }
                        }]);
                    }
                    }>
                        <label style={{ cursor: "pointer" }} className="mb-0">
                        <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                                                <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                                            </svg>
                             Add Plans</label>
                    </Button>
                </div>
            </div>
            <Divider className="mt-3 mb-5" />
            {plansArray.length <= 0 && (
                <div className="no-data-alert">
                    <h6>Please start adding carrier plans</h6>
                </div>
            )}
            {plansArray.map((p, pi) => (
                <div className="row mb-2" key={`Plan-${p.GUID}`}>
                    <div className="col-xl-3 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Carrier Plan Name</h6>
                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                        <input type="text" className="text-input w-100" value={p.CarrierPlanName ?? ""} onChange={e => { p.CarrierPlanName = e.target.value; forceUpdate(); }} />
                    </div>
                    <div className="col-xl-3 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Plan Type</h6>
                        <span className="text-danger mb-2 ml-2 mr-2">*</span>
                        <FormControl label="Select Plan Type" focused={false} variant="outlined" className="w-100">
                            <Select
                                className="text-dropdown"
                                value={p.PlanType ?? ""}
                                onChange={e => { p.PlanType = e.target.value; p.PlanTypeID = planTypes.filter(s => (s.PlanTypeName === e.target.value))[0].PlanTypeID; forceUpdate(); }}
                            >
                                {planTypes.map((p, pi) => (
                                    <MenuItem key={`plan-types-${pi}`} value={p.PlanTypeName}>{p.PlanTypeName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-xl-4 mr-4 alignvh-center" style={{ whiteSpace: "nowrap" }}>
                        <h6 className="form-title">Analyst Comment</h6>
                        <span className="text-danger mb-2 ml-2">*</span>
                        <textarea value={p.Comment?.Text ?? ""} onChange={e => { p.Comment.Text = e.target.value; forceUpdate(); }} style={{ minWidth: "unset", width: "100%" }} rows={3} />
                    </div>
                    <div className="col-xl-1 ml-2 alignvh-center">
                        <CustomTooltip TransitionComponent={Zoom} title={plansArray.length === 1 ? "There should be at least one matched plan" : ""}>
                            <span>
                                <Button className="icon-btn" disabled={plansArray.length === 1} onClick={e => { plansArray.splice(pi, 1); forceUpdate(); }}>
                                    {/* <DeleteForeverIcon style={{ width: "30px", height: "30px" }} color="action" /> */}
                                    <svg  width="21" height="24" viewBox="0 0 21 24"  style={{ cursor: "pointer" }}>
                                        <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                                    </svg>
                                </Button>
                            </span>
                        </CustomTooltip>
                    </div>
                </div>
            ))
            }
        </div >
    )
}

export default CarrierPlansAnalyst;