
import MomentTZ from 'moment-timezone';
import { ConfirmModal } from './Components/Modals/ConfirmModal';
import { SuccessModal } from './Components/Modals/SuccessModal';
import { FailureModal } from './Components/Modals/FailureModal';

export function toESTTime(timeString, format = undefined) {
    const localTime = MomentTZ.utc(timeString).toDate();
    return MomentTZ(localTime).tz("America/New_York").format(format ?? "YYYY-MM-DD HH:mm:ss")
}


export function openModal(dialog, modalType, handleFunction, content) {
    switch (modalType) {
        case 0:
            return ConfirmModal(dialog, handleFunction, content);
        case 1:
            return SuccessModal(dialog, content);
        case 2:
            return FailureModal(dialog, content);
        default:
            break;
    }
}