import React from "react";
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { CustomTooltip } from "./Tooltip";
import ErrorIcon from '@mui/icons-material/Error';
import { Zoom } from "@material-ui/core";

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #E6E8F1',
        marginTop: "1em",
    },
    indicator: {
        backgroundColor: "var(--pink-primary-color)",
        height: '3px',
    },
})(Tabs);

const AntTab = withStyles((theme) => ({
    root: {
        minWidth: 72,
        fontWeight: '350 !important',
        fontSize: '14px',
        position: 'relative',
        marginRight: theme.spacing(4),
        color: 'var(--var(--grey3-secondary-color))',
        '&:hover': {
            color: '#42505C',
            opacity: 1,
        },
    },
    selected: {
        color: "var(--black-dark-color)",
        fontWeight: '500 !important',
    },
}))((props) => <Tab disableRipple {...props} />);



function TabsTitles({ tabTitle, ...props }) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AntTabs value={value} onChange={handleChange}>
                {tabTitle.map((t, ti) => (
                    <AntTab key={ti} label={t} />
                ))}
            </AntTabs>
        </div>
    )
}



const AntTabnew = withStyles((theme) => ({
    root: {
        minWidth: 72,
        fontWeight: '350 !important',
        fontSize: '14px',
        position: 'relative',
        marginRight: theme.spacing(4),
        color: 'var(--grey3-secondary-color)',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            color: '#42505C',
            opacity: 1,
        },
    },
    selected: {
        color: "var(--black-dark-color)",
        fontWeight: '500 !important',
    },
}))(({ tooltip, label, ...props }) => (
    <Tab disableRipple {...props}
        label={
            <>
                {label}
                {tooltip && (
                    <CustomTooltip TransitionComponent={Zoom} title={tooltip}>
                        <span style={{ position: "absolute", color: "red", top: -4, right: 0 }}>
                            <ErrorIcon className="fs-18" />
                        </span>
                    </CustomTooltip>
                )}
            </>
        }
    />
));
export function TabsTitlesWithIcons({ tabTitle, value, setValue, ...props }) {


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <AntTabs value={value} onChange={handleChange}>
                {tabTitle.map((t, ti) => (
                    <AntTabnew key={ti} tooltip={t?.tooltip} label={t?.label} />
                ))}
            </AntTabs>
        </div>
    )
}
export default TabsTitles;