import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SelectSingleCarrierPlan({
    carrier,
    index,
    planTypesList,
    parentForceUpdate,
    ...props
}) {


    const handleOtherPlanTypesChange = (s) => (e) => {
        s.OtherPlanTypes = (e.target.value);
        parentForceUpdate();
    }

    const handlePlanClick = (value, f) => (event) => {
        let selectedPlans = f.SelectedPlans ?? [];
        if (!value || value === "") selectedPlans = [];
        else if (selectedPlans.indexOf(value) >= 0) {
            selectedPlans.splice(selectedPlans.indexOf(value), 1);
            if (value === `Other`) {
                f.OtherChecked = false;
                f.OtherPlanTypes = "";
                if (selectedPlans.length === planTypesList.length && !selectedPlans.includes("Other"))
                    f.SelectAllPlansChecked = true; else f.SelectAllPlansChecked = false;
            }
        }
        else {
            selectedPlans.push(value);
            if (value === `Other`) {
                f.OtherChecked = true;
                f.OtherPlanTypes = "";
                if (selectedPlans.length === planTypesList.length && !selectedPlans.includes("Other")) {
                    f.SelectAllPlansChecked = true;
                    f.OtherChecked = false;
                    f.OtherPlanTypes = "";
                } else f.SelectAllPlansChecked = false;
            }
        }
        f.SelectedPlans = ([...selectedPlans]);
        parentForceUpdate();
    }

    const handleSelectAllPlanClick = (value, f) => (event) => {
        let selectedPlans = f.SelectedPlans ?? [];
        if (!value || value === "") selectedPlans = [];
        else if (selectedPlans?.sort().join(',') === value.sort().join(',')) {
            selectedPlans = [];
            f.SelectAllPlansChecked = false;
            if (selectedPlans.any(p => p === "Other"))
                f.OtherChecked = true; else {
                f.OtherChecked = false;
                selectedPlans.splice(selectedPlans.indexOf("Other"), 1);
                f.OtherPlanTypes = "";
            }
        }
        else {
            selectedPlans?.push(...value);
            selectedPlans = Array.from(new Set(selectedPlans));
            f.SelectAllPlansChecked = true;
            if (selectedPlans.any(p => p === "Other")) {
                f.OtherChecked = false;
                selectedPlans.splice(selectedPlans.indexOf("Other"), 1);
                f.OtherPlanTypes = "";
            }
        }
        f.SelectedPlans = ([...selectedPlans]);
        parentForceUpdate();
    }

    return (
        <TabPanel value={index} index={index}  >
            <div className="row plans-list-div p-3">
                <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                        <Checkbox
                            id={`plans-option-select-all`}
                            value={planTypesList}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checked={carrier.SelectedPlans && carrier.SelectedPlans.length === planTypesList.length && !carrier.SelectedPlans.includes("Other") ? true : false}
                            onClick={handleSelectAllPlanClick(planTypesList.map(s => s.PlanTypeID), carrier)}
                            checkedIcon={<DoneOutlineIcon />}
                            color="default"
                        />
                    }
                    label="Select All"
                />
                <br />
                {planTypesList.map((p, pi) => (
                    <React.Fragment key={`plan-type-${pi}`}>
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={
                                <Checkbox
                                    id={`plan-option-${pi}`}
                                    value={p}
                                    checked={carrier.SelectedPlans && carrier.SelectedPlans.indexOf(p.PlanTypeID) >= 0 ? true : false}
                                    onClick={handlePlanClick(p.PlanTypeID, carrier)}
                                    icon={<CheckBoxOutlineBlankIcon />}
                                    checkedIcon={<DoneOutlineIcon />}
                                />}
                            label={p.PlanTypeName}
                        />
                        <br />
                    </React.Fragment>
                ))}
                <FormControlLabel
                    aria-label="Acknowledge"
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                        <Checkbox
                            id={`plan-option-other`}
                            value="Other"
                            checked={carrier.SelectedPlans && carrier.SelectedPlans.indexOf("Other") >= 0 ? true : false}
                            onClick={handlePlanClick("Other", carrier)}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<DoneOutlineIcon />}
                        />}
                    label="Other"
                />
                <br />
                {carrier.OtherChecked && (
                    <textarea
                        placeholder="Please enter plan types separated by new lines."
                        className="pt-2 pb-2 text-input w-100"
                        value={carrier.OtherPlanTypes ?? []}
                        rows={3}
                        onChange={handleOtherPlanTypesChange(carrier)}
                    />
                )}
            </div>
        </TabPanel>
    )
}

export default SelectSingleCarrierPlan;