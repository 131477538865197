import React from "react";
import * as APIs from "../../../Data/APIs";
import { useHistory } from "react-router-dom";
import MultipleTabsTitles from "../../Layout/MultipleTabsTitles";
import { Paper, Zoom } from "@material-ui/core";
import Moment from 'moment';
import { useLoader } from "../../Layout/Loader";
import { useAuthentication } from "../../../Data/Authentication";
import TransmissionDashboardProductionFilter from "./TransmissionDashboardProductionFilter";
import Pagination from "../../Layout/Pagination";
import { CustomTooltip } from "../../Layout/Tooltip";
import TransmissionsStatistics from "../TransmissionsStatistics/TransmissionsStatistics";
import downloadIconSVG from "../../../Icons/downloadIcon.svg";
import GenericTable from "../../Layout/GenericTable";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

const yearDate = new Date();
yearDate.setHours(0, 0, 0, 0)
yearDate.setFullYear(todayDate.getFullYear() - 1);


function TransmissionDashboardProduction() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);
    const tableRef = React.useRef();
    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const transmissionsTabs = ["Transmission Dashboard", "Production Dashboard"];
    const [tabValue, setTabValue] = React.useState(1);
    const [productionTransmissionsList, setProductionTransmissionsList] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [statusesList, setStatusesList] = React.useState([]);
    const [statusTabValue, setStatusTabValue] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [fromDateFilter, setFromDateFilter] = React.useState(null);
    const [toDateFilter, setToDateFilter] = React.useState(null);
    const [feedIDFilter, setFeedIDFilter] = React.useState("");
    const [carrierIDFilter, setCarrierIDFilter] = React.useState("");
    const [feedTypeFilter, setFeedTypeFilter] = React.useState("");
    const [feedPhaseFilter, setFeedPhaseFilter] = React.useState("");
    const [executionStatusFilter, setExecutionStatusFilter] = React.useState("");
    const [executionIDFilter, setExecutionIDFilter] = React.useState("");
    const [processingStepFilter, setProcessingStepFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("DESC");
    const [refreshClicked, setRefreshClicked] = React.useState(false);

    //Advanced Filters
    const [feedIDAdvFilter, setFeedIDAdvFilter] = React.useState("");
    const [selectedFeedNameAdvFilter, setSelectedFeedNameAdvFilter] = React.useState([]);
    const [selectedCarrierIDAdvFilter, setSelectedCarrierIDAdvFilter] = React.useState([]);
    const [selectedFeedTypeAdvFilter, setSelectedFeedTypeAdvFilter] = React.useState([]);
    const [executionIDAdvFilter, setExecutionIDAdvFilter] = React.useState("");
    const [selectedExecutionStatusAdvFilter, setSelectedExecutionStatusAdvFilter] = React.useState([]);
    const [selectedProcessingStepAdvFilter, setSelectedProcessingStepAdvFilter] = React.useState([]);
    const [selectedFeedPhaseAdvFilter, setSelectedFeedPhaseAdvFilter] = React.useState([]);
    const [requestFromDateAdvFilter, setRequestFromDateAdvFilter] = React.useState(null);
    const [requestToDateAdvFilter, setRequestToDateAdvFilter] = React.useState(null);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("DESC");

    const [filterCard, setFilterCard] = React.useState(false);
    const [onTransmissionStatusDashboardChange, setOnTransmissionStatusDashboardChange] = React.useState(false);
    const [onTransmissionPhaseDashboardChange, setOnTransmissionPhaseDashboardChange] = React.useState(false);


    React.useEffect(() => {
        APIs.getProductionDashboardStatusesList().then((r) => setStatusesList(r?.data));
    }, [])

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
        if (newValue === 1)
            routeHistory.push(`/EDITransmissions/Dashboard/Production`);
        else routeHistory.push(`/EDITransmissions/Dashboard`);
    };

    const getDataQuickFilters = () => {
        openLoader();
        // if (fromDateFilter === null || todayDate === null) return;
        // else {
        const feedsTransmissions = {};
        feedsTransmissions.feedPhaseID = ["7"]; //production only
        // feedsTransmissions.type = `${statusTabValue + 1}`;
        if (selectedPage >= 1) {
            feedsTransmissions.page = selectedPage;
            feedsTransmissions.limit = pageSize;
        }
        if (pageSize >= 10) {
            feedsTransmissions.limit = pageSize;
            feedsTransmissions.page = selectedPage;
        }
        // if (fromDateFilter)
        //     feedsTransmissions.fromDate = Moment(fromDateFilter).format("YYYY-MM-DD");
        // if (toDateFilter)
        //     feedsTransmissions.toDate = Moment(toDateFilter).format("YYYY-MM-DD");
        if (feedIDFilter !== "")
            feedsTransmissions.feedID = [`${feedIDFilter}`];
        if (carrierIDFilter !== "")
            feedsTransmissions.carrierID = [`${carrierIDFilter}`];
        if (executionStatusFilter !== "")
            feedsTransmissions.transmissionStatusID = executionStatusFilter;
        // if (feedPhaseFilter !== "")
        //     feedsTransmissions.feedPhaseID = [`${feedPhaseFilter}`]; //convert it to support only production
        // if (processingStepFilter !== "")
        //     feedsTransmissions.processingStep = [`${processingStepFilter}`];
        // if (feedTypeFilter !== "")
        //     feedsTransmissions.feedTypeID = [`${feedTypeFilter}`];
        // if (executionIDFilter !== "")
        //     feedsTransmissions.executionID = executionIDFilter;
        if (sortFieldFilter !== "")
            feedsTransmissions.sortType = sortFieldFilter;
        if (sortOrderFilter !== "")
            feedsTransmissions.sortOrder = sortOrderFilter;

        APIs.getProductionDashboardList(feedsTransmissions).then((r) => {
            setProductionTransmissionsList(r?.data);
            setRefreshClicked(false);
            setTotalCount(r?.totalCount ?? 0);
            setFilterCard(false);
            closeLoader();
        });
        // }
    };

    const getDataAdvancedFilters = () => {
        openLoader();
        if (requestFromDateAdvFilter === null || requestToDateAdvFilter === null) return;
        const feedsTransmissions = {};
        feedsTransmissions.type = `${statusTabValue + 1}`;
        if (selectedPageAdv >= 1) {
            feedsTransmissions.page = selectedPageAdv;
            feedsTransmissions.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            feedsTransmissions.limit = pageSizeAdv;
            feedsTransmissions.page = selectedPageAdv;
        }
        if (requestFromDateAdvFilter)
            feedsTransmissions.fromDate = Moment(requestFromDateAdvFilter).format("YYYY-MM-DD");
        if (requestToDateAdvFilter)
            feedsTransmissions.toDate = Moment(requestToDateAdvFilter).format("YYYY-MM-DD");
        if (feedIDAdvFilter !== "")
            feedsTransmissions.feedID = [`${feedIDAdvFilter}`];
        if (selectedFeedNameAdvFilter.length > 0)
            feedsTransmissions.feedID = selectedFeedNameAdvFilter?.map(s => `${s?.FeedID}`);
        if (selectedCarrierIDAdvFilter.length > 0)
            feedsTransmissions.carrierID = selectedCarrierIDAdvFilter?.map(s => `${s?.CarrierID}`);
        if (selectedExecutionStatusAdvFilter.length > 0)
            feedsTransmissions.executionStatus = selectedExecutionStatusAdvFilter?.map(s => `${s?.Id}`);
        if (selectedProcessingStepAdvFilter.length > 0)
            feedsTransmissions.processingStep = selectedProcessingStepAdvFilter?.map(s => `${s?.Id}`);
        if (selectedFeedTypeAdvFilter.length > 0)
            feedsTransmissions.feedTypeID = selectedFeedTypeAdvFilter?.map(s => `${s?.FeedTypeID}`);
        if (selectedFeedPhaseAdvFilter.length > 0)
            feedsTransmissions.feedPhaseID = selectedFeedPhaseAdvFilter?.map(s => `${s?.FeedPhaseID}`);
        if (executionIDAdvFilter !== "")
            feedsTransmissions.executionID = executionIDAdvFilter;
        if (sortFieldAdvFilter !== "")
            feedsTransmissions.sortType = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            feedsTransmissions.sortOrder = sortOrderAdvFilter;

        APIs.getProductionDashboardList(feedsTransmissions).then((r) => {
            setProductionTransmissionsList(r?.data);
            setRefreshClicked(false);
            setTotalCount(r?.totalCount ?? 0);
            setAdvancedFiltersAppliedClicked(false);
            closeLoader();
        });
    };

    React.useEffect(() => {
        if (quickFiltersClicked && !filterCard) getDataQuickFilters();
    }, [filterCard, quickFiltersClicked === true, statusTabValue, selectedPage, pageSize, fromDateFilter, toDateFilter, feedIDFilter, feedPhaseFilter, carrierIDFilter, executionStatusFilter, feedTypeFilter, executionIDFilter, processingStepFilter, sortFieldFilter, sortOrderFilter, refreshClicked === true])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])



    React.useEffect(() => {
        if (advancedFiltersClicked === true) getDataAdvancedFilters();
    }, [advancedFiltersClicked === true])

    // ==============================================
    const handleSort = (column, sortDirection, type) => {
        if (type === "quick") {
            setSortFieldFilter(column?.sortingkey);
            setSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            setSortFieldAdvFilter(column?.sortingkey);
            setSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };
    const baseColumnsArray = [
        {
            name: 'Feed Name',
            sortable: true,
            sortingkey: 'FeedName',
            selector: feedTransmission => (
                <a
                    style={{ wordBreak: "break-word", margin: "auto" }}
                    href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`}
                    onClick={(e) => { e.stopPropagation(); }}
                >
                    {feedTransmission?.FeedName}
                </a>
            ),
        },
        {
            name: 'Carrier Name',
            id: 'CarrierName',
            sortable: true,
            sortingkey: 'CarrierName',
            selector: feedTransmission => feedTransmission.CarrierName,
        },
        {
            name: 'Feed Type',
            id: 'FeedType',
            sortable: true,
            sortingkey: 'FeedType',
            selector: feedTransmission => feedTransmission.FeedType,
        },
        {
            name: 'Feed Phase',
            id: 'FeedPhaseName',
            width: '100px',

            sortable: true,
            sortingkey: 'FeedPhaseName',
            selector: feedTransmission => feedTransmission.FeedPhaseName,
        },
        {
            name: 'Last Sent File Date',
            id: 'SentDate',
            sortable: false,
            width: '120px',
            selector: feedTransmission => (
                feedTransmission?.SentDate === "1970-01-01 00:00:00" || feedTransmission?.SentDate === null
                    ? "N/A"
                    : Moment(feedTransmission?.SentDate).format("MM/DD/YYYY HH:mm:ss")
            ),
        },
        {
            name: 'Next Run',
            id: 'NextRun',
            sortable: true,
            width: '120px',
            sortingkey: 'NextRun',
            selector: feedTransmission => Moment(feedTransmission?.NextRun).format("MM/DD/YYYY HH:mm:ss"),
        },
        {
            name: 'Transmission Status',
            id: 'TransmissionStatusName',
            sortable: true,
            sortingkey: 'TransmissionStatusName',
            doesNotHaveToolTip: true,
            selector: feedTransmission => (
                <CustomTooltip
                    TransitionComponent={Zoom}
                    title={(feedTransmission?.TransmissionStatusName)?.includes("Progress") ? "The feed is still processing" :
                        (feedTransmission?.TransmissionStatusName)?.includes("Completed") ? "Current processing step is completed" :
                            (feedTransmission?.TransmissionStatusName)?.includes("Parsing") ? "Failed to convert the data file to the required format" :
                                (feedTransmission?.TransmissionStatusName)?.includes("Tracking") ? "Failed to process the data" :
                                    (feedTransmission?.TransmissionStatusName)?.includes("Mapping") ? "There is an error in the output file" :
                                        (feedTransmission?.TransmissionStatusName)?.includes("Serializing") ? "Unable to successfully generate a file" :
                                            (feedTransmission?.TransmissionStatusName)?.includes("Encrypting") ? "Failed to encrypt the output file" :
                                                (feedTransmission?.TransmissionStatusName)?.includes("Failed Transmitting") ? "Failed to submit the file" :
                                                    (feedTransmission?.TransmissionStatusName)?.includes("Pending Transmission") ? "File needs to be manually submitted" :
                                                        (feedTransmission?.TransmissionStatusName)?.includes("Pending") ? "The feed didn't start processing yet" :
                                                            (feedTransmission?.LastRun !== null && (feedTransmission?.TransmissionStatusName === null)) ? "Failed to extract a data file" :
                                                                (feedTransmission?.TransmissionStatusName)?.includes("Finished") ? "There are no changes on the output file" :
                                                                    ""}
                >
                    <span>
                        <div
                            style={(feedTransmission?.TransmissionStatusName)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                            className={`${((feedTransmission?.TransmissionStatusName)?.includes("Fail") ||
                                (feedTransmission?.LastRun !== null && (feedTransmission?.TransmissionStatusName === null))) ? "red background status-div" :
                                (feedTransmission?.TransmissionStatusName)?.includes("Pending Transmission") ? "yellow-secondary-color background status-div" :
                                    (feedTransmission?.TransmissionStatusName)?.includes("Completed") ? "green background status-div" :
                                        (feedTransmission?.TransmissionStatusName === "Pending" || (feedTransmission?.LastRun === null && feedTransmission?.TransmissionStatusName === null)) ? "purple1 background status-div" :
                                            (feedTransmission?.TransmissionStatusName)?.includes("Finished") ? "lightgreen background status-div" :
                                                ""}`}
                        >
                            {(feedTransmission?.TransmissionStatusName === "Pending" || (feedTransmission?.LastRun === null && feedTransmission?.TransmissionStatusName === null)) ? "Pending" :
                                (feedTransmission?.LastRun !== null && (!((feedTransmission?.TransmissionStatusName)?.includes("Pending Transmission")) && (feedTransmission?.logid_today === null || feedTransmission?.TransmissionStatusName === null))) ? "File Pulling Failed" : feedTransmission?.TransmissionStatusName}
                        </div>
                    </span>
                </CustomTooltip>
            ),
        },
        {
            name: 'Scheduler Status',
            id: 'HasEnabledSchedules',
            sortable: true,
            sortingkey: 'HasEnabledSchedules',
            selector: feedTransmission => feedTransmission.HasEnabledSchedules === false ? "Disabled" : "Enabled",
        },
        {
            name: 'Scheduler',
            id: 'Schedules',
            sortable: true,
            sortingkey: 'Schedules',
            width: '250px',
            selector: feedTransmission => <div className="py-1">{feedTransmission.Schedules}</div>,
        },
        {
            name: 'Actions',
            width: '80px',
            sortable: false,
            doesNotHaveToolTip: true,
            selector: feedTransmission => (
                <CustomTooltip
                    TransitionComponent={Zoom}
                    title={feedTransmission?.TransmissionStatusName === "Failed Parsing" || feedTransmission?.TransmissionStatusName === "Failed Tracking" || (feedTransmission?.LastRun !== null && (!((feedTransmission?.TransmissionStatusName)?.includes("Pending Transmission")) && (feedTransmission?.logid_today === null || feedTransmission?.TransmissionStatusName === null))) ? `No readable file available` :
                        (feedTransmission?.TransmissionStatusName)?.includes("Failed Mapping") ? `No readable file available for failed mapping transmission` :
                            (feedTransmission?.TransmissionStatusName)?.includes("Finished") ? "No readable file available for empty file" :
                                `Download Readable File`}
                >
                    <button
                        className="btn-like-link"
                        style={{
                            cursor: feedTransmission?.TransmissionStatusName === "Failed Parsing" ||
                                feedTransmission?.TransmissionStatusName === "Failed Tracking" ||
                                (feedTransmission?.TransmissionStatusName)?.includes("Failed Mapping") ||
                                (feedTransmission?.TransmissionStatusName)?.includes("Finished") ||
                                (feedTransmission?.LastRun !== null && (!((feedTransmission?.TransmissionStatusName)?.includes("Pending Transmission")) && (feedTransmission?.logid_today === null || feedTransmission?.TransmissionStatusName === null))) ? "default" :
                                "pointer", textDecoration: feedTransmission?.TransmissionStatusName === "Failed Parsing" || feedTransmission?.TransmissionStatusName === "Failed Tracking" ? "none" : ""
                        }}
                        onClick={() => {
                            if (feedTransmission?.TransmissionStatusName === "Failed Parsing" ||
                                feedTransmission?.TransmissionStatusName === "Failed Tracking" ||
                                (feedTransmission?.TransmissionStatusName)?.includes("Failed Mapping") ||
                                (feedTransmission?.TransmissionStatusName)?.includes("Finished") ||
                                (feedTransmission?.LastRun !== null && (!((feedTransmission?.TransmissionStatusName)?.includes("Pending Transmission")) && (feedTransmission?.logid_today === null || feedTransmission?.TransmissionStatusName === null)))
                            )
                                return;
                            else APIs.getDownloadReadableExcel(feedTransmission?.ExecutionID).then(r => window.open(r?.url));
                        }}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20">
                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                        </svg>
                    </button>
                </CustomTooltip>
            ),
        },
    ];

    const pushToColumnsBasedOnUserAction = () => {
        let checkif43or20 = (userActions?.includes("43") || userActions?.includes("20"))

        let itemsToInsert = [
            {
                index: 0,
                value: {
                    name: 'Feed ID',
                    id: 'FeedID',
                    sortable: true,
                    sortingkey: 'FeedID',
                    width: '80px',
                    selector: feedTransmission => (
                        <a
                            style={{ margin: "auto" }}
                            href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`}
                            onClick={(e) => { e.stopPropagation(); }}
                        >
                            {feedTransmission?.FeedID}
                        </a>
                    ),
                },
                condition: checkif43or20
            }
        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumnsArray];


        itemsToInsert.forEach(item => {
            if (item?.condition)
                finalColumnsArray.splice(item.index, 0, item.value);
        });


        return finalColumnsArray;
    };

    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(productionTransmissionsList)) {
            return [];
        }

        return productionTransmissionsList
    }



    return (
        <div id="transmission-dashboard-table">
            <div className="d-flex">
                <MultipleTabsTitles tabTitle={transmissionsTabs} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />
            </div>
            {/* <div>
                <MultipleTabsTitles tabTitle={statusesList?.map(s => s.Name)} value={statusTabValue} setValue={setStatusTabValue} onChange={handleStatusTabChange} />
            </div> */}
            <TransmissionsStatistics
                title="Production Transmissions Statistics"
                isProductionDashboard={true}
                tableRef={tableRef}
                onTransmissionStatusFilter={(d) => setExecutionStatusFilter(d)}
                onTransmissionPhaseFilter={(d) => setFeedPhaseFilter(d)}
                onTransmissionStatusDashboardChange={(d) => setOnTransmissionStatusDashboardChange(d)}
                onTransmissionPhaseDashboardChange={(d) => setOnTransmissionPhaseDashboardChange(d)}
                onSetFilterCard={(s) => setFilterCard(s)}
                refreshClicked={refreshClicked}
            />

            <Paper className="content-card-paper">
                <TransmissionDashboardProductionFilter
                    onFeedIDFilter={(d) => setFeedIDFilter(d)}
                    onCarrierIDFilter={(d) => setCarrierIDFilter(d)}
                    executionStatusFilter={executionStatusFilter}
                    onExecutionStatusFilter={(d) => setExecutionStatusFilter(d)}
                    onProcessingStepFilter={(d) => setProcessingStepFilter(d)}
                    onFeedTypeFilter={(d) => setFeedTypeFilter(d)}
                    onExecutionIDFilter={(d) => setExecutionIDFilter(d)}
                    feedPhaseFilter={feedPhaseFilter}
                    onFeedPhaseFilter={(d) => setFeedPhaseFilter(d)}
                    onFromDateFilter={(d) => setFromDateFilter(d)}
                    fromDateFilter={fromDateFilter}
                    onToDateFilter={(d) => setToDateFilter(d)}
                    toDateFilter={toDateFilter}
                    setRefreshClicked={setRefreshClicked}
                    selectedPage={selectedPage}
                    onSelectedPage={(p) => setSelectedPage(p)}
                    pageSize={pageSize}
                    onPageSize={(p) => setPageSize(p)}
                    totalCount={totalCount}
                    setTotalCount={setTotalCount}
                    selectedPageAdv={selectedPageAdv}
                    onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                    pageSizeAdv={pageSizeAdv}
                    onPageSizeAdv={(p) => setPageSizeAdv(p)}
                    quickFiltersClicked={quickFiltersClicked}
                    setQuickFiltersClicked={setQuickFiltersClicked}
                    advancedFiltersClicked={advancedFiltersClicked}
                    setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                    advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                    setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                    feedIDAdvFilter={feedIDAdvFilter}
                    onFeedIDAdvFilter={s => setFeedIDAdvFilter(s)}
                    selectedFeedNameAdvFilter={selectedFeedNameAdvFilter}
                    onSelectedFeedNameAdvFilter={s => setSelectedFeedNameAdvFilter(s)}
                    selectedCarrierIDAdvFilter={selectedCarrierIDAdvFilter}
                    onSelectedCarrierIDAdvFilter={s => setSelectedCarrierIDAdvFilter(s)}
                    selectedFeedTypeAdvFilter={selectedFeedTypeAdvFilter}
                    onSelectedFeedTypeAdvFilter={s => setSelectedFeedTypeAdvFilter(s)}
                    selectedFeedPhaseAdvFilter={selectedFeedPhaseAdvFilter}
                    onSelectedFeedPhaseAdvFilter={s => setSelectedFeedPhaseAdvFilter(s)}
                    executionIDAdvFilter={executionIDAdvFilter}
                    onExecutionIDAdvFilter={s => setExecutionIDAdvFilter(s)}
                    selectedExecutionStatusAdvFilter={selectedExecutionStatusAdvFilter}
                    onSelectedExecutionStatusAdvFilter={s => setSelectedExecutionStatusAdvFilter(s)}
                    selectedProcessingStepAdvFilter={selectedProcessingStepAdvFilter}
                    onSelectedProcessingStepAdvFilter={s => setSelectedProcessingStepAdvFilter(s)}
                    requestFromDateAdvFilter={requestFromDateAdvFilter}
                    onRequestFromDateAdvFilter={s => setRequestFromDateAdvFilter(s)}
                    requestToDateAdvFilter={requestToDateAdvFilter}
                    onRequestToDateAdvFilter={s => setRequestToDateAdvFilter(s)}
                    statusTabValue={statusTabValue}
                    onTransmissionStatusDashboardChange={onTransmissionStatusDashboardChange}
                    onTransmissionPhaseDashboardChange={onTransmissionPhaseDashboardChange}
                    onSetTransmissionStatusDashboardChange={(d) => setOnTransmissionStatusDashboardChange(d)}
                    onSetTransmissionPhaseDashboardChange={(d) => setOnTransmissionPhaseDashboardChange(d)}
                    onSetFilterCard={(s) => setFilterCard(s)}
                />

                <div className="max-w-100 mt-3" ref={tableRef}>
                    <GenericTable
                        columns={[...pushToColumnsBasedOnUserAction()]}
                        data={[...FilteredAndAddedIsExpand()]}
                        onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
                    />
                </div>


                <div>
                    <Pagination
                        stateLimit={true}
                        pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                        page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                        onChange={(t, v) => {
                            if (quickFiltersClicked) {
                                if (t === 1) setPageSize(v);
                                else if (t === 2) setSelectedPage(v);
                            } else if (advancedFiltersClicked) {
                                if (t === 1) setPageSizeAdv(v);
                                else if (t === 2) setSelectedPageAdv(v);
                            }
                        }}
                        count={totalCount}
                    />
                </div>
            </Paper>
        </div >
    )
}
export default TransmissionDashboardProduction;