import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Truncate = ({ lines, ellipsis, children, className }) => {
    const [isTruncated, setIsTruncated] = useState(false);
    const contentRef = useRef(null);

    useEffect(() => {
        const contentElement = contentRef.current;
        if (contentElement) {
            // Check if the content is overflowing
            setIsTruncated(contentElement.scrollHeight > contentElement.clientHeight);
        }
    }, [children, lines]);

    return (
        <div className={`truncate-container ${className}`}>
            <div
                ref={contentRef}
                className="truncate-content"
                style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: lines,
                }}
            >
                {children}
            </div>
            {isTruncated && (
                <div className="truncate-ellipsis">
                    {ellipsis}
                </div>
            )}
        </div>
    );
};

Truncate.propTypes = {
    lines: PropTypes.number.isRequired,
    ellipsis: PropTypes.node,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Truncate.defaultProps = {
    ellipsis: null,
    className: '',
};

export default Truncate;
