import React from "react";
import CarrierContactsTable from "./CarrierContactsTable";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";


function CarrierContacts({
    onTabValueCallback,
    selectCRContactValidationForm,
    selectCRContactFirstNameValidationForm,
    selectCRContactLastNameValidationForm,
    selectCRContactEmailValidationForm,
    selectCRContactTypeValidationForm,
    carrierContactTypes,
    groupReq,
    isPEOClicked,
    selectedOtherCarriers,
    inputsSavedValidation,
    setInputsSavedValidation,
    contactValidationCallback,
    setContactValidationCallback,
    selectedCarriers,
    requestSubmitted,
    parentForceUpdate,
    ...props }) {

    const [expanded, setExpanded] = React.useState("CarriersContacts");


    return (
        <Accordion expanded={expanded === `CarriersContacts`}>
            <AccordionSummary
                // onClick={() => expanded === `CarriersContacts` ? setExpanded("") : setExpanded("CarriersContacts")}
                // className="pl-0 pr-0"
                // expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="expand-carrier-contacts"
            >
                <span >Carrier's Contacts</span>
            </AccordionSummary>
            <AccordionDetails className="d-flex flex-column">
                <div className="mb-2 text-danger" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}>{selectCRContactValidationForm}</div>
                <div className="mb-2 text-danger" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}>{selectCRContactFirstNameValidationForm}</div>
                <div className="mb-2 text-danger" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}>{selectCRContactLastNameValidationForm}</div>
                <div className="mb-2 text-danger" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}>{selectCRContactEmailValidationForm}</div>
                <div className="mb-2 text-danger" hidden={!requestSubmitted || groupReq?.groupRequest?.GroupRequestStatusID === 4}>{selectCRContactTypeValidationForm}</div>
                <CarrierContactsTable
                    onTabValueCallback={onTabValueCallback}
                    carrierContactTypes={carrierContactTypes}
                    isPEOClicked={isPEOClicked}
                    selectedOtherCarriers={selectedOtherCarriers?.selectedOtherCarriersFullArray}
                    requestSubmitted={requestSubmitted}
                    setInputsSavedValidation={setInputsSavedValidation}
                    setContactValidation={setContactValidationCallback}
                    selectedCarriers={selectedCarriers}
                    parentForceUpdate={parentForceUpdate} />
            </AccordionDetails>
        </Accordion>
    )
}

export default CarrierContacts;
