import React from "react";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Divider, IconButton } from "@material-ui/core";
import deleteForeverSVG from "../../../Icons/trash-xmark.svg";

function TPFileRow({
    file,
    index,
    uploadedFiles,
    parentForceUpdate,
    ...props
}) {

    const handleDeleteFile = () => {
        uploadedFiles = uploadedFiles.splice(index, 1);
        uploadedFiles = [...(uploadedFiles ?? [])];
        parentForceUpdate();
    }


    return (
        <div className="row alignvh-center mt-3 text-center ml-4">
            <div className="col-2">
                <div className="blue-outlined-square-btn">{file?.Extension?.toUpperCase()}</div>
            </div>
            <div className="col-5 color black2 fw-500" style={{ wordBreak: "break-all" }}>
                {file?.Name}
            </div>
            <div className="col-3">
                <div className="blue-outlined-square-btn">{file?.Title}</div>
            </div>
            <div className="col-1">
                {/* <DeleteForeverIcon onClick={handleDeleteFile} color="action" style={{ fontSize: "30px", cursor: "pointer" }} /> */}
                <svg  width="21" height="24" viewBox="0 0 21 24" className="ml-3" onClick={handleDeleteFile}  style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${deleteForeverSVG}#trash-deleteIcon`}></use>
                        </svg>
            </div>
        </div>
    )
}

export default TPFileRow;