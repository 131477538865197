import React, { createRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useState } from 'react';
import * as Apis from '../../../../Data/APIs'
import { useLoader } from '../../../Layout/Loader';
import ClientFormsPendingHRSignatureTabSingleFeedWithFormsSingleFormRow from './ClientFormsPendingHRSignatureTabSingleFeedWithFormsSingleFormRow';
import { Button, Divider, Zoom } from '@material-ui/core';
import { ConfirmModal } from '../../../Modals/ConfirmModal';
import { SuccessModal } from '../../../Modals/SuccessModal';
import { FailureModal } from '../../../Modals/FailureModal';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import { useAuthentication } from '../../../../Data/Authentication';
import { toESTTime } from '../../../../_helper';
import moment from 'moment';
import ChangedDetails from '../../ChangedDetails';
import { CustomTooltip } from '../../../Layout/Tooltip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import editPenIcon from "../../../../Icons/editPen.svg";
import viewActionItemSVG from "../../../../Icons/viewActionItem.svg";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadWetSignatureInput from '../../UploadWetSignatureInput';
import { ConfirmSignFormModal, EmployeeForm } from '../../../EmployeeViews/EmployeeSignFormModal';
import RejectionReasonModal from "../../../TransmissionsForms/RejectionReasonModal";
import GenericTable from '../../../Layout/GenericTable';



const ClientFormsPendingHRSignatureTabSingleFeedWithForms = ({ feed, ...props }) => {
    const dialog = useModalDialog(0);
    const dialog1 = useModalDialog(1);
    const dialog2 = useModalDialog(2);
    const dialog3 = useModalDialog(3);

    const { openLoader, closeLoader } = useLoader();
    const [expanded, setexpanded] = useState(false);

    const [forms, setForms] = useState([])
    const [lastEvaluatedKey, setlastEvaluatedKey] = useState(null);

    const [selfForms, setSelfForms] = useState([]);
    const [SelfLastEvaluatedKey, setSelfLastEvaluatedKey] = useState(null);

    const [dfElements, setDfElements] = useState([])


    const { authUser } = useAuthentication();
    let userLegalID = authUser?.attributes["custom:LegalID"]
    let userAccountID = authUser?.attributes["custom:AccountID"]
    let userClientID = authUser?.attributes["custom:ClientID"]
    let userHasElectronicSignature = authUser?.attributes["custom:ElectronicSignature"] == "Accepted";

    const signaturesRef = createRef();
    const formRef = createRef();
    const pdfRef = createRef();
    const fieldsRef = createRef();


    // formsType ==> all , self , other
    let getFormsPerFeed = (FeedID, formsType, withReset, withResetSelf) => {
        openLoader();
        let currentFeedInfo = {
            //lsi 5 >> Transmissions Tab
            lsi: '14',
            roleID: "6",
            feedID: FeedID,
            hrSign: '1',
            userClientID: userClientID ?? '',
            userAccountID: userAccountID ?? '',
            userLegalID: userLegalID ?? ''
        };
        if ((formsType == 'all' || formsType == 'other') && lastEvaluatedKey && !withReset) currentFeedInfo.LastEvaluatedKey = lastEvaluatedKey
        if ((formsType == 'all' || formsType == 'self') && SelfLastEvaluatedKey && !withResetSelf) currentFeedInfo.SelfLastEvaluatedKey = SelfLastEvaluatedKey
        Apis.getEmployeeForms({ ...currentFeedInfo }).then(r => {
            const { selfData, clientData } = r;
            // self Data
            if (selfData.msg == "Success") {
                setDfElements(r.selfData.DFElements)
                // if (formsType == 'all' || formsType == 'self') {
                if (withResetSelf)
                    setSelfForms(selfData?.data);
                else
                    setSelfForms([...selfForms, ...selfData?.data]);
                // }
                setSelfLastEvaluatedKey(selfData?.SelfLastEvaluatedKey ? selfData?.SelfLastEvaluatedKey : null)
            } else setSelfForms([])

            // all Data
            if (clientData.msg == "Success") {
                if (!dfElements) {
                    setDfElements(r.clientData.DFElements)
                }
                // if (formsType == 'all' || formsType == 'other') {
                let notSelfForms = clientData?.data.filter(f => f.selfFlag !== "1")
                if (withReset)
                    setForms(notSelfForms);
                else
                    setForms([...forms, ...notSelfForms]);
                // }
                setlastEvaluatedKey(clientData?.LastEvaluatedKey ? clientData?.LastEvaluatedKey : null)
            } else setForms([])

        }).catch((error) => {
            console.log('Get Pending Forms Error', error);
        }).finally((t) => closeLoader());
    }


    let handleExpandFeed = (FeedID) => {
        if (!expanded) {
            getFormsPerFeed(FeedID, 'all', true, true)
        }
        setexpanded(prev => !prev);
    }
    // Process Feed
    // process Feed and get raw data 
    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content, 'lineHeight-1-6em');
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }
    const getTestFile = (testOnly) => {
        // testOnly =>>will be 1 or 0 , 1 = stops on parser step only
        openLoader();
        Apis.getTestFile({ feedId: feed?.FeedID, testOnly }).then((r) => {
            if (r?.statusCode === 200) {
                SuccessModal(dialog, {
                    title: 'Success!',
                    body: `Your request has been processed successfully!`,
                });
            } else {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: `The request has not been processed successfully. Please try again!`,
                });
            }
            closeLoader();
        })
    }
    const getTestFileConfirmation = () => {
        openModal(0, () => getTestFile(0), {
            title: 'Please Confirm',
            question: `Generate Forms will clear all the Forms that are still Pending an action <br/> Please make sure all Pending Forms are transmitted or rejected before generating new forms`,
        })
    }

    // ==============================================================NEW TABLE UI
    const handleWetSignatureUploader = (form, withSelfFlag) => dialog3.openDialog('Upload File', <UploadWetSignatureInput form={form} withSelfFlag={withSelfFlag} dialog={dialog3} HandleSignCore={HandleSignCore} />)

    const handleViewFn = (form) => {
        dialog2.openDialog(
            <>
            </>,
            <>


                {/* will submit rejection reason in this Component */}
                <EmployeeForm
                    form={form}
                    signaturesRef={signaturesRef}
                    fieldsRef={fieldsRef}
                    formRef={formRef}
                    pdfRef={pdfRef}
                    canSign={false}
                    canEdit={true}
                    mainDialog={dialog2}
                />
            </>,
        )
    }

    const HandleSignCore = (form, withSelfFlag, spouseFlag = false) => {
        openLoader();
        let transmitFormObject = {
            feedID: feed?.FeedID,
            hrSign: '1',
            empArray: [{
                SK: form?.SK,
                empDOB: form?.empDOB,
                empKey: form?.empKey,
                empName: form?.empName,
                empSSN: form?.empSSN,
                outputFile: form?.outputFile,
                executionID: form?.executionID,
                formType: form?.formType,
                depSignature: spouseFlag ? '1' : '0'
            }]
        }
        Apis.transmitForm(transmitFormObject).then(r => {
            getFormsPerFeed(feed?.FeedID, withSelfFlag ? 'self' : 'other', true, true)
        })
            .catch((error) => {
                console.log('Sign Form Error:', error);
            }).finally((t) => {
                dialog.closeDialog();
                // close wet Signature dialog
                dialog3.closeDialog();
                closeLoader()
            });
    }


    let getGeneratedEmployeeFormHandler = async (form, withSelfFlag) => {
        openLoader();
        const forms = await Apis.getGeneratedEmployeeForms(form.FeedID, form?.IsolvedEmployeeID);
        const generatedForm = await Apis.getGeneratedEmployeeForm(forms[0].PK, forms[0].SK);
        closeLoader();
        ConfirmSignFormModal(
            dialog1,
            form,
            (spouseFlag) => {
                HandleSignCore(form, withSelfFlag, spouseFlag);
            },
            'Client',
            // canEdit (Fields)
            true,
            openLoader,
            closeLoader,
            generatedForm
        )
    }

    const handleSignFn = (form, withSelfFlag) => ConfirmModal(
        dialog,
        () => {
            getGeneratedEmployeeFormHandler(form, withSelfFlag);
        },
        {
            title: "Please Confirm",
            question: `<div class="text-center client-form-sign-confirmation-modal">I have agreed to the Terms of Use and Consent to Electronic Signature. I certify the terms and conditions of the agreement are still in effect. I have reviewed the content of the enrollment form and it is true and complete to the best of my knowledge. By selecting ‘Sign’ below, I acknowledge I am electronically signing the enrollment form.</div>`,
        }, "", { yes: "Sign", no: "Cancel" });


    const handleRejectForms = (form, withSelfFlag, rejectReason, rejectionValidationForm) => {
        if (rejectionValidationForm) return;
        openLoader();
        dialog.closeDialog();
        let rejectFormObject = {
            empArray: [{
                SK: form?.SK,
                empDOB: form?.empDOB,
                empKey: form?.empKey,
                empName: form?.empName,
                empSSN: form?.empSSN,
                outputFile: form?.outputFile,
                executionID: form?.executionID,
                feedID: form?.feedID,
                rejectionReason: rejectReason,
            }]
        }

        Apis.rejectForm(rejectFormObject).then((r) => {
            getFormsPerFeed(feed?.FeedID, withSelfFlag ? 'self' : 'other', true, true)
        });
    }

    const columnBaseArrayWithSelfFlag = (withSelfFlag) => {
        const columnBaseArray = [
            {
                name: 'Employee Name',
                sortable: false,
                id: 'empName',
                selector: form => form.empName
            },
            {
                name: 'Carrier',
                sortable: false,
                id: 'carrierName',
                selector: form => form.carrierName
            },
            {
                name: 'Form Type',
                sortable: false,
                id: 'formType',
                selector: form => form?.formType?.replace('Enrolment', 'Enrollment')
            },
            {
                name: 'Generation Timestamp',
                sortable: false,
                id: 'formGenerationTime',
                selector: form => form?.formGenerationTime ? moment(toESTTime(form?.formGenerationTime)).format('MM/DD/YYYY, HH:mm:ss') : "N/A"
            },
            {
                name: 'Generation Reason',
                sortable: false,
                id: 'GenerationReason',
                selector: form =>
                    <button className="fs-13 btn-like-link link-style" style={{ color: "#2c87f0" }} onClick={(e) => {
                        e.stopPropagation(); e.preventDefault(); dialog.openDialog("Changed Fields:",
                            <div id="last-sent-file-modal">
                                {/* <h5 className="black1 fw-700">Changed Fields:</h5>
                                <Divider /> */}
                                <ChangedDetails dsxUrl={form?.trackedFile} dfElements={dfElements} />
                            </div>, []
                        )
                    }}>
                        Change Details
                    </button>
            },
            {
                name: 'Actions',
                sortable: false,
                id: 'Actions',
                selector: form => <div className="col d-flex flex-column">
                    <div className="d-flex my-1" role="button" onClick={() => handleViewFn(form)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" >
                            <use xlinkHref={`${viewActionItemSVG}#viewAIIcon`}></use>
                        </svg>
                        <span className="ml-2">View</span>
                    </div>
                    {feed?.HrSignature &&
                        <>
                            {userHasElectronicSignature ?
                                <>
                                    {form?.isWetSignature ?
                                        <CustomTooltip TransitionComponent={Zoom}
                                            title='Upload Signed Form'>
                                            <div className="d-flex my-1" role="button" onClick={() => handleWetSignatureUploader(form, withSelfFlag)}>
                                                <FileUploadIcon /><span className="ml-2">Upload</span>
                                            </div>
                                        </CustomTooltip> :
                                        <div className="d-flex my-1" role="button" onClick={() => handleSignFn(form, withSelfFlag)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" >
                                                <use xlinkHref={`${editPenIcon}#editPenIcon`}></use>
                                            </svg>
                                            <span className="ml-2">Sign</span>
                                        </div>
                                    }
                                </>
                                :
                                <CustomTooltip TransitionComponent={Zoom}
                                    title='Upload Signed Form'>
                                    <div className="d-flex my-1" role="button" onClick={() => handleWetSignatureUploader(form, withSelfFlag)}>
                                        <FileUploadIcon /><span className="ml-2">Upload</span>
                                    </div>
                                </CustomTooltip>
                            }
                            {/* ====================================================== */}
                        </>
                    }
                    <div className="d-flex my-1" role="button"
                        onClick={() => {
                            openModal(0,
                                dialog.openDialog("Rejection Reason", <RejectionReasonModal forceRejectWithReason={handleRejectForms} withSelfFlag={withSelfFlag} form={form} />, []), {
                                title: "Please Confirm",
                                question: `Are you sure you want to reject the selected forms?`,
                            })
                        }}>
                        <HighlightOffIcon /><span className="ml-2">Reject</span>
                    </div>
                </div>
            },

        ]

        return columnBaseArray

    }
    return (
        <>
            <div className="w-100 d-flex py-2 mt-1">
                <div className="" role="button" onClick={() => handleExpandFeed(feed?.FeedID)}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <p className="">{feed?.CarrierName ? feed?.CarrierName : 'Carrier Name'} {
                    feed?.FeedFormMainTypes?.length > 0 && <> - ({feed?.FeedFormMainTypes?.map((el, elIndex) => <>
                        {el} {elIndex !== feed?.FeedFormMainTypes?.length - 1 && ', '}
                    </>)})
                    </>
                }</p>
                <Button variant="contained" className="blue-outlined-btn ml-auto" hidden disabled={!feed} style={{ whiteSpace: "nowrap" }} onClick={getTestFileConfirmation}>
                    <label style={{ cursor: "pointer" }} className="mb-0" hidden>Generate Forms</label>
                </Button>
            </div>
            <div className="w-100 d-flex flex-column">
                {expanded && <>
                    {/* Self Forms */}
                    {selfForms &&
                        <>
                            <p className='mt-3'>My Forms</p>

                            <GenericTable
                                columns={[...columnBaseArrayWithSelfFlag(true)]}
                                data={[...selfForms]}
                                onSort={() => { }}
                            />


                        </>}
                    {/* All Forms */}
                    {forms ? <>
                        <p className='mt-3'>All Forms</p>

                        <GenericTable
                            columns={[...columnBaseArrayWithSelfFlag(false)]}
                            data={[...forms]}
                            onSort={() => { }}
                        />

                        {forms?.length >= 0 &&
                            <>
                                <div className="mt-3 text-center">
                                    <Button className="load-more-btn blue-btn" disabled={lastEvaluatedKey == null} onClick={() => getFormsPerFeed(feed?.FeedID, 'other', false, true)}>Load More Results</Button>
                                </div>

                            </>
                        }

                    </> : ''}
                </>}
            </div>
        </>
    )
}

export default ClientFormsPendingHRSignatureTabSingleFeedWithForms
