import React from "react";
import * as APIs from "../../Data/APIs";
import Moment from 'moment';
import { useLoader } from "../Layout/Loader";
import TransmissionDashboardFilter from "./TransmissionDashboardFilter";
import SingleFeedTransmissions from "./SingleFeedTransmissions";
import { Button, Divider, Paper, Zoom } from "@material-ui/core";
import Pagination from "../Layout/Pagination";
import MultipleTabsTitles from "../Layout/MultipleTabsTitles";
import { CustomTooltip } from "../Layout/Tooltip";
import { useHistory } from "react-router-dom";
import { useAuthentication } from "../../Data/Authentication";
import TransmissionsStatistics from "./TransmissionsStatistics/TransmissionsStatistics";
import { changeTimeZoneToEST } from "../Layout/DateConverted";
import GenericTable from "../Layout/GenericTable";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import Truncate from "react-truncate";
import DownloadIcon from '@mui/icons-material/Download';
import CopyToClipboardButton from "../Layout/CopyToClipboardButton";
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import PieChartIcon from '@mui/icons-material/PieChart';
import { FailureModal } from "../Modals/FailureModal";
import SingleFeedTransmissionsPlans from "./SingleFeedTransmissionsPlans";
import { useState } from "react";
import { useEffect } from "react";
import { SingleFeedTransmissionNew, withCustomProps } from "./SingleFeedTransmissionNew";
import downloadIconSVG from "../../Icons/downloadIcon.svg";
import fileNameSVG from "../../Icons/fileName.svg";
import schedulerIconSVG from "../../Icons/scheduler.svg";
import viewPlansSVG from "../../Icons/viewPlans.svg";


const todayDate = changeTimeZoneToEST(new Date());
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0);
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0);
sixMonthDate.setMonth(todayDate.getMonth() - 6);



function TransmissionDashboard() {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const tableRef = React.useRef();
    const [quickFiltersClicked, setQuickFiltersClicked] = React.useState(true);
    const [advancedFiltersClicked, setAdvancedFiltersClicked] = React.useState(false);
    const [advancedFiltersAppliedClicked, setAdvancedFiltersAppliedClicked] = React.useState(false);

    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const transmissionsTabs = ["Transmission Dashboard", "Production Dashboard"];
    const [tabValue, setTabValue] = React.useState(0);
    const [feedsTransmissionsList, setFeedsTransmissionsList] = React.useState([]);
    const [feedsTransmissionsResponse, setFeedsTransmissionsResponse] = React.useState("");
    const [totalCount, setTotalCount] = React.useState(0);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);
    const [fromDateFilter, setFromDateFilter] = React.useState(monthDate);
    const [toDateFilter, setToDateFilter] = React.useState(todayDate);
    const [sentFromDateFilter, setSentFromDateFilter] = React.useState(null);
    const [sentToDateFilter, setSentToDateFilter] = React.useState(null);
    const [feedIDFilter, setFeedIDFilter] = React.useState("");
    const [carrierIDFilter, setCarrierIDFilter] = React.useState("");
    const [feedTypeFilter, setFeedTypeFilter] = React.useState("");
    const [clientIDFilter, setClientIDFilter] = React.useState("");
    const [peoIDFilter, setPEOIDFilter] = React.useState("");
    const [feedStatusFilter, setFeedStatusFilter] = React.useState("");
    const [feedPhaseFilter, setFeedPhaseFilter] = React.useState("");
    const [executionStatusFilter, setExecutionStatusFilter] = React.useState("");
    const [executionIDFilter, setExecutionIDFilter] = React.useState("");
    const [processingStepFilter, setProcessingStepFilter] = React.useState("");
    const [sortFieldFilter, setSortFieldFilter] = React.useState("");
    const [sortOrderFilter, setSortOrderFilter] = React.useState("DESC");
    const [refreshClicked, setRefreshClicked] = React.useState(false);

    const [feedsFilterObject, setFeedsFilterObject] = React.useState();

    //Advanced Filters
    const [feedIDAdvFilter, setFeedIDAdvFilter] = React.useState("");
    const [selectedFeedNameAdvFilter, setSelectedFeedNameAdvFilter] = React.useState([]);
    const [selectedCarrierIDAdvFilter, setSelectedCarrierIDAdvFilter] = React.useState([]);
    const [selectedFeedTypeAdvFilter, setSelectedFeedTypeAdvFilter] = React.useState([]);
    const [selectedClientIDsAdvFilter, setSelectedClientIDsAdvFilter] = React.useState([]);
    const [selectedPEOIDsAdvFilter, setSelectedPEOIDsAdvFilter] = React.useState([]);
    const [selectedFeedStatusAdvFilter, setSelectedFeedStatusAdvFilter] = React.useState([]);
    const [selectedFeedPhaseAdvFilter, setSelectedFeedPhaseAdvFilter] = React.useState([]);
    const [executionIDAdvFilter, setExecutionIDAdvFilter] = React.useState("");
    const [selectedExecutionStatusAdvFilter, setSelectedExecutionStatusAdvFilter] = React.useState([]);
    const [selectedProcessingStepAdvFilter, setSelectedProcessingStepAdvFilter] = React.useState([]);
    const [requestFromDateAdvFilter, setRequestFromDateAdvFilter] = React.useState(monthDate);
    const [requestToDateAdvFilter, setRequestToDateAdvFilter] = React.useState(todayDate);
    const [sentFromDateAdvFilter, setSentFromDateAdvFilter] = React.useState(null);
    const [sentToDateAdvFilter, setSentToDateAdvFilter] = React.useState(null);
    const [selectedPageAdv, setSelectedPageAdv] = React.useState(1);
    const [pageSizeAdv, setPageSizeAdv] = React.useState(30);
    const [sortFieldAdvFilter, setSortFieldAdvFilter] = React.useState("");
    const [sortOrderAdvFilter, setSortOrderAdvFilter] = React.useState("DESC");


    const [filterCard, setFilterCard] = React.useState(false);
    const [onTransmissionStatusDashboardChange, setOnTransmissionStatusDashboardChange] = React.useState(false);
    const [onTransmissionPhaseDashboardChange, setOnTransmissionPhaseDashboardChange] = React.useState(false);

    const [expandedRows, setExpandedRows] = React.useState({});






    const handleTabChange = (e, newValue) => {
        setTabValue(newValue);
        if (newValue === 1)
            routeHistory.push(`/EDITransmissions/Dashboard/Production`);
        else routeHistory.push(`/EDITransmissions/Dashboard`);
    };

    const getDataQuickFilters = () => {
        openLoader();
        if (fromDateFilter === null || todayDate === null) return;
        else {
            const feedsTransmissions = {};
            if (selectedPage >= 1) {
                feedsTransmissions.page = selectedPage;
                feedsTransmissions.limit = pageSize;
            }
            if (pageSize >= 10) {
                feedsTransmissions.limit = pageSize;
                feedsTransmissions.page = selectedPage;
            }
            if (fromDateFilter)
                feedsTransmissions.fromDate = Moment(fromDateFilter).format("YYYY-MM-DD");
            if (toDateFilter)
                feedsTransmissions.toDate = Moment(toDateFilter).format("YYYY-MM-DD");
            if (sentFromDateFilter)
                feedsTransmissions.sentFromDate = Moment(sentFromDateFilter).format("YYYY-MM-DD");
            if (sentToDateFilter)
                feedsTransmissions.sentToDate = Moment(sentToDateFilter).format("YYYY-MM-DD");
            if (feedIDFilter !== "")
                feedsTransmissions.feedID = [`${feedIDFilter}`];
            if (carrierIDFilter !== "")
                feedsTransmissions.carrierID = [`${carrierIDFilter}`];
            if (executionStatusFilter !== "")
                feedsTransmissions.executionStatus = [`${executionStatusFilter}`];
            if (processingStepFilter !== "")
                feedsTransmissions.processingStep = [`${processingStepFilter}`];
            if (feedTypeFilter !== "")
                feedsTransmissions.feedTypeID = [`${feedTypeFilter}`];
            if (clientIDFilter !== "")
                feedsTransmissions.clientID = [`${clientIDFilter}`];
            if (peoIDFilter !== "")
                feedsTransmissions.peoID = [`${peoIDFilter}`];
            if (feedStatusFilter !== "")
                feedsTransmissions.feedStatusID = [`${feedStatusFilter}`];
            if (feedPhaseFilter !== "")
                feedsTransmissions.feedPhaseID = [`${feedPhaseFilter}`];
            if (executionIDFilter !== "")
                feedsTransmissions.executionID = executionIDFilter;
            if (sortFieldFilter !== "")
                feedsTransmissions.sortType = sortFieldFilter;
            if (sortOrderFilter !== "")
                feedsTransmissions.sortOrder = sortOrderFilter;

            setFeedsFilterObject(feedsTransmissions);

            APIs.getFeedsTranmissionsNEW(feedsTransmissions).then((r) => {
                setFeedsTransmissionsList(r?.data);
                setRefreshClicked(false);
                setTotalCount(r?.totalCount ?? 0);
                closeLoader();
                setFilterCard(false);
                setFeedsTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
            });
        }
    };

    const getDataAdvancedFilters = () => {
        openLoader();
        if (requestFromDateAdvFilter === null || requestToDateAdvFilter === null) return;
        const feedsTransmissions = {};
        if (selectedPageAdv >= 1) {
            feedsTransmissions.page = selectedPageAdv;
            feedsTransmissions.limit = pageSizeAdv;
        }
        if (pageSizeAdv >= 10) {
            feedsTransmissions.limit = pageSizeAdv;
            feedsTransmissions.page = selectedPageAdv;
        }
        if (requestFromDateAdvFilter)
            feedsTransmissions.fromDate = Moment(requestFromDateAdvFilter).format("YYYY-MM-DD");
        if (requestToDateAdvFilter)
            feedsTransmissions.toDate = Moment(requestToDateAdvFilter).format("YYYY-MM-DD");
        if (sentFromDateAdvFilter)
            feedsTransmissions.sentFromDate = Moment(sentFromDateAdvFilter).format("YYYY-MM-DD");
        if (sentToDateAdvFilter)
            feedsTransmissions.sentToDate = Moment(sentToDateAdvFilter).format("YYYY-MM-DD");
        if (feedIDAdvFilter !== "")
            feedsTransmissions.feedID = [`${feedIDAdvFilter}`];
        if (selectedFeedNameAdvFilter.length > 0)
            feedsTransmissions.feedID = selectedFeedNameAdvFilter?.map(s => `${s?.FeedID}`);
        if (selectedCarrierIDAdvFilter.length > 0)
            feedsTransmissions.carrierID = selectedCarrierIDAdvFilter?.map(s => `${s?.CarrierID}`);
        if (selectedExecutionStatusAdvFilter.length > 0)
            feedsTransmissions.executionStatus = selectedExecutionStatusAdvFilter?.map(s => `${s?.Id}`);
        if (selectedProcessingStepAdvFilter.length > 0)
            feedsTransmissions.processingStep = selectedProcessingStepAdvFilter?.map(s => `${s?.Id}`);
        if (selectedFeedTypeAdvFilter.length > 0)
            feedsTransmissions.feedTypeID = selectedFeedTypeAdvFilter?.map(s => `${s?.FeedTypeID}`);
        if (selectedClientIDsAdvFilter.length > 0)
            feedsTransmissions.clientID = selectedClientIDsAdvFilter?.map(s => `${s?.GroupID}`);
        if (selectedPEOIDsAdvFilter.length > 0)
            feedsTransmissions.peoID = selectedPEOIDsAdvFilter?.map(s => `${s?.PeoId}`);
        if (selectedFeedStatusAdvFilter.length > 0)
            feedsTransmissions.feedStatusID = selectedFeedStatusAdvFilter?.map(s => `${s?.FeedStatusID}`);
        if (selectedFeedPhaseAdvFilter.length > 0)
            feedsTransmissions.feedPhaseID = selectedFeedPhaseAdvFilter?.map(s => `${s?.FeedPhaseID}`);
        if (executionIDAdvFilter !== "")
            feedsTransmissions.executionID = executionIDAdvFilter;
        if (sortFieldAdvFilter !== "")
            feedsTransmissions.sortType = sortFieldAdvFilter;
        if (sortOrderAdvFilter !== "")
            feedsTransmissions.sortOrder = sortOrderAdvFilter;

        setFeedsFilterObject(feedsTransmissions);

        APIs.getFeedsTranmissionsNEW(feedsTransmissions).then((r) => {
            setFeedsTransmissionsList(r?.data);
            setRefreshClicked(false);
            setTotalCount(r?.totalCount ?? 0);
            setAdvancedFiltersAppliedClicked(false);
            closeLoader();
            setFeedsTransmissionsResponse(r?.LastEvaluatedKey ? { LastEvaluatedKey: r?.LastEvaluatedKey } : null);
        });
    };

    React.useEffect(() => {
        if (quickFiltersClicked && !filterCard) getDataQuickFilters();
    }, [filterCard, quickFiltersClicked === true, selectedPage, pageSize, fromDateFilter, toDateFilter, sentFromDateFilter, sentToDateFilter, feedIDFilter, carrierIDFilter, executionStatusFilter, feedTypeFilter, clientIDFilter, peoIDFilter, feedStatusFilter, feedPhaseFilter, executionIDFilter, processingStepFilter, sortFieldFilter, sortOrderFilter, refreshClicked === true])

    React.useEffect(() => {
        getDataAdvancedFilters();
    }, [selectedPageAdv, pageSizeAdv, sortFieldAdvFilter, sortOrderAdvFilter, advancedFiltersAppliedClicked])


    React.useEffect(() => {
        if (advancedFiltersClicked === true) getDataAdvancedFilters();
    }, [advancedFiltersClicked === true])


    // ============================================================================[NEW UI]
    const sortActualPlans = (plans) => {
        plans.sort((a, b) => {
            if (a.isExported && !b.isExported) {
                return -1; // a should come before b
            } else if (!a.isExported && b.isExported) {
                return 1; // b should come before a
            } else {
                return 0; // the order of a and b doesn't matter
            }
        });

        return plans
    }
    const getActualPlansHandle = (feedTransmission) => {
        openLoader()
        APIs.getActualPlans(feedTransmission?.SK)
            .then(r => {
                dialog.openDialog(
                    <div>
                        <h5 className="black1 fw-700 text-left mb-0">Actual Plans</h5>
                    </div>,
                    <>
                        <SingleFeedTransmissionsPlans plans={r?.length > 0 ? sortActualPlans(r) : []} />
                    </>

                )


            })
            .catch(err => FailureModal(dialog, {
                title: 'Failure!',
                body: err
            }))
            .finally(() => closeLoader())
    }


    const getExactFileNameHandle = (feedTransmission) => {
        openLoader()
        APIs.getExactFileName(feedTransmission?.SK)
            .then(r => {
                if (r?.success === "1") {
                    dialog.openDialog(
                        <div>
                            <h5 className="black1 fw-700">File name</h5>
                            {/* <Divider /> */}
                        </div>,
                        <>
                            <div>{r?.fileName}</div>
                            <div className="row" style={{ justifyContent: "flex-end" }}><CopyToClipboardButton textToCopy={r?.fileName} /></div>
                        </>

                    )
                }
                else {
                    FailureModal(dialog, {
                        title: 'Failure!',
                        body: 'Failed Retrieving File Name'
                    });
                }
            })
            .catch(err => console.log(err))
            .finally(() => closeLoader())
    }

    const dialog = useModalDialog(0);


    const handleSort = (column, sortDirection, type) => {
        // setSortOrder(sortDirection?.toUpperCase());

        if (type === "quick") {
            setSortFieldFilter(column?.sortingkey)
            setSortOrderFilter(sortDirection?.toUpperCase());

        } else if (type === 'adv') {
            setSortFieldAdvFilter(column?.sortingkey)
            setSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };


    const handleRowExpandToggle = async (row) => {
        if (expandedRows[row.id]?.isExpanded) {
            setExpandedRows((prev) => ({ ...prev, [row.SK]: { ...prev[row.SK], isExpanded: false } }));
        } else {
            // const response = await axios.get(`https://jsonplaceholder.typicode.com/posts/${row.id}/comments`);
            setExpandedRows((prev) => ({
                ...prev,
                [row.SK]: { isExpanded: true },
            }));
        }
    };

    const expandableRowExpanded = (row) => row.isExpanded;



    const baseColumns = [
        // Feed ID
        // ================Difference here
        {
            name: 'Feed Name',
            sortable: true,
            sortingkey: 'FeedName',
            id: 'FeedName',
            selector: feedTransmission => <a style={{ wordBreak: "break-word", margin: "auto" }} href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`} onClick={(e) => { e.stopPropagation(); }}>
                {feedTransmission?.FeedName}
            </a>
        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: feedTransmission => <div style={{ userSelect: "text", cursor: "auto" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>{feedTransmission?.CarrierName === "Third Party" ? `${feedTransmission?.CarrierName} (${feedTransmission?.ThirdPartyName})` : feedTransmission?.CarrierName}</div>
        },
        {
            name: 'Client Name',
            sortable: true,
            sortingkey: 'GroupName',
            id: 'GroupName',
            selector: feedTransmission =>
                <div>
                    {feedTransmission?.ConnectivityRequests?.length > 1 ? (
                        <button className="fs-13 link-style btn-like-link" style={{ color: "#2c87f0" }} onClick={(e) => {
                            e.stopPropagation(); e.preventDefault(); dialog.openDialog("Clients:",
                                <div id="last-sent-file-modal">
                                    {/* <h5 className="black1 fw-700">Clients:</h5>
                                    <Divider /> */}
                                    <h6 className="text-nowrap mt-4 fw-500">
                                        {feedTransmission?.ConnectivityRequests?.map((s, si) => (
                                            <>
                                                <a key={`internal-client-names-${si}`} className="link-style" href={`/GroupRequests/ViewGroupRequest/${s?.GroupRequestID}`}>
                                                    - {s?.PeoName ? s?.PeoName : s?.GroupName}
                                                </a>
                                                <br />
                                            </>
                                        ))}
                                    </h6>
                                </div>, []
                            )
                        }}>
                            {`${feedTransmission?.ConnectivityRequests?.length} Clients`}
                        </button>
                    ) : (
                        <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${feedTransmission?.GroupRequestID}`}>
                            {feedTransmission?.PeoName ? feedTransmission?.PeoName : feedTransmission?.ClientName}
                        </a>
                    )}
                </div>
        },
        {
            name: 'Feed Status',
            sortable: true,
            sortingkey: 'FeedStatusName',
            id: 'FeedStatusName',
            selector: feedTransmission => feedTransmission?.FeedStatus
        },
        {
            name: 'Feed Phase',
            sortable: true,
            sortingkey: 'FeedPhaseName',
            id: 'FeedPhaseName',
            selector: feedTransmission => feedTransmission?.FeedPhase
        },
        {
            name: 'Execution ID',
            sortable: true,
            sortingkey: 'ExecutionID',
            id: 'ExecutionID',
            selector: feedTransmission => feedTransmission?.SK,
        },
        // Request Timestamp
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"Date and Time of when the feed started processing or reprocessing"}>
                <span>
                    <div>
                        Request Timestamp
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            sortingkey: 'requestTimestamp',
            id: 'requestTimestamp',
            selector: feedTransmission => Moment(feedTransmission?.RequestDate).format("MM/DD/YYYY HH:mm:ss")
        },
        // Sent Timestamp
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"Date and Time of when the file feed was transmitted"}>
                <span>
                    <div>
                        Sent Timestamp
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            sortingkey: 'SentTimeStamp',
            id: 'SentTimeStamp',
            selector: feedTransmission =>
                <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom} title={feedTransmission?.SentDate === "N/A" ? "File is not transmitted yet" : ""}>
                    <span>
                        {`${feedTransmission?.SentDate === "N/A" ? "N/A" : Moment(feedTransmission?.SentDate).format("MM/DD/YYYY HH:mm:ss")}`}
                    </span>
                </CustomTooltip>
        },
        //  Processing Step
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"The step in which the feed is currently processing"}>
                <span>
                    <div>
                        Processing Step
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            sortingkey: 'ProcessingStepID',
            id: 'ProcessingStepID',
            selector: feedTransmission => feedTransmission.ProcessingStep
        },
        //  Processing Log
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"The status of the Processing Step"}>
                <span>
                    <div>
                        Processing Log
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            width: '100px',
            sortingkey: 'ProcessingLog',
            id: 'ProcessingLog',
            selector: feedTransmission => <div className="w-100" style={{ wordBreak: "break-all" }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                <Truncate lines={4} ellipsis={<span>... <button className="btn-like-link" style={{ color: "#2c87f0" }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); dialog.openDialog("Processing Logs:",<div><p style={{ wordBreak: "break-word" }}>{feedTransmission?.ProcessingLog}</p></div>,[]) }}>Read More</button></span>}>
                    {feedTransmission?.ProcessingLog}
                </Truncate>
            </div>
        },
        // Transmission Status
        {
            name: <CustomTooltip style={{ cursor: "pointer" }} TransitionComponent={Zoom} title={"Identifies if the feed processing was completed, failed, stopped, or pending"}>
                <span>
                    <div>
                        Transmission Status
                    </div>
                </span>
            </CustomTooltip>
            ,
            doesNotHaveToolTip: true,
            sortable: true,
            sortingkey: 'tes.Name',
            id: 'tes.Name',
            selector: feedTransmission => <CustomTooltip onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} TransitionComponent={Zoom}
                title={(feedTransmission?.ExecutionStatus)?.includes("Progress") ? "The feed is still processing" :
                    (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "Current processing step is completed" :
                        (feedTransmission?.ExecutionStatus)?.includes("Parsing") ? "Failed to convert the data file to the required format" :
                            (feedTransmission?.ExecutionStatus)?.includes("Tracking") ? "Failed to process the data" :
                                (feedTransmission?.ExecutionStatus)?.includes("Mapping") ? "There is an error in the output file" :
                                    (feedTransmission?.ExecutionStatus)?.includes("Serializing") ? "Unable to successfully generate a file" :
                                        (feedTransmission?.ExecutionStatus)?.includes("Encrypting") ? "Failed to encrypt the output file" :
                                            (feedTransmission?.ExecutionStatus)?.includes("Failed Transmitting") ? "Failed to submit the file" :
                                                (feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? "File needs to be manually submitted" :
                                                    (feedTransmission?.ExecutionStatus)?.includes("Pending") ? "The feed didn't start processing yet" :
                                                        (feedTransmission?.ExecutionStatus)?.includes("File Pulling Failed") ? "Failed to extract a data file" :
                                                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "There are no changes on the output file" :
                                                                ""}>
                <span>
                    <div style={(feedTransmission?.ExecutionStatus)?.includes("Pending Transmission") ? { fontSize: "smaller" } : {}}
                        className={`
            ${(feedTransmission?.ExecutionStatus).includes("Fail") ? "red background status-div" :
                                (feedTransmission?.ExecutionStatus).includes("Pending Transmission") ? "yellow-secondary-color background status-div" :
                                    (feedTransmission?.ExecutionStatus)?.includes("Completed") ? "green background status-div" :
                                        (feedTransmission?.ExecutionStatus)?.includes("In Progress") ? "purple1 background status-div" :
                                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "lightgreen background status-div" :
                                                ""}`}
                    >
                        {feedTransmission?.ExecutionStatus}
                    </div>
                </span>
            </CustomTooltip>
        },
        // Actions
        {
            name: "Actions",
            sortable: false,
            id: 'Actions',
            width: '200px',
            selector: feedTransmission => <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>

                <CustomTooltip TransitionComponent={Zoom}
                    title={feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" ? `No readable file available for this step` :
                        (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") ? `No readable file available for failed mapping transmission` :
                            (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "No readable file available for empty file" :
                                `Download Readable File`}>
                    <button
                        className="btn-like-link"
                        style={{ cursor: feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" || (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") || (feedTransmission?.ExecutionStatus)?.includes("Finished") ? "default" : "", textDecoration: feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" ? "none" : "" }} onClick={() => {
                            if (feedTransmission?.ProcessingStep === "Parser" || feedTransmission?.ProcessingStep === "Tracker" || (feedTransmission?.ExecutionStatus)?.includes("Failed Mapping") || (feedTransmission?.ExecutionStatus)?.includes("Finished"))
                                return;
                            else APIs.getDownloadReadableExcel(feedTransmission?.SK).then(r => window.open(r?.url));
                        }}>
                        <svg width="20" height="20" viewBox="0 0 20 20" className="mt-2">
                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                        </svg>
                    </button>
                </CustomTooltip>

                {/* File name show only when transmission is completed */}
                {feedTransmission?.ProcessingLog === "Transmission Completed" && (
                    <CustomTooltip TransitionComponent={Zoom} title="File Name">
                        <button className="btn-like-link" onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            getExactFileNameHandle(feedTransmission);


                        }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" >
                                <use xlinkHref={`${fileNameSVG}#fileNameIcon`}></use>
                            </svg>
                        </button>
                    </CustomTooltip>
                )}

                {feedTransmission?.Schedules && (
                    <CustomTooltip TransitionComponent={Zoom} title="Scheduler">
                        <button
                            className="btn-like-link"
                            onClick={(e) => {
                                e.stopPropagation(); e.preventDefault(); dialog.openDialog(
                                    <div>
                                        <h5 className="black1 fw-700">Scheduler</h5>
                                        {/* <Divider /> */}
                                    </div>,
                                    <>
                                        <div>{feedTransmission?.Schedules?.split('\n').map(s => <p>{s}</p>)}</div>
                                        <div className="row" style={{ justifyContent: "flex-end" }}><CopyToClipboardButton textToCopy={feedTransmission?.Schedules} /></div>
                                    </>

                                )

                            }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" >
                                <use xlinkHref={`${schedulerIconSVG}#schedulerIcon`}></use>
                            </svg>
                        </button>

                    </CustomTooltip>
                )}



                {/* mapper or above && @mapper case (it should have a file) */}
                {((feedTransmission?.ProcessingStep === "Mapper" &&
                    (feedTransmission?.ProcessingLog == "Invalid Output File" || feedTransmission?.ProcessingLog == "Mapping Completed")
                ) ||
                    feedTransmission?.ProcessingStep === "Serializer" ||
                    feedTransmission?.ProcessingStep === "Encrypter" ||
                    feedTransmission?.ProcessingStep === "Transmitter") &&
                    <CustomTooltip TransitionComponent={Zoom} title="View Plans">
                        <button
                            className="btn-like-link"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                getActualPlansHandle(feedTransmission);
                            }}>
                            <svg width="14" height="14" viewBox="0 0 14 14" >
                                <use xlinkHref={`${viewPlansSVG}#viewPlansIcon`}></use>
                            </svg>
                        </button>
                    </CustomTooltip>

                }


            </div>
        },
        // ===============================
        // Actions
    ]


    const pushToColumnsBasedOnUserAction = () => {
        const columnsToAdd = [
            {
                name: 'Feed ID',
                width: '60px',
                sortable: true,
                sortingkey: 'FeedID',
                id: 'FeedID',
                selector: feedTransmission => <a style={{ margin: "auto" }} href={`/Feeds/ViewFeed/${feedTransmission?.FeedID}`} onClick={(e) => { e.stopPropagation(); }}>
                    {feedTransmission?.FeedID}
                </a>
            },

        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumns];

        if (userActions?.includes("43") || userActions?.includes("20")) {
            finalColumnsArray.unshift(...columnsToAdd);
        }

        return finalColumnsArray;
    };


    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(feedsTransmissionsList)) {
            return [];
        }

        return feedsTransmissionsList
            .filter((obj, index) =>
                feedsTransmissionsList.findIndex((item) => item.FeedID === obj.FeedID && item.SK === obj.SK) === index
            )
        // .map(item => ({
        //     ...item,
        //     toggleExpand: () => handleRowExpandToggle(item),
        //     isExpanded: item.isExpanded || false,
        // }));
    }


    const ExpandableRowsComponentWithProps = withCustomProps(SingleFeedTransmissionNew, {
        refreshClicked: refreshClicked,
        setRefreshClicked: setRefreshClicked,
    });



    return (
        <div id="transmission-dashboard-table">
            <div className="d-flex">
                <MultipleTabsTitles tabTitle={transmissionsTabs} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />
            </div>

            <TransmissionsStatistics
                title="EDI Transmissions Statistics"
                isProductionDashboard={false}
                onFromDateFilter={(d) => setFromDateFilter(d)}
                fromDateFilter={fromDateFilter}
                onToDateFilter={(d) => setToDateFilter(d)}
                toDateFilter={toDateFilter}
                tableRef={tableRef}
                onTransmissionStatusFilter={(d) => setExecutionStatusFilter(d)}
                onTransmissionPhaseFilter={(d) => setFeedPhaseFilter(d)}
                onTransmissionStatusDashboardChange={(d) => setOnTransmissionStatusDashboardChange(d)}
                onTransmissionPhaseDashboardChange={(d) => setOnTransmissionPhaseDashboardChange(d)}
                onSetFilterCard={(s) => setFilterCard(s)}
                refreshClicked={refreshClicked}
            />

            <Paper className="content-card-paper">
                <TransmissionDashboardFilter
                    onFeedIDFilter={(d) => setFeedIDFilter(d)}
                    onCarrierIDFilter={(d) => setCarrierIDFilter(d)}
                    executionStatusFilter={executionStatusFilter}
                    onExecutionStatusFilter={(d) => setExecutionStatusFilter(d)}
                    onProcessingStepFilter={(d) => setProcessingStepFilter(d)}
                    onFeedTypeFilter={(d) => setFeedTypeFilter(d)}
                    onClientIDFilter={(d) => setClientIDFilter(d)}
                    onPEOIDFilter={(d) => setPEOIDFilter(d)}
                    onFeedStatusFilter={(d) => setFeedStatusFilter(d)}
                    feedPhaseFilter={feedPhaseFilter}
                    onFeedPhaseFilter={(d) => setFeedPhaseFilter(d)}
                    onExecutionIDFilter={(d) => setExecutionIDFilter(d)}
                    onFromDateFilter={(d) => setFromDateFilter(d)}
                    fromDateFilter={fromDateFilter}
                    onToDateFilter={(d) => setToDateFilter(d)}
                    toDateFilter={toDateFilter}
                    onSentFromDateFilter={(d) => setSentFromDateFilter(d)}
                    sentFromDateFilter={sentFromDateFilter}
                    onSentToDateFilter={(d) => setSentToDateFilter(d)}
                    sentToDateFilter={sentToDateFilter}
                    setRefreshClicked={setRefreshClicked}
                    selectedPage={selectedPage}
                    onSelectedPage={(p) => setSelectedPage(p)}
                    pageSize={pageSize}
                    onPageSize={(p) => setPageSize(p)}
                    totalCount={totalCount}
                    setTotalCount={setTotalCount}
                    selectedPageAdv={selectedPageAdv}
                    onSelectedPageAdv={(p) => setSelectedPageAdv(p)}
                    pageSizeAdv={pageSizeAdv}
                    onPageSizeAdv={(p) => setPageSizeAdv(p)}
                    quickFiltersClicked={quickFiltersClicked}
                    setQuickFiltersClicked={setQuickFiltersClicked}
                    advancedFiltersClicked={advancedFiltersClicked}
                    setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                    advancedFiltersAppliedClicked={advancedFiltersAppliedClicked}
                    setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                    feedIDAdvFilter={feedIDAdvFilter}
                    onFeedIDAdvFilter={s => setFeedIDAdvFilter(s)}
                    selectedFeedNameAdvFilter={selectedFeedNameAdvFilter}
                    onSelectedFeedNameAdvFilter={s => setSelectedFeedNameAdvFilter(s)}
                    selectedCarrierIDAdvFilter={selectedCarrierIDAdvFilter}
                    onSelectedCarrierIDAdvFilter={s => setSelectedCarrierIDAdvFilter(s)}
                    selectedFeedTypeAdvFilter={selectedFeedTypeAdvFilter}
                    onSelectedFeedTypeAdvFilter={s => setSelectedFeedTypeAdvFilter(s)}
                    selectedClientIDsAdvFilter={selectedClientIDsAdvFilter}
                    onSelectedClientIDsAdvFilter={s => setSelectedClientIDsAdvFilter(s)}
                    selectedPEOIDsAdvFilter={selectedPEOIDsAdvFilter}
                    onSelectedPEOIDsAdvFilter={s => setSelectedPEOIDsAdvFilter(s)}
                    selectedFeedStatusAdvFilter={selectedFeedStatusAdvFilter}
                    onSelectedFeedStatusAdvFilter={s => setSelectedFeedStatusAdvFilter(s)}
                    selectedFeedPhaseAdvFilter={selectedFeedPhaseAdvFilter}
                    onSelectedFeedPhaseAdvFilter={s => setSelectedFeedPhaseAdvFilter(s)}
                    executionIDAdvFilter={executionIDAdvFilter}
                    onExecutionIDAdvFilter={s => setExecutionIDAdvFilter(s)}
                    selectedExecutionStatusAdvFilter={selectedExecutionStatusAdvFilter}
                    onSelectedExecutionStatusAdvFilter={s => setSelectedExecutionStatusAdvFilter(s)}
                    selectedProcessingStepAdvFilter={selectedProcessingStepAdvFilter}
                    onSelectedProcessingStepAdvFilter={s => setSelectedProcessingStepAdvFilter(s)}
                    requestFromDateAdvFilter={requestFromDateAdvFilter}
                    onRequestFromDateAdvFilter={s => setRequestFromDateAdvFilter(s)}
                    requestToDateAdvFilter={requestToDateAdvFilter}
                    onRequestToDateAdvFilter={s => setRequestToDateAdvFilter(s)}
                    sentFromDateAdvFilter={sentFromDateAdvFilter}
                    onSentFromDateAdvFilter={s => setSentFromDateAdvFilter(s)}
                    sentToDateAdvFilter={sentToDateAdvFilter}
                    onSentToDateAdvFilter={s => setSentToDateAdvFilter(s)}
                    tableRef={tableRef}
                    onTransmissionStatusDashboardChange={onTransmissionStatusDashboardChange}
                    onTransmissionPhaseDashboardChange={onTransmissionPhaseDashboardChange}
                    onSetTransmissionStatusDashboardChange={(d) => setOnTransmissionStatusDashboardChange(d)}
                    onSetTransmissionPhaseDashboardChange={(d) => setOnTransmissionPhaseDashboardChange(d)}
                    onSetFilterCard={(s) => setFilterCard(s)}
                />

                {/* ================ */}
                <div className="max-w-100 mt-3" ref={tableRef}>
                    <GenericTable
                        columns={pushToColumnsBasedOnUserAction()}
                        // Remove duplicates if feed is connected with multiple CRs 
                        data={FilteredAndAddedIsExpand()}
                        onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}

                        expandableRows
                        expandableRowsComponent={ExpandableRowsComponentWithProps}
                        expandedRows={expandedRows}
                        handleRowExpandToggle={handleRowExpandToggle}
                        expandableRowExpanded={expandableRowExpanded}
                    />

                </div>


                <div>
                    <Pagination
                        stateLimit={true}
                        pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                        page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                        onChange={(t, v) => {
                            if (quickFiltersClicked) {
                                if (t === 1) setPageSize(v);
                                else if (t === 2) setSelectedPage(v);
                            } else if (advancedFiltersClicked) {
                                if (t === 1) setPageSizeAdv(v);
                                else if (t === 2) setSelectedPageAdv(v);
                            }
                        }}
                        count={totalCount}
                    />
                </div>
            </Paper>


        </div >
    )
}

export default TransmissionDashboard;