import React, { useRef } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CreateContactRow from "./CreateContactRow";
import plusIconSVG from "../../../Icons/PlusIconWhite.svg";
import TabsTitlesWithMoreDD from "../../Layout/TabsTitlesWithMoreDD";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));



function CarrierContactsTable({ onTabValueCallback, carrierContactTypes, isPEOClicked, selectedOtherCarriers, requestSubmitted, setInputsSavedValidation, setContactValidation, selectedCarriers, parentForceUpdate, ...props }) {

    const classes = useStyles();

    const [tabValue, setTabValue] = React.useState(0);
    const [tabDefaultValue, setTabDefaultValue] = React.useState("[Other Carrier Name]")


    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        onTabValueCallback(newValue);
    };
    // if selectedTab removed from selected Carriers list and it was last item
    const handleTabValue = () => {
        let otherCarrierInCasePEOLength = (selectedOtherCarriers?.length ?? 0)
        if (tabValue >= (selectedCarriers?.length + otherCarrierInCasePEOLength)) {
            let totalLength = (selectedCarriers.length + otherCarrierInCasePEOLength)
            if (totalLength == 0) {
                return (0)
            } else {
                return (selectedCarriers.length - 1 + otherCarrierInCasePEOLength)
            }
        } else {
            return (tabValue)
        }
    }

    React.useEffect(() => {
        setTabValue(handleTabValue())
    }, [selectedCarriers, tabValue, selectedOtherCarriers])


    return (
        <div id="carrier-contacts" className={classes.root + " d-flex flex-column"}>
            <div className="pr-0 text-center ml-auto" >
                {selectedCarriers?.length > 0 && tabValue < selectedCarriers?.length && (
                    <div  className="add-record-btn" onClick={() => {
                        if (selectedCarriers[tabValue].Contacts === undefined) selectedCarriers[tabValue].Contacts = [];
                        selectedCarriers[tabValue].Contacts = ([...selectedCarriers[tabValue].Contacts, {
                            ConnectivityRequestContactFirstName: "",
                            ConnectivityRequestContactLastName: "",
                            ConnectivityRequestContactEmail: "",
                            CarrierContactType: "Test",
                        }]);
                        parentForceUpdate();
                    }
                    }><svg width="12" height="12" viewBox="0 0 12 12" className="mr-2">
                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                        </svg> ADD</div>
                )}
                {selectedOtherCarriers?.length > 0 && tabValue >= selectedCarriers?.length && (
                    <div className="add-record-btn" onClick={() => {
                        if (selectedOtherCarriers[tabValue - selectedCarriers?.length].Contacts === undefined) selectedOtherCarriers[tabValue - selectedCarriers?.length].Contacts = [];
                        selectedOtherCarriers[tabValue - selectedCarriers?.length].Contacts = ([...selectedOtherCarriers[tabValue - selectedCarriers?.length].Contacts, {
                            ConnectivityRequestContactFirstName: "",
                            ConnectivityRequestContactLastName: "",
                            ConnectivityRequestContactEmail: "",
                            CarrierContactType: "Test",
                        }]);
                        parentForceUpdate();
                    }
                    }><svg width="12" height="12" viewBox="0 0 12 12" className="mr-2">
                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                        </svg> ADD</div>
                )}
            </div>
            <TabsTitlesWithMoreDD
                tabTitle={[
                    ...(selectedCarriers?.map(sc => isPEOClicked ? sc?.CarrierName : sc?.carrierName) || []),
                    ...(selectedOtherCarriers?.map(s => s?.Name ?? tabDefaultValue) || [])
                ]}
                value={handleTabValue()}
                setValue={setTabValue}
            />
            {/* <AppBar position="static" color="default">
                <div className="row">
                    <div className="col-10 pl-0">
                        <Tabs
                            className="tab-style"
                            value={handleTabValue()}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs"
                        >
                            {selectedCarriers?.map((sc, sci) => (
                                <Tab key={`carrier-contacts-tab-${sci}`} label={isPEOClicked ? sc?.CarrierName : sc?.carrierName} />
                            ))}
                            {selectedOtherCarriers?.map((s, si) => (
                                <Tab
                                    key={`tp-tab-${si}`}
                                    label={s?.Name ?? tabDefaultValue}
                                />
                            ))}
                        </Tabs>
                    </div>
                    <div className="col-2 pr-0 text-center">
                        {selectedCarriers?.length > 0 && tabValue < selectedCarriers?.length && (
                            <div style={{ marginTop: "7px" }} className="add-record-btn" onClick={() => {
                                if (selectedCarriers[tabValue].Contacts === undefined) selectedCarriers[tabValue].Contacts = [];
                                selectedCarriers[tabValue].Contacts = ([...selectedCarriers[tabValue].Contacts, {
                                    ConnectivityRequestContactFirstName: "",
                                    ConnectivityRequestContactLastName: "",
                                    ConnectivityRequestContactEmail: "",
                                    CarrierContactType: "Test",
                                }]);
                                parentForceUpdate();
                            }
                            }><svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                        </svg> ADD</div>
                        )}
                        {selectedOtherCarriers?.length > 0 && tabValue >= selectedCarriers?.length && (
                            <div style={{ marginTop: "7px" }} className="add-record-btn" onClick={() => {
                                if (selectedOtherCarriers[tabValue - selectedCarriers?.length].Contacts === undefined) selectedOtherCarriers[tabValue - selectedCarriers?.length].Contacts = [];
                                selectedOtherCarriers[tabValue - selectedCarriers?.length].Contacts = ([...selectedOtherCarriers[tabValue - selectedCarriers?.length].Contacts, {
                                    ConnectivityRequestContactFirstName: "",
                                    ConnectivityRequestContactLastName: "",
                                    ConnectivityRequestContactEmail: "",
                                    CarrierContactType: "Test",
                                }]);
                                parentForceUpdate();
                            }
                            }><svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                        </svg> ADD</div>
                        )}
                    </div>
                </div>
            </AppBar> */}
            {selectedCarriers?.length > 0 && tabValue < selectedCarriers?.length && (
                <TabPanel value={tabValue} index={tabValue} >
                    <div className="row table-header">
                        <div className="col-2 table-cell first-cell">
                            First Name
                        </div>
                        <div className="col-2 table-cell">
                            Last Name
                        </div>
                        <div className="col-3 table-cell">
                            Email
                        </div>
                        <div className="col-3 table-cell">
                            Type
                        </div>
                        <div className="col-2 table-cell">
                            Actions
                        </div>
                    </div>

                    {((!selectedCarriers[tabValue]?.Contacts) || (selectedCarriers[tabValue]?.Contacts?.length <= 0)) && (
                        <div className="no-data-alert">
                            <h6>No Contacts Added!</h6>
                        </div>
                    )}

                    {selectedCarriers[tabValue]?.Contacts?.map((c, ci) => (
                        <CreateContactRow
                            contact={c}
                            index={ci}
                            key={`contact-tp-${ci}`}
                            fullContacts={selectedCarriers[tabValue].Contacts}
                            parentForceUpdate={parentForceUpdate}
                            requestSubmitted={requestSubmitted}
                            carrierContactTypes={carrierContactTypes}
                        />
                    ))}
                </TabPanel>
            )}
            {selectedOtherCarriers?.length > 0 && tabValue >= selectedCarriers?.length && (
                <TabPanel value={tabValue} index={tabValue} >
                    <div className="row table-header">
                        <div className="col-2 table-cell first-cell">
                            First Name
                        </div>
                        <div className="col-2 table-cell">
                            Last Name
                        </div>
                        <div className="col-3 table-cell">
                            Email
                        </div>
                        <div className="col-3 table-cell">
                            Type
                        </div>
                        <div className="col-2 table-cell">
                            Actions
                        </div>
                    </div>

                    {((!selectedOtherCarriers[tabValue > selectedCarriers.length ? tabValue - selectedCarriers.length : selectedCarriers.length - tabValue]?.Contacts) || (selectedOtherCarriers[tabValue > selectedCarriers.length ? tabValue - selectedCarriers.length : selectedCarriers.length - tabValue]?.Contacts?.length <= 0)) && (
                        <div className="no-data-alert">
                            <h6>No Contacts Added!</h6>
                        </div>
                    )}

                    {selectedOtherCarriers[tabValue > selectedCarriers.length ? tabValue - selectedCarriers.length : selectedCarriers.length - tabValue]?.Contacts?.map((c, ci) => (
                        <CreateContactRow
                            contact={c}
                            index={ci}
                            key={`contact-tp-${ci}`}
                            fullContacts={selectedOtherCarriers[tabValue > selectedCarriers.length ? tabValue - selectedCarriers.length : selectedCarriers.length - tabValue].Contacts}
                            parentForceUpdate={parentForceUpdate}
                            requestSubmitted={requestSubmitted}
                            carrierContactTypes={carrierContactTypes}
                        />
                    ))}
                </TabPanel>
            )}
        </div>
    )
}

export default CarrierContactsTable;
