import React from 'react'
import Moment from 'moment';
import Pagination from "../../../Layout/Pagination";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import { useAuthentication } from "../../../../Data/Authentication";
import { CustomTooltip } from "../../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { FailureModal } from '../../../Modals/FailureModal';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import dataReadyIconSVG from "../../../../Icons/dataReadyIcon.svg";
import downloadIconSVG from "../../../../Icons/downloadIcon.svg";
import oeDataReadyDisabled from "../../../../Icons/oeDataReadyDisabled.svg";
import oeDataReadyEnabled from "../../../../Icons/oeDataReadyEnabled.svg";
import GenericTable from '../../../Layout/GenericTable';

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const AnalystConnectivityRequestOEListTable = ({
    advancedFiltersClicked,
    quickFiltersClicked,
    oERequestsList,
    setOERequestsList,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    selectedPageAdv,
    setSelectedPageAdv,
    pageSizeAdv,
    setPageSizeAdv,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    ...props
}) => {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();


    const getTitleSendOEButton = (oeRequest) => {
        if (oeRequest?.OERequestStatusID === 4) {
            return <div>This button is disabled because OE request is Blocked</div>;
        } else if (oeRequest?.OERequestStatusID === 3) {
            return <div>This button is disabled because OE request is Cancelled</div>;
        } else if (Moment(todayDate).format('YYYY-MM-DD') >= oeRequest?.PlanYearStartDate) {
            return <div>Plan Year Start Date has passed, updates will go through the weekly files</div>;
        } else if ((new Date(oeRequest?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45) {
            return <div>The earliest for an OE file to be sent is 45 days before the PYSD to capture terminations as expected</div>;
        }
        else {
            return <div>OE Data is Ready</div>;
        }
    }

    const handleDownloadOEFile = (OERequestID) => {
        openLoader();
        APIs.downloadOEFile(OERequestID).then(r => {
            window.open(r?.url)
        }).catch(error => {
            FailureModal(dialog, {
                title: 'Failure',
                body: 'Failed To Download OE File',
            })
        }).finally(() => closeLoader())
    }


    // ======================================================================
    const handleSort = (column, sortDirection, type) => {
        if (type === "quick") {
            onSortFieldFilter(column?.sortingkey);
            onSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            onSortFieldAdvFilter(column?.sortingkey);
            onSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };
    const baseColumnsArray = [
        {
            name: 'OE Request ID',
            sortable: true,
            width: '80px',
            sortingkey: 'OERequestID',
            id: 'OERequestID',
            selector: o => <a href={`/OERequests/ViewConnectivityRequest/${o?.ConnectivityRequestID}/ViewOERequest/${o?.Id}`}>{o?.Id}</a>
            ,
        },
        {
            name: 'Updated Group Structure',
            sortable: true,
            sortingkey: 'UpdatedGroupStructure',
            id: 'UpdatedGroupStructure',
            selector: c => c?.UpdatedGroupStructure === 1 ? "Yes" : "No",
        },
        {
            name: 'Plan Year Start Date',
            sortable: true,
            sortingkey: 'PlanYearStartDate',
            id: 'PlanYearStartDate',
            selector: c => c?.PlanYearStartDate ? Moment(c?.PlanYearStartDate).format('MM/DD/YYYY') : '',
        },
        {
            name: "Client's Expected Data Ready Date",
            sortable: true,
            sortingkey: 'ClientDataExpectedDate',
            id: 'ClientDataExpectedDate',
            selector: c => c?.ClientDataExpectedDate ? Moment(c?.ClientDataExpectedDate).format('MM/DD/YYYY') : '',
        },
        {
            name: 'Assigned To',
            sortable: true,
            sortingkey: 'AssignedTo',
            id: 'AssignedTo',
            selector: c => c?.AssignedTo !== null ? c?.AssignedTo : '',
        },
        {
            name: 'isolved Contact',
            sortable: true,
            sortingkey: 'IsolvedContact',
            id: 'IsolvedContact',
            selector: c => c?.IsolvedContact !== null ? c?.IsolvedContact : '',
        },
        {
            name: 'Status',
            sortable: true,
            sortingkey: 'OERequestStatusName',
            id: 'OERequestStatusName',
            selector: c => c?.OERequestStatusName,
        },
        {
            name: 'Phase',
            sortable: true,
            sortingkey: 'OERequestPhaseName',
            id: 'OERequestPhaseName',
            selector: c => c?.OERequestPhaseName,
        },
        {
            name: 'OE File Transmission Date',
            sortable: true,
            sortingkey: 'OEFileSubmissionDate',
            id: 'OEFileSubmissionDate',
            selector: o => <div>
                {o?.OEFileSubmissionDate !== null && o?.OEFileSubmissionDate !== "0000-00-00" ? Moment(o?.OEFileSubmissionDate).format('MM/DD/YYYY') : ""}
                <div className="mt-1">
                    {(o?.OERequestPhaseName !== "Completed" && o?.OERequestPhaseName !== "Get Carrier Confirmation") ? '' :
                        o?.OEFileSubmissionDate && o?.PlanYearStartDate && o?.SendOEFileDone == 1 && o?.SendOEFileDate &&
                            (Moment(o?.OEFileSubmissionDate).format('YYYY-MM-DD') < Moment(o?.PlanYearStartDate).format('YYYY-MM-DD')) &&
                            (Moment(o?.OEFileSubmissionDate).format('YYYY-MM-DD') >= Moment(o?.SendOEFileDate).format('YYYY-MM-DD')) ? <>
                            <CustomTooltip TransitionComponent={Zoom} title="Download OE File">
                                <button className="sendOEButtonDashboard" onClick={() => handleDownloadOEFile(o?.Id)}>
                                    <svg width="20" height="20" viewBox="0 0 20 20" >
                                        <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                                    </svg>
                                </button>
                            </CustomTooltip>
                        </> : ''
                    }
                </div>
            </div>
        },
        {
            name: 'Created By',
            sortable: true,
            sortingkey: 'CreatedBy',
            id: 'CreatedBy',
            selector: c => c?.CreatedBy !== null ? c?.CreatedBy : '',
        },
        {
            name: 'Created Date',
            sortable: true,
            sortingkey: 'CreatedDate',
            id: 'CreatedDate',
            selector: c => c?.Created ? Moment(c?.Created).format('MM/DD/YYYY') : '',
        },
        {
            name: 'Send OE Data',
            sortable: false,
            width: '80px',
            sortingkey: 'SendOEData',
            id: 'SendOEData',
            selector: c => (userActions?.includes("57") || userActions?.includes("20")) ? (
                <CustomTooltip TransitionComponent={Zoom} title={c?.SendOEFileDone === 1 ? `OE Data is Ready` : getTitleSendOEButton(c)}>
                    <span>
                        <button disabled={
                            c?.SendOEFileDone === 1 ||
                            c?.OERequestStatusID === 3 ||
                            c?.OERequestStatusID === 4 ||
                            Moment(todayDate).format('YYYY-MM-DD') >= c?.PlanYearStartDate ||
                            (new Date(c?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45
                        }
                            style={(c?.SendOEFileDone === 1 || c?.OERequestStatusID === 3 || c?.OERequestStatusID === 4) ? { pointerEvents: "none", } : {}} className="sendOEButtonDashboard" onClick={(e) => {
                                openLoader();
                                APIs.sendOEFile({ OERequestIDs: [c.Id] }).then(r => {
                                    if (r.success === "1") {
                                        openLoader();
                                        APIs.getOERequestDetails(c.Id).then(r => {
                                            openLoader();
                                            e.preventDefault();
                                            let editOEBody = {
                                                OERequestID: r.data.Id,
                                                PlanYearStartDate: r.data.PlanYearStartDate,
                                                ClientDataExpectedDate: r.data.ClientDataExpectedDate,
                                                OEType: r.data.OEType,
                                                OEChanges: r.data.OEChanges,
                                                ISolvedDataChanges: r.data.ISolvedDataChanges,
                                                UpdatedGroupStructure: r.data.UpdatedGroupStructure,
                                                IsolvedContact: r.data.IsolvedContactCognitoID,
                                                OERequestStatusID: r.data.OERequestStatusID,
                                                OERequestPhaseID: 4,
                                                AssignedTo: r.data.AssignedToCognitoID,
                                                OEFileSubmissionDate: r.data.OEFileSubmissionDate,
                                                NoAttachmentUpdate: true,
                                                IsDraftOERequest: r.data?.IsDraftOERequest,
                                                CanResumeProducionPYSD: r.data?.CanResumeProducionPYSD ?? 0
                                            }
                                            // Api for edit OE Request 
                                            APIs.editOERequest(editOEBody).then(r => window.location.reload())
                                        })
                                    } else {
                                        closeLoader();
                                        FailureModal(dialog, {
                                            title: 'Failure',
                                            body: r.msg,
                                        })
                                    }
                                })
                            }}>
                            {c?.SendOEFileDone === 1 ?
                                <svg width="19" height="13" viewBox="0 0 19 13" >
                                    <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                                </svg> :
                                c?.OERequestStatusID === 3 ||
                                    c?.OERequestStatusID === 4 ||
                                    Moment(todayDate).format('YYYY-MM-DD') >= c?.PlanYearStartDate ||
                                    (new Date(c?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                                    <svg width="15" height="15" viewBox="0 0 15 15" >
                                        <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                                    </svg> :
                                    <svg width="15" height="15" viewBox="0 0 15 15" >
                                        <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                                    </svg>
                            }
                        </button>
                    </span>
                </CustomTooltip>
            ) : (
                <CustomTooltip TransitionComponent={Zoom} title="OE Data is Ready">
                    {c?.SendOEFileDone === 1 ?
                        <svg width="19" height="13" viewBox="0 0 19 13" >
                            <use xlinkHref={`${dataReadyIconSVG}#dataReadyIcon`}></use>
                        </svg> :
                        c?.OERequestStatusID === 3 ||
                            c?.OERequestStatusID === 4 ||
                            Moment(todayDate).format('YYYY-MM-DD') >= c?.PlanYearStartDate ||
                            (new Date(c?.PlanYearStartDate).getTime() - new Date(Moment(todayDate).format('YYYY-MM-DD')).getTime()) / (1000 * 3600 * 24) >= 45 ?
                            <svg width="15" height="15" viewBox="0 0 15 15" >
                                <use xlinkHref={`${oeDataReadyDisabled}#oeDataReadyDisabled`}></use>
                            </svg> :
                            <svg width="15" height="15" viewBox="0 0 15 15" >
                                <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                            </svg>
                    }
                </CustomTooltip>
            )
        }
    ];

    const FilteredAndAddedIsExpand = () => {
        if (!Array.isArray(oERequestsList)) {
            return [];
        }
        return oERequestsList
    }


    return (
        <div id="oe-request-table" className='mt-5'>
            <GenericTable
                columns={[...baseColumnsArray]}
                data={[...FilteredAndAddedIsExpand()]}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}

            />

            <Pagination
                stateLimit={true}
                pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                onChange={(t, v) => {
                    if (quickFiltersClicked) {
                        if (t === 1) setPageSize(v);
                        else if (t === 2) setSelectedPage(v);
                    } else if (advancedFiltersClicked) {
                        if (t === 1) setPageSizeAdv(v);
                        else if (t === 2) setSelectedPageAdv(v);
                    }

                }}
                count={totalCount}
            />

        </div>
    )
}

export default AnalystConnectivityRequestOEListTable
