import React, { useEffect, useState } from 'react'
import { ConfirmSignFormModal, EmployeeForm, blobToBytes } from '../EmployeeViews/EmployeeSignFormModal';
import { DataObject } from 'pdf-wasm';


const ChangedDetails = ({ dsxUrl, dfElements, ...props }) => {
    const [changes, setChanges] = useState();
    const [employeesChangesForReason, setEmployeesChangesForReason] = useState([]);
    const [dependentsChangesForReason, setDependentsChangesForReason] = useState([]);
    const [employeeBenefitsChangesForReason, setEmployeeBenefitsChangesForReason] = useState([]);
    const [dependentBenefitsChangesForReason, setDependentBenefitsChangesForReason] = useState([]);
    const [waivedCoveragesChangesForReason, setWaivedCoveragesChangesForReason] = useState([]);
    const [beneficiaryChangesForReason, setBeneficiaryChangesForReason] = useState([]);
    const [employeeChanges, setEmployeeChanges] = React.useState([]);
    const [dependentChanges, setDependentChanges] = React.useState([]);
    const [employeeBenefitsChanges, setEmployeeBenefitsChanges] = React.useState([]);
    const [dependentBenefitsChanges, setDependentBenefitsChanges] = React.useState([]);
    const [waivedCoveragesChanges, setWaivedCoveragesChanges] = React.useState([]);
    const [beneficiaryChanges, setBeneficiaryChanges] = React.useState([]);

    React.useEffect(() => {
        setEmployeeChanges(dfElements?.AllElements?.filter(x => x.TableID === 1));
        setDependentChanges(dfElements?.AllElements?.filter(x => x.TableID === 2));
        setEmployeeBenefitsChanges(dfElements?.AllElements?.filter(x => x.TableID === 3));
        setDependentBenefitsChanges(dfElements?.AllElements?.filter(x => x.TableID === 4));
        setWaivedCoveragesChanges(dfElements?.AllElements?.filter(x => x.TableID === 5));
        setBeneficiaryChanges(dfElements?.AllElements?.filter(x => x.TableID === 6));
    }, [])

    const checkExistenceAndFilterCommon = (selectedChanges, currentChanges) => {
        return currentChanges?.map((item) => {
            const itemKeys = Object.keys(item);
            let newElements = [];
            const commonElements = selectedChanges.filter(element => itemKeys.includes(element.ElementName)).map(element => {
                return {
                    ...element,
                    newValue: item[element.ElementName].newValue,
                    oldValue: item[element.ElementName].oldValue
                };
            });
            if (item.isNew) {
                newElements.push(item);
            }
            return {
                item: item,
                commonElements: commonElements,
                newElements: newElements
            };
        });
    };

    useEffect(() => {
        if (dsxUrl) {
            fetch(dsxUrl)
                .then((response) => response.blob())
                .then(blobToBytes)
                .then((b) => {
                    const data = DataObject.fromXsd(new Uint8Array(b))
                    try {
                        const allChanges = data.getChanges();

                        for (const p of Object.getOwnPropertyNames(allChanges)) {
                            switch (p.toLowerCase()) {
                                case "employee":
                                    for (const item of allChanges[p]) {
                                        const qResult = data.query(p, item.id, ["employeeFirstName", "employeeLastName"]);
                                        if (qResult?.length > 0) {
                                            item.recordTitle = `${qResult[0].employeeFirstName} ${qResult[0].employeeLastName}`;
                                        }
                                        const employeesChangesReason = checkExistenceAndFilterCommon(employeeChanges, allChanges?.employee);
                                        setEmployeesChangesForReason(employeesChangesReason);
                                    }
                                    break;
                                case "dependent":
                                    for (const item of allChanges[p]) {
                                        const qResult = data.query(p, item.id, ["dependentFirstName", "dependentLastName"]);
                                        if (qResult?.length > 0) {
                                            item.recordTitle = `${qResult[0].dependentFirstName} ${qResult[0].dependentLastName}`;
                                        }
                                        const dependentsChangesReason = checkExistenceAndFilterCommon(dependentChanges, allChanges?.dependent);
                                        setDependentsChangesForReason(dependentsChangesReason);
                                    }
                                    break;
                                case "employeebenefit":
                                case "dependentbenefit":
                                    for (const item of allChanges[p]) {
                                        let qResult = data.query(p, item.id, ["benefitId"]);
                                        if (qResult?.length > 0) {
                                            qResult = data.query("carrierBenefit", { benefitId: qResult[0].benefitId }, ["benefitName", "benefitType"]);
                                        }
                                        if (qResult?.length > 0) {
                                            item.recordTitle = `${qResult[0].benefitName} (${qResult[0].benefitType})`;
                                        }
                                        const employeeBenefitsChangesReason = checkExistenceAndFilterCommon(employeeBenefitsChanges, allChanges?.employeebenefit);
                                        const dependentBenefitsChangesReason = checkExistenceAndFilterCommon(dependentBenefitsChanges, allChanges?.dependentbenefit);
                                        setEmployeeBenefitsChangesForReason(employeeBenefitsChangesReason);
                                        setDependentBenefitsChangesForReason(dependentBenefitsChangesReason);
                                    }
                                    break;
                                case "benefitbeneficiary":
                                    for (const item of allChanges[p]) {
                                        const dResult = data.query("dependent", {
                                            "companyId": item.id.companyId,
                                            "dependentId": item.id.dependentId,
                                            "employeePersonId": item.id.employeePersonId,
                                            "employerFein": item.id.employerFein
                                        }, ["dependentFirstName", "dependentLastName"]);
                                        const bResult = data.query("carrierBenefit", {
                                            "benefitId": item.id.benefitId
                                        }, ["benefitName"]);
                                        if (dResult?.length > 0 && bResult?.length > 0) {
                                            item.recordTitle = `${dResult[0].dependentFirstName} ${dResult[0].dependentLastName} (${bResult[0].benefitName})`;
                                        }
                                        const beneficiaryChangesReason = checkExistenceAndFilterCommon(beneficiaryChanges, allChanges?.benefitbeneficiary);
                                        setBeneficiaryChangesForReason(beneficiaryChangesReason);
                                    }
                                    break;
                                case "coveragewaivedemployeebenefit":
                                    for (const item of allChanges[p]) {
                                        const qResult = data.query(p, item.id, ["benefitType"]);
                                        if (qResult?.length > 0) {
                                            item.recordTitle = qResult[0].benefitType;
                                        }
                                        const coveragewaivedChangesReason = checkExistenceAndFilterCommon(waivedCoveragesChanges, allChanges?.coveragewaivedemployeebenefit);
                                        setWaivedCoveragesChangesForReason(coveragewaivedChangesReason);
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                        setChanges(allChanges);
                    }
                    finally {
                        if (data) {
                            data.free();
                        }
                    }
                });
        }
    }, [dsxUrl, employeeChanges, dependentChanges, employeeBenefitsChanges, dependentBenefitsChanges, beneficiaryChanges, waivedCoveragesChanges]);

    return (
        <>
            {employeesChangesForReason?.length === 0 &&
                dependentsChangesForReason?.length === 0 &&
                employeeBenefitsChangesForReason?.length === 0 &&
                dependentBenefitsChangesForReason?.length === 0 &&
                beneficiaryChangesForReason?.length === 0 &&
                waivedCoveragesChangesForReason?.length === 0 && (
                    <div style={{minWidth: "600px"}}>
                        <h6 className="black1 fw-500 mt-3">No Changes Found</h6>
                    </div>
                )}
            {/* Employee Changes Table */}
            {employeesChangesForReason?.length > 0 && (
                <React.Fragment>
                    <div style={{minWidth: "600px"}}>
                        <h6 className="black1 fw-500 mt-3">Employees:</h6>
                    </div>
                    <div className="row table-header">
                        <div className="col-3 table-cell">Identifier</div>
                        <div className="col-3 table-cell">Changed Fields</div>
                        <div className="col-3 table-cell">From</div>
                        <div className="col-3 table-cell">To</div>
                    </div>
                    {employeesChangesForReason?.map((x, xi) => (
                        <div key={`${xi}-employee-reason`}>
                            {x.commonElements?.map((y, yi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${yi}-employees-changes-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        {y.ElementTitle}
                                    </div>
                                    <div className="col-3 table-cell">{y.oldValue}</div>
                                    <div className="col-3 table-cell">{y.newValue}</div>
                                </div>
                            ))}
                            {x.newElements?.map((z, zi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${zi}-employees-new-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        New Employee
                                    </div>
                                    <div className="col-3 table-cell"></div>
                                    <div className="col-3 table-cell"></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </React.Fragment>
            )}

            {/* Dependents Changes Table */}
            {dependentsChangesForReason?.length > 0 && (
                <React.Fragment>
                    <div style={{minWidth: "600px"}}>
                        <h6 className="black1 fw-500 mt-3">Dependents:</h6>
                    </div>
                    <div className="row table-header">
                        <div className="col-3 table-cell">Identifier</div>
                        <div className="col-3 table-cell">Changed Fields</div>
                        <div className="col-3 table-cell">From</div>
                        <div className="col-3 table-cell">To</div>
                    </div>
                    {dependentsChangesForReason?.map((x, xi) => (
                        <div key={`${xi}-dependent-reason`}>
                            {x.commonElements?.map((y, yi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${yi}-dependents-changes-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        {y.ElementTitle}
                                    </div>
                                    <div className="col-3 table-cell">{y.oldValue}</div>
                                    <div className="col-3 table-cell">{y.newValue}</div>
                                </div>
                            ))}
                            {x.newElements?.map((z, zi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${zi}-dependents-new-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        New Dependent
                                    </div>
                                    <div className="col-3 table-cell"></div>
                                    <div className="col-3 table-cell"></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </React.Fragment>
            )}

            {/* Employees Benefits Changes Table */}
            {employeeBenefitsChangesForReason?.length > 0 && (
                <React.Fragment>
                    <div style={{minWidth: "600px"}}>
                        <h6 className="black1 fw-500 mt-3">Employees Benefits:</h6>
                    </div>
                    <div className="row table-header">
                        <div className="col-3 table-cell">Identifier</div>
                        <div className="col-3 table-cell">Changed Fields</div>
                        <div className="col-3 table-cell">From</div>
                        <div className="col-3 table-cell">To</div>
                    </div>
                    {employeeBenefitsChangesForReason?.map((x, xi) => (
                        <div key={`${xi}-employee-benefit-reason`}>
                            {x.commonElements?.map((y, yi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${yi}-employees-benefits-changes-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        {y.ElementTitle}
                                    </div>
                                    <div className="col-3 table-cell">{y.oldValue}</div>
                                    <div className="col-3 table-cell">{y.newValue}</div>
                                </div>
                            ))}
                            {x.newElements?.map((z, zi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${zi}-employees-benefits-new-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        New Plan
                                    </div>
                                    <div className="col-3 table-cell"></div>
                                    <div className="col-3 table-cell"></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </React.Fragment>
            )}

            {/* Dependents Benefits Changes Table */}
            {dependentBenefitsChangesForReason?.length > 0 && (
                <React.Fragment>
                    <div style={{minWidth: "600px"}}>
                        <h6 className="black1 fw-500 mt-3">Dependents Benefits:</h6>
                    </div>
                    <div className="row table-header">
                        <div className="col-3 table-cell">Identifier</div>
                        <div className="col-3 table-cell">Changed Fields</div>
                        <div className="col-3 table-cell">From</div>
                        <div className="col-3 table-cell">To</div>
                    </div>
                    {dependentBenefitsChangesForReason?.map((x, xi) => (
                        <div key={`${xi}-dependent-benefit-reason`}>
                            {x.commonElements?.map((y, yi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${yi}-dependents-benefits-changes-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        {y.ElementTitle}
                                    </div>
                                    <div className="col-3 table-cell">{y.oldValue}</div>
                                    <div className="col-3 table-cell">{y.newValue}</div>
                                </div>
                            ))}
                            {x.newElements?.map((z, zi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${zi}-dependents-benefits-new-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        New Plan
                                    </div>
                                    <div className="col-3 table-cell"></div>
                                    <div className="col-3 table-cell"></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </React.Fragment>
            )}

            {/* Beneficiary Changes Table */}
            {beneficiaryChangesForReason?.length > 0 && (
                <React.Fragment>
                    <div style={{minWidth: "600px"}}>
                        <h6 className="black1 fw-500 mt-3">Beneficiaries:</h6>
                    </div>
                    <div className="row table-header">
                        <div className="col-3 table-cell">Identifier</div>
                        <div className="col-3 table-cell">Changed Fields</div>
                        <div className="col-3 table-cell">From</div>
                        <div className="col-3 table-cell">To</div>
                    </div>
                    {beneficiaryChangesForReason?.map((x, xi) => (
                        <div key={`${xi}-beneficiary-reason`}>
                            {x.commonElements?.map((y, yi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${yi}-beneficiaries-changes-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        {y.ElementTitle}
                                    </div>
                                    <div className="col-3 table-cell">{y.oldValue}</div>
                                    <div className="col-3 table-cell">{y.newValue}</div>
                                </div>
                            ))}
                            {x.newElements?.map((z, zi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${zi}-beneficiaries-new-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        New Beneficiary
                                    </div>
                                    <div className="col-3 table-cell"></div>
                                    <div className="col-3 table-cell"></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </React.Fragment>
            )}

            {/* Waived Coverages Changes Table */}
            {waivedCoveragesChangesForReason?.length > 0 && (
                <React.Fragment>
                    <div>
                        <h6 className="black1 fw-500 mt-3">Waived Coverages:</h6>
                    </div>
                    <div className="row table-header">
                        <div className="col-3 table-cell">Identifier</div>
                        <div className="col-3 table-cell">Changed Fields</div>
                        <div className="col-3 table-cell">From</div>
                        <div className="col-3 table-cell">To</div>
                    </div>
                    {waivedCoveragesChangesForReason?.map((x, xi) => (
                        <div key={`${xi}-waivedcoverage-reason`}>
                            {x.commonElements?.map((y, yi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${yi}-waivedcoverages-changes-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        {y.ElementTitle}
                                    </div>
                                    <div className="col-3 table-cell">{y.oldValue}</div>
                                    <div className="col-3 table-cell">{y.newValue}</div>
                                </div>
                            ))}
                            {x.newElements?.map((z, zi) => (
                                <div
                                    className="row table-row position-relative popup-table"
                                    key={`${zi}-waivedcoverages-new-for-reason`}
                                    style={{ wordBreak: "break-word" }}
                                >
                                    <div className="col-3 table-cell first-cell">{x?.item?.recordTitle}</div>
                                    <div className="col-3 table-cell">
                                        Waiving Coverage
                                    </div>
                                    <div className="col-3 table-cell"></div>
                                    <div className="col-3 table-cell"></div>
                                </div>
                            ))}
                        </div>
                    ))}
                </React.Fragment>
            )}
        </>
    )
}

export default ChangedDetails
