import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./Styles/layout.css";
import "./Styles/tableStyles.css";
import "./Styles/colorPalettes.css";
import "./Styles/buttons.css";
import "./Styles/genericStyles.css";
import "./Styles/typography.css";
import "./Styles/containers.css";
import "./Styles/materialUI.css";
import "./Styles/statuses.css";
import "./Styles/modals.css";
import "./Styles/advancedFilterModal.css";
import "./Styles/responsiveness.css";
import App from "./App";
import { Amplify, Auth } from "aws-amplify";
import { AuthContextProvider, CustomStorage, getJwtAccessToken, getJwtToken } from "./Data/Authentication";
import config from "./config.json";
import { ModalDialogProvider } from "./Components/Layout/ModalDialog";
import { ModalDialogCustomizedProvider } from "./Components/Layout/ModalDialogCustomized";
import { CommentsModalDialogProvider } from "./Components/Layout/CommentsModalDialog";
import { LoaderProvider } from "./Components/Layout/Loader";
import ReactGA from 'react-ga';
import { ModalDialogCustomizedNoCloseProvider } from "./Components/Layout/ModalDialogCustomizedNoClose";

// Auth.configure({ storage: CustomStorage });

let env = process.env.REACT_APP_ENV?.toLowerCase()?.trim();
if (!env) env = "dev";

const currentURL = window.location.pathname;
const TRACKING_ID = config.googleanalytics[env]; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(currentURL);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito[env + "REGION"],
    userPoolId: config.cognito[env + "USER_POOL_ID"],
    userPoolWebClientId: config.cognito[env + "APP_CLIENT_ID"],
    authenticationFlowType: "USER_PASSWORD_AUTH",
  },
  API: {
    endpoints: [
      {
        name: "MainApiCall",
        endpoint: config.api[env + "mainURL"],
        custom_header: async () => {
          const token = await getJwtToken();
          if (token)
            return {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            };
        },
      },
      {
        name: "EngineApiCall",
        endpoint: config.api[env + "engineURL"],
        custom_header: async () => {
          const token = await getJwtToken();
          if (token)
            return {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            };
        },
      },
      {
        name: "PublicApiCall",
        endpoint: config.api[env + "engineURL"],
        custom_header: async () => {
          const firstDigitArr = [2, 4, 6, 8];
          const lastDigitArr = [0, 5];
          const firstDigit = firstDigitArr[Math.floor(Math.random() * firstDigitArr.length)];
          const lastDigit = lastDigitArr[Math.floor(Math.random() * lastDigitArr.length)];
          const middleDigits = Math.floor(10000000000 + Math.random() * 90000000000);
          const token = `${firstDigit}${middleDigits}${lastDigit}`;
          return {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          };
        },
      },
      {
        name: "DFApiCall",
        endpoint: config.api[env + "DFURL"],
        custom_header: async () => {
          const token = await getJwtAccessToken();
          if (token)
            return {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            };
        },
      },
    ],
  },
});

ReactDOM.render(
  <AuthContextProvider>
    <LoaderProvider>
      <ModalDialogProvider>
        <ModalDialogCustomizedProvider>
          <ModalDialogCustomizedNoCloseProvider>
            <CommentsModalDialogProvider>
              <App />
            </CommentsModalDialogProvider>
          </ModalDialogCustomizedNoCloseProvider>
        </ModalDialogCustomizedProvider>
      </ModalDialogProvider>
    </LoaderProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);
